import { BaseObjectEntity } from '@/types';
import { path } from '@/utils/formatLink';

const ROOTS_AUTH = '/auth';

export const PATH_AUTH: BaseObjectEntity = {
  root: ROOTS_AUTH,
  login: '/login',
  register: '/register',
  reset: '/reset',
  password: '/password',
  verify: '/verify',
};

export function authPath(pathKey: string) {
  return path(ROOTS_AUTH, PATH_AUTH[pathKey]);
}
