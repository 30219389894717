import { BaseObjectEntity } from '@/types';
import { path } from '@/utils/formatLink';

const ROOTS_USER = '/app';

export const PATH_USER: BaseObjectEntity = {
  root: ROOTS_USER,
  login: '/login',
  unauthorized: '/unauthorized',
};

export function userPath(pathKey: string) {
  return path(ROOTS_USER, PATH_USER[pathKey]);
}
