import { useNavigate, useParams } from 'react-router-dom';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useFieldArray, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
//
import { Card, IconButton, MenuItem, Stack } from '@mui/material';
import { LoadingButton } from '@mui/lab';
//
import { FormProvider, RHFEditor, RHFTextField } from '@/components/hook-form';
import { SkeletonLoadingTableData } from '@/components/skeleton';
import { AddItemButton, SectionTitle } from '.';
// api
import {
  createVariant,
  getServices,
  getSingleVariant,
  updateVariant,
} from '@/features/services-management/api';
import { getSettingsData } from '@/features/app/api';
// hooks + utils
import {
  STATUS_OPTIONS,
  VariantSchema,
  variantFormDefaultValues,
  variantFormFieldDefaultRow,
} from '@/features/services-management/utils';
import { useSnackbarMsg } from '@/hooks/useSnackbarMsg';
import { servicesManagementPath } from '..';
// types
import {
  VariantFormValues,
  Variant,
} from '@/features/services-management/types';
import { Iconify } from '@/components';

// ---------------------------------------------

export default function VariantDetails() {
  const navigate = useNavigate();
  const { variantId } = useParams();
  const { errorMsg, successMsg } = useSnackbarMsg();

  // =================
  //        RHF
  // =================

  const methods = useForm<VariantFormValues>({
    defaultValues: variantFormDefaultValues,
    resolver: yupResolver(VariantSchema),
  });

  const {
    handleSubmit,
    reset: resetForm,
    control,
    formState: { isSubmitting },
  } = methods;

  const {
    append: appendToFieldArray,
    fields: fieldArrayFields,
    remove: removeFromFieldArray,
  } = useFieldArray({
    control,
    name: 'service_form_fields',
  });

  // =================
  //      QUERIES
  // =================
  const queryClient = useQueryClient();

  // SERVICES
  const { data: services, isLoading: isServicesDataLoading } = useQuery({
    queryKey: ['services'],
    queryFn: getServices,
    onError: (error) => {
      errorMsg(error, `Something went wrong while fetching services data`);
    },
  });

  // SETTINGS
  const { data: serviceRecurences, isLoading: isRecurrenceOptionsLoading } =
    useQuery({
      queryKey: ['settings'],
      queryFn: () => getSettingsData(),
      onError: (error) => {
        errorMsg(error, `Something went wrong while fetching settings data`);
      },
      select: (data) => data?.serviceRecurences,
      // TODO: add stalec/cahed time
      refetchOnWindowFocus: false,
    });

  // CURRENT VARIANT
  const { data: currentVariant, isLoading: isVariantDataLoading } = useQuery({
    queryKey: ['serviceVariants', variantId],
    queryFn: () => getSingleVariant(Number(variantId)),
    onSuccess: (variant) => {
      // reset RHF form values with fetched data
      resetForm({
        variant_name: variant.name,
        service_type: variant.service.id,
        price: variant.price,
        service_recurrence: variant.service_reccurence.id,
        status: variant.active ? 'Active' : 'Inactive',
        description: variant.description,
      });
      variant.service_form_fields.forEach((field) =>
        appendToFieldArray({
          name: field.name,
          type: field.type,
          ref: field.ref,
          min_value: field.min_value,
          max_value: field.max_value,
          increment_value: field.increment_value,
        })
      );
    },
    onError: (error) => {
      errorMsg(error, `Something went wrong while fetching variant data`);
    },
    refetchOnWindowFocus: false,
    enabled: !!variantId && !!services && !!serviceRecurences,
  });

  // CREATE
  const createVariantMutation = useMutation({
    mutationFn: (variantDto: Partial<Variant>) => createVariant(variantDto),
    onSuccess: () => {
      queryClient.invalidateQueries(['serviceVariants']);
      successMsg('Variant successfully created!');
      navigate(servicesManagementPath('variants'));
    },
    onError: (error) =>
      errorMsg(error, `Something went wrong while creating variant`),
  });

  // UPDATE
  const updateVariantMutation = useMutation({
    mutationFn: (variantDto: Partial<Variant>) =>
      updateVariant(Number(variantId), variantDto),
    onSuccess: () => {
      queryClient.invalidateQueries(['serviceVariants', variantId]);
      successMsg('Variant successfully updated!');
      navigate(servicesManagementPath('variants'));
    },
    onError: (error) =>
      errorMsg(error, `Something went wrong while updating variant`),
  });

  const onSubmitVariant = (data: VariantFormValues) => {
    const variantDto = {
      name: data.variant_name,
      description: data.description,
      price: Number(data.price),
      active: data.status === 'Active' ? true : false,
      service_id: Number(data.service_type),
      service_recurrence_id: Number(data.service_recurrence),
      service_form_fields: data.service_form_fields.map((field) => ({
        ...field,
        min_value: Number(field.min_value),
        max_value: Number(field.max_value),
        increment_value: Number(field.increment_value),
      })),
    };
    variantId
      ? updateVariantMutation.mutate(variantDto)
      : createVariantMutation.mutate(variantDto);
  };

  const onErrorSubmit = (error: any) => console.error(error);

  const showSkeleton =
    (variantId && (isVariantDataLoading || !currentVariant)) ||
    isRecurrenceOptionsLoading ||
    isServicesDataLoading;

  return (
    <Card sx={{ p: 3 }}>
      {showSkeleton ? (
        <SkeletonLoadingTableData />
      ) : (
        <FormProvider
          methods={methods}
          onSubmit={handleSubmit(onSubmitVariant, onErrorSubmit)}
        >
          <Stack direction="column" spacing={2}>
            <SectionTitle title="Variant Details" />
            <Stack direction="row" spacing={2}>
              <RHFTextField name="variant_name" label="Variant Name" />
              <RHFTextField select name="service_type" label="Service">
                {(services || []).map((service, index) => (
                  <MenuItem key={index} value={service.id}>
                    {service.name}
                  </MenuItem>
                ))}
              </RHFTextField>
            </Stack>

            <Stack direction="row" spacing={2}>
              <RHFTextField label="Price" name="price" type="number" />
              <RHFTextField select name="service_recurrence" label="Recurrence">
                {(serviceRecurences || []).map((option, index) => (
                  <MenuItem key={index} value={option.id}>
                    {option.name}
                  </MenuItem>
                ))}
              </RHFTextField>
              <RHFTextField name="status" label="Status" select>
                {STATUS_OPTIONS.map((option, index) => (
                  <MenuItem key={index} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </RHFTextField>
            </Stack>

            <Stack direction="column" spacing={1}>
              <SectionTitle title="Description" />
              <RHFEditor name="description" />
            </Stack>

            <Stack spacing={2}>
              <SectionTitle title="Form fields" />

              {fieldArrayFields.map((field, index) => (
                <Stack direction="row" spacing={2} key={field.id}>
                  <RHFTextField
                    select
                    key={field.id}
                    name={`service_form_fields[${index}].type`}
                    label="Type"
                  >
                    {['select', 'picker', 'number'].map((option, index) => (
                      <MenuItem key={index} value={option}>
                        {option}
                      </MenuItem>
                    ))}
                  </RHFTextField>

                  <RHFTextField
                    name={`service_form_fields[${index}].ref`}
                    label="Ref"
                  />

                  <RHFTextField
                    name={`service_form_fields[${index}].name`}
                    label="Name"
                  />

                  <RHFTextField
                    name={`service_form_fields[${index}].min_value`}
                    label="Min value"
                  />

                  <RHFTextField
                    name={`service_form_fields[${index}].max_value`}
                    label="Max value"
                  />
                  <RHFTextField
                    name={`service_form_fields[${index}].increment_value`}
                    label="Increment value"
                  />
                  <IconButton onClick={() => removeFromFieldArray(index)}>
                    <Iconify icon={'eva:trash-2-fill'} color="error.main" />
                  </IconButton>
                </Stack>
              ))}
              <AddItemButton
                variant="contained"
                startIcon={<Iconify icon={'eva:plus-fill'} />}
                onClick={() => appendToFieldArray(variantFormFieldDefaultRow)}
              >
                Add Field
              </AddItemButton>
            </Stack>

            <Stack direction="row" justifyContent="flex-end">
              <LoadingButton
                type="submit"
                variant="contained"
                loading={isSubmitting}
              >
                {!currentVariant ? 'Create Variant' : 'Update Variant'}
              </LoadingButton>
            </Stack>
          </Stack>
        </FormProvider>
      )}
    </Card>
  );
}
