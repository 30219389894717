import React from 'react';
import { Card, SxProps } from '@mui/material';

export default function VBMCardWrapper({
  children,
  pinkBg,
  sx,
}: {
  children: React.ReactNode;
  pinkBg?: boolean;
  sx?: SxProps;
}) {
  return (
    <Card
      sx={[{
        width: '100%',
        p: 2.5,
        pt: 1.5,
        background: (theme) =>
          pinkBg ? theme.palette.error.lighter : theme.palette.grey[50],
        position: 'relative',
      },
            ...(Array.isArray(sx) ? sx : [sx]),
          ]}

    >
      {children}
    </Card>
  );
}
