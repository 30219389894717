import { Navigate, Route, Routes } from 'react-router-dom';
import { AgentDashboard } from '@/features/agent-dashboard/pages';
import DashboardLayout from '@/layouts/dashboard';
import AuthGuard from '@/guards/AuthGuard';
import RoleBasedGuard from '@/guards/RoleBasedGuard';

export const AppRoutes = () => (
  <Routes>
    <Route
      element={
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      }
    >
      <Route
        index
        element={
          <RoleBasedGuard roles={[]}>
            <AgentDashboard />
          </RoleBasedGuard>
        }
      />
      <Route path="*" element={<Navigate to="/app" />} />
    </Route>
  </Routes>
);
