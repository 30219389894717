// mui
import { Badge, IconButton, Tooltip } from '@mui/material';
import { Iconify } from '@/components';
// redux
import { useSelector } from '@/stores';
// type

interface RightSidebarIconProps {
  id: number;
  title: string;
  icon: string;
  onClick: VoidFunction;
  hideIcon?: boolean;
}

export default function RightSidebarIcon({
  id,
  title,
  icon,
  onClick,
  hideIcon = false,
}: RightSidebarIconProps) {
  const { selectedMenubarIconId } = useSelector((state) => state.rightSideBar);
  const { unreadMessages } = useSelector((state) => state.chatStream);
  const isSelected = selectedMenubarIconId === id;

  return (
    <>
      {hideIcon ? null : (
        <Tooltip
          title={title}
          placement="left"
          sx={{
            // paddingRight: 2.5,
            borderRadius: '8px',
            backgroundColor: isSelected ? 'background.paper' : 'transparent',
            '&:hover': {
              backgroundColor: 'background.paper',
              // borderRadius: '8px 0 0 8px',
            },
          }}
        >
          {title === 'Chat' ? (
            <IconButton color="default" onClick={onClick}>
              <Badge
                badgeContent={
                  unreadMessages.count === 0 ? '' : unreadMessages.count
                }
                color={unreadMessages.count !== 0 ? 'error' : 'default'}
              >
                <Iconify icon={icon} width={32} height={32} />
              </Badge>
            </IconButton>
          ) : (
            <IconButton color="default" onClick={onClick}>
              <Iconify icon={icon} width={30} height={30} />
            </IconButton>
          )}
        </Tooltip>
      )}
    </>
  );
}
