import { useState } from 'react';
// @mui
import { useTheme } from '@mui/material/styles';
import { TableRow, TableCell, Typography, MenuItem } from '@mui/material';
// components
import { Label, Iconify } from '@/components';
import { TableMoreMenu } from '@/components/table';
// types
import { EmailTemplate } from '../types';
import { PermissionsObject } from '@/types';

type Props = {
  row: EmailTemplate;
  permissions?: PermissionsObject;
  onEditRow: VoidFunction;
  onDeleteRow: VoidFunction;
  onPreviewRow: VoidFunction;
};

export default function EmailTemplateTableRow({
  row,
  permissions,
  onEditRow,
  onDeleteRow,
  onPreviewRow,
}: Props) {
  const theme = useTheme();

  const { subject, description, active, email_template_category } = row;

  const [openMenu, setOpenMenuActions] = useState<HTMLElement | null>(null);

  const handleOpenMenu = (event: React.MouseEvent<HTMLElement>) => {
    setOpenMenuActions(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setOpenMenuActions(null);
  };
  const { canUpdate, canDelete } = permissions || {};
  return (
    <TableRow hover>
      <TableCell>
        <Typography variant="subtitle2" noWrap>
          {subject}
        </Typography>
      </TableCell>

      <TableCell align="left">
        <Typography>{description}</Typography>
      </TableCell>

      <TableCell align="left">
        <Typography>{email_template_category.name}</Typography>
      </TableCell>

      <TableCell align="left">
        <Label
          variant={theme.palette.mode === 'light' ? 'ghost' : 'filled'}
          color={(active === false && 'error') || 'success'}
          sx={{ textTransform: 'capitalize' }}
        >
          {active ? 'Active' : 'Disabled'}
        </Label>
      </TableCell>

      <TableCell align="right">
        <TableMoreMenu
          open={openMenu}
          onOpen={handleOpenMenu}
          onClose={handleCloseMenu}
          actions={
            <>
              {(canUpdate || canDelete) && (
                <MenuItem
                  onClick={() => {
                    onPreviewRow();
                    handleCloseMenu();
                  }}
                  sx={{ color: 'info.main' }}
                >
                  <Iconify icon={'eva:eye-outline'} />
                  Preview
                </MenuItem>
              )}
              {canUpdate && (
                <MenuItem
                  onClick={() => {
                    onEditRow();
                    handleCloseMenu();
                  }}
                >
                  <Iconify icon={'eva:edit-fill'} />
                  Edit
                </MenuItem>
              )}
              {canDelete && (
                <MenuItem
                  onClick={() => {
                    onDeleteRow();
                    handleCloseMenu();
                  }}
                  sx={{ color: 'error.main' }}
                >
                  <Iconify icon={'eva:trash-2-outline'} />
                  Delete
                </MenuItem>
              )}
            </>
          }
        />
      </TableCell>
    </TableRow>
  );
}
