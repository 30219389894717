export const tableRowLinkSx = {
  fontWeight: 600,
  textDecoration: 'none',
  color: 'text.primary',
  fontSize: 14,
  borderRadius: 1,
  width: 'fit-content',
  p: 0,
  '&:hover': {
    backgroundColor: 'primary.lighter',
    px: 1,
  },
};
