import { Link } from 'react-router-dom';
//
import { SxProps, Theme } from '@mui/material/styles';
import { Box, Stack, Typography } from '@mui/material';
import { Iconify } from '@/components';
import { ReactNode } from 'react';
//
type CleaningJobDetailsTableRowProps = {
  descriptionText: string | ReactNode;
  iconText?: string;
  labelText: string;
  linkTo?: string;
  openInNewTab?: boolean;
  //
  descriptionSx?: SxProps<Theme>;
  iconSx?: SxProps<Theme>;
  stackSx?: SxProps<Theme>;
  labelSx?: SxProps<Theme>;
  //
};

export default function CleaningJobDetailsTableRow({
  descriptionText,
  iconText,
  labelText,
  linkTo,
  openInNewTab = false,
  //
  descriptionSx,
  iconSx,
  labelSx,
  stackSx,
}: CleaningJobDetailsTableRowProps) {
  const linkSx = {
    fontWeight: 600,
    textDecoration: 'none',
    color: 'text.primary',
    fontSize: 16,
    pr: 1,
    py: 0.5,
    borderRadius: 1,
    '&:hover': {
      backgroundColor: 'primary.lighter',
    },
  };

  return (
    <Stack
      direction="row"
      alignItems="center"
      sx={[
        { width: '100%' },
        ...(Array.isArray(stackSx) ? stackSx : [stackSx]),
      ]}
    >
      <Typography
        sx={[
          { width: '22%', minWidth: 90 },
          ...(Array.isArray(labelSx) ? labelSx : [labelSx]),
        ]}
      >
        {labelText}
      </Typography>

      <Typography
        fontWeight={600}
        component={linkTo ? Link : 'div'}
        to={linkTo ?? ''}
        target={openInNewTab ? '_blank' : '_self'}
        rel="noopener noreferrer"
        sx={[
          linkTo && linkSx,
          ...(Array.isArray(descriptionSx) ? descriptionSx : [descriptionSx]),
        ]}
      >
        {descriptionText}
      </Typography>

      {iconText && !iconText?.includes('question') && (
        <Box sx={[{ ml: 2 }, ...(Array.isArray(iconSx) ? iconSx : [iconSx])]}>
          <Iconify icon={iconText} />
        </Box>
      )}
    </Stack>
  );
}
