//
import { Box, Stack, Container, Typography } from '@mui/material';
import { Page, Image } from '@/components';
import {
  ContentStyle,
  HeaderStyle,
  LoginForm,
  RootStyle,
  SectionStyle,
} from '@/features/auth/components/';
// hooks + utils
import useResponsive from '@/hooks/useResponsive';

// ----------------------------------------------------------------------

export default function Login() {
  const mdUp = useResponsive('up', 'md');

  return (
    <Page title="Login">
      <RootStyle>
        <HeaderStyle>
          <Image
            sx={{ width: '170px' }}
            visibleByDefault
            disabledEffect
            src={'/logo/logo_full.svg'}
            alt="company_logo_icon"
          />
        </HeaderStyle>

        {mdUp && (
          <SectionStyle>
            <Image
              visibleByDefault
              disabledEffect
              src="/assets/illustrations/illustration_login.png"
              alt="login"
            />
            <p
              style={{
                paddingLeft: '10px',
                fontSize: '10px',
                color: '#333333',
              }}
            >
              App Version:{' '}
              {process.env.REACT_APP_VERSION?.slice(0, 7) || '0.0.1'}
            </p>
          </SectionStyle>
        )}

        <Container maxWidth="sm">
          <ContentStyle>
            <Stack direction="row" alignItems="center" sx={{ mb: 3 }}>
              <Box sx={{ flexGrow: 1 }}>
                <Typography variant="h4" gutterBottom>
                  Hi, Welcome back
                </Typography>
                <Typography sx={{ color: 'text.secondary' }}>
                  Login using the form below
                </Typography>
              </Box>
            </Stack>

            <LoginForm />
          </ContentStyle>
        </Container>
      </RootStyle>
    </Page>
  );
}
