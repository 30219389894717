import { useState } from 'react';
import { useQuery } from '@tanstack/react-query';
//
import {
  Box,
  Card,
  Container,
  FormControlLabel,
  Switch,
  Table,
  TableBody,
  TableContainer,
  TablePagination,
} from '@mui/material';
import { HeaderBreadcrumbs, Page, Scrollbar } from '@/components';
import { SkeletonLoadingTable } from '@/components/skeleton';
import { ToolbarWithSearchAndStatus } from '@/components/toolbar';
import {
  TableEmptyRows,
  TableHeadCustom,
  TableNoData,
} from '@/components/table';
import { InvoicesRunInvoicesListTableRow } from '../components';
// api
import { getProviderInvoice } from '@/features/invoices/api';
// hookes + utils
import { useGetAccess } from '@/hooks/useAccess';
import useSettings from '@/hooks/useSettings';
import { useSnackbarMsg } from '@/hooks/useSnackbarMsg';
import useTable, { emptyRows, getComparator } from '@/hooks/useTable';
//
import {
  PROVIDER_INVOICES_TABLE_HEAD,
  applySortFilterInvoicesList,
} from '@/features/invoices/utils';
import { PATH_USER } from '@/features/app';
import InvoiceRunsInvoicesListTableToolbar from '../components/InvoiceRunsInvoicesListTableToolbar';
import Invoice from '../components/providerInvoiceDetails';
import { consoleError } from '@/utils/consoleError';
import { invoicesPath, PATH_INVOICES } from '../routes/paths';
import { useParams } from 'react-router';

export default function InvoiceRunInvoicesList() {
  const {
    dense,
    page,
    order,
    orderBy,
    rowsPerPage,
    //
    selected,
    //
    onSort,
    onChangeDense,
    onChangePage,
    onChangeRowsPerPage,
    setSelected,
    setPage,
  } = useTable();

  const allow = useGetAccess('reviews');
  const { errorMsg } = useSnackbarMsg();
  const { themeStretch } = useSettings();
  const { invoiceId } = useParams();
  const [searchFieldValue, setSearchFieldValue] = useState('');
  const [filterStartDate, setFilterStartDate] = useState<string | null>(null);
  const [filterEndDate, setFilterEndDate] = useState<string | null>(null);

  // const statusOptions = ['all', ...REVIEW_STATUSES.map((s) => s.name)];
  const [filterStatus, setFilterStatus] = useState('all');
  const handleFilterStatus = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFilterStatus(event.target.value);
  };

  // =================
  //      QUERIES
  // =================
  const { data: invoiceDetails, isLoading: invoiceLoading } = useQuery({
    queryKey: ['invoiceDetails', invoiceId],
    queryFn: () => getProviderInvoice(Number(invoiceId)),
    onError: (error) => {
      errorMsg(error, `Something went wrong while fetching reviews`);
    },
  });

  const resetFilters = () => {
    setFilterStartDate(null);
    setFilterEndDate(null);
  };

  const handleFilterDropdown = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    switch (name) {
      case 'start_date':
        setFilterStartDate(value);
        break;
      case 'end_date':
        setFilterEndDate(value);
        break;
      default:
        consoleError('CouponsList', 'handleFilterDropdown()');
    }
    setSelected([]);
    setPage(0);
  };

  const handleDatepickerChange = (date: string | null, date_type: string) => {
    switch (date_type) {
      case 'start':
        setFilterStartDate(date);
        break;
      case 'end':
        setFilterEndDate(date);
        break;
      default:
        consoleError('CouponsList', 'handleDatepickerChange()');
    }
    setSelected([]);
    setPage(0);
  };

  return (
    <Page title="Provider Invoice Details">
      <Container maxWidth={themeStretch ? false : 'lg'}>
        <HeaderBreadcrumbs
          heading="Provider Invoice Details"
          links={[
            { name: 'Dashboard', href: PATH_USER.root },
            { name: 'Invoices Run', href: invoicesPath('list') },
            { name: `INV-` || '' },
          ]}
        />
        <Invoice invoice={invoiceDetails} />
      </Container>
    </Page>
  );
}
