import { useCallback, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { format } from 'date-fns';
//
import { Datepicker, MbscDatepickerChangeEvent } from '@mobiscroll/react';
//
import { Box, Button, Stack, Typography } from '@mui/material';
import {
  VBMCardTitle,
  VBMCardWrapper,
  VBMConfirmCleanerDialog,
  VBMEditButton,
} from '..';
// hooks + utils
import useResponsive from '@/hooks/useResponsive';
import useToggle from '@/hooks/useToggle';
import { timePickerSx } from '@/features/users/providers/utils';
import { fDate } from '@/utils/formatTime';
// types

type VBMDateTimeProps = {
  date: string;
  time: string;
  editable?: boolean;
};

export default function VBMDateTime({
  date,
  time,
  editable = false,
}: VBMDateTimeProps) {
  const [isEdit, setIsEdit] = useState(false);
  const [searchParams] = useSearchParams();
  const downMd = useResponsive('down', 'md');
  const sessionID = searchParams.get('sessionID');
  const [dateLocalValue, setDate] = useState<string>(date);
  const [timeLocalValue, setTime] = useState<string>(time);

  const {
    toggle: isConfirmCleanerDialogOpen,
    onOpen: openConfirmCleanerDialog,
    onClose: closeConfirmCleanerDialog,
  } = useToggle();

  const handleDateChange = useCallback((ev: MbscDatepickerChangeEvent) => {
    setDate(format(new Date(String(ev.valueText)), 'yyyy-MM-dd'));
  }, []);

  const handleTimeChange = useCallback((ev: MbscDatepickerChangeEvent) => {
    const selectedTime = ev.value;
    if (selectedTime instanceof Date) {
      setTime(format(new Date(selectedTime), 'HH:mm'));
    } else {
      setTime('');
    }
  }, []);

  const handleToggleEdit = (value: boolean) => setIsEdit(value);

  const onSuccessCallbackFn = () => {
    handleToggleEdit(false);
    closeConfirmCleanerDialog();
  };

  return (
    <>
      <VBMCardWrapper>
        {!isEdit && editable && (
          <VBMEditButton onClickButton={() => setIsEdit(true)} />
        )}

        <VBMCardTitle title="Date & time" sx={{ mb: 1 }} />

        {isEdit ? (
          <>
            <Stack spacing={2}>
              <Box sx={timePickerSx}>
                <Datepicker
                  name="VBMDateTime-mbscDatePicker"
                  label="Date"
                  theme="ios"
                  themeVariant="light"
                  controls={['calendar']}
                  touchUi={true}
                  display="anchored"
                  animation="pop"
                  value={dateLocalValue}
                  onChange={handleDateChange}
                  dateFormat="D MMM YYYY"
                  buttons={[]}
                />
              </Box>
              <Box sx={timePickerSx}>
                <Datepicker
                  name="VBMDateTime-mbscTimePicker"
                  value={timeLocalValue}
                  onChange={handleTimeChange}
                  label="Time"
                  theme="ios"
                  themeVariant="light"
                  controls={['timegrid']}
                  display="anchored"
                  touchUi={true}
                  minTime="06:00"
                  maxTime="23:00"
                  stepMinute={30}
                  animation="pop"
                  timeFormat="HH:mm"
                />
              </Box>
            </Stack>

            <Stack
              direction="row"
              justifyContent="flex-end"
              spacing={1}
              sx={{ mt: 2 }}
            >
              <Button
                variant="outlined"
                size="small"
                color="inherit"
                onClick={() => handleToggleEdit(false)}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                size="small"
                onClick={openConfirmCleanerDialog}
              >
                {downMd ? 'Save' : 'Save changes'}
              </Button>
            </Stack>
          </>
        ) : (
          <Typography variant="body2">
            {fDate(date, 'EE, dd MMM yyyy')} - {time}
          </Typography>
        )}
      </VBMCardWrapper>
      <VBMConfirmCleanerDialog
        isDialogOpen={isConfirmCleanerDialogOpen}
        onCloseDialog={closeConfirmCleanerDialog}
        bookingSessionId={Number(sessionID)}
        onSuccessCallbackFn={onSuccessCallbackFn}
        onSuccessText={`Time/date updated`}
        parentBookingSessionDto={{ time: timeLocalValue, date: dateLocalValue }}
      />
    </>
  );
}
