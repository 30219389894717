import { Route, Routes } from 'react-router-dom';
import DashboardLayout from '@/layouts/dashboard';
import AuthGuard from '@/guards/AuthGuard';
import RoleBasedGuard from '@/guards/RoleBasedGuard';

import { PATH_USER_MANAGEMENT } from './paths';
import { UserCreateEdit, UserList } from '../pages';

const DASHBOARD_USERS_ROUTES = [
  {
    path: '',
    roles: ['users.view'],
    component: <UserList />,
    index: true,
  },
  {
    path: PATH_USER_MANAGEMENT.new,
    roles: ['users.create'],
    component: <UserCreateEdit />,
    index: false,
  },
  {
    path: PATH_USER_MANAGEMENT.edit,
    roles: ['users.update'],
    component: <UserCreateEdit />,
    index: false,
  },
];

export const UserRoutes = () => (
  <Routes>
    <Route
      element={
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      }
    >
      {DASHBOARD_USERS_ROUTES.map((route, index) => (
        <Route
          key={index}
          path={route.path}
          index={route.index}
          element={
            <RoleBasedGuard roles={route.roles}>
              {route.component}
            </RoleBasedGuard>
          }
        />
      ))}
    </Route>
  </Routes>
);
