import { Box, Divider, Skeleton, Stack } from '@mui/material';

export default function SkeletonLoadingTable() {
  return (
    <Stack spacing={2} sx={{ pl: 3, pb: 2 }}>
      <Box>
        <Skeleton sx={{ width: 0.98 }} height={100} />
      </Box>
      <Divider />
      <Box>
        <Skeleton sx={{ width: 0.98 }} height={100} />
      </Box>
      <Box>
        {[...Array(5)].map((_, i) => (
          <Skeleton key={i} sx={{ width: 0.98 }} height={60} />
        ))}
      </Box>
    </Stack>
  );
}
