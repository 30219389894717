import { Button } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';

type LoginFormBackButtonProps = {
  linkTo: string;
};

export default function LoginFormBackButton({
  linkTo,
}: LoginFormBackButtonProps) {
  return (
    <Button
      component={RouterLink}
      to={linkTo}
      sx={{
        pb: 2,
        justifyContent: 'start',
        '&.MuiButtonBase-root:hover': {
          bgcolor: 'transparent',
        },
      }}
      startIcon={<ChevronLeftIcon />}
    >
      <strong>Back</strong>
    </Button>
  );
}
