import { Route, Routes } from 'react-router-dom';
import DashboardLayout from '@/layouts/dashboard';
import AuthGuard from '@/guards/AuthGuard';
import RoleBasedGuard from '@/guards/RoleBasedGuard';

import { PATH_SERVICES_MANAGEMENT } from './paths';
import {
  ServiceCreateEdit,
  ServiceVariantCreateEdit,
  ServicesList,
  ServiceVariantsList,
} from '../pages';

const SERVICE_MANAGEMENT_ROUTES = [
  {
    path: PATH_SERVICES_MANAGEMENT.services,
    roles: ['users.view'],
    component: <ServicesList />,
    index: false,
  },
  {
    path: PATH_SERVICES_MANAGEMENT.variants,
    roles: ['users.view'],
    component: <ServiceVariantsList />,
    index: false,
  },
  {
    path: PATH_SERVICES_MANAGEMENT.newService,
    roles: ['users.create'],
    component: <ServiceCreateEdit />,
    index: false,
  },
  {
    path: PATH_SERVICES_MANAGEMENT.newVariant,
    roles: ['users.create'],
    component: <ServiceVariantCreateEdit />,
    index: false,
  },
  {
    path: PATH_SERVICES_MANAGEMENT.editService,
    roles: ['users.update'],
    component: <ServiceCreateEdit />,
    index: false,
  },
  {
    path: PATH_SERVICES_MANAGEMENT.editVariant,
    roles: ['users.update'],
    component: <ServiceVariantCreateEdit />,
    index: false,
  },
];

export const ServicesManagementRoutes = () => (
  <Routes>
    <Route
      element={
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      }
    >
      {SERVICE_MANAGEMENT_ROUTES.map((route, index) => (
        <Route
          key={index}
          path={route.path}
          index={route.index}
          element={
            <RoleBasedGuard roles={route.roles}>
              {route.component}
            </RoleBasedGuard>
          }
        />
      ))}
    </Route>
  </Routes>
);
