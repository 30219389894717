import { useState } from 'react';
// mui
import { FormControlLabel, Stack, Switch, TextField } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// api
import { verifyCoupon } from '@/features/coupons/api';
// hooks + utils
import { useSnackbarMsg } from '@/hooks/useSnackbarMsg';
// redux
import { useDispatch, useSelector } from '@/stores';
import { setCouponObject } from '@/features/bookings-management/slices';
// types
import { ServiceVariant } from '@/types';

export default function Discount() {
  const [isActive, setIsActive] = useState<boolean>(false);
  const [couponCode, setCouponCode] = useState<string>('');
  const [buttonLoading, setButtonLoading] = useState<boolean>(false);

  const { selectedServiceVariant } = useSelector((state) => state.booking);

  const dispatch = useDispatch();
  const { errorMsg } = useSnackbarMsg();

  const handleApplyCoupon = async () => {
    if (!(selectedServiceVariant as ServiceVariant).id) {
      errorMsg('Select a service first');
      return;
    }
    try {
      setButtonLoading(true);
      const response = await verifyCoupon({
        service_variant_id: (selectedServiceVariant as ServiceVariant).id,
        couponCode,
        platform: 'WEB_PLATFORM',
      });
      dispatch(setCouponObject(response));
      setCouponCode('');
    } catch (error) {
      errorMsg(error, 'There was an error while applying coupon code');
    } finally {
      setButtonLoading(false);
    }
  };
  return (
    <Stack direction="row" spacing={2}>
      <FormControlLabel
        control={<Switch onChange={() => setIsActive(!isActive)} />}
        label="Coupon"
        sx={{ width: '23%' }}
      />

      {isActive && (
        <>
          <TextField
            label="Coupon code"
            onChange={(e) => setCouponCode(e.target.value)}
            value={couponCode}
            size="small"
            sx={{ width: 200 }}
          />

          <LoadingButton
            variant="contained"
            size="small"
            loading={buttonLoading}
            onClick={handleApplyCoupon}
          >
            Apply
          </LoadingButton>
        </>
      )}
    </Stack>
  );
}
