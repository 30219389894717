import { useParams } from 'react-router-dom';
import { useState } from 'react';
import { useQuery } from '@tanstack/react-query';
//
import {
  Box,
  Card,
  Divider,
  FormControlLabel,
  Switch,
  Tab,
  Table,
  TableBody,
  TableContainer,
  TablePagination,
  Tabs,
} from '@mui/material';
import { Scrollbar } from '@/components';
import { SkeletonLoadingTable } from '@/components/skeleton';
import { ToolbarWithSearchAndStatus } from '@/components/toolbar';
import {
  TableEmptyRows,
  TableHeadCustom,
  TableNoData,
} from '@/components/table';
import { ClientBookingsTableRow } from '..';
// api
import { getUserBookings } from '@/features/users/shared/api';
// hookes + utils
import { useGetAccess } from '@/hooks/useAccess';
import { useSnackbarMsg } from '@/hooks/useSnackbarMsg';
import useTable, { emptyRows, getComparator } from '@/hooks/useTable';
import useTabs from '@/hooks/useTabs';
import {
  CLIENT_BOOKINGS_TABLE_HEAD,
  applySortFilterClientBookings,
} from '@/features/users/clients/utils';

export default function ClientBookings() {
  const {
    dense,
    page,
    order,
    orderBy,
    rowsPerPage,
    //
    selected,
    //
    onSort,
    onChangeDense,
    onChangePage,
    onChangeRowsPerPage,
  } = useTable();

  const allow = useGetAccess('users');
  const { userId } = useParams();
  const { errorMsg } = useSnackbarMsg();

  const [searchFieldValue, setSearchFieldValue] = useState('');
  const { currentTab: filterStatus, onChangeTab: onChangeFilterStatus } =
    useTabs('Today');
  // =================
  //      QUERIES
  // =================
  const { data: clientBookings, isLoading: isClientDataLoading } = useQuery({
    queryKey: ['clientBookings'],
    queryFn: () => getUserBookings(Number(userId)),
    onError: (error) => {
      errorMsg(error, `Something went wrong while fetching clients`);
    },
  });

  const dataFiltered = applySortFilterClientBookings({
    tableData: clientBookings || [],
    comparator: getComparator(order, orderBy),
    searchFieldValue,
    filterStatus,
  });

  const denseHeight = dense ? 52 : 72;

  const isNotFound =
    (!dataFiltered.length && !!searchFieldValue) || !dataFiltered.length;

  return (
    <Card>
      {isClientDataLoading ? (
        <SkeletonLoadingTable />
      ) : (
        <>
          <Tabs
            allowScrollButtonsMobile
            variant="scrollable"
            scrollButtons="auto"
            value={filterStatus}
            onChange={onChangeFilterStatus}
            sx={{ px: 2, bgcolor: 'background.neutral' }}
          >
            {['Past', 'Today', 'Upcoming', 'All'].map((tab, index) => (
              <Tab disableRipple key={index} label={tab} value={tab} />
            ))}
          </Tabs>

          <Divider />
          <ToolbarWithSearchAndStatus
            filterText={searchFieldValue}
            onFilterText={setSearchFieldValue}
            labels={{ search: `Search in client's bookings...` }}
          />

          <Scrollbar>
            <TableContainer sx={{ minWidth: 800, position: 'relative' }}>
              <Table size={dense ? 'small' : 'medium'}>
                <TableHeadCustom
                  order={order}
                  orderBy={orderBy}
                  headLabel={CLIENT_BOOKINGS_TABLE_HEAD}
                  rowCount={[clientBookings || []].length}
                  numSelected={selected.length}
                  onSort={onSort}
                />
                <TableBody>
                  {dataFiltered
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((booking) => (
                      <ClientBookingsTableRow
                        key={booking.id}
                        row={booking}
                        canDelete={allow.canDelete}
                        canUpdate={allow.canUpdate}
                      />
                    ))}

                  <TableEmptyRows
                    height={denseHeight}
                    emptyRows={emptyRows(
                      page,
                      rowsPerPage,
                      dataFiltered.length
                    )}
                  />

                  <TableNoData
                    isNotFound={isNotFound}
                    title="No bookings found"
                  />
                </TableBody>
              </Table>
            </TableContainer>
          </Scrollbar>

          <Box sx={{ position: 'relative' }}>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={dataFiltered.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={onChangePage}
              onRowsPerPageChange={onChangeRowsPerPage}
            />

            <FormControlLabel
              control={<Switch checked={dense} onChange={onChangeDense} />}
              label="Dense"
              sx={{ px: 3, py: 1.5, top: 0, position: { md: 'absolute' } }}
            />
          </Box>
        </>
      )}
    </Card>
  );
}
