import { useEffect, forwardRef } from 'react';
import { m, useAnimation } from 'framer-motion';
// @mui
import { Box, IconButton } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import { AnimateWrapProp, IconButtonPropsExtended } from '../types';

const IconNotificationAnimate = forwardRef<
  HTMLButtonElement,
  IconButtonPropsExtended
>(({ children, size = 'medium', count = 0, ...other }, ref) => (
  <AnimateWrap count={count}>
    <IconButton size={size} ref={ref} {...other}>
      {children}
    </IconButton>
  </AnimateWrap>
));

export default IconNotificationAnimate;

function AnimateWrap({ count, children }: AnimateWrapProp) {
  const animation = useAnimation();
  const theme = useTheme();

  const varMedium = {
    hover: { scale: 1.09 },
    tap: { scale: 0.97 },
    count: {
      scale: [1, 1.09, 1],
      transition: { duration: 0.8 },
      backgroundColor: [
        theme.palette.background.paper,
        theme.palette.background.neutral,
        theme.palette.background.paper,
      ],
      borderRadius: ['50%', '50%'],
    },
  };

  useEffect(() => {
    count > 0 && animation.start('count');
  }, [count, animation]);

  return (
    <Box
      component={m.div}
      animate={animation}
      whileTap="tap"
      whileHover="hover"
      variants={varMedium}
      sx={{
        display: 'inline-flex',
      }}
    >
      {children}
    </Box>
  );
}
