import { useForm } from 'react-hook-form';
import { lightFormat } from 'date-fns';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
//
import DialogSimple from '@/components/dialog/DialogSimple';
import { CleaningJobDetailsDialogDetails } from '..';
// hooks
import { FormProvider } from '@/components/hook-form';
import { useSnackbarMsg } from '@/hooks/useSnackbarMsg';
// API
import {
  getBookingSession,
  updateBookingSession,
} from '@/features/bookings-management/api';
import { RHFDefaultValues, JOB_DETAILS_DEFAULT_VALUES } from '../../utils';
// types

type CleaningJobDetailsDialogProps = {
  currentSessionId: number | null;
  isJobDialogOpen: boolean;
  closeJobDialog: () => void;
  tempValues?: any;
};

export default function CleaningJobDetailsDialog({
  currentSessionId,
  isJobDialogOpen,
  closeJobDialog,
}: CleaningJobDetailsDialogProps) {
  const { errorMsg, successMsg } = useSnackbarMsg();

  // ====================
  //       QUERIES
  // ====================
  const queryClient = useQueryClient();

  // FETCH
  const { data: currentCleaningSession } = useQuery({
    enabled: !!currentSessionId,
    queryKey: ['scheduler', currentSessionId],
    queryFn: () => getBookingSession(Number(currentSessionId)),
    onSuccess: (currentJob) => {
      resetRhfFormValues({
        mbscDatePicker: currentJob?.date,
        mbscTimePicker: `${currentJob?.date}T${currentJob?.time}`,
        status_id: currentJob?.status_id,
        admin_notes: currentJob?.admin_notes,
        booking_cleaners: currentJob?.booking_session_cleaners[0]?.id ?? 0,
      });
    },
    onError: (error) => {
      errorMsg(
        error,
        `Something went wrong while fetching current session data`
      );
    },
  });

  // POST
  const updateBookingSessionMutation = useMutation({
    // this mutate function will make the API call
    mutationFn: (updatedBookingSession: any) =>
      updateBookingSession(currentSessionId!, updatedBookingSession),

    // when you're doing an optimistic update,
    // utilize what you're getting back or the very least invalidate afterwards
    onSuccess: () => {
      // here it's enough if we just invalidate the scheduler query
      queryClient.invalidateQueries(['scheduler']);
      // TODO: this component is used for assigned/unassigned jobs as well
      //       maybe we can separate these in the future
      queryClient.invalidateQueries(['unassignedJobs']);

      successMsg(`Booking session ID#${currentSessionId} updated`);
    },
    onError: (error) =>
      errorMsg(error, 'Something went wrong while updating booking session'),
  });

  // ===============
  //       RHF
  // ===============

  const methods = useForm<RHFDefaultValues>({
    defaultValues: JOB_DETAILS_DEFAULT_VALUES,
  });

  const {
    reset: resetRhfFormValues,
    handleSubmit,
    formState: { dirtyFields },
  } = methods;

  // ================
  //      SUBMIT
  // ================
  const submitJob = async (data: RHFDefaultValues) => {
    const dirtyData: Partial<RHFDefaultValues> = {};
    // we need to make sure we only send the fields that have been changed
    // in case of the change of cleaner, date, time the job will get unassigned from the cleaner

    (Object.keys(dirtyFields) as (keyof RHFDefaultValues)[]).forEach(
      (field) => {
        if (dirtyFields[field]) {
          const value = data[field];

          if (value instanceof Date && field === 'mbscDatePicker') {
            Object.assign(dirtyData, {
              date: lightFormat(value, 'yyyy-MM-dd'),
            });
            return;
          }
          if (value instanceof Date && field === 'mbscTimePicker') {
            Object.assign(dirtyData, { time: lightFormat(value, 'HH:mm') });
            return;
          }
          if (field === 'booking_cleaners') {
            Object.assign(dirtyData, {
              [field]: [Number(value)],
            });
            return;
          }
          Object.assign(dirtyData, { [field]: value });
        }
      }
    );

    updateBookingSessionMutation.mutate(dirtyData);
    closeJobDialog();
  };

  const handleCloseDialog = () => {
    closeJobDialog();
  };

  // ================

  return (
    <DialogSimple
      dialogTitle={'Booking Session Details'}
      open={isJobDialogOpen}
      onClose={handleCloseDialog}
      onClickButtonClose={handleCloseDialog}
      disagreeButton={false}
      loadingButton={false}
      maxWidth="xs"
    >
      <FormProvider methods={methods} onSubmit={handleSubmit(submitJob)}>
        <CleaningJobDetailsDialogDetails
          currentCleaningSession={currentCleaningSession}
          closeJobDialog={handleCloseDialog}
        />
      </FormProvider>
    </DialogSimple>
  );
}
