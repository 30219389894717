import { useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
// mui
import {
  Box,
  Button,
  Card,
  Container,
  Divider,
  FormControlLabel,
  Switch,
  Tab,
  Tabs,
  Table,
  TableBody,
  TableContainer,
  TablePagination,
} from '@mui/material';
// components
import { HeaderBreadcrumbs, Iconify, Page, Scrollbar } from '@/components';
import {
  TableEmptyRows,
  TableHeadCustom,
  TableNoData,
} from '@/components/table';
// custom components
import { ToolbarWithSearchAndStatus } from '@/components/toolbar';
import { ServicesTableRow } from '../components';
// API
import { getServices, updateService } from '../api';
// utils + hooks
import { useGetAccess } from '@/hooks/useAccess';
import useTabs from '@/hooks/useTabs';
import useSettings from '@/hooks/useSettings';
import useTable, { getComparator, emptyRows } from '@/hooks/useTable';
import { useSnackbarMsg } from '@/hooks/useSnackbarMsg';
//
import { SERVICES_TABLE_HEAD, applySortFilterServices } from '../utils';
import { servicesManagementPath } from '../routes/paths';
// types
import { Service, ServiceDto } from '../types';
import { SkeletonLoadingTableData } from '@/components/skeleton';

// ----------------------------------------------------------

export default function ServicesList() {
  const {
    dense,
    page,
    order,
    orderBy,
    rowsPerPage,
    setPage,
    //
    onSort,
    onChangeDense,
    onChangePage,
    onChangeRowsPerPage,
  } = useTable();

  const { themeStretch } = useSettings();

  const navigate = useNavigate();
  const { successMsg, errorMsg } = useSnackbarMsg();
  // TODO: check
  const allow = useGetAccess('users');
  const queryClient = useQueryClient();
  const STATUS_OPTIONS = ['Active', 'Inactive', 'All'];

  // =================
  //      QUERIES
  // =================

  const { data: services, isLoading: isServicesDataLoading } = useQuery({
    queryKey: ['services'],
    queryFn: getServices,
    onError: (error) => {
      errorMsg(error, `Something went wrong while fetching services data`);
    },
  });

  // ==========================
  //      FILTER, UPDATE
  // ==========================

  const [filterName, setFilterName] = useState('');

  const { currentTab: filterStatus, onChangeTab: onChangeFilterStatus } =
    useTabs('Active');

  const handleFilterName = (filterName: string) => {
    setFilterName(filterName);
    setPage(0);
  };
  const updateServiceStatus = useMutation({
    mutationFn: ({
      id,
      serviceUpdateDto,
    }: {
      id: number;
      serviceUpdateDto: ServiceDto;
    }) => updateService(id, serviceUpdateDto),
    onSuccess: () => {
      queryClient.invalidateQueries(['services']);
      successMsg(`Service status updated successfully`);
    },
    onError: (error) => {
      errorMsg(error, `There was an error while updating service status`);
    },
  });

  const handleSetStatusRow = async (service: Service) => {
    const serviceUpdateDto = {
      name: service.name,
      active: !service.active,
    };
    updateServiceStatus.mutate({ id: service.id, serviceUpdateDto });
  };

  const handleEditRow = (id: number) =>
    navigate(servicesManagementPath('editService', { serviceId: String(id) }));

  const dataFiltered = applySortFilterServices({
    tableData: services ?? [],
    comparator: getComparator(order, orderBy),
    filterName,
    filterStatus,
  });

  const denseHeight = dense ? 52 : 72;
  const isNotFound =
    (!dataFiltered.length && !!filterName) ||
    (!dataFiltered.length && !!filterStatus);

  // ---------------------------------------------------

  return (
    <Page title="Services Management">
      <Container maxWidth={themeStretch ? false : 'lg'}>
        <HeaderBreadcrumbs
          heading="Services Management"
          links={[
            { name: 'Dashboard', href: '' },
            { name: 'Services', href: '' },
          ]}
          action={
            <Button
              variant="contained"
              component={RouterLink}
              to={servicesManagementPath('newService')}
              startIcon={<Iconify icon={'eva:plus-fill'} />}
            >
              Add Service
            </Button>
          }
        />

        <Card>
          {isServicesDataLoading ? (
            <SkeletonLoadingTableData />
          ) : (
            <>
              <Tabs
                allowScrollButtonsMobile
                variant="scrollable"
                scrollButtons="auto"
                value={filterStatus}
                onChange={onChangeFilterStatus}
                sx={{ px: 2, bgcolor: 'background.neutral' }}
              >
                {STATUS_OPTIONS.map((tab) => (
                  <Tab disableRipple key={tab} label={tab} value={tab} />
                ))}
              </Tabs>

              <Divider />

              <ToolbarWithSearchAndStatus
                filterText={filterName}
                onFilterText={handleFilterName}
              />

              <Scrollbar>
                <TableContainer sx={{ minWidth: 800, position: 'relative' }}>
                  <Table size={dense ? 'small' : 'medium'}>
                    <TableHeadCustom
                      order={order}
                      orderBy={orderBy}
                      headLabel={SERVICES_TABLE_HEAD}
                      onSort={onSort}
                    />
                    <TableBody>
                      {dataFiltered
                        .slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                        .map((service, index) => (
                          <ServicesTableRow
                            key={index}
                            row={service}
                            onSetStatusRow={() => handleSetStatusRow(service)}
                            onEditRow={() => handleEditRow(service.id)}
                            canDelete={allow.canDelete}
                            canUpdate={allow.canUpdate}
                          />
                        ))}

                      <TableEmptyRows
                        height={denseHeight}
                        emptyRows={emptyRows(
                          page,
                          rowsPerPage,
                          dataFiltered.length
                        )}
                      />

                      <TableNoData
                        isNotFound={isNotFound}
                        title="No service found"
                      />
                    </TableBody>
                  </Table>
                </TableContainer>
              </Scrollbar>

              <Box sx={{ position: 'relative' }}>
                <TablePagination
                  rowsPerPageOptions={[5, 10, 25]}
                  component="div"
                  count={dataFiltered.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={onChangePage}
                  onRowsPerPageChange={onChangeRowsPerPage}
                />

                <FormControlLabel
                  control={<Switch checked={dense} onChange={onChangeDense} />}
                  label="Dense"
                  sx={{ px: 3, py: 1.5, top: 0, position: { md: 'absolute' } }}
                />
              </Box>
            </>
          )}
        </Card>
      </Container>
    </Page>
  );
}
