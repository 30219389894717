import { BaseObjectEntity } from '@/types';
import { path } from '@/utils/formatLink';
import { generatePath } from 'react-router';

export const ROOTS_ROLES = '/roles';

export const PATH_ROLES: BaseObjectEntity = {
  root: ROOTS_ROLES,
  new: '/new',
  edit: '/:roleId/edit',
};

export function rolesPath(pathKey: string, params?: BaseObjectEntity) {
  return generatePath(path(ROOTS_ROLES, PATH_ROLES[pathKey]), params);
}
