import {
  isAfter,
  isBefore,
  isFuture,
  isPast,
  isThisWeek,
  isToday,
  isTomorrow,
  set,
} from 'date-fns';
import { Activity } from '../types';

export function applySortFilterList({
  tableData,
  comparator,
  filterName,
  filterStatus,
  filterType,
  filterPriority,
  filterAssigned,
  filterLinked,
  filterStartDate,
  filterEndDate,
  filterDate,
}: {
  tableData: Activity[];
  comparator: (a: any, b: any) => number;
  filterName: string;
  filterStatus: string;
  filterType: string;
  filterPriority: string;
  filterAssigned: string;
  filterLinked: string;
  filterStartDate: Date | null;
  filterEndDate: Date | null;
  filterDate: string;
}) {
  const stabilizedThis = tableData.map((el, index) => [el, index] as const);

  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });

  tableData = stabilizedThis.map((el) => el[0]);

  if (filterName) {
    tableData = tableData.filter(
      ({ linked_entity_type, subject, owner: { first_name } }) =>
        subject.toLowerCase().indexOf(filterName.toLowerCase()) !== -1 ||
        linked_entity_type.description
          .toLowerCase()
          .indexOf(filterName.toLowerCase()) !== -1 ||
        first_name.toLowerCase().indexOf(filterName.toLowerCase()) !== -1
    );
  }
  if (filterType !== 'all') {
    tableData = tableData.filter(
      ({ activity_type: { name } }) =>
        name.toLowerCase().indexOf(filterType.toLowerCase()) !== -1
    );
  }

  if (filterPriority !== 'all') {
    tableData = tableData.filter(
      ({ priority: { name } }) =>
        name.toLowerCase().indexOf(filterPriority.toLowerCase()) !== -1
    );
  }
  if (filterAssigned !== 'all') {
    tableData = tableData.filter(
      ({ owner: { first_name } }) =>
        first_name.toLowerCase().indexOf(filterAssigned.toLowerCase()) !== -1
    );
  }
  if (filterLinked !== 'all') {
    tableData = tableData.filter(
      ({ linked_entity_type: { description } }) =>
        description.toLowerCase().indexOf(filterLinked.toLowerCase()) !== -1
    );
  }

  if (filterStartDate && filterEndDate) {
    tableData = tableData.filter(
      ({ start_date, end_date }) =>
        isAfter(
          new Date(start_date),
          set(filterStartDate, { hours: 0, minutes: 0, seconds: 0 })
        ) &&
        isBefore(
          new Date(end_date),
          set(filterEndDate, { hours: 23, minutes: 59, seconds: 59 })
        )
    );
  }

  if (filterDate !== 'all') {
    tableData = tableData.filter(
      ({ end_date, completed }) =>
        (isToday(new Date(end_date)) &&
          filterDate.toLocaleLowerCase() === 'today') ||
        (isTomorrow(new Date(end_date)) &&
          filterDate.toLowerCase() === 'tomorrow') ||
        (isThisWeek(new Date(end_date)) &&
          filterDate.toLowerCase() === 'thisweek') ||
        (isPast(new Date(end_date)) &&
          filterDate.toLowerCase() === 'overdue') ||
        (isFuture(new Date(end_date)) &&
          filterDate.toLowerCase() === 'nextweek') ||
        (filterDate === 'todo' && !completed)
    );
  }

  if (filterStatus !== 'all') {
    tableData = tableData.filter(({ completed }) =>
      completed
        ? filterStatus.toLowerCase() === 'done'
        : filterStatus.toLowerCase() === 'to do'
    );
  }
  return tableData;
}
