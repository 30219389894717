import { string, number, object } from 'yup';
import { tableSortFilter } from '@/utils/arrayProcessing';
import { VariantCustomPrice } from '../types/custom-prices';

export function applySortFilterVariantCustomPrices({
  tableData,
  comparator,
  searchFieldValue,
}: {
  tableData: VariantCustomPrice[];
  comparator: (a: any, b: any) => number;
  searchFieldValue: string;
}) {
  tableData = tableSortFilter(tableData, comparator);
  const searchFieldValueLowerCase = searchFieldValue.toLowerCase();

  if (searchFieldValue) {
    tableData = tableData.filter(
      (price) =>
        String(price.price).indexOf(searchFieldValueLowerCase) !== -1 ||
        String(price.sun_bank_holiday_extra).indexOf(
          searchFieldValueLowerCase
        ) !== -1 ||
        (price.description &&
          price.description.toLowerCase().indexOf(searchFieldValueLowerCase) !==
            -1) ||
        price.cities.name.toLowerCase().indexOf(searchFieldValueLowerCase) !==
          -1 ||
        price.start_date.toLowerCase().indexOf(searchFieldValueLowerCase) !==
          -1 ||
        price.end_date.toLowerCase().indexOf(searchFieldValueLowerCase) !== -1
    );
  }

  return tableData;
}

export const VARIANTS_CUSTOM_PRICES_TABLE_HEAD = [
  { id: 'cities.name', label: 'City', align: 'left' },
  { id: 'price', label: 'Price', align: 'left' },
  { id: 'sun_bank_holiday_extra', label: 'Sunday/BH extra', align: 'left' },
  { id: 'description', label: 'Description', align: 'left' },
  { id: 'overwrite', label: 'Overwrite', align: 'left' },
  { id: 'start_date', label: 'Start date', align: 'left' },
  { id: 'end_date', label: 'End date', align: 'left' },
  { id: 'actionBtns' },
];

export const NEW_VARIANT_CUSTOM_PRICE_DEFAULT_VALUES = {
  city_id: '',
  price: '',
  sun_bank_holiday_extra: '',
  description: '',
  start_date: '',
  end_date: '',
  overwrite: false,
};

export const NewVariantCustomPriceSchema = object().shape({
  city_id: string().required('City is required'),
  price: number().typeError('Price is required').required('Price is required'),
  sun_bank_holiday_extra: number()
    .typeError('Extra price is required')
    .required('Extra price is required'),
  description: string().required('Description is required'),
  start_date: string().required('Start date is required'),
  end_date: string().required('End date is required'),
});
