import { format, getTime, formatDistanceToNow } from 'date-fns';

// ----------------------------------------------------------------------

export function fDate(
  date: Date | string | number,
  defaultDate = 'dd MMMM yyyy'
) {
  return format(new Date(date), defaultDate); // 01 January 2021
}
export function fDateWithLeadingZeros(date: Date | string | number) {
  return format(new Date(date), 'dd-MM-yyyy'); // 01-01-2021
}
export function fDateAbbrevMonth(
  date: Date | string | number,
  outputFormat?: string
) {
  return format(new Date(date), outputFormat ? outputFormat : 'dd MMM yyyy'); // 01 Jan 2021
}

export function fDateTime(
  date: Date | string | number,
  defaultDate = 'dd MMM yyyy p'
) {
  return format(new Date(date), defaultDate); // 01 Jan 2021 12:00 AM
}

export function fTimestamp(date: Date | string | number) {
  return getTime(new Date(date));
}

export function fDateTimeSuffix(date: Date | string | number) {
  return format(new Date(date), 'dd/MM/yyyy hh:mm aa');
}

export function fToNow(date: Date | string | number) {
  return formatDistanceToNow(new Date(date), {
    addSuffix: true,
  });
}

export function validateTypedDate(
  dateStr: string | Date,
  daysInPast?: number,
  daysInFuture?: number,
  onlyLastDaysOfCurrentMonth?: boolean
): Date | string | null {
  let inputDate: Date;

  if (typeof dateStr === 'string') {
    if (!dateStr.trim()) return null;

    const [day, month, year] = dateStr.split('/').map(Number);

    if (
      day <= 0 ||
      day > 31 ||
      month <= 0 ||
      month > 12 ||
      year < 1900 ||
      year > 2099 ||
      isNaN(day) ||
      isNaN(month) ||
      isNaN(year)
    ) {
      return `Invalid date format - ${dateStr}`;
    }

    inputDate = new Date(year, month - 1, day);
  } else if (dateStr instanceof Date) {
    inputDate = dateStr;
  } else {
    return `Invalid input - ${dateStr}`;
  }

  const today = new Date();

  const allowedPastDate = daysInPast
    ? new Date(today.setDate(today.getDate() - daysInPast))
    : undefined;

  today.setDate(new Date().getDate());
  const allowedFutureDate = daysInFuture
    ? new Date(today.setDate(today.getDate() + daysInFuture))
    : undefined;

  if (allowedPastDate && inputDate.getTime() < allowedPastDate.getTime()) {
    if (daysInPast === 1) {
      return 'No past date allowed.';
    } else {
      return `Date should not be more than ${daysInPast} days in the past.`;
    }
  }

  if (allowedFutureDate && inputDate.getTime() > allowedFutureDate.getTime()) {
    if (daysInFuture === 1) {
      return 'No future date allowed.';
    } else {
      return `Date should not be more than ${daysInFuture} days in the future.`;
    }
  }

  if (onlyLastDaysOfCurrentMonth) {
    if (
      inputDate.getFullYear() === today.getFullYear() &&
      inputDate.getMonth() === today.getMonth()
    ) {
      const lastDayOfMonth = new Date(
        today.getFullYear(),
        today.getMonth() + 1,
        0
      );
      if (inputDate.getTime() > lastDayOfMonth.getTime()) {
        return 'Date should be one of the days remaining in the current month.';
      }
    } else {
      return 'Date should be one of the days remaining in the current month.';
    }
  }

  return inputDate;
}
