// @mui
import {
  TableRow,
  TableCell,
  Typography,
  Button,
  IconButton,
} from '@mui/material';
// components
import { Iconify } from '@/components';
// types
import { ActivityType } from '../types';
import { PermissionsObject } from '@/types';

type Props = {
  row: ActivityType;
  permissions?: PermissionsObject;
  onEditRow: VoidFunction;
  onSelectRow: VoidFunction;
  selected: boolean;
};

export default function TableRowData({
  row,
  permissions,
  onEditRow,
  selected,
}: Props) {
  const { name, icon } = row;

  return (
    <TableRow hover selected={selected}>
      <TableCell>
        <Typography variant="subtitle2" noWrap>
          <IconButton
            color="primary"
            sx={{ minHeight: 0, minWidth: 0, padding: 0 }}
          >
            <Iconify
              fontSize="20px"
              icon={icon}
              sx={{ verticalAlign: 'top', margin: '0 10px 0 10px' }}
            />
          </IconButton>
          {name}
        </Typography>
      </TableCell>

      <TableCell align="right">
        <>
          {permissions?.canUpdate && (
            <Button
              onClick={() => {
                onEditRow();
              }}
              variant="contained"
            >
              Edit
            </Button>
          )}
        </>
      </TableCell>
    </TableRow>
  );
}
