import { SyntheticEvent, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { capitalCase } from 'change-case';
// @mui
import { Box, Button, Container, Tab, Tabs } from '@mui/material';
// components
import { Page, HeaderBreadcrumbs, Iconify } from '@/components/';
import {
  ClientBookings,
  ClientDetails,
  ClientInvoices,
  ClientWallet,
  ClientReviews,
  ClientPrices,
} from '../components';
//  hooks + utils
import useSettings from '@/hooks/useSettings';
import useTabs from '@/hooks/useTabs';
import useToggle from '@/hooks/useToggle';
import { PATH_USER } from '@/features/app';
import { PATH_CLIENT_MANAGEMENT, clientManagementPath } from '../routes/paths';

// ----------------------------------------------------------------------

export default function ClientCreateEdit() {
  const { themeStretch } = useSettings();
  const { userId, tabSid } = useParams();
  const { currentTab, onChangeTab, setCurrentTab } = useTabs('details');
  const navigate = useNavigate();

  useEffect(() => {
    tabSid && setCurrentTab(tabSid);
  }, [tabSid, setCurrentTab]);

  const handleTabChange = (
    event: SyntheticEvent<Element, Event>,
    newValue: string
  ) => {
    onChangeTab(event, newValue);
    navigate(
      clientManagementPath('edit', { userId: String(userId), tabSid: newValue })
    );
  };

  const {
    toggle: isCustomPriceDialogOpen,
    onOpen: openCustomPriceDialog,
    onClose: closeCustomPriceDialog,
  } = useToggle();

  const CLIENT_PROFILE_TABS = [
    {
      value: 'details',
      component: <ClientDetails />,
      visible: true,
    },
    {
      value: 'bookings',
      component: <ClientBookings />,
      visible: !!userId,
    },
    {
      value: 'invoices',
      component: <ClientInvoices />,
      visible: !!userId,
    },
    {
      value: 'wallet',
      component: <ClientWallet />,
      visible: !!userId,
    },
    {
      value: 'reviews',
      component: <ClientReviews />,
      visible: !!userId,
    },
    {
      value: 'prices',
      component: (
        <ClientPrices
          isCustomPriceDialogOpen={isCustomPriceDialogOpen}
          closeCustomPriceDialog={closeCustomPriceDialog}
        />
      ),
      visible: !!userId,
    },
  ];

  return (
    <Page title={`Clients: ${!userId ? 'Create new' : 'Edit client'}`}>
      <Container maxWidth={themeStretch ? false : 'lg'}>
        <HeaderBreadcrumbs
          heading={!userId ? 'Create a new client' : 'Edit client'}
          links={[
            { name: 'Dashboard', href: PATH_USER.root },
            { name: 'Clients', href: PATH_CLIENT_MANAGEMENT.root },
            { name: !userId ? 'New client' : 'Edit client' },
          ]}
          action={
            // TODO add permissions
            currentTab === 'prices' && (
              <Button
                variant="contained"
                startIcon={<Iconify icon={'eva:plus-fill'} />}
                onClick={openCustomPriceDialog}
              >
                Add Custom Price
              </Button>
            )
          }
        />

        <Tabs
          allowScrollButtonsMobile
          variant="scrollable"
          scrollButtons="auto"
          value={currentTab}
          onChange={handleTabChange}
          sx={{ mb: 1 }}
          aria-label="profile tabs"
        >
          {CLIENT_PROFILE_TABS.map(
            (tab) =>
              tab.visible && (
                <Tab
                  disableRipple
                  key={tab.value}
                  value={tab.value}
                  label={capitalCase(tab.value)}
                />
              )
          )}
        </Tabs>

        {CLIENT_PROFILE_TABS.map(
          (tab) =>
            tab.value === currentTab && (
              <Box key={tab.value} sx={{ width: '100%' }}>
                {tab.component}
              </Box>
            )
        )}
      </Container>
    </Page>
  );
}
