// @mui
import { Stack, StackProps, Typography } from '@mui/material';
import { TooltipIcon } from './Iconify';

// ----------------------------------------------------------------------

interface Props extends StackProps {
  title: string;
  tooltip?: string;
  spacing?: number;
  icon?: string;
}

export default function Block({
  sx,
  title,
  spacing = 2,
  tooltip,
  icon = 'eva:info-outline',
  children,
  ...other
}: Props) {
  return (
    <Stack spacing={1.3} sx={{ mb: spacing, ...sx }} {...other}>
      <Stack
        direction="row"
        alignItems="center"
        sx={{
          color: 'text.secondary',
        }}
      >
        <Typography variant="caption" sx={{ fontWeight: 'fontWeightMedium' }}>
          {title}
        </Typography>

        {!!tooltip && (
          <TooltipIcon
            title={tooltip}
            icon={icon}
            width={16}
            sx={{ mx: 0.5 }}
          />
        )}
      </Stack>

      {children}
    </Stack>
  );
}
