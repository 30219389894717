import axios from '@/utils/axios';
import { LocationData } from '@/features/users/shared/types';
import { Postcode } from '../types/postcode';

export async function findAddressPostcodeLookup(
  postcode: string
): Promise<LocationData> {
  const {
    data: { data },
  } = await axios.get(`/addresses/find/${postcode}`);
  return data;
}

export async function getAvailablePostcodes(): Promise<Postcode[]> {
  const {
    data: { data },
  } = await axios.get(`/postcodes`);
  return data;
}
