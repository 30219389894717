import { LogsData } from '../types';
import { isAfter, isBefore, set } from 'date-fns';
//
export function applySortFilter({
  tableData,
  comparator,
  filterName,
  filterActions,
  filterUsers,
  filterStartDate,
  filterEndDate,
}: {
  tableData: LogsData[];
  comparator: (a: any, b: any) => number;
  filterName: string;
  filterActions: string;
  filterUsers: string;
  filterStartDate: Date | null;
  filterEndDate: Date | null;
}) {
  const stabilizedThis = tableData.map((el, index) => [el, index] as const);

  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });

  tableData = stabilizedThis.map((el) => el[0]);

  if (filterName) {
    tableData = tableData.filter(
      ({ user_info, fields_changed, action, resource_name }) =>
        user_info.toLowerCase().indexOf(filterName.toLowerCase()) !== -1 ||
        fields_changed
          ?.join(', ')
          .toLowerCase()
          .indexOf(filterName.toLowerCase()) !== -1 ||
        action.toLowerCase().indexOf(filterName.toLowerCase()) !== -1 ||
        resource_name.toLowerCase().indexOf(filterName.toLowerCase()) !== -1
    );
  }

  if (filterUsers !== 'all') {
    tableData = tableData.filter(
      ({ user_info }) =>
        user_info.toLowerCase().indexOf(filterUsers.toLowerCase()) !== -1
    );
  }

  if (filterActions !== 'all') {
    tableData = tableData.filter(
      ({ action }) => action === filterActions.toLowerCase()
    );
  }

  if (filterStartDate && filterEndDate) {
    tableData = tableData.filter(
      ({ created_at }) =>
        isAfter(
          new Date(created_at),
          set(filterStartDate, { hours: 0, minutes: 0, seconds: 0 })
        ) &&
        isBefore(
          new Date(created_at),
          set(filterEndDate, { hours: 23, minutes: 59, seconds: 59 })
        )
    );
  }
  return tableData;
}
