import DialogSimple from '@/components/dialog/DialogSimple';
import { Typography } from '@mui/material';
// types

type DialogProps = {
  isDialogOpen: boolean;
  closeDialog: VoidFunction;
  onEnable: VoidFunction;
};

export default function BrowserNotificationDialog({ isDialogOpen, closeDialog, onEnable }: DialogProps) {
  return (
    <DialogSimple
      dialogTitle="Enable browser notifications"
      open={isDialogOpen}
      loadingButton={false}
      agreeButton
      agreeButtonText="Set it now"
      disagreeButtonText="Decide later"
      onClickButtonAgree={onEnable}
      onClickButtonClose={closeDialog}
    >
      <Typography sx={{ px: 3, py: 1.5 }}>
        Would you like to enable notifications so you won't miss out on new messages? You can go to notifcation settings
        now or decide later.
      </Typography>
    </DialogSimple>
  );
}
