import axios from '@/utils/axios';
import { BookingSession } from '../types';

export async function getSchedulerBookingSessions(
  parameter?: string
): Promise<BookingSession[]> {
  const {
    data: { bookings },
  } = await axios.get(`/bookings/sessions${parameter ?? ''}`);
  return bookings;
}

export async function getFilteredBookingSessions(
  sessionFilter: string, // 'previous' | 'today' | 'upcoming' | 'unassigned'
  parameter?: string
): Promise<BookingSession[]> {
  const {
    data: { data },
  } = await axios.get(
    `/bookings/sessions/filter/${sessionFilter}${parameter ?? ''}`
  );
  return data;
}

export async function getBookingSession(
  sessionId: number
): Promise<BookingSession> {
  const {
    data: { data },
  } = await axios.get(`/bookings/sessions/${sessionId}`);
  return data;
}

export async function getFilteredBookingSession(
  bookingId: number,
  sessionFilter: string, // 'completed' | 'today' | 'upcoming | 'unassigned',
  parameter?: string
): Promise<BookingSession[]> {
  const {
    data: { data },
  } = await axios.get(
    `/bookings/${bookingId}/sessions/filter/${sessionFilter}${parameter ?? ''}`
  );
  return data;
}

export async function updateBookingSession(
  bookingSessionId: number,
  bookingSessionDto: Partial<BookingSession>
): Promise<BookingSession> {
  const {
    data: { data },
  } = await axios.put(
    `/bookings/sessions/${bookingSessionId}`,
    bookingSessionDto
  );

  return data;
}

export async function cloneBookingSession(
  bookingSessionId: number,
  bookingSessionDto: Partial<BookingSession>
): Promise<BookingSession> {
  const {
    data: { data },
  } = await axios.post(
    `/bookings/sessions/${bookingSessionId}/clone`,
    bookingSessionDto
  );

  return data[0];
}
