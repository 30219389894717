import clsx from 'clsx';
import { useCallback } from 'react';
import {
  ChannelPreviewUIComponentProps,
  useChatContext,
} from 'stream-chat-react';

import { DirectMessagingChannelPreview } from './DirectMessagingChannelPreview';
import { TeamChannelPreview } from './TeamChannelPreview';

import { useWorkspaceController } from '@/contexts/WorkspaceController';

import type { StreamChatType } from '../../types';
import { SupportMessagingChannelPreview } from './SupportMessagingChannelPreview';
import { JobSupportChannelPreview } from './JobSupportChannelPreview';

type TeamChannelPreviewProps =
  ChannelPreviewUIComponentProps<StreamChatType> & {
    type: string;
  };

export const ChannelPreview = ({ channel, type }: TeamChannelPreviewProps) => {
  const { channel: activeChannel, setActiveChannel } =
    useChatContext<StreamChatType>();
  const { displayWorkspace } = useWorkspaceController();

  const handleClick = useCallback(() => {
    displayWorkspace('Chat');
    if (setActiveChannel) {
      setActiveChannel(channel);
    }
  }, [channel, displayWorkspace, setActiveChannel]);

  const unreadCount = channel.countUnread();

  return (
    <button
      className={clsx('channel-preview', {
        selected: channel?.id === activeChannel?.id,
      })}
      onClick={handleClick}
    >
      {type === 'team' && (
        <TeamChannelPreview
          name={
            channel?.data?.name || (channel?.data?.id as string) || 'random'
          }
          date=""
        />
      )}
      {type === 'jobSupport' && (
        <JobSupportChannelPreview
          name={
            channel?.data?.name || (channel?.data?.id as string) || 'random'
          }
          date={(channel?.data?.channelMetaData as any)?.bookingDate}
          time={(channel?.data?.channelMetaData as any)?.bookingTime}
          unreadCount={unreadCount}
        />
      )}
      {type === 'messaging' && (
        <DirectMessagingChannelPreview channel={channel} />
      )}
      {(type === 'cleanerSupport' || type === 'clientSupport') && (
        <SupportMessagingChannelPreview channel={channel} />
      )}
    </button>
  );
};
