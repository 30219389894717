import { Grid, Stack, Typography } from '@mui/material';
// components
import { Iconify } from '@/components';
// redux
import { useDispatch } from '@/stores';
import { setSelectedMenubarIconId } from '@/features/app/slices';

export default function RightSidebarTitle({ title }: { title: string }) {
  const dispatch = useDispatch();

  return (
    <Grid
      container
      item
      sx={{
        borderBottom: '1px solid #e0e0e0',
        width: '100%',
        '&.MuiGrid-root': { py: 2, pl: 2, pr: 1 },
      }}
      alignItems="center"
      justifyContent={'space-between'}
    >
      <Stack direction="row" alignItems="center" spacing={2}>
        <Typography fontSize={18} fontWeight={600}>
          {title}
        </Typography>
      </Stack>
      <Iconify
        icon={'eva:close-outline'}
        sx={{ cursor: 'pointer' }}
        onClick={() => dispatch(setSelectedMenubarIconId(0))}
        width={20}
        height={20}
      />
    </Grid>
  );
}
