import { useEffect, useState } from 'react';
import { Link as RouterLink, useParams, useNavigate } from 'react-router-dom';
import {
  Box,
  Button,
  Card,
  CardContent,
  Typography,
  Container,
  Skeleton,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import Label from '@/components/Label';

import useSettings from '@/hooks/useSettings';

import Page from '@/components/Page';
import HeaderBreadcrumbs from '@/components/HeaderBreadcrumbs';

import { ROOTS_SETTINGS, settingsPath } from '../routes/paths';
import Iconify from '@/components/Iconify';
import { getEmailTemplate } from '../api';
import { EmailTemplate } from '../types';
import { Stack } from '@mui/system';
// access
import { useGetAccess } from '@/hooks/useAccess';

export default function ViewEmailTemplates() {
  const [template, setTemplate] = useState<EmailTemplate | {}>({});
  const [isLoading, seIsLoading] = useState<boolean>(true);
  const { id } = useParams();
  const navigate = useNavigate();
  const theme = useTheme();

  const { themeStretch } = useSettings();

  useEffect(() => {
    async function callGetEmailTemplate(id: number) {
      const data: EmailTemplate = await getEmailTemplate(Number(id));
      setTemplate(data);
      seIsLoading(false);
    }
    !id && navigate(settingsPath('templates'));
    id && callGetEmailTemplate(Number(id));
  }, [id, navigate]);
  // get the access for the module
  const allow = useGetAccess('settings');
  return (
    <Page title="Settings: View Email Templates">
      <Container maxWidth={themeStretch ? false : 'lg'}>
        <HeaderBreadcrumbs
          heading={'Email Templates'}
          links={[
            { name: 'Settings', href: ROOTS_SETTINGS },
            { name: 'Email Templates', href: settingsPath('templates') },
            { name: 'View' },
            { name: `${id}` },
          ]}
          action={
            allow.canCreate && (
              <Button
                variant="contained"
                component={RouterLink}
                to={settingsPath('new-template')}
                startIcon={<Iconify icon={'eva:plus-fill'} />}
              >
                Add email template
              </Button>
            )
          }
        />
        <Card>
          {isLoading ? (
            <Box pl={2} pb={2}>
              <Skeleton sx={{ width: 0.8 }} height={40} />
              <Skeleton sx={{ width: 0.5 }} height={40} />
              <Skeleton sx={{ width: 0.7 }} height={40} />
            </Box>
          ) : (
            <CardContent>
              <Stack direction="row" spacing={4} justifyContent="space-between">
                <Typography variant="h4">
                  Subject: {(template as EmailTemplate).subject}
                </Typography>
                <Label
                  variant={theme.palette.mode === 'light' ? 'ghost' : 'filled'}
                  color={
                    ((template as EmailTemplate).active === false && 'error') ||
                    'success'
                  }
                  sx={{ textTransform: 'capitalize' }}
                >
                  {(template as EmailTemplate).active ? 'Active' : 'Disabled'}
                </Label>
              </Stack>
              <Typography
                variant="subtitle2"
                sx={{ mt: 0.5, color: 'text.secondary' }}
              >
                Description: {(template as EmailTemplate).description}
              </Typography>
              <Typography
                variant="subtitle2"
                sx={{ mt: 0.5, color: 'text.secondary' }}
              >
                Category:{' '}
                {(template as EmailTemplate).email_template_category.name}
              </Typography>

              <Box sx={{ p: 2, mt: 3, boxShadow: theme.customShadows.z1 }}>
                <div
                  dangerouslySetInnerHTML={{
                    __html: (template as EmailTemplate).body,
                  }}
                />
              </Box>
            </CardContent>
          )}
        </Card>
      </Container>
    </Page>
  );
}
