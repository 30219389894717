import { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
//
import { Link, Stack, Alert, IconButton, InputAdornment } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { Iconify } from '@/components';
import { FormProvider, RHFTextField } from '@/components/hook-form';
// hooks + utils
import useAuth from '@/hooks/useAuth';
import useIsMountedRef from '@/hooks/useIsMountedRef';
import { authPath } from '../routes/paths';
import { LOGIN_FORM_SCHEMA, LOGIN_FORM_DEFAULT_VALUES } from '../utils';
// types
import { LoginFormValuesProps } from '../types';
// ----------------------------------------------------------------------

export default function LoginForm() {
  const { login } = useAuth();
  const isMountedRef = useIsMountedRef();
  const [showPassword, setShowPassword] = useState(false);

  const handleToggleShowPassword = () => setShowPassword(!showPassword);

  const methods = useForm<LoginFormValuesProps>({
    resolver: yupResolver(LOGIN_FORM_SCHEMA),
    defaultValues: LOGIN_FORM_DEFAULT_VALUES,
  });

  const {
    reset: resetFormValues,
    setError,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = methods;

  const onSubmitLogin = async (data: LoginFormValuesProps) => {
    try {
      await login(data.email, data.password);
    } catch (error) {
      resetFormValues();

      if (isMountedRef.current) {
        setError('afterSubmit', { ...error, message: error.message });
      }
    }
  };

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmitLogin)}>
      <Stack spacing={2}>
        {!!errors.afterSubmit && (
          <Alert severity="error">{errors.afterSubmit.message}</Alert>
        )}
        <RHFTextField
          name="email"
          label="Email address"
          inputProps={{
            autoComplete: 'username',
          }}
          InputLabelProps={{
            shrink: true,
          }}
        />

        <RHFTextField
          name="password"
          label="Password"
          type={showPassword ? 'text' : 'password'}
          inputProps={{
            autoComplete: 'current-password',
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={handleToggleShowPassword} edge="end">
                  <Iconify
                    icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'}
                  />
                </IconButton>
              </InputAdornment>
            ),
          }}
          InputLabelProps={{
            shrink: true,
          }}
        />

        <Link
          component={RouterLink}
          variant="subtitle2"
          to={authPath('reset')}
          sx={{ alignSelf: 'flex-end' }}
        >
          Forgot password?
        </Link>

        <LoadingButton
          fullWidth
          size="large"
          type="submit"
          variant="contained"
          loading={isSubmitting}
        >
          Login
        </LoadingButton>
      </Stack>
    </FormProvider>
  );
}
