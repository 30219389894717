import { memo } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

type colors =
  | 'inherit'
  | 'primary'
  | 'secondary'
  | 'success'
  | 'error'
  | 'info'
  | 'warning';

type Props = {
  dialogTitle: string;
  dialogText: string;
  dialogDisagree: VoidFunction;
  dialogAgree: VoidFunction;
  handleCloseDialog: (
    event?: {},
    reason?: 'backdropClick' | 'escapeKeyDown'
  ) => void;
  isDialogOpen: boolean;
  setIsDialogOpen: Function;
  agreeButtonText?: string;
  disagreeButtonText?: string;
  agreeButtonColor?: colors;
  disagreeButtonColor?: colors;
};
// color options: https://mui.com/material-ui/customization/palette/#adding-new-colors
// handleCloseDialog controls the visibility of the Dialog
// by setting handleCloseDialog setIsDialogOpen(false);
// This funciton needs to be created in the parent component

function AlertDialog({
  dialogTitle,
  dialogText,
  dialogAgree,
  dialogDisagree,
  handleCloseDialog,
  isDialogOpen,
  agreeButtonText,
  disagreeButtonText,
  agreeButtonColor,
  disagreeButtonColor,
}: Props) {
  const handleDisagree = () => {
    // if you need this function only to close the Dialog, then
    // create a function in parent component with a simple return
    // ie: const dialogDisagree = () => { return }
    handleCloseDialog();
    dialogDisagree();
  };

  const handleAgree = () => {
    handleCloseDialog();
    dialogAgree();
  };

  return (
    <>
      <Dialog
        open={isDialogOpen}
        onClose={handleCloseDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth={true}
        maxWidth="sm"
      >
        <Box>
          <DialogTitle id="alert-dialog-title" sx={{ mb: 2 }}>
            {dialogTitle}
          </DialogTitle>

          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {dialogText}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleDisagree} color={disagreeButtonColor}>
              {disagreeButtonText ? disagreeButtonText : 'Disagree'}
            </Button>
            <Button
              onClick={handleAgree}
              variant="contained"
              color={agreeButtonColor}
              autoFocus
            >
              {agreeButtonText ? agreeButtonText : 'Agree'}
            </Button>
          </DialogActions>
        </Box>
      </Dialog>
    </>
  );
}

export default memo(AlertDialog);
