import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { isEmpty } from 'lodash';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
//
import {
  Stack,
  IconButton,
  InputAdornment,
  Alert,
  TextField,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { Iconify } from '@/components';
import { FormProvider, RHFTextField } from '@/components/hook-form';
// api
import { setNewPassword } from '../api';
// hooks + utils
import { authPath } from '../routes/paths';
import { useSnackbarMsg } from '@/hooks/useSnackbarMsg';
import { NEW_PASSWORD_SCHEMA, NEW_PWD_FORM_DEFAULT_VALUES } from '../utils';
// types
import { NewPasswordFormValuesProps } from '../types';

// ----------------------------------------------------------------------

export default function NewPasswordForm() {
  const navigate = useNavigate();
  const { successMsg, errorMsg } = useSnackbarMsg();
  const email = sessionStorage.getItem('email-recovery');
  const [showPassword, setShowPassword] = useState(false);
  const [message, setMessage] = useState<string>('');

  const methods = useForm({
    mode: 'all',
    resolver: yupResolver(NEW_PASSWORD_SCHEMA),
    defaultValues: NEW_PWD_FORM_DEFAULT_VALUES,
  });

  const {
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const onSubmitNewPassword = async ({
    new_password,
    confirm_password,
  }: NewPasswordFormValuesProps) => {
    try {
      const dataValues = await setNewPassword(new_password, confirm_password);

      if (
        Object.values(dataValues).includes('Password successfully updated.')
      ) {
        successMsg(dataValues.message);
        sessionStorage.clear(); // delete email and verification code
        navigate(authPath('login'));
      }
    } catch (error) {
      errorMsg(error, "Couldn't update password");
      setMessage(error.message);
    }
  };

  return (
    <FormProvider
      methods={methods}
      onSubmit={handleSubmit(onSubmitNewPassword)}
    >
      <Stack spacing={3}>
        {!isEmpty(message) && (
          <Alert sx={{ textAlign: 'left' }} severity="error">
            {message}
          </Alert>
        )}

        {/* HIDDEN USERNAME FIELD */}
        <TextField // https://www.chromium.org/developers/design-documents/create-amazing-password-forms/
          id="username"
          label="Username"
          name="username"
          type="text"
          variant="outlined"
          fullWidth
          margin="normal"
          value={email}
          style={{ display: 'none' }}
          inputProps={{
            autoComplete: 'username',
          }}
        />

        <RHFTextField
          name="new_password"
          label="Password"
          type={showPassword ? 'text' : 'password'}
          inputProps={{
            autoComplete: 'new-password',
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  onClick={() => setShowPassword(!showPassword)}
                  edge="end"
                >
                  <Iconify
                    icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'}
                  />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />

        <RHFTextField
          name="confirm_password"
          label="Retype Password"
          type={showPassword ? 'text' : 'password'}
          inputProps={{
            autoComplete: 'new-password',
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  onClick={() => setShowPassword(!showPassword)}
                  edge="end"
                >
                  <Iconify
                    icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'}
                  />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <LoadingButton
          fullWidth
          size="large"
          type="submit"
          variant="contained"
          loading={isSubmitting}
        >
          Change Password
        </LoadingButton>
      </Stack>
    </FormProvider>
  );
}
