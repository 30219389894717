import { useState } from 'react';
//
import { Card, Stack, TextField } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { SectionTitle } from '@/features/users/shared/components';
import { resetUserPassword } from '../api';
import { useSnackbarMsg } from '@/hooks/useSnackbarMsg';

export default function UserPasswordReset({
  currentEmail,
}: {
  currentEmail: string | undefined;
}) {
  const { successMsg, errorMsg } = useSnackbarMsg();
  const [email, setEmail] = useState(currentEmail ?? '');
  const [isLoading, setIsLoading] = useState(false);
  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
  };
  const handleSubmitReset = async () => {
    if (!email || email === '') {
      errorMsg('Email is required');
      return;
    }
    setIsLoading(true);
    try {
      await resetUserPassword(email);
      successMsg('Password reset link sent');
    } catch (error) {
      errorMsg(error, `There was an error while sending link`);
    } finally {
      setIsLoading(false);
    }
  };
  return (
    <Stack direction="column" spacing={2} component={Card} sx={{ p: 3 }}>
      <SectionTitle title="Reset Password" />
      <TextField
        label="Email"
        value={email}
        onChange={handleEmailChange}
        size="small"
      />
      <LoadingButton
        variant="contained"
        onClick={handleSubmitReset}
        loading={isLoading}
      >
        Send Reset Link
      </LoadingButton>
    </Stack>
  );
}
