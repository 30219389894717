import axios from '@/utils/axios';
import { NotificationResponse, NotificationObjectProps } from '../types';

export async function getNotificationRequest(): Promise<{
  notifications: NotificationObjectProps[];
  totalUnseen: number;
}> {
  const {
    data: { data: notifications, totalUnseen },
  }: NotificationResponse = await axios.get('/notifications');
  return { notifications, totalUnseen };
}

export async function getNotificationLimitedRequest(
  numberOfNotifications: number
): Promise<{
  notifications: NotificationObjectProps[];
  totalUnseen: number;
}> {
  const {
    data: { data: notifications, totalUnseen },
  }: NotificationResponse = await axios.get(
    `/notifications/limit/${numberOfNotifications}`
  );

  return { notifications, totalUnseen };
}

export async function getNotificationUpdateRequest(id: number): Promise<{}> {
  const {
    data: { data },
  }: any = await axios.get(`/notifications/${id}`);
  return { data };
}
