export const DASHBOARD_USERS_TABLE_HEAD = [
  { id: 'emptyCell' },
  { id: 'first_name', label: 'Name', align: 'left' },
  { id: 'email', label: 'Email', align: 'left' },
  { id: 'user_roles', label: 'Roles', align: 'left' },
  { id: 'is_chat_user', label: 'Chat', align: 'left' },
  { id: 'created_at', label: 'Created at', align: 'left' },
  { id: 'status.display', label: 'Status', align: 'left' },
  { id: 'actionBtns' },
];
