import { isAfter, isBefore, set } from 'date-fns';
import { uniq } from 'lodash';
import { NotificationObjectProps } from '../types';

// need mapping type and entity of notification
export function fLinkBuild(type: string, id: string) {
  return `/${type}/${id}`;
}
export function getLables(notifications: NotificationObjectProps[]) {
  return uniq(
    notifications.map(
      ({ notification }: NotificationObjectProps) =>
        notification.notification_type.entity_type.table
    )
  );
}

export function applySortFilter({
  tableData,
  filterName,
  filterStatus,
  filterTable,
  filterFrom,
  filterTo,
}: {
  tableData: NotificationObjectProps[];
  filterName: string;
  filterStatus: string;
  filterTable: string;
  filterFrom: Date | null;
  filterTo: Date | null;
}) {
  if (filterName) {
    tableData = tableData.filter(
      (item: Record<string, any>) =>
        item.name.toLowerCase().indexOf(filterName.toLowerCase()) !== -1
    );
  }
  if (filterStatus !== 'all') {
    tableData = tableData.filter(
      ({ seen }: NotificationObjectProps) => seen === (filterStatus === 'read')
    );
  }
  if (filterTable !== 'all') {
    tableData = tableData.filter(
      ({
        notification: {
          notification_type: {
            entity_type: { table },
          },
        },
      }: NotificationObjectProps) => table === filterTable
    );
  }
  if (filterFrom) {
    tableData = tableData.filter(
      ({ notification: { created_at } }: NotificationObjectProps) =>
        isAfter(
          new Date(created_at),
          set(filterFrom, { hours: 0, minutes: 0, seconds: 0 })
        )
    );
  }
  if (filterTo) {
    tableData = tableData.filter(
      ({ notification: { created_at } }: NotificationObjectProps) =>
        isBefore(
          new Date(created_at),
          set(filterTo, { hours: 23, minutes: 59, seconds: 59 })
        )
    );
  }

  return tableData;
}
