export const MONTHS = [
  { id: 1, full_name: 'January', short_name: 'Jan' },
  { id: 2, full_name: 'February', short_name: 'Feb' },
  { id: 3, full_name: 'March', short_name: 'Mar' },
  { id: 4, full_name: 'April', short_name: 'Apr' },
  { id: 5, full_name: 'May', short_name: 'May' },
  { id: 6, full_name: 'June', short_name: 'Jun' },
  { id: 7, full_name: 'July', short_name: 'Jul' },
  { id: 8, full_name: 'August', short_name: 'Aug' },
  { id: 9, full_name: 'September', short_name: 'Sep' },
  { id: 10, full_name: 'October', short_name: 'Oct' },
  { id: 11, full_name: 'November', short_name: 'Nov' },
  { id: 12, full_name: 'December', short_name: 'Dec' },
];

export const DAYS_OF_WEEK = [
  {
    id: 1,
    full_name: 'Monday',
    name: 'Monday',
    short_name: 'Mon',
    recurrence_short_name: 'MO',
  },
  {
    id: 2,
    full_name: 'Tuesday',
    name: 'Tuesday',
    short_name: 'Tue',
    recurrence_short_name: 'TU',
  },
  {
    id: 3,
    full_name: 'Wednesday',
    name: 'Wednesday',
    short_name: 'Wed',
    recurrence_short_name: 'WE',
  },
  {
    id: 4,
    full_name: 'Thursday',
    name: 'Thursday',
    short_name: 'Thu',
    recurrence_short_name: 'TH',
  },
  {
    id: 5,
    full_name: 'Friday',
    name: 'Friday',
    short_name: 'Fri',
    recurrence_short_name: 'FR',
  },
  {
    id: 6,
    full_name: 'Saturday',
    name: 'Saturday',
    short_name: 'Sat',
    recurrence_short_name: 'SA',
  },
  {
    id: 7,
    full_name: 'Sunday',
    name: 'Sunday',
    short_name: 'Sun',
    recurrence_short_name: 'SU',
  },
];
