import { Skeleton, Grid } from '@mui/material';

export default function SchedulerPageSkeleton() {
  return (
    <>
      <Skeleton
        variant="rectangular"
        width="100%"
        height={100}
        sx={{ mb: 2 }}
      />
      <Grid container spacing={3}>
        <Grid item container xs={12} md={10} spacing={3} direction="column">
          {[...new Array(6)].map((_, index) => (
            <Grid item key={index}>
              <Skeleton variant="rectangular" width="100%" height={80} />
            </Grid>
          ))}
        </Grid>
        <Grid item container xs={12} md={2}>
          <Skeleton variant="rectangular" width="100%" height={600} />
        </Grid>
      </Grid>
    </>
  );
}
