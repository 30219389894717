import { isEqual, isPast, isAfter } from 'date-fns';
import { Booking } from '@/features/bookings-management/types';
import { tableSortFilter } from '@/utils/arrayProcessing';

export const CLIENT_BOOKINGS_TABLE_HEAD = [
  { id: 'emptyCell' },
  { id: 'id', label: 'Booking ID', align: 'left' },
  { id: 'date', label: 'Date & time', align: 'left' },
  { id: 'total_amount', label: 'Total amount', align: 'left' },
  {
    id: 'booking_service.service_name',
    label: 'Booking service',
    align: 'left',
  },
  { id: 'service_variant.name', label: 'Service variant', align: 'left' },
  { id: 'address.formatted_address', label: 'Address', align: 'left' },
  { id: 'booking_status.display', label: 'Booking status', align: 'left' },
  { id: 'cleaner', label: 'Cleaner', align: 'left' },
  { id: 'actionButtons', label: '', align: 'left' },
];

export function applySortFilterClientBookings({
  tableData,
  comparator,
  searchFieldValue,
  filterStatus,
}: {
  tableData: Booking[];
  comparator: (a: any, b: any) => number;
  searchFieldValue: string;
  filterStatus: string;
}) {
  tableData = tableSortFilter(tableData, comparator);
  const searchFieldLowerCase = searchFieldValue.toLowerCase();

  if (searchFieldValue) {
    tableData = tableData.filter((booking) => {
      const matchesClientDetails =
        booking.id.toString().indexOf(searchFieldValue) !== -1 ||
        booking.address?.formatted_address
          .toLowerCase()
          .indexOf(searchFieldLowerCase) !== -1 ||
        booking.address?.postcode
          .toLowerCase()
          .indexOf(searchFieldLowerCase) !== -1 ||
        booking.address?.postcode
          .replace(/\s/g, '')
          .toLowerCase()
          .indexOf(searchFieldLowerCase) !== -1;

      const matchesCleanerName = booking.booking_cleaners?.some((cleaner) => {
        const cleanerFullName = `${cleaner.first_name} ${cleaner.last_name}`;
        return (
          cleanerFullName.toLowerCase().indexOf(searchFieldLowerCase) !== -1
        );
      });

      const matchesAmount =
        (booking.total_amount ?? 0).toString().indexOf(searchFieldValue) !== -1;

      return matchesClientDetails || matchesCleanerName || matchesAmount;
    });
  }
  // FILTER TODAY
  if (filterStatus.toLowerCase() !== 'all') {
    if (filterStatus.toLowerCase() === 'today') {
      tableData = tableData.filter((item) =>
        isEqual(new Date(item.date), new Date())
      );
    }
    if (filterStatus.toLowerCase() === 'past') {
      tableData = tableData.filter((item) => isPast(new Date(item.date)));
    }
    if (filterStatus.toLowerCase() === 'upcoming') {
      tableData = tableData.filter((item) =>
        isAfter(new Date(item.date), new Date())
      );
    }
  }
  return tableData;
}
