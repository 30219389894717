import { useFormContext, useWatch } from 'react-hook-form';
// mui
import { TableRow, TableCell, Typography } from '@mui/material';
import { RHFCheckbox } from '.';
// types
import type { RolesModules } from '../types/roles';

type Props = {
  roleModule: RolesModules;
};

export default function PermissionsTableRow({ roleModule }: Props) {
  const { control } = useFormContext();
  const { selected_permissions } = useWatch({ control });

  return (
    <TableRow hover>
      <TableCell>
        <Typography variant="subtitle2" noWrap>
          {roleModule.name}
        </Typography>
      </TableCell>

      {roleModule.permissions.map((permission) => (
        <TableCell align="left" key={permission.ref}>
          {
            <RHFCheckbox
              label=""
              name={permission.ref}
              selectedPermissions={selected_permissions}
              value={permission.ref}
              disabled={!permission.show}
            />
          }
        </TableCell>
      ))}
    </TableRow>
  );
}
