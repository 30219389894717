import { createSlice } from '@reduxjs/toolkit';

// =======================
//      INITIAL STATE
// =======================

const initialRightSidebarState: {
  selectedMenubarIconId: number;
} = {
  selectedMenubarIconId: 0,
};

// =======================
//      CREATE SLICE
// =======================

export const rightSidebarSlice = createSlice({
  name: 'right-sidebar',
  initialState: initialRightSidebarState,
  reducers: {
    setSelectedMenubarIconId(state, { payload }) {
      state.selectedMenubarIconId = payload;
    },
  },
});

// =======================

export const { setSelectedMenubarIconId } = rightSidebarSlice.actions;

export default rightSidebarSlice.reducer;
