import { useParams } from 'react-router';
import { useQuery } from '@tanstack/react-query';
//
import {
  Box,
  Card,
  FormControlLabel,
  Switch,
  Table,
  TableBody,
  TableContainer,
  TablePagination,
} from '@mui/material';
//
import { Scrollbar } from '@/components';
import {
  TableEmptyRows,
  TableHeadCustom,
  TableNoData,
} from '@/components/table';
import { ProviderPriceDialog } from '..';
// api
import { getUserCustomPrices } from '@/features/users/shared/api';
// utils + hooks
import useTable, { emptyRows, getComparator } from '@/hooks/useTable';
import { useGetAccess } from '@/hooks/useAccess';
import { useSnackbarMsg } from '@/hooks/useSnackbarMsg';
import {
  applySortFilterProviderPrices,
  PROVIDER_CUSTOM_PRICES_TABLE_HEAD,
} from '../../utils';
import ProviderPricesTableRow from './ProviderPricesTableRow';
import { SkeletonLoadingTable } from '@/components/skeleton';
//
type ProviderPricesPricesProps = {
  isCustomPriceDialogOpen: boolean;
  closeCustomPriceDialog: VoidFunction;
  openCustomPriceDialog: VoidFunction;
};
export default function ProviderPrices({
  isCustomPriceDialogOpen,
  closeCustomPriceDialog,
  openCustomPriceDialog,
}: ProviderPricesPricesProps) {
  const allow = useGetAccess('users'); // TODO: double check this
  const { errorMsg } = useSnackbarMsg();
  const { userId } = useParams();
  const {
    dense,
    page,
    order,
    orderBy,
    rowsPerPage,
    onSort,
    onChangeDense,
    onChangePage,
    onChangeRowsPerPage,
  } = useTable({
    defaultOrderBy: 'end_date',
    defaultOrder: 'asc',
    defaultDense: false,
  });

  // =================
  //      QUERIES
  // =================

  // GET
  const { data: providerCustomPrices, isLoading: isPricesLoading } = useQuery({
    queryKey: ['providerCustomPrices'],
    queryFn: () => getUserCustomPrices(String(userId)),
    onError: (error) => errorMsg(error, 'Could not fetch custom prices'),
    enabled: !!userId,
  });

  // ======================
  //     FILTER, UPDATE
  // ======================

  const dataFiltered = applySortFilterProviderPrices({
    tableData: providerCustomPrices || [],
    comparator: getComparator(order, orderBy),
  });

  const isNotFound = !dataFiltered.length;
  const denseHeight = dense ? 52 : 72;
  const showSkeleton = isPricesLoading;

  return (
    <Card sx={{ px: 3, py: 1.5 }}>
      {showSkeleton ? (
        <SkeletonLoadingTable />
      ) : (
        <>
          <Scrollbar>
            <TableContainer>
              <Table size={dense ? 'small' : 'medium'}>
                <TableHeadCustom
                  order={order}
                  orderBy={orderBy}
                  headLabel={PROVIDER_CUSTOM_PRICES_TABLE_HEAD}
                  rowCount={dataFiltered.length}
                  onSort={onSort}
                />
                <TableBody>
                  {dataFiltered
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((price) => (
                      <ProviderPricesTableRow
                        key={price.id}
                        row={price}
                        canUpdate={allow.canUpdate}
                      />
                    ))}

                  <TableEmptyRows
                    height={denseHeight}
                    emptyRows={emptyRows(
                      page,
                      rowsPerPage,
                      dataFiltered.length
                    )}
                  />

                  <TableNoData
                    isNotFound={isNotFound}
                    title="No custom prices available"
                  />
                </TableBody>
              </Table>
            </TableContainer>
          </Scrollbar>
          <Box sx={{ position: 'relative' }}>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={(dataFiltered || []).length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={onChangePage}
              onRowsPerPageChange={onChangeRowsPerPage}
            />

            <FormControlLabel
              control={<Switch checked={dense} onChange={onChangeDense} />}
              label="Dense"
              sx={{ px: 3, py: 1.5, top: 0, position: { md: 'absolute' } }}
            />
          </Box>

          {/* This dialog is controlled by a button in HeaderBreadcrumbs */}
          <ProviderPriceDialog
            isCustomPriceDialogOpen={isCustomPriceDialogOpen}
            closeCustomPriceDialog={closeCustomPriceDialog}
          />
        </>
      )}
    </Card>
  );
}
