import axios from '@/utils/axios';
import { DayOff, DayOffDto } from '../types';

export async function getProviderAllDaysOff(userID: string): Promise<DayOff[]> {
  const {
    data: { data },
  } = await axios.get(`/users/${userID}/offdays`);
  return data;
}

export async function getProviderDayOff(
  userID: string,
  dayOffID: string
): Promise<DayOff> {
  const {
    data: { data },
  } = await axios.get(`/users/${userID}/offdays/${dayOffID}`);
  return data;
}

export async function createProviderTimeOff(
  userID: string,
  dayOffData: DayOffDto
): Promise<any> {
  const { data } = await axios.post(`/users/${userID}/offdays`, dayOffData);
  return data;
}

export async function updateProviderTimeOff(
  userID: string,
  dayOffID: string,
  dayOffData: DayOffDto
): Promise<any> {
  const {
    data: { data },
  } = await axios.put(`/users/${userID}/offdays/${dayOffID}`, dayOffData);
  return data;
}

export async function deleteProviderTimeoff(
  userID: string,
  dayOffID: string
): Promise<void> {
  await axios.delete<void>(`/users/${userID}/offdays/${dayOffID}`);
}
