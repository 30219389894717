// utils
import { lightFormat } from 'date-fns';
import { TransformedBooking } from '../types';
import { User } from '@/features/users/shared/types';
import { MbscDateType } from '@mobiscroll/react';

// types

export function applySortFilterCleaningJobs({
  bookingSessions,
  cleaners,
  cleanerId, // comparator,
}: {
  bookingSessions: TransformedBooking[] | undefined;
  cleaners: User[] | undefined;
  cleanerId: number | null;
  // comparator: (a: any, b: any) => number;
}) {
  if (!bookingSessions || !cleaners) return [];
  // tableData = tableSortFilter(tableData, comparator);

  if (cleanerId) {
    bookingSessions = bookingSessions.filter(
      (session) => session.resource === cleanerId
    );
    cleaners = cleaners.filter((cleaner) => cleaner.id === cleanerId);
  }

  return [bookingSessions, cleaners];
}

export const getNextPrevDay = (date: Date, param: string): string => {
  const dayToReturn = new Date(date);
  const number = param === 'next' ? 1 : -1;
  dayToReturn.setDate(date.getDate() + number);
  return lightFormat(new Date(dayToReturn), 'yyyy-MM-dd');
};

export const formatMbscDateType = (mbscDate: MbscDateType): Date => {
  if (mbscDate instanceof Date) {
    return mbscDate;
  }
  if (typeof mbscDate === 'string' || typeof mbscDate === 'number') {
    return new Date(mbscDate);
  }
  throw new Error('Invalid MbscDateType');
};
