import { Quill } from 'react-quill';
const BaseImage = Quill.import('formats/image');

const WHITE_STYLE = ['margin', 'display', 'float'];

// appending missing attributes for image after rendering 
// in the editor
class QuillImage extends BaseImage {
  static create(value: any) {
    if (typeof value == 'string') {
      return super.create(value);
    } else {
      return value;
    }
  }

  static value(domNode: any) {
    return domNode;
  }

  sanitize_style(style: any) {
    let style_arr = style.split(';');
    let allow_style = '';
    style_arr.forEach((v: any) => {
      if (WHITE_STYLE.indexOf(v.trim().split(':')[0]) !== -1) {
        allow_style += v + ';';
      }
    });
    return allow_style;
  }
}

export default QuillImage;
