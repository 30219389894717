import {
  RHFDatepicker,
  RHFMobiscrollDateTimePicker,
  RHFMultipleSelectCheckmarks,
  RHFTextField,
} from '@/components/hook-form';
import { Box, MenuItem, Stack, useTheme } from '@mui/material';
import { TimeoffRequestNotes } from '../..';
// utils + hooks
import {
  RECURRING_TYPES,
  timePickerSx,
} from '@/features/users/providers/utils';
import { DAYS_OF_WEEK } from '@/utils/months-and-days';
// types
import { MbscDatepickerOptions } from '@mobiscroll/react';

export default function TimeOffRecurringRequest() {
  const theme = useTheme();

  const timePickerConfig = {
    controls: ['timegrid'],
    display: 'anchored',
    touchUi: true,
    minTime: '06:00',
    maxTime: '23:00',
    stepMinute: 60,
    endIcon: 'clock',
    animation: 'pop',
    timeFormat: 'HH:mm',
  };

  return (
    <>
      <Stack spacing={2}>
        <Box sx={timePickerSx(theme)}>
          <RHFMobiscrollDateTimePicker
            name="start_date"
            label="Start time"
            mbscProps={timePickerConfig as MbscDatepickerOptions}
          />
        </Box>
        <Box sx={timePickerSx(theme)}>
          <RHFMobiscrollDateTimePicker
            name="end_date"
            label="End time"
            mbscProps={timePickerConfig as MbscDatepickerOptions}
          />
        </Box>
      </Stack>

      <Stack spacing={2}>
        <RHFTextField
          label="Recurring occurence"
          name="recurrence_repeat_string"
          select
        >
          {RECURRING_TYPES.map((option) => (
            <MenuItem key={option.id} value={option.name.toLowerCase()}>
              {option.name}
            </MenuItem>
          ))}
        </RHFTextField>
        <Box sx={{ maxWidth: 250 }}>
          <RHFMultipleSelectCheckmarks
            name="recurrence_days_of_week"
            label="Days of the week"
            options={DAYS_OF_WEEK}
          />
        </Box>
      </Stack>

      <Stack spacing={2}>
        <RHFDatepicker
          label="Recurrence start date"
          name="recurrence_from_date"
          size="small"
        />
        <RHFDatepicker
          label="Recurrence end date"
          name="recurrence_until"
          size="small"
        />
      </Stack>

      <TimeoffRequestNotes />
    </>
  );
}
