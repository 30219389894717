import { TableRow, TableCell } from '@mui/material';
import { NotificationItem } from '.';
import { NotificationObjectProps } from '../types';

type Props = {
  row: NotificationObjectProps;
};

export default function NotificationTableRow({ row }: Props) {
  return (
    <TableRow
      hover
      sx={{
        ...(!row.seen && {
          bgcolor: 'action.selected',
        }),
      }}
    >
      <TableCell sx={{ p: '0!important' }}>
        <NotificationItem notificationObject={row} />
      </TableCell>
    </TableRow>
  );
}
