import { Route, Routes } from 'react-router-dom';
//
import AuthGuard from '@/guards/AuthGuard';
import RoleBasedGuard from '@/guards/RoleBasedGuard';
//
import DashboardLayout from '@/layouts/dashboard';
//
import { JobScheduler } from '../pages';
//
import { FeatureRouteObject } from '@/types';

const JOB_SCHEDULER_ROUTES: FeatureRouteObject[] = [
  {
    path: '',
    component: <JobScheduler />,
    index: true,
    roles: [],
  },
];

export const JobSchedulerRoutes = () => (
  <Routes>
    <Route
      element={
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      }
    >
      {JOB_SCHEDULER_ROUTES.map(({ path, component, roles, index }) => (
        <Route
          key={index ? 'index' : path}
          path={path}
          index={index}
          element={<RoleBasedGuard roles={roles}>{component}</RoleBasedGuard>}
        />
      ))}
    </Route>
  </Routes>
);
