import { Route, Routes } from 'react-router-dom';
import DashboardLayout from '@/layouts/dashboard';
import AuthGuard from '@/guards/AuthGuard';

import { PATH_CLIENT_MANAGEMENT } from './paths';
import { ClientCreateEdit, ClientList } from '../pages';
import RoleBasedGuard from '@/guards/RoleBasedGuard';

const CLIENT_MANAGEMENT_ROUTES = [
  {
    path: '',
    roles: ['clients.view'],
    component: <ClientList />,
    index: true,
  },
  {
    path: PATH_CLIENT_MANAGEMENT.new,
    roles: ['clients.create'],
    component: <ClientCreateEdit />,
    index: false,
  },
  {
    path: PATH_CLIENT_MANAGEMENT.edit,
    roles: ['clients.update'],
    component: <ClientCreateEdit />,
    index: false,
  },
];

export const ClientManagementRoutes = () => (
  <Routes>
    <Route
      element={
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      }
    >
      {CLIENT_MANAGEMENT_ROUTES.map((route, index) => (
        <Route
          key={index}
          path={route.path}
          index={route.index}
          element={
            <RoleBasedGuard roles={route.roles}>
              {route.component}
            </RoleBasedGuard>
          }
        />
      ))}
    </Route>
  </Routes>
);
