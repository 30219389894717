import axios from '@/utils/axios';
import { UserCustomPrice } from '@/features/users/shared/types';

export const getUserCustomPrices = async (
  clientId: string
): Promise<UserCustomPrice[]> => {
  const {
    data: { data },
  } = await axios.get(`/users/${clientId}/custom-prices`);
  return data;
};

export const getUserCustomPrice = async (
  clientId: string,
  customPriceId: string
): Promise<UserCustomPrice> => {
  const {
    data: { data },
  } = await axios.get(`/users/${clientId}/custom-prices/${customPriceId}`);
  return data;
};

export const createUserCustomPrice = async (
  clientId: string,
  customPriceDto: Partial<UserCustomPrice>
) => {
  const { data } = await axios.post(
    `/users/${clientId}/custom-prices`,
    customPriceDto
  );
  return data;
};

export const updateUserCustomPrice = async (
  clientId: string,
  customPriceId: string,
  customPriceDto: Partial<UserCustomPrice>
) => {
  const { data } = await axios.put(
    `/users/${clientId}/custom-prices/${customPriceId}`,
    customPriceDto
  );
  return data;
};

export const deleteUserCustomPrice = async (
  clientId: string,
  customPriceId: string
) => {
  await axios.delete(`/users/${clientId}/custom-prices/${customPriceId}`);
};
