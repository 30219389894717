import numeral from 'numeral';
import 'numeral/locales/en-gb';
numeral.locale('en-gb');

export function fCurrency(number: string | number) {
  return '£' + numeral(number).format(Number.isInteger(number) ? '£0,0.00' : '£0,0.00');
}

export function fCurrencyWithExtra(hours: string | number | null, price: number) {
  if (hours)
    return numeral(price * parseFloat(hours as string)).format(
      Number.isInteger(price * parseFloat(hours as string)) ? '£0,0' : '£0,0.00'
    );
}

export function fPercent(number: number) {
  return numeral(number / 100).format('0.0%');
}

export function fNumber(number: string | number) {
  return numeral(number).format();
}

export function fShortenNumber(number: string | number) {
  return numeral(number).format('0.00a').replace('.00', '');
}

export function fData(number: string | number) {
  return numeral(number).format('0.0 b');
}

function limit(val: string, max: string) {
  if (val.length === 1 && val[0] > max[0]) {
    val = '0' + val;
  }

  if (val.length === 2) {
    if (Number(val) === 0) {
      val = '01';
    } else if (val > max) {
      val = max;
    }
  }

  return val;
}

export function formatCardExpiry(val: string) {
  let month = limit(val.substring(0, 2), '12');
  let year = val.substring(2, 4);

  return month + (year.length ? '/' + year : '');
}
