import { Box, Divider, Stack, Skeleton } from '@mui/material';

export default function BookingsManagementSkeleton() {
  return (
    <>
      <Stack direction="row" spacing={2} sx={{ p: 2 }}>
        {[...new Array(3)].map((_, i) => (
          <Skeleton sx={{ width: 0.98 }} height={60} key={i} />
        ))}
      </Stack>
      <Divider />
      <Box pl={2} pb={2} pt={1}>
        <Skeleton sx={{ width: 0.98 }} height={100} />
      </Box>
      <Divider />
      <Box pl={2} pb={2} pt={2}>
        {[...new Array(5)].map((_, i) => (
          <Skeleton sx={{ width: 0.98 }} height={60} key={i} />
        ))}
      </Box>
    </>
  );
}
