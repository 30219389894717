import { format, isPast, lightFormat } from 'date-fns';
import { Datepicker } from '@mobiscroll/react';
// @mui
import { MenuItem, Stack, TextField, useTheme } from '@mui/material';
// components
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import CustomErrorMessage from './error-message/CustomErrorMessage';
// utils
import { timePickerSx } from '@/features/bookings-management/utils';
// redux
import { useDispatch, useSelector } from '@/stores';
import {
  selectBookingStatus,
  addBookingDate,
  addBookingTime,
  selectedVariant,
  setValidationError,
} from '@/features/bookings-management/slices';
// types
import { StatusBase, ServiceVariant } from '@/types';
import { useParams } from 'react-router';

interface BookingIdStatusTimeBarProps {
  bookingStatuses: StatusBase[];
}

export default function BookingIdStatusTimeBar({
  bookingStatuses,
}: BookingIdStatusTimeBarProps) {
  const theme = useTheme();
  const dispatch = useDispatch();
  const { id } = useParams();
  const {
    date,
    time,
    booking_id,
    booking_status_id,
    created_at,
    validationErrors,
  } = useSelector((state) => state.booking);
  const choosenVariant: ServiceVariant = useSelector(selectedVariant);

  const lightFormattedDate = lightFormat(new Date(date!), 'yyyy-MM-dd');

  const timePickerValue =
    time && date ? `${lightFormattedDate}T${time}` : undefined;

  const isRecurring = choosenVariant.id === 2 || choosenVariant.id === 3;

  return (
    <Stack
      spacing={2}
      direction={{ xs: 'column', sm: 'row' }}
      sx={{ p: 3, bgcolor: 'background.neutral' }}
    >
      <TextField
        disabled
        label="Booking ID"
        value={booking_id}
        size="small"
        fullWidth
      />

      <TextField
        disabled
        label="Creation Date"
        value={format(new Date(created_at), 'dd-MMM-yyyy, EEEE')}
        size="small"
        fullWidth
      />

      <Stack direction="column" spacing={1} width="100%">
        <TextField
          select
          fullWidth
          size="small"
          label="Booking Status"
          value={booking_status_id}
          InputLabelProps={{ shrink: true }}
          SelectProps={{ native: false, sx: { textTransform: 'capitalize' } }}
          onChange={(e) => {
            dispatch(selectBookingStatus(Number(e.target.value)));
            dispatch(
              setValidationError({ field: 'booking_status_id', error: null })
            );
          }}
          error={!!validationErrors.booking_status_id}
          helperText={validationErrors.booking_status_id}
        >
          {bookingStatuses.map((option) => (
            <MenuItem key={option.id} value={option.id}>
              {option.display}
            </MenuItem>
          ))}
        </TextField>
      </Stack>

      <Stack direction="column" spacing={1} width="100%">
        <DatePicker
          label="Booking Date"
          value={date}
          disablePast
          disabled={(!!id && isRecurring) || (!!id && !!date && isPast(date))}
          onChange={(newValue) => {
            dispatch(addBookingDate(newValue));
            dispatch(setValidationError({ field: 'date', error: null }));
          }}
          renderInput={(params) => (
            <TextField
              size="small"
              {...params}
              error={!!validationErrors.date}
              helperText={validationErrors.date}
            />
          )}
        />
      </Stack>

      <Stack
        direction="column"
        spacing={1}
        width="100%"
        sx={timePickerSx(theme, !!validationErrors.time)}
      >
        <Datepicker
          theme="ios"
          themeVariant="light"
          label="Time"
          value={timePickerValue}
          onChange={(newValue) => {
            dispatch(addBookingTime(newValue.valueText));
            dispatch(setValidationError({ field: 'time', error: null }));
          }}
          controls={['timegrid']}
          display="anchored"
          touchUi={true}
          minTime="06:00"
          maxTime="23:00"
          stepMinute={30}
          invalid={[
            {
              start: '09:29',
              end: '10:59',
              recurring: {
                repeat: 'daily',
              },
            },
          ]}
          animation="pop"
          timeFormat="HH:mm"
        />
        {validationErrors.time && (
          <CustomErrorMessage label={validationErrors.time} />
        )}
      </Stack>
    </Stack>
  );
}
