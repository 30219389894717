// mui
import { Stack, TextField } from '@mui/material';
//
import useResponsive from '@/hooks/useResponsive';
// types
import { ServiceFormField } from '@/types';

interface Prop {
  serviceFormField: ServiceFormField;
}

export default function NumberInputField({ serviceFormField }: Prop) {
  const downMd = useResponsive('down', 'md');
  return (
    <Stack
      direction="row"
      spacing={2}
      alignItems="center"
      sx={{ width: downMd ? '100%' : '75%' }}
    >
      <TextField
        fullWidth
        label={serviceFormField.name}
        type="number"
        inputProps={{
          min: serviceFormField.min_value,
          max: serviceFormField.max_value,
          step: 1,
        }}
      />
    </Stack>
  );
}
