import axios from '@/utils/axios';
import { UserAddress } from '@/features/users/shared/types';

export async function createAddressForUser(
  userAddress: Partial<UserAddress>
): Promise<UserAddress> {
  const {
    data: { data },
  } = await axios.post(`/addresses`, userAddress);
  return data;
}

export const updateAddress = async (
  addressId: number,
  userAddress: Partial<UserAddress>
) => axios.put(`/addresses/${addressId}`, userAddress);

export const updateDefaultAddress = async (addressId: number) =>
  axios.put(`/addresses/${addressId}`, { default: true });

export const deleteAddress = async (addressId: number) =>
  axios.delete(`/addresses/${addressId}`);
