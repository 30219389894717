import { MbscEventcalendarView } from '@mobiscroll/react';

export const mbscCalendarConfig = () =>
  ({
    timeline: {
      type: 'day',
      resolutionHorizontal: 'hour',
      startTime: '06:00',
      endTime: '23:00',
      startDay: 1, // Monday
      endDay: 0, // Sunday
    },
  }) as MbscEventcalendarView;
