import { DAYS_OF_WEEK } from '@/utils/months-and-days';
import {
  ButtonColors,
  DayOfWeek,
  LabelColors,
  OffDaysRequestType,
} from '@/types';
import { DayOff, OffdayRecurrence } from '../types';

export const PROVIDER_AVAILABILTY_TABLE_HEAD = [
  { id: 'emptyCell' },
  { id: 'id', label: 'ID', align: 'left' },
  { id: 'type_id', label: 'Type', align: 'left' },
  { id: 'start_date', label: 'Start Date', align: 'left' },
  { id: 'end_date', label: 'End Date', align: 'left' },
  { id: 'status', label: 'Status', align: 'left' },
  { id: 'notes', label: 'Notes', align: 'left' },
  { id: 'offday_recurrence', label: 'Recurrence', align: 'left' },
  { id: 'offday_recurrence.week_days', label: 'Recurring days', align: 'left' },
  { id: 'actionBtns', label: '', align: 'left' },
];

// TODO: ask for colors
export const calendarLabelColorPicker = (id: number) => {
  switch (id) {
    case 1:
      return 'red';
    case 2:
      return 'green';
    case 3:
      return 'blue';
    default:
      return 'yellow';
  }
};

export const timeoffStatusColorPicker = (status: string): LabelColors => {
  switch (status) {
    case 'approved':
      return 'success';
    case 'pending':
      return 'warning';
    case 'rejected':
    default:
      return 'error';
  }
};

export const formatRepeatString = (
  offday_recurrence: OffdayRecurrence | undefined
) => {
  if (!offday_recurrence || !offday_recurrence?.repeat_string) {
    return 'N/A';
  }
  if (
    offday_recurrence.repeat_string === 'weekly' &&
    offday_recurrence.interval_number === 2
  ) {
    return 'fortnightly';
  }
  return offday_recurrence?.repeat_string;
};

export const generateEventLabel = (dayOff: DayOff) => {
  if (!dayOff.offday_recurrence) {
    return dayOff.offday_request_types.description.toUpperCase();
  } else {
    return `${dayOff.offday_request_types.description.toUpperCase()} - ${formatRepeatString(
      dayOff.offday_recurrence
    )}`;
  }
};

// Define type for the mapping object
type DayIdMap = { [key: string]: number };

// Function to sort days based on predefined order in DAYS_OF_WEEK
export const sortRecurringDays = (
  offday_recurrence: OffdayRecurrence | undefined
): string[] => {
  if (!offday_recurrence?.week_days) return [];
  const arrayOfDays = (offday_recurrence?.week_days).split(', ');
  // Create a mapping from recurrence_short_name to id
  const dayIdMap: DayIdMap = DAYS_OF_WEEK.reduce<DayIdMap>((acc, day) => {
    acc[day.recurrence_short_name] = day.id;
    return acc;
  }, {});

  // Sort the array based on the ids from the mapping
  arrayOfDays.sort((a, b) => dayIdMap[a] - dayIdMap[b]);

  return arrayOfDays;
};

export const returnTypeName = (
  typeId: number,
  requestTypes: OffDaysRequestType[]
) =>
  requestTypes.find((type) => type.id === typeId)?.description ??
  'Type not found';

export const formatProviderAvailabilityDate = (date: string) => {
  const dateFormatOptionsConfig: Intl.DateTimeFormatOptions = {
    weekday: 'short',
    year: 'numeric',
    month: 'short',
    day: 'numeric',
    timeZone: 'Europe/London',
    hour: '2-digit',
    minute: '2-digit',
  };
  // !recurring &&
  //   Object.assign(dateFormatOptionsConfig, {
  //     hour: '2-digit',
  //     minute: '2-digit',
  //   });
  return new Date(date).toLocaleDateString('en-gb', dateFormatOptionsConfig);
};

export const formatStartEndTimeForTableRowLabel = (
  start: string | undefined,
  end: string | undefined
) => {
  const formattedStart = start
    ? new Date(start).toLocaleTimeString('en-gb', {
        hour: '2-digit',
        minute: '2-digit',
      })
    : 'N/A';
  const formattedEnd = end
    ? new Date(end).toLocaleTimeString('en-gb', {
        hour: '2-digit',
        minute: '2-digit',
      })
    : 'N/A';
  return `${formattedStart} - ${formattedEnd}`;
};

// this is not needed anymore, we can just use the `start_date` for all
// export const formatStartEndDay = (
//   dateType: 'start' | 'end',
//   dayOff: DayOff
// ): string => {
//   const { start_date, end_date, offday_recurrence } = dayOff;
//   if (!offday_recurrence) {
//     if (dateType === 'start') {
//       return start_date ? formatProviderAvailabilityDate(start_date) : 'N/A';
//     } else {
//       return end_date ? formatProviderAvailabilityDate(end_date) : 'N/A';
//     }
//   } else {
//     if (dateType === 'start') {
//       return offday_recurrence.from_date
//         ? formatProviderAvailabilityDate(offday_recurrence.from_date)
//         : 'N/A';
//     } else {
//       return offday_recurrence.until
//         ? formatProviderAvailabilityDate(offday_recurrence.until)
//         : 'indefinite';
//     }
//   }
// };

export const formatDaysOfWeekFromApi = (days: string): DayOfWeek[] => {
  const arrayOfDays = days.split(', ');
  return DAYS_OF_WEEK.filter((day) =>
    arrayOfDays.includes(day.recurrence_short_name)
  );
};

// TODO: extend this to be reusable loadingButtonText, disagreeButtonText... etc
export const createDialog = (
  name: string,
  actionVerb: string,
  title: string,
  loadingButtonColor: ButtonColors,
  agreeFunction: () => void
) => ({
  name,
  actionVerb,
  title,
  loadingButtonColor,
  disagreeButtonText: 'Cancel',
  agreeFunction,
});

export const TIME_OFF_STATUSES = [
  {
    id: 1,
    name: 'approved',
    actionVerb: 'Approve',
    color: 'success',
    icon: 'eva:checkmark-outline',
  },
  {
    id: 2,
    name: 'pending',
    actionVerb: 'Set pending',
    color: 'warning',
    icon: 'eva:pause-circle-outline',
  },
  {
    id: 3,
    name: 'rejected',
    actionVerb: 'Reject',
    color: 'error',
    icon: 'eva:close-outline',
  },
];
