import { generatePath } from 'react-router';
import { path } from '@/utils/formatLink';
import { BaseObjectEntity } from '@/types';

export const ROOTS_USER_MANAGEMENT = '/users';

export const PATH_USER_MANAGEMENT: BaseObjectEntity = {
  root: ROOTS_USER_MANAGEMENT,
  new: '/new',
  edit: '/:userId/:tabSid',
};

export function userPath(pathKey: string, params?: BaseObjectEntity) {
  return generatePath(
    path(ROOTS_USER_MANAGEMENT, PATH_USER_MANAGEMENT[pathKey]),
    params
  );
}
