import { useEffect, useState } from 'react';
import { isEmpty } from 'lodash';
// api
import { getSingleDiaryData } from '../api';
// react router
import { useParams, useLocation } from 'react-router-dom';
// @mui
import { Container } from '@mui/material';
// hooks
import useSettings from '@/hooks/useSettings';
// components
import { Page, HeaderBreadcrumbs } from '@/components';
// custom components
import ActivityCreateEdit from './ActivityCreateEdit';
// types
import type { Activity } from '../types';
// paths
import { PATH_ACTIVITIES } from '../routes/paths';
import { PATH_USER } from '@/features/app';

// ----------------------------------------------------------------------

export default function ActivitiesCreate() {
  const { themeStretch } = useSettings();
  const [currentData, setCurrentData] = useState<Activity | {}>({});

  const { pathname } = useLocation();

  const { id } = useParams();

  const isEdit = pathname.includes('edit');
  // ----------------------------------------------------------------------
  // Fetch data and assign profile picture to activity_to_user id
  useEffect(() => {
    const fetchData = async () => {
      const data = await getSingleDiaryData(Number(id));
      setCurrentData(data);
    };

    id && fetchData();
  }, [id]);

  return (
    <Page title="Create or Edit activity">
      <Container maxWidth={themeStretch ? false : 'lg'}>
        <HeaderBreadcrumbs
          heading={!isEdit ? 'Add activity' : 'Edit activity'}
          links={[
            { name: 'Dashboard', href: PATH_USER.root },
            {
              name: 'Diary',
              href: PATH_ACTIVITIES.root,
            },
            {
              name: 'Activities',
              href: PATH_ACTIVITIES.root,
            },
            {
              name: !isEdit
                ? 'Add New Activity'
                : isEmpty(currentData)
                ? ''
                : (currentData as Activity).subject,
            },
          ]}
        />
        <ActivityCreateEdit
          currentData={currentData as Activity}
          ID={Number(id)}
        />
      </Container>
    </Page>
  );
}
