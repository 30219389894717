// @mui
import { List, Box, Divider } from '@mui/material';
//
import { NavSectionProps } from '../type';
import { ListSubheaderStyle } from './style';
import NavList from './NavList';

// ----------------------------------------------------------------------

const hideScrollbar = {
  msOverflowStyle: 'none',
  scrollbarWidth: 'none',
  overflowY: 'scroll',
  '&::-webkit-scrollbar': {
    display: 'none',
  },
} as const;

export default function NavSectionVertical({
  navConfig,
  sx,
  isCollapse,
  ...other
}: NavSectionProps) {
  return (
    <Box {...other} sx={{ ...hideScrollbar }}>
      {navConfig.map((group, idx) => (
        <List key={group.subheader} disablePadding sx={{ px: 0.5 }}>
          {!group.hide && (
            <>
              <ListSubheaderStyle
                sx={{
                  ...(isCollapse && {
                    display: 'flex',
                    opacity: 1,
                    fontSize: 8,
                    px: 0,
                    py: 1,
                    justifyContent: 'center',
                  }),
                }}
              >
                {group.subheader}
              </ListSubheaderStyle>
              {isCollapse && idx ? (
                <Divider sx={{ mx: 1, my: 0.3, p: 0 }} />
              ) : (
                ''
              )}
            </>
          )}
          {group.items.map((list) => (
            <NavList
              key={list.title + list.path}
              sx={{
                ...(isCollapse && {
                  display: 'flex',
                  flexDirection: 'column',
                  py: 0.5,
                }),
              }}
              data={list}
              depth={1}
              hasChildren={!!list.children}
              isCollapse={isCollapse}
              restrictedTo={list.restricted_to}
            />
          ))}
        </List>
      ))}
    </Box>
  );
}
