import { createContext, ReactNode, useEffect, useReducer } from 'react';
import axios from '@/utils/axios';
import { isTokenValid, setSession } from '@/utils/jwt';

import {
  ActionMap,
  AuthState,
  AuthUser,
  JWTContextType,
  UserSessionResponse,
} from '@/features/app';
import { destroyNotificationInterval } from '@/features/notification/slices';

export enum Types {
  Initial = 'INITIALIZE',
  Login = 'LOGIN',
  Logout = 'LOGOUT',
  Register = 'REGISTER',
}

type JWTAuthPayload = {
  [Types.Initial]: {
    isAuthenticated: boolean;
    isSuperAdmin: boolean;
    permissions: string[];
    user: AuthUser;
  };
  [Types.Login]: {
    isSuperAdmin: boolean;
    permissions: string[];
    user: AuthUser;
  };
  [Types.Logout]: undefined;
  [Types.Register]: {
    user: AuthUser;
  };
};

export type JWTActions =
  ActionMap<JWTAuthPayload>[keyof ActionMap<JWTAuthPayload>];

const initialAuthState: AuthState = {
  isAuthenticated: false,
  isSuperAdmin: false,
  isInitialized: false,
  permissions: [],
  user: null,
};

const JWTReducer = (state: AuthState, action: JWTActions) => {
  switch (action.type) {
    case 'INITIALIZE':
      return {
        isAuthenticated: action.payload.isAuthenticated,
        isSuperAdmin: action.payload.isSuperAdmin,
        isInitialized: true,
        permissions: action.payload.permissions,
        user: action.payload.user,
      };
    case 'LOGIN':
      return {
        ...state,
        isAuthenticated: true,
        isSuperAdmin: action.payload.isSuperAdmin,
        permissions: action.payload.permissions,
        user: action.payload.user,
      };
    case 'LOGOUT':
      return {
        ...state,
        isAuthenticated: false,
        isSuperAdmin: false,
        permissions: [],
        user: null,
      };
    case 'REGISTER':
      return {
        ...state,
        isAuthenticated: true,
        permissions: [],
        user: action.payload.user,
      };
    default:
      return state;
  }
};

const AuthContext = createContext<JWTContextType | null>(null);

type AuthProviderProps = {
  children: ReactNode;
};

function AuthProvider({ children }: AuthProviderProps) {
  const [state, dispatch] = useReducer(JWTReducer, initialAuthState);

  useEffect(() => {
    const initialize = async () => {
      try {
        const accessToken = window.localStorage.getItem('accessToken');
        if (accessToken && isTokenValid(accessToken)) {
          setSession(accessToken);
          const {
            data: {
              data: { user },
            },
          } = await axios.get('/account/profile');

          dispatch({
            type: Types.Initial,
            payload: {
              isAuthenticated: true,
              isSuperAdmin: user.superadmin,
              permissions: user.permissions,
              user,
            },
          });
        } else {
          dispatch({
            type: Types.Initial,
            payload: {
              isAuthenticated: false,
              isSuperAdmin: false,
              permissions: [],
              user: null,
            },
          });
        }
      } catch (err) {
        console.error(err);
        dispatch({
          type: Types.Initial,
          payload: {
            isAuthenticated: false,
            isSuperAdmin: false,
            permissions: [],
            user: null,
          },
        });
      }
    };
    initialize();
  }, [dispatch]);

  const login = async (email: string, password: string, page?: string) => {
    const {
      data: {
        data: { token, user },
      },
    }: UserSessionResponse = await axios.post('auth/dashboard/login', {
      email,
      password,
      page,
    });

    setSession(token);
    dispatch({
      type: Types.Login,
      payload: {
        isSuperAdmin: user.superadmin,
        permissions: user.permissions,
        user,
      },
    });
  };

  const register = async (
    email: string,
    password: string,
    first_name: string,
    last_name: string,
    phone: string
  ) => {
    const {
      data: {
        data: { token, user },
      },
    }: UserSessionResponse = await axios.post('/signup', {
      email,
      password,
      first_name,
      phone,
      user_type: Number(process.env.USER_TYPE),
      last_name,
    });
    setSession(token);
    dispatch({
      type: Types.Register,
      payload: {
        user,
      },
    });
  };

  const logout = async () => {
    setSession(null);
    dispatch({ type: Types.Logout });
    destroyNotificationInterval();
  };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: 'jwt',
        login,
        logout,
        register,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export { AuthContext, AuthProvider };
