import { Grid, Skeleton } from '@mui/material';
import {
  VBMCleaner,
  VBMCleaningTypeAndExtras,
  VBMDateTime,
  VBMDetailCard,
  VBMNote,
  VBMPaymentDetails,
  VBMStatus,
  VBMStatusHistory,
} from '..';
// hooks
import useResponsive from '@/hooks/useResponsive';
// redux
import { useSelector } from '@/stores';
// types
import { BookingSession } from '@/features/bookings-management/types';
//
type VBMBookingDetailsProps = {
  displayPaymentDetails?: boolean;
  sessionView?: boolean;
  bookingSession?: BookingSession;
};

// '/bookings/view/:bookingId/booking-sessions/:sessionFilter?sessionID=:sessionID'
export default function VBMBookingDetails({
  displayPaymentDetails = false,
  sessionView = false,
  bookingSession,
}: VBMBookingDetailsProps) {
  const downMd = useResponsive('down', 'md');
  // TODO: do we still need data from redux store or is it just some legacy stuff in here?
  const booking = useSelector((state) => state.booking);
  const isSession = sessionView && bookingSession;
  // TODO: is this enough, should we check for more conditions? ie. statuses?
  // const editable = isSession && !isPast(new Date(bookingSession.date));
  const editable = !!isSession;
  const showSkeleton = !bookingSession && sessionView;

  return (
    <>
      {!showSkeleton ? (
        <Grid
          container
          direction={downMd ? 'column' : 'row'}
          spacing={4}
          sx={{ pt: 1 }}
        >
          {/* LEFT COLUMN */}
          <Grid item container direction="column" xs={8} spacing={3}>
            {/* -- DETAIL CARDS */}
            <Grid
              item
              container
              direction={downMd ? 'column' : 'row'}
              spacing={2}
              justifyContent="space-between"
            >
              <Grid item xs={4}>
                <VBMDetailCard
                  title="Hours"
                  icon="basil:clock-outline"
                  hours={
                    isSession
                      ? bookingSession.hours
                      : booking.cleanDetails.hours
                  }
                  editable={editable}
                />
              </Grid>
              <Grid item xs={4}>
                <VBMDetailCard
                  title="Bedrooms"
                  icon="material-symbols:king-bed-outline"
                  hours={
                    isSession
                      ? bookingSession.bedrooms
                      : booking.cleanDetails.numberOfBedrooms
                  }
                  editable={editable}
                />
              </Grid>
              <Grid item xs={4}>
                <VBMDetailCard
                  title="Bathrooms"
                  icon="majesticons:bath-shower-line"
                  hours={
                    isSession
                      ? bookingSession.bathrooms
                      : booking.cleanDetails.numberOfBathrooms
                  }
                  editable={editable}
                />
              </Grid>
            </Grid>

            {/* -- CLEANER, STATUS, DATE & TIME  */}
            {sessionView && (
              <Grid
                item
                container
                direction={downMd ? 'column' : 'row'}
                spacing={2}
                justifyContent="space-between"
              >
                <Grid item xs={4}>
                  <VBMCleaner
                    selectedOption={
                      isSession
                        ? bookingSession.booking_session_cleaners[0]
                        : booking.booking_cleaners[0]
                    }
                    editable={editable}
                  />
                </Grid>
                <Grid item xs={4}>
                  <VBMStatus
                    selectedBookingStatusID={
                      isSession
                        ? bookingSession.status_id
                        : Number(booking.booking_status_id)
                    }
                    selectedBookingPaymentStatusID={
                      isSession ? bookingSession?.payment_status_id : 0
                    }
                    editable={editable}
                  />
                </Grid>
                <Grid item xs={4}>
                  <VBMDateTime
                    date={bookingSession ? bookingSession.date : ''}
                    time={bookingSession ? bookingSession.time : ''}
                    editable={editable}
                  />
                </Grid>
              </Grid>
            )}

            {/* NOTES */}
            <Grid
              container
              item
              direction={downMd ? 'column' : 'row'}
              spacing={2}
            >
              <Grid
                item
                container
                direction="column"
                xs={displayPaymentDetails ? 6 : 12}
                spacing={3}
              >
                <Grid item>
                  <VBMNote
                    title="Office Notes"
                    toolTipInfoText="These are the notes from the office to the provider"
                    keyValue="admin_notes"
                    noteText={
                      isSession
                        ? bookingSession.admin_notes
                        : booking.cleanDetails.notes.admin_notes ?? 'N/A'
                    }
                    editable={editable}
                  />
                </Grid>
                <Grid item>
                  <VBMNote
                    title="Cleaning Instructions"
                    toolTipInfoText="These are the instruction from the client to the provider"
                    keyValue="provider_instructions"
                    noteText={
                      isSession
                        ? bookingSession.provider_instructions
                        : booking.cleanDetails.notes.provider_instructions ??
                          'N/A'
                    }
                    editable={editable}
                  />
                </Grid>
                <Grid item>
                  <VBMNote
                    title="Access Instructions"
                    toolTipInfoText="These are the instructions on how to access the property"
                    keyValue="access_instructions"
                    noteText={
                      isSession
                        ? bookingSession.access_instructions
                        : booking.cleanDetails.notes.access_instructions ??
                          'N/A'
                    }
                    editable={editable}
                  />
                </Grid>
                <Grid item>
                  <VBMStatusHistory
                    statusHistory={bookingSession?.status_history || []}
                  />
                </Grid>
              </Grid>

              {/* PAYMENT DETAILS */}
              {displayPaymentDetails && (
                <Grid item xs={6}>
                  <VBMPaymentDetails />
                </Grid>
              )}
            </Grid>
          </Grid>

          {/* RIGHT COLUMN */}

          <Grid item container xs={4} direction="column" spacing={3}>
            <Grid item>
              <VBMCleaningTypeAndExtras
                editable={editable}
                bookingSession={bookingSession}
                sessionView={sessionView}
              />
            </Grid>
            <Grid item>
              <VBMNote
                title="Internal Notes"
                toolTipInfoText="These are notes from admin to admin only"
                keyValue="internal_notes"
                noteText={
                  isSession
                    ? bookingSession.internal_notes
                    : booking.cleanDetails.notes.internal_notes ?? 'N/A'
                }
                editable={editable}
                pinkBg
              />
            </Grid>
          </Grid>
        </Grid>
      ) : (
        <Skeleton variant="rounded" height={50} />
      )}
    </>
  );
}
