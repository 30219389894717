import { Skeleton, Stack } from '@mui/material';

export default function ViewBookingSkeleton() {
  return (
    <Stack spacing={2}>
      <Skeleton variant="rounded" height={200} />
      <Skeleton variant="rounded" height={500} />
    </Stack>
  );
}
