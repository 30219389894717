import axios from '@/utils/axios';
import { Booking, BookingStats } from '../types';

// BOOKINGS
export async function getBookings(): Promise<Booking[]> {
  const {
    data: { bookings },
  } = await axios.get(`/bookings`);
  return bookings;
}

export async function getFilteredBookings(
  startDate: string,
  endDate?: string
): Promise<Booking[]> {
  const queryParams = `?startDate=${startDate}`;
  endDate && queryParams.concat(`&endDate=${endDate}`);
  const {
    data: { bookings },
  } = await axios.get(`/bookings/filter${queryParams}`);
  return bookings;
}

export async function getSingleBooking(id: number): Promise<Booking> {
  const {
    data: { data },
  } = await axios.get(`/bookings/${id}`);
  return data;
}

export async function createBooking(booking: Booking): Promise<Booking> {
  const { data } = await axios.post(`/bookings`, booking);
  return data;
}

export async function updateBooking(
  bookingId: number,
  // TODO: check the type Partial<Booking>
  updatedBooking: any
): Promise<Booking> {
  const {
    data: { data },
  } = await axios.put(`/bookings/${bookingId}`, updatedBooking);
  return data[0];
}

export async function deleteBooking(bookingId: number) {
  const { data } = await axios.delete(`/bookings/${bookingId}`);
  return data;
}

// =================================================================
// BOOKING STATS
export async function getBookingStats(): Promise<BookingStats> {
  const {
    data: { data },
  } = await axios.get('/stats/bookings');
  return data;
}
