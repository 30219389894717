// icons
import { Icon, IconifyIcon } from '@iconify/react';
// @mui
import { Box, BoxProps, SxProps, Tooltip } from '@mui/material';
import { forwardRef } from 'react';

// ----------------------------------------------------------------------

interface Props extends BoxProps {
  sx?: SxProps;
  icon: IconifyIcon | string;
}

export default function Iconify({ width = 20, icon, sx, ...other }: Props) {
  return (
    <Box
      component={Icon}
      icon={icon}
      sx={{ width, height: width, ...sx }}
      {...other}
    />
  );
}

// Compare with Iconify we can add tooltip without need to wrap aoround the IconButton
// also only width needed insted to type width: 24 height: 24

interface ToolTipIconProps extends BoxProps {
  icon: IconifyIcon | string;
  title: string;
}

export const TooltipIcon = forwardRef<SVGAElement, ToolTipIconProps>(
  ({ icon, width = 20, sx, title, ...other }, ref) => (
    <Tooltip title={title}>
      <Box
        ref={ref}
        component={Icon}
        icon={icon}
        sx={{ width, height: width, ...sx }}
        {...other}
      />
    </Tooltip>
  )
);
