import { useState } from 'react';
import { format } from 'date-fns';
import { isEmpty } from 'lodash';
// @mui
import {
  TableRow,
  TableCell,
  Typography,
  Checkbox,
  MenuItem,
  Link,
  Chip,
  Avatar,
  ListItem,
  Stack,
  Tooltip,
  Button,
} from '@mui/material';
import {
  KeyboardDoubleArrowUp,
  KeyboardArrowDown,
  KeyboardDoubleArrowDown,
  KeyboardArrowUp,
  DragHandle,
} from '@mui/icons-material';
// components
import AlertDialog from '@/components/dialog/Dialog';
import { Iconify, Label } from '@/components';
import { TableMoreMenu } from '@/components/table';
// types
import { Activity } from '../types';
import { PermissionsObject } from '@/types';
// ----------------------------------------------------------------------

type Props = {
  row: Activity;
  permissions?: PermissionsObject;
  onEditRow: VoidFunction;
  onSelectRow: VoidFunction;
  onDeleteRow: VoidFunction;
  selected: boolean;
};

export default function TableRowData({
  row,
  permissions,
  onSelectRow,
  onEditRow,
  onDeleteRow,
  selected,
}: Props) {
  const {
    activity_type,
    owner,
    subject,
    priority,
    end_date,
    linked_entity_type,
    completed,
    activity_to_users,
    linked_entity_id,
  } = row;
  const [openMenu, setOpenMenuActions] = useState<HTMLElement | null>(null);

  const handleOpenMenu = (event: React.MouseEvent<HTMLElement>) => {
    setOpenMenuActions(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setOpenMenuActions(null);
  };
  // ----------------------------------------------------------------------
  // MODAL/DIALOG STATES AND FUNCTIONS

  const [openModal, setOpenModal] = useState(false);
  const handleClickonDeleteRow = () => {
    setOpenModal(true);
  };

  const handleCloseDialog = () => {
    setOpenModal(false);
  };

  const dialogDisagree = () => {};

  const { canDelete, canUpdate } = permissions || {};
  return (
    <>
      {openModal && (
        <AlertDialog
          dialogTitle="Delete user"
          dialogText={`Are you sure you want to delete this activity: ${subject} activity?`}
          dialogAgree={onDeleteRow}
          dialogDisagree={dialogDisagree}
          isDialogOpen={openModal}
          setIsDialogOpen={setOpenModal}
          handleCloseDialog={handleCloseDialog}
          agreeButtonText="Delete"
          disagreeButtonText="Cancel"
          agreeButtonColor="error"
          disagreeButtonColor="inherit"
        />
      )}

      <TableRow hover selected={selected}>
        <TableCell padding="checkbox">
          <Checkbox checked={selected} onClick={onSelectRow} />
        </TableCell>
        <TableCell align="center">
          <Typography variant="subtitle2" noWrap>
            {priority.name === 'Highest' ? (
              <KeyboardDoubleArrowUp color="error" fontSize="medium" />
            ) : priority.name === 'High' ? (
              <KeyboardArrowUp color="error" fontSize="medium" />
            ) : priority.name === 'Medium' ? (
              <DragHandle color="warning" fontSize="medium" />
            ) : priority.name === 'Low' ? (
              <KeyboardArrowDown color="secondary" fontSize="medium" />
            ) : priority.name === 'Lowest' ? (
              <KeyboardDoubleArrowDown color="secondary" fontSize="medium" />
            ) : (
              ''
            )}
          </Typography>
        </TableCell>
        <TableCell>
          {
            <Tooltip title={activity_type.name}>
              <Button
                sx={{
                  ml: 1,
                  '&.MuiButtonBase-root:hover': {
                    bgcolor: 'transparent',
                  },
                }}
              >
                <Iconify
                  icon={activity_type.icon}
                  key={activity_type.name}
                  width={20}
                  height={20}
                />
              </Button>
            </Tooltip>
          }
        </TableCell>
        <TableCell>
          <Typography variant="subtitle2" noWrap>
            {subject}
          </Typography>
        </TableCell>
        <TableCell>
          <Chip
            avatar={<Avatar alt="profile-picture" src={owner.profile_image} />}
            label={owner.first_name}
            color="primary"
            size="small"
          />
        </TableCell>
        <TableCell>
          <Link
            href={`${linked_entity_type?.dashboard_route}/${linked_entity_id}`}
            variant="subtitle2"
            noWrap
            underline="always"
            color="inherit"
          >
            {linked_entity_type?.description.split(' ').at(0)}
          </Link>
        </TableCell>
        <TableCell>
          <Typography
            variant="subtitle2"
            noWrap
            color={completed ? 'secondary' : 'priary'}
          >
            <Label
              color={completed ? 'primary' : 'secondary'}
              sx={{ textTransform: 'capitalize' }}
            >
              {completed ? 'Done' : 'To Do'}
            </Label>
          </Typography>
        </TableCell>
        <TableCell>
          <Stack
            direction="row"
            sx={{
              display: 'inline',

              justifyContent: 'center',
              flexWrap: 'wrap',
              listStyle: 'none',
              p: 0.5,
              m: 0,
            }}
          >
            {!isEmpty(activity_to_users) &&
              activity_to_users?.slice(0, 2).map((item) => (
                <ListItem key={item.id} sx={{ display: 'inline', p: 0.5 }}>
                  <Chip
                    label={item.first_name}
                    size="small"
                    color="primary"
                    avatar={
                      <Avatar alt="profile-picture" src={item.profile_image} />
                    }
                  />
                </ListItem>
              ))}
            {!isEmpty(activity_to_users) && activity_to_users.length > 2 && (
              <Label color="primary" display="inline">
                +{activity_to_users?.length - 2}
              </Label>
            )}
          </Stack>
        </TableCell>
        <TableCell
          align="left"
          dangerouslySetInnerHTML={{
            __html: format(new Date(end_date), 'dd MMM yyyy') as string,
          }}
        />

        <TableCell align="right">
          {(canUpdate || canDelete) && (
            <TableMoreMenu
              open={openMenu}
              onOpen={handleOpenMenu}
              onClose={handleCloseMenu}
              actions={
                <>
                  {canUpdate && (
                    <MenuItem
                      onClick={() => {
                        onEditRow();
                        handleCloseMenu();
                      }}
                    >
                      <Iconify icon={'eva:edit-fill'} />
                      Edit
                    </MenuItem>
                  )}

                  {canDelete && (
                    <MenuItem
                      onClick={() => {
                        handleClickonDeleteRow();
                        handleCloseMenu();
                      }}
                      sx={{ color: 'error.main' }}
                    >
                      <Iconify icon={'eva:trash-2-outline'} />
                      Delete
                    </MenuItem>
                  )}
                </>
              }
            />
          )}
        </TableCell>
      </TableRow>
    </>
  );
}
