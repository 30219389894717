import { BaseObjectEntity } from '@/types';
import { path } from '@/utils/formatLink';

export const ROOTS_AUDIT = '/audit';

export const PATH_AUDIT: BaseObjectEntity = {
  root: ROOTS_AUDIT,
  logs: '/logs',
  register: '/register',
};

export function auditPath(pathKey: string) {
  return path(ROOTS_AUDIT, PATH_AUDIT[pathKey]);
}
