import { useForm, useWatch } from 'react-hook-form';
import { useParams } from 'react-router';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { yupResolver } from '@hookform/resolvers/yup';
//
import {
  Button,
  InputAdornment,
  MenuItem,
  Skeleton,
  Stack,
} from '@mui/material';
import {
  FormProvider,
  RHFCheckbox,
  RHFDatepicker,
  RHFTextField,
} from '@/components/hook-form';
import { LoadingButton } from '@mui/lab';
import DialogSimple from '@/components/dialog/DialogSimple';
// api
import { getSettingsData } from '@/features/app/api';
import {
  createVariantCustomPrice,
  getVariantSingleCustomPrice,
  updateVariantCustomPrice,
} from '@/features/services-management/api';
// hooks + utils
import { useSnackbarMsg } from '@/hooks/useSnackbarMsg';
import {
  NEW_VARIANT_CUSTOM_PRICE_DEFAULT_VALUES,
  NewVariantCustomPriceSchema,
} from '@/features/services-management/utils';
// types
import {
  VariantCustomPrice,
  VariantCustomPriceFormValues,
} from '@/features/services-management/types/custom-prices';

type VariantCustomPriceDialogProps = {
  customPriceId?: string;
  isDialogOpen: boolean;
  closeDialog: VoidFunction;
};

export default function VariantCustomPriceDialog({
  customPriceId,
  isDialogOpen,
  closeDialog,
}: VariantCustomPriceDialogProps) {
  const { errorMsg, successMsg } = useSnackbarMsg();
  const { variantId } = useParams();
  const queryClient = useQueryClient();

  // =================
  //      RHF FORM
  // =================
  const methods = useForm<VariantCustomPriceFormValues>({
    resolver: yupResolver(NewVariantCustomPriceSchema),
    defaultValues: NEW_VARIANT_CUSTOM_PRICE_DEFAULT_VALUES,
  });
  const { control, handleSubmit, reset: resetFormValues } = methods;
  const { start_date } = useWatch({ control });

  // =================
  //      QUERIES
  // =================

  // GET
  const { data: currentPrice, isLoading: isCustomPriceLoading } = useQuery({
    queryKey: ['variantCustomPrice', customPriceId],
    queryFn: () =>
      getVariantSingleCustomPrice(String(variantId), String(customPriceId)),
    onSuccess: (data) => {
      resetFormValues({
        city_id: data.cities.id,
        price: data.price,
        sun_bank_holiday_extra: data.sun_bank_holiday_extra,
        description: data.description ?? '',
        start_date: data.start_date,
        end_date: data.end_date,
        overwrite: data.overwrite,
      });
    },
    onError: (error) => {
      errorMsg(error, `Something went wrong while fetching custom price`);
    },
    enabled: !!customPriceId && isDialogOpen,
    refetchOnWindowFocus: false,
  });

  // CITIES
  const { data: cities, isLoading: isCitiesLoading } = useQuery({
    queryKey: ['settings'],
    queryFn: getSettingsData,
    onError: (error) => {
      errorMsg(error, `Something went wrong while fetching settings data`);
    },
    select: (data) => data?.cities,
    enabled: isDialogOpen,
    refetchOnWindowFocus: false,
    // TODO: add stale time
  });

  // SUBMIT
  const submitCustromPriceMutation = useMutation({
    mutationFn: (data: Partial<VariantCustomPrice>) =>
      customPriceId
        ? updateVariantCustomPrice(
            String(variantId),
            String(customPriceId),
            data
          )
        : createVariantCustomPrice(String(variantId), data),
    onSuccess: () => {
      queryClient.invalidateQueries(['variantCustomPrices']);
      successMsg(
        `Custom price succesfully ${customPriceId ? 'updated' : 'created'}`
      );
      closeDialog();
    },
    onError: (error) => {
      errorMsg(
        error,
        `There was an error while ${
          customPriceId ? 'updating' : 'creating'
        } custom price`
      );
    },
  });

  const onSubmitCustomPrice = (data: VariantCustomPriceFormValues) => {
    const customPriceDto = {
      city_id: Number(data.city_id),
      price: Number(data.price),
      sun_bank_holiday_extra: Number(data.sun_bank_holiday_extra),
      description: data.description,
      start_date: new Date(data.start_date).toISOString(),
      end_date: new Date(data.end_date).toISOString(),
      overwrite: data.overwrite,
    };
    submitCustromPriceMutation.mutate(customPriceDto);
  };

  //
  const showSkeleton =
    isCitiesLoading ||
    (customPriceId && isCustomPriceLoading) ||
    (customPriceId && !currentPrice);

  return (
    <DialogSimple
      dialogTitle={`${customPriceId ? 'Edit' : 'Add'} custom price`}
      open={isDialogOpen}
      onClickButtonClose={closeDialog}
      loadingButton={false}
      disagreeButton={false}
      maxWidth="md"
    >
      <FormProvider
        methods={methods}
        onSubmit={handleSubmit(onSubmitCustomPrice)}
      >
        <Stack spacing={3} sx={{ pt: 1, pb: 2, px: 3 }}>
          {!showSkeleton ? (
            <>
              <Stack direction="row" spacing={2}>
                <RHFTextField select name="city_id" label="City or town">
                  {(cities || []).map((city) => (
                    <MenuItem key={city.id} value={city.id}>
                      {city.name}
                    </MenuItem>
                  ))}
                </RHFTextField>
                <RHFTextField
                  name="description"
                  label="Description"
                  multiline
                  maxRows={2}
                />
              </Stack>
              <Stack direction="row" spacing={2}>
                <RHFTextField
                  name="price"
                  label="Price"
                  inputProps={{ type: 'number', step: 0.5, min: 0 }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">£</InputAdornment>
                    ),
                  }}
                />
                <RHFTextField
                  name="sun_bank_holiday_extra"
                  label="Sunday/Bank holiday extra"
                  inputProps={{ type: 'number', step: 0.5, min: 0 }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">£</InputAdornment>
                    ),
                  }}
                />
                <RHFCheckbox name="overwrite" label="Overwrite" />
              </Stack>

              <Stack direction="row" spacing={2}>
                <RHFDatepicker
                  name="start_date"
                  label="Start date"
                  size="small"
                  disablePast
                />
                <RHFDatepicker
                  name="end_date"
                  label="End date"
                  size="small"
                  disablePast
                  minDate={start_date}
                />
              </Stack>
            </>
          ) : (
            [...new Array(3)].map((_, index) => (
              <Skeleton variant="rounded" height={30} key={index} />
            ))
          )}
          <Stack direction="row" spacing={2} justifyContent="flex-end">
            <Button color="inherit" variant="outlined" onClick={closeDialog}>
              Cancel
            </Button>
            <LoadingButton
              type="submit"
              variant="contained"
              size="medium"
              loading={submitCustromPriceMutation.isLoading}
            >
              {customPriceId ? 'Update price' : 'Create price'}
            </LoadingButton>
          </Stack>
        </Stack>
      </FormProvider>
    </DialogSimple>
  );
}
