import { Navigate, Route, Routes } from 'react-router-dom';
import DashboardLayout from '@/layouts/dashboard';

import { PATH_PRODUCTS, ROOTS_MANAGE } from './paths';
import { ProductCreate, ProductList } from '../pages';

import RoleBasedGuard from '@/guards/RoleBasedGuard';
import AuthGuard from '@/guards/AuthGuard';

export const ProductsRoutes = () => (
  <Routes>
    <Route
      element={
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      }
    >
      <Route
        index
        element={
          <RoleBasedGuard roles={['products.view']}>
            <Navigate to={PATH_PRODUCTS.root + ROOTS_MANAGE} replace />
          </RoleBasedGuard>
        }
      />
      <Route path={ROOTS_MANAGE}>
        <Route
          index
          element={
            <RoleBasedGuard roles={['products.view']}>
              <ProductList />
            </RoleBasedGuard>
          }
        />
        <Route
          path={PATH_PRODUCTS.new}
          element={
            <RoleBasedGuard roles={['products.create']}>
              <ProductCreate />
            </RoleBasedGuard>
          }
        />
        <Route
          path={PATH_PRODUCTS.edit}
          element={
            <RoleBasedGuard roles={['products.update']}>
              <ProductCreate />
            </RoleBasedGuard>
          }
        />
      </Route>
    </Route>
  </Routes>
);
