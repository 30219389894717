import { STREAM_KEY } from '@/config';
import useAuth from '@/hooks/useAuth';
import { ChannelFilters, ChannelOptions, ChannelSort, User } from 'stream-chat';
import {
  Avatar,
  Channel,
  ChannelHeader,
  ChannelList,
  Chat,
  LoadingIndicator,
  MessageInput,
  MessageList,
  Thread,
  useChannelActionContext,
  Window,
  useChannelStateContext,
  ChatDownProps,
} from 'stream-chat-react';

import { EmojiPicker } from 'stream-chat-react/emojis';

import { init, SearchIndex } from 'emoji-mart';
import data from '@emoji-mart/data';

// import 'stream-chat-react/dist/css/v2/index.css';
// import '../style/layout.css';
// import '../style/ChannelSearch/ChannelSearch.layout.css';
// import '../style/ChannelSearch/ChannelSearch.theme.css';

import '../style/index.scss';

import { useEffect } from 'react';
import { CustomTypingIndicator } from '../components/CustomTypingIndicator';
import { ChannelSearch } from '../components/ChannelSearch/ChannelSearch';
import { WorkspaceController } from '@/contexts/WorkspaceController';
import { Sidebar } from '../components/Sidebar/Sidebar';
import { ChannelContainer } from '../components/ChannelContainer/ChannelContainer';
// import { CustomSearchDropdown } from '../components';

init({ data });

interface Props {
  chatClient: any; // Replace 'any' with the actual type of your chat client
  channelFilters: ChannelFilters;
  channelSort: ChannelSort;
  channelOptions: ChannelOptions;
}

// const channelSearch = {
//   searchForChannels: true,
//   searchForUsers: true,
// };

// const DropDown = (props: SearchResultsListProps) => (
//   <CustomSearchDropdown {...props} />
// );

const additionalProps = {
  // DropdownContainer: DropDown,
  searchForChannels: true,
};

const ChatStream: React.FC<Props> = ({
  chatClient,
  channelFilters,
  channelSort,
  channelOptions,
}) => {
  const CustomErrorIndicator = (props: ChatDownProps) => {
    const { text } = props;

    return <div>{text}</div>;
  };

  const CustomLoadingIndicator = () => <div>Loading, loading, loading...</div>;

  // const ChannelInner = () => {
  //   const { addNotification } = useChannelActionContext();
  //   const { channel } = useChannelStateContext();

  //   useEffect(() => {
  //     const clickToAddNotification = () => {
  //       addNotification('A message has been edited!', 'success');
  //     };

  //     channel.on('message.updated', clickToAddNotification);

  //     return () => {
  //       channel.off('message.updated', clickToAddNotification);
  //     };
  //   }, [addNotification, channel]);

  //   return (
  //     <>
  //       <Window>
  //         <ChannelHeader />
  //         <MessageList />
  //         <MessageInput />
  //       </Window>
  //       <Thread />
  //     </>
  //   );
  // };

  if (!chatClient) {
    return <LoadingIndicator />;
  }

  return (
    <div id="chat-stream">
      <div className="app__wrapper str-chat">
        <Chat client={chatClient} theme="team light">
          {/* <ChannelSearch />
        <ChannelList
          filters={channelFilters}
          sort={channelSort}
          options={channelOptions}
          showChannelSearch={true}
          additionalChannelSearchProps={additionalProps}
        />
        <Channel
          EmojiPicker={EmojiPicker}
          emojiSearchIndex={SearchIndex}
          TypingIndicator={CustomTypingIndicator}
        >
          <Window>
            <ChannelInner />
          </Window>
          <Thread />
        </Channel> */}

          <WorkspaceController>
            <Sidebar />
            <ChannelContainer />
          </WorkspaceController>
        </Chat>
      </div>
    </div>
  );
};

export default ChatStream;
