import { useEffect, useState } from 'react';
import { isEmpty } from 'lodash';
// mui
import { Stack, Typography } from '@mui/material';
// custom component
import CustomPickerComponent from './CustomPickerComponent';
// utils
import { generateArrayOfHoursObject } from '@/utils/generateArray';
// redux
import { useDispatch, useSelector } from '@/stores';
import { setVariantHours } from '@/features/bookings-management/slices';
// types
import { ServiceFormField } from '@/types';
import { HoursObject } from '@/features/bookings-management/types';

interface Prop {
  serviceFormField: ServiceFormField;
}

export default function PickerInputField({ serviceFormField }: Prop) {
  const [hours, setHours] = useState<HoursObject[]>([]);

  const dispatch = useDispatch();
  const cleanDetails = useSelector((state) => state.booking.cleanDetails);

  useEffect(() => {
    const arrayOfHoursObjects = generateArrayOfHoursObject(
      serviceFormField.min_value,
      serviceFormField.max_value,
      serviceFormField.increment_value
    );
    // set selected based on store value
    const arrayToSet = arrayOfHoursObjects.map((hour) => {
      const isSelected = hour.numberOfHours === cleanDetails.hours;
      const objectToReturn = {
        ...hour,
        selected: isSelected,
      };
      return objectToReturn;
    });
    setHours(arrayToSet);
  }, [cleanDetails, serviceFormField]);

  const handleSelectedHour = async (clickedHourId: number) => {
    // find hour object so the right component can be selected on click
    // and all the other components are unselected
    if (clickedHourId) {
      const arrayToSet = hours.map((hour) => {
        const isSelected = hour.id === clickedHourId;
        isSelected && dispatch(setVariantHours(hour.numberOfHours));
        const objectToReturn = {
          ...hour,
          selected: isSelected,
        };
        return objectToReturn;
      });
      setHours(arrayToSet);
    }
  };

  return (
    <Stack direction="column">
      <Typography
        sx={{
          ml: 1,
          mb: 1,
          mt: 0.5,
          fontSize: '0.9rem',
          color: 'text.secondary',
        }}
      >
        {serviceFormField.name}
      </Typography>
      <Stack
        direction="row"
        spacing={2}
        alignItems="center"
        justifyContent="space-between"
        sx={{ width: '100%', px: 1 }}
      >
        <Stack direction="row" sx={{ flexWrap: 'wrap', gap: '1rem' }}>
          {!isEmpty(hours) &&
            hours.map((option, index) => (
              <CustomPickerComponent
                onClick={() => handleSelectedHour(option.id)}
                label={option.numberOfHours}
                index={index}
                key={index}
                selected={option.selected}
              />
            ))}
        </Stack>
      </Stack>
    </Stack>
  );
}
