import { Box, FormControlLabel, Switch } from '@mui/material';
import { dispatch, useSelector } from '@/stores';
import { toggleCleaningProduct } from '@/features/bookings-management/slices';

export default function CleaingProducts() {
  const booking = useSelector((state) => state.booking);
  return (
    <Box sx={{ pl: 1.3 }}>
      <FormControlLabel
        control={
          <Switch
            onChange={(e) => dispatch(toggleCleaningProduct(e.target.checked))}
            checked={booking.cleanDetails.requestForCleaningProduct}
          />
        }
        label="Add cleaning products"
      />
    </Box>
  );
}
