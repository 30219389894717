import { ReactNode } from 'react';
// @mui
import { styled } from '@mui/material/styles';
import { Typography, Box, BoxProps } from '@mui/material';
//
import Image from './Image';

// ----------------------------------------------------------------------

const RootStyle = styled(Box)(({ theme }) => ({
  height: '100%',
  display: 'flex',
  textAlign: 'center',
  alignItems: 'center',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: theme.spacing(8, 2),
}));

// ----------------------------------------------------------------------

interface Props extends BoxProps {
  title: string;
  img?: string;
  reversed?: boolean;
  description?: string | ReactNode;
}

export default function EmptyContent({
  title,
  description,
  img,
  reversed = false,
  ...other
}: Props) {
  return (
    <RootStyle
      sx={{
        flexDirection: reversed ? 'column-reverse' : 'column',
        gap: reversed ? 1 : 0,
      }}
      {...other}
    >
      <Image
        disabledEffect
        visibleByDefault
        alt="empty content"
        src={img || '/assets/illustrations/illustration_empty_content.svg'}
        sx={{ height: 240, mb: reversed ? 0 : 3, mt: reversed ? 3 : 0 }}
      />

      <Typography variant="h5" gutterBottom>
        {title}
      </Typography>

      {typeof description === 'string' ? (
        <Typography
          variant="body2"
          data-testid="custom-element"
          role="description"
          sx={{ color: 'text.secondary' }}
        >
          {description}
        </Typography>
      ) : (
        <Box>{description}</Box>
      )}
    </RootStyle>
  );
}
