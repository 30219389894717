import { isEmpty } from 'lodash';
import { useSelector } from '@/stores';
// mui
import { Stack } from '@mui/material';
import { Label } from '@/components';
import { SummaryFieldLabel } from '.';
//
import { ServiceVariant } from '@/types';

export default function SummaryCleanType() {
  const booking = useSelector((state) => state.booking);
  return (
    <Stack direction="row" gap={2}>
      <SummaryFieldLabel>Clean type</SummaryFieldLabel>

      {!isEmpty(booking.selectedServiceVariant) &&
        (booking.selectedServiceVariant as ServiceVariant).name.length > 0 && (
          <Label
            sx={{
              backgroundColor: 'primary.main',
              color: 'background.paper',
              ml: 'auto',
            }}
          >
            {(booking.selectedServiceVariant as ServiceVariant).name}
          </Label>
        )}
    </Stack>
  );
}
