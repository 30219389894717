import { useState, useEffect } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';
// api
import { getProductData, deleteProduct } from '../api/index';
// @mui
import {
  Box,
  Button,
  Card,
  Container,
  FormControlLabel,
  IconButton,
  Switch,
  Table,
  TableBody,
  TableContainer,
  TablePagination,
  Tooltip,
  Skeleton,
} from '@mui/material';
// routes
import { productsPath, ROOTS_PRODUCTS } from '../routes/paths';
import { PATH_USER } from '@/features/app';
// hooks
import useSettings from '@/hooks/useSettings';
import useTable, { getComparator, emptyRows } from '@/hooks/useTable';
import { useGetAccess } from '@/hooks/useAccess';
// components
import { Page, Iconify, Scrollbar, HeaderBreadcrumbs } from '@/components';
import {
  TableNoData,
  TableEmptyRows,
  TableSelectedActions,
  TableHeadCustom,
} from '@/components/table';
// custom components
import { ProductTableToolbar, ProductTableRow } from '../components';
// types
import type { Product } from '../types';
// sort function
import { applySortFilterForProductList } from '../utils';
import { uniq } from 'lodash';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'name', label: 'Name', align: 'left' },
  { id: 'category', label: 'Category', align: 'left' },
  { id: 'description', label: 'Description', align: 'left' },
  { id: 'provider', label: 'Provider', align: 'left' },

  { id: 'active', label: 'Status', align: 'left' },
  { id: '' },
];

const PRODUCT_STATUS = ['all', 'active', 'inactive'];
let PRODUCT_CATEGORY_OPTIONS: string[] = [];
// ----------------------------------------------------------------------

export default function ProductList() {
  const {
    dense,
    page,
    order,
    orderBy,
    rowsPerPage,
    setPage,
    //
    selected,
    setSelected,
    onSelectRow,
    onSelectAllRows,
    //
    onSort,
    onChangeDense,
    onChangePage,
    onChangeRowsPerPage,
  } = useTable();

  const { themeStretch } = useSettings();

  const navigate = useNavigate();

  const { enqueueSnackbar } = useSnackbar();

  // --------------------------------------------------------------------------
  // API CALL + USESTATE + SETTING FETCHDATA

  const [tableData, setTableData] = useState<Product[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    getProductData().then((response) => {
      const dataEmptyAssign = response;

      dataEmptyAssign.map(
        (item) =>
          (item.product_category ??= { id: 10000, name: 'Not Assigned' })
      );

      setTableData(dataEmptyAssign);
    });
    setIsLoading(false);
  }, []);

  // Set Categories options and add 'All' as a first option
  const categoryFromData = tableData.map(
    (category: Product) => category.product_category.name
  );
  PRODUCT_CATEGORY_OPTIONS = ['all'].concat(uniq(categoryFromData));

  // get the access for the module
  const allow = useGetAccess('products');

  // --------------------------------------------------------------------------
  //  FILTER & DELETE FUNCTIONS

  const [filterName, setFilterName] = useState('');
  const [filterStatus, setFilterStatus] = useState('all');
  const [filterCategory, setFilterCategory] = useState('all');

  const handleFilterName = (filterName: string) => {
    setFilterName(filterName.toLowerCase());
    setPage(0);
  };

  const handleFilterStatus = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFilterStatus(event.target.value);
  };

  const handleFilterCategory = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFilterCategory(event.target.value);
  };

  const handleDeleteProduct = async (id: string) => {
    const { status } = await deleteProduct(Number(id));
    if (status === 200) {
      const deleteRow = tableData.filter((row) => String(row.id) !== id);
      setSelected([]);
      setTableData(deleteRow);
      enqueueSnackbar('Product successfully deleted');
    }
  };

  const handleDeleteRows = (selected: string[]) => {
    const deleteRows = tableData.filter(
      (row) => !selected.includes(String(row.id))
    );
    setSelected([]);
    setTableData(deleteRows);
  };

  const handleEditRow = (id: number) => {
    navigate(productsPath('edit', 'id', id));
  };

  const dataFiltered = applySortFilterForProductList({
    tableData,
    comparator: getComparator(order, orderBy),
    filterName,
    filterCategory,
    filterStatus,
  });

  const denseHeight = dense ? 52 : 72;

  const isNotFound =
    (!dataFiltered.length && !!filterName) ||
    (!dataFiltered.length && !!filterStatus) ||
    (!dataFiltered.length && !!filterCategory);

  return (
    <>
      {
        <Page title="Products">
          <Container maxWidth={themeStretch ? false : 'lg'}>
            <HeaderBreadcrumbs
              heading="Products"
              links={[
                { name: 'Dashboard', href: PATH_USER.root },
                {
                  name: 'Products Managment',
                  href: `${ROOTS_PRODUCTS}`,
                },
                { name: 'Products' },
              ]}
              action={
                allow.canCreate && (
                  <Button
                    variant="contained"
                    component={RouterLink}
                    to={productsPath('new')}
                    startIcon={<Iconify icon={'eva:plus-fill'} />}
                  >
                    add new product
                  </Button>
                )
              }
            />

            <Card>
              {isLoading ? (
                <Box pl={2} pb={2}>
                  <Skeleton sx={{ width: 0.98 }} height={100} />
                </Box>
              ) : (
                <ProductTableToolbar
                  filterName={filterName}
                  filterStatus={filterStatus}
                  filterCategory={filterCategory}
                  onFilterName={handleFilterName}
                  onFilterCategory={handleFilterCategory}
                  onFilterStatus={handleFilterStatus}
                  optionsStatus={PRODUCT_STATUS}
                  optionsCategory={PRODUCT_CATEGORY_OPTIONS}
                />
              )}
              {isLoading ? (
                <Box pl={2} pb={2}>
                  {[...Array(5)].map((_, index) => (
                    <Skeleton key={index} sx={{ width: 0.98 }} height={40} />
                  ))}
                </Box>
              ) : (
                <Scrollbar>
                  <TableContainer sx={{ minWidth: 800, position: 'relative' }}>
                    {selected.length > 0 && (
                      <TableSelectedActions
                        dense={dense}
                        numSelected={selected.length}
                        rowCount={tableData.length}
                        onSelectAllRows={(checked) =>
                          onSelectAllRows(
                            checked,
                            tableData.map((row) => String(row.id))
                          )
                        }
                        actions={
                          <Tooltip title="Delete">
                            <IconButton
                              color="primary"
                              onClick={() => handleDeleteRows(selected)}
                              disabled={!allow.canDelete}
                            >
                              <Iconify icon={'eva:trash-2-outline'} />
                            </IconButton>
                          </Tooltip>
                        }
                      />
                    )}
                    <Table size={dense ? 'small' : 'medium'}>
                      <TableHeadCustom
                        order={order}
                        orderBy={orderBy}
                        headLabel={TABLE_HEAD}
                        rowCount={tableData.length}
                        numSelected={selected.length}
                        onSort={onSort}
                        onSelectAllRows={(checked) =>
                          onSelectAllRows(
                            checked,
                            tableData.map((row) => String(row.id))
                          )
                        }
                      />

                      <TableBody>
                        {dataFiltered
                          .slice(
                            page * rowsPerPage,
                            page * rowsPerPage + rowsPerPage
                          )
                          .map((row) => (
                            <ProductTableRow
                              key={row.id}
                              row={row}
                              selected={selected.includes(String(row.id))}
                              onSelectRow={() => onSelectRow(String(row.id))}
                              onDeleteRow={() =>
                                handleDeleteProduct(String(row.id))
                              }
                              onEditRow={() => handleEditRow(row.id)}
                              permissions={allow}
                            />
                          ))}

                        <TableEmptyRows
                          height={denseHeight}
                          emptyRows={emptyRows(
                            page,
                            rowsPerPage,
                            tableData.length
                          )}
                        />

                        <TableNoData isNotFound={isNotFound} />
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Scrollbar>
              )}

              <Box sx={{ position: 'relative' }}>
                <TablePagination
                  rowsPerPageOptions={[25, 50, 100]}
                  component="div"
                  count={tableData.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={onChangePage}
                  onRowsPerPageChange={onChangeRowsPerPage}
                />

                <FormControlLabel
                  control={<Switch checked={dense} onChange={onChangeDense} />}
                  label="Dense"
                  sx={{ px: 3, py: 1.5, top: 0, position: { md: 'absolute' } }}
                />
              </Box>
            </Card>
          </Container>
        </Page>
      }
    </>
  );
}
