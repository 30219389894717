import { useState } from 'react';
import { useParams } from 'react-router';
import { useQuery } from '@tanstack/react-query';
//
import {
  Box,
  Card,
  Skeleton,
  Stack,
  Table,
  TableBody,
  TableContainer,
} from '@mui/material';
//
import { Scrollbar } from '@/components';
import {
  TableEmptyRows,
  TableHeadCustom,
  TableNoData,
} from '@/components/table';
import { ToolbarWithSearchAndStatus } from '@/components/toolbar';
import { VariantCustomPricesTableRow } from '..';
// api
import { getVariantCustomPrices } from '../../api';
// utils + hooks
import useTable, { emptyRows, getComparator } from '@/hooks/useTable';
import { useGetAccess } from '@/hooks/useAccess';
import { useSnackbarMsg } from '@/hooks/useSnackbarMsg';
import {
  applySortFilterVariantCustomPrices,
  VARIANTS_CUSTOM_PRICES_TABLE_HEAD,
} from '../../utils';
//

export default function VariantCustomCityPrices() {
  const {
    dense,
    page,
    order,
    orderBy,
    rowsPerPage,
    setPage,
    setSelected,
    onSort,
  } = useTable({ defaultOrderBy: 'end_date', defaultOrder: 'desc' });

  const allow = useGetAccess('service-management'); // TODO: double check this
  const { errorMsg } = useSnackbarMsg();
  const { variantId } = useParams();

  const [searchFieldValue, setSearchFieldValue] = useState('');

  // =================
  //      QUERIES
  // =================

  // GET
  const { data: variantCustomPrices, isLoading: isPricesLoading } = useQuery({
    queryKey: ['variantCustomPrices'],
    queryFn: () => getVariantCustomPrices(String(variantId)),
    onError: (error) => {
      errorMsg(error, `Something went wrong while fetching settings data`);
    },
    enabled: !!variantId,
  });

  // ======================
  //     FILTER, UPDATE
  // ======================

  const dataFiltered = applySortFilterVariantCustomPrices({
    tableData: variantCustomPrices || [],
    comparator: getComparator(order, orderBy),
    searchFieldValue,
  });

  const handleSearchFieldValueChange = (newValue: string) => {
    setSearchFieldValue(newValue);
    setPage(0);
    setSelected([]);
  };

  const isNotFound = !dataFiltered.length && !!searchFieldValue;
  const denseHeight = dense ? 52 : 72;
  const showSkeleton = isPricesLoading;

  return (
    <Stack spacing={2} component={Card} sx={{ px: 3, py: 1.5 }}>
      {!showSkeleton ? (
        <Box>
          <ToolbarWithSearchAndStatus
            filterText={searchFieldValue}
            onFilterText={handleSearchFieldValueChange}
            labels={{ search: 'Search in custom prices...' }}
          />
          <Scrollbar>
            <TableContainer>
              <Table size="small">
                <TableHeadCustom
                  order={order}
                  orderBy={orderBy}
                  headLabel={VARIANTS_CUSTOM_PRICES_TABLE_HEAD}
                  rowCount={dataFiltered.length}
                  onSort={onSort}
                />
                <TableBody>
                  {dataFiltered
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((price) => (
                      <VariantCustomPricesTableRow
                        key={price.id}
                        row={price}
                        canDelete={allow.canDelete}
                        canUpdate={allow.canUpdate}
                      />
                    ))}

                  <TableEmptyRows
                    height={denseHeight}
                    emptyRows={emptyRows(
                      page,
                      rowsPerPage,
                      dataFiltered.length
                    )}
                  />

                  <TableNoData
                    isNotFound={isNotFound}
                    title="No custom price found"
                  />
                </TableBody>
              </Table>
            </TableContainer>
          </Scrollbar>
        </Box>
      ) : (
        [...new Array(7)].map((_, index) => (
          <Skeleton variant="rounded" height={40} key={index} />
        ))
      )}
    </Stack>
  );
}
