import axios from '@/utils/axios';
import { DashboardData } from '../types';

export async function getDashboardData(): Promise<DashboardData> {
  const {
    data: { data },
  } = await axios.get('/me/dashboard/data');

  return data;
}
