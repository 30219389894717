import React from 'react';

export const JobSupportChannelPreview = ({
  name,
  date = '',
  time = '',
  unreadCount = 0,
}: {
  name: string;
  date: string;
  time: string;
  unreadCount: number;
}) => (
  <div className={`channel-preview__item_job ${unreadCount > 0 ? 'bold' : ''}`}>
    <div className="channel-preview__name">{`${name}`}</div>
    <div className="channel-preview__date">{`${date} ${time}`}</div>
    {unreadCount > 0 && (
      <div className="channel-preview__unread-count">{unreadCount}</div>
    )}
  </div>
);
