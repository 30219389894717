import { BaseObjectEntity } from '@/types';
import { path } from '@/utils/formatLink';
import { generatePath } from 'react-router';

export const ROOTS_SERVICES_MANAGEMENT = '/services';

export const PATH_SERVICES_MANAGEMENT: BaseObjectEntity = {
  root: ROOTS_SERVICES_MANAGEMENT,
  services: '/services-management',
  variants: '/service-variants',
  newService: '/services-management/new',
  newVariant: '/service-variants/new',
  editService: '/services-management/:serviceId/edit',
  editVariant: '/service-variants/:variantId/edit/:tabSid',
};

export function servicesManagementPath(
  pathKey: string,
  params?: BaseObjectEntity
) {
  return generatePath(
    path(ROOTS_SERVICES_MANAGEMENT, PATH_SERVICES_MANAGEMENT[pathKey]),
    params
  );
}
