import { useEffect } from 'react';
// mui
import { Box, Stack, useTheme } from '@mui/material';
// components
import AccountPopover from '@/layouts/dashboard/header/AccountPopover';
import Searchbar from '@/layouts/dashboard/header/Searchbar';
import { NotificationsPopover } from '@/features/notification/components';
//
import { RightSidebarIcon } from '.';
// redux
import { dispatch, useSelector } from '@/stores';
import { setSelectedMenubarIconId } from '../slices';
// hooks + utils
import useAuth from '@/hooks/useAuth';
import { RIGHT_SIDEBAR_MENU_OPTIONS } from '../utils';
import { RIGHT_SIDEBAR, STREAM_KEY } from '@/config';
// chat-stream
import { useChatClient } from '@/hooks/useChatClient';
import { StreamChat, User } from 'stream-chat';
import { updateUnreadMessages } from '@/features/chat-stream/slices';
// types

export default function RightSidebar() {
  const theme = useTheme();
  const { user } = useAuth();
  const { selectedMenubarIconId } = useSelector((state) => state.rightSideBar);

  const handleIconClick = (id: number) => {
    const idToSend = selectedMenubarIconId === id ? 0 : id;
    dispatch(setSelectedMenubarIconId(idToSend));
  };

  // CHAT

  let chatClient: StreamChat | null = null; // Declare chatClient variable outside the conditional block

  if (user?.chat_token) {
    const apiKey = STREAM_KEY;
    const userToken = user?.chat_token || '';
    const userId = user?.chat_user_id;
    const userName = user?.displayName;

    const chatUser: User = {
      id: userId,
      name: userName,
      image: user?.profile_image,
    };

    // eslint-disable-next-line react-hooks/rules-of-hooks
    chatClient = useChatClient({
      // Initialize chatClient
      apiKey,
      user: chatUser,
      tokenOrProvider: userToken,
    });
  }

  useEffect(() => {
    if (chatClient) {
      chatClient.on((event) => {
        if (event.total_unread_count !== undefined) {
          dispatch(
            updateUnreadMessages({ unreadCount: event.total_unread_count })
          );

          // Show the browser notification
          if (
            typeof Notification !== 'undefined' &&
            event.message?.user?.id !== user?.chat_user_id &&
            event.message?.user?.id !== undefined
          ) {
            new Notification('New Message', {
              body: event?.message?.text,
            });
          }
        }
      });
    }
  }, [chatClient, user?.chat_user_id]);

  return (
    <Box
      sx={{
        bgcolor: (theme) =>
          theme.palette.mode === 'light'
            ? theme.palette.grey[200]
            : theme.palette.grey[800],
        zIndex: theme.zIndex.appBar + 2,
        position: 'sticky',
        top: 0,
        right: 0,
        height: '100vh',
        width: RIGHT_SIDEBAR.BASE_WIDTH,
      }}
    >
      <Stack spacing={1} alignItems={'center'} sx={{ pt: 2 }}>
        <AccountPopover />
        <NotificationsPopover />
        <Searchbar />
      </Stack>
      <Stack
        sx={{ pt: 2 }}
        direction="column"
        alignItems="center"
        justifyContent="center"
        spacing={1}
      >
        {RIGHT_SIDEBAR_MENU_OPTIONS.map((menuOption) => (
          <RightSidebarIcon
            key={menuOption.id}
            id={menuOption.id}
            title={menuOption.title}
            icon={menuOption.icon}
            onClick={() => handleIconClick(menuOption.id)}
            hideIcon={!user?.chat_token && menuOption.slug === 'chat'}
          />
        ))}
      </Stack>
    </Box>
  );
}
