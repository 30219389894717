import { BaseObjectEntity } from '@/types';
import { path } from '@/utils/formatLink';
import { generatePath } from 'react-router';

export const ROOTS_TASKS = '/tasks';

export const PATH_TASKS: BaseObjectEntity = {
  root: ROOTS_TASKS,
};

export function invoicesPath(pathKey: string, params?: BaseObjectEntity) {
  return generatePath(path(ROOTS_TASKS, PATH_TASKS[pathKey]), params);
}
