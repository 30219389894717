import { useState, MouseEvent } from 'react';
import { useNavigate } from 'react-router';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useSnackbarMsg } from '@/hooks/useSnackbarMsg';
// mui
import { Box, Chip, MenuItem, Stack, TableCell, TableRow } from '@mui/material';
// components
import { TableMoreMenu, TableRowLink } from '@/components/table';
import { Avatar, Iconify, Label } from '@/components';
import DialogSimple from '@/components/dialog/DialogSimple';
import { couponsPath } from '..';
// api
import { updateCoupon } from '../api';
// hooks + utilis
import { clientManagementPath } from '@/features/users/clients';
// types
import { Coupon, CouponUpdateDialog } from '../types';
import { ButtonColors } from '@/types';

type CouponCustomRowProps = {
  row: Coupon;
  onEditRow: VoidFunction;
  onDeleteRow: VoidFunction;
  canUpdate: boolean;
  canDelete: boolean;
};

// ----------------------------------------------------------------------
export default function CouponTableRow({
  row,
  //
  onEditRow,
  onDeleteRow,
  //
  canUpdate,
  canDelete,
}: CouponCustomRowProps) {
  const { errorMsg, successMsg } = useSnackbarMsg();
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const {
    id,
    code,
    description,
    value,
    value_type,
    usage_limit,
    start_date,
    end_date,
    created_at,
    coupon_platforms,
    active,
    drip_sessions,
    users,
  } = row;

  const formatDate = (date: string) => {
    const formattedDate = new Date(date).toLocaleDateString('en-gb', {
      weekday: 'short',
      year: '2-digit',
      month: 'short',
      day: 'numeric',
    });
    return formattedDate;
  };

  // ===================
  //      3DOT MENU
  // ===================

  const [openMenu, setOpenMenuActions] = useState<HTMLElement | null>(null);

  const handleOpenMenu = (event: MouseEvent<HTMLElement>) => {
    setOpenMenuActions(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setOpenMenuActions(null);
  };

  // ============================
  //    DELETE / UPDATE COUPON
  // ============================

  const [selectedDialog, setSelectedDialog] =
    useState<CouponUpdateDialog | null>(null);

  const updateCouponStatusMutation = useMutation({
    mutationFn: ({ id, active }: { id: number; active: boolean }) =>
      updateCoupon(id, { active }),
    onSuccess: (_apiResponse, variables) => {
      queryClient.setQueryData(['coupons'], (old: Coupon[] = []) =>
        old.filter((b: any) => b.id !== variables)
      );
      queryClient.invalidateQueries(['coupons']);
      successMsg(`Coupon succesfully ${!active ? 'activated' : 'deactivated'}`);
    },
    onError: (error) => {
      errorMsg(error, `There was an error while deleting coupon`);
    },
  });

  const DIALOGS = [
    {
      name: 'delete',
      title: 'Delete coupon',
      text: `Are you sure you want to delete this coupon: ${code}?`,
      loadingButtonText: 'Delete',
      loadingButtonColor: 'error' as ButtonColors,
      disagreeButtonText: 'Cancel',
      agreeFunction: () => onDeleteRow(),
    },
    {
      name: 'deactivate',
      title: 'Deactivate coupon',
      text: `Are you sure you want to deactivate this coupon: ${code} (${description})?`,
      loadingButtonText: 'Deactivate',
      disagreeButtonText: 'Cancel',
      agreeFunction: () =>
        updateCouponStatusMutation.mutate({ id, active: false }),
    },
    {
      name: 'reactivate',
      title: 'Reactivate coupon',
      text: `Are you sure you want to reactivate this coupon: ${code} (${description})?`,
      loadingButtonText: 'Reactivate',
      disagreeButtonText: 'Cancel',
      agreeFunction: () =>
        updateCouponStatusMutation.mutate({ id, active: true }),
    },
  ];

  const handleOpenDialog = (dialogName: string) => {
    const selectedDialog = DIALOGS.find((d) => d.name === dialogName);
    selectedDialog
      ? setSelectedDialog(selectedDialog)
      : errorMsg(null, 'Dialog not found');
  };

  const handleClickChip = () => {
    navigate(
      clientManagementPath('edit', {
        userId: String(users?.id),
        tabSid: 'details',
      })
    );
  };

  // ----------------------------------------------------------------------

  return (
    <>
      {selectedDialog && (
        <DialogSimple
          dialogTitle={selectedDialog.title}
          open={!!selectedDialog}
          onClickButtonClose={() => setSelectedDialog(null)}
          onClickButtonLoading={selectedDialog.agreeFunction}
          loadingButtonText={selectedDialog.loadingButtonText}
          disagreeButtonText={selectedDialog.disagreeButtonText}
          loadingButtonColor={
            selectedDialog.loadingButtonColor ?? ('primary' as ButtonColors)
          }
          disagreeButtonColor="inherit"
        >
          <Box sx={{ px: 3, py: 1.5 }}>{selectedDialog.text}</Box>
        </DialogSimple>
      )}

      <TableRow hover sx={{ verticalAlign: 'top' }}>
        <TableCell align="left" width={20} />
        <TableCell align="left" width={20}>
          {users && (
            <Chip
              size="small"
              avatar={
                <Avatar alt="profile-picture" src={users?.profile_image} />
              }
              label={users?.first_name + ' ' + users?.last_name}
              color="default"
              clickable={true}
              onClick={handleClickChip}
            />
          )}
        </TableCell>
        <TableCell align="left">
          <TableRowLink
            linkText={code}
            linkTo={couponsPath('edit', { couponId: String(id) })}
            sx={{ width: 'fit-content' }}
          />
        </TableCell>

        <TableCell align="left">{description}</TableCell>
        <TableCell align="left">{value}</TableCell>

        <TableCell align="left">{value_type}</TableCell>
        <TableCell align="left">{usage_limit}</TableCell>
        <TableCell align="left">{drip_sessions}</TableCell>
        <TableCell align="left" width={200}>
          <Stack direction="column" spacing={0.5} flexWrap="wrap">
            {coupon_platforms.map((p) => (
              <Label color="info" key={p.id}>
                {p.name}
              </Label>
            ))}
          </Stack>
        </TableCell>
        <TableCell align="left">{formatDate(start_date)}</TableCell>
        <TableCell align="left">{formatDate(end_date)}</TableCell>
        <TableCell align="left">{formatDate(created_at)}</TableCell>
        <TableCell align="left">
          {
            <Label color={active ? 'success' : 'error'}>
              {active ? 'Active' : 'Inactive'}
            </Label>
          }
        </TableCell>

        {/* ACTION BUTTONS */}
        <TableCell align="right">
          {(canDelete || canUpdate) && (
            <TableMoreMenu
              open={openMenu}
              onOpen={handleOpenMenu}
              onClose={handleCloseMenu}
              arrow="top-right"
              actions={
                <>
                  {canUpdate && (
                    <MenuItem
                      onClick={() => {
                        onEditRow();
                        handleCloseMenu();
                      }}
                    >
                      <Iconify icon={'eva:edit-fill'} />
                      Edit
                    </MenuItem>
                  )}
                  {canUpdate && active && (
                    <MenuItem
                      onClick={() => {
                        handleOpenDialog('deactivate');
                        handleCloseMenu();
                      }}
                    >
                      <Iconify icon={'eva:pause-circle-outline'} />
                      Deactivate
                    </MenuItem>
                  )}
                  {canUpdate && !active && (
                    <MenuItem
                      onClick={() => {
                        handleOpenDialog('reactivate');
                        handleCloseMenu();
                      }}
                    >
                      <Iconify icon={'eva:refresh-outline'} />
                      Reactivate
                    </MenuItem>
                  )}
                  {canDelete && (
                    <MenuItem
                      onClick={() => {
                        handleOpenDialog('delete');
                        handleCloseMenu();
                      }}
                      sx={{ color: 'error.main' }}
                    >
                      <Iconify icon={'eva:trash-2-outline'} />
                      Delete
                    </MenuItem>
                  )}
                </>
              }
            />
          )}
        </TableCell>
      </TableRow>
    </>
  );
}
