import {
  isBefore,
  isAfter,
  isWithinInterval,
  isToday,
  parseISO,
} from 'date-fns';
// global util
// types
import { BookingSession } from '../types';
import { tableSortFilter } from '@/utils/arrayProcessing';
import { StatusWithSyleClass } from '@/types';
type FilterBookingsInputs = {
  tableData: BookingSession[];
  comparator: (a: any, b: any) => number;
  searchFieldValue: string;
  filterServiceId: number;
  filterServiceTypeId: number;
  filterStartDate: string | null;
  filterEndDate: string | null;
  filterStatusId: number;
  filterPaymentStatusId: number;
  //
  servicesWithTheirVariants: { id: number; name: string; variants: number[] }[];
};

export function applySortFilterSessions({
  tableData,
  comparator,
  searchFieldValue,
  filterServiceId,
  filterServiceTypeId,
  filterStartDate,
  filterEndDate,
  filterStatusId,
  filterPaymentStatusId,
  //
  servicesWithTheirVariants,
}: FilterBookingsInputs) {
  tableData = tableSortFilter(tableData, comparator);

  // ===================
  //     SEARCH BAR
  // ===================
  if (searchFieldValue) {
    const seachFieldValueLowerCase = searchFieldValue.toLowerCase();

    tableData = tableData.filter((session) => {
      const user = `${session.user?.first_name} ${session.user?.last_name}`;
      return (
        session.id.toString().indexOf(seachFieldValueLowerCase) !== -1 ||
        user.toLowerCase().indexOf(seachFieldValueLowerCase) !== -1 ||
        session.user.email.toLowerCase().indexOf(seachFieldValueLowerCase) !==
          -1 ||
        session.address.postcode
          .toLowerCase()
          .indexOf(seachFieldValueLowerCase) !== -1 ||
        session.address.postcode
          .toLowerCase()
          .replace(/\s/g, '')
          .indexOf(seachFieldValueLowerCase) !== -1 ||
        session.address.formatted_address
          .toLowerCase()
          .indexOf(seachFieldValueLowerCase) !== -1
        // booking.total_amount.toString().indexOf(seachFieldValueLowerCase) !== -1
      );
    });
  }

  // ===================
  //    SERVICE NAME
  // ===================
  if (filterServiceId !== 0) {
    tableData = tableData.filter((session) => {
      const selectedService = servicesWithTheirVariants.find(
        (service) => service.id === filterServiceId
      );
      return selectedService?.variants.includes(session.service_variant.id);
    });
  }

  // ==========================
  //    SERVICE TYPE/VARIANT
  // ==========================
  if (filterServiceTypeId !== 0) {
    tableData = tableData.filter(
      (session) => session.service_variant.id === filterServiceTypeId
    );
  }

  // ===================
  //        DATES
  // ===================
  if (filterStartDate && filterEndDate) {
    tableData = tableData.filter((session) => {
      if (session.date.length > 0) {
        return isWithinInterval(new Date(session.date), {
          start: new Date(filterStartDate),
          end: new Date(filterEndDate),
        });
      }
      return false;
    });
  }

  if (filterStartDate) {
    tableData = tableData.filter((session) => {
      if (session.date.length > 0) {
        return isAfter(new Date(session.date), new Date(filterStartDate));
      }
      return false;
    });
  }

  if (filterEndDate) {
    tableData = tableData.filter((session) => {
      if (session.date.length > 0) {
        return isBefore(new Date(session.date), new Date(filterEndDate));
      }
      return false;
    });
  }

  // ====================
  //    BOOKING STATUS
  // ====================
  if (filterStatusId !== 0) {
    tableData = tableData.filter(
      (session) => session.status_id === filterStatusId
    );
  }
  // ====================
  //    PAYMENT STATUS
  // ====================
  if (filterPaymentStatusId !== 0) {
    tableData = tableData.filter(
      (session) => session.payment_status_id === filterPaymentStatusId
    );
  }

  return tableData;
}

export const getNamedInputValueFromForm = (
  event: React.FormEvent<HTMLFormElement>,
  inputName: string
) => {
  const form = event.target as HTMLFormElement;
  const input = form.elements.namedItem(inputName) as HTMLInputElement;
  return input.value;
};

// =================
//    TABLE HEAD
// =================

export const SESSIONS_TABLE_HEAD = [
  { id: 'id', label: 'Session ID', align: 'left' },
  {
    id: 'service_variant.name',
    label: 'Service type',
    align: 'left',
  },
  { id: 'date', label: 'Date', align: 'left' },
  { id: 'time', label: 'Time', align: 'left' },
  { id: 'total_hours', label: 'Total hours', align: 'left', width: '20px' },
  { id: 'user.first_name', label: 'Customer Details', align: 'left' },
  {
    id: 'booking_session_cleaners.first_name',
    label: 'Cleaner',
    align: 'left',
  },
  { id: 'address.formatted_address', label: 'Address', align: 'left' },
  {
    id: 'booking_session_status.display',
    label: 'Session status',
    align: 'left',
  },
  { id: 'toggle-accordion' },
];

export const returnSessionFilterFromDate = (dateString: string) => {
  const date = parseISO(dateString);
  const today = new Date();

  if (isToday(date)) {
    return 'today';
  } else if (isBefore(date, today)) {
    return 'previous';
  } else if (isAfter(date, today)) {
    return 'upcoming';
  }

  return 'error'; // In case the date is invalid or some other issue
};

export const convertStatusHistoryCreatedAt = (
  createdAtDateTimeString: string
) => {
  // the app uses a format like this and that's what we get in the API response
  // created_at: "09-09-2024 09:43"
  const [datePart, timePart] = createdAtDateTimeString.split(' ');

  // Split the date part into day, month, and year
  const [day, month, year] = datePart.split('-').map(Number);

  // Split the time part into hours and minutes
  const [hours, minutes] = timePart.split(':').map(Number);

  // Create a new Date object (month is zero-based in JS Date object)
  const date = new Date(year, month - 1, day, hours, minutes);

  return date;
};

export const returnStatusObject = (
  statusName: string,
  statuses: StatusWithSyleClass[]
) => {
  const notFound = {
    id: 0,
    value: 'NOT_FOUND',
    display: '??Error??',
    style_class: 'error',
  };
  const statusObject = statuses.find(
    (s) => s.display.toLowerCase() === statusName.toLowerCase()
  );

  return statusObject || notFound;
};

export const getInputValue = (
  event: React.FormEvent<HTMLFormElement>,
  inputName: string
) => {
  const form = event.target as HTMLFormElement;
  const input = form.elements.namedItem(inputName) as HTMLInputElement;
  return input.value;
};
