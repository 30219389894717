import {
  Checkbox,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
} from '@mui/material';
import Select, { SelectChangeEvent } from '@mui/material/Select';
// redux
import { useSelector, dispatch } from '@/stores';
import { setRecurringDays } from '@/features/bookings-management/slices';
// hooks + utils
import useResponsive from '@/hooks/useResponsive';
import { getSelectedDayNames } from '@/features/bookings-management/utils';
import { DAYS_OF_WEEK } from '@/utils/months-and-days';

export default function MultipleSelectCheckmarks() {
  const downMd = useResponsive('down', 'md');
  const { recurring_days } = useSelector((state) => state.booking);

  const handleChange = (event: SelectChangeEvent<typeof recurring_days>) => {
    const {
      target: { value },
    } = event;

    // in redux store the values are stored aa an array of day IDs --> see DAYS_OF_WEEK
    const arrayOfDayIDs = typeof value === 'string' ? [Number(value)] : value;
    dispatch(setRecurringDays(arrayOfDayIDs.sort()));
  };

  const selectedDays = getSelectedDayNames(recurring_days);

  return (
    <div>
      <FormControl sx={{ height: 40, width: downMd ? '100%' : '75%' }}>
        <InputLabel id="multiple-checkbox-label" size="small">
          Day(s) of recurrence
        </InputLabel>
        <Select
          labelId="multiple-checkbox-label"
          id="multiple-checkbox"
          multiple
          value={recurring_days}
          onChange={handleChange}
          input={<OutlinedInput label="Day(s) of recurrence" />}
          renderValue={() => selectedDays.fullNames.join(', ')}
          size="small"
        >
          {DAYS_OF_WEEK.map((day) => (
            <MenuItem key={day.id} value={day.id}>
              <Checkbox checked={recurring_days.indexOf(day.id) > -1} />
              <ListItemText primary={day.full_name} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
}
