import { SvgIcon } from '@mui/material';

type IconProps = {
  iconUrl: string;
  width?: number;
  height?: number;
};
export default function SvgIconWrapper({
  iconUrl,
  width = 24,
  height = 24,
}: IconProps) {
  return (
    <SvgIcon>
      <image href={iconUrl} width={width} height={height} />
    </SvgIcon>
  );
}
