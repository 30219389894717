import axios from '@/utils/axios';
import { Service, SingleService, ServiceDto } from '../types';

export async function getServices(): Promise<Service[]> {
  const {
    data: { data },
  }: any = await axios.get('/services');
  return data;
}

export async function getSingleService(id: number): Promise<SingleService> {
  const {
    data: { data },
  }: any = await axios.get(`/services/${id}`);
  return data;
}

export async function createService(serviceDto: ServiceDto) {
  const data = await axios.post('/services', serviceDto);
  return data;
}

export async function updateService(id: number, serviceDto: ServiceDto) {
  const data = await axios.put(`/services/${id}`, serviceDto);
  return data;
}

// export async function deleteService(id: number) {
//   const data = await axios.delete(`/services/${id}`);
//   return data;
// }
