import { DAYS_OF_WEEK } from '@/utils/months-and-days';

/**
 * Get selected day names
 * @param {number[]} selectedIds - selected day ids
 * @returns {Object} - selected day names
 */
export function getSelectedDayNames(selectedIds: number[]) {
  const selectedDayObjects = DAYS_OF_WEEK.filter((day) =>
    selectedIds.includes(day.id)
  );

  // For full names
  const fullNames = selectedDayObjects.map((day) => day.full_name);
  // For short names
  const shortNames = selectedDayObjects.map((day) => day.short_name);
  // For recurrence names
  const recurrenceNames = selectedDayObjects.map(
    (day) => day.recurrence_short_name
  );

  return {
    fullNames,
    shortNames,
    recurrenceNames,
  };
}

/**
 * Return recurring day ids
 * @param {string} days - selected days in string format, ie. 'MO,TU,WE'
 * @returns {number[]} - recurring day ids
 */
export function returnRecurringDayIds(days: string) {
  const daysArray = days.split(',');
  let recurringDays: number[] = [];
  daysArray.forEach((day) => {
    const dayObject = DAYS_OF_WEEK.find(
      (d) => d.recurrence_short_name.toLowerCase() === day.toLowerCase()
    );
    dayObject && recurringDays.push(dayObject?.id);
  });
  return recurringDays;
}
