export const createDateAndTime = (time: string) => {
  const [hours, minutes] = time.split(':');
  const newDate = new Date();
  newDate.setHours(Number(hours));
  newDate.setMinutes(Number(minutes));
  const isoString = newDate.toISOString();
  const formattedDate = isoString.slice(0, 10);
  const formattedTime = isoString.slice(11, 16);

  return `${formattedDate}T${formattedTime}`;
  // return isoString;
};

export const UNASSIGNED_JOBS = [
  {
    id: 1,
    start: new Date(),
    end: createDateAndTime('20:00'),
    title: '#11111',
    location: 'BS1 1UQ',
    resource: 1,
    property: {
      type: 'Airbnb',
      icon: 'logos:airbnb-icon',
    },
    address: {
      line1: '23 Frogmore St',
      line2: 'AdreLine2',
      city: 'London',
      postcode: 'LS12 U23',
    },
    eventType: 0,
    // recurring: null,
  },

  {
    id: 2,
    start: createDateAndTime('08:00'),
    end: createDateAndTime('11:00'),
    title: '#85124',
    resource: 1,
    property: {
      type: 'Private',
      icon: 'ion:home-sharp',
    },
    address: {
      line1: '23 Frogmore St',
      line2: 'AdreLine2',
      city: 'London',
      postcode: 'LS12 U23',
    },
    eventType: 0,
  },
  {
    id: 3,
    start: createDateAndTime('09:00'),
    end: createDateAndTime('14:00'),
    title: '#54321',
    resource: 1,
    property: {
      type: 'Private',
      icon: 'ion:home-sharp',
    },
    address: {
      line1: '23 Frogmore St',
      line2: 'AdreLine2',
      city: 'London',
      postcode: 'LS12 U23',
    },
    eventType: 0,
  },
  {
    id: 4,
    start: createDateAndTime('13:00'),
    end: createDateAndTime('15:00'),
    title: '#66532',
    resource: 1,
    property: {
      type: 'Private',
      icon: 'ion:home-sharp',
    },
    address: {
      line1: '23 Frogmore St',
      line2: 'AdreLine2',
      city: 'London',
      postcode: 'LS12 U23',
    },
    eventType: 0,
  },
  {
    id: 5,
    start: createDateAndTime('10:00'),
    end: createDateAndTime('12:00'),
    title: '#43211',
    resource: 1,
    property: {
      type: 'Airbnb',
      icon: 'logos:airbnb-icon',
    },
    address: {
      line1: '23 Frogmore St',
      line2: 'AdreLine2',
      city: 'London',
      postcode: 'LS12 U23',
    },
    eventType: 0,
  },
  {
    id: 11,
    start: '2024-01-18T11:00',
    end: '2024-01-18T16:30',
    title: '#53321',
    resource: 2,
    property: {
      type: 'Airbnb',
      icon: 'logos:airbnb-icon',
    },
    address: {
      line1: '23 Frogmore St',
      line2: 'AdreLine2',
      city: 'London',
      postcode: 'HG45 U23',
    },
    eventType: 4,
  },
  {
    id: 12,
    start: '2024-01-19T13:30',
    end: '2024-01-19T16:30',
    title: '#94421',
    resource: 2,
    property: {
      type: 'Airbnb',
      icon: 'logos:airbnb-icon',
    },
    address: {
      line1: '23 Frogmore St',
      line2: 'AdreLine2',
      city: 'London',
      postcode: 'IU76 M99',
    },
    eventType: 4,
  },
];

// ====================
//    INVALID EVENTS
// ====================

export const invalidEvents = [
  {
    start: createDateAndTime('8:00'),
    end: createDateAndTime('16:00'),
    resource: 3, // this invalid will be displayed only in resource group where id is 1
    // recurring: { repeat: 'weekly', weekDays: 'MO,TU,WE,TH,FR' },
    title: 'Holiday',
    cssClass: 'holiday',
  },
];

export const RECURRENCE_TYPES = [
  { id: 1, name: 'On-off', color: 'info' },
  { id: 2, name: 'Weekly', color: 'warning' },
  { id: 3, name: 'Monthly', color: 'secondary' },
];

export const getCategory = (id: number) => {
  // const event = eventTypes.find((event) => event.id === id);
  switch (id) {
    case 0:
      return {
        name: 'Unassigned',
        color: '#bdc0c4',
      };
    case 1:
      return {
        name: 'Cancelled by client',
        color: '#ee964b',
      };
    case 2:
      return {
        name: 'On the way',
        color: '#afdfe8',
      };
    case 3:
      return {
        name: 'In progress',
        color: '#bde1ac',
      };
    case 4:
      return {
        name: 'Upcoming',
        color: '#f6cacb',
      };
    case 5:
      return {
        name: 'Finished',
        color: '#4c9060',
      };
    default:
      return {
        name: 'No category',
        color: '#5ac8fa',
      };
  }
};

export function convertTo24HourFormat(time12h: string | undefined): string {
  if (!time12h) {
    console.error('Time is not defined - convertTo24HourFormat()');
    return 'error';
  }
  // Extract hours, minutes, and the AM/PM part from the input string
  const [time, modifier] = time12h.split(' ');
  let [hours, minutes] = time.split(':');

  // Convert hours to an integer to perform calculations
  let hoursInt = parseInt(hours, 10);

  // Adjust hours if necessary
  if (modifier === 'PM' && hoursInt < 12) {
    hoursInt += 12;
  } else if (modifier === 'AM' && hoursInt === 12) {
    hoursInt = 0;
  }

  // Convert hours back to string and keep the leading zero if needed
  hours = hoursInt.toString().padStart(2, '0');

  // Return the 24-hour format time
  return `${hours}:${minutes}`;
}

export function calculateHoursBetween(
  start: string | undefined,
  end: string | undefined
): string {
  if (!start || !end) {
    console.error('Start or end time is not defined - calculateHoursBetween()');
    return 'error';
  }
  start = convertTo24HourFormat(start);
  end = convertTo24HourFormat(end);
  // Parse the start and end times into hours and minutes
  const [startHours, startMinutes] = start.split(':').map(Number);
  const [endHours, endMinutes] = end.split(':').map(Number);
  const totalHours = endHours - startHours;

  const totalMinutes = endMinutes - startMinutes;
  // console.log('totalMinutes', totalMinutes);
  if (totalMinutes > 0) {
    return `${totalHours}.5`;
  }
  return String(totalHours);
}

export const generateTodayAt6am = () => {
  const today = new Date();
  today.setHours(6, 0, 0, 0);
  return today;
};
