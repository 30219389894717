export const JOB_DETAILS_DEFAULT_VALUES = {
  mbscDatePicker: '',
  mbscTimePicker: '',
  // TODO: fix status
  status_id: '',
  booking_cleaners: '',
  admin_notes: '',
};

export type RHFDefaultValues = {
  mbscDatePicker: string | Date;
  mbscTimePicker: string | Date;
  status_id: number | string;
  booking_cleaners: number | string;
  admin_notes: string;
};
