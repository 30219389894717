import {
  Stack,
  InputAdornment,
  TextField,
  MenuItem,
  TextFieldProps,
} from '@mui/material';
import {
  KeyboardDoubleArrowUp,
  KeyboardArrowDown,
  KeyboardDoubleArrowDown,
  KeyboardArrowUp,
  DragHandle,
} from '@mui/icons-material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
// components
import { Iconify } from '@/components';
import { TableToolbarProps } from '../types';

// ----------------------------------------------------------------------

const INPUT_WIDTH = 160;

export default function TableToolbar({
  optionsType,
  optionsStatus,
  optionsPriority,
  optionsAssigned,
  optionsLinked,
  filterType,
  filterStatus,
  filterPriority,
  filterAssigned,
  filterLinked,
  filterStartDate,
  filterEndDate,
  filterName,
  onFilterType,
  onFilterStatus,
  onFilterPriority,
  onFilterAssigned,
  onFilterLinked,
  onFilterStartDate,
  onFilterEndDate,
  onFilterName,
}: TableToolbarProps) {
  return (
    <Stack
      spacing={2}
      direction={{ xs: 'column', md: 'row' }}
      sx={{ py: 2.5, px: 3 }}
    >
      <TextField
        fullWidth
        select
        label="Type"
        value={filterType}
        onChange={onFilterType}
        SelectProps={{
          MenuProps: {
            sx: { '& .MuiPaper-root': { maxHeight: 260 } },
          },
        }}
        sx={{
          maxWidth: { md: INPUT_WIDTH },
          textTransform: 'capitalize',
        }}
      >
        {optionsType.map((option: any) => (
          <MenuItem
            key={option}
            value={option}
            sx={{
              mx: 1,
              my: 0.5,
              borderRadius: 0.75,
              typography: 'body2',
              textTransform: 'capitalize',
            }}
          >
            {option}
          </MenuItem>
        ))}
      </TextField>
      <TextField
        fullWidth
        select
        label="Status"
        value={filterStatus}
        onChange={onFilterStatus}
        SelectProps={{
          MenuProps: {
            sx: { '& .MuiPaper-root': { maxHeight: 260 } },
          },
        }}
        sx={{
          maxWidth: { md: INPUT_WIDTH },
          textTransform: 'capitalize',
        }}
      >
        {optionsStatus.map((option: any) => (
          <MenuItem
            key={option}
            value={option}
            sx={{
              mx: 1,
              my: 0.5,
              borderRadius: 0.75,
              typography: 'body2',
              textTransform: 'capitalize',
            }}
          >
            {option}
          </MenuItem>
        ))}
      </TextField>
      <TextField
        fullWidth
        select
        label="Priority"
        value={filterPriority}
        onChange={onFilterPriority}
        SelectProps={{
          MenuProps: {
            sx: { '& .MuiPaper-root': { maxHeight: 260 } },
          },
        }}
        sx={{
          maxWidth: { md: INPUT_WIDTH },
          textTransform: 'capitalize',
        }}
      >
        {optionsPriority.map((option: any) => (
          <MenuItem
            key={option}
            value={option}
            sx={{
              mx: 1,
              my: 0.5,
              borderRadius: 0.75,
              typography: 'body2',
              textTransform: 'capitalize',
            }}
          >
            {option === 'Highest' ? (
              <>
                <KeyboardDoubleArrowUp
                  color="error"
                  fontSize="medium"
                  sx={{ verticalAlign: 'top' }}
                />{' '}
                {option}
              </>
            ) : option === 'High' ? (
              <>
                <KeyboardArrowUp
                  color="error"
                  fontSize="medium"
                  sx={{ verticalAlign: 'top' }}
                />{' '}
                {option}
              </>
            ) : option === 'Medium' ? (
              <>
                <DragHandle
                  color="warning"
                  fontSize="medium"
                  sx={{ verticalAlign: 'top' }}
                />{' '}
                {option}
              </>
            ) : option === 'Low' ? (
              <>
                <KeyboardArrowDown
                  color="secondary"
                  fontSize="medium"
                  sx={{ verticalAlign: 'top' }}
                />{' '}
                {option}
              </>
            ) : option === 'Lowest' ? (
              <>
                {' '}
                <KeyboardDoubleArrowDown
                  color="secondary"
                  fontSize="medium"
                  sx={{ verticalAlign: 'top' }}
                />{' '}
                {option}
              </>
            ) : (
              'all'
            )}
          </MenuItem>
        ))}
      </TextField>
      <TextField
        fullWidth
        select
        label="Assigned To"
        value={filterAssigned}
        onChange={onFilterAssigned}
        SelectProps={{
          MenuProps: {
            sx: { '& .MuiPaper-root': { maxHeight: 260 } },
          },
        }}
        sx={{
          maxWidth: { md: INPUT_WIDTH },
          textTransform: 'capitalize',
        }}
      >
        {optionsAssigned.map((option: any) => (
          <MenuItem
            key={option}
            value={option}
            sx={{
              mx: 1,
              my: 0.5,
              borderRadius: 0.75,
              typography: 'body2',
              textTransform: 'capitalize',
            }}
          >
            {option}
          </MenuItem>
        ))}
      </TextField>
      <TextField
        fullWidth
        select
        label="Linked to"
        value={filterLinked}
        onChange={onFilterLinked}
        SelectProps={{
          MenuProps: {
            sx: { '& .MuiPaper-root': { maxHeight: 260 } },
          },
        }}
        sx={{
          maxWidth: { md: INPUT_WIDTH },
          textTransform: 'capitalize',
        }}
      >
        {optionsLinked.map((option: any, index: number) => (
          <MenuItem
            key={index}
            value={option}
            sx={{
              mx: 1,
              my: 0.5,
              borderRadius: 0.75,
              typography: 'body2',
              textTransform: 'capitalize',
            }}
          >
            {option}
          </MenuItem>
        ))}
      </TextField>
      <DatePicker
        label="Start date"
        value={filterStartDate}
        onChange={onFilterStartDate}
        renderInput={(params: TextFieldProps) => (
          <TextField
            {...params}
            fullWidth
            sx={{
              maxWidth: { md: INPUT_WIDTH },
            }}
          />
        )}
      />

      <DatePicker
        label="End date"
        value={filterEndDate}
        onChange={onFilterEndDate}
        renderInput={(params: TextFieldProps) => (
          <TextField
            {...params}
            fullWidth
            sx={{
              maxWidth: { md: INPUT_WIDTH },
            }}
          />
        )}
      />

      <TextField
        fullWidth
        value={filterName}
        onChange={(event) => onFilterName(event.target.value)}
        placeholder="Search..."
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Iconify
                icon={'eva:search-fill'}
                sx={{ color: 'text.disabled', width: 20, height: 20 }}
              />
            </InputAdornment>
          ),
        }}
      />
    </Stack>
  );
}
