import Avatar, { Props as AvatarProps } from './Avatar';
// hooks
import useAuth from '@/hooks/useAuth';
// utils
import createAvatar from '@/utils/createAvatar';

// ----------------------------------------------------------------------

export default function MyAvatar({ ...other }: AvatarProps) {
  const { user } = useAuth();

  return (
    <Avatar
      src={user?.profile_image}
      alt={'' + user?.first_name + ' ' + user?.last_name}
      color={
        user?.profile_image ? 'default' : createAvatar(user?.first_name).color
      }
      {...other}
    >
      {createAvatar(user?.first_name).name}
    </Avatar>
  );
}
