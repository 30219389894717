import { ChangeEvent, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
//
import { Button, Stack, TextField } from '@mui/material';
import { Iconify } from '@/components';
import {
  VBMCardTitle,
  VBMCardWrapper,
  VBMConfirmCleanerDialog,
  VBMEditButton,
} from '..';
// hooks + utils
import useResponsive from '@/hooks/useResponsive';
import useToggle from '@/hooks/useToggle';
// types
type VBMDetailCardProps = {
  title: string; // Hours | Bedrooms | Bathrooms
  icon: string;
  hours: number;
  iconSize?: number;
  editable?: boolean;
};

export default function VBMDetailCard({
  title,
  icon,
  hours,
  iconSize = 30,
  editable = false,
}: VBMDetailCardProps) {
  const [isEdit, setIsEdit] = useState(false);
  const [inputValue, setInputValue] = useState<string | number>(hours);
  const [searchParams] = useSearchParams();
  const sessionID = searchParams.get('sessionID');
  const downMd = useResponsive('down', 'md');
  const betweenMdLg = useResponsive('between', undefined, 'md', 'lg');

  const {
    toggle: isConfirmCleanerDialogOpen,
    onOpen: openConfirmCleanerDialog,
    onClose: closeConfirmCleanerDialog,
  } = useToggle();

  const handleInputChange = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { value } = e.target;
    setInputValue(Number(value));
  };

  const handleToggleEdit = (value: boolean) => setIsEdit(value);

  const onSuccessCallbackFn = () => {
    handleToggleEdit(false);
    closeConfirmCleanerDialog();
  };

  const cardTitle = `${hours} ${hours !== 1 ? title : title.slice(0, -1)}`;
  return (
    <>
      <VBMCardWrapper>
        {!isEdit && editable && (
          <VBMEditButton onClickButton={() => setIsEdit(true)} />
        )}

      <Stack
        alignItems="center"
        justifyContent="center"
        direction="row"
        sx={{ width: '100%', pt: 0.5 }}
        spacing={downMd ? 1 : 2}
      >
        {!betweenMdLg && <Iconify icon={icon} width={downMd ? 24 : iconSize} />}

          {isEdit ? (
            <Stack spacing={1}>
              <Stack direction="row" spacing={1} alignItems="center">
                <TextField
                  name={title.toLowerCase()}
                  value={inputValue}
                  onChange={handleInputChange}
                  size="small"
                  type="number"
                  inputProps={{ step: 0.5 }}
                  sx={{
                    background: (theme) => theme.palette.background.paper,
                    width: 75,
                  }}
                />
                <VBMCardTitle title={title} sx={{ textAlign: 'center' }} />
              </Stack>

              <Stack
                direction="row"
                justifyContent="flex-end"
                spacing={1}
                sx={{ mt: 2 }}
              >
                <Button
                  variant="outlined"
                  size="small"
                  color="inherit"
                  onClick={() => handleToggleEdit(false)}
                >
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  size="small"
                  onClick={openConfirmCleanerDialog}
                >
                  {downMd ? 'Save' : 'Save changes'}
                </Button>
              </Stack>
            </Stack>
          ) : (
            <VBMCardTitle title={cardTitle} sx={{ textAlign: 'center' }} />
          )}
        </Stack>
      </VBMCardWrapper>
      <VBMConfirmCleanerDialog
        isDialogOpen={isConfirmCleanerDialogOpen}
        onCloseDialog={closeConfirmCleanerDialog}
        bookingSessionId={Number(sessionID)}
        onSuccessCallbackFn={onSuccessCallbackFn}
        onSuccessText={`Number of ${title.toLowerCase()} updated`}
        parentBookingSessionDto={{ [title.toLowerCase()]: inputValue }}
      />
    </>
  );
}
