import { Box } from '@mui/material';
import DialogSimple from '@/components/dialog/DialogSimple';
import { AddNewAddressForm, EditAddressForm } from '..';
import { UserAddress } from '@/features/users/shared/types';

type DialogProps = {
  isDialogOpen: boolean;
  closeDialog: VoidFunction;
  mode: 'add' | 'edit';
  currentAddress?: UserAddress | null;
  queryKey: string[];
};

export default function ClientAddressDialog({
  isDialogOpen,
  closeDialog,
  mode,
  currentAddress,
  queryKey,
}: DialogProps) {
  const dialogTitle = mode === 'add' ? 'Add new address' : 'Edit address';
  return (
    <DialogSimple
      dialogTitle={dialogTitle}
      open={isDialogOpen}
      onClickButtonClose={closeDialog}
      loadingButton={false}
      disagreeButton={false}
    >
      <Box sx={{ px: 3, pb: 2 }}>
        {mode === 'add' ? (
          <AddNewAddressForm onClose={closeDialog} queryKey={queryKey} />
        ) : (
          <EditAddressForm
            onClose={closeDialog}
            currentAddress={currentAddress}
            queryKey={queryKey}
          />
        )}
      </Box>
    </DialogSimple>
  );
}
