// API
import { getSchedulerBookingSessions } from '@/features/bookings-management/api';
// types
import { BookingSession } from '@/features/bookings-management/types';
import { TransformedBooking } from '../types';

/**
 * Transforms an array of booking data into a specific format suitable for further processing or display.
 * Each booking is mapped to a new structure that includes parsed and formatted date and time,
 * title, resource, property details, address, recurrence information, and event type.
 *
 * @param {Booking[]} bookingData - An array of booking objects to be transformed.
 * @returns {Object[]} An array of transformed booking objects. Each object includes fields such as id,
 *                     start, end, title, resource, property, address, recurring, and eventType.
 *                     The `start` and `end` fields are generated based on booking time and duration.
 *                     The `title` defaults to the booking id if not present.
 *                     The `property` and `address` fields are constructed from the booking details.
 *                     Recurrence information is also included if available.
 *
 * @example
 * // Example of a booking object that might be transformed
 * const bookings = [{
 *   id: 1,
 *   time: '10:00',
 *   hours: 2,
 *   address: { line_1: '123 Main St', line_2: 'Apt 4', town_or_city: 'Anytown', postcode: '12345' },
 *   booking_recurrence: { from_date: '2024-01-01', repeat_string: 'Weekly', week_days: 'Mon,Wed,Fri' }
 * }];
 *
 * const transformedBookings = parseBookingData(bookings);
 * console.log(transformedBookings);
 */

/**
 * Parses and transforms an array of booking data into a structured format.
 * Each booking is formatted with detailed attributes including dates, address, recurrence, and more.
 *
 * @param {Booking[]} bookingData - An array of booking objects to be transformed.
 * @returns {TransformedBooking[]} An array of transformed booking objects.
 */

// export function parseBookingData(bookingData: Booking[]) {
//   // const filteredArray = bookingData.filter((booking) =>
//   //   booking.date.includes('2024-02-07')
//   // );
//   // return filteredArray.map((booking) => transformBooking(booking));
//   return bookingData.map((booking) => transformBooking(booking));
// }
export function parseBookingSessionData(
  bookingSessions: BookingSession[]
): TransformedBooking[] {
  const transformedSessions = bookingSessions.map((filteredSessions) =>
    transformBookingSession(filteredSessions)
  );

  return transformedSessions;
}

function addHoursToDate(date: Date, hours: number) {
  date.setHours(date.getHours() + hours);
  return date;
}

function createDateFromDateTime(dateString: string, timeString: string) {
  // Combine date and time strings
  const dateTimeString = `${dateString}T${timeString}`;

  return dateTimeString;
}

export function formatDateToISOString(dateString: Date, dateOnly?: boolean) {
  // Parse the original date string to create a Date object
  const date = new Date(dateString);

  // Format the date to ISO string in UTC ('YYYY-MM-DDTHH:mm:ss' format)
  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const day = date.getDate().toString().padStart(2, '0');
  const hours = date.getHours().toString().padStart(2, '0');
  const minutes = date.getMinutes().toString().padStart(2, '0');
  const seconds = date.getSeconds().toString().padStart(2, '0');

  // Combine the components to match the desired format
  const formattedDate = `${year}-${month}-${day}`;
  const formattedDateAndTime =
    formattedDate + `T${hours}:${minutes}:${seconds}`;

  return dateOnly ? formattedDate : formattedDateAndTime;
}

export const assignPropertyTypeIcon = (propertyType: string) => {
  switch (propertyType) {
    case 'house':
      return 'ph:house-fill';
    case 'flat':
      return 'vaadin:office';
    case 'guest_house':
      return 'ion:bed';
    case 'hotel':
      return 'fa6-solid:hotel';
    default:
      return 'eva:question-mark-outline';
  }
};

// TODO: have Chris+Elena confirm this
const assignServiceLabel = (serviceVariantId: number) => {
  // personal on-off, peronal weekly, personal fortnightly, holiday rental one-off, office
  switch (serviceVariantId) {
    case 1:
      return 'PO';
    case 2:
      return 'PW';
    case 3:
      return 'PF';
    case 4:
      return 'HO';
    case 5:
      return 'OF';
    default:
      return '??';
  }
};

/**
 * Transforms a single booking object into a structured format.
 *
 * @param {Booking} booking - A booking object to be transformed.
 * @returns {TransformedBooking} A transformed booking object.
 */

export function transformBookingSession(
  session: BookingSession
): TransformedBooking {
  const transformedBookingSession = {
    id: session.id,
    start: createDateFromDateTime(session.date, session.time),
    end: formatDateToISOString(
      addHoursToDate(
        new Date(createDateFromDateTime(session.date, session.time)),
        // TODO: check this when API is ready with new response
        session.total_hours ?? 0
      )
    ),
    title: `#${session.id}` || 'No title',
    resource: session.booking_session_cleaners[0]?.id || 0,
    property: {
      type: session.property_type,
      icon: assignPropertyTypeIcon(session.property_type),
    },
    address: {
      line1: session.address?.line_1 || '',
      line2: session.address?.line_2 || '',
      city: session.address?.town_or_city || '',
      postcode: session.address?.postcode || '',
    },
    serviceLabel: assignServiceLabel(session.service_variant?.id),
    cssClass: session.booking_session_status?.style_class,
    serviceTypeId: session.service_variant.service.id,
    editable: true,
  };

  // if (
  //   session.booking_recurrence &&
  //   session.booking_recurrence.interval_number > 0
  // ) {
  //   Object.assign(transformedBookingSession, {
  //     recurring: {
  //       repeat: session.booking_recurrence.repeat_string || '',
  //       weekDays: session.booking_recurrence.week_days
  //         ? session.booking_recurrence.week_days.replaceAll('|', ',')
  //         : '',
  //       interval: session.booking_recurrence.interval_number || 0,
  //       from: session.booking_recurrence.from_date || '',
  //       until: session.booking_recurrence.until || '',
  //     },
  //   });
  // }
  // debugger;
  return transformedBookingSession;
}

/**
 * Asynchronously fetches and transforms booking data. If a start date is provided,
 * it fetches bookings filtered from that date. Otherwise, it fetches all bookings.
 *
 * @param {string} [startDate] - An optional start date in 'YYYY-MM-DD' format to filter bookings.
 *                               If not provided, all bookings are fetched.
 * @returns {Promise<BookingTransformed[]>} A promise that resolves to an array of transformed booking data.
 *
 * @example
 * // Fetch all bookings
 * fetchAndTransformBookings().then(data => console.log(data));
 *
 * @example
 * // Fetch bookings from a specific start date
 * fetchAndTransformBookings('2024-01-01').then(data => console.log(data));
 */
// export const fetchAndTransformBookings = async (
//   startDate?: string
// ): Promise<TransformedBooking[]> => {
//   let bookings: Booking[] = [];

//   if (!startDate) {
//     bookings = await getBookings();
//   } else {
//     bookings = await getFilteredBookings('2024-01-01');
//   }
//   return parseBookingData(bookings);
// };
export const fetchAndTransformBookingSessions = async (
  params?: string
): Promise<TransformedBooking[]> => {
  let bookingSessions: BookingSession[] = [];
  bookingSessions = await getSchedulerBookingSessions(params);

  return parseBookingSessionData(bookingSessions);
};
