import { useState } from 'react';
import { useSearchParams } from 'react-router-dom';
//
import { Skeleton, Stack, TextField, Typography } from '@mui/material';
import {
  VBMActionButtons,
  VBMCardTitle,
  VBMCardWrapper,
  VBMEditButton,
} from '..';
import { TooltipIcon } from '@/components/Iconify';
// hooks + utils
import usePostBookingSessionUpdate from '@/features/bookings-management/hooks/usePostBookingSessionUpdate';
import { getNamedInputValueFromForm } from '@/features/bookings-management/utils';
//
type VBMNoteProps = {
  title: string;
  keyValue: string;
  noteText: string;
  toolTipInfoText?: string;
  editable?: boolean;
  pinkBg?: boolean;
};

export default function VBMNote({
  title,
  keyValue,
  noteText,
  toolTipInfoText,
  editable = false,
  pinkBg = false,
}: VBMNoteProps) {
  const [isEdit, setIsEdit] = useState(false);
  const [searchParams] = useSearchParams();
  const sessionID = searchParams.get('sessionID');

  const { sessionUpdateMutation } = usePostBookingSessionUpdate({
    onSuccessFn: () => setIsEdit(false),
    successText: `${title} updated`,
  });

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const inputValue = getNamedInputValueFromForm(event, keyValue);

    sessionUpdateMutation.mutate({
      bookingSessionId: Number(sessionID),
      bookingSessionDto: { [keyValue]: inputValue },
    });
  };

  return (
    <VBMCardWrapper pinkBg={pinkBg}>
      {!isEdit && editable && (
        <VBMEditButton onClickButton={() => setIsEdit(true)} />
      )}

      <Stack direction="row" spacing={1} alignItems="center">
        <VBMCardTitle title={title} sx={{ mb: 1 }} />
        {toolTipInfoText && (
          <TooltipIcon icon="eva:info-outline" title={toolTipInfoText} />
        )}
      </Stack>

      {isEdit ? (
        <form onSubmit={handleSubmit}>
          <Stack spacing={2}>
            <TextField
              defaultValue={noteText}
              name={keyValue}
              fullWidth
              multiline
              size="small"
              sx={{ background: (theme) => theme.palette.background.paper }}
            />
            <VBMActionButtons
              onClickCancel={() => setIsEdit(false)}
              isSubmitting={sessionUpdateMutation.isLoading}
            />
          </Stack>
        </form>
      ) : (
        <>
          {sessionUpdateMutation.isLoading ? (
            <Skeleton height={50} />
          ) : (
            <Typography variant="body2">{noteText}</Typography>
          )}
        </>
      )}
    </VBMCardWrapper>
  );
}
