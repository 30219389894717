import { useFormContext, useController } from 'react-hook-form';
import { Switch, Typography, FormControlLabel, FormControlLabelProps } from '@mui/material';
import { Variant } from '@mui/material/styles/createTypography';

type OmitLabel = Omit<FormControlLabelProps, 'control' | 'label' | 'onChange'>;

interface Props extends OmitLabel {
  name: string;
  activeLabel?: string;
  inactiveLabel?: string;
  label?: string | JSX.Element;
  color?: string;
  fontWeight?: string | number;
  variant?: Variant;
  render?: (value: boolean) => JSX.Element | null;
  callback?: (value: boolean) => void;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void;
}

export default function RHFSwitchWithText({
  name,
  activeLabel = 'Yes',
  inactiveLabel = 'No',
  label,
  color = 'black',
  fontWeight = 'normal',
  variant,
  callback,
  render = () => null,
  onChange: parentOnChange,
  ...other
}: Props) {
  const { control } = useFormContext();
  const {
    field: { value, onChange: fieldOnChange },
  } = useController({
    name,
    control,
  });

  // Determine the label to be used
  const determinedLabel = label || (
    <Typography sx={{ color: color, fontWeight: fontWeight, variant: variant }}>
      {value ? activeLabel : inactiveLabel}
    </Typography>
  );

  return (
    <>
      <FormControlLabel
        control={
          <Switch
            checked={value}
            onChange={(e, checked) => {
              parentOnChange?.(e, checked);
              fieldOnChange(e);
              callback && callback(checked);
            }}
          />
        }
        label={determinedLabel}
        {...other}
      />
      {render(value)}
    </>
  );
}
