import { SyntheticEvent, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router';
//
import { Box, Card, Tab, Tabs } from '@mui/material';
import { VBMCleaningSessions, VBMPauseCancel } from '.';
// hooks + utils
import useTabs from '@/hooks/useTabs';
import useSettings from '@/hooks/useSettings';
import { useGetAccess } from '@/hooks/useAccess';
import { bookingsManagementPath } from '@/features/bookings-management';

export default function ViewBookingMain() {
  const { themeStretch } = useSettings();
  const { canUpdate } = useGetAccess('bookings');
  const { bookingId, bookingTabSid } = useParams();
  const { currentTab, onChangeTab, setCurrentTab } =
    useTabs('booking-sessions');
  const navigate = useNavigate();

  useEffect(() => {
    bookingTabSid && setCurrentTab(bookingTabSid);
  }, [bookingTabSid, setCurrentTab]);

  const handleTabChange = (
    event: SyntheticEvent<Element, Event>,
    newValue: string
  ) => {
    onChangeTab(event, newValue);
    const pathFunctionParams = {
      bookingId: String(bookingId),
      bookingTabSid: newValue,
    };
    if (newValue === 'booking-sessions') {
      Object.assign(pathFunctionParams, { sessionFilter: 'today' });
    }
    navigate(bookingsManagementPath('view', pathFunctionParams));
  };

  const VIEW_BOOKING_TABS = [
    {
      value: 'booking-sessions',
      label: 'Booking Sessions',
      component: <VBMCleaningSessions />,
      isShown: true,
    },
    {
      value: 'pause-cancel',
      label: 'Pause / Cancel Booking',
      component: <VBMPauseCancel />,
      isShown: canUpdate,
    },
  ];

  return (
    <Card sx={{ px: themeStretch ? 5 : 3, pt: 1.5, pb: 3 }}>
      <Tabs
        allowScrollButtonsMobile
        variant="scrollable"
        scrollButtons="auto"
        value={currentTab}
        onChange={handleTabChange}
        aria-label="booking details tabs"
      >
        {VIEW_BOOKING_TABS.map(
          (tab) =>
            tab.isShown && (
              <Tab
                key={tab.value}
                value={tab.value}
                label={tab.label}
                disableRipple
                sx={{ fontSize: themeStretch ? 15 : 14 }}
              />
            )
        )}
      </Tabs>

      <Box sx={{ mt: 3 }}>
        {VIEW_BOOKING_TABS.map(
          (tab) =>
            tab.value === currentTab && (
              <Box key={tab.value}>{tab.component}</Box>
            )
        )}
      </Box>
    </Card>
  );
}
