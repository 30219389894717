import { useState } from 'react';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box, MenuItem, TableCell, TableRow, Typography } from '@mui/material';
//
import { Iconify, Label } from '@/components';
import { TableMoreMenu, TableRowLink } from '@/components/table';
import DialogSimple from '@/components/dialog/DialogSimple';
// hooks + utils
import useToggle from '@/hooks/useToggle';
import { rolesPath } from '../routes/paths';
// types
import { Role } from '../types/roles';

type TableRowProps = {
  row: Role;
  onEditRow: VoidFunction;
  onDeleteRow: VoidFunction;
  canDelete: boolean;
  canUpdate: boolean;
};

export default function RolesTableRow({
  row,
  onEditRow,
  onDeleteRow,
  canDelete,
  canUpdate,
}: TableRowProps) {
  const theme = useTheme();
  const { id, name, description, active } = row;

  // ===================
  //      3DOT MENU
  // ===================

  const [openMenu, setOpenMenuActions] = useState<HTMLElement | null>(null);

  const handleOpenMenu = (event: React.MouseEvent<HTMLElement>) => {
    setOpenMenuActions(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setOpenMenuActions(null);
  };

  // =================
  //    EDIT/DELETE
  // =================
  const {
    toggle: isDeleteDialogOpen,
    onOpen: openDeleteDialog,
    onClose: closeDeleteDialog,
  } = useToggle();

  const handleDelete = () => {
    onDeleteRow();
    closeDeleteDialog();
  };

  return (
    <>
      <DialogSimple
        dialogTitle={`Delete role: ${name}`}
        open={isDeleteDialogOpen}
        onClickButtonClose={closeDeleteDialog}
        onClickButtonLoading={handleDelete}
        loadingButton={true}
        disagreeButton={true}
        loadingButtonText="Delete"
        loadingButtonColor="error"
        disagreeButtonText="Cancel"
        disagreeButtonColor="inherit"
      >
        <Box sx={{ px: 3 }}>
          <Typography variant="body2" sx={{ mb: 2 }}>
            Are you sure you want to delete this role:{' '}
            <span style={{ fontWeight: 600 }}>{name}</span>?
          </Typography>
        </Box>
      </DialogSimple>

      <TableRow hover>
        <TableCell width={10} />

        <TableCell>
          <TableRowLink
            linkTo={rolesPath('edit', { roleId: String(id) })}
            linkText={name}
          />
        </TableCell>

        <TableCell align="left">
          <Typography>{description}</Typography>
        </TableCell>

        <TableCell align="left">
          <Label
            variant={theme.palette.mode === 'light' ? 'ghost' : 'filled'}
            color={(active === false && 'error') || 'success'}
            sx={{ textTransform: 'capitalize' }}
          >
            {active ? 'Active' : 'Disabled'}
          </Label>
        </TableCell>

        <TableCell align="right">
          {(canUpdate || canDelete) && (
            <TableMoreMenu
              open={openMenu}
              onOpen={handleOpenMenu}
              onClose={handleCloseMenu}
              actions={
                <>
                  {canUpdate && (
                    <MenuItem
                      onClick={() => {
                        onEditRow();
                        handleCloseMenu();
                      }}
                    >
                      <Iconify icon={'eva:edit-fill'} />
                      Edit
                    </MenuItem>
                  )}
                  {canDelete && (
                    <MenuItem
                      onClick={() => {
                        openDeleteDialog();
                        handleCloseMenu();
                      }}
                      sx={{ color: 'error.main' }}
                    >
                      <Iconify icon={'eva:trash-2-outline'} />
                      Delete
                    </MenuItem>
                  )}
                </>
              }
            />
          )}
        </TableCell>
      </TableRow>
    </>
  );
}
