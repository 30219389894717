import { useState, useEffect, useMemo } from 'react';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';
import { isEmpty, unionBy } from 'lodash';
// api
import {
  getActivityType,
  getDiaryData,
  getSettings,
  getUsersData,
  postDiaryData,
  updateDiaryData,
} from '../api';

// form
import { object, string, array } from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import {
  KeyboardDoubleArrowUp,
  KeyboardArrowDown,
  KeyboardDoubleArrowDown,
  KeyboardArrowUp,
  DragHandle,
} from '@mui/icons-material';
import { timelineItemClasses } from '@mui/lab/TimelineItem';
import {
  Stack,
  Grid,
  Box,
  Card,
  ToggleButtonGroup,
  ToggleButton,
  Divider,
  Button,
  Typography,
  MenuItem,
  Chip,
  Avatar,
  Select,
  InputLabel,
  FormControl,
  Container,
  Tooltip,
  styled,
  Skeleton,
  AutocompleteRenderGetTagProps,
} from '@mui/material';
import {
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  Timeline,
  TimelineSeparator,
} from '@mui/lab';
import { priorityIcons } from '@/config';
import LoadingButton from '@mui/lab/LoadingButton';
// hooks
import useSettings from '@/hooks/useSettings';
// components
import {
  FormProvider,
  RHFTextField,
  RHFTimePicker,
  RHFAutocomplete,
  RHFDatepicker,
} from '@/components/hook-form';
import { Iconify } from '@/components';
// types
import type { Activity, Settings, UserManager } from '../types';
import { ActivityType } from '@/features/settings/types';
// roots
import { PATH_ACTIVITIES } from '../routes/paths';
import { format } from 'date-fns';

// ----------------------------------------------------------------------

// PROP TYPES
interface FormValuesProps {
  type_id?: number;
  reason_id?: { id: number } | null;
  owner_id?: { id: number } | null;
  subject: string;
  description: string;
  private_note: string;
  start_date: string;
  start_time: string;
  end_date: string;
  end_time: string;
  link_record_type?: { id: number } | null;
  linked_entity_id?: number;
  priority_id?: number;
  activity_to_users: {
    id?: number;
    first_name?: string;
    last_name?: string;
    profile_image?: string;
  }[];
  completed: string;
}

const SectionLabel = styled(Typography)(() => ({
  color: 'gray',
  fontWeight: 900,
  marginBottom: '1rem',
}));

type Props = {
  currentData?: Activity;
  ID?: number;
};
//-------------------------------------------------------
export default function ActivitiesCreateEdit({ currentData }: Props) {
  const [tableData, setTableData] = useState<Activity[]>([]);
  const [userData, setUsersData] = useState<UserManager[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [settingsData, setSettingsData] = useState<Settings[]>([]);
  const [iconData, setIcon] = useState<number>(1);
  const [priorityData, setPriorityData] = useState<string>('');
  const [activityType, setActivityType] = useState<ActivityType[]>([]);
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    getUsersData().then((res) => {
      setUsersData(res);
    });
    getDiaryData().then((res) => {
      setTableData(res);
    });
    getActivityType().then((response) => setActivityType(response));
    getSettings().then((res) => {
      setSettingsData(res);
    });
    setIsLoading(false);
  }, []);
  //-------------------------------------------------------

  const linkedFromData = tableData.map(
    ({ linked_entity_type }) => linked_entity_type
  );
  const uniqLinked = unionBy(linkedFromData, 'id').sort();

  const activityTypeFromData = activityType
    .map(({ icon, id, name, active }) => ({
      icon,
      id,
      name,
      active,
    }))
    .filter((item) => item.active === true);

  // ----------------------------------------------------------------------
  // REACT HOOK FORM

  const Schema = object().shape({
    type_id: string().required('Icon is required'),
    reason_id: object().required('Reason is required').nullable(),
    owner_id: object().required('Owner is required').nullable(),
    subject: string().required('Subject is required'),
    description: string().required('Description is required'),
    private_note: string().required('Note is required'),
    start_time: string().required('Start time is required'),
    end_time: string().required('End time is required'),
    start_date: string().required('Start date is required'),
    end_date: string().required('End date is required'),
    link_record_type: object().required('Assign is required').nullable(),
    // Waiting for API
    //linked_entity_id: number().required('Entity is required'),
    priority_id: string().required('Priority is required'),
    completed: string().required('Status is required').nullable(),
    activity_to_users: array().min(1, 'User(s) required'),
  });

  const defaultValues = useMemo(
    () => ({
      type_id: currentData?.activity_type?.id,
      reason_id: currentData?.activity_reason || null,
      subject: currentData?.subject || '',
      description: currentData?.description || '',
      private_note: currentData?.private_note || '',
      start_date: currentData?.start_date || '',
      start_time: currentData?.start_time || '',
      end_date: currentData?.end_date || '',
      end_time: currentData?.end_time || '',
      link_record_type: currentData?.linked_entity_type || null,
      // waiting for API
      linked_entity_id: 1,
      // linked_entity_id: currentData?.linked_entity_type?.id,
      priority_id: currentData?.priority?.id,
      completed: currentData?.completed ? 'Done' : 'To Do',
      owner_id: currentData?.owner || null,
      activity_to_users: currentData?.activity_to_users,
    }),

    [currentData]
  );

  const methods = useForm<FormValuesProps>({
    resolver: yupResolver(Schema),
    defaultValues,
  });

  const {
    reset,
    handleSubmit,
    register,
    setValue,
    clearErrors,
    watch,
    formState: { isSubmitting, errors },
  } = methods;
  // ----------------------------------------------------------------------
  useEffect(() => {
    if (!isEmpty(currentData)) {
      setValue('type_id', currentData?.activity_type?.id);
      setPriorityData(String(currentData.priority.id));
      setIcon(currentData.activity_type.id);
      reset(defaultValues);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentData, reset, defaultValues]);

  // ----------------------------------------------------------------------
  const onSubmit = async (data: FormValuesProps) => {
    try {
      const onSubmitData = {
        type_id: Number(data.type_id),
        reason_id: data.reason_id?.id as number,
        owner_id: data.owner_id?.id as number,
        subject: data.subject,
        description: data.description,
        private_note: data.private_note,
        start_date: format(new Date(data.start_date), 'yyyy-MM-dd'),
        start_time: data.start_time,
        end_date: format(new Date(data.end_date), 'yyyy-MM-dd'),
        end_time: data.end_time,
        link_record_type: Number(data.link_record_type?.id),
        // default value waiting for API
        linked_entity_id: 1,
        priority_id: Number(data.priority_id),
        activity_to_users: data.activity_to_users.map(
          (item) => item.id
        ) as number[],
        completed: data.completed === 'Done' ? true : false,
      };

      !isEmpty(currentData)
        ? await updateDiaryData(onSubmitData, currentData?.id)
        : await postDiaryData(onSubmitData);
      enqueueSnackbar(
        !isEmpty(currentData)
          ? 'Activity successfully updated!'
          : 'Activity successfully created!'
      );
      navigate(PATH_ACTIVITIES.root);
    } catch (error) {
      console.error(error);
      enqueueSnackbar('Something went wrong', { variant: 'error' });
    }
  };
  // ----------------------------------------------------------------------
  const { themeStretch } = useSettings();

  // ----------------------------------------------------------------------
  //
  function handleChange(
    event: React.MouseEvent<HTMLElement>,
    iconType: number
  ) {
    const iconTypeString = iconType;
    setIcon(iconTypeString);
    clearErrors('type_id');
    setValue('type_id', iconTypeString);
  }
  // Display options in Autocomplete
  const labelHander = (
    props: any,
    { id, first_name, last_name, profile_image }: UserManager
  ) => (
    <li {...props} key={id} id={id}>
      <Avatar src={profile_image} /> {first_name} {last_name}
    </li>
  );
  // Display chips in Tag users
  const renderTagsHandler = (
    tagValue: UserManager[],
    getTagProps: AutocompleteRenderGetTagProps
  ) =>
    tagValue!.map(
      ({ id, first_name, profile_image }: UserManager, index: number) => (
        <Chip
          {...getTagProps({ index })}
          key={id}
          label={first_name}
          size="small"
          color="primary"
          avatar={<Avatar alt="photo" src={profile_image} />}
        />
      )
    );

  const renderOptionLabel = ({ first_name, last_name }: UserManager) => {
    const fullName = `${first_name} ${last_name}`;
    return fullName;
  };

  // ----------------------------------------------------------------------
  // Tooltip for ToggleButton

  type PropsToggle = {
    children: React.ReactNode;
    title: string;
    value: string | number;
    key: number;
  };

  const TooltipToggleButton = ({
    children,
    title,
    value,
    key,
    ...props
  }: PropsToggle) => (
    <Tooltip title={title}>
      <ToggleButton key={key} value={value} {...props}>
        {children}
      </ToggleButton>
    </Tooltip>
  );
  return (
    <>
      <Container
        maxWidth={themeStretch ? false : 'lg'}
        style={{ position: 'relative' }}
      >
        {isLoading ? (
          <>
            <Box pl={2} pb={2}>
              <Skeleton sx={{ width: 0.98 }} height={100} />
            </Box>
            <Divider />
            <Box pl={2} pb={2}>
              <Skeleton sx={{ width: 0.98 }} height={100} />
            </Box>
            <Box pl={2} pb={2}>
              <Skeleton sx={{ width: 0.98 }} height={60} />
              <Skeleton sx={{ width: 0.98 }} height={60} />
              <Skeleton sx={{ width: 0.98 }} height={60} />
              <Skeleton sx={{ width: 0.98 }} height={60} />
              <Skeleton sx={{ width: 0.98 }} height={60} />
            </Box>
          </>
        ) : (
          <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
            <Grid container spacing={3}>
              {/* LEFT CARD */}
              <Grid item xs={12} md={8}>
                <Card sx={{ p: 3 }}>
                  <Stack spacing={2} direction="row">
                    <Stack spacing={2}>
                      <RHFTextField name="subject" label="Subject" fullWidth />
                      <ToggleButtonGroup
                        {...register('type_id')}
                        color="primary"
                        size="small"
                        value={iconData}
                        exclusive
                        onChange={handleChange}
                        aria-label="Platform"
                        sx={{
                          display: 'flex',
                          flexWrap: 'wrap',
                          height: 'auto',
                          overflow: 'auto',
                          bgcolor: 'theme.grey',
                        }}
                      >
                        {activityTypeFromData.map((item, idx) => (
                          <TooltipToggleButton
                            value={item.id}
                            title={item.name}
                            key={idx}
                          >
                            <Iconify icon={item.icon} width={25} height={25} />
                          </TooltipToggleButton>
                        ))}
                      </ToggleButtonGroup>
                      {errors.type_id?.message && (
                        <Typography
                          color="error"
                          fontSize={'small'}
                          sx={{ verticalAlign: 'top', margin: '-8px 0 0 50px' }}
                        >
                          Type is required
                        </Typography>
                      )}
                      <RHFAutocomplete
                        name="reason_id"
                        noOptionsText={'Reason not found'}
                        options={settingsData}
                        multiple={false}
                        label="Reason"
                        placeholder="Reason"
                        getOptionLabel={(option: Settings) => option.name}
                        disableCloseOnSelect={false}
                      />
                      <Stack direction="row" spacing={2}>
                        <RHFDatepicker
                          label="Start Date"
                          name="start_date"
                          value={currentData?.start_date}
                          maxDate={new Date(watch('end_date'))}
                        />
                        <RHFTimePicker
                          name="start_time"
                          value={currentData?.start_time}
                          label="Start Time"
                        />
                        <RHFDatepicker
                          name="end_date"
                          label="End Date"
                          value={currentData?.end_date}
                          minDate={new Date(watch('start_date'))}
                        />
                        <RHFTimePicker
                          name="end_time"
                          label="End Time"
                          value={currentData?.end_time}
                        />
                      </Stack>
                      <RHFTextField
                        name="description"
                        label="Description"
                        multiline
                        rows={6}
                        sx={{ mt: 2 }}
                      />

                      <RHFAutocomplete
                        name="activity_to_users"
                        options={userData}
                        noOptionsText={'User not found'}
                        label="Tag Users"
                        placeholder="Tag Users"
                        limitTags={6}
                        renderTags={renderTagsHandler}
                        renderOption={labelHander}
                        getOptionLabel={renderOptionLabel}
                        sx={{
                          marginBottom: '-8px',
                        }}
                      />
                    </Stack>
                  </Stack>
                </Card>
              </Grid>
              {/* RIGHT CARD */}
              <Grid item xs={12} md={4}>
                <Card sx={{ p: 3 }}>
                  <Stack spacing={2}>
                    <Stack spacing={2}>
                      <RHFAutocomplete
                        name="completed"
                        noOptionsText={'Status not found'}
                        options={['To Do', 'Done']}
                        multiple={false}
                        label="Status"
                        placeholder="Status"
                        getOptionLabel={(option: Settings) => option}
                        disableCloseOnSelect={false}
                        filterSelectedOptions={false}
                      />
                      <FormControl>
                        <InputLabel id="priority">Priority</InputLabel>

                        <Select
                          sx={{
                            borderColor: 'red',
                          }}
                          label="Priority"
                          {...register('priority_id', {
                            required: 'Priority is required',
                          })}
                          value={priorityData}
                          onChange={(e) => {
                            setPriorityData(e.target.value);
                            clearErrors('priority_id');
                          }}
                        >
                          {priorityIcons.map((option: any) => (
                            <MenuItem key={option.id} value={Number(option.id)}>
                              {option.name === 'Highest' ? (
                                <>
                                  <KeyboardDoubleArrowUp
                                    color="error"
                                    fontSize="medium"
                                    sx={{ verticalAlign: 'top' }}
                                  />{' '}
                                  {option.name}
                                </>
                              ) : option.name === 'High' ? (
                                <>
                                  <KeyboardArrowUp
                                    color="error"
                                    fontSize="medium"
                                    sx={{ verticalAlign: 'top' }}
                                  />{' '}
                                  {option.name}
                                </>
                              ) : option.name === 'Medium' ? (
                                <>
                                  <DragHandle
                                    color="warning"
                                    fontSize="medium"
                                    sx={{ verticalAlign: 'top' }}
                                  />{' '}
                                  {option.name}
                                </>
                              ) : option.name === 'Low' ? (
                                <>
                                  <KeyboardArrowDown
                                    color="secondary"
                                    fontSize="medium"
                                    sx={{ verticalAlign: 'top' }}
                                  />{' '}
                                  {option.name}
                                </>
                              ) : option.name === 'Lowest' ? (
                                <>
                                  {' '}
                                  <KeyboardDoubleArrowDown
                                    color="secondary"
                                    fontSize="medium"
                                    sx={{ verticalAlign: 'top' }}
                                  />{' '}
                                  {option.name}
                                </>
                              ) : (
                                ''
                              )}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                      {!isEmpty(errors?.priority_id?.message) && (
                        <Typography
                          display="block"
                          color="error"
                          fontSize={'small'}
                          sx={{
                            margin: '8px 14px 0',
                            fontSize: '13px',
                          }}
                        >
                          Priority is required
                        </Typography>
                      )}
                      <RHFAutocomplete
                        name="owner_id"
                        noOptionsText={'User not found'}
                        options={userData}
                        multiple={false}
                        label="Owner"
                        placeholder="Owner"
                        renderOption={labelHander}
                        getOptionLabel={renderOptionLabel}
                        disableCloseOnSelect={false}
                        avatarIcon={'profile_image'}
                      />
                      <RHFAutocomplete
                        name="link_record_type"
                        noOptionsText={'Type not found'}
                        options={uniqLinked}
                        multiple={false}
                        label="Assign Lead | Opportiunity | Sale"
                        getOptionLabel={(option: Activity) =>
                          option.description
                        }
                        disableCloseOnSelect={false}
                      />

                      <RHFTextField
                        name="private_note"
                        label="Private Notes"
                        multiline
                        rows={4}
                        sx={{ mt: 2 }}
                      />
                      {/* Waiting for API  for now hard coded */}
                      <Box>
                        <SectionLabel>Activity</SectionLabel>
                        <Card sx={{ backgroundColor: '#ebebeb', py: 1, mb: 2 }}>
                          <Timeline
                            sx={{
                              [`& .${timelineItemClasses.root}:before`]: {
                                flex: 0,
                                padding: 0,
                                paddingLeft: '1rem',
                              },
                            }}
                          >
                            <TimelineItem>
                              <TimelineSeparator>
                                <TimelineDot color="success" />
                                <TimelineConnector />
                              </TimelineSeparator>
                              <TimelineContent>
                                <Typography fontSize={15} fontWeight={700}>
                                  Modified 11/16/2022 11:38 AM
                                </Typography>
                                <Typography fontSize={15} fontWeight={700}>
                                  Adrian Tamas (you)
                                </Typography>
                                <Typography
                                  fontSize={11}
                                  fontWeight={500}
                                  sx={{ color: 'gray' }}
                                >
                                  2 hours ago
                                </Typography>
                              </TimelineContent>
                            </TimelineItem>
                            <TimelineItem>
                              <TimelineSeparator>
                                <TimelineDot color="success" />
                                <TimelineConnector />
                              </TimelineSeparator>
                              <TimelineContent>
                                <Typography fontSize={15} fontWeight={700}>
                                  Created: 11/16/2022 11:38 AM
                                </Typography>
                                <Typography fontSize={15} fontWeight={700}>
                                  Adrian Tamas (you)
                                </Typography>
                                <Typography
                                  fontSize={11}
                                  fontWeight={500}
                                  sx={{ color: 'gray' }}
                                >
                                  24 Sep 2020
                                </Typography>
                              </TimelineContent>
                            </TimelineItem>
                          </Timeline>
                        </Card>
                      </Box>
                    </Stack>
                    <Stack direction="row" spacing={2}>
                      <Button
                        fullWidth
                        variant="outlined"
                        size="medium"
                        color="inherit"
                        href={PATH_ACTIVITIES.root}
                      >
                        Cancel
                      </Button>
                      <LoadingButton
                        fullWidth
                        type="submit"
                        variant="contained"
                        size="medium"
                        loading={isSubmitting}
                      >
                        {!isEmpty('hello')
                          ? 'Save Activity'
                          : 'Create Activity'}
                      </LoadingButton>
                    </Stack>
                  </Stack>
                </Card>
              </Grid>
            </Grid>
          </FormProvider>
        )}
      </Container>
    </>
  );
}
