import { useEffect, SyntheticEvent } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { capitalCase } from 'change-case';
//
import { Box, Button, Container, Tab, Tabs } from '@mui/material';
//
import {
  VariantCustomCityPrices,
  VariantCustomPriceDialog,
  VariantDetails,
} from '../components';
import { Page, HeaderBreadcrumbs, Iconify } from '@/components';
// hooks + utils
import useSettings from '@/hooks/useSettings';
import useToggle from '@/hooks/useToggle';
import { servicesManagementPath } from '..';
import { PATH_USER } from '@/features/app';
// types
import useTabs from '@/hooks/useTabs';

// ---------------------------------------------

export default function ServiceVariantCreateEdit() {
  const { themeStretch } = useSettings();
  const { variantId, tabSid } = useParams();
  const { currentTab, onChangeTab, setCurrentTab } = useTabs('details');
  const navigate = useNavigate();

  useEffect(() => {
    tabSid && setCurrentTab(tabSid);
  }, [tabSid, setCurrentTab]);

  const handleTabChange = (
    event: SyntheticEvent<Element, Event>,
    newValue: string
  ) => {
    onChangeTab(event, newValue);
    navigate(
      servicesManagementPath('editVariant', {
        variantId: String(variantId),
        tabSid: newValue,
      })
    );
  };

  const VARIANT_TABS = [
    {
      value: 'details',
      component: <VariantDetails />,
      visible: true,
    },
    {
      value: 'prices',
      component: <VariantCustomCityPrices />,
      visible: !!variantId,
    },
  ];

  const {
    toggle: isCustomPriceDialogOpen,
    onOpen: openCustomPriceDialog,
    onClose: closeCustomPriceDialog,
  } = useToggle();

  return (
    <Page title="Service Management: Variants">
      <Container maxWidth={themeStretch ? false : 'lg'}>
        <HeaderBreadcrumbs
          heading={!variantId ? 'Add variant' : 'Edit variant'}
          links={[
            { name: 'Dashboard', href: PATH_USER.root },
            { name: 'Services', href: servicesManagementPath('services') },
            {
              name: 'Service Variants',
              href: servicesManagementPath('variants'),
            },
            { name: !variantId ? 'Add new' : 'Edit variant' },
          ]}
          action={
            currentTab === 'prices' && (
              <Button
                variant="contained"
                onClick={openCustomPriceDialog}
                startIcon={<Iconify icon={'eva:plus-fill'} />}
              >
                Add Custom Price
              </Button>
            )
          }
        />
        <Tabs
          allowScrollButtonsMobile
          variant="scrollable"
          scrollButtons="auto"
          value={currentTab}
          onChange={handleTabChange}
          sx={{ mb: 1 }}
          aria-label="variant tabs"
        >
          {VARIANT_TABS.map(
            (tab) =>
              tab.visible && (
                <Tab
                  disableRipple
                  key={tab.value}
                  value={tab.value}
                  label={capitalCase(tab.value)}
                />
              )
          )}
        </Tabs>

        {VARIANT_TABS.map(
          (tab) =>
            tab.value === currentTab && (
              <Box key={tab.value} sx={{ width: '100%' }}>
                {tab.component}
              </Box>
            )
        )}
        {isCustomPriceDialogOpen && (
          <VariantCustomPriceDialog
            isDialogOpen={isCustomPriceDialogOpen}
            closeDialog={closeCustomPriceDialog}
          />
        )}
      </Container>
    </Page>
  );
}
