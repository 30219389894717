import { Box, Typography } from '@mui/material';
import { SxProps, Theme } from '@mui/material/styles';

type Props = {
  title: string;
  subtitle?: string;
  sx?: SxProps<Theme>;
};
export default function CardTitle({ title, subtitle, sx }: Props) {
  return (
    <Box sx={{ ...sx }}>
      <Typography
        sx={{
          pb: 1,
          fontWeight: 700,
          fontSize: '1.2rem',
        }}
      >
        {title}
      </Typography>
      <Typography sx={{ color: 'text.secondary' }}>
        {subtitle ? subtitle : <br />}
      </Typography>
    </Box>
  );
}
