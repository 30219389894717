import { ActivityType } from '@/features/settings/types';

import axios from '@/utils/axios';
import { AxiosResponse } from 'axios';
import {
  Activity,
  ActivityObjectToPost,
  Settings,
  UserManager,
} from '../types';

// API ACTIVITY TYPES
// ----------------------------------------------------------------------

// GET
export async function getDiaryData(): Promise<Activity[]> {
  const {
    data: { data },
  } = await axios.get('/activities');
  return data;
}

export async function getActivityType(): Promise<ActivityType[]> {
  const {
    data: { data },
  } = await axios.get('/activities/types');
  return data;
}
export async function getUsersData(): Promise<UserManager[]> {
  const {
    data: { data },
  } = await axios.get('/users');
  return data;
}

export async function getSingleDiaryData(ID: number): Promise<Activity> {
  const {
    data: { data },
  } = await axios.get(`/activities/${ID}`);
  return data;
}

// POST
export const postDiaryData = async (postData: ActivityObjectToPost) =>
  axios.post('/activities', postData);

// PUT
export const updateDiaryData = async (data: ActivityObjectToPost, ID: number) =>
  axios.put(`activities/${ID}`, data);

// DELETE
export async function deleteDiaryData(ID: number): Promise<AxiosResponse> {
  const data = await axios.delete(`/activities/${ID}`);

  return data;
}

// Get settigns
export async function getSettings(): Promise<Settings[]> {
  const {
    data: {
      data: {
        settings: { activityReasons },
      },
    },
  } = await axios.get('/settings');
  return activityReasons;
}
