import { Divider, Stack, Typography } from '@mui/material';
import { VBMCardTitle, VBMCardWrapper } from '..';
import useResponsive from '@/hooks/useResponsive';
import { Iconify } from '@/components';
//
export default function VBMPaymentDetails() {
  const transactionDetails = {
    'Transaction Date': '£tbd',
    'Payment Method': 'TBC',
    'Total Amount': '£TBC',
    Discount: '£TBC',
    'Deposit Paid': '£TBC',
    'Balance Paid': '£TBC',
    'Outstanding Balance': '£44',
  };
  const downMd = useResponsive('down', 'md');
  return (
    <VBMCardWrapper>
      <VBMCardTitle title="Payment Details" sx={{ mb: 1 }} />

      <Stack
        spacing={1}
        divider={<Divider orientation="horizontal" flexItem />}
      >
        {Object.entries(transactionDetails).map(([key, value]) => (
          <Stack key={key} direction="row" justifyContent="space-between">
            <Stack direction="row" spacing={1}>
              <Typography variant="body2" fontSize={downMd ? 12 : 14}>
                {key}
              </Typography>
              <Stack direction="row" spacing={1}>
                {key.toLowerCase() === 'discount' && (
                  <>
                    <Iconify icon="eva:plus-circle-outline" />{' '}
                    <Iconify icon="eva:minus-circle-outline" />
                  </>
                )}
              </Stack>
            </Stack>
            <Typography variant="body2" fontSize={downMd ? 12 : 14}>
              {value}
            </Typography>
          </Stack>
        ))}
      </Stack>
    </VBMCardWrapper>
  );
}
