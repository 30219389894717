import { DayOfWeek } from '@/types';
import { keyframes } from '@mui/material';
import { boolean, object, string } from 'yup';

// write day off schema and default values based on DayOffDto interface
export const NEW_DAYOFF_SCHEMA = object().shape({
  type_id: string().required('Type is required'),
  start_date: string().required('Start date is required'),
  end_date: string().required('End date is required'),
  status: string().required('Status is required'),
  notes: string().required('Notes is required'),
  offday_recurrence_enabled: boolean(),
  offday_recurrence: object().shape({
    repeat_string: string().when('offday_recurrence_enabled', {
      is: true,
      then: string().required('Repeat string is required'),
    }),
    // day: string().required('Day is required'),
    // month: string().required('Month is required'),
    week_days: string().when('offday_recurrence_enabled', {
      is: true,
      then: string().required('Week days is required'),
    }),
    // week_start: string().required('Week start is required'),
    // pos_number: string().required('Pos number is required'),
    // count: string().required('Count is required'),
    from_date: string().when('offday_recurrence_enabled', {
      is: true,
      then: string().required('From date is required'),
    }),
    until: string().when('offday_recurrence_enabled', {
      is: true,
      then: string().required('From date is required'),
    }),
    interval_number: string().when('offday_recurrence_enabled', {
      is: true,
      then: string().required('Interval number is required'),
    }),
  }),
});

export const TIME_OFF_DEFAULT_VALUES = {
  request_type_id: '',
  start_date: '',
  end_date: '',
  status: '',
  timeoff_request_notes: '',
  recurrence_type: '',
  offday_recurrence_enabled: false,
  recurrence_days_of_week: [] as DayOfWeek[],
  recurrence_repeat_string: '',
  recurrence_from_date: '',
  recurrence_until: '',
  recurrence_interval_number: '',
};

export const RECURRING_TYPES = [
  { id: 1, name: 'Weekly' },
  { id: 2, name: 'Fortnightly' },
  { id: 3, name: 'Monthly' },
  { id: 4, name: 'Yearly' },
];

export const localZoominEffext = keyframes`
0% { transform: scale(1)}
50%{ transform: scale(1.1)}
100%{ transform: scale(1)}
`;
