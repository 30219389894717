import { MbscResource } from '@mobiscroll/react';
import { Stack, Typography } from '@mui/material';
//
type MbscCleanerCellProps = {
  cleanerResource: MbscResource;
};

export default function MbscCleanerCell({
  cleanerResource,
}: MbscCleanerCellProps) {
  return (
    <Stack spacing={1}>
      <Typography
        fontSize={14}
        fontWeight={600}
      >{`${cleanerResource.first_name} ${cleanerResource.last_name}`}</Typography>
      <Typography fontSize={12}>{`N/A%`}</Typography>
    </Stack>
  );
}
