// @mui
import { IconButton, PopoverOrigin } from '@mui/material';
//
import Iconify from '../Iconify';
import MenuPopover, { Arrow } from '@/components/MenuPopover';
// types

type Props = {
  actions: React.ReactNode;
  open?: HTMLElement | null;
  onClose?: (event: React.MouseEvent<HTMLElement>) => void;
  onOpen?: (event: React.MouseEvent<HTMLElement>) => void;
  iconColor?: 'success' | 'error';
  iconWidth?: number;
  iconHeight?: number;
  turnOffPointerEvents?: boolean;
  visibilityHidden?: boolean;
  popOverWidth?: number;
  arrow?: Arrow;
  anchorOrigin?: {
    vertical: PopoverOrigin['vertical'];
    horizontal: PopoverOrigin['horizontal'];
  };
  transformOrigin?: {
    vertical: PopoverOrigin['vertical'];
    horizontal: PopoverOrigin['horizontal'];
  };
  icon?: string;
};

export default function TableMoreMenu({
  actions,
  open,
  onClose,
  onOpen,
  iconWidth = 20,
  iconHeight = 20,
  iconColor = 'error',
  turnOffPointerEvents = false,
  visibilityHidden = false,
  popOverWidth = 160,
  arrow = 'top-right',
  anchorOrigin,
  transformOrigin,
  icon = 'eva:more-vertical-fill',
}: Props) {
  return (
    <>
      <IconButton
        onClick={onOpen}
        sx={{
          pointerEvents: turnOffPointerEvents ? 'none' : 'auto',
          visibility: visibilityHidden ? 'hidden' : 'visible',
        }}
      >
        <Iconify
          icon={icon}
          width={iconWidth}
          height={iconHeight}
          color={iconColor}
        />
      </IconButton>

      <MenuPopover
        open={Boolean(open)}
        anchorEl={open}
        onClose={onClose}
        anchorOrigin={anchorOrigin}
        transformOrigin={transformOrigin}
        arrow={arrow}
        sx={{
          mt: -1,
          width: popOverWidth,
          '& .MuiMenuItem-root': {
            px: 1,
            typography: 'body2',
            borderRadius: 0.75,
            '& svg': { mr: 2, width: 20, height: 20 },
          },
        }}
      >
        {actions}
      </MenuPopover>
    </>
  );
}
