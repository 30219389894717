// form
import { useFormContext, Controller } from 'react-hook-form';
// @mui
import {
  Box,
  CircularProgress,
  FormHelperText,
  IconButton,
  Typography,
  alpha,
  keyframes,
  useTheme,
} from '@mui/material';
// type
import {
  UploadAvatar,
  UploadMultiFile,
  UploadSingleFile,
  UploadProps,
  UploadMultiFileProps,
} from '../upload';
import Iconify from '../Iconify';

interface Props extends Omit<UploadProps, 'file'> {
  name: string;
  isAvatarUploading?: boolean;
  showRemoveButton?: boolean;
  removeAvatar?: () => void;
}

const accept = { 'image/*': ['.jpeg', '.png'] };

export function RHFUploadAvatar({
  name,
  isAvatarUploading = false,
  showRemoveButton = true,
  removeAvatar,
  ...other
}: Props) {
  const { control } = useFormContext();
  const theme = useTheme();

  const pulseEffect = keyframes`
  0%, 100% {
    background-color: ${alpha(theme.palette.primary.main, 0.2)};
  }
  50% {
    background-color: ${alpha(theme.palette.primary.main, 0.4)};
  }
  `;

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => {
        const checkError = !!error && !field.value;

        return (
          <Box sx={{ position: 'relative', width: '100%', height: '100%' }}>
            <UploadAvatar error={checkError} {...other} file={field.value} />
            {checkError && (
              <FormHelperText error sx={{ px: 2, textAlign: 'center' }}>
                {error.message}
              </FormHelperText>
            )}

            {isAvatarUploading && (
              <Box
                sx={{
                  position: 'absolute',
                  top: 0,
                  background: (theme) => alpha(theme.palette.primary.main, 0.6),
                  animation: `${pulseEffect} 2s infinite`,
                  animationDirection: 'alternate',
                  borderRadius: 2,
                  width: '100%',
                  height: '100%',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  flexDirection: 'column',
                  gap: 1,
                  color: 'background.paper',
                }}
              >
                <Typography fontWeight={500} color="background.paper">
                  Uploading...
                </Typography>
                <CircularProgress size={35} color="inherit" />
              </Box>
            )}

            {showRemoveButton && field.value && !isAvatarUploading && (
              <IconButton
                onClick={() => {
                  field.onChange(null);
                  removeAvatar && removeAvatar();
                }}
                title="Remove avatar"
                sx={{ position: 'absolute', bottom: 0, right: 0 }}
              >
                <Iconify icon="bi:trash" color="error.main" />
              </IconButton>
            )}
          </Box>
        );
      }}
    />
  );
}

export function RHFUploadSingleFile({ name, ...other }: Props) {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => {
        const checkError = !!error && !field.value;

        return (
          <UploadSingleFile
            accept={accept}
            file={field.value}
            error={checkError}
            helperText={
              checkError && (
                <FormHelperText error sx={{ px: 2 }}>
                  {error.message}
                </FormHelperText>
              )
            }
            {...other}
          />
        );
      }}
    />
  );
}

interface RHFUploadMultiFileProps extends Omit<UploadMultiFileProps, 'files'> {
  name: string;
  loadingButton?: boolean;
}

export function RHFUploadMultiFile({
  name,
  loadingButton = true,
  ...other
}: RHFUploadMultiFileProps) {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => {
        const checkError = !!error && field.value?.length === 0;

        return (
          <UploadMultiFile
            accept={accept}
            files={field.value}
            loadingButton={loadingButton}
            error={checkError}
            helperText={
              checkError && (
                <FormHelperText error sx={{ px: 2 }}>
                  {error?.message}
                </FormHelperText>
              )
            }
            {...other}
          />
        );
      }}
    />
  );
}
