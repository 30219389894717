import { Fragment } from 'react';
// mui
import { useTheme } from '@mui/material/styles';
import {
  Chip,
  MenuItem,
  TableRow,
  TableCell,
  Typography,
  Box,
} from '@mui/material';
//
import { Label, Iconify } from '@/components';
import { TableMoreMenu, TableRowLink } from '@/components/table';
import DialogSimple from '@/components/dialog/DialogSimple';
// hooks + utils
import useToggle from '@/hooks/useToggle';
import { userPath } from '../routes/paths';
import { use3DotMenu } from '@/hooks/use3DotMenu';
// types
import { LabelColors } from '@/types';
import { User } from '../../shared/types';

type RowProps = {
  row: User;
  onEditRow: VoidFunction;
  onDeleteRow: VoidFunction;
  //
  canDelete: boolean;
  canUpdate: boolean;
};

export default function UserTableRow({
  row,
  onEditRow,
  onDeleteRow,
  canDelete,
  canUpdate,
}: RowProps) {
  const theme = useTheme();

  const {
    id,
    first_name,
    last_name,
    email,
    user_roles,
    created_at,
    user_statuses,
    is_chat_user,
  } = row;

  const createdAtFormatted = new Date(created_at).toLocaleDateString('en-gb', {
    weekday: 'long',
    year: 'numeric',
    month: 'short',
    day: 'numeric',
  });

  user_roles.sort((a, b) => (a.name > b.name ? 1 : a.name < b.name ? -1 : 0));

  // ===================

  const { openMenu, handleCloseMenu, handleOpenMenu } = use3DotMenu();

  // =====================
  //      DELETE USER
  // =====================
  const {
    toggle: isDeleteDialogOpen,
    onOpen: openDeleteDialog,
    onClose: closeDeleteDialog,
  } = useToggle();

  const handleDelete = () => {
    onDeleteRow();
    closeDeleteDialog();
  };
  return (
    <>
      <DialogSimple
        dialogTitle={`Delete provider: ${first_name} ${last_name}`}
        open={isDeleteDialogOpen}
        onClickButtonClose={closeDeleteDialog}
        onClickButtonLoading={handleDelete}
        loadingButton={true}
        disagreeButton={true}
        loadingButtonText="Delete"
        loadingButtonColor="error"
        disagreeButtonText="Cancel"
        disagreeButtonColor="inherit"
      >
        <Box sx={{ px: 3 }}>
          <Typography variant="body2" sx={{ mb: 2 }}>
            Are you sure you want to delete this user:{' '}
            <span style={{ fontWeight: 600 }}>
              {first_name} {last_name}
            </span>
            ?
          </Typography>
        </Box>
      </DialogSimple>

      <TableRow hover sx={{ verticalAlign: 'center' }}>
        <TableCell width={5} />

        <TableCell>
          <TableRowLink
            linkText={`${first_name} ${last_name}`}
            linkTo={userPath('edit', {
              userId: String(id),
              tabSid: 'details',
            })}
            noWrap
          />
        </TableCell>

        <TableCell align="left">
          <TableRowLink linkText={email} linkTo={`mailto:${email}`} />
        </TableCell>

        <TableCell align="left" sx={{ textTransform: 'capitalize' }}>
          {user_roles.map((role, index) => (
            <Fragment key={index}>
              <Chip label={role.name} sx={{ mx: 0.2, my: 0.4 }} />
            </Fragment>
          ))}
        </TableCell>

        <TableCell align="left">
          <Iconify
            icon={
              is_chat_user
                ? 'eva:checkmark-circle-2-fill'
                : 'eva:close-circle-outline'
            }
            color={is_chat_user ? 'green' : 'gray'}
          />
        </TableCell>

        <TableCell align="left">{createdAtFormatted}</TableCell>

        <TableCell align="left">
          <Label
            variant={theme.palette.mode === 'light' ? 'ghost' : 'filled'}
            color={user_statuses.style_class as LabelColors}
            sx={{ textTransform: 'capitalize' }}
          >
            {user_statuses.value}
          </Label>
        </TableCell>

        <TableCell align="right">
          {(canDelete || canUpdate) && (
            <TableMoreMenu
              open={openMenu}
              onOpen={handleOpenMenu}
              onClose={handleCloseMenu}
              actions={
                <>
                  {canUpdate && (
                    <MenuItem
                      onClick={() => {
                        onEditRow();
                        handleCloseMenu();
                      }}
                    >
                      <Iconify icon={'eva:edit-fill'} />
                      Edit
                    </MenuItem>
                  )}
                  {canDelete && (
                    <MenuItem
                      onClick={() => {
                        openDeleteDialog();
                        handleCloseMenu();
                      }}
                      sx={{ color: 'error.main' }}
                    >
                      <Iconify icon={'eva:trash-2-outline'} />
                      Delete
                    </MenuItem>
                  )}
                </>
              }
            />
          )}
        </TableCell>
      </TableRow>
    </>
  );
}
