export const useHasPermission = (
  permissionNames: string[],
  availablePermissions: string[],
  isSuperAdmin?: boolean
): { isAllowed: boolean } => {
  if (isSuperAdmin === true) {
    return { isAllowed: true };
  }

  // Allow access if no permission names are provided
  if (permissionNames.length === 0) {
    return { isAllowed: true };
  }

  return {
    isAllowed: availablePermissions.some((permissionName) =>
      permissionNames.includes(permissionName)
    ),
  };
};
