import { tableSortFilter } from '@/utils/arrayProcessing';
import { Wallet } from '@/features/users/shared/types';

export const CLIENT_WALLET_TABLE_HEAD = [
  { id: 'emptyCell' },
  { id: 'name', label: 'Name', align: 'left' },
  { id: 'brand', label: 'Brand', align: 'left' },
  { id: 'exp_year', label: 'Expiry date', align: 'left' },
  { id: 'last_used', label: 'Last used', align: 'left' },
];

export function applySortFilterClientCards({
  tableData,
  comparator,
  searchFieldValue,
}: {
  tableData: Wallet[];
  comparator: (a: any, b: any) => number;
  searchFieldValue: string;
}) {
  tableData = tableSortFilter(tableData, comparator);
  const searchFieldLowerCase = searchFieldValue.toLowerCase();
  if (searchFieldValue) {
    tableData = tableData.filter(
      (item) =>
        item.brand.toString().indexOf(searchFieldLowerCase) !== -1 ||
        item.last4.toString().indexOf(searchFieldLowerCase) !== -1 ||
        item.exp_year.toString().indexOf(searchFieldLowerCase) !== -1
    );
  }

  return tableData;
}
