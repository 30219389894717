import { Stack, Typography, IconButton } from '@mui/material';
//
import Iconify from './Iconify';
import { formatAddress } from '@/utils/formatAddress';
//
type AddressProps = {
  formatted_address: string;
  postcode?: string;
  hideGoogleMapsButton?: boolean;
};

export default function AddressWithGoogleMapsIconButton({
  formatted_address,
  postcode,
  hideGoogleMapsButton = false,
}: AddressProps) {
  const { formattedAddress, formattedAddressWithPostcode, googleMapsAddress } =
    formatAddress(formatted_address, postcode);
  return (
    <Stack direction="row">
      <Typography
        variant="body2"
        sx={{ mt: 1, mb: 0.5, whiteSpace: 'pre-line' }}
        dangerouslySetInnerHTML={{
          __html: postcode ? formattedAddressWithPostcode : formattedAddress,
        }}
      />
      {!hideGoogleMapsButton && (
        <IconButton
          size="small"
          href={`https://www.google.com/maps/search/${googleMapsAddress}`}
          target="_blank"
          rel="noopener noreferrer"
          color="info"
          sx={{ alignSelf: 'start', mt: 0.5 }}
        >
          <Iconify icon="eva:pin-outline" />
        </IconButton>
      )}
    </Stack>
  );
}
