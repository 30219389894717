import { useState } from 'react';
//
import { Stack } from '@mui/material';
import {
  VBMCardTitle,
  VBMCleaningExtrasEdit,
  VBMCleaningExtrasView,
  VBMEditButton,
} from '..';
//
import { BookingSession } from '@/features/bookings-management/types';

type VBMCleaningExtrasProps = {
  editable?: boolean;
  bookingSession: BookingSession | undefined;
  sessionView?: boolean;
};

export default function VBMCleaningExtras({
  editable = false,
  bookingSession,
  sessionView = false,
}: VBMCleaningExtrasProps) {
  const [isEdit, setIsEdit] = useState(false);
  const handleSetIsEdit = (value: boolean) => setIsEdit(value);
  return (
    <Stack
      direction="row"
      alignItems="center"
      sx={{ position: 'relative', py: 0.5 }}
    >
      {!isEdit && editable && (
        <VBMEditButton onClickButton={() => setIsEdit(true)} />
      )}
      <Stack spacing={1}>
        <VBMCardTitle title="Cleaning Extras" />
        {!isEdit ? (
          <VBMCleaningExtrasView
            bookingSession={bookingSession}
            sessionView={sessionView}
          />
        ) : (
          <VBMCleaningExtrasEdit
            handleSetIsEdit={handleSetIsEdit}
            bookingSession={bookingSession}
          />
        )}
      </Stack>
    </Stack>
  );
}
