// mui
import { styled } from '@mui/system';
// types
type InputProps = {
  selected?: boolean;
  numberOfHours?: string;
};

interface Props {
  index?: number;
  label: string | number;
  selected: boolean;
  onClick?: React.MouseEventHandler<HTMLElement>;
}
// ------------------------------
//        CUSTOM COMPONENT
// ------------------------------
const options = {
  shouldForwardProp: (prop: string) => prop !== 'selected',
};

const CustomPickerComponent = styled(
  'div',
  options
)<InputProps>(({ selected = false, theme }) => ({
  width: '40px',
  height: '40px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  cursor: 'pointer',
  border: selected
    ? `1px solid ${theme.palette.primary.light}`
    : `1px solid ${theme.palette.grey[400]}`,
  backgroundColor: selected ? theme.palette.primary.main : 'transparent',
  color: selected ? theme.palette.background.paper : 'text.primary',
  fontSize: '0.9rem',
}));

// ------------------------------

export default function CustomPicker({
  index,
  label,
  selected,
  onClick,
}: Props) {
  return (
    <CustomPickerComponent selected={selected} onClick={onClick} key={index}>
      {label}
    </CustomPickerComponent>
  );
}
