import { useQuery } from '@tanstack/react-query';
import {
  CalendarNav,
  CalendarPrev,
  CalendarNext,
  CalendarToday,
} from '@mobiscroll/react';
//
import { MenuItem, Skeleton, Stack, TextField } from '@mui/material';
//
import { useSnackbarMsg } from '@/hooks/useSnackbarMsg';
import { getProviders } from '@/features/users/shared/api';
//
type MbscCalendarHeaderProps = {
  handleCleanerFilter?: (cleanerId: number) => void;
};

export default function MbscCalendarHeader({
  handleCleanerFilter = () => {},
}: MbscCalendarHeaderProps) {
  const { errorMsg } = useSnackbarMsg();

  const cleanersQuery = useQuery({
    queryKey: ['providers'],
    queryFn: getProviders,
    onError: (error) => errorMsg(error, 'Error fetching cleaners'),
  });

  const cleaners = cleanersQuery.data;
  return (
    <>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        sx={{ width: '100%', pb: 1 }}
      >
        <CalendarNav className="md-header-filter-nav" />
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          sx={{ width: '100%', pl: 2 }}
        >
          {cleaners ? (
            <TextField
              select
              sx={{ width: 170 }}
              size="small"
              label="Select cleaner"
              defaultValue=""
              onChange={(e) => handleCleanerFilter(Number(e.target.value))}
            >
              <MenuItem key={0} value={0}>
                All Cleaner
              </MenuItem>
              {cleaners.map((cleaner) => (
                <MenuItem key={cleaner.id} value={cleaner.id}>
                  {`${cleaner.first_name} ${cleaner.last_name}`}
                </MenuItem>
              ))}
            </TextField>
          ) : (
            <Skeleton variant="rectangular" width={150} height={40} />
          )}
        </Stack>

        <Stack direction="row" alignItems="center">
          <CalendarPrev className="md-header-filter-prev" />
          <CalendarToday className="md-header-filter-today" />
          <CalendarNext className="md-header-filter-next" />
        </Stack>
      </Stack>
    </>
  );
}
