import { styled } from '@mui/material/styles';
import { Typography } from '@mui/material';

const SummaryFieldLabel = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.secondary,
  fontWeight: 600,
}));

const SummaryFieldText = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.disabled,
  fontWeight: 400,
  fontSize: 14,
  paddingLeft: 1,
}));
const SummaryFieldPrice = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.primary,
  fontWeight: 600,
  fontSize: 14,
  paddingLeft: 1,
}));

export { SummaryFieldLabel, SummaryFieldText, SummaryFieldPrice };
