//
import { Box, Container, Typography } from '@mui/material';
import { Page } from '@/components';
import { ContentStyle } from '../components/LoginStyledComponents';
import {
  LoginFormBackButton,
  LoginFormLogo,
  VerifyCodeForm,
} from '@/features/auth/components';
// utils
import { authPath } from '../routes/paths';
// ----------------------------------------------------------------------

export default function VerifyCode() {
  return (
    <Page title="Verify Code">
      <LoginFormLogo imageSrc="/logo/logo_full.svg" />

      <Container>
        <ContentStyle>
          <LoginFormBackButton linkTo={authPath('reset')} />

          <Typography variant="h3" paragraph>
            Request sent successfully
          </Typography>

          <Typography sx={{ color: 'text.secondary' }}>
            Enter the 6-digit security code to verify your account.
          </Typography>

          <Box sx={{ mt: 5, mb: 3 }}>
            <VerifyCodeForm />
          </Box>
        </ContentStyle>
      </Container>
    </Page>
  );
}
