import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getNotificationLimitedRequest } from '../api';
import { NotficationState } from '../types';
import { dispatch } from '@/stores';

const initialState: NotficationState = {
  isLoading: true,
  totalUnseen: 0,
  notifications: [],
};
let intervalCall: ReturnType<typeof setInterval>;

export const fetchUserById = createAsyncThunk('fetchUserById', async () => {
  const { notifications, totalUnseen } = await getNotificationLimitedRequest(5);
  return { notifications, totalUnseen };
});

const slice = createSlice({
  name: 'notification',
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
    },

    getNotificationsSuccess(state, { payload }) {
      state.notifications = payload.notifications;
      state.totalUnseen = payload.totalUnseen;
      state.isLoading = false;
    },
    hasError(state) {
      state.isLoading = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchUserById.fulfilled, (state, action) => {
      slice.caseReducers.getNotificationsSuccess(state, action);
    });
  },
});

export function initiateNotifications() {
  if (intervalCall) return;
  dispatch(fetchUserById());
  intervalCall = setInterval(function () {
    dispatch(fetchUserById());
  }, 5000000000);
}

export function destroyNotificationInterval() {
  clearInterval(intervalCall);
}

export default slice.reducer;
