import { useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import {
  Autocomplete,
  Avatar,
  Box,
  Button,
  Chip,
  Divider,
  MenuItem,
  Skeleton,
  Stack,
  TextField,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import DialogSimple from '@/components/dialog/DialogSimple';
//
import { getProviders } from '@/features/users/shared/api';
import { useSnackbarMsg } from '@/hooks/useSnackbarMsg';
import usePostBookingSessionUpdate from '@/features/bookings-management/hooks/usePostBookingSessionUpdate';
// types
import { BookingSession } from '@/features/bookings-management/types';

type ConfirmCleanerDialogProps = {
  // dialog props
  isDialogOpen: boolean;
  onCloseDialog: VoidFunction;
  // session details
  bookingSessionId: number;
  parentBookingSessionDto: Partial<BookingSession>;
  // onMutate() - onSuccess
  onSuccessCallbackFn: VoidFunction;
  onSuccessText: string;
};

export default function VBMConfirmCleanerDialog({
  isDialogOpen,
  onCloseDialog,
  bookingSessionId,
  parentBookingSessionDto,
  onSuccessCallbackFn,
  onSuccessText,
}: ConfirmCleanerDialogProps) {
  const { errorMsg } = useSnackbarMsg();
  const [selectedCleanerId, setSelectedCleanerId] = useState<number | null>(
    null
  );

  const { data: providers, isLoading: isCleanersDataLoading } = useQuery({
    queryKey: ['providers'],
    queryFn: getProviders,
    onError: (error) =>
      errorMsg(error, 'There was an error while fetching cleaners data'),
    select: (data) => [
      {
        id: 0,
        first_name: 'Unassigned',
        last_name: 'cleaner',
        profile_image: '',
      },
      ...data,
    ],
  });

  const { sessionUpdateMutation } = usePostBookingSessionUpdate({
    onSuccessFn: () => onSuccessCallbackFn(),
    successText: onSuccessText,
  });

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const bookingSessionDto: Partial<BookingSession> = {
      ...parentBookingSessionDto,
      booking_cleaners:
        Number(selectedCleanerId) === 0 || !selectedCleanerId
          ? []
          : [Number(selectedCleanerId)],
    };

    sessionUpdateMutation.mutate({
      bookingSessionId,
      bookingSessionDto,
    });
  };
  return (
    <DialogSimple
      open={isDialogOpen}
      dialogTitle="Confirm change in assigned cleaner"
      loadingButton={false}
      disagreeButton={false}
      dialogCloseOutside={onCloseDialog}
      onClickButtonClose={onCloseDialog}
    >
      <form onSubmit={handleSubmit}>
        <Stack spacing={2} sx={{ px: 3, pb: 2 }}>
          <Box>
            The changes you are about to make will unassign the selected
            cleaner. If you want to keep a cleaner assigned to the session, you
            can use the dropdown list below.
          </Box>
          <Box sx={{ width: '100%', py: 1 }}>
            {providers && !isCleanersDataLoading ? (
              <Autocomplete
                fullWidth
                defaultValue={{
                  id: 0,
                  first_name: 'Unassigned',
                  last_name: 'Cleaner',
                  profile_image: '',
                }}
                onChange={(_e, newValue) =>
                  setSelectedCleanerId(newValue?.id ?? null)
                }
                filterSelectedOptions
                options={providers ?? []}
                noOptionsText="No such cleaner"
                getOptionLabel={(option: any) =>
                  `${option.first_name ?? 'N/A'} ${option.last_name ?? 'N/A'}`
                }
                renderOption={(props, option) => {
                  if (option.id === 0) {
                    return (
                      <>
                        <MenuItem
                          {...props}
                          key={option.id}
                          value={0}
                          sx={{ fontWeight: 500, pt: 1 }}
                        >
                          Unassigned cleaner
                        </MenuItem>
                        <Divider />
                      </>
                    );
                  } else {
                    return (
                      <MenuItem {...props} key={option.id} value={option.id}>
                        <Chip
                          avatar={
                            <Avatar
                              alt={`${option.first_name ?? 'N/A'} ${
                                option.last_name ?? 'N/A'
                              }`}
                              src={option.profile_image}
                            />
                          }
                          label={`${option.first_name ?? 'N/A'} ${
                            option.last_name ?? 'N/A'
                          }`}
                        />
                      </MenuItem>
                    );
                  }
                }}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Search cleaner"
                    placeholder=""
                    fullWidth
                    autoFocus
                    size="small"
                  />
                )}
              />
            ) : (
              <Skeleton variant="rectangular" width={150} height={40} />
            )}
          </Box>

          {/* ACTION BUTTONS */}
          <Stack
            direction="row"
            justifyContent="flex-end"
            spacing={1}
            sx={{ mt: 2 }}
          >
            <Button
              variant="outlined"
              size="small"
              color="inherit"
              onClick={onCloseDialog}
            >
              Cancel
            </Button>
            <LoadingButton
              variant="contained"
              size="small"
              type="submit"
              loading={sessionUpdateMutation.isLoading}
              disabled={isCleanersDataLoading}
            >
              Save changes
            </LoadingButton>
          </Stack>
        </Stack>
      </form>
    </DialogSimple>
  );
}
