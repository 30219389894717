import { useSelector } from '@/stores';
//
import { Stack } from '@mui/material';
import { SummaryFieldLabel, SummaryFieldPrice } from '.';
//
import { fCurrency } from '@/utils/formatNumber';
//
type SummaryAmountProps = {
  sumType: 'sub' | 'total';
};

export default function SummaryAmount({ sumType }: SummaryAmountProps) {
  const booking = useSelector((state) => state.booking);
  return (
    <Stack flexDirection="row" justifyContent="space-between">
      <SummaryFieldLabel sx={{ color: 'text.primary' }}>
        {sumType === 'sub' ? `Sub total` : 'Total amount'}
      </SummaryFieldLabel>
      <SummaryFieldPrice sx={{ fontSize: 16, alignSelf: 'end' }}>
        {sumType === 'sub'
          ? fCurrency(booking.subTotal)
          : fCurrency(booking.totalAmount)}
      </SummaryFieldPrice>
    </Stack>
  );
}
