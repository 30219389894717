import { Skeleton, Stack } from '@mui/material';

export default function ProviderEditTimeOffSkeleton() {
  return (
    <Stack direction="column" spacing={2} sx={{ pb: 1 }}>
      {[...new Array(7)].map((_, i) => (
        <Skeleton key={i} variant="rounded" height={45} />
      ))}
    </Stack>
  );
}
