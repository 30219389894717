import { combineReducers } from 'redux';
import createWebStorage from 'redux-persist/lib/storage/createWebStorage';
// slices
import notficationReducer from '@/features/notification/slices';
import bookingReducer from '@/features/bookings-management/slices';
import chatStreamReducer from '@/features/chat-stream/slices';
import rightSidebarReducer from '@/features/app/slices';

const createNoopStorage = () => ({
  getItem(_key: string) {
    return Promise.resolve(null);
  },
  setItem(_key: string, value: any) {
    return Promise.resolve(value);
  },
  removeItem(_key: string) {
    return Promise.resolve();
  },
});

const storage =
  typeof window !== 'undefined'
    ? createWebStorage('local')
    : createNoopStorage();

const rootPersistConfig = {
  key: 'root',
  storage,
  keyPrefix: 'hc-',
  whitelist: [],
};

const rootReducer = combineReducers({
  notfication: notficationReducer,
  booking: bookingReducer,
  chatStream: chatStreamReducer,
  rightSideBar: rightSidebarReducer,
});

export { rootPersistConfig, rootReducer };
