import { useState } from 'react';
import { useParams } from 'react-router';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// mui
import {
  Button,
  InputAdornment,
  MenuItem,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
//
import { Iconify } from '@/components';
// API
import { findAddressPostcodeLookup } from '@/features/app/api';
import { createAddressForUser } from '@/features/users/shared/api';
// hooks + utils
import {
  FormProvider,
  RHFCheckbox,
  RHFTextField,
} from '@/components/hook-form';
import { useSnackbarMsg } from '@/hooks/useSnackbarMsg';
//
import {
  NEW_ADDRESS_FROM_DEFAULT_VALUES,
  NewAddressFormSchema,
  POSTCODE_LOOKUP_DEFAULT_VALUES,
  PostcodeLookupSchema,
} from '@/features/users/shared/utils';
// type
import {
  NewAddressFormValues,
  PostcodeLookupFormValues,
  UserAddress,
} from '@/features/users/shared/types';

interface NewAddressProps {
  onClose: VoidFunction;
  queryKey: string[];
}

// --------------------------------------------------

export default function AddNewAddressForm({
  onClose,
  queryKey,
}: NewAddressProps) {
  const { errorMsg, successMsg } = useSnackbarMsg();
  const { userId } = useParams();
  const queryClient = useQueryClient();

  // LOOKED UP ADDRESSES
  // this one is managed by useState, the oother inputs are managed by react-hook-form
  const [selectedAddress, setSelectedAddress] = useState<string | null>(null);

  // ==============================
  //      POSTCODE LOOKUP FORM
  // ==============================

  // USEFORM
  const postcodeLookupMethods = useForm<PostcodeLookupFormValues>({
    resolver: yupResolver(PostcodeLookupSchema),
    defaultValues: POSTCODE_LOOKUP_DEFAULT_VALUES,
  });

  const { handleSubmit: handleSubmitPostcodeLookup } = postcodeLookupMethods;

  // QUERY
  const postcodeLookupMutation = useMutation({
    mutationFn: (postcode: string) => findAddressPostcodeLookup(postcode),
    onError: (error) => {
      errorMsg(error, `There was an error while gettind addresses`);
    },
  });

  //  SUBMIT
  const submitPostcodeLookup = async (data: PostcodeLookupFormValues) => {
    setSelectedAddress(null);
    postcodeLookupMutation.mutate(data.postcode);
  };

  // ========================
  //     NEW ADDRESS FORM
  // ========================

  const newAddressFormMethods = useForm<NewAddressFormValues>({
    resolver: yupResolver(NewAddressFormSchema),
    defaultValues: NEW_ADDRESS_FROM_DEFAULT_VALUES,
  });

  const { handleSubmit: handleSubmitNewAddress, reset: resetNewAddressForm } =
    newAddressFormMethods;

  // ========================
  //       HANDLER FNS
  // ========================

  function findAddressDetails(value: string) {
    const retrievedAddress = postcodeLookupMutation.data?.addresses.find(
      (address) => address.line_1 === value
    );
    return retrievedAddress;
  }

  const handleSelectAddress = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { value } = e.target;
    const address = findAddressDetails(value);
    if (!address) {
      errorMsg('Address not found');
    } else {
      resetNewAddressForm({
        line_1: address.line_1,
        line_2: address.line_2,
        line_3: address.line_3,
        line_4: address.line_4,
        town_or_city: address.town_or_city,
        postcode: postcodeLookupMutation.data?.postcode ?? '',
        default_address: true,
        address_name: '',
      });

      setSelectedAddress(address.line_1);
    }
  };

  const addNewAddressMutation = useMutation({
    // mutate function will make the API call
    mutationFn: (addressDto: Partial<UserAddress>) =>
      createAddressForUser(addressDto),
    onSuccess: () => {
      queryClient.invalidateQueries(queryKey);
      successMsg(`Address succesfully created`);
      onClose();
    },
    onError: (error) => {
      errorMsg(error, `There was an error while creating address`);
    },
  });

  const submitNewAddress = async (data: NewAddressFormValues) => {
    if (!selectedAddress) {
      errorMsg('Please select an address');
      return;
    }
    const address = findAddressDetails(selectedAddress);
    if (!address) {
      errorMsg('Cannot find selected address details');
      return;
    }
    const reFormattedLine = address.formatted_address
      .filter((n) => n)
      .join('</br>');
    const formattedAddress = {
      ...address,
      postcode: data.postcode,
      name: data.address_name,
      formatted_address: reFormattedLine,
      default: data.default_address,
      user_id: Number(userId),
    };
    if (!userId) throw new Error('Client ID not found');
    addNewAddressMutation.mutate(formattedAddress);
  };

  return (
    <Stack spacing={3} sx={{ px: 1, pb: selectedAddress ? 0 : 2 }}>
      <FormProvider
        methods={postcodeLookupMethods}
        onSubmit={handleSubmitPostcodeLookup(submitPostcodeLookup)}
      >
        <Stack direction="column" spacing={2}>
          <Typography sx={{ fontWeight: 500 }}>Postcode lookup</Typography>
          <Stack direction="row" spacing={2}>
            <RHFTextField
              size="small"
              name="postcode"
              label="Postcode"
              fullWidth
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Iconify icon={'eva:map-fill'} width={20} height={20} />
                  </InputAdornment>
                ),
              }}
            />

            <LoadingButton
              sx={{ width: '30%' }}
              variant="contained"
              size="small"
              type="submit"
              loading={postcodeLookupMutation.isLoading}
            >
              Find Address
            </LoadingButton>
          </Stack>
          {postcodeLookupMutation.isSuccess && (
            <TextField
              name="lookedup_addresses"
              label="Choose an address"
              value={selectedAddress ?? ''}
              select
              size="small"
              fullWidth
              onChange={handleSelectAddress}
            >
              {postcodeLookupMutation.data.addresses.map((address, index) => (
                <MenuItem key={index} value={address.line_1}>
                  {address.line_1}
                </MenuItem>
              ))}
            </TextField>
          )}
        </Stack>
      </FormProvider>

      {selectedAddress && (
        <FormProvider
          methods={newAddressFormMethods}
          onSubmit={handleSubmitNewAddress(submitNewAddress)}
        >
          <Stack direction="column" spacing={2}>
            <Typography sx={{ fontWeight: 500 }}>
              New address details
            </Typography>
            <RHFTextField name="line_1" label="Address line 1" />
            <RHFTextField name="line_2" label="Address line 2" />

            <RHFTextField name="line_3" label="Address line 3" />
            <RHFTextField name="line_4" label="Address line 4" />

            <RHFTextField name="town_or_city" label="Town or city" />
            <RHFTextField name="postcode" label="Postcode" />
            <RHFTextField name="address_name" label="Address' name" />
          </Stack>

          <RHFCheckbox
            name="default_address"
            label="Set this as default address"
            sx={{ py: 1, pl: 0.5 }}
          />

          <Stack direction="row" spacing={2} justifyContent="flex-end">
            <Button color="inherit" variant="outlined" onClick={onClose}>
              Cancel
            </Button>
            <LoadingButton
              type="submit"
              variant="contained"
              size="medium"
              loading={addNewAddressMutation.isLoading}
            >
              Add Address
            </LoadingButton>
          </Stack>
        </FormProvider>
      )}
    </Stack>
  );
}
