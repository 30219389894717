import { useEffect, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { isEmpty } from 'lodash';
// mui
import {
  Box,
  Chip,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  TextFieldProps,
} from '@mui/material';
// icons
import Iconify from '@/components/Iconify';
import { Role } from '@/features/roles-and-permissions/types';
// api

// types
interface IProps {
  name: string;
  currentUserRoles: string[];
  fullRolesData: Role[];
  label: string;
  roleNames: string[];
  size?: 'small' | 'medium';
}

export default function RHFMultiSelect({
  currentUserRoles,
  label,
  fullRolesData,
  name,
  roleNames,
  size = 'small',
}: IProps & TextFieldProps) {
  const { control, setValue } = useFormContext();

  const [selectedRoles, setSelectedRoles] = useState<string[]>([]);

  useEffect(() => {
    if (!isEmpty(currentUserRoles)) {
      setSelectedRoles(currentUserRoles);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  //  the API needs the roleIDs when we create/update user data
  const transformRoleNameToNumber = (roleName: string[]) => {
    let roleIDs: number[] = [];

    for (let i = 0; i < fullRolesData.length; i++) {
      const currentRoleInLoop = fullRolesData[i];
      roleName.forEach((role) => {
        if (role === currentRoleInLoop.name) {
          roleIDs.push(currentRoleInLoop.id);
        }
      });
    }
    return roleIDs;
  };

  const handleDelete = (e: React.MouseEvent, value: string) => {
    const updatedRoles = selectedRoles.filter((name) => name !== value);
    setSelectedRoles(updatedRoles);
    // updates the 'user_roles' for form submition with roleIDs
    setValue('user_roles', transformRoleNameToNumber(updatedRoles));
  };
  // --------------------------------------------------------------------------

  return (
    <FormControl size={size} sx={{ width: '100%', pr: 1 }}>
      <InputLabel id="user-roles-label">{label}</InputLabel>
      <Controller
        control={control}
        name={name}
        render={() => (
          <Select
            labelId="colors"
            id={name}
            label="Roles"
            value={selectedRoles}
            multiple
            input={<OutlinedInput id={name} label="Chip" />}
            size={size}
            onChange={(event: any) => {
              setSelectedRoles(event.target.value as string[]);
              setValue(
                'user_roles',
                transformRoleNameToNumber(event.target.value)
              );
            }}
            renderValue={(selected) => (
              <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                {selected.map((value, index) => (
                  <Chip
                    key={index}
                    label={value}
                    size={size}
                    onMouseDown={(event) => event.stopPropagation()}
                    onDelete={(e) => handleDelete(e, value)}
                    deleteIcon={
                      <IconButton>
                        <Iconify icon="eva:close-outline" />
                      </IconButton>
                    }
                  />
                ))}
              </Box>
            )}
          >
            {roleNames.sort().map((role) => (
              <MenuItem key={role} value={role}>
                {role}
              </MenuItem>
            ))}
          </Select>
        )}
      />
    </FormControl>
  );
}
