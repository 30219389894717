import { Skeleton, Divider, Box } from '@mui/material';

export default function BookingsListSkeletons() {
  return (
    <>
      <Box pl={2} pb={2}>
        <Skeleton sx={{ width: 0.98 }} height={100} />
      </Box>
      <Divider />
      <Box pl={2} pb={2}>
        <Skeleton sx={{ width: 0.98 }} height={100} />
      </Box>
      <Box pl={2} pb={2}>
        {[...Array(5)].map((_, index) => (
          <Skeleton sx={{ width: 0.98 }} height={60} key={index} />
        ))}
      </Box>
    </>
  );
}
