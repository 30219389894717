import { useState, useEffect } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';
// api
import { deleteProviderData, getProviderData } from '../api';
// @mui
import {
  Box,
  Button,
  Card,
  Container,
  FormControlLabel,
  IconButton,
  Switch,
  Table,
  TableBody,
  TableContainer,
  TablePagination,
  Tooltip,
  Skeleton,
} from '@mui/material';
// routes
import { PATH_USER } from '@/features/app';
import { settingsPath, PATH_SETTINGS } from '../routes/paths';
// hooks
import useSettings from '@/hooks/useSettings';
import useTable, { emptyRows } from '@/hooks/useTable';
import { useGetAccess } from '@/hooks/useAccess';
// components
import { Page, Iconify, Scrollbar, HeaderBreadcrumbs } from '@/components';

import {
  TableNoData,
  TableEmptyRows,
  TableSelectedActions,
  TableHeadCustom,
} from '@/components/table';
// custom components
import { ProviderTableToolbar, ProviderTableRow } from '../components';
// types
import type { Provider } from '../types';
// sort function
import { applySortFilterProviderList } from '../utils';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'name', label: 'Name', align: 'left' },
  { id: 'ref', label: 'Reference', align: 'left' },
  { id: 'active', label: 'Status', align: 'left' },
  { id: '' },
];

const STATUS_OPTIONS: string[] = ['all', 'active', 'inactive'];
// ----------------------------------------------------------------------

export default function ProviderList() {
  const {
    dense,
    page,
    order,
    orderBy,
    rowsPerPage,
    setPage,
    //
    selected,
    setSelected,
    onSelectRow,
    onSelectAllRows,
    //
    onSort,
    onChangeDense,
    onChangePage,
    onChangeRowsPerPage,
  } = useTable();

  const { themeStretch } = useSettings();

  const navigate = useNavigate();

  const { enqueueSnackbar } = useSnackbar();

  // --------------------------------------------------------------------------
  // API CALL + USESTATE + SETTING FETCHDATA
  const [tableData, setTableData] = useState<Provider[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    getProviderData().then((response) => {
      setTableData(response);
      setIsLoading(false);
    });
  }, []);

  // get the access for the module
  const allow = useGetAccess('products');

  // --------------------------------------------------------------------------
  //  FILTER & DELETE FUNCTIONS

  const [filterName, setFilterName] = useState('');
  const [filterStatus, setFilterStatus] = useState('all');

  const handleFilterName = (filterName: string) => {
    setFilterName(filterName.toLowerCase());
    setPage(0);
  };

  const handleFilterStatus = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFilterStatus(event.target.value);
  };

  const handleDelete = async (id: number) => {
    const { status } = await deleteProviderData(Number(id));
    if (status === 200) {
      const deleteRow = tableData.filter((row) => row.id !== id);
      setSelected([]);
      setTableData(deleteRow);
      enqueueSnackbar('Provider successfully deleted');
    }
  };

  const handleDeleteRows = (selected: string[]) => {
    const deleteRows = tableData.filter(
      (row) => !selected.includes(String(row.id))
    );
    setSelected([]);
    setTableData(deleteRows);
  };

  const handleEditRow = (id: number) => {
    navigate(settingsPath('edit-providers', 'id', id));
  };

  const dataFiltered = applySortFilterProviderList({
    tableData,
    filterName,
    filterStatus,
  });

  const denseHeight = dense ? 52 : 72;

  const isNotFound =
    (!dataFiltered.length && !!filterName) ||
    (!dataFiltered.length && !!filterStatus);

  return (
    <>
      {
        <Page title="Providers">
          <Container maxWidth={themeStretch ? false : 'lg'}>
            <HeaderBreadcrumbs
              heading="Providers"
              links={[
                { name: 'Dashboard', href: PATH_USER.root },
                {
                  name: 'Settings',
                  href: `${PATH_SETTINGS.root}${PATH_SETTINGS.providers}`,
                },
                { name: 'Providers', href: PATH_SETTINGS.providers },
              ]}
              action={
                allow.canCreate && (
                  <Button
                    variant="contained"
                    component={RouterLink}
                    to={settingsPath('new-providers')}
                    startIcon={<Iconify icon={'eva:plus-fill'} />}
                  >
                    Add new provider
                  </Button>
                )
              }
            />

            <Card>
              {isLoading ? (
                <Box pl={2} pb={2}>
                  <Skeleton sx={{ width: 0.98 }} height={100} />
                </Box>
              ) : (
                <ProviderTableToolbar
                  filterName={filterName}
                  filterStatus={filterStatus}
                  onFilterName={handleFilterName}
                  onFilterStatus={handleFilterStatus}
                  optionsStatus={STATUS_OPTIONS}
                />
              )}
              {isLoading ? (
                <Box pl={2} pb={2}>
                  {[...Array(5)].map((_, index) => (
                    <Skeleton key={index} sx={{ width: 0.98 }} height={40} />
                  ))}
                </Box>
              ) : (
                <Scrollbar>
                  <TableContainer sx={{ minWidth: 800, position: 'relative' }}>
                    {selected.length > 0 && (
                      <TableSelectedActions
                        dense={dense}
                        numSelected={selected.length}
                        rowCount={tableData.length}
                        onSelectAllRows={(checked) =>
                          onSelectAllRows(
                            checked,
                            tableData.map((row) => String(row.id))
                          )
                        }
                        actions={
                          allow.canDelete && (
                            <Tooltip title="Delete">
                              <IconButton
                                color="primary"
                                onClick={() => handleDeleteRows(selected)}
                              >
                                <Iconify icon={'eva:trash-2-outline'} />
                              </IconButton>
                            </Tooltip>
                          )
                        }
                      />
                    )}
                    <Table size={dense ? 'small' : 'medium'}>
                      <TableHeadCustom
                        order={order}
                        orderBy={orderBy}
                        headLabel={TABLE_HEAD}
                        rowCount={tableData.length}
                        numSelected={selected.length}
                        onSort={onSort}
                        onSelectAllRows={(checked) =>
                          onSelectAllRows(
                            checked,
                            tableData.map((row) => String(row.id))
                          )
                        }
                      />

                      <TableBody>
                        {dataFiltered
                          .slice(
                            page * rowsPerPage,
                            page * rowsPerPage + rowsPerPage
                          )
                          .map((row) => (
                            <ProviderTableRow
                              key={row.id}
                              row={row}
                              selected={selected.includes(String(row.id))}
                              onSelectRow={() => onSelectRow(String(row.id))}
                              onDeleteRow={() => handleDelete(row.id)}
                              onEditRow={() => handleEditRow(row.id)}
                              permissions={allow}
                            />
                          ))}

                        <TableEmptyRows
                          height={denseHeight}
                          emptyRows={emptyRows(
                            page,
                            rowsPerPage,
                            tableData.length
                          )}
                        />

                        <TableNoData isNotFound={isNotFound} />
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Scrollbar>
              )}

              <Box sx={{ position: 'relative' }}>
                <TablePagination
                  rowsPerPageOptions={[25, 50, 100]}
                  component="div"
                  count={tableData.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={onChangePage}
                  onRowsPerPageChange={onChangeRowsPerPage}
                />

                <FormControlLabel
                  control={<Switch checked={dense} onChange={onChangeDense} />}
                  label="Dense"
                  sx={{ px: 3, py: 1.5, top: 0, position: { md: 'absolute' } }}
                />
              </Box>
            </Card>
          </Container>
        </Page>
      }
    </>
  );
}
