import { useQuery } from '@tanstack/react-query';
//
import { Autocomplete, MenuItem, Stack, TextField } from '@mui/material';
// Redux
import { dispatch } from '@/stores';
import { updateAssignedCleaners } from '@/features/bookings-management/slices';
// API
import { getProviders } from '@/features/users/shared/api';
// utils + hooks
import { useSnackbarMsg } from '@/hooks/useSnackbarMsg';
// types

export default function SearchCleaner() {
  const { errorMsg } = useSnackbarMsg();

  const { data: cleaners } = useQuery({
    queryKey: ['providers'],
    queryFn: () => getProviders(),
    onError: (error) => {
      errorMsg(error, `Something went wrong while fetching cleaners data `);
    },
    refetchOnWindowFocus: false,
    // TODO: double check this with Adrian
    // staleTime: 24 * 60 * 60 * 1000, // update every 24 hours
    // cacheTime: 24 * 60 * 60 * 1000, // update every 24 hours
  });

  const handleChange = (cleanerId: number) => {
    const selectedCleaner = cleaners?.find(
      (cleaner) => cleaner.id === cleanerId
    );
    dispatch(updateAssignedCleaners(selectedCleaner));
  };
  // in the future it will be a multiple select
  // const handleChange = (arrayOfCleaners: Cleaner[]) => {
  //   dispatch(assignCleaners(arrayOfCleaners));
  //   setValue('cleaner', arrayOfCleaners[0].id);
  //   clearErrors('cleaner');
  // };

  // ---------------------------------------------

  return (
    <Stack direction="column" sx={{ mt: 2 }}>
      <Autocomplete
        fullWidth
        // multiple
        filterSelectedOptions
        options={cleaners ?? []}
        onChange={(_e, newValue) => handleChange(newValue?.id ?? 0)}
        // value={currentCleaner || null}
        // defaultValue={null}
        noOptionsText="No such cleaner"
        getOptionLabel={(option: any) =>
          `${option.first_name ?? 'N/A'} ${option.last_name ?? 'N/A'}`
        }
        renderOption={(props, option) => (
          <MenuItem {...props} key={option.id} value={option.id}>
            {`${option.first_name} ${option.last_name}`}
          </MenuItem>
        )}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Search cleaner"
            placeholder=""
            fullWidth
            autoFocus
          />
        )}
      />
    </Stack>
  );
}
