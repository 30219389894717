//
import { Box, Divider, Stack, Typography } from '@mui/material';
import { Iconify, Label } from '@/components';
import { VBMCardTitle, VBMCardWrapper, VBMCleaningExtras } from '..';
// redux
import { useSelector } from '@/stores';
// hooks
import useResponsive from '@/hooks/useResponsive';
// type
import { BookingSession } from '@/features/bookings-management/types';
type VBMCleaningTypeAndExtrasProps = {
  editable?: boolean;
  bookingSession?: BookingSession;
  sessionView?: boolean;
};

export default function VBMCleaningTypeAndExtras({
  editable,
  bookingSession,
  sessionView = false,
}: VBMCleaningTypeAndExtrasProps) {
  const booking = useSelector((state) => state.booking);
  const downMd = useResponsive('down', 'md');
  return (
    <VBMCardWrapper>
      <Stack
        spacing={2}
        divider={<Divider orientation="horizontal" />}
        sx={{ pt: 1 }}
      >
        <Stack spacing={1}>
          <VBMCardTitle title="Cleaning Type" />
          <Label
            sx={{
              backgroundColor: 'primary.main',
              color: 'background.paper',
              fontSize: downMd ? 10 : 15,
              p: 1,
              width: 'fit-content',
            }}
          >
            {sessionView && bookingSession
              ? `${bookingSession.service_variant?.service.name} - ${bookingSession.service_variant?.name}`
              : `${booking.service_variant?.service.name} - ${booking.service_variant?.name}`}
          </Label>
        </Stack>
        <VBMCleaningExtras
          editable={editable}
          bookingSession={bookingSession}
          sessionView={sessionView}
        />
        <Stack spacing={1}>
          <VBMCardTitle title="Pets" sx={{ mb: 1 }} />
          <Stack spacing={1.5} sx={{ pl: 1, pt: 0.5 }}>
            <Stack direction="row" alignItems="center" spacing={1}>
              <Box sx={{ width: 35 }}>
                <Iconify icon="ph:dog-fill" width={downMd ? 20 : 30} />
              </Box>
              <Typography fontSize={downMd ? 12 : 16}>
                {booking.cleanDetails.pets_enabled
                  ? booking.cleanDetails.pets.join(' + ')
                  : 'No pets'}
              </Typography>
            </Stack>
          </Stack>
        </Stack>
      </Stack>
    </VBMCardWrapper>
  );
}
