import { BaseObjectEntity } from '@/types';
import { path } from '@/utils/formatLink';
import { generatePath } from 'react-router';

export const ROOTS_COUPONS = '/coupons';

export const PATH_COUPONS: BaseObjectEntity = {
  root: ROOTS_COUPONS,
  list: '',
  new: '/new',
  edit: '/:couponId/edit',
};

export function couponsPath(pathKey: string, params?: BaseObjectEntity) {
  return generatePath(path(ROOTS_COUPONS, PATH_COUPONS[pathKey]), params);
}
