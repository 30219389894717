// mui
import { Box, Grid } from '@mui/material';
// components
import { RightSidebarTitle } from '..';
import { ChatStream } from '@/features/chat-stream/pages';
// import { ChannelFilters, ChannelOptions, ChannelSort, User } from 'stream-chat';
import { useChatClient } from '@/hooks/useChatClient';
// import { STREAM_KEY } from '@/config';
import { useEffect, useState } from 'react';

import { STREAM_KEY } from '@/config';
import { ChannelFilters, ChannelOptions, ChannelSort, User } from 'stream-chat';
import {
  Channel,
  ChannelHeader,
  ChannelList,
  Chat,
  LoadingIndicator,
  MessageInput,
  MessageList,
  Thread,
  Window,
} from 'stream-chat-react';

// import 'stream-chat-react/dist/css/v2/index.css';
// import '@/features/chat-stream/style/layout.css';
import useAuth from '@/hooks/useAuth';

// interface Props {
//   chatClient: any; // Replace 'any' with the actual type of your chat client
//   channelFilters: ChannelFilters;
//   channelSort: ChannelSort;
//   channelOptions: ChannelOptions;
// }
export default function RightChatBar() {
  const { user } = useAuth();

  const apiKey = STREAM_KEY;
  const userToken = user?.chat_token || '';

  const userId = user?.chat_user_id;
  const userName = user?.displayName;

  const chatUser: User = {
    id: userId,
    name: userName,
    image: user?.profile_image,
  };

  const chatClient = useChatClient({
    apiKey,
    user: chatUser,
    tokenOrProvider: userToken,
  });

  if (!chatClient) {
    return <LoadingIndicator />;
  }

  const sort: ChannelSort = { last_message_at: -1 };
  const filters: ChannelFilters = {
    type: {
      $in: [
        'messaging',
        'team',
        'jobSupport',
        'cleanerSupport',
        'clientSupport',
      ],
    },
    members: { $in: [userId] },
  };
  const options: ChannelOptions = {
    limit: 10,
  };

  return (
    <Grid
      item
      container
      direction="column"
      spacing={2}
      sx={{
        m: 0,
        backgroundColor: 'background.paper',
        width: '100%',
        height: '100vh',
        zIndex: 5,
        boxShadow: '-12px 12px 32px - 4px rgba(99, 115, 129, 0.36)',
      }}
    >
      <Box
        sx={{
          width: '100%',
          height: '100vh',
        }}
      >
        <RightSidebarTitle title="Chat" />
        <ChatStream
          chatClient={chatClient}
          channelSort={sort}
          channelFilters={filters}
          channelOptions={options}
        />
      </Box>
    </Grid>
  );
}
