//
import { MenuItem, TableCell, TableRow } from '@mui/material';
// components
import { Iconify } from '@/components';
import { TableMoreMenu, TableRowLink } from '@/components/table';
import { ProviderPriceDialog } from '..';
// hooks + utils
import useToggle from '@/hooks/useToggle';
import { use3DotMenu } from '@/hooks/use3DotMenu';
import { fCurrency } from '@/utils/formatNumber';
import { fDateAbbrevMonth } from '@/utils/formatTime';
import { servicesManagementPath } from '@/features/services-management';
// types
import { UserCustomPrice } from '@/features/users/shared/types';

type ProviderPricesTableRowProps = {
  row: UserCustomPrice;
  canUpdate: boolean;
};

// ----------------------------------------------------------------------
export default function ProviderPricesTableRow({
  row,
  canUpdate,
}: ProviderPricesTableRowProps) {
  const {
    id,
    cities,
    service_variant,
    price,
    sun_bank_holiday_extra,
    start_date,
    end_date,
    locked,
  } = row;

  const isPriceExpired = end_date < new Date() || locked;
  const isEditAllowed = canUpdate && !locked && !isPriceExpired;

  // ============
  //     EDIT
  // ============
  const {
    toggle: isCustomPriceDialogOpen,
    onOpen: openCustomPriceDialog,
    onClose: closeCustomPriceDialog,
  } = useToggle();

  const { openMenu, handleOpenMenu, handleCloseMenu } = use3DotMenu();

  const handleEditRow = () => {
    openCustomPriceDialog();
    handleCloseMenu();
  };

  // ----------------------------------------------------------------------

  return (
    <>
      <TableRow
        hover
        sx={{
          color: (theme) =>
            isPriceExpired ? theme.palette.grey[400] : 'inherit',
        }}
      >
        <TableCell width={10} />

        <TableCell
          align="left"
          sx={{
            color: 'inherit',
            paddingLeft: isPriceExpired ? '1.5rem' : 'none',
          }}
        >
          {isEditAllowed ? (
            <TableRowLink
              linkText={cities.name}
              linkTo="#"
              onClick={handleEditRow}
            />
          ) : (
            <>{cities.name}</>
          )}
        </TableCell>

        <TableCell align="left">
          {service_variant.service ? (
            <TableRowLink
              linkText={service_variant.service.name}
              linkTo={servicesManagementPath('editService', {
                serviceId: String(service_variant.service.id),
              })}
              sx={{ color: 'inherit' }}
            />
          ) : (
            'N/A'
          )}
        </TableCell>

        <TableCell align="left">
          {service_variant ? (
            <TableRowLink
              linkText={service_variant.name}
              linkTo={servicesManagementPath('editVariant', {
                variantId: String(service_variant.id),
                tabSid: 'details',
              })}
              sx={{ color: 'inherit' }}
            />
          ) : (
            'N/A'
          )}
        </TableCell>

        <TableCell align="left" sx={{ color: 'inherit' }}>
          {fCurrency(price)}
        </TableCell>
        <TableCell align="left" sx={{ color: 'inherit' }}>
          {fCurrency(sun_bank_holiday_extra)}
        </TableCell>

        <TableCell align="left" sx={{ color: 'inherit' }}>
          {fDateAbbrevMonth(start_date, 'EEE, dd MMM yyyy')}
        </TableCell>

        <TableCell align="left" sx={{ color: 'inherit' }}>
          {fDateAbbrevMonth(end_date, 'EEE, dd MMM yyyy')}
        </TableCell>

        <TableCell align="left">
          {
            <Iconify
              icon={
                locked ? 'material-symbols:lock' : 'material-symbols:lock-open'
              }
              color={locked ? 'error.main' : 'success.main'}
            />
          }
        </TableCell>
        <TableCell align="right">
          {isEditAllowed && (
            <TableMoreMenu
              open={openMenu}
              onOpen={handleOpenMenu}
              onClose={handleCloseMenu}
              arrow="top-right"
              actions={
                <MenuItem onClick={handleEditRow}>
                  <Iconify icon="eva:edit-outline" />
                  Edit
                </MenuItem>
              }
            />
          )}
        </TableCell>
      </TableRow>

      <ProviderPriceDialog
        isCustomPriceDialogOpen={isCustomPriceDialogOpen}
        closeCustomPriceDialog={closeCustomPriceDialog}
        currentPriceId={String(id)}
      />
    </>
  );
}
