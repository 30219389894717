import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
//
import {
  Box,
  Card,
  FormControlLabel,
  Switch,
  Table,
  TableBody,
  TableContainer,
  TablePagination,
} from '@mui/material';
import { Scrollbar } from '@/components';
import { SkeletonLoadingTable } from '@/components/skeleton';
import { ToolbarWithSearchAndStatus } from '@/components/toolbar';
import {
  TableEmptyRows,
  TableHeadCustom,
  TableNoData,
} from '@/components/table';
import { ClientInvoicesTableRow } from '..';
// api
// import { getProviderInvoices } from '../../api';
// hookes + utils
import { useGetAccess } from '@/hooks/useAccess';
import useSettings from '@/hooks/useSettings';
import { useSnackbarMsg } from '@/hooks/useSnackbarMsg';
import useTable, { emptyRows, getComparator } from '@/hooks/useTable';
import {
  PROVIDER_INVOICES_TABLE_HEAD,
  applySortFilterProviderInvoices,
} from '../../utils';
// types

export type DummyProviderInvoices = {
  id: number;
  from_date: string;
  to_date: string;
  payment_date: string;
  jobs_no: number;
};

const DUMMY_PROVIDER_INVOICES = [
  {
    id: 2345,
    from_date: '2023-11-20',
    to_date: '2023-11-26',
    payment_date: '2023-12-01',
    jobs_no: 2,
  },
  {
    id: 2346,
    from_date: '2023-10-08',
    to_date: '2023-10-10',
    payment_date: '2023-11-20',
    jobs_no: 3,
  },
];

const isProviderInvoicesDataLoading = false;

export default function ClientInvoices() {
  const {
    dense,
    page,
    order,
    orderBy,
    rowsPerPage,
    //
    selected,
    //
    onSort,
    onChangeDense,
    onChangePage,
    onChangeRowsPerPage,
  } = useTable();

  const allow = useGetAccess('users');
  // const { themeStretch } = useSettings();
  // const { errorMsg, successMsg } = useSnackbarMsg();

  const [searchFieldValue, setSearchFieldValue] = useState('');

  // =================
  //      QUERIES
  // =================
  // const { data: reviews, isLoading: isReviewsDataLoading } = useQuery({
  //   queryKey: ['invoices'],
  //   queryFn: () => getProviderInvoices(),
  //   onError: (error) => {
  //     errorMsg(error, `Something went wrong while fetching invoices`);
  //   },
  // });

  const dataFiltered = applySortFilterProviderInvoices({
    tableData: DUMMY_PROVIDER_INVOICES || [],
    comparator: getComparator(order, orderBy),
    searchFieldValue,
  });

  const denseHeight = dense ? 52 : 72;

  const isNotFound =
    (!dataFiltered.length && !!searchFieldValue) || !dataFiltered.length;

  return (
    <Card>
      {isProviderInvoicesDataLoading ? (
        <SkeletonLoadingTable />
      ) : (
        <>
          <ToolbarWithSearchAndStatus
            filterText={searchFieldValue}
            onFilterText={setSearchFieldValue}
            labels={{ search: 'Search in invoices...' }}
          />

          <Scrollbar>
            <TableContainer sx={{ minWidth: 800, position: 'relative' }}>
              <Table size={dense ? 'small' : 'medium'}>
                <TableHeadCustom
                  order={order}
                  orderBy={orderBy}
                  headLabel={PROVIDER_INVOICES_TABLE_HEAD}
                  rowCount={dataFiltered.length}
                  numSelected={selected.length}
                  onSort={onSort}
                />
                <TableBody>
                  {dataFiltered
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((invoice) => (
                      <ClientInvoicesTableRow
                        key={invoice.id}
                        row={invoice}
                        canDelete={allow.canDelete}
                        canUpdate={allow.canUpdate}
                      />
                    ))}

                  <TableEmptyRows
                    height={denseHeight}
                    emptyRows={emptyRows(
                      page,
                      rowsPerPage,
                      dataFiltered.length
                    )}
                  />

                  <TableNoData
                    isNotFound={isNotFound}
                    title="No invoices found"
                  />
                </TableBody>
              </Table>
            </TableContainer>
          </Scrollbar>

          <Box sx={{ position: 'relative' }}>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={dataFiltered.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={onChangePage}
              onRowsPerPageChange={onChangeRowsPerPage}
            />

            <FormControlLabel
              control={<Switch checked={dense} onChange={onChangeDense} />}
              label="Dense"
              sx={{ px: 3, py: 1.5, top: 0, position: { md: 'absolute' } }}
            />
          </Box>
        </>
      )}
    </Card>
  );
}
