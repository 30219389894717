import { User, UserAddress } from '@/features/users/shared/types';
import { tableSortFilter } from '@/utils/arrayProcessing';

function isUser(item: any): item is User {
  return (
    'first_name' in item &&
    'last_name' in item &&
    'email' in item &&
    'address' in item
  );
}

function isUserAddress(item: any): item is UserAddress {
  return 'postcode' in item && 'line_1' in item && 'town_or_city' in item;
}
// SearchAddress + SearchClient
export function applyFilterSearchbar({
  tableData,
  searchFieldValue,
  comparator,
}: {
  tableData: (UserAddress | User)[];
  searchFieldValue: string;
  comparator: (a: any, b: any) => number;
}) {
  tableData = tableSortFilter(tableData, comparator);
  const seachFieldValueLowerCase = searchFieldValue.toLowerCase();

  if (searchFieldValue) {
    tableData = tableData.filter((item) => {
      // filter in clients
      if (isUser(item)) {
        const user = `${item?.first_name} ${item?.last_name}`;
        return user.toLowerCase().indexOf(seachFieldValueLowerCase) !== -1;
      }
      // filter in addresses
      if (isUserAddress(item)) {
        return (
          item.postcode.toLowerCase().indexOf(seachFieldValueLowerCase) !==
            -1 ||
          item.postcode
            .toLowerCase()
            .replace(/\s/g, '')
            .indexOf(seachFieldValueLowerCase) !== -1 ||
          item.name.toLowerCase().indexOf(seachFieldValueLowerCase) !== -1 ||
          item.line_1.toLowerCase().indexOf(seachFieldValueLowerCase) !== -1 ||
          item.town_or_city.toLowerCase().indexOf(seachFieldValueLowerCase) !==
            -1
        );
      }

      return false;
    });
  }

  return tableData;
}
