import { LoadingButton } from '@mui/lab';
import { Stack, Button } from '@mui/material';
// types
type CleaningJobDetailsActionButtonsProps = {
  isFormDirty: boolean;
  isSubmitting: boolean;
  closeJobDialog: () => void;
};

export default function CleaningJobDetailsActionButtons({
  isFormDirty,
  isSubmitting,
  closeJobDialog,
}: CleaningJobDetailsActionButtonsProps) {
  return (
    <Stack
      spacing={2}
      justifyContent="flex-end"
      direction={'row'}
      sx={{ pb: 3, pt: 1 }}
    >
      {isFormDirty ? (
        <>
          <Button
            variant="outlined"
            size="medium"
            color="inherit"
            onClick={closeJobDialog}
            sx={{ width: '20%' }}
          >
            Cancel
          </Button>

          <LoadingButton
            type="submit"
            loading={isSubmitting}
            variant="contained"
            color="primary"
            sx={{ width: '20%' }}
          >
            Save
          </LoadingButton>
        </>
      ) : (
        <Button
          variant="contained"
          size="medium"
          color="primary"
          onClick={closeJobDialog}
          sx={{ width: '20%' }}
        >
          Ok
        </Button>
      )}
    </Stack>
  );
}
