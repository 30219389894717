import { BaseObjectEntity } from '@/types';
import { path } from '@/utils/formatLink';

export const ROOTS_ACTIVITIES = '/activities';

export const PATH_ACTIVITIES: BaseObjectEntity = {
  root: ROOTS_ACTIVITIES,
  new: '/new',
  edit: '/:id/edit',
};

export function activitiesPath(
  pathKey: string,
  key: string = '',
  value: number | string = ''
) {
  return key
    ? path(ROOTS_ACTIVITIES, PATH_ACTIVITIES[pathKey]).replace(
        `:${key}`,
        String(value)
      )
    : path(ROOTS_ACTIVITIES, PATH_ACTIVITIES[pathKey]);
}
