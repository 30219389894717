import { m } from 'framer-motion';
import { useFormContext, useWatch } from 'react-hook-form';
import { useQuery } from '@tanstack/react-query';
import { isEmpty } from 'lodash';
//
import {
  Box,
  Divider,
  IconButton,
  MenuItem,
  Skeleton,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import { alpha } from '@mui/material/styles';
//
import {
  RHFTextField,
  RHFMobiscrollDateTimePicker,
} from '@/components/hook-form';
import { Iconify, Label } from '@/components';
import {
  CleaningJobDetailsActionButtons,
  CleaningJobDetailsDialogSkeleton,
  CleaningJobDetailsTableRow,
} from '..';
import AddressWithGoogleMapsIconButton from '@/components/AddressWithGoogleMapsIconButton';
// utils
import { bookingsManagementPath } from '@/features/bookings-management';
import { clientManagementPath } from '@/features/users/clients';
import { getSettingsData } from '@/features/app/api';
import { useSnackbarMsg } from '@/hooks/useSnackbarMsg';
import {
  assignPropertyTypeIcon,
  generateRecurringRuleText,
  getSessionDateFilter,
  mobiscrollPickerSx,
} from '../../utils';
// API
import { getProviders } from '@/features/users/shared/api';
//types
import { BookingSession } from '@/features/bookings-management/types';
import { LabelColors } from '@/types';

type DetailsDialogProps = {
  closeJobDialog: () => void;
  currentCleaningSession: BookingSession | undefined;
};
export default function CleaningJobDetailsDialogDetails({
  closeJobDialog,
  currentCleaningSession,
}: DetailsDialogProps) {
  const theme = useTheme();
  const { errorMsg } = useSnackbarMsg();

  const {
    control,
    formState: { isSubmitting, isDirty },
  } = useFormContext();

  const { mbscTimePicker, mbscDatePicker } = useWatch({ control });

  const isJobRecurring =
    !!currentCleaningSession &&
    !isEmpty(currentCleaningSession?.booking_recurrence) &&
    currentCleaningSession?.booking_recurrence.interval_number > 0;

  // ====================
  //       QUERIES
  // ====================
  const { data: allCleaners, isLoading: isCleanersDataLoading } = useQuery({
    queryKey: ['providers'],
    queryFn: getProviders,
    onError: (error) =>
      errorMsg(error, 'There was an error while fetching cleaners data'),
  });

  const { data: settingsData, isLoading: isSettingsDataLoading } = useQuery({
    queryKey: ['settings'],
    queryFn: () => getSettingsData(),
    onError: (error) => {
      errorMsg(error, `Something went wrong while fetching settings data `);
    },
    // TODO: add stale time
  });

  const client = `${currentCleaningSession?.user?.first_name} ${currentCleaningSession?.user?.last_name}`;

  const showSkeleton =
    isCleanersDataLoading || isSettingsDataLoading || !currentCleaningSession;

  return (
    <>
      {!showSkeleton ? (
        <Stack
          px={3}
          spacing={1.8}
          divider={<Divider orientation="horizontal" flexItem />}
          // framer motion
          component={m.div}
          initial={{ opacity: 1, overflow: 'hidden' }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{
            ease: 'linear',
            duration: 0.2,
          }}
        >
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-evenly"
            spacing={2}
            divider={<Divider orientation="vertical" flexItem />}
          >
            {/* BOOKING ID */}
            <Stack direction="row">
              <CleaningJobDetailsTableRow
                labelText="Booking ID"
                descriptionText={`#${String(
                  currentCleaningSession.booking_id
                )}`}
                descriptionSx={{ pl: 1 }}
                iconText={assignPropertyTypeIcon(
                  currentCleaningSession.property_type ?? ''
                )}
                openInNewTab
                linkTo={bookingsManagementPath('view', {
                  bookingId: String(currentCleaningSession.booking_id),
                  bookingTabSid: 'booking-sessions',
                  sessionFilter: getSessionDateFilter(
                    currentCleaningSession.date
                  ),
                })}
              />
            </Stack>

            {/* BOOKING SESSION ID */}
            <Stack direction="row">
              <CleaningJobDetailsTableRow
                labelText="Session ID"
                descriptionText={`#${String(currentCleaningSession.id)}`}
                descriptionSx={{ pl: 1 }}
                openInNewTab
                linkTo={
                  bookingsManagementPath('view', {
                    bookingId: String(currentCleaningSession.booking_id),
                    bookingTabSid: 'booking-sessions',
                    sessionFilter: getSessionDateFilter(
                      currentCleaningSession.date
                    ),
                  }) + `?sessionID=${currentCleaningSession.id}`
                }
              />
            </Stack>
          </Stack>

          {/* STATUS */}
          <Stack direction="row" alignItems="center">
            <Typography sx={{ width: '22%' }}>Status</Typography>

            {settingsData?.bookingSessionStatuses ? (
              <RHFTextField
                name="status_id"
                select
                sx={{
                  width: 200,
                }}
              >
                {settingsData?.bookingSessionStatuses.map((status) => (
                  <MenuItem key={status.id} value={status.id}>
                    <Label color={(status.style_class ?? '') as LabelColors}>
                      {status.display}
                    </Label>
                  </MenuItem>
                ))}
              </RHFTextField>
            ) : (
              <Skeleton variant="rectangular" width={150} height={40} />
            )}
          </Stack>

          {/* CLEANER */}
          <Stack direction="row" alignItems="center">
            <Typography sx={{ width: '22%' }}>Cleaner</Typography>

            {allCleaners ? (
              <RHFTextField name="booking_cleaners" select sx={{ width: 200 }}>
                {allCleaners.map((cleaner) => (
                  <MenuItem key={cleaner.id} value={cleaner.id}>
                    {`${cleaner.first_name} ${cleaner.last_name}`}
                  </MenuItem>
                ))}
              </RHFTextField>
            ) : (
              <Skeleton variant="rectangular" width={150} height={40} />
            )}

            <Stack direction="row" spacing={0.5} sx={{ marginLeft: 'auto' }}>
              {/* TODO: hook this up with API + extract component*/}
              <IconButton
                color="success"
                sx={{
                  '&:hover': {
                    backgroundColor: (theme) =>
                      alpha(theme.palette.success.light, 0.3),
                  },
                }}
              >
                <Iconify icon="material-symbols:thumb-up-outline" />
              </IconButton>
              <IconButton color="error">
                <Iconify icon="material-symbols:thumb-down-outline" />
              </IconButton>
            </Stack>
          </Stack>

          {/* DATE PICKER */}
          <Stack
            direction="row"
            alignItems="center"
            sx={mobiscrollPickerSx(theme)}
          >
            <Typography sx={{ width: '22%' }}>Date</Typography>
            <Box>
              <RHFMobiscrollDateTimePicker
                name="mbscDatePicker"
                label=""
                value={mbscDatePicker}
                mbscProps={{
                  controls: ['calendar'],
                  touchUi: true,
                  display: 'anchored',
                  animation: 'pop',
                  dateFormat: 'DDD DD-MMM-YYYY',
                }}
              />
            </Box>
          </Stack>

          {/* TIME PICKER */}
          <Stack
            direction="row"
            alignItems="center"
            sx={mobiscrollPickerSx(theme)}
          >
            <Typography sx={{ width: '22%' }}>Time</Typography>
            <Box>
              <RHFMobiscrollDateTimePicker
                name="mbscTimePicker"
                label=""
                value={mbscTimePicker}
                mbscProps={{
                  controls: ['timegrid'],
                  display: 'anchored',
                  touchUi: true,
                  minTime: '06:00',
                  maxTime: '23:00',
                  stepMinute: 30,
                  animation: 'pop',
                  timeFormat: 'HH:mm',
                }}
              />
            </Box>
          </Stack>

          {/* RECURRENCE */}
          {isJobRecurring && (
            <CleaningJobDetailsTableRow
              labelText="Recurring"
              descriptionText={generateRecurringRuleText(
                currentCleaningSession
              )}
            />
          )}

          {/* CLIENT */}
          <CleaningJobDetailsTableRow
            labelText="Client"
            descriptionText={client}
            linkTo={clientManagementPath('edit', {
              userId: String(currentCleaningSession?.user?.id),
              tabSid: 'details',
            })}
          />

          {/* ADDRESS */}
          <CleaningJobDetailsTableRow
            labelText="Address"
            descriptionText={
              <AddressWithGoogleMapsIconButton
                formatted_address={
                  currentCleaningSession.address.formatted_address
                }
                postcode={currentCleaningSession.address.postcode}
              />
            }
            descriptionSx={{ whiteSpace: 'pre-line', lineHeight: 1.1 }}
          />

          {/*  ADMIN NOTES */}
          <Stack direction="row" alignItems="center">
            <Typography sx={{ width: '22%' }}>Admin Notes</Typography>

            <RHFTextField name="admin_notes" multiline />
          </Stack>

          {/* BUTTONS */}
          <CleaningJobDetailsActionButtons
            isFormDirty={isDirty}
            isSubmitting={isSubmitting}
            closeJobDialog={closeJobDialog}
          />
        </Stack>
      ) : (
        <CleaningJobDetailsDialogSkeleton />
      )}
    </>
  );
}
