import { MenuItem, TextField } from '@mui/material';

type Props = {
  name?: string;
  label: string;
  maxWidth?: number | string;
  disabled?: boolean;
  listOptions: { id: number; name: string }[];
  filterDropdownValue: number;
  handleFilterDropdown?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  size?: 'small' | 'medium';
};

export default function DropdownSelection({
  name,
  label,
  maxWidth = '100%',
  disabled = false,
  filterDropdownValue,
  handleFilterDropdown,
  listOptions,
  size = 'small',
}: Props) {
  return (
    <TextField
      disabled={disabled}
      name={name}
      fullWidth
      select
      size={size}
      label={label}
      value={filterDropdownValue}
      onChange={handleFilterDropdown}
      SelectProps={{
        MenuProps: {
          sx: { '& .MuiPaper-root': { maxHeight: 260 } },
        },
      }}
      sx={{
        maxWidth: { md: maxWidth || 180 },
        textTransform: 'capitalize',
      }}
    >
      {listOptions.map((option) => (
        <MenuItem
          key={option.id}
          value={option.id}
          sx={{
            mx: 1,
            my: 0.5,
            borderRadius: 0.75,
            typography: 'body2',
            textTransform: 'capitalize',
          }}
        >
          {option.name}
        </MenuItem>
      ))}
    </TextField>
  );
}
