import {
  EmailTemplate,
  ActivityType,
  Category,
  CoverExtra,
  CoverType,
  Product,
  Provider,
} from '../types';

// ActivityTypeList.tsx
export function applySortFilterList({
  tableData,
  filterName,
  filterStatus,
}: {
  tableData: ActivityType[];
  filterName: string;
  filterStatus: string;
}) {
  if (filterName) {
    tableData = tableData.filter(
      ({ name }) => name.toLowerCase().indexOf(filterName.toLowerCase()) !== -1
    );
  }

  if (filterStatus !== 'all') {
    tableData = tableData.filter(({ active }) =>
      active === true
        ? filterStatus.toLowerCase() === 'active'
        : filterStatus.toLowerCase() === 'inactive'
    );
  }
  return tableData;
}

export function applySortFilterForTemplates({
  tableData,
  filterText,
  filterStatus,
}: {
  tableData: EmailTemplate[];
  filterText: string;
  filterStatus: string;
}) {
  if (filterText) {
    tableData = tableData.filter(
      ({ subject, description, email_template_category }) =>
        subject.toLowerCase().indexOf(filterText.toLowerCase()) !== -1 ||
        description.toLowerCase().indexOf(filterText.toLowerCase()) !== -1 ||
        email_template_category.name
          .toLowerCase()
          .indexOf(filterText.toLowerCase()) !== -1
    );
  }
  if (filterStatus !== 'all') {
    tableData = tableData.filter(
      ({ active }) => active === (filterStatus === 'active')
    );
  }

  return tableData;
}

// CategoryList.tsx
export function applySortFilterForCategoryList({
  tableData,
  filterName,
  filterStatus,
}: {
  tableData: Category[];

  filterName: string;
  filterStatus: string;
}) {
  if (filterName) {
    tableData = tableData.filter(
      ({ name, description }) =>
        name.toLowerCase().indexOf(filterName.toLowerCase()) !== -1 ||
        description.toLowerCase().indexOf(filterName.toLowerCase()) !== -1
    );
  }

  if (filterStatus !== 'all') {
    tableData = tableData.filter(({ active }) =>
      active === true
        ? filterStatus.toLowerCase() === 'active'
        : filterStatus.toLowerCase() === 'inactive'
    );
  }
  return tableData;
}

// ProductList.tsx
export function applySortFilterForProductList({
  tableData,
  filterName,
  filterCategory,
  filterStatus,
}: {
  tableData: Product[];
  filterName: string;
  filterCategory: string;
  filterStatus: string;
}) {
  if (filterName) {
    tableData = tableData.filter(
      ({ name, description, provider: { name: providerName } }) =>
        name.toLowerCase().indexOf(filterName.toLowerCase()) !== -1 ||
        description.toLowerCase().indexOf(filterName.toLowerCase()) !== -1 ||
        providerName.toLowerCase().indexOf(filterName.toLowerCase()) !== -1
    );
  }

  if (filterStatus !== 'all') {
    tableData = tableData.filter(({ active }) =>
      active === true
        ? filterStatus.toLowerCase() === 'active'
        : filterStatus.toLowerCase() === 'inactive'
    );
  }
  if (filterCategory !== 'all') {
    tableData = tableData.filter(
      ({ name }) =>
        name.toLowerCase().indexOf(filterCategory.toLowerCase()) !== -1
    );
  }
  return tableData;
}

// ProviderList.tsx
export function applySortFilterProviderList({
  tableData,
  filterName,
  filterStatus,
}: {
  tableData: Provider[];
  filterName: string;
  filterStatus: string;
}) {
  if (filterName) {
    tableData = tableData.filter(
      ({ name, ref }) =>
        name.toLowerCase().indexOf(filterName.toLowerCase()) !== -1 ||
        ref.toLowerCase().indexOf(filterName.toLowerCase()) !== -1
    );
  }

  if (filterStatus !== 'all') {
    tableData = tableData.filter(({ active }) =>
      active === true
        ? filterStatus.toLowerCase() === 'active'
        : filterStatus.toLowerCase() === 'inactive'
    );
  }
  return tableData;
}

// CoverLevelType
export function applySortFilterCoverLevelList({
  tableData,
  filterName,
  filterStatus,
}: {
  tableData: CoverType[];
  filterName: string;
  filterStatus: string;
}) {
  if (filterName) {
    tableData = tableData.filter(
      ({ name }) => name.toLowerCase().indexOf(filterName.toLowerCase()) !== -1
    );
  }

  if (filterStatus !== 'all') {
    tableData = tableData.filter(({ active }) =>
      active === true
        ? filterStatus.toLowerCase() === 'active'
        : filterStatus.toLowerCase() === 'inactive'
    );
  }
  return tableData;
}

// CoverLevelExtrtas
export function applySortFilterCoverLevelExtrasList({
  tableData,
  filterName,
  filterStatus,
  filterCoverLevel,
}: {
  tableData: CoverExtra[];
  filterName: string;
  filterStatus: string;
  filterCoverLevel: string;
}) {
  if (filterName) {
    tableData = tableData.filter(
      ({ name, extra_type: { name: extraTypeName } }) =>
        name.toLowerCase().indexOf(filterName.toLowerCase()) !== -1 ||
        extraTypeName.toLowerCase().indexOf(filterName.toLowerCase()) !== -1
    );
  }
  if (filterCoverLevel !== 'all') {
    tableData = tableData.filter(
      ({ name }) =>
        name.toLowerCase().indexOf(filterCoverLevel.toLowerCase()) !== -1
    );
  }

  if (filterStatus !== 'all') {
    tableData = tableData.filter(({ active }) =>
      active === true
        ? filterStatus.toLowerCase() === 'active'
        : filterStatus.toLowerCase() === 'inactive'
    );
  }
  return tableData;
}
