export const RIGHT_SIDEBAR_MENU_OPTIONS = [
  {
    id: 1,
    title: 'Scheduler',
    slug: 'scheduler',
    icon: 'eva:calendar-fill',
    width: '80%',
  },
  {
    id: 2,
    title: 'Chat',
    slug: 'chat',
    icon: 'eva:message-circle-outline',
    width: '70%',
  },
];
