import { useState } from 'react';
// @mui
import { useTheme } from '@mui/material/styles';
import {
  TableRow,
  TableCell,
  Typography,
  MenuItem,
  Checkbox,
} from '@mui/material';
// components
import { Label, Iconify } from '@/components';
import { TableMoreMenu } from '@/components/table';
// types
import { Product } from '../types';
import { PermissionsObject } from '@/types';

type Props = {
  row: Product;
  selected: boolean;
  permissions?: PermissionsObject;
  onEditRow: VoidFunction;
  onSelectRow: VoidFunction;
  onDeleteRow: VoidFunction;
};

export default function ProductTableRow({
  row,
  selected,
  permissions,
  onSelectRow,
  onEditRow,
  onDeleteRow,
}: Props) {
  const theme = useTheme();

  const {
    name,
    description,
    active,
    product_category,
    provider: { name: provider },
  } = row;

  const [openMenu, setOpenMenuActions] = useState<HTMLElement | null>(null);

  const handleOpenMenu = (event: React.MouseEvent<HTMLElement>) => {
    setOpenMenuActions(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setOpenMenuActions(null);
  };
  const { canDelete, canUpdate } = permissions || {};
  return (
    <TableRow hover selected={selected}>
      <TableCell padding="checkbox">
        <Checkbox checked={selected} onClick={onSelectRow} />
      </TableCell>

      <TableCell>
        <Typography variant="subtitle2" noWrap>
          {name}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography variant="subtitle2" noWrap>
          {product_category.name}
        </Typography>
      </TableCell>

      <TableCell align="left">
        <Typography>{description}</Typography>
      </TableCell>
      <TableCell align="left">
        <Typography>{provider}</Typography>
      </TableCell>

      <TableCell align="left">
        <Label
          variant={theme.palette.mode === 'light' ? 'ghost' : 'filled'}
          color={(active === false && 'error') || 'success'}
          sx={{ textTransform: 'capitalize' }}
        >
          {active ? 'Active' : 'Inactive'}
        </Label>
      </TableCell>

      <TableCell align="right">
        {(canDelete || canUpdate) && (
          <TableMoreMenu
            open={openMenu}
            onOpen={handleOpenMenu}
            onClose={handleCloseMenu}
            actions={
              <>
                {canUpdate && (
                  <MenuItem
                    onClick={() => {
                      onEditRow();
                      handleCloseMenu();
                    }}
                  >
                    <Iconify icon={'eva:edit-fill'} />
                    Edit
                  </MenuItem>
                )}
                {canDelete && (
                  <MenuItem
                    onClick={() => {
                      onDeleteRow();
                      handleCloseMenu();
                    }}
                    sx={{ color: 'error.main' }}
                  >
                    <Iconify icon={'eva:trash-2-outline'} />
                    Delete
                  </MenuItem>
                )}
              </>
            }
          />
        )}
      </TableCell>
    </TableRow>
  );
}
