import { useState, useEffect, useMemo } from 'react';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';
import { isEmpty } from 'lodash';
// api
import { postCoverLevelData, updateCoverLevelData } from '../api';
// form
import { object, string } from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import { Stack, Grid, Box, Card, Container, Skeleton } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
// hooks
import useSettings from '@/hooks/useSettings';
// components
import { FormProvider, RHFSelect, RHFTextField } from '@/components/hook-form';
// types
import type { CoverType } from '../types';
// paths
import { settingsPath } from '../routes/paths';

// roots

// ----------------------------------------------------------------------
const STATUS_OPTIONS = ['Active', 'Inactive'];

// PROP TYPES
interface FormValuesProps {
  name: string;
  active: string;
}

type Props = {
  currentData?: CoverType;
  ID?: number;
};

export default function ActivityTypesCreateEdit({ currentData }: Props) {
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  // ----------------------------------------------------------------------
  // REACT HOOK FORM

  const Schema = object().shape({
    name: string().required('Cover level type is required'),
    active: string().required('Status is required'),
  });

  const defaultValues = useMemo(
    () => ({
      name: currentData?.name || '',
      active: currentData?.active
        ? 'Active'
        : isEmpty(currentData)
        ? 'Active'
        : 'Inactive',
    }),

    [currentData]
  );

  const methods = useForm<FormValuesProps>({
    resolver: yupResolver(Schema),
    defaultValues,
  });

  const {
    reset,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  useEffect(() => {
    if (!isEmpty(currentData)) {
      reset(defaultValues);
    }
    setIsLoading(false);
  }, [currentData, reset, defaultValues]);

  const onSubmit = async (data: FormValuesProps) => {
    try {
      const onSubmitData = {
        name: data.name,
        active: data.active === 'Active' ? true : false,
      };

      !isEmpty(currentData)
        ? await updateCoverLevelData(onSubmitData, currentData?.id)
        : await postCoverLevelData(onSubmitData);
      enqueueSnackbar(
        !isEmpty(currentData)
          ? 'Cover type successfully updated!'
          : 'Cover type successfully created!'
      );
      navigate(settingsPath('coverLevel'));
    } catch (error) {
      console.error(error);

      enqueueSnackbar('Something went wrong', { variant: 'error' });
    }
  };

  // ----------------------------------------------------------------------
  // Skeleton State

  const { themeStretch } = useSettings();

  return (
    <Container
      maxWidth={themeStretch ? false : 'lg'}
      style={{ position: 'relative' }}
    >
      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <Grid item xs={12}>
          <Card sx={{ p: 3 }}>
            <Box
              sx={{
                display: 'grid',
                columnGap: 2,
                rowGap: 3,
                gridTemplateColumns: {
                  xs: 'repeat(1, 1fr)',
                  sm: 'repeat(1, 1fr)',
                },
              }}
            >
              {isLoading ? (
                <Box pl={2} pt={3}>
                  <Skeleton sx={{ width: 0.98 }} height={80} />
                  <Skeleton sx={{ width: 0.98 }} height={80} />
                </Box>
              ) : (
                <>
                  <RHFTextField
                    name="name"
                    label="Cover level type"
                    fullWidth
                  />
                  <RHFSelect name="active" label="Status" placeholder="Status">
                    {STATUS_OPTIONS.map((option) => (
                      <option key={option} value={option}>
                        {option}
                      </option>
                    ))}
                  </RHFSelect>
                </>
              )}
            </Box>

            <Stack alignItems="flex-end" sx={{ mt: 3 }}>
              {!isLoading && (
                <LoadingButton
                  type="submit"
                  variant="contained"
                  loading={isSubmitting}
                >
                  {isEmpty(currentData) ? 'Create Type' : 'Save Changes'}
                </LoadingButton>
              )}
            </Stack>
          </Card>
        </Grid>
      </FormProvider>
    </Container>
  );
}
