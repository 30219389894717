import { useParams } from 'react-router';
import { isEmpty } from 'lodash';
//
import { IconButton, Stack, Tooltip } from '@mui/material';
import { SummaryFieldText, SummaryFieldPrice, SummaryFieldLabel } from '.';
import { Iconify } from '@/components';
// redux
import { useSelector, dispatch } from '@/stores';
import { resetCoupon } from '@/features/bookings-management/slices';
// utils + hooks
import { useSnackbarMsg } from '@/hooks/useSnackbarMsg';
import { fCurrency } from '@/utils/formatNumber';
// api
import { removeCoupon } from '@/features/coupons/api';

export default function SummaryCouponDiscount() {
  const booking = useSelector((state) => state.booking);
  const { id } = useParams();
  const { errorMsg } = useSnackbarMsg();

  const handleRemoveCoupon = async () => {
    try {
      await removeCoupon(Number(id));
      dispatch(resetCoupon());
    } catch (error) {
      errorMsg(error, 'Error removing coupon');
    }
  };
  return (
    <Stack direction="column">
      <SummaryFieldLabel>Coupons and discounts</SummaryFieldLabel>

      {!isEmpty(booking.cleanDetails.couponObject) && (
        <Stack
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Stack direction="row" spacing={1} alignItems="center">
            <Tooltip title="Remove coupon">
              <IconButton onClick={() => handleRemoveCoupon()}>
                <Iconify icon={'eva:minus-circle-outline'} width={18} />
              </IconButton>
            </Tooltip>
            <SummaryFieldText>
              Coupon - {booking.cleanDetails.couponObject?.code}
            </SummaryFieldText>
          </Stack>
          <SummaryFieldPrice>
            - {fCurrency(booking.cleanDetails.coupon_discount)}
          </SummaryFieldPrice>
        </Stack>
      )}
    </Stack>
  );
}
