import { useParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
//
import {
  Box,
  Card,
  Divider,
  FormControlLabel,
  Switch,
  Tab,
  Table,
  TableBody,
  TableContainer,
  TablePagination,
  Tabs,
} from '@mui/material';
import { Scrollbar } from '@/components';
import { SkeletonLoadingTable } from '@/components/skeleton';
import {
  TableEmptyRows,
  TableHeadCustom,
  TableNoData,
} from '@/components/table';
import { ClientPriceDialog, ClientPricesTableRow } from '..';
// api
import { getUserCustomPrices } from '@/features/users/shared/api';
// hookes + utils
import { useGetAccess } from '@/hooks/useAccess';
import { useSnackbarMsg } from '@/hooks/useSnackbarMsg';
import useTable, { emptyRows, getComparator } from '@/hooks/useTable';
import {
  applySortFilterClientPrices,
  CLIENT_CUSTOM_PRICES_TABLE_HEAD,
} from '@/features/users/clients/utils';
import useTabs from '@/hooks/useTabs';
// types

type ClientPricesProps = {
  isCustomPriceDialogOpen: boolean;
  closeCustomPriceDialog: VoidFunction;
};
// TODO: ClientPrices and ProviderPrices almost identical, could be handled by a single component in the shared folder
export default function ClientPrices({
  isCustomPriceDialogOpen,
  closeCustomPriceDialog,
}: ClientPricesProps) {
  const { errorMsg } = useSnackbarMsg();
  const { userId } = useParams();
  const { canUpdate } = useGetAccess('users');
  const {
    dense,
    page,
    order,
    orderBy,
    rowsPerPage,
    //
    onSort,
    onChangeDense,
    onChangePage,
    onChangeRowsPerPage,
  } = useTable({ defaultOrderBy: 'end_date', defaultOrder: 'asc' });
  const CITY_PRICE_FILTER_OPTIONS = ['active', 'expired'];
  const { currentTab: filterStatus, onChangeTab: onChangeFilterStatus } =
    useTabs('active');

  // =================
  //      QUERIES
  // =================

  const { data: clientCustomPrices, isLoading: isPricesDataLoading } = useQuery(
    {
      queryFn: () => getUserCustomPrices(String(userId)),
      queryKey: ['clientCustomPrices'],
      onError: (error) => errorMsg(error, 'Could not fetch custom prices'),
      enabled: !!userId,
    }
  );

  //
  const dataFiltered = applySortFilterClientPrices({
    tableData: clientCustomPrices || [],
    comparator: getComparator(order, orderBy),
    filterStatus,
  });

  const denseHeight = dense ? 52 : 72;
  const isNotFound = !dataFiltered.length;
  const showSkeleton = isPricesDataLoading;

  return (
    <Card>
      {showSkeleton ? (
        <SkeletonLoadingTable />
      ) : (
        <>
          <Tabs
            allowScrollButtonsMobile
            variant="scrollable"
            scrollButtons="auto"
            value={filterStatus}
            onChange={onChangeFilterStatus}
            sx={{ px: 2 }}
          >
            {CITY_PRICE_FILTER_OPTIONS.map((tab) => (
              <Tab disableRipple key={tab} label={tab} value={tab} />
            ))}
          </Tabs>

          <Divider />
          <Scrollbar>
            <TableContainer sx={{ minWidth: 800, position: 'relative' }}>
              <Table size={dense ? 'small' : 'medium'}>
                <TableHeadCustom
                  order={order}
                  orderBy={orderBy}
                  headLabel={CLIENT_CUSTOM_PRICES_TABLE_HEAD}
                  onSort={onSort}
                />
                <TableBody>
                  {(dataFiltered || [])
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((price) => (
                      <ClientPricesTableRow
                        key={price.id}
                        row={price}
                        canUpdate={canUpdate}
                      />
                    ))}

                  <TableEmptyRows
                    height={denseHeight}
                    emptyRows={emptyRows(
                      page,
                      rowsPerPage,
                      (dataFiltered || []).length
                    )}
                  />

                  <TableNoData
                    isNotFound={isNotFound}
                    title="No custrom prices available"
                  />
                </TableBody>
              </Table>
            </TableContainer>
          </Scrollbar>

          <Box sx={{ position: 'relative' }}>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={(dataFiltered || []).length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={onChangePage}
              onRowsPerPageChange={onChangeRowsPerPage}
            />

            <FormControlLabel
              control={<Switch checked={dense} onChange={onChangeDense} />}
              label="Dense"
              sx={{ px: 3, py: 1.5, top: 0, position: { md: 'absolute' } }}
            />
          </Box>

          {/* This dialog is controlled by a button in HeaderBreadcrumbs */}
          <ClientPriceDialog
            isCustomPriceDialogOpen={isCustomPriceDialogOpen}
            closeCustomPriceDialog={closeCustomPriceDialog}
          />
        </>
      )}
    </Card>
  );
}
