import { useSelector } from '@/stores';
//
import { Stack } from '@mui/material';
import { SummaryFieldLabel, SummaryFieldText, SummaryFieldPrice } from '.';
//
import { fCurrency } from '@/utils/formatNumber';
//
import { CleaningExtra } from '@/features/services-management/types';

export default function SummaryCleaningExtras() {
  const booking = useSelector((state) => state.booking);
  return (
    <Stack direction="column" gap={2}>
      <SummaryFieldLabel>Service extras</SummaryFieldLabel>
      {booking.selectedServiceCleaningExtras.map(
        (extra: CleaningExtra, index: number) => {
          // id7 = Owns a pet
          if (extra.id !== 7) {
            return (
              <Stack direction="row" justifyContent="space-between" key={index}>
                <SummaryFieldText>{extra.name}</SummaryFieldText>
                <SummaryFieldPrice>
                  {fCurrency(extra.hours! * booking.cleanDetails.baseRate)}
                </SummaryFieldPrice>
              </Stack>
            );
          } else {
            return null;
          }
        }
      )}
    </Stack>
  );
}
