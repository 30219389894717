import { object, string, number } from 'yup';
import { useSnackbar } from 'notistack';
import { useNavigate, useParams } from 'react-router-dom';

import { Card, CardContent, Container, Skeleton } from '@mui/material';
import { LoadingButton } from '@mui/lab';

import Page from '@/components/Page';
import HeaderBreadcrumbs from '@/components/HeaderBreadcrumbs';

import { ROOTS_SETTINGS, settingsPath } from '../routes/paths';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormProvider, RHFSelect, RHFTextField } from '@/components/hook-form';
import RHFEditor from '../components/editor/RHFEditor';
import { Stack } from '@mui/system';
import { useEffect, useState } from 'react';
import { EmailTemplate, SettingsCategory } from '../types';
import {
  getEmailTemplate,
  getSettingCategories,
  postEmailTemplate,
  putEmailTemplate,
} from '../api';

export default function EmailTemplateForm() {
  const navigate = useNavigate();
  const { id } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const [categories, setCategories] = useState<SettingsCategory[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const EmailTemplate = object().shape({
    subject: string().required('Email is required'),
    description: string(),
    category_id: number()
      .required('Category is required')
      .typeError('Please select a categroy'),
    active: number(),
    body: string().required('Password is required'),
  });

  const defaultValues = {
    subject: '',
    description: '',
    category_id: '',
    active: 1,
    body: '',
  };

  const methods = useForm<any>({
    resolver: yupResolver(EmailTemplate),
    defaultValues,
  });

  const {
    reset,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const onSubmit = async (data: any) => {
    try {
      await (id
        ? putEmailTemplate(
            Object.assign(data, { active: !!data.active }),
            Number(id)
          )
        : postEmailTemplate(Object.assign(data, { active: !!data.active })));
      enqueueSnackbar('Email Template Created');
      navigate(settingsPath('templates'));
    } catch (error) {
      enqueueSnackbar(error.message, { variant: 'error' });
    }
  };

  useEffect(() => {
    async function callGetSettingCategories() {
      const data: SettingsCategory[] = await getSettingCategories();
      setCategories(data);
      setIsLoading(false);
    }
    callGetSettingCategories();
  }, []);

  useEffect(() => {
    async function getEmailTemplateData(id: number) {
      setIsLoading(true);
      try {
        const {
          subject,
          email_template_category,
          active,
          description,
          body,
        }: EmailTemplate = await getEmailTemplate(id);
        reset({
          subject,
          body,
          description,
          category_id: email_template_category.id,
          active: active ? 1 : 0,
        });
        setIsLoading(false);
      } catch (error) {
        enqueueSnackbar(error.message, { variant: 'error' });
      }
    }
    !id && setIsLoading(false);
    id && getEmailTemplateData(Number(id));
  }, [id, enqueueSnackbar, reset]);

  return (
    <Page title="Settings: New Email Template">
      <Container>
        <HeaderBreadcrumbs
          heading={'Add New Email Templates'}
          links={[
            { name: 'Settings', href: ROOTS_SETTINGS },
            { name: 'Email Templates', href: settingsPath('templates') },
            { name: 'New' },
          ]}
        />
        <Card>
          <CardContent>
            {isLoading ? (
              <Stack spacing={2}>
                <Skeleton height={64} />
                <Skeleton height={64} />
                <Stack spacing={2} direction="row">
                  <Skeleton width="50%" height={64} />
                  <Skeleton width="50%" height={64} />
                </Stack>
                <Skeleton width="50%" height={64} />
              </Stack>
            ) : (
              <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
                <Stack spacing={3}>
                  <RHFTextField name="subject" label="Subject" />
                  <RHFTextField name="description" label="Description" />
                </Stack>
                <Stack spacing={3} direction="row" sx={{ pt: 3 }}>
                  <RHFSelect
                    name="category_id"
                    label="Category"
                    placeholder="Category"
                  >
                    <option value={''} />
                    {categories.map((category) => (
                      <option key={category.id} value={category.id}>
                        {category.name}
                      </option>
                    ))}
                  </RHFSelect>
                  <RHFSelect name="active" label="Status" placeholder="Status">
                    <option value={1}>Active</option>
                    <option value={0}>Disabled</option>
                  </RHFSelect>
                </Stack>
                <Stack spacing={3} sx={{ pt: 3 }}>
                  <RHFEditor simple name="body" />
                </Stack>
                <Stack alignItems="flex-end" sx={{ mt: 3 }}>
                  <LoadingButton
                    type="submit"
                    variant="contained"
                    loading={isSubmitting}
                  >
                    Save Changes
                  </LoadingButton>
                </Stack>
              </FormProvider>
            )}
          </CardContent>
        </Card>
      </Container>
    </Page>
  );
}
