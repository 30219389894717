import axios from '@/utils/axios';
import {
  ResetPasswordResponse,
  RestPasswordResponseData,
  VerifyCodeResponse,
  VerifyCodeResponseData,
  SetNewPasswordResponse,
  SetNewPasswordResponseData,
} from '../types';

export async function resetPassword(
  email: string
): Promise<ResetPasswordResponse> {
  const {
    data: { debug_code, message },
  }: RestPasswordResponseData = await axios.post('auth/reset-password', {
    email,
  });

  return { debug_code, message };
}

export async function verifyCode(code: string): Promise<VerifyCodeResponse> {
  const email = sessionStorage.getItem('email-recovery');
  const {
    data: { message, valid },
  }: VerifyCodeResponseData = await axios.post('auth/reset-password/validate', {
    email,
    code,
  });
  return { message, valid };
}

export async function setNewPassword(
  new_password: string,
  confirm_password: string
): Promise<SetNewPasswordResponse> {
  const email = sessionStorage.getItem('email-recovery');
  const code = sessionStorage.getItem('code-recovery');
  const {
    data: { message },
  }: SetNewPasswordResponseData = await axios.post('auth/reset-password/save', {
    email,
    code,
    new_password,
    confirm_password,
  });

  return { message };
}
