import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { isEmpty } from 'lodash';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
//
import { Stack, Alert } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { FormProvider, RHFTextField } from '@/components/hook-form';
// api
import { resetPassword } from '../api';
// hooks + utils
import useIsMountedRef from '@/hooks/useIsMountedRef';
import { useSnackbarMsg } from '@/hooks/useSnackbarMsg';
import { authPath } from '../routes/paths';
import { RESET_PASSWORD_SCHEMA } from '../utils';

type ResetPasswordFormProps = {
  email: string;
  afterSubmit?: string;
};

export default function ResetPasswordForm() {
  const navigate = useNavigate();
  const isMountedRef = useIsMountedRef();
  const { errorMsg } = useSnackbarMsg();
  const [message, setMessage] = useState<string>('');

  const methods = useForm<ResetPasswordFormProps>({
    resolver: yupResolver(RESET_PASSWORD_SCHEMA),
    defaultValues: { email: '' },
  });

  const {
    reset: resetFormValues,
    setError,
    handleSubmit,
    formState: { isSubmitting, errors },
  } = methods;

  const onSubmit = async ({ email }: ResetPasswordFormProps) => {
    try {
      const response = await resetPassword(email);

      if (response.hasOwnProperty('debug_code')) {
        sessionStorage.setItem('email-recovery', email);
        navigate(authPath('verify'));
      } else {
        setMessage(response.message);
        sessionStorage.clear();
      }
    } catch (error) {
      errorMsg(error, 'There was an error while resetting password');

      resetFormValues();
      if (isMountedRef.current) {
        setError('afterSubmit', { ...error, message: error.message });
      }
    }
  };

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={3}>
        {!!errors.afterSubmit && (
          <Alert severity="error">{errors.afterSubmit.message}</Alert>
        )}
        {!isEmpty(message) && (
          <Alert sx={{ textAlign: 'left' }} severity="error">
            {message}
          </Alert>
        )}
        <RHFTextField name="email" label="Email address" />

        <LoadingButton
          fullWidth
          size="large"
          type="submit"
          variant="contained"
          loading={isSubmitting}
        >
          Send Request
        </LoadingButton>
      </Stack>
    </FormProvider>
  );
}
