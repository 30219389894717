import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { isEmpty, find } from 'lodash';
//
import { RTKNoteType } from '../components/bookings/create-edit/main-section/notes-partials/RTKNotes';
// utils
import {
  retrieveExtrasFromId,
  returnRecurringDayIds,
  transformExtraIds,
  transformPets,
} from '../utils';
// types
import { CleaningExtra } from '@/features/services-management/types';
import { InitialBookingState, SetValidationErrorActionPayload } from '../types';
import { ServiceVariant, Settings } from '@/types';
import { UserAddress } from '@/features/users/shared/types';

const initialBookingState: InitialBookingState = {
  validationErrors: {
    client: null,
    address: null,
    booking_status_id: null,
    date: null,
    time: null,
    selectedServiceId: null,
    selectedServiceVariant: null,
    access_type: null,
    pets: null,
    property_type_id: null,
  },
  bookingLoading: false,
  // TOP SECTION
  // userId: 0,
  all_variants_from_api: [],
  all_extras_from_api: [],
  client: undefined,
  booking_id: 'B-undefined',
  created_at: new Date(),
  booking_status_id: '',
  date: null,
  time: '',
  // MAIN SECTION
  cleanDetails: {
    numberOfBathrooms: 1,
    numberOfBedrooms: 1,
    numberOfDesks: 0,
    //
    hoursIncrementValue: 0.5,
    minHours: 3,
    maxHours: 6,
    //
    hours: 0,
    extraServiceVariantHours: 0,
    extraServiceExtrasHours: 0,
    totalHours: 0,
    pets_enabled: false,
    pets: [],
    extra: {},
    requestForCleaningProduct: false,
    cleaningProductsPrice: 10,
    //
    baseRate: 0,
    // coupons, discounts
    coupon_discount: 0,
    couponObject: null,
    //
    access_type: '',
    access_instructions_enabled: false,
    notes: {
      provider_instructions: '', // cleaning instructions
      access_instructions: '',
      admin_notes: '',
      reason_for_change: '',
      internal_notes: '',
    },
    linen: false,
    essential_amenities: false,
    property_type_id: null,
    property_type: null,
  },
  address: {},
  clientHasCustomPrice: false,
  subTotal: 0,
  totalAmount: 0,
  hoursSum: 0,
  selectedServiceId: null,
  service_variant_id: null,
  service_variant: null,
  selectedServiceVariants: [],
  selectedServiceCleaningExtras: [],
  selectedServiceVariant: {},
  booking_items: [],
  validateSession: false,
  newAddress: {},
  booking_cleaners: [],
  custom_user_prices: null,
  custom_city_price: null,
  next_cleaning_sessions: [],
  booking_recurrence: null,
  recurring_days: [], //id of the days
  isInitiallyRecurring: false, // helper state to check if the booking is recurring on load
};

export const bookingSlice = createSlice({
  name: 'booking',
  initialState: initialBookingState,
  reducers: {
    setLoading(state, { payload }: { payload: boolean }) {
      state.bookingLoading = payload;
    },
    // ERRORS
    setValidationError: (
      state,
      action: PayloadAction<SetValidationErrorActionPayload>
    ) => {
      const { field, error } = action.payload;
      if (state.validationErrors && field in state.validationErrors) {
        state.validationErrors[field] = error;
      }
    },

    resetValidatonErrors(state) {
      state.validationErrors = {
        client: null,
        address: null,
        booking_status_id: null,
        date: null,
        time: null,
        selectedServiceId: null,
        selectedServiceVariant: null,
        access_type: null,
        pets: null,
        property_type_id: null,
      };
    },

    // RESET VALUES
    resetReduxValues() {
      return initialBookingState;
    },

    // CALCULATE TOTAL HOURS
    calculateTotalVariantHours(state) {
      // set extra hours based on the number of bathrooms and bedrooms * increment value (0.5)
      state.cleanDetails.extraServiceVariantHours =
        (state.cleanDetails.numberOfBathrooms - 1) *
          state.cleanDetails.hoursIncrementValue +
        (state.cleanDetails.numberOfBedrooms - 1) *
          state.cleanDetails.hoursIncrementValue;

      // calculate total variant hours that needs to be capped in the case of one-off cleaning
      let totalVariantHours =
        state.cleanDetails.minHours +
        state.cleanDetails.extraServiceVariantHours;
      if (totalVariantHours > state.cleanDetails.maxHours) {
        totalVariantHours = state.cleanDetails.maxHours;
      } else if (totalVariantHours < state.cleanDetails.minHours) {
        totalVariantHours = state.cleanDetails.minHours;
      }
      //
      state.cleanDetails.hours = totalVariantHours;
      bookingSlice.caseReducers.calculateTotalHours(state);
    },

    calculateTotalHours(state) {
      state.cleanDetails.totalHours =
        state.cleanDetails.hours + state.cleanDetails.extraServiceExtrasHours;
      bookingSlice.caseReducers.calculateTotalPrice(state);
    },

    calculateTotalPrice(state) {
      const { totalHours } = state.cleanDetails;
      const { baseRate } = state.cleanDetails;
      const cleaningProduct = state.cleanDetails.requestForCleaningProduct
        ? 10
        : 0;
      state.subTotal = totalHours * baseRate;

      let finalPrice = state.subTotal;
      const { couponObject } = state.cleanDetails;
      if (couponObject) {
        if (couponObject.value_type === 'PERCENTAGE') {
          state.cleanDetails.coupon_discount =
            (state.subTotal * couponObject.value) / 100;
          finalPrice =
            state.subTotal - (state.subTotal * couponObject.value) / 100;
        }
        if (couponObject.value_type === 'FIXED') {
          state.cleanDetails.coupon_discount = couponObject.value;
          finalPrice = state.subTotal - couponObject.value;
        }
      }

      finalPrice += cleaningProduct;
      state.totalAmount = finalPrice;
    },

    setBaseRate(state) {
      if (!state.selectedServiceVariant) return;

      // 1. NEW BOOKING
      // client custom price overrides all prices
      if (state.clientHasCustomPrice) {
        const customPrice = (state.client?.user_custom_prices || []).find(
          (price) =>
            price.service_variant_id ===
            (state.selectedServiceVariant as ServiceVariant).id
        );
        if (customPrice) {
          state.cleanDetails.baseRate = customPrice.price;
          bookingSlice.caseReducers.calculateTotalPrice(state);
          return;
        }
      }

      // if there's no custom price for client, use city price
      if (
        !isEmpty(state.selectedServiceVariant) &&
        (state.selectedServiceVariant as ServiceVariant).custom_prices.length >
          0 &&
        !isEmpty(state.address)
      ) {
        const customPrice = (
          state.selectedServiceVariant as ServiceVariant
        ).custom_prices.find(
          (price) => price.city_id === (state.address as UserAddress).city_id
        );
        if (customPrice) {
          state.cleanDetails.baseRate = customPrice.price;
          bookingSlice.caseReducers.calculateTotalPrice(state);
          return;
        }
      }

      // in case of existing booking, check for custom_user_price
      if (state.custom_user_prices && state.custom_user_prices.length > 0) {
        const customPrice = state.custom_user_prices.find(
          (price) =>
            price.service_variant_id ===
            (state.selectedServiceVariant as ServiceVariant).id
        );
        if (customPrice) {
          state.cleanDetails.baseRate = customPrice.price;
          bookingSlice.caseReducers.calculateTotalPrice(state);
          return;
        }
      } else {
        // use default price
        state.cleanDetails.baseRate = (
          state.selectedServiceVariant as ServiceVariant
        ).price;
        bookingSlice.caseReducers.calculateTotalPrice(state);
      }
    },
    // -----------------------------
    //           SETTINGS
    // -----------------------------

    updateServiceVariantsAndExtras(state, { payload }: { payload: Settings }) {
      state.all_variants_from_api = payload.serviceVariants.filter(
        (variant) => !variant.ref.includes('windows')
      );
      state.all_extras_from_api = payload.serviceExtras;
    },

    // -----------------------------
    //          TOP SECTION
    // -----------------------------

    // CLIENT
    setClient(state, { payload }) {
      state.client = payload;
      state.clientHasCustomPrice =
        payload.user_custom_prices && payload.user_custom_prices.length > 0;
      state.address = {};
      bookingSlice.caseReducers.setBaseRate(state);
    },
    // ADDRESS
    setAddress(state, { payload }: { payload: UserAddress }) {
      state.address = payload;
      bookingSlice.caseReducers.setBaseRate(state);
    },

    addNewAddress(state, { payload }) {
      !isEmpty(state.client) && state.client.address.push(payload);
      state.address = payload;
      bookingSlice.caseReducers.setBaseRate(state);
    },

    // CLEANERS
    updateAssignedCleaners(state, { payload }) {
      state.booking_cleaners.push(payload);
    },

    removeCleaner(state) {
      // later it will be multiple cleaner selection
      state.booking_cleaners = [];
    },
    // BOOKING STATUS
    selectBookingStatus(state, { payload }) {
      state.booking_status_id = payload;
    },
    // BOOKING DATE
    addBookingDate(state, { payload }) {
      state.date = new Date(payload);
    },
    // BOOKING TIME
    addBookingTime(state, { payload }) {
      state.time = payload;
    },

    // -----------------------------
    //          MAIN SECTION
    // -----------------------------

    resetValuesOnVariantChange(state) {
      state.cleanDetails.hours = 1;
      state.cleanDetails.hoursIncrementValue = 0.5;
      state.cleanDetails.minHours = 2;
      state.cleanDetails.maxHours = 6;
      state.cleanDetails.numberOfBathrooms = 1;
      state.cleanDetails.numberOfBedrooms = 1;
      state.cleanDetails.extraServiceVariantHours = 0;
      state.cleanDetails.extraServiceExtrasHours = 0;
      state.selectedServiceCleaningExtras = [];
      state.recurring_days = [];
    },

    // SERVICE DETAILS
    selectService(state, { payload }) {
      state.selectedServiceId = payload;
      state.selectedServiceVariant = {};
      bookingSlice.caseReducers.resetValuesOnVariantChange(state);
    },

    selectVariant(state, { payload }: { payload: ServiceVariant }) {
      bookingSlice.caseReducers.resetValuesOnVariantChange(state);
      state.selectedServiceVariant = payload;

      if (payload.id !== 5) {
        // 5: offfices
        const hoursFormField = payload.service_form_fields.find(
          (variant) => variant.ref === 'hours'
        );
        const bathroomFormField = payload.service_form_fields.find(
          (variant) => variant.ref === 'bathrooms'
        );
        const bedroomFormField = payload.service_form_fields.find(
          (variant) => variant.ref === 'bedrooms'
        );
        // reset values on change
        if (hoursFormField) {
          state.cleanDetails.hoursIncrementValue =
            hoursFormField.increment_value;
          state.cleanDetails.hours = hoursFormField.min_value;
          state.cleanDetails.minHours = hoursFormField.min_value;
          state.cleanDetails.maxHours = hoursFormField.max_value;
        }

        state.cleanDetails.extraServiceVariantHours = 0;

        if (bathroomFormField) {
          state.cleanDetails.numberOfBathrooms = bathroomFormField.min_value;
        }

        if (bedroomFormField) {
          state.cleanDetails.numberOfBedrooms = bedroomFormField.min_value;
        }

        state.selectedServiceCleaningExtras = [];
      }
      bookingSlice.caseReducers.setBaseRate(state);
      bookingSlice.caseReducers.calculateTotalHours(state);
      bookingSlice.caseReducers.calculateTotalPrice(state);
    },
    setVariantHours(state, { payload }) {
      state.cleanDetails.hours = payload;
      bookingSlice.caseReducers.calculateTotalHours(state);
    },
    setNumberOfBathrooms(state, { payload }) {
      state.cleanDetails.numberOfBathrooms = payload ?? '';
      bookingSlice.caseReducers.calculateTotalVariantHours(state);
    },
    setNumberOfBedrooms(state, { payload }) {
      state.cleanDetails.numberOfBedrooms = payload ?? '';
      bookingSlice.caseReducers.calculateTotalVariantHours(state);
    },
    setNumberOfDesks(state, { payload }) {
      state.cleanDetails.numberOfDesks = payload;
    },

    // SERVICE EXTRAS
    addServiceExtra(state, { payload: { cleaning_extra } }) {
      state.selectedServiceCleaningExtras.push(cleaning_extra);
      state.cleanDetails.extraServiceExtrasHours += cleaning_extra.hours;
      bookingSlice.caseReducers.calculateTotalHours(state);
    },
    removeServiceExtra(state, { payload: { cleaning_extra } }) {
      const filteredArray = state.selectedServiceCleaningExtras.filter(
        (extra: CleaningExtra) => extra.id !== cleaning_extra.id
      );
      state.cleanDetails.extraServiceExtrasHours -= cleaning_extra.hours;
      state.selectedServiceCleaningExtras = filteredArray;
      bookingSlice.caseReducers.calculateTotalHours(state);
    },

    // SET RECURRING DAYS
    setRecurringDays(state, { payload }) {
      state.recurring_days = payload;
    },

    // CLEANING PRODUCTS
    toggleCleaningProduct(state, { payload }: { payload: boolean }) {
      state.cleanDetails.requestForCleaningProduct = payload;
      bookingSlice.caseReducers.calculateTotalPrice(state);
    },

    // NOTES
    updateNotes(
      state,
      { payload }: { payload: { noteType: RTKNoteType; value: string } }
    ) {
      state.cleanDetails.notes[payload.noteType] = payload.value;
    },
    toggleAccessInstructionsSwitch(state, { payload }) {
      state.cleanDetails.access_instructions_enabled = payload;
    },
    setAccessType(state, { payload }) {
      state.cleanDetails.access_type = payload;
    },

    // PETS
    togglePetsSwitch(state, { payload }) {
      state.cleanDetails.pets_enabled = payload;
    },
    setPets(state, { payload }) {
      state.cleanDetails.pets = payload;
    },

    // COUPONS AND DISCOUNTS
    setCouponObject(state, { payload }) {
      state.cleanDetails.couponObject = payload;
      bookingSlice.caseReducers.calculateTotalPrice(state);
    },
    resetCoupon(state) {
      state.cleanDetails.coupon_discount = 0;
      state.cleanDetails.couponObject = null;
      bookingSlice.caseReducers.calculateTotalPrice(state);
    },

    // LINEN, ESSENTIAL AMENITIES
    setLinen(state, { payload }) {
      state.cleanDetails.linen = payload;
    },
    setEssentialAmenities(state, { payload }) {
      state.cleanDetails.essential_amenities = payload;
    },

    // PROPERTY TYPE
    setPropertyType(state, { payload }) {
      state.cleanDetails.property_type_id = Number(payload);
    },

    // ------------------------------
    //      CURRENT BOOKING
    // ------------------------------

    updateStoreWithCurrentBooking(state, { payload }) {
      // reset redux values first
      bookingSlice.caseReducers.setLoading(state, { payload: true });
      // bookingSlice.caseReducers.resetReduxValues();
      //
      state.client = payload.user;
      state.address = payload.address;
      state.booking_cleaners = payload.booking_cleaners;
      //
      state.booking_status_id = payload.booking_status_id;
      state.booking_id = payload.id;
      state.created_at = payload.created_at;
      state.time = payload.time;
      state.date =
        typeof payload.date === 'string'
          ? new Date(Date.parse(payload.date))
          : payload.date;
      //
      state.cleanDetails.hours = payload.hours;
      state.cleanDetails.numberOfBathrooms = payload.bathrooms;
      state.cleanDetails.numberOfBedrooms = payload.bedrooms;
      state.cleanDetails.numberOfDesks =
        payload.desks === null ? 0 : payload.desks;
      // service + variants
      state.selectedServiceId = payload.service_variant.service.id;
      const selectedServiceVariant = find(state.all_variants_from_api, {
        id: payload.service_variant.id,
      });
      state.service_variant = payload.service_variant;
      // TODO: the `all_variants_from_api` from abouce can be empty at the time of the call
      // it is because the store is updated with a separate call
      // -- that's why this check needed. This could be improved in the future
      if (selectedServiceVariant) {
        const hoursFormField =
          selectedServiceVariant.service_form_fields.filter(
            (field) => field.ref === 'hours'
          );

        state.selectedServiceVariant = selectedServiceVariant;
        state.cleanDetails.hoursIncrementValue =
          hoursFormField[0].increment_value;
        state.cleanDetails.minHours = hoursFormField[0].min_value;
        state.cleanDetails.maxHours = hoursFormField[0].max_value;
      }
      // extras
      const transformedExtraIds = transformExtraIds(payload.extras);
      state.selectedServiceCleaningExtras = retrieveExtrasFromId(
        transformedExtraIds,
        state.all_extras_from_api
      );
      state.booking_items = payload.booking_items;
      // notes
      state.cleanDetails.notes.provider_instructions =
        payload.provider_instructions;
      state.cleanDetails.notes.admin_notes = payload.admin_notes;
      state.cleanDetails.access_instructions_enabled =
        !!payload.access_instructions;
      state.cleanDetails.notes.access_instructions =
        payload.access_instructions;
      state.cleanDetails.access_type = payload.access_type;
      // pets
      state.cleanDetails.pets_enabled = !!payload.pets;
      state.cleanDetails.pets = transformPets(payload.pets);
      // cleaning prodcucts
      state.cleanDetails.requestForCleaningProduct = payload.cleaning_products;
      // coupons
      state.cleanDetails.couponObject = payload.coupon;
      // linen, essential amenities
      state.cleanDetails.linen = payload.linen;
      state.cleanDetails.essential_amenities = payload.essential_amenities;
      // property type
      state.cleanDetails.property_type = payload.property_type;
      state.cleanDetails.property_type_id = payload.property_type
        ? payload.property_type.id
        : null;
      // custom_prices
      state.custom_user_prices = payload.user_custom_prices;
      // next cleaning sessions
      state.next_cleaning_sessions = payload.next_cleaning_sessions;
      // booking_recurrence
      state.booking_recurrence = payload.booking_recurrence;
      state.recurring_days =
        payload.booking_recurrence.interval_number > 0
          ? returnRecurringDayIds(payload.booking_recurrence.week_days)
          : [];
      state.isInitiallyRecurring = !isEmpty(payload.booking_recurrence);
      bookingSlice.caseReducers.setBaseRate(state);
      bookingSlice.caseReducers.calculateTotalHours(state);
      bookingSlice.caseReducers.setLoading(state, { payload: false });
    },
  },
});

export const {
  // SETTINGS
  updateServiceVariantsAndExtras,
  resetReduxValues,
  setValidationError,
  setLoading,
  // TOP
  setClient,
  setAddress,
  addNewAddress,
  updateAssignedCleaners,
  removeCleaner,
  selectBookingStatus,
  addBookingDate,
  addBookingTime,
  // MAIN
  //  - service + variant
  selectService,
  selectVariant,
  setNumberOfBathrooms,
  setNumberOfBedrooms,
  setNumberOfDesks,
  setVariantHours,
  //  - extras
  addServiceExtra,
  removeServiceExtra,
  // - recurring days
  setRecurringDays,
  //  - cleaning product
  toggleCleaningProduct,
  //  - coupon
  setCouponObject,
  resetCoupon,
  //  - notes
  toggleAccessInstructionsSwitch,
  setAccessType,
  updateNotes,
  // - pets
  togglePetsSwitch,
  setPets,
  // - linen, essential amenities
  setLinen,
  setEssentialAmenities,
  // - property type
  setPropertyType,
  //  - current booking update
  updateStoreWithCurrentBooking,
} = bookingSlice.actions;
export default bookingSlice.reducer;

export const selectedVariant = (state: any) =>
  state.booking.selectedServiceVariant;
