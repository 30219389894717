export const formatAddress = (
  formatted_address: string,
  postcode: string | undefined
) => {
  const errorMessage = 'Error, no address found';
  // TODO: consider throwing an error here instead of returning an object
  if (!formatted_address)
    return {
      formattedAddress: errorMessage,
      formattedAddressWithPostcode: errorMessage,
      googleMapsAddress: errorMessage,
    };

  const formattedAddress = formatted_address
    .replaceAll('\\n', ' \n ')
    .replaceAll('<br/>', ' \n ');
  const formattedAddressWithPostcode = formattedAddress.concat(
    `, ${postcode ?? 'N/A'}`
  );
  const googleMapsAddress = formattedAddress.replace(/\n/g, '+');

  return { formattedAddress, formattedAddressWithPostcode, googleMapsAddress };
};
