import { useSelector } from '@/stores';
//
import { Stack } from '@mui/material';
import { SummaryFieldLabel, SummaryFieldPrice } from '.';
//
import { fCurrency } from '@/utils/formatNumber';

export default function SummaryCleaningProducts() {
  const booking = useSelector((state) => state.booking);
  return (
    <>
      {booking.cleanDetails.requestForCleaningProduct && (
        <Stack flexDirection="row" justifyContent="space-between">
          <SummaryFieldLabel>Cleaning Products</SummaryFieldLabel>
          <SummaryFieldPrice>
            {fCurrency(booking.cleanDetails.cleaningProductsPrice)}
          </SummaryFieldPrice>
        </Stack>
      )}
    </>
  );
}
