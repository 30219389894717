import { Theme } from '@mui/material';

export const timePickerSx = (theme: Theme, error?: boolean) => ({
  width: '100%',
  '& .mbsc-textfield-wrapper': {
    border: `1px solid ${
      error ? theme.palette.error.main : theme.palette.divider
    }`,
    borderRadius: 1,
    backgroundColor: theme.palette.background.paper,
    height: 40,
    color: 'text.disabled',
  },
  '& .mbsc-ios.mbsc-form-control-wrapper.mbsc-ltr:before ': {
    border: 'none',
  },
  '& .mbsc-ios.mbsc-form-control-wrapper.mbsc-ltr:after ': {
    border: 'none',
  },
  '& .mbsc-popup-buttonx': {
    color: theme.palette.primary.main,
  },
  '& .mbsc-label': {
    color: error ? theme.palette.error.main : theme.palette.text.disabled,
    font: 'inherit',
    letterSpacing: '0.5px',
    display: 'flex',
    alignItems: 'center',
    ml: -0.4,
    width: 'fit-content',
  },
  '& .mbsc-textfield-inner': {
    width: 120,
    // pr: 1,
    // pl: 1,
  },
  '& .mbsc-ios.mbsc-textfield-icon': {
    top: 6,
    right: 6,
    fontSize: 20,
    color: 'text.secondary',
  },
});
