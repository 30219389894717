import { path } from '@/utils/formatLink';
import { generatePath } from 'react-router';
import { BaseObjectEntity } from '@/types';

export const ROOT_INVOICES = '/invoices';

export const PATH_INVOICES: BaseObjectEntity = {
  root: ROOT_INVOICES,
  list: '/run',
  providerRunInvoicesList: '/run/provider/:runId',
  providerInvoiceDetails: '/provider/view/:invoiceId',
};

export function invoicesPath(pathKey: string, params?: BaseObjectEntity) {
  return generatePath(path(ROOT_INVOICES, PATH_INVOICES[pathKey]), params);
}
