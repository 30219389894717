import { useParams } from 'react-router';
import { useSnackbarMsg } from '@/hooks/useSnackbarMsg';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { updateBookingSession } from '../api';
import { BookingSession } from '../types';

type PostSessionHookParams = {
  onSuccessFn?: () => void;
  successText?: string;
};

export default function usePostBookingSessionUpdate({
  onSuccessFn,
  successText,
}: PostSessionHookParams) {
  const { errorMsg, successMsg } = useSnackbarMsg();
  const queryClient = useQueryClient();
  const { sessionFilter } = useParams();

  const sessionUpdateMutation = useMutation({
    mutationFn: ({
      bookingSessionId,
      bookingSessionDto,
    }: {
      bookingSessionId: number;
      bookingSessionDto: Partial<BookingSession>;
    }) => updateBookingSession(bookingSessionId, bookingSessionDto),
    onSuccess: (_data, variables) => {
      successMsg(successText ?? `Session updated`);
      queryClient.invalidateQueries([
        'bookingSessions',
        sessionFilter,
        String(variables.bookingSessionId) || '',
      ]);
      // SessionTableRow - we need to invalidate the list query
      queryClient.invalidateQueries(['bookingSessions']);
      onSuccessFn && onSuccessFn();
    },
    onError: (error) => {
      errorMsg(error, `Something went wrong while updating session`);
    },
  });

  return { sessionUpdateMutation, errorMsg, successMsg };
}
