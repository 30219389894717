import { User } from '@/features/users/shared/types';

export const retrieveCleaner = (
  cleanerId: number | undefined,
  allCleaners: User[] | undefined,
  returnCleanerObject?: boolean
) => {
  if (!cleanerId) return 'No cleaner assigned';
  if (!allCleaners) return `Counldn't get cleaners list`;
  const cleaner = allCleaners?.find((c) => c.id === cleanerId);

  return returnCleanerObject
    ? cleaner
    : `${cleaner?.first_name} ${cleaner?.last_name}` ??
        `Cannot find cleaner with ${cleanerId}`;
  // return (
  //   `${cleaner?.first_name} ${cleaner?.last_name}` ??
  //   `Cannot find cleaner with ${cleanerId}`
  // );
};
