// mui
import {
  Button,
  Dialog,
  DialogActions,
  DialogProps,
  DialogTitle,
  IconButton,
  SxProps,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import CloseIcon from '@mui/icons-material/Close';
// types
import {
  ButtonColors,
  ButtonTypes,
  ButtonVariants,
  MaxWidthOptions,
} from '@/types';

// TODO: REVAMP THIS COMPONENT, THIS IS BLOATED AND SMELLS BAD

// ==============================
//      STYLED DIALOG TITLE
// ==============================

export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose?: VoidFunction;
  sx: SxProps;
}

function StyledDialogTitle(props: DialogTitleProps) {
  const { sx, children, onClose, ...other } = props;

  return (
    <DialogTitle sx={sx} {...other}>
      {children}
      <IconButton
        aria-label="close"
        onClick={onClose}
        sx={{
          position: 'absolute',
          right: 16,
          top: 16,
          color: 'grey.500',
        }}
      >
        <CloseIcon />
      </IconButton>
    </DialogTitle>
  );
}

// =========================
//      DIALOG SIMPLE
// =========================

export interface Props extends DialogProps {
  dialogTitle: string;
  open: boolean;
  //
  fullWidth?: boolean;
  maxWidth?: MaxWidthOptions;
  //
  onClickButtonAgree?: any;
  onClickButtonClose?: VoidFunction;
  onClickButtonExtra?: VoidFunction;
  onClickButtonLoading?: VoidFunction;
  dialogCloseOutside?: VoidFunction;
  disableEscapeKeyDown?: boolean;
  //
  isSubmitting?: boolean;
  disableLoadingButton?: boolean;
  sxButtons?: SxProps;
  //
  sxAgreeButton?: SxProps;
  sxDisagreeButton?: SxProps;
  sxExtraButton?: SxProps;
  sxLoadingButton?: SxProps;
  //
  agreeButton?: boolean;
  disagreeButton?: boolean;
  extraButton?: boolean;
  loadingButton?: boolean;
  //
  agreeButtonColor?: ButtonColors;
  disagreeButtonColor?: ButtonColors;
  extraButtonColor?: ButtonColors;
  loadingButtonColor?: ButtonColors;
  //
  agreeButtonText?: string;
  disagreeButtonText?: string;
  extraButtonText?: string;
  loadingButtonText?: string;
  //
  agreeButtonVariant?: ButtonVariants;
  disagreeButtonVariant?: ButtonVariants;
  extraButtonVariant?: ButtonVariants;
  loadingButtonVariant?: ButtonVariants;
  //
  agreeButtonType?: ButtonTypes;
  disagreeButtonType?: ButtonTypes;
  extraButtonType?: ButtonTypes;
  loadingButtonType?: ButtonTypes;
}

export default function DialogSimple({
  dialogTitle,
  open = false,
  children,
  //
  fullWidth = true,
  maxWidth = 'sm',
  //
  onClickButtonAgree,
  onClickButtonClose,
  onClickButtonExtra,
  onClickButtonLoading,
  dialogCloseOutside,
  disableEscapeKeyDown = false,
  //
  isSubmitting = false,
  disableLoadingButton = false,
  sxButtons,
  //
  sxAgreeButton,
  sxDisagreeButton,
  sxExtraButton,
  sxLoadingButton,
  //
  agreeButton = false,
  disagreeButton = true,
  extraButton = false,
  loadingButton = true,
  //
  agreeButtonText = 'Add',
  disagreeButtonText = 'Cancel',
  extraButtonText = 'Button Extra',
  loadingButtonText = 'Loading Button',
  //
  agreeButtonColor = 'primary',
  disagreeButtonColor = 'inherit',
  extraButtonColor = 'primary',
  loadingButtonColor = 'primary',
  //
  agreeButtonVariant = 'contained',
  disagreeButtonVariant = 'outlined',
  extraButtonVariant = 'contained',
  loadingButtonVariant = 'contained',
  //
  agreeButtonType = 'button',
  disagreeButtonType = 'button',
  extraButtonType = 'button',
  loadingButtonType = 'submit',
}: Props) {
  const renderActionButtons =
    agreeButton || disagreeButton || extraButton || loadingButton;
  return (
    <>
      {open && (
        <Dialog
          fullWidth={fullWidth}
          maxWidth={maxWidth}
          open={open}
          onClose={dialogCloseOutside || onClickButtonClose}
          disableEscapeKeyDown
        >
          <StyledDialogTitle
            id="alert-dialog-title"
            sx={{ mb: 2, whiteSpace: 'pre-line' }}
            onClose={onClickButtonClose}
          >
            {dialogTitle}
          </StyledDialogTitle>
          {children}
          {renderActionButtons && (
            <DialogActions sx={sxButtons}>
              {disagreeButton && (
                <Button
                  variant={disagreeButtonVariant}
                  onClick={onClickButtonClose}
                  color={disagreeButtonColor}
                  sx={sxDisagreeButton}
                  type={disagreeButtonType}
                >
                  {disagreeButtonText}
                </Button>
              )}

              {agreeButton && (
                <Button
                  variant={agreeButtonVariant}
                  onClick={onClickButtonAgree}
                  color={agreeButtonColor}
                  sx={sxAgreeButton}
                  type={agreeButtonType}
                >
                  {agreeButtonText}
                </Button>
              )}

              {extraButton && (
                <Button
                  variant={extraButtonVariant}
                  onClick={onClickButtonExtra}
                  color={extraButtonColor}
                  sx={sxExtraButton}
                  type={extraButtonType}
                >
                  {extraButtonText}
                </Button>
              )}

              {loadingButton && (
                <LoadingButton
                  disabled={disableLoadingButton}
                  variant={loadingButtonVariant}
                  loading={isSubmitting}
                  onClick={onClickButtonLoading}
                  color={loadingButtonColor}
                  sx={sxLoadingButton}
                  type={loadingButtonType}
                >
                  {loadingButtonText}
                </LoadingButton>
              )}
            </DialogActions>
          )}
        </Dialog>
      )}
    </>
  );
}
