import { Stack, Typography } from '@mui/material';

type ValidationErrorsListProps = {
  validationErrors: string[];
};
export default function ValidationErrorsList({
  validationErrors,
}: ValidationErrorsListProps) {
  return (
    <Stack sx={{ mt: 2 }}>
      {validationErrors.map((error) => (
        <Typography color="error" fontStyle="italic" fontSize={14} key={error}>
          {error}
        </Typography>
      ))}
    </Stack>
  );
}
