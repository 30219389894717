import { useState, useEffect } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';
// api
import { getCategoryData, deleteCategory } from '../api/index';
// @mui
import {
  Box,
  Button,
  Card,
  Container,
  FormControlLabel,
  IconButton,
  Switch,
  Table,
  TableBody,
  TableContainer,
  TablePagination,
  Tooltip,
  Skeleton,
} from '@mui/material';
// paths
import { PATH_SETTINGS, settingsPath } from '../routes/paths';
import { PATH_USER } from '@/features/app';
// hooks
import useSettings from '@/hooks/useSettings';
import useTable, { emptyRows } from '@/hooks/useTable';
import { useGetAccess } from '@/hooks/useAccess';
// components
import { Page, Iconify, Scrollbar, HeaderBreadcrumbs } from '@/components';
import {
  TableNoData,
  TableEmptyRows,
  TableSelectedActions,
  TableHeadCustom,
} from '@/components/table';
// custom components
import { CategoryTableToolbar, CategoryTableRow } from '../components';
// types
import type { Category } from '../types';
// sort function
import { applySortFilterForCategoryList } from '../utils';
// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'name', label: 'Name', align: 'left' },
  { id: 'description', label: 'Description', align: 'left' },
  { id: 'active', label: 'Status', align: 'left' },
  { id: '' },
];

const STATUS_OPTIONS: string[] = ['all', 'active', 'inactive'];
// ----------------------------------------------------------------------

export default function CategoryList() {
  const {
    dense,
    page,
    order,
    orderBy,
    rowsPerPage,
    setPage,
    //
    selected,
    setSelected,
    onSelectRow,
    onSelectAllRows,
    //
    onSort,
    onChangeDense,
    onChangePage,
    onChangeRowsPerPage,
  } = useTable();

  const { themeStretch } = useSettings();

  const navigate = useNavigate();

  const { enqueueSnackbar } = useSnackbar();

  // --------------------------------------------------------------------------
  // API CALL + USESTATE + SETTING FETCHDATA
  const [tableData, setTableData] = useState<Category[]>([]);
  // Skeleton State - is page loading?
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    getCategoryData().then((response) => {
      setTableData(response);
    });
    setIsLoading(false);
  }, []);
  // get the access for the module
  const allow = useGetAccess('products');

  // --------------------------------------------------------------------------
  //  FILTER & DELETE FUNCTIONS

  const [filterName, setFilterName] = useState('');

  const [filterStatus, setFilterStatus] = useState('all');

  const handleFilterName = (filterName: string) => {
    setFilterName(filterName.toLowerCase());
    setPage(0);
  };

  const handleFilterStatus = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFilterStatus(event.target.value);
  };

  const handleDeleteCategory = async (id: string) => {
    const { status } = await deleteCategory(Number(id));
    if (status === 200) {
      const deleteRow = tableData.filter((row) => row.id !== id);
      setSelected([]);
      setTableData(deleteRow);
      enqueueSnackbar('Category successfully deleted');
    }
  };

  const handleDeleteRows = (selected: string[]) => {
    const deleteRows = tableData.filter((row) => !selected.includes(row.id));
    setSelected([]);
    setTableData(deleteRows);
  };

  const handleEditRow = (id: number) => {
    navigate(settingsPath('edit-categories', 'id', id));
  };

  const dataFiltered = applySortFilterForCategoryList({
    tableData,
    filterName,
    filterStatus,
  });

  const denseHeight = dense ? 52 : 72;

  const isNotFound =
    (!dataFiltered.length && !!filterName) ||
    (!dataFiltered.length && !!filterStatus);

  return (
    <>
      {
        <Page title="Product Categories">
          <Container maxWidth={themeStretch ? false : 'lg'}>
            <HeaderBreadcrumbs
              heading="Product Categories"
              links={[
                { name: 'Dashboard', href: PATH_USER.root },
                {
                  name: 'Settings',
                  href: `${PATH_SETTINGS.root}${PATH_SETTINGS.categories}`,
                },
                { name: 'Categories' },
              ]}
              action={
                allow.canCreate && (
                  <Button
                    variant="contained"
                    component={RouterLink}
                    to={settingsPath('new-categories')}
                    startIcon={<Iconify icon={'eva:plus-fill'} />}
                  >
                    Add new category
                  </Button>
                )
              }
            />

            <Card>
              {isLoading ? (
                <Box pl={2} pb={2}>
                  <Skeleton sx={{ width: 0.98 }} height={100} />
                </Box>
              ) : (
                <CategoryTableToolbar
                  filterName={filterName}
                  filterStatus={filterStatus}
                  onFilterName={handleFilterName}
                  onFilterStatus={handleFilterStatus}
                  optionsStatus={STATUS_OPTIONS}
                />
              )}
              {isLoading ? (
                <Box pl={2} pb={2}>
                  {[...Array(5)].map((_, index) => (
                    <Skeleton key={index} sx={{ width: 0.98 }} height={40} />
                  ))}
                </Box>
              ) : (
                <Scrollbar>
                  <TableContainer sx={{ minWidth: 800, position: 'relative' }}>
                    {selected.length > 0 && (
                      <TableSelectedActions
                        dense={dense}
                        numSelected={selected.length}
                        rowCount={tableData.length}
                        onSelectAllRows={(checked) =>
                          onSelectAllRows(
                            checked,
                            tableData.map((row) => row.id)
                          )
                        }
                        actions={
                          <Tooltip title="Delete">
                            <IconButton
                              color="primary"
                              onClick={() => handleDeleteRows(selected)}
                            >
                              <Iconify icon={'eva:trash-2-outline'} />
                            </IconButton>
                          </Tooltip>
                        }
                      />
                    )}
                    <Table size={dense ? 'small' : 'medium'}>
                      <TableHeadCustom
                        order={order}
                        orderBy={orderBy}
                        headLabel={TABLE_HEAD}
                        rowCount={tableData.length}
                        numSelected={selected.length}
                        onSort={onSort}
                        onSelectAllRows={(checked) =>
                          onSelectAllRows(
                            checked,
                            tableData.map((row) => row.id)
                          )
                        }
                      />

                      <TableBody>
                        {dataFiltered
                          .slice(
                            page * rowsPerPage,
                            page * rowsPerPage + rowsPerPage
                          )
                          .map((row) => (
                            <CategoryTableRow
                              key={row.id}
                              row={row}
                              selected={selected.includes(row.id)}
                              onSelectRow={() => onSelectRow(row.id)}
                              onDeleteRow={() => handleDeleteCategory(row.id)}
                              onEditRow={() => handleEditRow(row.id)}
                              permissions={allow}
                            />
                          ))}

                        <TableEmptyRows
                          height={denseHeight}
                          emptyRows={emptyRows(
                            page,
                            rowsPerPage,
                            tableData.length
                          )}
                        />

                        <TableNoData isNotFound={isNotFound} />
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Scrollbar>
              )}

              <Box sx={{ position: 'relative' }}>
                <TablePagination
                  rowsPerPageOptions={[25, 50, 100]}
                  component="div"
                  count={tableData.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={onChangePage}
                  onRowsPerPageChange={onChangeRowsPerPage}
                />

                <FormControlLabel
                  control={<Switch checked={dense} onChange={onChangeDense} />}
                  label="Dense"
                  sx={{ px: 3, py: 1.5, top: 0, position: { md: 'absolute' } }}
                />
              </Box>
            </Card>
          </Container>
        </Page>
      }
    </>
  );
}
