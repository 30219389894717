import { Stack, InputAdornment, TextField, MenuItem } from '@mui/material';
// components
import { Iconify } from '@/components';

// ----------------------------------------------------------------------

type Props = {
  filterName: string;
  filterCoverLevel: string;
  optionsCoverLevel: string[];
  onFilterName: (value: string) => void;
  onFilterCoverLevel: (event: React.ChangeEvent<HTMLInputElement>) => void;
};

export default function TableToolbarData({
  filterName,
  onFilterName,
  filterCoverLevel,
  optionsCoverLevel,
  onFilterCoverLevel,
}: Props) {
  return (
    <Stack
      spacing={2}
      direction={{ xs: 'column', sm: 'row' }}
      sx={{ py: 2.5, px: 3 }}
    >
      <TextField
        fullWidth
        select
        label="Cover Level Type"
        value={filterCoverLevel}
        onChange={onFilterCoverLevel}
        SelectProps={{
          MenuProps: {
            sx: { '& .MuiPaper-root': { maxHeight: 260 } },
          },
        }}
        sx={{
          maxWidth: { sm: 240 },
          textTransform: 'capitalize',
        }}
      >
        {optionsCoverLevel.map((option) => (
          <MenuItem
            key={option}
            value={option}
            sx={{
              mx: 1,
              my: 0.5,
              borderRadius: 0.75,
              typography: 'body2',
              textTransform: 'capitalize',
            }}
          >
            {option}
          </MenuItem>
        ))}
      </TextField>
      <TextField
        fullWidth
        value={filterName}
        onChange={(event) => onFilterName(event.target.value)}
        placeholder="Search..."
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Iconify
                icon={'eva:search-fill'}
                sx={{ color: 'text.disabled', width: 20, height: 20 }}
              />
            </InputAdornment>
          ),
        }}
      />
    </Stack>
  );
}
