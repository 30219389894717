// @mui
import { styled } from '@mui/material/styles';
import { alpha, Box, CardActionArea, Stack, Theme } from '@mui/material';
// hooks
import useSettings from '../../../hooks/useSettings';
//
import Iconify from '../../Iconify';

// ----------------------------------------------------------------------

const StyledCard = styled(CardActionArea, {
  shouldForwardProp: (prop) => prop !== 'selected',
})(({ selected, theme }: { selected: boolean; theme?: Theme }) => ({
  height: 72,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  color: theme?.palette.text.disabled,
  borderRadius: theme?.shape.borderRadius,
  border: `solid 1px ${alpha(theme?.palette.grey[500] as string, 0.12)}`,
  '& .svg-color': {
    width: 28,
    height: 28,
  },
  ...(selected && {
    color: theme?.palette.primary.main,
    boxShadow: theme?.customShadows.z12,
    borderColor: alpha(theme?.palette.grey[500] as string, 0.24),
  }),
}));

// ----------------------------------------------------------------------

export default function SettingStretch() {
  const { themeStretch, onToggleStretch } = useSettings();

  return (
    <StyledCard
      selected={themeStretch}
      onClick={onToggleStretch}
      sx={{ height: 48, px: 1 }}
    >
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        sx={{
          width: 0.24,
          transition: (theme) => theme.transitions.create('width'),
          ...(themeStretch && {
            width: 0.5,
          }),
        }}
      >
        <Iconify
          icon={
            themeStretch
              ? 'eva:arrow-ios-back-fill'
              : 'eva:arrow-ios-forward-fill'
          }
        />

        <Box sx={{ flexGrow: 1, borderBottom: `dashed 1.5px currentcolor` }} />

        <Iconify
          icon={
            themeStretch
              ? 'eva:arrow-ios-forward-fill'
              : 'eva:arrow-ios-back-fill'
          }
        />
      </Stack>
    </StyledCard>
  );
}
