import { generatePath } from 'react-router';
import { path } from '@/utils/formatLink';
import { BaseObjectEntity } from '@/types';

export const ROOTS_PROVIDER_MANAGEMENT = '/providers';

export const PATH_PROVIDER_MANAGEMENT: BaseObjectEntity = {
  root: ROOTS_PROVIDER_MANAGEMENT,
  new: '/new',
  edit: '/:userId/:tabSid',
};

export function providersPath(pathKey: string, params?: BaseObjectEntity) {
  return generatePath(
    path(ROOTS_PROVIDER_MANAGEMENT, PATH_PROVIDER_MANAGEMENT[pathKey]),
    params
  );
}
