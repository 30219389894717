import { Navigate, Route, Routes } from 'react-router-dom';
import GuestGuard from '@/guards/GuestGuard';
import { PATH_AUTH } from './paths';

import Login from '../pages/Login';
import ResetPassword from '../pages/ResetPassword';
import VerifyCode from '../pages/VerifyCode';
import NewPassword from '../pages/NewPassword';

export const AuthRoutes = () => (
  <Routes>
    <Route
      path={PATH_AUTH.login}
      element={
        <GuestGuard>
          <Login />
        </GuestGuard>
      }
    />
    <Route path={PATH_AUTH.reset} element={<ResetPassword />} />
    <Route path={PATH_AUTH.verify} element={<VerifyCode />} />
    <Route path={PATH_AUTH.password} element={<NewPassword />} />
    <Route path="*" element={<Navigate to="/auth/login" />} />
  </Routes>
);
