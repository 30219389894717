import axios from '@/utils/axios';
import { Variant } from '../types';

export async function getVariants(): Promise<Variant[]> {
  const {
    data: { data },
  }: any = await axios.get('/services/variants');
  return data;
}

export async function getSingleVariant(id: number): Promise<Variant> {
  const {
    data: { data },
  }: any = await axios.get(`/services/variants/${id}`);
  return data;
}

export async function createVariant(variantDto: Partial<Variant>) {
  const data = await axios.post('/services/variants', variantDto);
  return data;
}

export async function updateVariant(id: number, variantDto: Partial<Variant>) {
  const data = await axios.put(`/services/variants/${id}`, variantDto);
  return data;
}

export async function deleteVariant(id: number) {
  const data = await axios.delete(`/services/variants/${id}`);
  return data;
}
