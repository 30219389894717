import { Route, Routes } from 'react-router-dom';
import DashboardLayout from '@/layouts/dashboard';
import AuthGuard from '@/guards/AuthGuard';

import NotificationsList from '../pages';

export const NotificationsRoutes = () => (
  <Routes>
    <Route
      element={
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      }
    >
      <Route index element={<NotificationsList />} />
    </Route>
  </Routes>
);
