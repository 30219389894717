import axios from '@/utils/axios';
import { InvoiceRun, ProviderInvoice } from '../types';

export async function getAllInvoiceRuns(): Promise<InvoiceRun[]> {
  const {
    data: { data },
  } = await axios.get(`/invoices/run`);
  return data;
}

export async function getAllProviderInvoicesForRun(
  id: number
): Promise<ProviderInvoice[]> {
  const {
    data: { data },
  } = await axios.get(`/invoices/provider/run/${id}`);
  return data;
}

export async function getProviderInvoices(
  provider_id: number
): Promise<ProviderInvoice[]> {
  const {
    data: { data },
  } = await axios.get(`/invoices/provider/${provider_id}`);
  return data;
}

export async function getProviderInvoice(
  invoice_id: number
): Promise<ProviderInvoice> {
  const {
    data: { data },
  } = await axios.get(`/invoices/provider/view/${invoice_id}`);
  return data;
}

// export async function getReview(id: number): Promise<Review> {
//   const {
//     data: { data },
//   } = await axios.get(`/reviews/${id}`);
//   return data;
// }

// export async function createReview(review: Partial<Review>): Promise<Review> {
//   const {
//     data: { data },
//   } = await axios.post('/reviews', review);
//   return data;
// }

// export async function updateReview(
//   id: number,
//   review: Partial<Review>
// ): Promise<Review> {
//   const {
//     data: { data },
//   } = await axios.put(`/reviews/${id}`, review);
//   return data;
// }

// export async function deleteReview(id: number): Promise<void> {
//   await axios.delete(`/reviews/${id}`);
// }

// export async function getReviewForJob(jobId: number): Promise<Review> {
//   const {
//     data: { data },
//   } = await axios.get(`/reviews/job/${jobId}`);
//   return data;
// }

// export async function getReviewsForClient(
//   clientId: number,
//   status?: string
// ): Promise<Review[]> {
//   const {
//     data: { data },
//   } = await axios.get(
//     `/reviews/client/${clientId}${status !== 'all' ? `?status=${status}` : ''}`
//   );
//   return data;
// }

// export async function getReviewsForCleaner(
//   cleanerId: number,
//   status?: string
// ): Promise<Review[]> {
//   const {
//     data: { data },
//   } = await axios.get(
//     `/reviews/cleaner/${cleanerId}${
//       status !== 'all' ? `?status=${status}` : ''
//     }`
//   );
//   return data;
// }
