import { isEmpty } from 'lodash';
// mui
import { Box, Divider, Stack, Typography } from '@mui/material';
// redux
import { useSelector } from '@/stores';
// types
import {
  SummaryAmount,
  SummaryBookingDateTime,
  SummaryCleanType,
  SummaryCleaningExtras,
  SummaryCleaningProducts,
  SummaryCleaningService,
  SummaryCouponDiscount,
} from './summary-partials';

// --------------------------------------------------

export default function BookingSummary() {
  const booking = useSelector((state) => state.booking);

  return (
    <Box
      sx={{
        p: 4,
        border: '1px',
        borderRadius: '10px',
        bgcolor: 'background.neutral',
      }}
    >
      <Typography fontWeight={700} sx={{ mb: 2 }}>
        Booking Summary
      </Typography>

      <Stack
        direction="column"
        spacing={2}
        divider={<Divider orientation="horizontal" flexItem />}
      >
        <SummaryCleanType />
        <SummaryBookingDateTime />
        <SummaryCleaningService />
        <SummaryCleaningExtras />

        {!isEmpty(booking.cleanDetails.couponObject) && (
          <>
            <SummaryAmount sumType="sub" />
            <Divider />
            <SummaryCouponDiscount />
          </>
        )}

        {booking.cleanDetails.requestForCleaningProduct && (
          <SummaryCleaningProducts />
        )}

        <SummaryAmount sumType="total" />
      </Stack>
    </Box>
  );
}
