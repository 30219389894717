//
import {
  Box,
  FormControlLabel,
  Grid,
  Stack,
  Switch,
  ToggleButton,
  ToggleButtonGroup,
} from '@mui/material';
// custom component
import { CustomErrorMessage, SectionLabel } from '../../..';
import RTKNotes from './notes-partials/RTKNotes';
// hooks
import useResponsive from '@/hooks/useResponsive';
// redux
import { dispatch, useSelector } from '@/stores';
import {
  setAccessType,
  setValidationError,
  toggleAccessInstructionsSwitch,
} from '@/features/bookings-management/slices';

export default function Notes() {
  const downMd = useResponsive('down', 'md');
  const booking = useSelector((state) => state.booking);

  // there's no API for this, so we're using a static list
  const ACCESS_TYPE_OPTIONS = [
    { id: 1, name: 'Someone at home', width: 'content-fit' },
    { id: 2, name: 'Concierge', width: 120 },
    { id: 3, name: 'Key Safe', width: 120 },
    { id: 4, name: 'Other', width: 120 },
  ];

  return (
    <Grid container item xs={12} spacing={2} direction="row" sx={{ pr: 2 }}>
      <Grid item xs={12}>
        <SectionLabel label="Notes and instructions" />
      </Grid>

      <Grid container item direction="row" spacing={2} xs={12}>
        {/* CLEANING INSTRUCTIONS */}
        <Grid item xs={12}>
          <RTKNotes noteType="provider_instructions" />
        </Grid>

        {/* ADMIN NOTES */}
        <Grid item xs={12}>
          <RTKNotes noteType="admin_notes" />
        </Grid>

        {/* INTERNAL NOTES */}
        <Grid item xs={12}>
          <RTKNotes noteType="internal_notes" />
        </Grid>

        {/* ACCESS INSTRUCTIONS */}
        <Grid item xs={12}>
          <Stack
            direction={downMd ? 'column' : 'row'}
            spacing={2}
            alignItems={downMd ? 'flex-start' : 'center'}
            sx={{ p: 0, pb: downMd ? 2.5 : 1 }}
          >
            <Box>
              <FormControlLabel
                control={
                  <Switch
                    onChange={(e) =>
                      dispatch(toggleAccessInstructionsSwitch(e.target.checked))
                    }
                    checked={booking.cleanDetails.access_instructions_enabled}
                  />
                }
                label="Add access instructions"
              />
            </Box>
            {booking.cleanDetails.access_instructions_enabled && (
              <ToggleButtonGroup
                orientation={downMd ? 'vertical' : 'horizontal'}
                color="primary"
                value={booking.cleanDetails.access_type}
                exclusive
                onChange={(_, value) => {
                  dispatch(setAccessType(value));
                  booking.validationErrors.access_type &&
                    dispatch(
                      setValidationError({ field: 'access_type', error: null })
                    );
                }}
                aria-label="Access type toggle switch"
                sx={{
                  maxWidth: 'fit-content',
                  height: downMd ? 'auto' : 46,
                  border: (theme) =>
                    !!booking.validationErrors.access_type
                      ? `1px solid ${theme.palette.error.main}`
                      : '1px solid text.secondary',
                }}
              >
                {ACCESS_TYPE_OPTIONS.map((option) => (
                  <ToggleButton
                    key={option.id}
                    value={option.name}
                    sx={{ width: downMd ? 'auto' : option.width }}
                  >
                    {option.name}
                  </ToggleButton>
                ))}
              </ToggleButtonGroup>
            )}
            {booking.validationErrors.access_type && (
              <CustomErrorMessage
                label={booking.validationErrors.access_type}
              />
            )}
          </Stack>
          {booking.cleanDetails.access_instructions_enabled && (
            <RTKNotes noteType="access_instructions" />
          )}
        </Grid>
      </Grid>
    </Grid>
  );
}
