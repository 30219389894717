import { Navigate, Route, Routes } from 'react-router-dom';
import DashboardLayout from '@/layouts/dashboard';
//
import AuthGuard from '@/guards/AuthGuard';
import RoleBasedGuard from '@/guards/RoleBasedGuard';
//
import { PATH_BOOKINGS_MANAGEMENT, bookingsManagementPath } from './paths';
import {
  BookingsList,
  CreateEditBooking,
  ViewBooking,
  //
  SessionsList,
} from '../pages';

const BOOKINGS_MANAGEMENT_ROUTES = [
  // bookings
  {
    path: '',
    roles: ['bookings.view'],
    component: <Navigate to={bookingsManagementPath('list')} replace />,
    index: true,
  },
  {
    path: PATH_BOOKINGS_MANAGEMENT.bookingsRoot,
    roles: ['bookings.view'],
    component: <Navigate to={bookingsManagementPath('list')} replace />,
    index: false,
  },
  {
    path: PATH_BOOKINGS_MANAGEMENT.list,
    roles: ['bookings.view'],
    component: <BookingsList />,
    index: false,
  },
  {
    path: PATH_BOOKINGS_MANAGEMENT.view,
    roles: ['bookings.view'],
    component: <ViewBooking />,
    index: false,
  },
  {
    path: PATH_BOOKINGS_MANAGEMENT.viewWithSessionFilter,
    roles: ['bookings.view'],
    component: <ViewBooking />,
    index: false,
  },
  {
    path: PATH_BOOKINGS_MANAGEMENT.new,
    roles: ['bookings.create'],
    component: <CreateEditBooking />,
    index: false,
  },
  {
    path: PATH_BOOKINGS_MANAGEMENT.edit,
    roles: ['bookings.update'],
    component: <CreateEditBooking />,
    index: false,
  },
  // booking-sessions
  {
    path: PATH_BOOKINGS_MANAGEMENT.sessionsRoot,
    roles: ['bookings.view'],
    component: <Navigate to={bookingsManagementPath('sessionsList')} replace />,
    index: false,
  },
  {
    path: PATH_BOOKINGS_MANAGEMENT.sessionsList,
    roles: ['bookings.view'],
    component: <SessionsList />,
    index: false,
  },
];

export const BookingsManagementRoutes = () => (
  <Routes>
    <Route
      element={
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      }
    >
      {BOOKINGS_MANAGEMENT_ROUTES.map((route) => (
        <Route
          key={route.index ? 'index' : route.path}
          path={route.path}
          index={route.index}
          element={
            <RoleBasedGuard roles={route.roles}>
              {route.component}
            </RoleBasedGuard>
          }
        />
      ))}
    </Route>
  </Routes>
);
