import React, { PropsWithChildren, useCallback, useState } from 'react';

import { AddChannelButton } from './AddChannelButton';

import { Icon } from '@iconify/react';
import chevronDown from '@iconify/icons-mdi/chevron-down';
import chevronUp from '@iconify/icons-mdi/chevron-up';

import {
  useWorkspaceController,
  Workspace,
} from '@/contexts/WorkspaceController';

import type { ChannelListMessengerProps } from 'stream-chat-react';

export type SupportChannelListProps = ChannelListMessengerProps & {
  type: string;
};

const ChannelList = (props: PropsWithChildren<SupportChannelListProps>) => {
  const { children, error = false, loading, type } = props;
  const [isCollapsed, setIsCollapsed] = useState(false);

  const { displayWorkspace } = useWorkspaceController();

  const handleAddChannelClick = useCallback(() => {
    displayWorkspace(`Admin-Admin-Channel-Create__${type}` as Workspace);
  }, [type, displayWorkspace]);

  const toggleCollapse = () => {
    setIsCollapsed(!isCollapsed);
  };

  if (error) {
    return type === 'jobSupport' ? (
      <div className="team-channel-list">
        <p className="team-channel-list__message">
          Connection error, please wait a moment and try again.
        </p>
      </div>
    ) : null;
  }

  if (loading) {
    return (
      <div className="team-channel-list">
        <p className="team-channel-list__message loading">
          {type === 'jobSupport' ? 'Job Support' : 'Clients Support'}{' '}
          loading....
        </p>
      </div>
    );
  }

  return (
    <div className="team-channel-list">
      <div className="team-channel-list__header">
        <button onClick={toggleCollapse} className="toggle-collapse-button">
          <Icon icon={isCollapsed ? chevronDown : chevronUp} />
        </button>
        <p className="team-channel-list__header__title">
          {type === 'jobSupport' ? 'Job Support' : 'Clients Support'}
        </p>
        <AddChannelButton onClick={handleAddChannelClick} />
      </div>
      {!isCollapsed && children}
    </div>
  );
};

export const JobChannelList = React.memo(ChannelList);
