import { useRef, useState } from 'react';
import { useMutation } from '@tanstack/react-query';
//
import { Button, Stack, TextField } from '@mui/material';
import DialogSimple from '@/components/dialog/DialogSimple';
import { LoadingButton } from '@mui/lab';
//
import { verifyCoupon } from '@/features/coupons/api';
import { useSnackbarMsg } from '@/hooks/useSnackbarMsg';
import { updateBookingSession } from '@/features/bookings-management/api';
//
type ApplyCouponDialogProps = {
  isDialogOpen: boolean;
  closeDialog: VoidFunction;
  serviceVariantId: number;
  bookingSessionId: number;
};
export default function VBMApplyCouponDialog({
  isDialogOpen,
  closeDialog,
  serviceVariantId,
  bookingSessionId,
}: ApplyCouponDialogProps) {
  const couponCodeRef = useRef<HTMLInputElement>(null);
  const { successMsg, errorMsg } = useSnackbarMsg();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const verifyCouponMutation = useMutation({
    mutationFn: (couponCode: string) =>
      verifyCoupon({
        service_variant_id: serviceVariantId,
        couponCode,
        platform: 'WEB_PLATFORM',
      }),
  });
  const applyCouponMutation = useMutation({
    mutationFn: (
      couponCodeId: number // we need to update the booking session here
    ) =>
      updateBookingSession(bookingSessionId, { coupon_code_id: couponCodeId }),
  });

  const handleSubmitCouponCode = async (
    event: React.FormEvent<HTMLFormElement>
  ) => {
    event.preventDefault();
    if (!serviceVariantId) {
      errorMsg('Cannot apply code without service variant ID');
      return;
    }
    if (!couponCodeRef.current || !!!couponCodeRef.current.value.length) {
      errorMsg('Coupon code required');
      return;
    }
    const couponCode = couponCodeRef?.current?.value; // Access the value of the input field
    setIsSubmitting(true);
    try {
      const verifiedCoupon = await verifyCouponMutation.mutateAsync(couponCode);
      await applyCouponMutation.mutateAsync(verifiedCoupon.id); // update booking session
      successMsg(
        `"${verifiedCoupon.description}" was succesfully applied to session #${bookingSessionId}`
      );
      closeDialog();
    } catch (error) {
      errorMsg(error, `There was an error while applying coupon`);
    } finally {
      setIsSubmitting(false);
    }
    // await applyCouponMutation.mutateAsync(couponCode);
  };
  return (
    <DialogSimple
      dialogTitle="Apply coupon code"
      open={isDialogOpen}
      loadingButton={false}
      disagreeButton={false}
      onClickButtonClose={closeDialog}
    >
      <form onSubmit={(e) => handleSubmitCouponCode(e)}>
        <Stack direction="column" spacing={2} sx={{ px: 3, pt: 1, pb: 2 }}>
          <TextField
            name="coupon_code"
            label="Coupon code"
            size="small"
            inputProps={{ ref: couponCodeRef }}
          />
          <Stack
            direction="row"
            justifyContent="flex-end"
            spacing={1}
            sx={{ mt: 2 }}
          >
            <Button
              variant="outlined"
              size="small"
              color="inherit"
              onClick={closeDialog}
            >
              Cancel
            </Button>
            <LoadingButton
              variant="contained"
              size="small"
              type="submit"
              loading={isSubmitting} // TODO
              disabled={isSubmitting}
            >
              Apply Coupon
            </LoadingButton>
          </Stack>
        </Stack>
      </form>
    </DialogSimple>
  );
}
