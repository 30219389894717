// mui
import { Typography } from '@mui/material';

export default function CustomErrorMessage({ label }: { label: string }) {
  return (
    <Typography variant="caption" color="error">
      {label}
    </Typography>
  );
}
