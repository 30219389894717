import { Stack, Button } from '@mui/material';
import { LoadingButton } from '@mui/lab';
//
import useResponsive from '@/hooks/useResponsive';
//
type VBMActionButtonsProps = {
  onClickCancel: () => void;
  isSubmitting: boolean;
  disabled?: boolean;
  onSubmit?: () => void;
};

export default function VBMActionButtons({
  onClickCancel,
  isSubmitting,
  disabled = false,
}: VBMActionButtonsProps) {
  const downMd = useResponsive('down', 'md');
  return (
    <Stack direction="row" justifyContent="flex-end" spacing={1} sx={{ mt: 2 }}>
      <Button
        variant="outlined"
        size="small"
        color="inherit"
        onClick={onClickCancel}
      >
        Cancel
      </Button>
      <LoadingButton
        variant="contained"
        size="small"
        type="submit"
        loading={isSubmitting}
        disabled={disabled}
      >
        {downMd ? 'Save' : 'Save changes'}
      </LoadingButton>
    </Stack>
  );
}
