import { createRef, ReactNode, useEffect, useMemo } from 'react';

import ReactQuill, { ReactQuillProps, Quill } from 'react-quill';
// @ts-ignore
import ImageResize from 'quill-image-resize-module-react';
import { styled } from '@mui/material/styles';
import { Box, BoxProps } from '@mui/material';
//
import EditorToolbar, {
  formats,
  imageHandler,
  placeholderChange,
  redoChange,
  undoChange,
} from './EditorToolbar';
import QuillImage from './QuillImage';

const RootStyle = styled(Box)(({ theme }) => ({
  borderRadius: theme.shape.borderRadius,
  border: `solid 1px ${theme.palette.grey[500_32]}`,
  '& .ql-container.ql-snow': {
    borderColor: 'transparent',
    ...theme.typography.body1,
    fontFamily: theme.typography.fontFamily,
  },
  '& .ql-editor': {
    minHeight: 200,
    maxHeight: 640,
    '&.ql-blank::before': {
      fontStyle: 'normal',
      color: theme.palette.text.disabled,
    },
  },
  '& .ql-placeholder .ql-picker-item': {
    padding: '4px !important',
  },
}));

Quill.register('modules/imageResize', ImageResize, true);
Quill.register(QuillImage, true);
// @ts-ignore
window.Quill = Quill;

export interface Props extends ReactQuillProps {
  id?: string;
  error?: boolean;
  simple?: boolean;
  helperText?: ReactNode;
  sx?: BoxProps;
}

export default function Editor({
  id = 'email-template-editor',
  error,
  value,
  onChange,
  simple = false,
  helperText,
  sx,
  ...other
}: Props) {
  const ref = createRef<HTMLDivElement>();
  const quillRef = createRef<ReactQuill>();

  useEffect(() => {
    const editorElement = ref.current;
    const placeholderPickerItems = Array.prototype.slice.call(
      (editorElement as HTMLElement)?.querySelectorAll(
        '.ql-placeholder .ql-picker-item'
      )
    );
    placeholderPickerItems.forEach(
      (item) => (item.textContent = item.dataset.label)
    );
    (
      (editorElement as HTMLElement)?.querySelector(
        '.ql-placeholder .ql-picker-label'
      ) as Element
    ).innerHTML = 'Placeholders';
  }, [ref]);

  const modules = useMemo(
    () => ({
      toolbar: {
        container: `#${id}`,
        handlers: {
          image: imageHandler,
          undo: undoChange,
          redo: redoChange,
          placeholder: placeholderChange,
        },
      },
      history: {
        delay: 500,
        maxStack: 100,
        userOnly: true,
      },
      clipboard: {
        matchVisual: false,
      },
      imageResize: {
        parchment: Quill.import('parchment'),
        modules: ['Resize', 'DisplaySize', 'Toolbar'],
      },
    }),
    [id]
  );

  return (
    <div ref={ref}>
      <RootStyle
        sx={{
          ...(error && {
            border: (theme) => `solid 1px ${theme.palette.error.main}`,
          }),
          ...sx,
        }}
      >
        <EditorToolbar id={id} isSimple={simple} />
        <ReactQuill
          ref={quillRef}
          value={value}
          onChange={onChange}
          modules={modules}
          formats={formats}
          placeholder="Write something awesome..."
          {...other}
        />
      </RootStyle>

      {helperText && helperText}
    </div>
  );
}
