// THIS COMPONENT IS USED WITH BOOKINGS AND BOOKING SESSIONS AS WELL
// mui
import {
  Button,
  Grid,
  IconButton,
  InputAdornment,
  Skeleton,
  TextField,
  Tooltip,
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
// components
import Iconify from '@/components/Iconify';
import DropdownSelection from '@/components/DropdownSelection';
//
import useResponsive from '@/hooks/useResponsive';
//types
import { Settings } from '@/types';
import { User } from '@/features/users/shared/types';

type InvoiceRunsTableToolbarProps = {
  filterStartDate: string | null;
  filterEndDate: string | null;
  //
  handleFilterDropdown: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleDatepickerChange: (
    value: string | null,
    date_type: string,
    keyboardInputValue?: string | undefined
  ) => void;
  resetFilters: () => void;
};

export default function InvoiceRunsInvoicesListTableToolbar({
  filterStartDate,
  filterEndDate,
  //
  handleFilterDropdown,
  handleDatepickerChange,
  resetFilters,
}: InvoiceRunsTableToolbarProps) {
  return (
    <Grid
      container
      spacing={2}
      direction={{ xs: 'column', lg: 'row' }}
      sx={{ py: 2.5, px: 3 }}
    >
      <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
        <Button
          // onClick={handleAgree}
          variant="contained"
          color={'info'}
          startIcon={<Iconify icon="eva:refresh-outline" />}
        >
          Regenerate Modified Invoices
        </Button>
      </Grid>
    </Grid>
  );
}
