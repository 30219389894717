import { Box, Skeleton } from '@mui/material';

export default function SkeletonLoadingTableData() {
  return (
    <Box sx={{ pl: 2, pb: 2 }}>
      <Skeleton sx={{ width: 0.98 }} height={120} />
      {[...Array(5)].map((_, i) => (
        <Skeleton key={i} sx={{ width: 0.98 }} height={70} />
      ))}
    </Box>
  );
}
