import { Route, Routes } from 'react-router-dom';
//
import DashboardLayout from '@/layouts/dashboard';
import AuthGuard from '@/guards/AuthGuard';
import RoleBasedGuard from '@/guards/RoleBasedGuard';
//
import { PATH_COUPONS } from './paths';
import { CouponCreateEdit, CouponList } from '../pages';

const COUPON_ROUTES = [
  {
    path: '',
    roles: ['coupons.view'],
    component: <CouponList />,
    index: true,
  },
  {
    path: PATH_COUPONS.new,
    roles: ['coupons.create'],
    component: <CouponCreateEdit />,
    index: false,
  },
  {
    path: PATH_COUPONS.edit,
    roles: ['coupons.update'],
    component: <CouponCreateEdit />,
    index: false,
  },
];

export const CouponsRoutes = () => (
  <Routes>
    <Route
      element={
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      }
    >
      {COUPON_ROUTES.map((route, index) => (
        <Route
          key={index}
          path={route.path}
          index={route.index}
          element={
            <RoleBasedGuard roles={route.roles}>
              {route.component}
            </RoleBasedGuard>
          }
        />
      ))}
    </Route>
  </Routes>
);
