import { generatePath } from 'react-router';
import { path } from '@/utils/formatLink';
import { BaseObjectEntity } from '@/types';

export const ROOTS_REVIEWS = '/reviews';

export const PATH_REVIEWS: BaseObjectEntity = {
  root: ROOTS_REVIEWS,
};

export function reviewsPath(pathKey: string, params?: BaseObjectEntity) {
  return generatePath(path(ROOTS_REVIEWS, PATH_REVIEWS[pathKey]), params);
}
