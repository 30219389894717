import { createSlice } from '@reduxjs/toolkit';
// import { Conversation, Message } from '@twilio/conversations';
// // utils
// import { reduxifyParticipant, reduxifyMessage } from './helpers';
// import {
//   convoSorter,
//   conversationsMap,
//   mediaMap,
//   messagesMap,
//   participantsMap,
// } from '../utils';
// types
import { ChatState } from '../types';
// import { find, findIndex } from 'lodash';

// ===================================

const initialState: ChatState = {
  // client: null,
  // convos: [],
  // connectionState: 'disconnected',
  // isLoading: false,
  // messages: {},
  // notifications: [],
  // participants: {},
  // sid: '',
  // token: '',
  unreadMessages: { count: 0 },
  // participantsWhoLeft: [],
  // attachments: {},
};

export const slice = createSlice({
  name: 'chat-stream',
  initialState,
  reducers: {
    // START LOADING
    // setLoading(state, { payload }) {
    //   state.isLoading = payload;
    // },

    // SET CLIENT
    // setClient(state, { payload }) {
    //   state.client = payload;
    // },

    // SET TOKEN
    // setToken(state, { payload }) {
    //   state.token = payload;
    // },

    // SET CONNECTION STATE
    // setConnectionState(state, { payload }) {
    //   state.connectionState = payload;
    // },

    // ===================================
    //            CONVERSATION
    // ===================================

    // UPSERT CONVERSATION
    // upsertConversation(state, { payload }) {
    //   const {
    //     sid,
    //     friendlyName,
    //     dateUpdated,
    //     notificationLevel,
    //     lastReadMessageIndex,
    //     lastMessage,
    //   } = payload as Conversation;

    //   const filteredClone = state.convos.filter(
    //     (conversation) => conversation.sid !== payload.sid
    //   );

    //   conversationsMap.set(payload.sid, payload);

    //   const updatedConvoArray = [
    //     ...filteredClone,
    //     {
    //       sid,
    //       friendlyName,
    //       dateUpdated,
    //       notificationLevel,
    //       lastReadMessageIndex,
    //       lastMessage: {
    //         ...lastMessage,
    //       },
    //     },
    //   ].sort(convoSorter);

    //   state.convos = updatedConvoArray;
    // },

    // UDATE CONVERSATION
    // updateConversation(state, { payload: { convoSid, convo } }) {
    //   const indexOfConvo = state.convos.indexOf(convoSid);

    //   const convoToUpdate = state.convos.find(
    //     (convo) => convo.sid === convoSid
    //   );
    //   if (convoToUpdate) {
    //     state.convos[indexOfConvo] = {
    //       ...convoToUpdate,
    //       ...convo,
    //     };
    //   }
    // },

    // UPDATE CURRENT CONVERSTAION SID
    // updateCurrentConversation(state, { payload }) {
    //   state.sid = payload;
    // },

    // REMOVE CONVERSATION
    // removeConversation(state, { payload }) {
    //   state.convos = state.convos.filter(
    //     (convo: ReduxConversation) => convo.sid !== payload
    //   );

    //   conversationsMap.delete(payload);
    // },

    // ===================================
    //             ATTACHMENTS
    // ===================================

    // ADD ATTACHMENTS
    // addAttachment(state, { payload }) {
    //   const { channelSid, messageSid, mediaSid, attachment } = payload;
    //   state.attachments[channelSid] = state.attachments[channelSid] ?? {};
    //   state.attachments[channelSid][messageSid] =
    //     state.attachments[channelSid][messageSid] ?? {};

    //   state.attachments = {
    //     ...state.attachments,
    //     [channelSid]: {
    //       ...(state.attachments[channelSid] || {}),
    //       [messageSid]: {
    //         ...(state.attachments[channelSid]?.[messageSid] || {}),
    //         [mediaSid]: attachment,
    //       },
    //     },
    //   };
    // },

    // REMOVE ATTACHMENTS
    // removeAttachments(state, { payload: { channelSid, messageSid } }) {
    //   state.attachments = {
    //     ...state.attachments,
    //     [channelSid]: {
    //       ...(state.attachments[channelSid] || {}),
    //       [messageSid]: {},
    //     },
    //   };
    // },

    // ===================================
    //            PARTICIPANTS
    // ===================================

    // UPDATE PARTICIPANTS
    // updateParticipants(state, { payload: { participants, sid } }) {
    //   for (const participant of participants) {
    //     participantsMap.set(participant.sid, participant);
    //   }

    //   state.participants = {
    //     ...state.participants,
    //     [sid]: participants.map(reduxifyParticipant),
    //   };
    // },

    // UPDATE PARTICIPANTS ON LEAVE
    // updateLeftParticipant(state, { payload: { participantLeft, sid } }) {
    //   const result = find(state.participantsWhoLeft, {
    //     conversationSid: sid,
    //   });

    //   if (result) {
    //     const index = findIndex(state.participantsWhoLeft, {
    //       conversationSid: sid,
    //     });

    //     state.participantsWhoLeft[index].leftParticipants.push({
    //       name: participantLeft.identity,
    //       leftAt: new Date().toISOString(),
    //     });
    //   } else {
    //     state.participantsWhoLeft.push({
    //       conversationSid: sid,
    //       leftParticipants: [
    //         {
    //           name: participantLeft.identity,
    //           leftAt: new Date().toISOString(),
    //         },
    //       ],
    //     });
    //   }
    // },

    // REMOVE LEFT PARTICIPANT / UPDATE STATE ON USER INTERACTION
    // removeLeftParticipant(state, { payload }) {
    //   state.participantsWhoLeft = state.participantsWhoLeft.filter(
    //     (p) => p.conversationSid !== payload
    //   );
    // },

    // ===================================
    //              MESSAGES
    // ===================================

    // UPSERT MESSAGES
    // upsertMessages(state, { payload }) {
    //   //get convo sid and messages to add from payload
    //   const { channelSid, messages: messagesToAdd } = payload;

    //   //get existing messages for the convo
    //   const existingMessages = state.messages[channelSid] ?? [];

    //   const filteredExistingMessages = existingMessages.filter(
    //     (message) =>
    //       !messagesToAdd.find(
    //         (value: Message | ReduxMessage) =>
    //           value.body === message.body &&
    //           value.author === message.author &&
    //           (message.index === -1 || value.index === message.index)
    //       )
    //   );
    //   //add new messages to exisiting, ignore duplicates
    //   const messagesUnique = [
    //     ...filteredExistingMessages,
    //     ...messagesToAdd.map(reduxifyMessage),
    //   ];

    //   for (const message of messagesToAdd) {
    //     if (message instanceof Message) {
    //       messagesMap.set(message.sid, message);

    //       if (message.attachedMedia) {
    //         message.attachedMedia.forEach((media) => {
    //           mediaMap.set(media.sid, media);
    //         });
    //       }
    //     }
    //   }

    //   const sortedMessages = messagesUnique.sort((a, b) => a.index - b.index);

    //   //overwrite the channelSid messages
    //   state.messages = {
    //     ...state.messages,
    //     [channelSid]: sortedMessages,
    //   };
    // },

    // UPDATE UNREAD MESSAGES
    updateUnreadMessages(state, { payload: { unreadCount } }) {
      state.unreadMessages = {
        ...state.unreadMessages,
        count: unreadCount,
      };
    },
  },
});

export default slice.reducer;

export const {
  //
  // setClient,
  // setConnectionState,
  // setLoading,
  // setToken,
  // // conversations
  // upsertConversation,
  // updateConversation,
  // updateCurrentConversation,
  // removeConversation,
  // // attachments
  // addAttachment,
  // removeAttachments,
  // // participants
  // removeLeftParticipant,
  // updateLeftParticipant,
  // updateParticipants,
  // messages
  updateUnreadMessages,
  // upsertMessages,
} = slice.actions;
