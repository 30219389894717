import { m } from 'framer-motion';
// @mui
import { Button, Container, Typography } from '@mui/material';
import { useHasPermission } from '@/hooks/useHasPermissions';
// components
import { MotionContainer, varBounce } from '../components/animate';
//
import { Link as RouterLink } from 'react-router-dom';
import { ForbiddenIllustration } from '../assets';
import useAuth from '@/hooks/useAuth';

type RoleBasedGuardProp = {
  roles: string[];
  children: React.ReactNode;
};

export default function RoleBasedGuard({
  roles,
  children,
}: RoleBasedGuardProp) {
  const { permissions, isSuperAdmin } = useAuth();
  const { isAllowed } = useHasPermission(roles, permissions, isSuperAdmin);
  if (!isAllowed) {
    return (
      <Container component={MotionContainer} sx={{ textAlign: 'center' }}>
        <m.div variants={varBounce().in}>
          <Typography variant="h3" paragraph>
            Permission Denied
          </Typography>
        </m.div>

        <m.div variants={varBounce().in}>
          <Typography sx={{ color: 'text.secondary' }}>
            You do not have permission to access this page. <br /> Contact your
            manager.
          </Typography>
        </m.div>

        <m.div variants={varBounce().in}>
          <ForbiddenIllustration sx={{ height: 260, my: { xs: 5, sm: 10 } }} />
        </m.div>
        <Button to="/" size="large" variant="contained" component={RouterLink}>
          Go to Home
        </Button>
      </Container>
    );
  }

  return <>{children}</>;
}
