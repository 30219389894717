import { tableSortFilter } from '@/utils/arrayProcessing';
import { Role } from '../types/roles';
import { object, string } from 'yup';

export function applySortFilterForRolesList({
  tableData,
  comparator,
  searchFieldValue,
  filterRole,
}: {
  tableData: Role[];
  comparator: (a: any, b: any) => number;
  searchFieldValue: string;
  filterRole: string;
}) {
  tableData = tableSortFilter(tableData, comparator);

  if (searchFieldValue) {
    tableData = tableData.filter(
      (role) =>
        role.name.toLowerCase().indexOf(searchFieldValue.toLowerCase()) !== -1
    );
  }

  if (filterRole !== 'all') {
    tableData = tableData.filter(
      (role) => role.name.toLowerCase() === filterRole.toLowerCase()
    );
  }
  return tableData;
}

export const ROLES_TABLE_HEAD = [
  { id: 'emptyCell' },
  { id: 'name', label: 'Name', align: 'left' },
  { id: 'description', label: 'Description', align: 'left' },
  { id: 'active', label: 'Status', align: 'left' },
  { id: 'actionBtns' },
];

export const NEW_ROLE_SCHEMA = object().shape({
  name: string().required('Name is required'),
  description: string().required('Description is required'),
  active: string().required('User status is required'),
});

export const NEW_ROLE_DEFAULT_VALUES = {
  name: '',
  description: '',
  active: '',
  selected_permissions: [],
};

export const STATUS_OPTIONS = ['Active', 'Inactive'];

export const ROLE_MODULES_TABLE_HEAD = [
  { id: 'name', label: 'Name', align: 'left' },
  { id: 'view', label: 'View', align: 'left' },
  { id: 'create', label: 'Create', align: 'left' },
  { id: 'update', label: 'Update', align: 'left' },
  { id: 'delete', label: 'Delete', align: 'left' },
  { id: 'execute', label: 'Execute', align: 'left' },
];
