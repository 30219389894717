import { Navigate, Route, Routes } from 'react-router-dom';
import DashboardLayout from '@/layouts/dashboard';
import AuthGuard from '@/guards/AuthGuard';
import { PATH_AUDIT, auditPath } from './paths';
import AuditList from '../pages/AuditList';

export const AuditRoutes = () => (
  <Routes>
    <Route
      element={
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      }
    >
      <Route index element={<AuditList />} />
      <Route path={PATH_AUDIT.logs} element={<AuditList />} />
      <Route path="*" element={<Navigate to={auditPath('logs')} />} />
    </Route>
  </Routes>
);
