import { BaseObjectEntity } from '@/types';
import { path } from '@/utils/formatLink';

export const ROOTS_JOB_SCHEDULER = '/job-scheduler';

export const PATH_JOB_SCHEDULER: BaseObjectEntity = {
  root: ROOTS_JOB_SCHEDULER,
};

export function jobSchedulerPath(
  pathKey: string,
  key: string = '',
  value: number | string = ''
) {
  return key
    ? path(ROOTS_JOB_SCHEDULER, PATH_JOB_SCHEDULER[pathKey]).replace(
        `:${key}`,
        String(value)
      )
    : path(ROOTS_JOB_SCHEDULER, PATH_JOB_SCHEDULER[pathKey]);
}
