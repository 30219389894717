import { Route, Routes } from 'react-router-dom';
import DashboardLayout from '@/layouts/dashboard';
import AuthGuard from '@/guards/AuthGuard';

import { TasksDashboard } from '../pages';

export const TasksRoutes = () => (
  <Routes>
    <Route
      element={
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      }
    >
      <Route
        index
        element={
          // <RoleBasedGuard roles={['']}>
          <TasksDashboard />
          // </RoleBasedGuard>
        }
      />
    </Route>
  </Routes>
);
