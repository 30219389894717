import { Controller, useFormContext } from 'react-hook-form';
//
import {
  Autocomplete,
  Checkbox,
  IconButton,
  InputAdornment,
  MenuItem,
  TextField,
} from '@mui/material';
//
import Iconify, { TooltipIcon } from '../Iconify';
//
type Props = {
  options: Record<string, string | number | boolean>[];
  name: string;
  label: string;
  disabled?: boolean;
};
export default function RHFMultipleSelectCheckmarks({
  options,
  name,
  label,
  disabled = false,
}: Props) {
  const { control } = useFormContext();

  const uncheckedIcon = <Iconify icon="eva:square-outline" />;
  const checkedIcon = <Iconify icon="eva:checkmark-square-2-outline" />;

  return (
    <>
      <Controller
        name={name}
        control={control}
        render={({ field, fieldState: { error } }) => (
          <Autocomplete
            {...field}
            fullWidth
            disabled={disabled}
            size="small"
            multiple
            id="platform-checkboxes"
            options={options}
            onChange={(_e, newValue) => field.onChange(newValue)}
            disableCloseOnSelect
            getOptionLabel={(option) => (option ? String(option.name) : '')}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            componentsProps={{
              clearIndicator: { style: { display: 'none' } }, // Hide clear button
            }}
            renderOption={(props, option, { selected }) => (
              <MenuItem sx={{ height: 40 }} {...props}>
                <Checkbox
                  icon={uncheckedIcon}
                  checkedIcon={checkedIcon}
                  style={{ marginRight: 8 }}
                  checked={selected}
                />
                {option.name}
              </MenuItem>
            )}
            // render select all / none button
            renderInput={(params) => {
              const isAllSelected =
                (field.value ?? []).length === (options ?? []).length;
              params.InputProps.endAdornment = (
                <>
                  {params.InputProps.endAdornment}
                  <InputAdornment position="end">
                    {
                      <IconButton
                        sx={{
                          position: 'absolute',
                          top: '50%',
                          right: 45,
                          transform: 'translate(50%, -50%)',
                        }}
                        onClick={() =>
                          field.onChange(!isAllSelected ? options : [])
                        }
                      >
                        <TooltipIcon
                          title={!isAllSelected ? 'Select All' : 'Select none'}
                          icon={
                            !isAllSelected
                              ? 'eva:checkmark-square-2-outline'
                              : 'eva:close-square-outline'
                          }
                          color="secondary"
                        />
                      </IconButton>
                    }
                  </InputAdornment>
                </>
              );
              return (
                <TextField
                  {...params}
                  sx={{ boder: '1px solid red' }}
                  label={label}
                  size="small"
                  error={!!error}
                  helperText={error?.message}
                />
              );
            }}
          />
        )}
      />
    </>
  );
}
