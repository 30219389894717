export const COUPONS_TABLE_HEAD = [
  { id: '' },
  { id: 'user_id', label: 'User', align: 'left' },
  { id: 'code', label: 'Code', align: 'left' },
  { id: 'description', label: 'Description', align: 'left' },
  { id: 'value', label: 'Value', align: 'left' },
  { id: 'value_type', label: 'Type', align: 'left' },
  { id: 'usage_limit', label: 'Limit', align: 'left' },
  { id: 'drip_sessions', label: 'Drip Sessions', align: 'left' },
  { id: 'coupon_platform.name', label: 'Platforms', align: 'left' },
  { id: 'start_date', label: 'Start date', align: 'left' },
  { id: 'end_date', label: 'End date', align: 'left' },
  { id: 'created_at', label: 'Created at', align: 'left' },
  { id: 'active', label: 'Status' },
  { id: '3-dot-menu' },
];
