export const PETS = [
  {
    id: 1,
    name: 'Cat',
    value: 'cat',
  },
  {
    id: 2,
    name: 'Dog',
    value: 'dog',
  },
  {
    id: 3,
    name: 'Other',
    value: 'other',
  },
];
