import { object, string } from 'yup';

// ==============================
//      POSTCODE LOOKUP FORM
// ==============================

export const POSTCODE_LOOKUP_DEFAULT_VALUES = {
  postcode: '',
};
export const PostcodeLookupSchema = object().shape({
  postcode: string()
    .min(5)
    .required('Postcode is required, minimum 5 characters.'),
});

// ========================
//     NEW ADDRESS FORM
// ========================
export const NewAddressFormSchema = object().shape({
  line_1: string().required('Address line 1 is required.'),
  town_or_city: string().required('Town/city is required.'),
  postcode: string().required('Postcode is required.'),
});

export const NEW_ADDRESS_FROM_DEFAULT_VALUES = {
  address_name: '',
  line_1: '',
  line_2: '',
  line_3: '',
  line_4: '',
  town_or_city: '',
  postcode: '',
  default_address: true,
};
