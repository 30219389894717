import { SyntheticEvent, useEffect } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
//
import { Box, Skeleton, Stack, Tab, Tabs } from '@mui/material';
import { VBMAccordion } from '..';
// api
import { getFilteredBookingSession } from '@/features/bookings-management/api';
// hooks + utils
import useTabs from '@/hooks/useTabs';
import { useSnackbarMsg } from '@/hooks/useSnackbarMsg';
import { bookingsManagementPath } from '@/features/bookings-management/routes/paths';

export default function VBMBookingSessions() {
  const { bookingId, sessionFilter } = useParams();
  const [searchParams] = useSearchParams();
  const sessionID = searchParams.get('sessionID');
  const { currentTab, onChangeTab, setCurrentTab } = useTabs(sessionFilter);
  const navigate = useNavigate();
  const { errorMsg } = useSnackbarMsg();

  useEffect(() => {
    sessionFilter && setCurrentTab(sessionFilter);
  }, [sessionFilter, setCurrentTab]);

  const handleTabChange = (
    event: SyntheticEvent<Element, Event>,
    newValue: string
  ) => {
    onChangeTab(event, newValue);
    navigate(
      bookingsManagementPath('view', {
        bookingId: String(bookingId),
        bookingTabSid: 'booking-sessions',
        sessionFilter: newValue,
      })
    );
  };

  // BOOKING SESSIONS
  const { data: bookingSessions, isLoading: isBookingSessionsDataLoading } =
    useQuery({
      queryKey: ['bookingSessions', sessionFilter],
      queryFn: () =>
        getFilteredBookingSession(Number(bookingId), String(sessionFilter)),
      onError: (error) => {
        errorMsg(error, `Something went wrong while fetching booking data`);
        navigate(bookingsManagementPath('list'));
      },
      refetchOnWindowFocus: false,
      enabled: !!bookingId,
    });

  const noSessions = bookingSessions && bookingSessions.length === 0;

  const CLEANING_SESSION_TABS = [
    {
      value: 'previous',
      label: 'Previous',
    },
    {
      value: 'today',
      label: 'Today',
    },
    {
      value: 'upcoming',
      label: 'Upcoming',
    },
  ];
  return (
    <Stack spacing={2} sx={{ mt: -2 }}>
      <Stack
        direction="row"
        alignItems="center"
        sx={{ borderBottom: 1, borderColor: 'divider', mb: 1 }}
      >
        <Tabs
          allowScrollButtonsMobile
          variant="scrollable"
          scrollButtons="auto"
          value={currentTab}
          onChange={handleTabChange}
        >
          {CLEANING_SESSION_TABS.map((tab) => (
            <Tab
              key={tab.value}
              value={tab.value}
              label={tab.label}
              disableRipple
              sx={{ width: 120 }}
            />
          ))}
        </Tabs>
      </Stack>

      <Box sx={{ px: 2 }}>
        {noSessions ? (
          <Box
            sx={{
              py: 3,
              textAlign: 'center',
            }}
          >
            There are no sessions available with the following filter:
            <span style={{ fontWeight: 600, paddingLeft: 4 }}>
              {sessionFilter}
            </span>
          </Box>
        ) : (
          <>
            {!bookingSessions || isBookingSessionsDataLoading ? (
              <Stack spacing={1}>
                {[...new Array(5)].map((_, index) => (
                  <Skeleton key={index} variant="rounded" height={50} />
                ))}
              </Stack>
            ) : (
              (bookingSessions || []).map((session) => (
                <VBMAccordion
                  key={session.id}
                  bookingSession={session}
                  isExpanded={Number(sessionID) === session.id}
                />
              ))
            )}
          </>
        )}
      </Box>
    </Stack>
  );
}
