import axios from '@/utils/axios';
import { Settings } from '@/types/settings';

export async function getSettingsData(): Promise<Settings> {
  const {
    data: {
      data: { settings },
    },
  } = await axios.get('/settings');
  return settings;
}
