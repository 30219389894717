import { useSelector } from '@/stores';
//
import { Stack } from '@mui/material';
import { SummaryFieldLabel, SummaryFieldText, SummaryFieldPrice } from '.';
//
import { fCurrency } from '@/utils/formatNumber';
//

export default function SummaryCleaningService() {
  const booking = useSelector((state) => state.booking);
  return (
    <Stack direction="column" gap={2}>
      <SummaryFieldLabel>Cleaning services</SummaryFieldLabel>

      {booking.cleanDetails.hours > 0 && (
        <Stack direction="row" spacing={2} justifyContent="space-between">
          <SummaryFieldText>
            {booking.cleanDetails.hours} hr *{' '}
            {fCurrency(booking.cleanDetails.baseRate)}
          </SummaryFieldText>
          <SummaryFieldPrice>
            {fCurrency(
              booking.cleanDetails.hours * booking.cleanDetails.baseRate
            )}
          </SummaryFieldPrice>
        </Stack>
      )}
    </Stack>
  );
}
