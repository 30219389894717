// mui
import { MenuItem, TextField } from '@mui/material';
// hooks + utils
import useResponsive from '@/hooks/useResponsive';
import { generateArray } from '@/utils/generateArray';
import { consoleError } from '@/utils/consoleError';
// redux
import { dispatch, useSelector } from '@/stores';
import {
  setNumberOfBathrooms,
  setNumberOfBedrooms,
} from '@/features/bookings-management/slices';
// types
import { ServiceFormField } from '@/types';

interface Prop {
  serviceFormField: ServiceFormField;
}

export default function SelectInputField({ serviceFormField }: Prop) {
  const downMd = useResponsive('down', 'md');
  const { numberOfBathrooms, numberOfBedrooms } = useSelector(
    (state) => state.booking.cleanDetails
  );

  const arrayOfOptions = generateArray(
    serviceFormField.min_value,
    serviceFormField.max_value,
    serviceFormField.increment_value
  );

  const handleValueChange = (typeOfRoom: string, rooms: number) => {
    switch (typeOfRoom) {
      case 'bathrooms':
        dispatch(setNumberOfBathrooms(rooms));
        return;
      case 'bedrooms':
        dispatch(setNumberOfBedrooms(rooms));
        return;
      default:
        consoleError('SelectInputField.tsx', 'chandleValueChange()');
        return;
    }
  };

  // ----------------------------------------

  return (
    <TextField
      select
      size="small"
      sx={{ width: downMd ? '100%' : '75%' }}
      defaultValue={1}
      value={
        serviceFormField.ref === 'bedrooms'
          ? numberOfBedrooms
          : numberOfBathrooms
      }
      name={serviceFormField.ref}
      label={serviceFormField.name}
      InputLabelProps={{ shrink: true }}
      SelectProps={{ native: false, sx: { textTransform: 'capitalize' } }}
      onChange={(e) =>
        handleValueChange(serviceFormField.ref, Number(e.target.value))
      }
    >
      {arrayOfOptions.map((option, idx) => (
        <MenuItem key={idx} value={option}>
          {option}
        </MenuItem>
      ))}
    </TextField>
  );
}
