import { useEffect } from 'react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// mui
import { Alert, Button, Stack } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// API
import { updateAddress } from '@/features/users/shared/api';
// hooks + utils
import {
  FormProvider,
  RHFCheckbox,
  RHFTextField,
} from '@/components/hook-form';
import { useSnackbarMsg } from '@/hooks/useSnackbarMsg';
//
import {
  NEW_ADDRESS_FROM_DEFAULT_VALUES,
  NewAddressFormSchema,
} from '@/features/users/shared/utils';
// type
import {
  NewAddressFormValues,
  UserAddress,
} from '@/features/users/shared/types';

interface NewAddressProps {
  onClose: VoidFunction;
  currentAddress: UserAddress | null | undefined;
  queryKey: string[];
}

// --------------------------------------------------

export default function EditAddressFrom({
  onClose,
  currentAddress,
  queryKey,
}: NewAddressProps) {
  const { errorMsg, successMsg } = useSnackbarMsg();
  const queryClient = useQueryClient();

  const newAddressFormMethods = useForm<NewAddressFormValues>({
    resolver: yupResolver(NewAddressFormSchema),
    defaultValues: NEW_ADDRESS_FROM_DEFAULT_VALUES,
  });

  const { handleSubmit: handleSubmitNewAddress, reset: resetNewAddressForm } =
    newAddressFormMethods;

  useEffect(() => {
    currentAddress &&
      resetNewAddressForm({
        line_1: currentAddress?.line_1,
        line_2: currentAddress?.line_2,
        line_3: currentAddress?.line_3,
        line_4: currentAddress?.line_4 ?? '',
        town_or_city: currentAddress?.town_or_city,
        postcode: currentAddress?.postcode,
        default_address: currentAddress?.default,
        address_name: currentAddress?.name,
      });
  }, [currentAddress, resetNewAddressForm]);

  // =============================
  //     NEW/EDIT ADDRESS FORM
  // =============================

  const updateAddressMutation = useMutation({
    // mutate function will make the API call
    mutationFn: ({
      id,
      addressDto,
    }: {
      id: number;
      addressDto: Partial<UserAddress>;
    }) => updateAddress(id, addressDto),
    onSuccess: () => {
      queryClient.invalidateQueries(queryKey);
      successMsg(`Address succesfully updated`);
      onClose();
    },
    onError: (error) => {
      errorMsg(error, `There was an error while updating address`);
    },
  });
  // ==================
  //       SUBMIT
  // ==================

  const submitUpdatedAddress = async (data: NewAddressFormValues) => {
    const addressDto = {
      line_1: data.line_1,
      line_2: data.line_2,
      line_3: data.line_3,
      line_4: data.line_4,
      town_or_city: data.town_or_city,
      postcode: data.postcode,
      name: data.address_name,
      default: data.default_address,
    };

    updateAddressMutation.mutate({
      id: Number(currentAddress?.id),
      addressDto,
    });
  };

  return (
    <>
      {currentAddress ? (
        <FormProvider
          methods={newAddressFormMethods}
          onSubmit={handleSubmitNewAddress(submitUpdatedAddress)}
        >
          <Stack spacing={2} sx={{ p: 1 }}>
            <RHFTextField name="line_1" label="Address line 1" />
            <RHFTextField name="line_2" label="Address line 2" />

            <RHFTextField name="line_3" label="Address line 3" />
            <RHFTextField name="line_4" label="Address line 4" />

            <RHFTextField name="town_or_city" label="Town or city" />
            <RHFTextField name="postcode" label="Postcode" />
            <RHFTextField name="address_name" label="Address' name" />
            <RHFCheckbox
              name="default_address"
              label="Set this as default address"
              sx={{ pl: 0.5 }}
            />

            <Stack direction="row" spacing={2} justifyContent="flex-end">
              <Button color="inherit" variant="outlined" onClick={onClose}>
                Cancel
              </Button>
              <LoadingButton
                type="submit"
                variant="contained"
                size="medium"
                loading={updateAddressMutation.isLoading}
              >
                Update Address
              </LoadingButton>
            </Stack>
          </Stack>
        </FormProvider>
      ) : (
        <Stack direction="column" spacing={2}>
          <Alert severity="error">Selected address is not found.</Alert>

          <Button
            onClick={onClose}
            variant="outlined"
            color="inherit"
            sx={{ alignSelf: 'flex-end', width: '25%' }}
          >
            Cancel
          </Button>
        </Stack>
      )}
    </>
  );
}
