/**
 * Logs an error message to the console with custom formatting.
 *
 * @param {string} componentName - The name of the component where the error occurred.
 * @param {string} [functionName=''] - The name of the function where the error occurred (optional).
 * @param {string} [textColor='black'] - The color of the error message text (optional).
 * @param {string} [textBackground='yellow'] - The background color of the error message (optional).
 * @returns {void}
 */

export function consoleError(
  componentName: string,
  functionName: string = '',
  textColor: string = 'black',
  textBackground: string = 'yellow'
) {
  return console.error(
    `.... !!!! .... \n 
    There was an error here: \n 
    ${componentName} >> %c${functionName}`,
    `color: ${textColor}; background: ${textBackground};`
  );
}
