// @mui
import { TableRow, TableCell, Typography, Button } from '@mui/material';
// types
import { PermissionsObject } from '@/types';
import { CoverType } from '../types';

type Props = {
  row: CoverType;
  permissions?: PermissionsObject;
  onEditRow: VoidFunction;
  onSelectRow: VoidFunction;
  selected: boolean;
};

export default function TableRowData({
  row,
  permissions,
  onEditRow,
  selected,
}: Props) {
  const { name } = row;

  return (
    <TableRow hover selected={selected}>
      <TableCell>
        <Typography variant="subtitle2" noWrap>
          {name}
        </Typography>
      </TableCell>

      <TableCell align="right">
        <>
          {permissions?.canUpdate && (
            <Button
              onClick={() => {
                onEditRow();
              }}
              variant="contained"
            >
              Edit
            </Button>
          )}
        </>
      </TableCell>
    </TableRow>
  );
}
