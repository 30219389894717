import axios from '@/utils/axios';
import { AxiosResponse } from 'axios';
import {
  SettingsFieldsData,
  SettingsFieldsResponseData,
  SettingsResponseData,
  CoverTypeObjectToPost,
  Category,
  CategoryObjectToPost,
  CoverType,
  Product,
  Provider,
  ProviderObjectToPost,
  CoverExtra,
  CoverExtraObjectToPost,
  SettingsResponseCategories,
  EmailTemplateData,
  EmailTemplate,
  SettingsCategory,
  SettingsCategoryData,
  EmailTemplatesData,
  EmailTempateData,
  ActivityObjectToPost,
  ActivityType,
  SettingsFromAPI,
} from '../types';

// API SETTINGS
// ----------------------------------------------------------------------

export async function getSettingsCategories(): Promise<
  SettingsResponseCategories[]
> {
  const {
    data: { data },
  }: SettingsResponseData = await axios.get('/settings/categories');
  return data;
}

export async function getSettingsFields(
  id: number
): Promise<SettingsFieldsData[]> {
  const {
    data: {
      data: { fields },
    },
  }: SettingsFieldsResponseData = await axios.get(
    `/settings/categories/${id}/fields`
  );

  return fields;
}

export async function saveSettingsFields(): Promise<any> {
  const {
    data: { data },
  }: any = await axios.put('/settings/fields/save');

  return data;
}

// ----------------------------------------------------------------------
// Activity types

// GET
export async function getActivityData(): Promise<ActivityType[]> {
  const {
    data: { data },
  } = await axios.get('/activities/types');
  return data;
}

export async function getSingleActivityData(id: number): Promise<ActivityType> {
  const {
    data: { data },
  } = await axios.get(`/activities/types/${id}`);
  return data;
}

// POST
export const postActivityData = async (postData: ActivityObjectToPost) =>
  axios.post('/activities/types', postData);

// PUT
export const updateActivityData = async (
  data: ActivityObjectToPost,
  ID: number
) => axios.put(`/activities/types/${ID}`, data);

// DELETE
export async function deleteActivityData(ID: number): Promise<AxiosResponse> {
  const data = await axios.delete(`/activities/types/${ID}`);

  return data;
}

// API EMAIL TEMPLATES
// ----------------------------------------------------------------------
export async function getEmailTemplates(): Promise<EmailTemplate[]> {
  const {
    data: { data },
  }: EmailTemplatesData = await axios.get('/email-templates');

  return data;
}

export async function getEmailTemplate(id: number): Promise<EmailTemplate> {
  const {
    data: { data },
  }: EmailTemplateData = await axios.get(`/email-templates/${id}`);
  return data;
}
export async function getSettingCategories(): Promise<SettingsCategory[]> {
  const {
    data: { data },
  }: SettingsCategoryData = await axios.get('/email-templates/categories');

  return data;
}

export async function postEmailTemplate(
  emailTemplate: EmailTempateData
): Promise<EmailTemplate> {
  const {
    data: { data },
  }: EmailTemplateData = await axios.post('/email-templates', emailTemplate);

  return data;
}

export async function putEmailTemplate(
  emailTemplate: EmailTempateData,
  id: number
): Promise<EmailTemplate> {
  const {
    data: { data },
  }: EmailTemplateData = await axios.put(
    `/email-templates/${id}`,
    emailTemplate
  );

  return data;
}

export async function deleteEmailTemplate(id: number): Promise<EmailTemplate> {
  const {
    data: { data },
  }: EmailTemplateData = await axios.delete(`/email-templates/${id}`);

  return data;
}

// ----------------------------------------------------------------------
// SETTINGS
export async function getSettingsData(): Promise<SettingsFromAPI> {
  const {
    data: {
      data: { settings },
    },
  } = await axios.get('/settings');
  return settings;
}

// ----------------------------------------------------------------------
// Categories

// GET
export async function getCategoryData(): Promise<Category[]> {
  const {
    data: { data },
  } = await axios.get('/products/categories');
  return data;
}

export async function getSingleCategoryData(
  categoryID: number
): Promise<Category> {
  const {
    data: { data },
  } = await axios.get(`/products/categories/${categoryID}`);
  return data;
}

// POST
export const postCategoryData = async (categoryData: CategoryObjectToPost) =>
  axios.post('/products/categories', categoryData);

// PUT
export const updateCategoryData = async (
  data: CategoryObjectToPost,
  categoryID: number
) => axios.put(`/products/categories/${categoryID}`, data);

// DELETE
export async function deleteCategory(
  categoryID: number
): Promise<AxiosResponse> {
  const data = await axios.delete(`/products/categories/${categoryID}`);

  return data;
}
// ----------------------------------------------------------------------
// Products

export async function getProductData(): Promise<Product[]> {
  const {
    data: { data },
  } = await axios.get('/products');
  return data;
}
export async function getSingleProductData(
  productID: number
): Promise<Product> {
  const {
    data: { data },
  } = await axios.get(`/products/${productID}`);
  return data;
}

export async function deleteProduct(productID: number): Promise<AxiosResponse> {
  const data = await axios.delete(`/products/${productID}`);

  return data;
}
// ----------------------------------------------------------------------
// Providers

export async function getProviderData(): Promise<Provider[]> {
  const {
    data: { data },
  } = await axios.get('/providers');
  return data;
}

export async function getSingleProviderData(ID: number): Promise<Provider> {
  const {
    data: { data },
  } = await axios.get(`/providers/${ID}`);
  return data;
}

export const postProviderData = async (postData: ProviderObjectToPost) =>
  axios.post('/providers', postData);

export const updateProviderData = async (
  data: ProviderObjectToPost,
  ID: number
) => axios.put(`/providers/${ID}`, data);

export async function deleteProviderData(ID: number): Promise<AxiosResponse> {
  const data = await axios.delete(`/providers/${ID}`);

  return data;
}
// ----------------------------------------------------------------------
// Cover level types

export async function getCoverLevelData(): Promise<CoverType[]> {
  const {
    data: { data },
  } = await axios.get('/products/cover-extra-types');
  return data;
}

export async function getSingleCoverLevelData(id: number): Promise<CoverType> {
  const {
    data: { data },
  } = await axios.get(`/products/cover-extra-types/${id}`);
  return data;
}

export const postCoverLevelData = async (postData: CoverTypeObjectToPost) =>
  axios.post('/products/cover-extra-types', postData);

export const updateCoverLevelData = async (
  data: CoverTypeObjectToPost,
  ID: number
) => axios.put(`/products/cover-extra-types/${ID}`, data);

export async function deleteCoverLevelData(ID: number): Promise<AxiosResponse> {
  const data = await axios.delete(`/products/cover-extra-types/${ID}`);

  return data;
}
// ----------------------------------------------------------------------
// Cover level extras

export async function getCoverExtrasData(): Promise<CoverExtra[]> {
  const {
    data: { data },
  } = await axios.get('/products/cover-extras');
  return data;
}
export async function getSingleCoverExtraData(id: number): Promise<CoverExtra> {
  const {
    data: { data },
  } = await axios.get(`/products/cover-extras/${id}`);
  return data;
}

export const postCoverExtraData = async (postData: CoverExtraObjectToPost) =>
  axios.post('/products/cover-extras', postData);

export const updateCoverExtraData = async (
  data: CoverExtraObjectToPost,
  ID: number
) => axios.put(`/products/cover-extras/${ID}`, data);

export async function deleteCoverExtraData(ID: number): Promise<AxiosResponse> {
  const data = await axios.delete(`/products/cover-extras/${ID}`);

  return data;
}
