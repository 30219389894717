import { Route, Routes } from 'react-router-dom';
import DashboardLayout from '@/layouts/dashboard';
import AuthGuard from '@/guards/AuthGuard';
import { PATH_ACTIVITIES } from './paths';
import { ActivityCreate, ActivityList } from '../pages';

export const ActivitiesRoutes = () => (
  <Routes>
    <Route
      element={
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      }
    >
      <Route index element={<ActivityList />} />
      <Route path={PATH_ACTIVITIES.new} element={<ActivityCreate />} />
      <Route path={PATH_ACTIVITIES.edit} element={<ActivityCreate />} />
    </Route>
  </Routes>
);
