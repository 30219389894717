import { useState, useEffect, useMemo } from 'react';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';
import { isEmpty } from 'lodash';
// api
import { postCategoryData, updateCategoryData } from '../api';
// form
import { object, string } from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import { Stack, Grid, Box, Card, Container, Skeleton } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
// hooks
import useSettings from '@/hooks/useSettings';
// components
import { Page } from '@/components';
import { FormProvider, RHFSelect, RHFTextField } from '@/components/hook-form';
// types
import type { Category } from '../types';
// paths
import { PATH_SETTINGS } from '../routes/paths';

// ----------------------------------------------------------------------
const STATUS_OPTIONS = ['Active', 'Inactive'];

// PROP TYPES
interface FormValuesProps {
  name: string;
  description: string;
  active: string;
}

type Props = {
  currentCategory?: Category;
  categoryID?: number;
};

export default function CreateEditCategory({ currentCategory }: Props) {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const { enqueueSnackbar } = useSnackbar();

  // ----------------------------------------------------------------------
  // REACT HOOK FORM

  const NewCategorySchema = object().shape({
    name: string().required('Name is required'),
    description: string().required('Description is required'),
    active: string().required('Status is required'),
  });

  const defaultValues = useMemo(
    () => ({
      name: currentCategory?.name || '',
      description: currentCategory?.description || '',
      active: currentCategory?.active
        ? 'Active'
        : isEmpty(currentCategory)
        ? 'Active'
        : 'Inactive',
    }),

    [currentCategory]
  );

  const methods = useForm<FormValuesProps>({
    resolver: yupResolver(NewCategorySchema),
    defaultValues,
  });

  const {
    reset,

    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  useEffect(() => {
    if (!isEmpty(currentCategory)) {
      reset(defaultValues);
    }
    setIsLoading(false);
  }, [currentCategory, reset, defaultValues]);

  const onSubmit = async (data: FormValuesProps) => {
    try {
      const categoryData = {
        name: data.name,
        description: data.description,
        active: data.active === 'Active' ? true : false,
      };

      !isEmpty(currentCategory)
        ? await updateCategoryData(categoryData, currentCategory?.id)
        : await postCategoryData(categoryData);
      enqueueSnackbar(
        !isEmpty(currentCategory)
          ? 'Category successfully updated!'
          : 'Category successfully created!'
      );
      navigate(PATH_SETTINGS.categories);
    } catch (error) {
      console.error(error);
      enqueueSnackbar('Something went wrong', { variant: 'error' });
    }
  };

  // ----------------------------------------------------------------------
  // Skeleton State

  const { themeStretch } = useSettings();

  // ----------------------------------------------------------------------

  return (
    <Page title="Product Categories">
      <Container
        maxWidth={themeStretch ? false : 'lg'}
        style={{ position: 'relative' }}
      >
        <Card>
          <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
            <Grid item xs={12}>
              <Card sx={{ p: 3 }}>
                <Box
                  sx={{
                    display: 'grid',
                    columnGap: 2,
                    rowGap: 3,
                    gridTemplateColumns: {
                      xs: 'repeat(1, 1fr)',
                      sm: 'repeat(1, 1fr)',
                    },
                  }}
                >
                  {isLoading ? (
                    <Box pl={2} pt={3}>
                      <Skeleton sx={{ width: 0.98 }} height={80} />
                      <Skeleton sx={{ width: 0.98 }} height={80} />
                    </Box>
                  ) : (
                    <>
                      <RHFTextField
                        name="name"
                        label="Category name"
                        fullWidth
                      />
                      <RHFTextField
                        name="description"
                        label="Category description"
                        fullWidth
                      />

                      <RHFSelect
                        name="active"
                        label="Status"
                        placeholder="Status"
                      >
                        {STATUS_OPTIONS.map((option) => (
                          <option key={option} value={option}>
                            {option}
                          </option>
                        ))}
                      </RHFSelect>
                    </>
                  )}
                </Box>

                <Stack alignItems="flex-end" sx={{ mt: 3 }}>
                  {!isLoading && (
                    <LoadingButton
                      type="submit"
                      variant="contained"
                      loading={isSubmitting}
                    >
                      {isEmpty(currentCategory)
                        ? 'Create Category'
                        : 'Save Changes'}
                    </LoadingButton>
                  )}
                </Stack>
              </Card>
            </Grid>
          </FormProvider>
        </Card>
      </Container>
    </Page>
  );
}
