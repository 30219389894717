import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { Link, Typography, ListItemText, ListItemButton } from '@mui/material';

import Iconify from '@/components/Iconify';
import { fToNow } from '@/utils/formatTime';
import { fLinkBuild } from '@/utils/formatLink';
import { NotificationObjectProps, NotificationProps } from '../types';
import { getNotificationUpdateRequest } from '../api';

function RenderContent(
  {
    title,
    description,
    notification_type: {
      entity_type: { dashboard_route },
    },
    record_id,
  }: NotificationProps,
  id: number,
  seen: boolean
) {
  const navigate = useNavigate();
  const handleOnClick = useCallback(async () => {
    try {
      if (!seen) await getNotificationUpdateRequest(id);
      navigate(fLinkBuild(dashboard_route, record_id));
    } catch (err) {
      console.error(err.message);
    }
  }, [navigate, dashboard_route, id, record_id, seen]);

  return (
    <Link underline="none" onClick={handleOnClick}>
      <Typography variant="subtitle2" sx={{ color: 'text.primary' }}>
        {title}&nbsp;
        <Typography
          component="span"
          variant="body2"
          sx={{ color: 'text.secondary' }}
          dangerouslySetInnerHTML={{
            __html: description,
          }}
        />
      </Typography>
    </Link>
  );
}
type NotificationItemProps = {
  popover?: boolean;
  notificationObject: NotificationObjectProps;
};

export default function NotificationItem({
  notificationObject,
  popover = false,
}: NotificationItemProps) {
  const { notification, id, seen } = notificationObject;
  return (
    <ListItemButton
      sx={{
        py: 1.6,
        px: popover ? 2.4 : 3.6,
        mt: '1px',
        '&:hover': {
          ...(!popover && { bgcolor: 'transparent' }),
        },
      }}
    >
      <ListItemText
        primary={RenderContent(notification, id, seen)}
        secondary={
          <Typography
            variant="caption"
            sx={{
              mt: 0.5,
              display: 'flex',
              alignItems: 'center',
              color: 'text.disabled',
            }}
          >
            <Iconify
              icon="eva:clock-outline"
              sx={{ mr: 0.5, width: 16, height: 16 }}
            />
            {fToNow(notification.created_at)}
          </Typography>
        }
      />
    </ListItemButton>
  );
}
