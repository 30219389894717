import { useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
// @mui
import {
  Box,
  Button,
  Card,
  Container,
  Divider,
  FormControlLabel,
  Switch,
  Tab,
  Tabs,
  Table,
  TableBody,
  TableContainer,
  TablePagination,
} from '@mui/material';
// components
import { HeaderBreadcrumbs, Iconify, Page, Scrollbar } from '@/components';
import {
  TableEmptyRows,
  TableHeadCustom,
  TableNoData,
} from '@/components/table';
import { CouponsTableToolbar, CouponTableRow } from '../components';
import { SkeletonLoadingTable } from '@/components/skeleton';
// hooks
import useTabs from '@/hooks/useTabs';
import useSettings from '@/hooks/useSettings';
import useTable, { getComparator, emptyRows } from '@/hooks/useTable';
import { useGetAccess } from '@/hooks/useAccess';
import { useSnackbarMsg } from '@/hooks/useSnackbarMsg';
// API
import { getSettingsData } from '@/features/app/api';
import { getUsersByType } from '@/features/users/shared/api';
import { deleteCoupon, getCoupons } from '../api';
// utils
import { applySortFilterCoupons, COUPONS_TABLE_HEAD } from '../utils';
import { couponsPath, ROOTS_COUPONS } from '../routes/paths';
import { PATH_USER } from '@/features/app';
// types
import { consoleError } from '@/utils/consoleError';
import { Coupon } from '../types';

// ----------------------------------------------------------------------

export default function CouponList() {
  const {
    dense,
    page,
    order,
    orderBy,
    rowsPerPage,
    setPage,
    //
    selected,
    setSelected,
    //
    onSort,
    onChangeDense,
    onChangePage,
    onChangeRowsPerPage,
  } = useTable();

  const { themeStretch } = useSettings();
  const { canDelete, canUpdate, canCreate } = useGetAccess('coupons');
  const { errorMsg, successMsg } = useSnackbarMsg();
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const [searchFieldValue, setSearchFieldValue] = useState('');
  const [filterUserId, setFilterUserId] = useState<number>(0);
  const [filterPlatform, setFilterPlatform] = useState<number>(0);
  const [filterStartDate, setFilterStartDate] = useState<string | null>(null);
  const [filterEndDate, setFilterEndDate] = useState<string | null>(null);

  const resetFilters = () => {
    setSearchFieldValue('');
    setFilterUserId(0);
    setFilterPlatform(0);
    setFilterStartDate(null);
    setFilterEndDate(null);
  };

  // SETTINGS
  const { data: settingsData } = useQuery({
    queryKey: ['settings'],
    queryFn: () => getSettingsData(),
    onError: (error) => {
      errorMsg(error, `Something went wrong while fetching settings data `);
    },
    staleTime: 24 * 60 * 60 * 1000, // update every 24 hours
    cacheTime: 24 * 60 * 60 * 1000, // update every 24 hours
  });

  // CLIENTS LIST
  const { data: clientsList } = useQuery({
    queryKey: ['clientsList'],
    queryFn: () => getUsersByType(1),
    onError: (error) => errorMsg(error, 'Error fetching cleaners'),
  });

  // =================
  //      QUERIES
  // =================

  const { data: coupons, isLoading: isCouponsDataLoading } = useQuery({
    queryKey: ['coupons'],
    queryFn: () => getCoupons(),
    onError: (error) => {
      errorMsg(error, `Something went wrong while fetching coupons data`);
    },
  });

  // ----------------------------------------------------------------------
  // FILTER DELETE EDIT

  const { currentTab: filterStatus, onChangeTab: onChangeFilterStatus } =
    useTabs('active');

  // FILTER
  const handleSearchField = (filterName: string) => {
    setSearchFieldValue(filterName);
    setPage(0);
  };

  const handleFilterDropdown = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    switch (name) {
      case 'user':
        setFilterUserId(Number(value));
        break;
      case 'platform':
        setFilterPlatform(Number(value));
        break;
      case 'start_date':
        setFilterStartDate(value);
        break;
      case 'end_date':
        setFilterEndDate(value);
        break;
      default:
        consoleError('CouponsList', 'handleFilterDropdown()');
    }
    setSelected([]);
    setPage(0);
  };

  const handleDatepickerChange = (date: string | null, date_type: string) => {
    switch (date_type) {
      case 'start':
        setFilterStartDate(date);
        break;
      case 'end':
        setFilterEndDate(date);
        break;
      default:
        consoleError('CouponsList', 'handleDatepickerChange()');
    }
    setSelected([]);
    setPage(0);
  };

  const deleteCouponMutation = useMutation({
    mutationFn: (id: number) => deleteCoupon(id),
    onSuccess: (_apiResponse, variables) => {
      queryClient.setQueryData(['coupons'], (old: Coupon[] = []) =>
        old.filter((coupon) => coupon.id !== variables)
      );
      queryClient.invalidateQueries(['coupons']);
      setSelected([]);
      setPage(0);
      successMsg(`Coupon succesfully deleted`);
    },
    onError: (error) => {
      errorMsg(error, `There was an error while deleting coupon`);
    },
  });

  const handleDeleteRow = async (id: number) => deleteCouponMutation.mutate(id);

  const handleEditRow = (id: number) =>
    navigate(couponsPath('edit', { couponId: String(id) }));

  const dataFiltered = applySortFilterCoupons({
    tableData: coupons ?? [],
    comparator: getComparator(order, orderBy),
    searchFieldValue,
    filterUserId,
    filterStatus,
    filterPlatform,
    filterStartDate,
    filterEndDate,
  });

  const denseHeight = dense ? 52 : 72;

  const isNotFound =
    (!dataFiltered.length && !!searchFieldValue) ||
    (!dataFiltered.length && !!filterStatus);

  const showSkeleton = !coupons || isCouponsDataLoading;

  // ----------------------------------------------------------------------

  return (
    <Page title="Coupons: List">
      <Container maxWidth={themeStretch ? false : 'lg'}>
        <HeaderBreadcrumbs
          heading="Coupons List"
          links={[
            { name: 'Dashboard', href: PATH_USER.root },
            { name: 'Coupons', href: ROOTS_COUPONS },

            { name: 'List' },
          ]}
          action={
            canCreate && (
              <Button
                variant="contained"
                component={RouterLink}
                to={couponsPath('new')}
                startIcon={<Iconify icon={'eva:plus-fill'} />}
                disabled={showSkeleton}
              >
                New Coupon
              </Button>
            )
          }
        />

        <Card>
          {showSkeleton ? (
            <SkeletonLoadingTable />
          ) : (
            <>
              <Tabs
                allowScrollButtonsMobile
                variant="scrollable"
                scrollButtons="auto"
                value={filterStatus}
                onChange={onChangeFilterStatus}
                sx={{ px: 2, bgcolor: 'background.neutral' }}
              >
                {[
                  { id: 1, value: 'active', name: 'Active' },
                  { id: 2, value: 'inactive', name: 'Inactive' },
                  { id: 0, value: '100', name: 'All' },
                ].map((tab) => (
                  <Tab
                    disableRipple
                    key={tab.id}
                    label={tab.name}
                    value={tab.value}
                  />
                ))}
              </Tabs>

              <Divider />

              <CouponsTableToolbar
                filterUser={filterUserId}
                filterPlatform={filterPlatform}
                filterSearchBar={searchFieldValue}
                filterStartDate={filterStartDate}
                filterEndDate={filterEndDate}
                //
                handleFilterDropdown={handleFilterDropdown}
                handleSearchField={handleSearchField}
                handleDatepickerChange={handleDatepickerChange}
                resetFilters={resetFilters}
                //
                settingsData={settingsData}
                clientsList={clientsList}
              />

              <Scrollbar>
                <TableContainer sx={{ minWidth: 800, position: 'relative' }}>
                  <Table size={dense ? 'small' : 'medium'}>
                    <TableHeadCustom
                      sx={{ pt: 1 }}
                      order={order}
                      orderBy={orderBy}
                      headLabel={COUPONS_TABLE_HEAD}
                      rowCount={dataFiltered.length}
                      numSelected={selected.length}
                      onSort={onSort}
                    />
                    <TableBody>
                      {dataFiltered
                        .slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                        .map((coupon) => (
                          <CouponTableRow
                            key={coupon.id}
                            row={coupon}
                            onDeleteRow={() => handleDeleteRow(coupon.id)}
                            onEditRow={() => handleEditRow(coupon.id)}
                            canDelete={canDelete}
                            canUpdate={canUpdate}
                          />
                        ))}

                      <TableEmptyRows
                        height={denseHeight}
                        emptyRows={emptyRows(
                          page,
                          rowsPerPage,
                          dataFiltered.length
                        )}
                      />

                      <TableNoData
                        isNotFound={isNotFound}
                        title="No coupon found"
                      />
                    </TableBody>
                  </Table>
                </TableContainer>
              </Scrollbar>

              <Box sx={{ position: 'relative' }}>
                <TablePagination
                  rowsPerPageOptions={[5, 10, 25]}
                  component="div"
                  count={dataFiltered.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={onChangePage}
                  onRowsPerPageChange={onChangeRowsPerPage}
                />

                <FormControlLabel
                  control={<Switch checked={dense} onChange={onChangeDense} />}
                  label="Dense"
                  sx={{ px: 3, py: 1.5, top: 0, position: { md: 'absolute' } }}
                />
              </Box>
            </>
          )}
        </Card>
      </Container>
    </Page>
  );
}
