import { useState } from 'react';
import { useSearchParams, Link as RouterLink } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
//
import {
  Autocomplete,
  Avatar,
  Chip,
  MenuItem,
  Skeleton,
  Stack,
  TextField,
  Typography,
  alpha,
} from '@mui/material';
import {
  VBMActionButtons,
  VBMCardTitle,
  VBMCardWrapper,
  VBMEditButton,
} from '..';
// API
import { getProviders } from '@/features/users/shared/api';
// hooks + utils
import { useSnackbarMsg } from '@/hooks/useSnackbarMsg';
import usePostBookingSessionUpdate from '@/features/bookings-management/hooks/usePostBookingSessionUpdate';
import { providersPath } from '@/features/users/providers';
// types
import { BookingUser } from '@/features/bookings-management/types';

type VBMCleanerProps = {
  selectedOption: BookingUser;
  editable?: boolean;
};

export default function VBMCleaner({
  selectedOption,
  editable = false,
}: VBMCleanerProps) {
  const [isEdit, setIsEdit] = useState(false);
  const [selectedCleanerId, setSelectedCleanerId] = useState<number | null>(
    null
  );
  const [searchParams] = useSearchParams();
  const sessionID = searchParams.get('sessionID');
  const { errorMsg } = useSnackbarMsg();

  const { sessionUpdateMutation } = usePostBookingSessionUpdate({
    onSuccessFn: () => setIsEdit(false),
    successText: `Cleaner updated`,
  });

  const handleToggleEdit = (boolean: boolean) => setIsEdit(boolean);

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    sessionUpdateMutation.mutate({
      bookingSessionId: Number(sessionID),
      bookingSessionDto: {
        booking_cleaners: selectedCleanerId ? [Number(selectedCleanerId)] : [],
      },
    });
  };

  // CLEANERS
  const { data: providers, isLoading: isProvidersDataLoading } = useQuery({
    queryKey: ['providers'],
    queryFn: getProviders,
    onError: (error) => {
      errorMsg(error, `Something went wrong while fetching providers`);
    },
    refetchOnWindowFocus: false,
  });

  const renderCleaner = () =>
    selectedOption ? (
      <Chip
        component={RouterLink}
        to={providersPath('edit', {
          userId: String(selectedOption.id ?? 0),
          tabSid: 'details',
        })}
        avatar={
          <Avatar
            alt={`${selectedOption.first_name ?? 'N/A'} ${
              selectedOption.last_name ?? 'N/A'
            }`}
            src={selectedOption.profile_image}
          />
        }
        label={`${selectedOption.first_name ?? 'N/A'} ${
          selectedOption.last_name ?? 'N/A'
        }`}
        sx={{ cursor: 'pointer' }}
      />
    ) : (
      <Typography variant="body2" fontStyle="italic">
        No cleaner assigned
      </Typography>
    );

  return (
    <>
      <VBMCardWrapper>
        {!isEdit && editable && (
          <VBMEditButton onClickButton={() => handleToggleEdit(true)} />
        )}

        <VBMCardTitle title="Cleaner" sx={{ mb: 1 }} />

        {isEdit ? (
          <form onSubmit={handleSubmit}>
            {!isProvidersDataLoading ? (
              <Stack spacing={2}>
                <Autocomplete
                  fullWidth
                  defaultValue={selectedOption ? selectedOption : undefined}
                  filterSelectedOptions
                  options={providers ?? []}
                  onChange={(_e, newValue) =>
                    setSelectedCleanerId(newValue?.id ?? null)
                  }
                  noOptionsText="No such cleaner"
                  getOptionLabel={(option: any) =>
                    `${option.first_name ?? 'N/A'} ${option.last_name ?? 'N/A'}`
                  }
                  renderOption={(props, option) => (
                    <MenuItem {...props} key={option.id} value={option.id}>
                      <Chip
                        avatar={
                          <Avatar
                            alt={`${option.first_name ?? 'N/A'} ${
                              option.last_name ?? 'N/A'
                            }`}
                            src={option.profile_image}
                          />
                        }
                        label={`${option.first_name ?? 'N/A'} ${
                          option.last_name ?? 'N/A'
                        }`}
                      />
                    </MenuItem>
                  )}
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Search cleaner"
                      placeholder=""
                      fullWidth
                      autoFocus
                      size="small"
                    />
                  )}
                />

                <VBMActionButtons
                  onClickCancel={() => handleToggleEdit(false)}
                  isSubmitting={sessionUpdateMutation.isLoading}
                  disabled={isProvidersDataLoading}
                />
              </Stack>
            ) : (
              <Skeleton
                height={50}
                sx={{
                  bgcolor: (theme) => alpha(theme.palette.primary.main, 0.1),
                }}
              />
            )}
          </form>
        ) : (
          <>
            {sessionUpdateMutation.isLoading ? (
              <Skeleton height={50} />
            ) : (
              renderCleaner()
            )}
          </>
        )}
      </VBMCardWrapper>
    </>
  );
}
