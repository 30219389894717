import { object, ref, string } from 'yup';
import { ResetFormValuesProps } from '../types';

export const LOGIN_FORM_SCHEMA = object().shape({
  email: string()
    .email('Email must be a valid email address')
    .required('Email is required'),
  password: string().required('Password is required'),
});

export const LOGIN_FORM_DEFAULT_VALUES = {
  email: '',
  password: '',
};

export const NEW_PASSWORD_SCHEMA = object().shape({
  new_password: string()
    .min(6, 'Password must be at least 6 characters')
    .required('Password is required'),
  confirm_password: string()
    .required('Confirm password is required')
    .oneOf([ref('new_password'), null], 'Passwords must match'),
});

export const NEW_PWD_FORM_DEFAULT_VALUES = {
  new_password: '',
  confirm_password: '',
};

export const VERIFY_CODE_SCHEMA = object().shape({
  code1: string().required('Code is required'),
  code2: string().required('Code is required'),
  code3: string().required('Code is required'),
  code4: string().required('Code is required'),
  code5: string().required('Code is required'),
  code6: string().required('Code is required'),
});

export const VERIFY_CODE_FORM_DEFAULT_VALUES = {
  code1: '',
  code2: '',
  code3: '',
  code4: '',
  code5: '',
  code6: '',
};

export const RESET_PASSWORD_SCHEMA = object().shape({
  email: string()
    .email('Email must be a valid email address')
    .required('Email is required'),
});

export type VerificationCodeInput = string | ResetFormValuesProps;
export const constructVerificationCode = (
  input: VerificationCodeInput
): string => {
  // Check if the input is a string, return it as-is if so
  if (typeof input === 'string') {
    return input;
  }

  // Otherwise, assume it's a ResetFormValuesProps object and reconstruct the code
  return Object.values(input).join('');
};
