import { upperFirst } from 'lodash';
//
import { TableRow, TableCell } from '@mui/material';
import Label from '@/components/Label';
//
import { fDate } from '@/utils/formatTime';
//
import { Wallet } from '@/features/users/shared/types';

type WalletTableRowProps = {
  row: Wallet;
};

export default function ClientWalletTableRow({ row }: WalletTableRowProps) {
  const {
    name,
    brand,
    exp_month,
    exp_year,
    default: isDefaultCard,
    last_used,
  } = row;

  return (
    <TableRow hover sx={{ tableLayout: 'fixed' }}>
      <TableCell width="2%" />
      <TableCell
        align="left"
        sx={{ letterSpacing: 1, fontWeight: 500 }}
        width={'38%'}
      >
        {name}
        {isDefaultCard && (
          <Label color="info" sx={{ ml: 0.5 }}>
            Default
          </Label>
        )}
      </TableCell>
      <TableCell align="left" width={'20%'}>
        {upperFirst(brand)}
      </TableCell>
      <TableCell
        align="left"
        width={'20%'}
      >{`${exp_month}/${exp_year}`}</TableCell>
      <TableCell align="left" width={'20%'}>
        {last_used ? fDate(last_used) : 'N/A'}
      </TableCell>
    </TableRow>
  );
}
