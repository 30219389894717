import { SyntheticEvent, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
// mui
import { Box, Container, Tab, Tabs } from '@mui/material';
//
import { Page, HeaderBreadcrumbs } from '@/components';
import { UserDetails } from '../components';
// hooks + utils
import useTabs from '@/hooks/useTabs';
import useSettings from '@/hooks/useSettings';
//
import { PATH_USER } from '@/features/app';
import { PATH_USER_MANAGEMENT, userPath } from '../routes/paths';
// types
import { capitalCase } from 'change-case';

// ----------------------------------------------------------------------

export default function UserCreateEdit() {
  const { themeStretch } = useSettings();
  const { userId, tabSid } = useParams();
  const { currentTab, onChangeTab, setCurrentTab } = useTabs('details');
  const navigate = useNavigate();

  useEffect(() => {
    tabSid && setCurrentTab(tabSid);
  }, [tabSid, setCurrentTab]);

  const handleTabChange = (
    event: SyntheticEvent<Element, Event>,
    newValue: string
  ) => {
    onChangeTab(event, newValue);
    navigate(userPath('edit', { userId: String(userId), tabSid: newValue }));
  };

  const USER_PROFILE_TABS = [
    {
      value: 'details',
      component: <UserDetails />,
      visible: true,
    },
  ];

  return (
    <Page title="Dashboard Users">
      <Container maxWidth={themeStretch ? false : 'lg'}>
        <HeaderBreadcrumbs
          heading={!userId ? 'Add new user' : 'Edit user'}
          links={[
            { name: 'Dashboard', href: PATH_USER.root },
            { name: 'User', href: PATH_USER_MANAGEMENT.root },
            { name: !userId ? 'New user' : 'Edit user' },
          ]}
        />
        <Tabs
          allowScrollButtonsMobile
          variant="scrollable"
          scrollButtons="auto"
          value={currentTab}
          onChange={handleTabChange}
          sx={{ mb: 1 }}
          aria-label="profile tabs"
        >
          {USER_PROFILE_TABS.map(
            (tab) =>
              tab.visible && (
                <Tab
                  disableRipple
                  key={tab.value}
                  value={tab.value}
                  label={capitalCase(tab.value)}
                />
              )
          )}
        </Tabs>

        {USER_PROFILE_TABS.map(
          (tab) =>
            tab.value === currentTab && (
              <Box key={tab.value} sx={{ width: '100%' }}>
                {tab.component}
              </Box>
            )
        )}
      </Container>
    </Page>
  );
}
