import { tableSortFilter } from '@/utils/arrayProcessing';
import { User } from '@/features/users/shared/types';

export const CLIENTS_TABLE_HEAD = [
  { id: 'emptyCell' },
  { id: 'first_name', label: 'Name', align: 'left' },
  { id: 'email', label: 'Email', align: 'left' },
  { id: 'phone', label: 'Phone', align: 'left' },
  { id: 'is_chat_user', label: 'Chat', align: 'left' },
  { id: 'user_statuses.display', label: 'Status', align: 'left' },
  { id: 'actionBtns' },
];

export function applySortFilterClients({
  tableData,
  comparator,
  filterName,
  filterStatus,
}: {
  tableData: User[];
  comparator: (a: any, b: any) => number;
  filterName: string;
  filterStatus: string;
}) {
  tableData = tableSortFilter(tableData, comparator);

  if (filterName) {
    tableData = tableData.filter((user) => {
      const name = `${user.first_name} ${user.last_name}`;
      return (
        name.toLowerCase().indexOf(filterName.toLowerCase()) !== -1 ||
        user.email.toLowerCase().indexOf(filterName.toLowerCase()) !== -1 ||
        (user.phone ?? '').toLowerCase().indexOf(filterName.toLowerCase()) !==
          -1
      );
    });
  }
  if (filterStatus !== 'all') {
    tableData = tableData.filter((user) =>
      !user.user_statuses
        ? false
        : (user.user_statuses.display ?? '').toLowerCase() === filterStatus
    );
  }

  return tableData;
}
