import { string, number, object } from 'yup';
import { tableSortFilter } from '@/utils/arrayProcessing';
import { UserCustomPrice } from '../../shared/types';

export function applySortFilterProviderPrices({
  tableData,
  comparator,
}: {
  tableData: UserCustomPrice[];
  comparator: (a: any, b: any) => number;
}) {
  tableData = tableSortFilter(tableData, comparator);

  return tableData;
}

export const PROVIDER_CUSTOM_PRICES_TABLE_HEAD = [
  { id: 'emptyCell', label: '', align: 'left' },
  { id: 'cities.name', label: 'City', align: 'left' },
  { id: 'service_variant.service.name', label: 'Service Type', align: 'left' },
  { id: 'service_variant.name', label: 'Service Variant', align: 'left' },
  { id: 'price', label: 'Price', align: 'left' },
  { id: 'sun_bank_holiday_extra', label: 'Sunday/BH extra', align: 'left' },
  { id: 'start_date', label: 'Start date', align: 'left' },
  { id: 'end_date', label: 'End date', align: 'left' },
  { id: 'locked', label: 'Locked', align: 'left' },
  { id: '3dotMenu', label: '', align: 'left' },
];

export const PROVIDER_CUSTOM_PRICE_DEFAULT_VALUES = {
  city_id: '',
  service_type_id: '',
  service_variant_id: '',
  price: '',
  sun_bank_holiday_extra: '',
  start_date: '',
  end_date: '',
};

export const NEW_PROVIDER_CUSTOM_PRICE_SCHEMA = object().shape({
  city_id: string().required('City is required'),
  service_variant_id: string().required('Service Variant is required'),
  price: number().typeError('Price is required').required('Price is required'),
  sun_bank_holiday_extra: number()
    .typeError('Extra price is required')
    .required('Extra price is required'),
  start_date: string().required('Start date is required'),
  end_date: string()
    .required('End Date is required')
    .test(
      'is-greater',
      'End Date must be greater than Start Date',
      function (end_date) {
        const { start_date } = this.parent;
        return end_date && start_date
          ? new Date(end_date) > new Date(start_date)
          : true;
      }
    ),
});
