// @mui
import {
  DragHandle,
  KeyboardArrowDown,
  KeyboardArrowUp,
  KeyboardDoubleArrowDown,
  KeyboardDoubleArrowUp,
} from '@mui/icons-material';
import { enUS, frFR, zhCN, viVN, arSD } from '@mui/material/locale';
// components
import { SettingsValueProps } from './components/settings/type';
import { BaseObjectEntityForArray } from './types';

// API
export const SERVICE_API_BASE =
  process.env.REACT_APP_SERVICE_API_BASE || 'https://api.dev.getsetclean.co.uk';
export const SERVICE_API_VERSION =
  process.env.REACT_APP_SERVICE_API_VERSION || '1.0.0';
export const SERVICE_IMAGE_BASE =
  process.env.SERVICE_IMAGE_BASE || 'http://localhost:3032';

// MUI License key
export const MUI_LICENSE_KEY = process.env.REACT_APP_MUI_LICENSE_KEY || '';

// STREAM CHAT
export const STREAM_KEY = process.env.REACT_APP_STREAM_KEY || '';

// LAYOUT
// ----------------------------------------------------------------------

export const HEADER = {
  MOBILE_HEIGHT: 64,
  MAIN_DESKTOP_HEIGHT: 88,
  DASHBOARD_DESKTOP_HEIGHT: 92,
  DASHBOARD_DESKTOP_OFFSET_HEIGHT: 92 - 32,
};

export const NAVBAR = {
  BASE_WIDTH: 260,
  DASHBOARD_WIDTH: 280,
  DASHBOARD_COLLAPSE_WIDTH: 88,
  //
  DASHBOARD_ITEM_ROOT_HEIGHT: 48,
  DASHBOARD_ITEM_SUB_HEIGHT: 40,
  DASHBOARD_ITEM_HORIZONTAL_HEIGHT: 32,
};

export const ICON = {
  NAVBAR_ITEM: 22,
  NAVBAR_ITEM_HORIZONTAL: 20,
};

export const RIGHT_SIDEBAR = {
  BASE_WIDTH: 60,
};

// FADE TIMEOUT
// ----------------------------------------------------------------------

export const TIMEOUT = 300;

// SETTINGS
// Please remove `localStorage` when you change settings.
// ----------------------------------------------------------------------

export const defaultSettings: SettingsValueProps = {
  themeMode: 'light',
  themeDirection: 'ltr',
  themeContrast: 'default',
  themeLayout: 'horizontal',
  themeColorPresets: 'default',
  themeStretch: true,
};

// HOTKEYS
// ----------------------------------------------------------------------
export const HOT_KEYS = {
  CONTACT_SEARCHBAR: ['meta+k', 'ctrl+k'],
  ESCAPE: ['esc', 'esc'],
};

// MULTI LANGUAGES
// Please remove `localStorage` when you change settings.
// ----------------------------------------------------------------------

export const ALL_LANGUAGES = [
  {
    label: 'English',
    value: 'en',
    systemValue: enUS,
    icon: '/assets/icons/flags/ic_flag_en.svg',
  },
  {
    label: 'French',
    value: 'fr',
    systemValue: frFR,
    icon: '/assets/icons/flags/ic_flag_fr.svg',
  },
  {
    label: 'Vietnamese',
    value: 'vn',
    systemValue: viVN,
    icon: '/assets/icons/flags/ic_flag_vn.svg',
  },
  {
    label: 'Chinese',
    value: 'cn',
    systemValue: zhCN,
    icon: '/assets/icons/flags/ic_flag_cn.svg',
  },
  {
    label: 'Arabic (Sudan)',
    value: 'ar',
    systemValue: arSD,
    icon: '/assets/icons/flags/ic_flag_sa.svg',
  },
];
// Eva icons
// ----------------------------------------------------------------------
export const ALL_ACTIVITY_TYPE_ICONS = [
  { name: 'mingcute:task-fill' },
  { name: 'eva:activity-fill' },
  { name: 'eva:alert-circle-fill' },
  { name: 'eva:alert-triangle-fill' },
  { name: 'eva:archive-fill' },
  { name: 'eva:arrow-back-fill' },
  { name: 'eva:arrow-circle-down-fill' },
  { name: 'eva:arrow-circle-left-fill' },
  { name: 'eva:arrow-circle-right-fill' },
  { name: 'eva:arrow-circle-up-fill' },
  { name: 'eva:arrow-down-fill' },
  { name: 'eva:arrow-downward-fill' },
  { name: 'eva:arrow-forward-fill' },
  { name: 'eva:arrow-ios-back-fill' },
  { name: 'eva:arrow-ios-downward-fill' },
  { name: 'eva:arrow-ios-forward-fill' },
  { name: 'eva:arrow-ios-upward-fill' },
  { name: 'eva:arrow-left-fill' },
  { name: 'eva:arrow-right-fill' },
  { name: 'eva:arrow-up-fill' },
  { name: 'eva:arrow-upward-fill' },
  { name: 'eva:arrowhead-down-fill' },
  { name: 'eva:arrowhead-left-fill' },
  { name: 'eva:arrowhead-right-fill' },
  { name: 'eva:arrowhead-up-fill' },
  { name: 'eva:at-fill' },
  { name: 'eva:attach-2-fill' },
  { name: 'eva:attach-fill' },
  { name: 'eva:award-fill' },
  { name: 'eva:backspace-fill' },
  { name: 'eva:bar-chart-2-fill' },
  { name: 'eva:bar-chart-fill' },
  { name: 'eva:battery-fill' },
  { name: 'eva:behance-fill' },
  { name: 'eva:bell-off-fill' },
  { name: 'eva:bell-fill' },
  { name: 'eva:bluetooth-fill' },
  { name: 'eva:book-open-fill' },
  { name: 'eva:book-fill' },
  { name: 'eva:bookmark-fill' },
  { name: 'eva:briefcase-fill' },
  { name: 'eva:browser-fill' },
  { name: 'eva:brush-fill' },
  { name: 'eva:bulb-fill' },
  { name: 'eva:calendar-fill' },
  { name: 'eva:camera-fill' },
  { name: 'eva:car-fill' },
  { name: 'eva:cast-fill' },
  { name: 'eva:charging-fill' },
  { name: 'eva:checkmark-circle-2-fill' },
  { name: 'eva:checkmark-circle-fill' },
  { name: 'eva:checkmark-square-2-fill' },
  { name: 'eva:checkmark-square-fill' },
  { name: 'eva:checkmark-fill' },
  { name: 'eva:chevron-down-fill' },
  { name: 'eva:chevron-left-fill' },
  { name: 'eva:chevron-right-fill' },
  { name: 'eva:chevron-up-fill' },
  { name: 'eva:clipboard-fill' },
  { name: 'eva:clock-fill' },
  { name: 'eva:close-circle-fill' },
  { name: 'eva:close-square-fill' },
  { name: 'eva:close-fill' },
  { name: 'eva:cloud-download-fill' },
  { name: 'eva:cloud-upload-fill' },
  { name: 'eva:code-download-fill' },
  { name: 'eva:code-fill' },
  { name: 'eva:collapse-fill' },
  { name: 'eva:color-palette-fill' },
  { name: 'eva:color-picker-fill' },
  { name: 'eva:compass-fill' },
  { name: 'eva:copy-fill' },
  { name: 'eva:corner-down-left-fill' },
  { name: 'eva:corner-down-right-fill' },
  { name: 'eva:corner-left-down-fill' },
  { name: 'eva:corner-left-up-fill' },
  { name: 'eva:corner-right-down-fill' },
  { name: 'eva:corner-right-up-fill' },
  { name: 'eva:corner-up-left-fill' },
  { name: 'eva:corner-up-right-fill' },
  { name: 'eva:credit-card-fill' },
  { name: 'eva:crop-fill' },
  { name: 'eva:cube-fill' },
  { name: 'eva:diagonal-arrow-left-down-fill' },
  { name: 'eva:diagonal-arrow-left-up-fill' },
  { name: 'eva:diagonal-arrow-right-down-fill' },
  { name: 'eva:diagonal-arrow-right-up-fill' },
  { name: 'eva:done-all-fill' },
  { name: 'eva:download-fill' },
  { name: 'eva:droplet-off-fill' },
  { name: 'eva:droplet-fill' },
  { name: 'eva:edit-2-fill' },
  { name: 'eva:edit-fill' },
  { name: 'eva:email-fill' },
  { name: 'eva:expand-fill' },
  { name: 'eva:external-link-fill' },
  { name: 'eva:eye-off-2-fill' },
  { name: 'eva:eye-off-fill' },
  { name: 'eva:eye-fill' },
  { name: 'eva:facebook-fill' },
  { name: 'eva:file-add-fill' },
  { name: 'eva:file-remove-fill' },
  { name: 'eva:file-text-fill' },
  { name: 'eva:file-fill' },
  { name: 'eva:film-fill' },
  { name: 'eva:flag-fill' },
  { name: 'eva:flash-off-fill' },
  { name: 'eva:flash-fill' },
  { name: 'eva:flip-2-fill' },
  { name: 'eva:flip-fill' },
  { name: 'eva:folder-add-fill' },
  { name: 'eva:folder-remove-fill' },
  { name: 'eva:folder-fill' },
  { name: 'eva:funnel-fill' },
  { name: 'eva:gift-fill' },
  { name: 'eva:github-fill' },
  { name: 'eva:globe-2-fill' },
  { name: 'eva:globe-3-fill' },
  { name: 'eva:globe-fill' },
  { name: 'eva:google-fill' },
  { name: 'eva:grid-fill' },
  { name: 'eva:hard-drive-fill' },
  { name: 'eva:hash-fill' },
  { name: 'eva:headphones-fill' },
  { name: 'eva:heart-fill' },
  { name: 'eva:home-fill' },
  { name: 'eva:image-2-fill' },
  { name: 'eva:image-fill' },
  { name: 'eva:inbox-fill' },
  { name: 'eva:info-fill' },
  { name: 'eva:keypad-fill' },
  { name: 'eva:layers-fill' },
  { name: 'eva:layout-fill' },
  { name: 'eva:link-2-fill' },
  { name: 'eva:link-fill' },
  { name: 'eva:linkedin-fill' },
  { name: 'eva:list-fill' },
  { name: 'eva:lock-fill' },
  { name: 'eva:log-in-fill' },
  { name: 'eva:log-out-fill' },
  { name: 'eva:map-fill' },
  { name: 'eva:maximize-fill' },
  { name: 'eva:menu-2-fill' },
  { name: 'eva:menu-arrow-fill' },
  { name: 'eva:menu-fill' },
  { name: 'eva:message-circle-fill' },
  { name: 'eva:message-square-fill' },
  { name: 'eva:mic-off-fill' },
  { name: 'eva:mic-fill' },
  { name: 'eva:minimize-fill' },
  { name: 'eva:minus-circle-fill' },
  { name: 'eva:minus-square-fill' },
  { name: 'eva:minus-fill' },
  { name: 'eva:monitor-fill' },
  { name: 'eva:moon-fill' },
  { name: 'eva:more-horizontal-fill' },
  { name: 'eva:more-vertical-fill' },
  { name: 'eva:move-fill' },
  { name: 'eva:music-fill' },
  { name: 'eva:navigation-2-fill' },
  { name: 'eva:navigation-fill' },
  { name: 'eva:npm-fill' },
  { name: 'eva:options-2-fill' },
  { name: 'eva:options-fill' },
  { name: 'eva:pantone-fill' },
  { name: 'eva:paper-plane-fill' },
  { name: 'eva:pause-circle-fill' },
  { name: 'eva:people-fill' },
  { name: 'eva:percent-fill' },
  { name: 'eva:person-add-fill' },
  { name: 'eva:person-delete-fill' },
  { name: 'eva:person-done-fill' },
  { name: 'eva:person-remove-fill' },
  { name: 'eva:person-fill' },
  { name: 'eva:phone-call-fill' },
  { name: 'eva:phone-missed-fill' },
  { name: 'eva:phone-off-fill' },
  { name: 'eva:phone-fill' },
  { name: 'eva:pie-chart-2-fill' },
  { name: 'eva:pie-chart-fill' },
  { name: 'eva:pin-fill' },
  { name: 'eva:play-circle-fill' },
  { name: 'eva:plus-circle-fill' },
  { name: 'eva:plus-square-fill' },
  { name: 'eva:plus-fill' },
  { name: 'eva:power-fill' },
  { name: 'eva:pricetags-fill' },
  { name: 'eva:printer-fill' },
  { name: 'eva:question-mark-circle-fill' },
  { name: 'eva:question-mark-fill' },
  { name: 'eva:radio-button-off-fill' },
  { name: 'eva:radio-button-on-fill' },
  { name: 'eva:radio-fill' },
  { name: 'eva:recording-fill' },
  { name: 'eva:refresh-fill' },
  { name: 'eva:repeat-fill' },
  { name: 'eva:rewind-left-fill' },
  { name: 'eva:rewind-right-fill' },
  { name: 'eva:save-fill' },
  { name: 'eva:scissors-fill' },
  { name: 'eva:search-fill' },
  { name: 'eva:settings-2-fill' },
  { name: 'eva:settings-fill' },
  { name: 'eva:shake-fill' },
  { name: 'eva:share-fill' },
  { name: 'eva:shield-off-fill' },
  { name: 'eva:shield-fill' },
  { name: 'eva:shopping-bag-fill' },
  { name: 'eva:shopping-cart-fill' },
  { name: 'eva:shuffle-2-fill' },
  { name: 'eva:shuffle-fill' },
  { name: 'eva:skip-back-fill' },
  { name: 'eva:skip-forward-fill' },
  { name: 'eva:slash-fill' },
  { name: 'eva:smartphone-fill' },
  { name: 'eva:smiling-face-fill' },
  { name: 'eva:speaker-fill' },
  { name: 'eva:square-fill' },
  { name: 'eva:star-fill' },
  { name: 'eva:stop-circle-fill' },
  { name: 'eva:sun-fill' },
  { name: 'eva:swap-fill' },
  { name: 'eva:sync-fill' },
  { name: 'eva:text-fill' },
  { name: 'eva:thermometer-minus-fill' },
  { name: 'eva:thermometer-plus-fill' },
  { name: 'eva:thermometer-fill' },
  { name: 'eva:toggle-left-fill' },
  { name: 'eva:toggle-right-fill' },
  { name: 'eva:trash-2-fill' },
  { name: 'eva:trash-fill' },
  { name: 'eva:trending-down-fill' },
  { name: 'eva:trending-up-fill' },
  { name: 'eva:tv-fill' },
  { name: 'eva:twitter-fill' },
  { name: 'eva:umbrella-fill' },
  { name: 'eva:undo-fill' },
  { name: 'eva:unlock-fill' },
  { name: 'eva:upload-fill' },
  { name: 'eva:video-off-fill' },
  { name: 'eva:video-fill' },
  { name: 'eva:volume-down-fill' },
  { name: 'eva:volume-mute-fill' },
  { name: 'eva:volume-off-fill' },
  { name: 'eva:volume-up-fill' },
  { name: 'eva:wifi-off-fill' },
  { name: 'eva:wifi-fill' },
];

export const defaultLang = ALL_LANGUAGES[0]; // English

// LOGICAL OPERATORS
// ----------------------------------------------------------------------

export const LOGICAL_OPERATORS = [
  'IS EQUAL TO',
  'IS NOT',
  'IS GREATER THAN',
  'IS LOWER THAN',
];

export const priorityIcons = [
  {
    id: 1,
    name: 'Highest',
    icon: KeyboardDoubleArrowUp,
    colorIcon: 'error',
  },
  {
    id: 2,
    name: 'High',
    icon: KeyboardArrowUp,
    color: 'error',
  },
  {
    id: 3,
    name: 'Medium',
    icon: DragHandle,
    color: 'warning',
  },
  {
    id: 4,
    name: 'Low',
    icon: KeyboardArrowDown,
    color: 'secondary',
  },
  {
    id: 5,
    name: 'Lowest',
    icon: KeyboardDoubleArrowDown,
    color: 'secondary',
  },
];

export const FIELD_SELECTION: BaseObjectEntityForArray = {
  VALUE: ['date', 'number', 'textarea'],
  VALUE_LIST: ['singleselect', 'multiselect'],
  REF: ['dropdown'],
};

export const PRIORITY_ICONS = [
  {
    id: 1,
    name: 'Highest',
    icon: KeyboardDoubleArrowUp,
    color: 'error',
  },
  {
    id: 2,
    name: 'High',
    icon: KeyboardArrowUp,
    color: 'error',
  },
  {
    id: 3,
    name: 'Medium',
    icon: DragHandle,
    color: 'warning',
  },
  {
    id: 4,
    name: 'Low',
    icon: KeyboardArrowDown,
    color: 'secondary',
  },
  {
    id: 5,
    name: 'Lowest',
    icon: KeyboardDoubleArrowDown,
    color: 'secondary',
  },
];

export const TITLE_OPTIONS = ['Mr', 'Miss', 'Mrs', 'Ms', 'Dr', 'Mx'];
