import { useState } from 'react';
// @mui
import { styled } from '@mui/material/styles';
import { Dialog, DialogProps, ClickAwayListener, Tooltip, Typography, Box } from '@mui/material';
// utils
// components
import Iconify from '../../../components/Iconify';
import { IconButtonAnimate } from '../../../components/animate';
import { AutocompleteAsyncLookupForGlobalSearch } from '@/components/toolbar';

import { useHotkeys } from 'react-hotkeys-hook';
import { HOT_KEYS } from '@/config';

// ----------------------------------------------------------------------

const StyledDialog = styled(Dialog)<DialogProps>(({ theme }) => ({
  '& .MuiDialog-paper': {
    top: 120,
    width: '100%',
    margin: 0,
    position: 'absolute',
    borderRadius: '5px 5px 5px 5px !important',
    zIndex: theme.zIndex.appBar,
  },
}));

// ----------------------------------------------------------------------

export default function Searchbar() {
  const [isOpen, setOpen] = useState(false);

  useHotkeys(HOT_KEYS.CONTACT_SEARCHBAR, () => setOpen(true), [isOpen]);

  useHotkeys(HOT_KEYS.ESCAPE, () => setOpen(false), [isOpen]);

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = async () => {
    setOpen((prev) => !prev);
  };

  return (
    <ClickAwayListener onClickAway={handleClose}>
      <>
        <Tooltip title="Search contacts" placement="left">
          <Box display="flex" alignItems="center" flexDirection="column">
            <IconButtonAnimate onClick={handleOpen}>
              <Iconify icon={'eva:search-fill'} width={20} height={20} />
            </IconButtonAnimate>
            <Typography
              variant="caption"
              sx={{
                backgroundColor: 'grey.400',
                px: 0.8,
                py: 0.2,
                borderRadius: 0.5,
              }}
            >
              ⌘K
            </Typography>
          </Box>
        </Tooltip>

        <StyledDialog open={isOpen} onClose={handleClose}>
          <AutocompleteAsyncLookupForGlobalSearch
            autoFocus
            apiUrl="/search/contacts"
            noOptionsText="No contact found"
            multiOptionFirstKeyValue="first_name"
            multiOptionSecondKeyValue="last_name"
            textFieldPlaceholder="Search contacts..."
            //
            handleClose={handleClose}
          />
        </StyledDialog>
      </>
    </ClickAwayListener>
  );
}
