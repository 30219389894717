import merge from 'lodash/merge';
import ReactApexChart from 'react-apexcharts';
// @mui
import { alpha, styled } from '@mui/material/styles';
import { Box, Card, Typography, Stack, CardProps } from '@mui/material';
// utils
import { fNumber, fPercent } from '@/utils/formatNumber';
// components
import Iconify from '@/components/Iconify';
import { BaseOptionChart } from '@/components/chart';
import { CardTitle } from '.';

// ----------------------------------------------------------------------

const IconWrapperStyle = styled('div')(({ theme }) => ({
  width: 24,
  height: 24,
  display: 'flex',
  borderRadius: '50%',
  alignItems: 'center',
  justifyContent: 'center',
  marginRight: theme.spacing(1),
  color: theme.palette.success.main,
  backgroundColor: alpha(theme.palette.success.main, 0.16),
}));

// ----------------------------------------------------------------------

interface Props extends CardProps {
  title: string;
  total: string;
  trendLabel: string;
  percent: number;
  chartColor: string;
  chartData: number[];
}

// https://apexcharts.com/docs/chart-types/line-chart/

export default function SalesApexChart({
  title,
  percent,
  total,
  trendLabel,
  chartColor,
  chartData,
  sx,
  ...other
}: Props) {
  const chartOptions = merge(BaseOptionChart(), {
    colors: [chartColor],
    chart: { animations: { enabled: true }, sparkline: { enabled: true } },
    stroke: { width: 2 },
    tooltip: {
      x: { show: false },
      y: {
        formatter: (seriesName: string) => fNumber(seriesName),
        title: {
          formatter: () => '',
        },
      },
      marker: { show: false },
    },
  });

  return (
    <Card
      sx={{
        p: 3,
        height: '100%',
      }}
    >
      <CardTitle title={title} />
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          gap: '1rem',
          flexGrow: 1,
        }}
      >
        <Stack direction="row" justifyContent="center" spacing={2}>
          <Typography variant="h3" gutterBottom>
            {total}
          </Typography>
          <ReactApexChart
            type="line"
            series={[{ data: chartData }]}
            options={chartOptions}
            width={100}
            height={50}
          />
        </Stack>

        <Stack direction="row" alignItems="center" justifyContent="center">
          <IconWrapperStyle
            sx={{
              ...(percent < 0 && {
                color: 'error.main',
                bgcolor: (theme) => alpha(theme.palette.error.main, 0.16),
              }),
            }}
          >
            <Iconify
              width={16}
              height={16}
              icon={
                percent >= 0 ? 'eva:trending-up-fill' : 'eva:trending-down-fill'
              }
            />
          </IconWrapperStyle>

          <Typography variant="subtitle2" component="span">
            {percent > 0 && '+'}
            {fPercent(percent)}
          </Typography>
          <Typography
            variant="body2"
            component="span"
            noWrap
            sx={{ color: 'text.secondary' }}
          >
            &nbsp;{trendLabel}
          </Typography>
        </Stack>
      </Box>
    </Card>
  );
}
