import { Iconify } from '@/components';
import { IconButton } from '@mui/material';
//
type VBMEditButtonProps = {
  onClickButton: () => void;
};

export default function VBMEditButton({ onClickButton }: VBMEditButtonProps) {
  return (
    <IconButton
      title="Modify"
      sx={{
        position: 'absolute',
        top: 1,
        right: 1,
        '&:hover': { background: (theme) => theme.palette.primary.lighter },
      }}
      onClick={onClickButton}
    >
      <Iconify icon="eva:edit-2-outline" />
    </IconButton>
  );
}
