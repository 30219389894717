export const schedulerSx = {
  width: '85%',
  height: '100%',
  overflowY: 'scroll',
  '& .mbsc-timeline-resource-col': {
    width: '150px !important',
  },
  '& .mbsc-timeline-header-column': {
    width: '40px !important',
  },
  '& .mbsc-timeline-column': {
    width: '40px !important',
  },
  '& .mbsc-timeline-header-date-text': {
    marginLeft: 'auto ',
    textAlign: 'right ',
    paddingRight: 1,
    marginTop: 0.2,
    marginBottom: 0.2,
  },
  '& .mbsc-timeline-header-date': {
    marginLeft: 'auto ',
    textAlign: 'right ',
    paddingRight: 1,
    marginTop: 0.2,
    marginBottom: 0.2,
  },
};
