import { useFormContext, Controller } from 'react-hook-form';
import {
  Avatar,
  TextField,
  IconButton,
  Autocomplete,
  TextFieldProps,
  InputAdornment,
  AutocompleteRenderGetTagProps,
  AutocompleteRenderOptionState,
} from '@mui/material';

import { TooltipIcon } from '../Iconify';

type Props = {
  name: string;
  size?: 'small' | 'medium';
  options: any[];
  renderOption?: (
    props: React.HTMLAttributes<HTMLLIElement>,
    option: any,
    state: AutocompleteRenderOptionState
  ) => React.ReactNode;
  renderTags?: (
    value: any[],
    getTagProps: AutocompleteRenderGetTagProps
  ) => React.ReactNode;
  label: string;
  placeholder?: string;
  disableCloseOnSelect?: boolean;
  filterSelectedOptions?: boolean;
  limitTags?: number;
  filterOptions?: any;
  multiple?: boolean;
  isOptionEqualToValue?: any;
  renderInput?: any;
  noOptionsText?: string;
  showSelectAllButton?: boolean;
  getOptionLabel?: any;
  avatarIcon?: string;
  onSelectChange?: (selectedId: number | null) => void;
} & TextFieldProps;

export default function RHFAutocomplete({
  name,
  options,
  label,
  renderOption,
  placeholder,
  size = 'small',
  renderTags,
  onSelectChange,
  getOptionLabel,
  noOptionsText = 'No options',
  showSelectAllButton = false,
  disableCloseOnSelect = true,
  filterSelectedOptions = true,
  isOptionEqualToValue = (option: { id: number }, value: { id: number }) =>
    option.id === value.id,
  filterOptions,
  avatarIcon,
  limitTags = -1,
  multiple = true,
  ...other
}: Props) {
  const { control, setValue } = useFormContext();

  if (other.value) {
    setValue(name, other.value);
  }

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={other?.value || []}
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <Autocomplete
          size={size}
          isOptionEqualToValue={isOptionEqualToValue}
          filterOptions={filterOptions}
          fullWidth
          multiple={multiple}
          limitTags={limitTags}
          disableCloseOnSelect={disableCloseOnSelect}
          filterSelectedOptions={filterSelectedOptions}
          options={options}
          onChange={(_e, newValue) => {
            onChange(newValue);
            if (other.onChange) other.onChange(newValue);
            if (onSelectChange) {
              onSelectChange(newValue ? newValue.id : 0);
            }
          }}
          renderOption={renderOption}
          renderTags={renderTags}
          value={value}
          noOptionsText={noOptionsText}
          getOptionLabel={getOptionLabel}
          renderInput={(params) => {
            !!params.inputProps.value &&
              !!avatarIcon &&
              !!value &&
              (params.InputProps.startAdornment = (
                <>
                  <InputAdornment position="start">
                    <Avatar
                      src={value[avatarIcon]}
                      sx={{ width: 24, height: 24 }}
                    />
                  </InputAdornment>
                </>
              ));
            params.InputProps.endAdornment = (
              <>
                {params.InputProps.endAdornment}
                <InputAdornment position="end">
                  {showSelectAllButton && value.length !== options.length && (
                    <IconButton
                      sx={{
                        position: 'absolute',
                        top: '50%',
                        right: 75,
                        transform: 'translate(50%, -50%)',
                      }}
                      onClick={() => {
                        onChange(options);
                        if (other.onChange) other.onChange(options as any);
                      }}
                    >
                      <TooltipIcon
                        title="Select All"
                        icon={'eva:checkmark-square-2-outline'}
                        color="secondary"
                      />
                    </IconButton>
                  )}
                </InputAdornment>
              </>
            );
            return (
              <TextField
                {...params}
                size={size}
                error={!!error}
                helperText={error?.message}
                label={label}
                placeholder={placeholder}
                fullWidth
              />
            );
          }}
        />
      )}
    />
  );
}
