import { MbscCalendarEventData } from '@mobiscroll/react';
import { isEmpty } from 'lodash';
import { Box, Stack, Typography } from '@mui/material';
import { Iconify, Label } from '@/components';
import { calculateHoursBetween, convertTo24HourFormat } from '../../utils';

type MbscScheduleEventProps = {
  data: MbscCalendarEventData;
};

export default function MbscScheduleEvent({ data }: MbscScheduleEventProps) {
  const isRecurring = !isEmpty(data.original?.recurring);
  // console.log('MbscScheduleEvent: ', data);
  return (
    <Stack sx={{ position: 'relative' }}>
      <Stack>
        {/* TITLE */}
        <Typography color="text.primary" fontSize={12} fontWeight={600} noWrap>
          {data.title}
        </Typography>

        {/* TIME AND LENGTH */}
        <Typography color="text.primary" fontSize={11}>
          {/* TODO - check function */}
          {convertTo24HourFormat(data.start)} -{' '}
          {convertTo24HourFormat(data.end)}{' '}
          {`(${calculateHoursBetween(data.start, data.end)} hrs)`}
        </Typography>
      </Stack>

      <Stack direction="row" alignItems="center" spacing={0.2}>
        {/* POSTCODE */}
        <Typography fontSize={11} color="text.primary" fontWeight={600}>
          {data.original?.address.postcode || 'N/A'}
        </Typography>

        {/* ADRESS_LINE_1: show address when it's an airbnb/holiday rental */}
        {data.original?.property.type && (
          <Typography color="text.primary" fontSize={10} noWrap>
            {' '}
            - {data.original.address.line1}
          </Typography>
        )}
      </Stack>

      <Stack
        direction="column"
        alignItems="center"
        spacing={0.5}
        sx={{
          position: 'absolute',
          right: 0.5,
          top: 0.5,
          bottom: 0.5,
          p: 0.5,
        }}
      >
        {/* PROPERTY TYPE ICON: only in case of holiday rental */}
        {data.original?.property.type && (
          <Box
            sx={{
              backgroundColor: 'white',
              width: 20,
              height: 20,
              borderRadius: 0.5,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Iconify
              icon={data.original?.property.icon}
              width={15}
              color={'black'}
            />
          </Box>
        )}

        {/* SERVICE + RECURRENCE LABEL, ie PO - personal one-off */}
        <Stack direction="row" spacing={1}>
          <Label color="info" variant="filled" sx={{ p: 0 }}>
            {data.original?.serviceLabel}
          </Label>
          {isRecurring && (
            <Label color="info" variant="filled" sx={{ px: 0.5 }}>
              Recurring
            </Label>
          )}
        </Stack>
      </Stack>
    </Stack>
  );
}
