import { Link as RouterLink } from 'react-router-dom';
import { SxProps, Theme, Typography } from '@mui/material';
import { HTMLAttributeAnchorTarget } from 'react';

type TableRowLinkProps = {
  linkText: string | number;
  linkTo: string;
  onClick?: () => void;
  sx?: SxProps<Theme>;
  noWrap?: boolean;
  target?: HTMLAttributeAnchorTarget;
};

export default function TableRowLink({
  linkText,
  linkTo,
  onClick,
  sx = [],
  noWrap = false,
  target = '_self',
}: TableRowLinkProps) {
  const rel = target === '_blank' ? 'noopener noreferrer' : undefined;
  return (
    <Typography
      component={RouterLink}
      to={linkTo}
      target={target}
      rel={rel}
      onClick={() => onClick && onClick()}
      noWrap={noWrap}
      sx={[
        {
          fontWeight: 600,
          textDecoration: ' none',
          color: 'text.primary',
          fontSize: 14,
          px: 1,
          py: 0.5,
          borderRadius: 1,
          '&:hover': {
            backgroundColor: 'primary.lighter',
          },
        },
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
    >
      {linkText}
    </Typography>
  );
}
