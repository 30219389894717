import { Theme } from '@mui/material';

export const timePickerSx = (theme: Theme, error?: boolean) => ({
  width: '100%',
  '& .mbsc-textfield-wrapper': {
    border: `1px solid ${
      error ? theme.palette.error.main : theme.palette.divider
    }`,
    borderRadius: 1,
    backgroundColor: theme.palette.background.neutral,
    height: 40,
  },
  '& .mbsc-ios.mbsc-form-control-wrapper.mbsc-ltr:before ': {
    border: 'none',
  },
  '& .mbsc-ios.mbsc-form-control-wrapper.mbsc-ltr:after ': {
    border: 'none',
  },
  '& .mbsc-popup-buttonx': {
    color: theme.palette.primary.main,
  },
  '& .mbsc-label': {
    color: error ? theme.palette.error.main : theme.palette.text.secondary,
  },
});
