// form
import { useFormContext, Controller } from 'react-hook-form';
// @mui
import { TextField, TextFieldProps } from '@mui/material';

interface IProps {
  name: string;
  type?: 'text' | 'number' | 'password';
}

export default function RHFTextField({
  name,
  type = 'text',
  ...other
}: IProps & TextFieldProps) {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <TextField
          {...field}
          size="small"
          fullWidth
          type={type}
          error={!!error}
          helperText={error?.message}
          {...other}
        />
      )}
    />
  );
}
