import { isEmpty } from 'lodash';
import { useQuery } from '@tanstack/react-query';
// mui
import { MenuItem, Stack, Skeleton, TextField } from '@mui/material';
// custom component
import {
  NumberInputField,
  PickerInputField,
  SelectInputField,
  SectionLabel,
  MultipleSelectCheckmarks,
} from '@/features/bookings-management/components';
// api
import { getSettingsData } from '@/features/app/api';
//
// hooks + utils
import useResponsive from '@/hooks/useResponsive';
import { useSnackbarMsg } from '@/hooks/useSnackbarMsg';
//
import { consoleError } from '@/utils/consoleError';
// redux
import { useSelector, dispatch } from '@/stores';
import {
  selectService,
  selectVariant,
  selectedVariant,
  setPropertyType,
  setValidationError,
} from '@/features/bookings-management/slices';
// types
import { ServiceVariant, ServiceFormField } from '@/types';

export default function ServiceDetails() {
  const downMd = useResponsive('down', 'md');
  const choosenVariant: ServiceVariant = useSelector(selectedVariant);
  const {
    all_variants_from_api: serviceVariantsReduxCopy,
    cleanDetails: { property_type_id },
    selectedServiceId,
    validationErrors,
  } = useSelector((state) => state.booking);
  const { errorMsg } = useSnackbarMsg();
  // const queryClient = useQueryClient();
  // settingsData is fetched in the parent component. Using this instead of the redux version
  // prevents a bug where the settings are not fetched and STORED IN REDUX in time
  // const settingsCachedData = queryClient.getQueryData<Settings>(['settings']);
  // SETTINGS
  const { data: settingsData } = useQuery({
    // TODO: probably this data should be fetched on app load
    queryKey: ['settings'],
    queryFn: getSettingsData,
    onError: (error) => {
      errorMsg(error, `Something went wrong while fetching settings data `);
    },
    refetchOnWindowFocus: false,
  });
  // ---------------------------------------------------
  //                    HELPER FUNCTIONS
  // ---------------------------------------------------
  const handleSelectedService = (serviceId: number) => {
    dispatch(selectService(serviceId));
    validationErrors.selectedServiceId &&
      dispatch(setValidationError({ field: 'selectedServiceId', error: null }));
  };
  const handleSelectedVariant = (variantId: number) => {
    // set selected variant
    const currentVariant = serviceVariantsReduxCopy.filter(
      (variant) => variant.id === variantId
    );
    dispatch(selectVariant(currentVariant[0]));
    validationErrors.selectedServiceVariant &&
      dispatch(
        setValidationError({ field: 'selectedServiceVariant', error: null })
      );
  };

  // ---------------------------------------------------
  //        HELPER FUNCTION TO RENDER INPUT FIELDS
  // ---------------------------------------------------

  const renderInputFields = (
    type: string,
    serviceFormField: ServiceFormField
  ) => {
    switch (type) {
      case 'select':
        return <SelectInputField serviceFormField={serviceFormField} />;
      case 'picker':
        return <PickerInputField serviceFormField={serviceFormField} />;
      case 'number':
        return <NumberInputField serviceFormField={serviceFormField} />;

      default:
        consoleError('ServiceDetails.tsx', 'renderInputFields()');
        return <div>There was an error here: {serviceFormField.name}</div>;
    }
  };

  // ---------------------------------------------------
  return (
    <Stack direction="column" spacing={2}>
      <SectionLabel label="Service Details:" />

      {settingsData ? (
        <Stack direction="column" spacing={1} width="100%">
          <TextField
            size="small"
            select
            sx={{ width: downMd ? '100%' : '75%' }}
            label="Service"
            InputLabelProps={{ shrink: true }}
            SelectProps={{ native: false, sx: { textTransform: 'capitalize' } }}
            onChange={(e) => handleSelectedService(Number(e.target.value))}
            value={selectedServiceId ?? ''}
            error={!!validationErrors.selectedServiceId}
            helperText={validationErrors.selectedServiceId}
          >
            {settingsData.services.map((option) => (
              <MenuItem key={option.id} value={option.id}>
                {option.name}
              </MenuItem>
            ))}
          </TextField>
        </Stack>
      ) : (
        <Skeleton variant="text" width={200} />
      )}
      {/* TODO - check the skeleton logic vor the service type */}
      {selectedServiceId && settingsData && (
        <>
          {settingsData.serviceVariants ? (
            <Stack direction="column" spacing={1} width="100%">
              <TextField
                size="small"
                select
                sx={{ width: downMd ? '100%' : '75%' }}
                label="Service Type"
                InputLabelProps={{ shrink: true }}
                SelectProps={{
                  native: false,
                  sx: { textTransform: 'capitalize' },
                }}
                onChange={(e) => handleSelectedVariant(Number(e.target.value))}
                value={choosenVariant.id ?? ''}
                error={!!validationErrors.selectedServiceVariant}
                helperText={validationErrors.selectedServiceVariant}
              >
                {settingsData.serviceVariants
                  .filter((variant) => variant.service_id === selectedServiceId)
                  .map((variant) => (
                    <MenuItem key={variant.id} value={variant.id}>
                      {variant.name}
                    </MenuItem>
                  ))}
              </TextField>
            </Stack>
          ) : (
            <Skeleton variant="text" width={200} />
          )}

          <Stack direction="column" spacing={2}>
            {/* PROPERTY TYPES DROWDOWN */}
            {choosenVariant.property_type &&
              !!choosenVariant.property_type.length && (
                <TextField
                  select
                  size="small"
                  sx={{ width: downMd ? '100%' : '75%' }}
                  label="Property type"
                  InputLabelProps={{ shrink: true }}
                  SelectProps={{
                    native: false,
                    sx: { textTransform: 'capitalize' },
                  }}
                  value={property_type_id ?? ''}
                  error={!!validationErrors.property_type_id}
                  helperText={validationErrors.property_type_id}
                  onChange={(e) => {
                    dispatch(setPropertyType(e.target.value));
                    dispatch(
                      setValidationError({
                        field: 'property_type_id',
                        error: null,
                      })
                    );
                  }}
                >
                  {choosenVariant.property_type.map((property) => (
                    <MenuItem key={property.id} value={property.id}>
                      {property.name}
                    </MenuItem>
                  ))}
                </TextField>
              )}

            {/* IF RECURRING => SHOW DAY OF WEEK // ID#2 - domestic-weekly, ID#3 - domestic-fortnightly */}
            {!isEmpty(choosenVariant) &&
              (choosenVariant.id === 2 || choosenVariant.id === 3) && (
                <MultipleSelectCheckmarks />
              )}

            {/* IF NOT COMMERCIAL => SHOW DROPDOWNS AND HOUR PICKER */}
            {!isEmpty(choosenVariant) &&
              !choosenVariant.ref.includes('office') &&
              choosenVariant.service_form_fields.map((field, index) => (
                <Stack direction="row" spacing={2} key={index}>
                  {renderInputFields(field.type, field)}
                </Stack>
              ))}
          </Stack>
        </>
      )}
    </Stack>
  );
}
