import { useState, useEffect, useMemo, Fragment } from 'react';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';
import { groupBy, isEmpty, startCase } from 'lodash';
import { object, string } from 'yup';
// api
import {
  getCategoryData,
  getCoverExtrasData,
  getProviderData,
  postProductData,
  updateProductData,
} from '../api';
// form
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import {
  Stack,
  Grid,
  Box,
  Card,
  Container,
  Typography,
  TextField,
  InputAdornment,
  FormControlLabel,
  Checkbox,
  IconButton,
  Skeleton,
} from '@mui/material';

import LoadingButton from '@mui/lab/LoadingButton';
// hooks
import useSettings from '@/hooks/useSettings';
// components
import { Iconify, EmptyContent, Page } from '@/components';
import {
  FormProvider,
  RHFEditor,
  RHFSelect,
  RHFTextField,
} from '@/components/hook-form';
// types
import type {
  Category,
  CoverExtra,
  Extras,
  ProductObjectToPost,
  Provider,
} from '../types';
// import _ from 'lodash';
import { ROOTS_PRODUCTS } from '../routes/paths';

// ----------------------------------------------------------------------
const STATUS_OPTIONS = ['Active', 'Inactive'];

// PROP TYPES
interface ProductProps {
  id: number;
  product_category: {
    id: number;
    name: string;
  };
  provider: {
    id: number;
    name: string;
  };
  name: string;
  description: string;
  provider_product_code: string;
  active: boolean | string;
  extras?: Extras[];
  extrasCheckbox: object[];
}

interface FormValuesProps {
  id: number;
  category_id?: number;
  provider_id?: number;
  name: string;
  description: string;
  provider_product_code: string;
  active: boolean | string;
  extras: Extras[];
  extrasCheckbox: string[] | boolean[];
}

type Props = {
  currentProduct?: ProductProps;
  productID?: number;
};

export default function ProductEditProduct({ currentProduct }: Props) {
  const [categoryData, setCategoryData] = useState<Category[]>([]);
  let [extrasData, setExtrasData] = useState<CoverExtra[]>([]);
  let [extrasDataMod, setExtrasDataMod] = useState<any>([]);
  let [extrasSelected, setExtrasSelected] = useState<Extras[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [providerData, setProviderData] = useState<Provider[]>([]);
  const navigate = useNavigate();

  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    async function fetchData() {
      const getCategory = await getCategoryData();
      setCategoryData(getCategory);
      const getProvider = await getProviderData();
      setProviderData(getProvider);
      const getExtras = await getCoverExtrasData();
      setExtrasData(getExtras);
      const extrasMod = groupBy(getExtras, 'extra_type.name');

      setExtrasDataMod(extrasMod);
    }
    setIsLoading(false);
    fetchData().catch(console.error);
  }, []);

  // ----------------------------------------------------------------------
  // REACT HOOK FORM
  const Schema = object().shape({
    category_id: string().required('Category is required'),
    provider_id: string().required('Provider is required'),
    name: string().required('Name is required'),
    description: string().required('Description is required'),
    provider_product_code: string().required('Provider code is required'),
    active: string().required('Status is required'),
  });

  const defaultValues = useMemo(
    () => ({
      category_id: currentProduct?.product_category?.id,
      provider_id: currentProduct?.provider?.id,
      name: currentProduct?.name || '',
      description: currentProduct?.description || '',
      provider_product_code: currentProduct?.provider_product_code || '',
      extras: currentProduct?.extras || [],
      active: currentProduct?.active
        ? 'Active'
        : isEmpty(currentProduct)
        ? 'Active'
        : 'Inactive',
    }),

    [currentProduct]
  );

  const methods = useForm<FormValuesProps>({
    resolver: yupResolver(Schema),
    defaultValues,
  });

  const {
    reset,
    handleSubmit,
    register,
    formState: { isSubmitting },
  } = methods;

  useEffect(() => {
    if (!isEmpty(currentProduct)) {
      reset(defaultValues);
    }

    reset(defaultValues);
  }, [currentProduct, reset, defaultValues]);

  useEffect(() => {
    if (currentProduct?.extras) {
      setExtrasSelected(currentProduct.extras);
    }
  }, [currentProduct]);

  const onSubmit = async (data: FormValuesProps) => {
    const extrasToSave = extrasSelected.map((item) => ({
      id: item.id,
      content: item.content,
    }));

    try {
      const productData: ProductObjectToPost = {
        category_id: Number(data.category_id),
        provider_id: Number(data.provider_id),
        name: data.name,
        extra: extrasToSave,
        description: data.description,
        provider_product_code: data.provider_product_code,
        active: data.active === 'Active' ? true : false,
      };

      !isEmpty(currentProduct)
        ? await updateProductData(productData, currentProduct?.id)
        : await postProductData(productData);
      enqueueSnackbar(
        !isEmpty(currentProduct)
          ? 'Product successfully updated!'
          : 'Product successfully created!'
      );
      navigate(ROOTS_PRODUCTS);
    } catch (error) {
      console.error(error);
      enqueueSnackbar('Something went wrong', { variant: 'error' });
    }
  };

  // ----------------------------------------------------------------------

  const { themeStretch } = useSettings();

  // --------------------------------------------------------------------------
  //  FILTER FUNCTIONS

  const handleFilterExtras = (e: any) => {
    const filteredExtras = extrasData.filter(
      (item: any) =>
        item.name.toLowerCase().indexOf(e.target.value.toLowerCase()) !== -1 ||
        item.extra_type.name
          .toLowerCase()
          .indexOf(e.target.value.toLowerCase()) !== -1
    );
    setExtrasDataMod(groupBy(filteredExtras, 'extra_type.name'));
  };

  const handleExtraCheck = (e: any) => {
    const { value, checked } = e.target;
    const selected: any = extrasData.find(
      (extra: any) => extra.id === Number(value)
    );

    if (checked) {
      setExtrasSelected([
        ...extrasSelected,
        { id: selected.id, name: selected.name, content: '' },
      ]);
    } else {
      setExtrasSelected(
        extrasSelected.filter((extra: any) => extra.id !== Number(value))
      );
    }
  };

  const handleExtraContent = (value: string, item: any) => {
    const selected: any = extrasSelected.find(
      (extra: any) => extra.id === Number(item.id)
    );
    selected.content = value;
    setExtrasSelected([...extrasSelected]);
  };

  const handleDeleteExtras = (item: any) => {
    setExtrasSelected(
      extrasSelected.filter((extra: any) => extra.id !== Number(item.id))
    );
  };
  // ----------------------------------------------------------------------

  // ----------------------------------------------------------------------
  return (
    <Page title="Products">
      <Container
        maxWidth={themeStretch ? false : 'lg'}
        style={{ position: 'relative' }}
      >
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={2} sx={{ mx: 'auto' }}>
            <Grid item xs={12}>
              <Card sx={{ p: 3 }}>
                <Box
                  sx={{
                    display: 'grid',
                    columnGap: 2,
                    rowGap: 3,
                    gridTemplateColumns: {
                      xs: 'repeat(1, 1fr)',
                      sm: 'repeat(2, 2fr)',
                    },
                  }}
                >
                  {isLoading ? (
                    <Box pl={2} pt={3}>
                      <Skeleton sx={{ width: 0.98 }} height={80} />
                      <Skeleton sx={{ width: 0.98 }} height={80} />
                    </Box>
                  ) : (
                    <>
                      <RHFTextField
                        name="name"
                        label="Product name"
                        fullWidth
                      />
                      <RHFSelect
                        name="category_id"
                        label="Product Category"
                        placeholder="Product Category"
                        InputLabelProps={{
                          shrink: true,
                        }}
                      >
                        <option value="" />
                        {categoryData.map((option) => (
                          <option key={option.id} value={Number(option.id)}>
                            {option.name.toUpperCase()}
                          </option>
                        ))}
                      </RHFSelect>
                      <RHFSelect
                        name="provider_id"
                        label="Provider"
                        placeholder="Provider"
                        InputLabelProps={{
                          shrink: true,
                        }}
                      >
                        <option value=" " />
                        {providerData.map((option) => (
                          <option key={option.id} value={Number(option.id)}>
                            {startCase(option.name)}
                          </option>
                        ))}
                      </RHFSelect>

                      <RHFTextField
                        name="provider_product_code"
                        label="Provider product code"
                        fullWidth
                      />
                      <RHFTextField
                        name="description"
                        label="Product Descripton"
                        fullWidth
                      />
                      <RHFSelect
                        name="active"
                        label="Status"
                        placeholder="Status"
                      >
                        {STATUS_OPTIONS.map((option) => (
                          <option key={option} value={option}>
                            {startCase(option)}
                          </option>
                        ))}
                      </RHFSelect>
                    </>
                  )}
                </Box>
              </Card>
            </Grid>
            {/* BOTTOM LEFT SIDE */}
            <Grid item xs={12} md={4}>
              <Stack spacing={2}>
                <Typography variant="h5">Cover Level Extras</Typography>
                <TextField
                  fullWidth
                  onChange={handleFilterExtras}
                  placeholder="Filter extras"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Iconify
                          icon={'eva:search-fill'}
                          sx={{ color: 'text.disabled', width: 20, height: 20 }}
                        />
                      </InputAdornment>
                    ),
                  }}
                />
                <Card
                  sx={{
                    p: 3,
                    height: 'auto',
                    minHeight: '500px',
                    overflow: 'auto',
                  }}
                >
                  {extrasDataMod &&
                    Object.keys(extrasDataMod).map((item: any) => (
                      <Fragment key={item}>
                        <Typography
                          variant="subtitle2"
                          sx={{ fontWeight: 'bold' }}
                        >
                          {item.toUpperCase()}
                        </Typography>
                        {extrasDataMod[item].map((extra: any) => (
                          <FormControlLabel
                            sx={{ width: '100%' }}
                            key={extra.id}
                            label={extra.name}
                            control={
                              <Checkbox
                                {...register(`extrasCheckbox.${extra.id}`)}
                                onChange={handleExtraCheck}
                                checked={extrasSelected.some(
                                  (rec) => rec.id === extra.id
                                )}
                                key={extra.id}
                                value={extra.id}
                                sx={{
                                  display: 'block',
                                  fontSize: '1.5rem',
                                }}
                              />
                            }
                          />
                        ))}
                      </Fragment>
                    ))}
                </Card>
              </Stack>
            </Grid>
            <Grid item xs={12} md={8}>
              <Stack spacing={2}>
                <Typography variant="h5">Selected Extras</Typography>

                {extrasSelected &&
                  extrasSelected.map((item, idx) => (
                    <Fragment key={idx}>
                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          mt: '2',
                        }}
                      >
                        <Typography key={idx}>
                          {' '}
                          <Typography
                            variant="subtitle2"
                            sx={{ fontWeight: 'bold' }}
                          >
                            {item.extra_type?.toUpperCase()}
                          </Typography>
                          {item.name}
                        </Typography>
                        <IconButton
                          aria-label="delete"
                          size="small"
                          onClick={() => handleDeleteExtras(item)}
                        >
                          <Iconify icon={'eva:trash-2-outline'} />
                        </IconButton>
                      </Box>
                      <RHFEditor
                        placeholder="Description"
                        isSimpleForProductsExtras
                        simple
                        value={item.content}
                        name={`extrasContent_${item.id}`}
                        onChange={(e) => handleExtraContent(e, item)}
                        key={item.id}
                      />
                    </Fragment>
                  ))}

                {extrasSelected?.length === 0 && (
                  <EmptyContent
                    title={'No Extras Selected'}
                    sx={{
                      '& span.MuiBox-root': { height: 160 },
                    }}
                  />
                )}

                <Stack alignItems="flex-end" sx={{ mt: 3 }}>
                  {!isLoading && (
                    <LoadingButton
                      type="submit"
                      variant="contained"
                      loading={isSubmitting}
                    >
                      {isEmpty(currentProduct)
                        ? 'Create Product'
                        : 'Save Changes'}
                    </LoadingButton>
                  )}
                </Stack>
              </Stack>
            </Grid>
          </Grid>
        </FormProvider>
      </Container>
    </Page>
  );
}
