import { useState, useEffect } from 'react';
import { useSnackbarMsg } from '@/hooks/useSnackbarMsg';
import { isEmpty } from 'lodash';
// mui
import { Checkbox, FormControlLabel, Stack, Skeleton } from '@mui/material';
// custom component
import { SectionLabel } from '../../..';
// redux
import { useSelector, useDispatch } from '@/stores';
import {
  addServiceExtra,
  removeServiceExtra,
} from '@/features/bookings-management/slices';
// API
import {
  getSingleVariant,
  getSingleService,
} from '@/features/services-management/api';
// types
import { ServiceCleaningExtra } from '@/features/services-management/types';
import { ServiceVariant } from '@/types';

export default function ServiceExtras() {
  const [availableExtras, setAvailableExtras] = useState<
    ServiceCleaningExtra[]
  >([]);
  const [isExtrasReady, setIsExtrasReady] = useState<boolean>(false);

  const { errorMsg } = useSnackbarMsg();

  // REDUX
  const dispatch = useDispatch();
  const { all_extras_from_api: serviceExtras } = useSelector(
    (state) => state.booking
  );
  const selectedServiceVariant = useSelector(
    (state) => state.booking.selectedServiceVariant
  );

  const selectedServiceExtras = useSelector(
    (state) => state.booking.selectedServiceCleaningExtras
  );

  //
  useEffect(() => {
    async function getSetExtras() {
      try {
        const fullVariantObject = await getSingleVariant(
          (selectedServiceVariant as ServiceVariant).id
        );
        const serviceObject = await getSingleService(
          fullVariantObject.service.id
        );
        setAvailableExtras(serviceObject.service_cleaning_extras);
      } catch (error) {
        errorMsg(
          error,
          `Something went wrong while fetching variants and extras: ${error.message}`
        );
      }
    }

    !isEmpty(selectedServiceVariant) &&
      getSetExtras().finally(() => setIsExtrasReady(true));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedServiceVariant]);
  // ---------------------------------------------------
  //             SET VALUES IN REDUX STORE
  // ---------------------------------------------------

  // ADD SELECTED TO SELECTEDEXTRAS
  const handleCheckboxSelection = (
    checked: boolean,
    extra: ServiceCleaningExtra
  ) => {
    checked
      ? dispatch(addServiceExtra(extra))
      : dispatch(removeServiceExtra(extra));
  };

  // ---------------------------------------------------
  //    CHECKBOXES BASED ON SELECTED SERVICE VARIANT
  // ---------------------------------------------------

  const DefaultExtras = () => (
    <>
      {serviceExtras ? (
        serviceExtras.map((extra, index: number) => (
          <FormControlLabel
            control={<Checkbox disabled />}
            label={extra.name}
            key={index}
            sx={{ ml: 0 }}
          />
        ))
      ) : (
        <Skeleton variant="text" width={200} />
      )}
    </>
  );

  const AvailableExtras = () => (
    <>
      {availableExtras.map((extra, index: number) => (
        <FormControlLabel
          control={
            <Checkbox
              checked={selectedServiceExtras.some(
                (x) => x.id === extra.cleaning_extra.id
              )}
              onChange={(e) => handleCheckboxSelection(e.target.checked, extra)}
            />
          }
          label={extra.cleaning_extra.name}
          key={index}
          sx={{ ml: 0 }}
        />
      ))}
    </>
  );

  return (
    <Stack direction="column" spacing={2}>
      <SectionLabel label="Service Extras:" />

      {!isEmpty(selectedServiceVariant) && (
        <Stack direction="column" spacing={1}>
          <>{isExtrasReady ? <AvailableExtras /> : <DefaultExtras />}</>
        </Stack>
      )}
    </Stack>
  );
}
