import { useState, useEffect } from 'react';
// @mui
import {
  Box,
  Tab,
  Tabs,
  Card,
  Table,
  Divider,
  TableBody,
  Container,
  TableContainer,
  TablePagination,
  Skeleton,
} from '@mui/material';
import { useSelector } from '@/stores';
import useTabs from '@/hooks/useTabs';
import useSettings from '@/hooks/useSettings';
import useTable, { emptyRows } from '@/hooks/useTable';
// components
import Page from '@/components/Page';
import Scrollbar from '@/components/Scrollbar';
import HeaderBreadcrumbs from '@/components/HeaderBreadcrumbs';
import { TableNoData, TableEmptyRows } from '@/components/table';
// sections
import { getNotificationRequest } from '../api';
import { NotificationObjectProps } from '../types';
import { NotificationTableToolbar, NotificationTableRow } from '../components';
import { applySortFilter, getLables } from '../utils';

const STATUS_OPTIONS = ['all', 'unread', 'read'];

let ROLE_OPTIONS: string[] = ['all'];

export default function NotificationsList() {
  const {
    dense,
    page,
    rowsPerPage,
    setPage,
    onChangePage,
    onChangeRowsPerPage,
  } = useTable();

  const { themeStretch } = useSettings();
  const [tableData, setTableData] = useState<NotificationObjectProps[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [filterName, setFilterName] = useState('');
  const [filterTable, setFilterTable] = useState('all');
  const [filterFrom, setFilterFrom] = useState<Date | null>(new Date());
  const [filterTo, setFilterTo] = useState<Date | null>(new Date());
  const { currentTab: filterStatus, onChangeTab: onChangeFilterStatus } =
    useTabs('all');
  const { totalUnseen } = useSelector((state) => state.notfication);

  const handleFilterName = (filterName: string) => {
    setFilterName(filterName);
    setPage(0);
  };

  const handlefilterTable = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFilterTable(event.target.value);
  };

  const handleFilterToDate = (date: Date | null) => {
    setFilterFrom(date);
    setPage(0);
  };

  const handleFilterFromDate = (date: Date | null) => {
    setFilterTo(date);
    setPage(0);
  };

  const dataFiltered = applySortFilter({
    tableData,
    filterName,
    filterTable,
    filterStatus,
    filterFrom,
    filterTo,
  });

  const denseHeight = dense ? 52 : 72;

  const isNotFound =
    (!dataFiltered.length && !!filterName) ||
    (!dataFiltered.length && !!filterTable) ||
    (!dataFiltered.length && !!filterStatus);

  useEffect(() => {
    async function fetchNotifications() {
      const { notifications } = await getNotificationRequest();
      ROLE_OPTIONS = ['all'].concat(getLables(notifications));
      setTableData(notifications);
      setIsLoading(false);
      setPage(0);
    }
    fetchNotifications();
  }, [totalUnseen, setPage]);

  return (
    <Page title="Notification: List">
      <Container maxWidth={themeStretch ? false : 'lg'}>
        <HeaderBreadcrumbs heading="Notifications" links={[{ name: 'List' }]} />

        <Card>
          {isLoading ? (
            <Box sx={{ width: 300 }} pl={2} pb={0.5}>
              <Skeleton width={400} height={52} />
            </Box>
          ) : (
            <Tabs
              allowScrollButtonsMobile
              variant="scrollable"
              scrollButtons="auto"
              value={filterStatus}
              onChange={onChangeFilterStatus}
              sx={{ px: 2, bgcolor: 'background.neutral' }}
            >
              {STATUS_OPTIONS.map((tab) => (
                <Tab disableRipple key={tab} label={tab} value={tab} />
              ))}
            </Tabs>
          )}
          <Divider />

          <NotificationTableToolbar
            filterName={filterName}
            filterTable={filterTable}
            optionsRole={ROLE_OPTIONS}
            onFilterName={handleFilterName}
            onFilterTable={handlefilterTable}
            onFilterFromDate={handleFilterToDate}
            onFilterToDate={handleFilterFromDate}
          />

          <Scrollbar>
            <TableContainer sx={{ minWidth: 800, position: 'relative' }}>
              {isLoading ? (
                <Box sx={{ width: 500 }} pl={2} pb={1}>
                  <Skeleton width={200} height={44} />
                  <Skeleton width={100} height={44} />
                  <Skeleton width={400} height={44} />
                </Box>
              ) : (
                <Table>
                  <TableBody>
                    {dataFiltered
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((row) => (
                        <NotificationTableRow key={row.id} row={row} />
                      ))}

                    <TableEmptyRows
                      height={denseHeight}
                      emptyRows={emptyRows(page, rowsPerPage, tableData.length)}
                    />

                    <TableNoData isNotFound={isNotFound} />
                  </TableBody>
                </Table>
              )}
            </TableContainer>
          </Scrollbar>
          {isLoading ? (
            ''
          ) : (
            <Box sx={{ position: 'relative' }}>
              <TablePagination
                rowsPerPageOptions={[25, 50, 100]}
                component="div"
                count={dataFiltered.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={onChangePage}
                onRowsPerPageChange={onChangeRowsPerPage}
              />
            </Box>
          )}
        </Card>
      </Container>
    </Page>
  );
}
