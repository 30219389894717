import { useEffect, useState } from 'react';
import { isEmpty } from 'lodash';
// api
import { getSingleCategoryData } from '../api';
// react router
import { useParams, useLocation } from 'react-router-dom';
// @mui
import { Container } from '@mui/material';
// hooks
import useSettings from '@/hooks/useSettings';
// components
import { Page, HeaderBreadcrumbs } from '@/components';
// custom components
import { CategoryCreateEdit } from './index';
// types
import type { Category } from '../types';
// paths
import { PATH_SETTINGS } from '../routes/paths';
import { PATH_USER } from '@/features/app';

// ----------------------------------------------------------------------

export default function CategoryCreate() {
  const { themeStretch } = useSettings();
  const [currentCategory, setCurrentCategory] = useState<Category | {}>({});

  const { pathname } = useLocation();

  const { id } = useParams();

  const isEdit = pathname.includes('edit');

  useEffect(() => {
    const fetchData = async () => {
      const data = await getSingleCategoryData(Number(id));
      setCurrentCategory(data);
    };

    id && fetchData();
  }, [id]);

  return (
    <Page title="Create new category">
      <Container maxWidth={themeStretch ? false : 'lg'}>
        <HeaderBreadcrumbs
          heading={!isEdit ? 'Add new category' : 'Edit category'}
          links={[
            { name: 'Dashboard', href: PATH_USER.root },
            {
              name: 'Settings',
              href: `${PATH_SETTINGS.root}${PATH_SETTINGS.categories}`,
            },
            {
              name: 'Categories',
              href: `${PATH_SETTINGS.root}${PATH_SETTINGS.categories}`,
            },
            {
              name: !isEdit
                ? 'Add New Category'
                : isEmpty(currentCategory)
                ? ''
                : (currentCategory as Category).name,
            },
          ]}
        />
        <CategoryCreateEdit
          currentCategory={currentCategory as Category}
          categoryID={Number(id)}
        />
      </Container>
    </Page>
  );
}
