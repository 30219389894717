import { useState } from 'react';
// @mui
import { ListItemButton, Stack, Typography } from '@mui/material';
import Scrollbar from '@/components/Scrollbar';
import EmptyContent from '@/components/EmptyContent';
// custom component
import { ToolbarWithSearchAndStatus } from '@/components/toolbar';
// utils + hooks
import { applyFilterSearchbar } from '@/features/bookings-management/utils';
import useTable, { getComparator } from '@/hooks/useTable';
// Redux
import { useSelector, dispatch } from '@/stores';
import {
  setAddress,
  setValidationError,
} from '@/features/bookings-management/slices';
// types
import { Label } from '@/components';
import { UserAddress } from '@/features/users/shared/types';

type SearchAddressProps = {
  onClose: VoidFunction;
  addressOptions: UserAddress[];
};

export default function SearchAddress({
  onClose,
  addressOptions,
}: SearchAddressProps) {
  const booking = useSelector((state) => state.booking);

  const handleChange = (addressObject: UserAddress) => {
    dispatch(setAddress(addressObject));
    booking.validationErrors.address &&
      dispatch(setValidationError({ field: 'address', error: null }));
    onClose();
  };

  // ---------------------------------------------
  //              SEARCH BAR FILTERING
  // ---------------------------------------------

  const [searchFieldValue, setSearchFieldValue] = useState<string>('');
  const handleSearchFieldValue = (searchFieldValue: string) => {
    setSearchFieldValue(searchFieldValue);
  };
  const { order, orderBy } = useTable();

  const dataFiltered = applyFilterSearchbar({
    tableData: addressOptions ? addressOptions : [],
    comparator: getComparator(order, orderBy),
    searchFieldValue,
  });

  const isNotFound = !dataFiltered.length && !!searchFieldValue;

  return (
    <Stack direction="column">
      <ToolbarWithSearchAndStatus
        filterText={searchFieldValue}
        onFilterText={handleSearchFieldValue}
        autoFocusSearchfield
      />
      <Scrollbar sx={{ p: 1.5, pt: 0, maxHeight: 80 * 8 }}>
        {(dataFiltered as UserAddress[]).map((address) => (
          <ListItemButton
            key={address.id}
            selected={address.id === (booking.address as UserAddress).id}
            onClick={() => handleChange(address)}
            sx={{
              p: 1.5,
              borderRadius: 1,
              flexDirection: 'column',
              alignItems: 'flex-start',
            }}
          >
            <Stack direction="row" spacing={1}>
              <Typography variant="subtitle2">{address.name}</Typography>
              {address.default && (
                <Label color="primary" fontSize={10}>
                  default
                </Label>
              )}
            </Stack>

            <Typography
              variant="caption"
              sx={{
                color: 'primary.main',
                my: 0.5,
                fontWeight: 'fontWeightMedium',
              }}
            >
              {address.line_1}
            </Typography>

            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
              {address.postcode}
            </Typography>
            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
              {address.town_or_city}
            </Typography>
          </ListItemButton>
        ))}
        {isNotFound && <EmptyContent title="No address found" />}
      </Scrollbar>
    </Stack>
  );
}
