import { useEffect, useState } from 'react';
import {
  DefaultGenerics,
  ExtendableGenerics,
  StreamChat,
  TokenOrProvider,
  User,
} from 'stream-chat';

export type UseClientOptions = {
  apiKey: string;
  user: User;
  tokenOrProvider: TokenOrProvider;
};

export const useChatClient = <
  SCG extends ExtendableGenerics = DefaultGenerics,
>({
  apiKey,
  user,
  tokenOrProvider,
}: UseClientOptions) => {
  const [chatClient, setChatClient] = useState<StreamChat<SCG> | null>(null);
  useEffect(() => {
    const client = new StreamChat<SCG>(apiKey);
    // prevents application from setting stale client (user changed, for example)
    let didUserConnectInterrupt = false;

    const connectionPromise = client
      .connectUser(user, tokenOrProvider)
      .catch((e) => {
        console.error(`Failed to connect user`, e);
      })
      .then(() => {
        if (!didUserConnectInterrupt) {
          setChatClient(client);
        }
      });

    return () => {
      connectionPromise.then(() => {
        setChatClient(null);
        client.disconnectUser().catch((e) => {
          console.error(`Failed to disconnect user`, e);
        });
      });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps -- should re-run only if user.id changes
  }, [apiKey, user.id, tokenOrProvider]);

  return chatClient;
};
