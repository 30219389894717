import { useQuery } from '@tanstack/react-query';
//
import {
  Avatar,
  Box,
  Chip,
  Grid,
  IconButton,
  List,
  ListItem,
  Stack,
  Typography,
} from '@mui/material';
import { Iconify, Label } from '@/components';
import { VBMCardWrapper, VBMCardTitle } from '..';
//
import { returnStatusObject } from '@/features/bookings-management/utils';
import { getSettingsData } from '@/features/app/api';
//
import { LabelColors } from '@/types';
import { BookingSessionStatusHistory } from '@/features/bookings-management/types';

type StatusHistoryProps = {
  statusHistory: BookingSessionStatusHistory[];
};

export default function VBMStatusHistory({
  statusHistory,
}: StatusHistoryProps) {
  const { data: bookingSessionStatuses } = useQuery({
    queryKey: ['settings'],
    queryFn: getSettingsData,
    select: (data) => data.bookingSessionStatuses,
    refetchOnWindowFocus: false,
  });
  return (
    <VBMCardWrapper>
      <VBMCardTitle title="Status history" sx={{ mb: 1 }} />
      <List
        sx={{
          overflow: 'auto',
          maxHeight: 200,
          backgroundColor: 'background.paper',
          borderRadius: 1,
          py: 0,
        }}
      >
        <>
          {statusHistory.map((el, index) => (
            <Stack
              component={ListItem}
              key={el.created_at + el.status}
              sx={{
                backgroundColor: !!(index % 2)
                  ? 'background.paper'
                  : 'background.neutral',
              }}
            >
              <Grid container spacing={1} alignItems="center">
                {/* CREATED AT */}
                <Grid item container alignSelf="flex-start" xs={3.5}>
                  <Stack direction="row" spacing={0.5}>
                    <Typography fontSize={12} fontWeight={500} textAlign="left">
                      {el.created_at}
                    </Typography>
                  </Stack>
                </Grid>

                {/* STATUS LABEL */}
                <Grid item container alignSelf="flex-start" xs={3}>
                  <Label
                    color={
                      returnStatusObject(
                        el.status,
                        bookingSessionStatuses || []
                      ).style_class as LabelColors
                    }
                    sx={{ alignSelf: 'flex-start' }}
                  >
                    {
                      returnStatusObject(
                        el.status,
                        bookingSessionStatuses || []
                      ).display
                    }
                  </Label>
                </Grid>

                {/* USER CHIP */}
                <Grid item container alignSelf="flex-start" xs={4}>
                  <Chip
                    size="small"
                    avatar={
                      <Avatar alt={el.full_name} src={el.profile_image} />
                    }
                    label={
                      <Stack
                        direction="row"
                        spacing={0.5}
                        alignItems="center"
                        sx={{ mr: el.is_admin ? -0.5 : 'inherit' }}
                      >
                        <Typography fontSize={12}>{el.full_name}.</Typography>
                        {el.is_admin && (
                          <Iconify
                            icon="mdi:shield-star-outline"
                            width={15}
                            color="info.main"
                          />
                        )}
                      </Stack>
                    }
                    variant="outlined"
                  />
                </Grid>

                {/* LOCATION PIN WITH COORDINATES */}
                <Grid item container alignSelf="flex-start" xs={1}>
                  {el.latitude && el.longitude && (
                    <IconButton
                      size="small"
                      href={`https://www.google.com/maps/search/?api=1&query=${el.latitude},${el.longitude}`}
                      target="_blank"
                      rel="noopener noreferrer"
                      color="info"
                      sx={{ display: 'flex' }}
                    >
                      <Iconify icon="eva:pin-outline" />
                    </IconButton>
                  )}
                </Grid>
              </Grid>

              {/* EXTRA NOTES IN CASE OF REVIEW */}
              {el.show_extra_notes && (
                <Box
                  component={Typography}
                  fontSize={13}
                  fontStyle="italic"
                  sx={{ alignSelf: 'start', pt: 0.5 }}
                >
                  This job has been flagged for review. Contact the
                  provider/client.
                </Box>
              )}
            </Stack>
          ))}
        </>
      </List>
    </VBMCardWrapper>
  );
}
