import { BaseObjectEntity } from '@/types';
import { path } from '@/utils/formatLink';

export const ROOTS_PRODUCTS = '/products';
export const ROOTS_MANAGE = '/manage';

export const PATH_PRODUCTS: BaseObjectEntity = {
  root: ROOTS_PRODUCTS,
  new: 'new',
  edit: ':id/edit',
};

export function productsPath(
  pathKey: string,
  key: string = '',
  value: number | string = ''
) {
  return key
    ? path(ROOTS_PRODUCTS, `${ROOTS_MANAGE}/${PATH_PRODUCTS[pathKey]}`).replace(
        `:${key}`,
        `${String(value)}`
      )
    : path(ROOTS_PRODUCTS, `${ROOTS_MANAGE}/${PATH_PRODUCTS[pathKey]}`);
}
