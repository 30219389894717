import { tableSortFilter } from '@/utils/arrayProcessing';
import { Review, ReviewAndDayoffStatus } from '../types';

export const REVIEWS_TABLE_HEAD = [
  { id: 'emptyCell' },
  { id: 'id', label: 'Review ID', align: 'left' },
  { id: 'client.first_name', label: 'Client', align: 'left' },
  { id: 'rating', label: 'Rating', align: 'left' },
  { id: 'review', label: 'Review', align: 'left' },
  { id: 'cleaner.first_name', label: 'Cleaner', align: 'left' },
  { id: 'status', label: 'Status', align: 'left' },
  { id: 'created_at', label: 'Created at', align: 'left' },
  { id: 'actionBtns', label: '', align: 'left' },
];

export const REVIEW_STATUSES: ReviewAndDayoffStatus[] = [
  {
    id: 1,
    name: 'published',
    color: 'success',
    actionVerb: 'publish',
    icon: 'eva:checkmark-outline',
  },
  {
    id: 2,
    name: 'pending',
    color: 'warning',
    actionVerb: 'set pending',
    icon: 'eva:pause-circle-outline',
  },
  {
    id: 3,
    name: 'rejected',
    color: 'error',
    actionVerb: 'reject',
    icon: 'eva:close-outline',
  },
];

export function applySortFilterReviews({
  tableData,
  comparator,
  searchFieldValue,
}: {
  tableData: Review[];
  comparator: (a: any, b: any) => number;
  searchFieldValue: string;
}) {
  tableData = tableSortFilter(tableData, comparator);
  const searchFieldLowerCase = searchFieldValue.toLowerCase();

  if (searchFieldValue) {
    tableData = tableData.filter((item) => {
      const clientFullName = `${item.client.first_name} ${item.client.last_name}`;
      const cleanerFullName = `${item.cleaner.first_name} ${item.cleaner.last_name}`;
      return (
        item.id.toString().indexOf(searchFieldValue) !== -1 ||
        clientFullName.toLowerCase().indexOf(searchFieldLowerCase) !== -1 ||
        cleanerFullName.toLowerCase().indexOf(searchFieldLowerCase) !== -1 ||
        item.review.toLowerCase().indexOf(searchFieldLowerCase) !== -1 ||
        item.rating.toString().indexOf(searchFieldValue) !== -1
      );
    });
  }

  return tableData;
}
