import { Image } from '@/components';

type LoginFormLogoProps = {
  imageSrc: string;
};
export default function LoginFormLogo({ imageSrc }: LoginFormLogoProps) {
  return (
    <Image
      sx={{ width: '170px', padding: '1.5rem 0 0 1.5rem' }}
      visibleByDefault
      disabledEffect
      src={imageSrc}
      alt="company_logo_icon"
    />
  );
}
