import { useState, MouseEvent } from 'react';

export const use3DotMenu = () => {
  const [openMenu, setOpenMenuActions] = useState<HTMLElement | null>(null);

  const handleOpenMenu = (event: MouseEvent<HTMLElement>) =>
    setOpenMenuActions(event.currentTarget);

  const handleCloseMenu = () => setOpenMenuActions(null);

  return { openMenu, handleOpenMenu, handleCloseMenu };
};
