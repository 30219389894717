import { generatePath } from 'react-router';
import { path } from '@/utils/formatLink';
import { BaseObjectEntity } from '@/types';

export const ROOTS_CLIENT_MANAGEMENT = '/clients';

export const PATH_CLIENT_MANAGEMENT: BaseObjectEntity = {
  root: ROOTS_CLIENT_MANAGEMENT,
  new: '/new',
  edit: '/:userId/:tabSid',
};

export function clientManagementPath(
  pathKey: string,
  params?: BaseObjectEntity
) {
  return generatePath(
    path(ROOTS_CLIENT_MANAGEMENT, PATH_CLIENT_MANAGEMENT[pathKey]),
    params
  );
}
