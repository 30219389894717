import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useForm } from 'react-hook-form';
import { boolean, object, string } from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
// mui
import { Card, Grid, MenuItem, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
//
import { FormProvider, RHFTextField, RHFSwitch } from '@/components/hook-form';
import { SkeletonLoadingTableData } from '@/components/skeleton';
import { ServiceExtrasInputWrapper } from '@/features/services-management/components';
// API
import {
  createService,
  updateService,
} from '@/features/services-management/api';
// hooks + utils
import { useSnackbarMsg } from '@/hooks/useSnackbarMsg';
//
import { STATUS_OPTIONS } from '@/features/roles-and-permissions/utils';
import { processServiceFormObject } from '@/features/services-management/utils';
import { servicesManagementPath } from '@/features/services-management/routes/paths';
// types
import {
  ServiceDto,
  SingleService,
} from '@/features/services-management/types';
import { ServiceExtra } from '@/types';

type FormProps = {
  currentService: SingleService | undefined;
  defaultServiceExtras: ServiceExtra[];
  defaultValues: Record<string, string | number | boolean>;
};

// ----------------------------------------------------------------------

export default function ServiceForm({
  currentService,
  defaultServiceExtras,
  defaultValues,
}: FormProps) {
  const { errorMsg, successMsg } = useSnackbarMsg();
  const { serviceId } = useParams();
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  // ========================
  //     REACT HOOK FORM
  // ========================
  const ServiceSchema = object().shape({
    service_name: string().required('Name is required'),
    service_status: string().required('Status is required'),
    multiple_dates: boolean(),
  });

  const methods = useForm<any>({
    resolver: yupResolver(ServiceSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    reset,
    formState: { isSubmitting },
  } = methods;

  useEffect(() => {
    reset(defaultValues);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultValues]);

  // =================
  //      QUERIES
  // =================

  // CREATE
  const createServiceMutation = useMutation({
    mutationFn: (serviceDto: ServiceDto) => createService(serviceDto),
    onSuccess: () => {
      queryClient.invalidateQueries(['services']);
      successMsg('Service successfully created!');
      navigate(servicesManagementPath('services'));
    },
    onError: (error) =>
      errorMsg(error, `Something went wrong while creating service`),
  });

  // UPDATE
  const updateServiceMutation = useMutation({
    mutationFn: ({
      serviceId,
      serviceDto,
    }: {
      serviceId: number;
      serviceDto: ServiceDto;
    }) => updateService(serviceId, serviceDto),
    onSuccess: () => {
      queryClient.invalidateQueries(['services', serviceId]);
      successMsg('Services successfully updated!');
      navigate(servicesManagementPath('services'));
    },
    onError: (error) =>
      errorMsg(error, `Something went wrong while updating service`),
  });

  const submitService = async (data: any) => {
    const serviceDto: ServiceDto = {
      name: data.service_name,
      active: data.service_status === 'Active' ? true : false,
      allow_multiple_dates: data.multiple_dates,
      service_extras: processServiceFormObject(data),
    };
    currentService
      ? updateServiceMutation.mutate({
          serviceId: currentService.id,
          serviceDto,
        })
      : createServiceMutation.mutate(serviceDto);
  };

  return (
    <Card sx={{ p: 3 }}>
      {serviceId && !currentService ? (
        <SkeletonLoadingTableData />
      ) : (
        <FormProvider methods={methods} onSubmit={handleSubmit(submitService)}>
          <Grid container spacing={3}>
            <Grid item container spacing={2}>
              <Grid item xs={12} md={6}>
                <RHFTextField
                  name="service_name"
                  label="Service Name"
                  size="small"
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <RHFTextField
                  name="service_status"
                  label="Status"
                  size="small"
                  select
                >
                  {STATUS_OPTIONS.map((option) => (
                    <MenuItem key={option} value={option}>
                      {option}
                    </MenuItem>
                  ))}
                </RHFTextField>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <RHFSwitch
                name="multiple_dates"
                labelPlacement="end"
                label={
                  <Typography variant="subtitle2">
                    Allow multiple dates selection
                  </Typography>
                }
                sx={{ mx: 0, width: 1, alignItems: 'center', gap: 1 }}
              />
            </Grid>

            <Grid item xs={12}>
              <Typography fontWeight={700}>Cleaning Extras</Typography>
            </Grid>

            <Grid
              container
              item
              xs={12}
              alignItems="center"
              spacing={2.5}
              sx={{ ml: 2 }}
            >
              {(defaultServiceExtras || []).map((extra) => (
                <ServiceExtrasInputWrapper
                  key={extra.id}
                  checkboxRHFName={`service_extra_checkbox_${extra.id}`}
                  checkboxLabel={extra.name}
                  textfieldRHFName={`service_extra_textfield_${extra.id}`}
                  textfieldLabel={'Extra hours'}
                />
              ))}
            </Grid>

            <Grid container item justifyContent="end">
              <Grid item>
                <LoadingButton
                  sx={{ alignSelf: 'end' }}
                  type="submit"
                  variant="contained"
                  loading={isSubmitting}
                >
                  {!currentService ? 'Create Service' : 'Update Service'}
                </LoadingButton>
              </Grid>
            </Grid>
          </Grid>
        </FormProvider>
      )}
    </Card>
  );
}
