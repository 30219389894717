// mui
import { SxProps, Typography } from '@mui/material';

type Prop = {
  label: string;
  sx?: SxProps;
};

export default function SectionLabel({ label, sx }: Prop) {
  return (
    <Typography variant="h6" sx={{ color: 'text.primary', ...sx }}>
      {label}
    </Typography>
  );
}
