import { Product } from '../types';

export function applySortFilterForProductList({
  tableData,
  comparator,
  filterName,
  filterCategory,
  filterStatus,
}: {
  tableData: Product[];
  comparator: (a: any, b: any) => number;
  filterName: string;
  filterCategory: string;
  filterStatus: string;
}) {
  const stabilizedThis = tableData.map((el, index) => [el, index] as const);

  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });

  tableData = stabilizedThis.map((el) => el[0]);

  if (filterName) {
    tableData = tableData.filter(
      ({ name, description, provider: { name: nameProvider } }) =>
        name.toLowerCase().indexOf(filterName.toLowerCase()) !== -1 ||
        description.toLowerCase().indexOf(filterName.toLowerCase()) !== -1 ||
        nameProvider.toLowerCase().indexOf(filterName.toLowerCase()) !== -1
    );
  }

  if (filterStatus !== 'all') {
    tableData = tableData.filter(({ active }) =>
      active === true
        ? filterStatus.toLowerCase() === 'active'
        : filterStatus.toLowerCase() === 'inactive'
    );
  }
  if (filterCategory !== 'all') {
    tableData = tableData.filter(
      ({ product_category: { name } }) =>
        name.toLowerCase().indexOf(filterCategory.toLowerCase()) !== -1
    );
  }
  return tableData;
}
