import { Checkbox, FormControlLabel, Stack } from '@mui/material';
//
import { useSelector, dispatch } from '@/stores';
import SectionLabel from '../label-partial/SectionLabel';
import {
  setEssentialAmenities,
  setLinen,
} from '@/features/bookings-management/slices';

export default function LinenAmnetities() {
  const { linen, essential_amenities } = useSelector(
    (state) => state.booking.cleanDetails
  );

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.name === 'linen') {
      dispatch(setLinen(e.target.checked));
    } else {
      dispatch(setEssentialAmenities(e.target.checked));
    }
  };

  return (
    <Stack direction="column" spacing={1}>
      <SectionLabel label="Linen and Amenities:" />
      <FormControlLabel
        control={<Checkbox checked={linen} onChange={(e) => onChange(e)} />}
        label="Linen"
        name="linen"
      />
      <FormControlLabel
        control={
          <Checkbox
            checked={essential_amenities}
            onChange={(e) => onChange(e)}
          />
        }
        label="Essential Amenities"
        name="essential_amenities"
      />
    </Stack>
  );
}
