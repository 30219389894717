// @mui
import {
  Box,
  Button,
  Container,
  Dialog,
  Stack,
  Tab,
  Tabs,
  Typography,
} from '@mui/material';
// hooks
import useTabs from '@/hooks/useTabs';
// types

type Props = {
  open: boolean;
  onClose: VoidFunction;
  defaultTab?: string;
  tabOptions: any[];
  label: string;
  //
  buttons: boolean;
  addButtonLabel?: string;
  isAddBtnDisabled?: boolean;
};

export default function ListDialog({
  open,
  onClose,
  tabOptions,
  defaultTab = tabOptions[0].value,
  label,
  //
  buttons,
  addButtonLabel,
  isAddBtnDisabled,
}: Props) {
  const { currentTab, onChangeTab } = useTabs(defaultTab);

  return (
    <Dialog fullWidth maxWidth="xs" open={open} onClose={onClose}>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        sx={{ py: 2.5, px: 3 }}
      >
        <Typography variant="h6"> {label} </Typography>
      </Stack>
      <Box sx={{ overflowY: 'hidden' }}>
        <Tabs
          allowScrollButtonsMobile
          variant="scrollable"
          scrollButtons="auto"
          value={currentTab}
          onChange={onChangeTab}
          sx={{ pb: 1, pl: 4 }}
          aria-label={`${label} tabs`}
        >
          {tabOptions.map((tab, index) => (
            <Tab
              disableRipple
              key={index}
              value={tab.value}
              icon={tab.icon}
              label={tab.value}
            />
          ))}
        </Tabs>

        {tabOptions.map((tab, index) => {
          const isMatched = tab.value === currentTab;
          return (
            isMatched && (
              <Container sx={{ minHeight: '80vh' }} key={index}>
                {tab.component}
              </Container>
            )
          );
        })}
      </Box>
      {buttons && (
        <Stack
          direction="row"
          justifyContent="flex-end"
          spacing={2}
          sx={{ p: 2 }}
        >
          <Button
            variant="contained"
            color="inherit"
            sx={{ py: 1, px: 2, width: '50%' }}
            onClick={onClose}
          >
            Cancel
          </Button>
          <Button
            type="submit"
            variant="contained"
            sx={{ py: 1, px: 2, width: '50%' }}
            onClick={onClose}
            disabled={isAddBtnDisabled}
          >
            {addButtonLabel}
          </Button>
        </Stack>
      )}
    </Dialog>
  );
}
