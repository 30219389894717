import { Link } from 'react-router-dom';
import { fCurrency } from '@/utils/formatNumber';
import { fDate } from '@/utils/formatTime';
// @mui
import {
  Button,
  Checkbox,
  Chip,
  MenuItem,
  Stack,
  TableRow,
  TableCell,
  Typography,
} from '@mui/material';
// components
import { Iconify, Label } from '@/components';
import { TableMoreMenu, TableRowLink } from '@/components/table';
import Dialog from '@/components/dialog/Dialog';
// hooks + utils
import useToggle from '@/hooks/useToggle';
import { bookingsManagementPath } from '@/features/bookings-management';
import { clientManagementPath } from '@/features/users/clients';
import { providersPath } from '@/features/users/providers';
import { use3DotMenu } from '@/hooks/use3DotMenu';
// types
import { Booking } from '../../../types';
import { PermissionsObject, LabelColors } from '@/types';

type BookingsTableRowProps = {
  row: Booking;
  permissions?: PermissionsObject;
  selected: boolean;
  onEditRow: VoidFunction;
  onSelectRow: VoidFunction;
  onDeleteRow: VoidFunction;
};

// ----------------------------------------------------------------------

export default function BookingsTableRow({
  row,
  permissions,
  selected,
  onEditRow,
  onSelectRow,
  onDeleteRow,
}: BookingsTableRowProps) {
  const {
    id,
    user,
    address,
    booking_cleaners,
    created_at,
    total_amount,
    booking_status,
    booking_service,
  } = row;
  const { canDelete, canUpdate } = permissions || {};

  const { openMenu, handleCloseMenu, handleOpenMenu } = use3DotMenu();

  const {
    toggle: isDialogOpen,
    onOpen: openDialog,
    onClose: closeDialog,
  } = useToggle();

  return (
    <>
      {isDialogOpen && (
        <Dialog
          dialogTitle="Delete booking"
          dialogText={`Are you sure you want to delete this booking: #${id}?`}
          dialogAgree={onDeleteRow}
          dialogDisagree={closeDialog}
          isDialogOpen={isDialogOpen}
          setIsDialogOpen={openDialog}
          handleCloseDialog={closeDialog}
          agreeButtonText="Delete"
          disagreeButtonText="Cancel"
          agreeButtonColor="error"
          disagreeButtonColor="inherit"
        />
      )}

      <TableRow hover selected={selected} sx={{ verticalAlign: 'center' }}>
        <TableCell padding="checkbox">
          <Checkbox checked={selected} onClick={onSelectRow} />
        </TableCell>

        {/* ID */}
        <TableCell>
          <Stack flexDirection="column" gap={0.5}>
            <TableRowLink
              linkText={id ?? 'N/A'}
              linkTo={bookingsManagementPath('view', {
                bookingId: String(id),
                bookingTabSid: 'booking-sessions',
                sessionFilter: 'today',
              })}
              sx={{ width: 'fit-content', ml: -0.5 }}
              target="_blank"
            />

            <Button
              variant="outlined"
              size="small"
              sx={{
                py: 0,
                px: 1,
                alignSelf: 'start',
                fontSize: 10,
                minWidth: 'fit-content',
              }}
              component={Link}
              to="#"
            >
              CHAT
            </Button>
          </Stack>
        </TableCell>

        {/* SERVICE NAME */}
        <TableCell align="left">
          <Typography>{booking_service[0]?.service_name ?? 'N/A'}</Typography>
        </TableCell>

        {/* CUSTOMER DETAILS */}
        <TableCell align="left">
          <Stack direction="column" spacing={0}>
            <TableRowLink
              linkText={user?.first_name + ' ' + user?.last_name}
              linkTo={clientManagementPath('edit', {
                userId: String(user.id),
                tabSid: 'details',
              })}
            />
            <TableRowLink
              linkText={user?.email ?? 'N/A'}
              linkTo={`mailto:${user?.email ?? ''}`}
            />
          </Stack>
        </TableCell>

        {/* ADDRESS NAME */}
        <TableCell align="left">
          <Typography>{address.name}</Typography>
        </TableCell>

        {/* POSTCODE */}
        <TableCell align="left">{address.postcode}</TableCell>

        {/* ASSIGNED */}
        <TableCell align="left">
          <Stack direction="row" spacing={1}>
            {booking_cleaners.map((cleaner) => (
              <Chip
                label={`${cleaner?.first_name ?? 'N/A'} ${
                  cleaner?.last_name ?? 'N/A'
                }`}
                key={cleaner?.id}
                color="primary"
                sx={{ cursor: 'pointer' }}
                component={Link}
                to={providersPath('edit', {
                  userId: String(cleaner.id),
                  tabSid: 'details',
                })}
                size="small"
              />
            ))}
          </Stack>
        </TableCell>

        {/* DATE ADDED */}
        <TableCell align="left">
          {created_at ? fDate(new Date(created_at), 'EEE, dd MMM yyy') : 'N/A'}
        </TableCell>

        {/* AMOUNT */}
        <TableCell align="left">{fCurrency(total_amount)}</TableCell>

        {/* STATUS */}
        <TableCell align="left">
          <Label
            color={(booking_status.style_class as LabelColors) || 'info'}
            variant={booking_status.value === 'NEW' ? 'filled' : 'ghost'}
          >
            {booking_status.display || 'N/A'}
          </Label>
        </TableCell>

        <TableCell align="right">
          {(canDelete || canUpdate) && (
            <TableMoreMenu
              open={openMenu}
              onOpen={handleOpenMenu}
              onClose={handleCloseMenu}
              actions={
                <>
                  {canUpdate && (
                    <MenuItem
                      onClick={() => {
                        onEditRow();
                        handleCloseMenu();
                      }}
                    >
                      <Iconify icon={'eva:edit-fill'} />
                      Edit
                    </MenuItem>
                  )}
                  {canDelete && (
                    <MenuItem
                      onClick={() => {
                        openDialog();
                        handleCloseMenu();
                      }}
                      sx={{ color: 'error.main' }}
                    >
                      <Iconify icon={'eva:trash-2-outline'} />
                      Delete
                    </MenuItem>
                  )}
                </>
              }
            />
          )}
        </TableCell>
      </TableRow>
    </>
  );
}
