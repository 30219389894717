import React, { useEffect, useMemo, useState } from 'react';
import { Avatar, useChatContext } from 'stream-chat-react';
import type { UserResponse } from 'stream-chat';
import type { StreamChatType } from '../../types';
import { useAdminPanelFormState } from './context/AdminPanelFormContext';
import { ValidationError } from './ValidationError';

const ListContainer = (props: { children: React.ReactNode }) => {
  const { children } = props;
  const { errors, createChannelType } = useAdminPanelFormState();
  const showHeading = !createChannelType || createChannelType === 'team';

  return (
    <div className="user-list__container">
      {showHeading && (
        <h2>
          <span>Add Members</span>
          <ValidationError errorMessage={errors.members} />
        </h2>
      )}
      <div className="user-list__header user-list__row">
        <div className="user-list__column-block">
          <p>User</p>
          <p className="user-list__column--last-active">Last Active</p>
        </div>
        <div className="user-list__column--checkbox">
          <p>Select</p>
        </div>
      </div>
      {children}
    </div>
  );
};

type UserItemProps = {
  index: number;
  user: UserResponse<StreamChatType>;
  type: string;
};

const UserItem = ({ index, user, type }: UserItemProps) => {
  const { handleMemberSelect } = useAdminPanelFormState();

  const lastActive = useMemo(() => {
    const now = new Date();
    const lastActiveDate = new Date(user.last_active as string);
    const diff = now.getTime() - lastActiveDate.getTime();
    const diffInMinutes = Math.round(diff / 60000);

    if (Number.isNaN(diffInMinutes)) {
      return 'Never Active';
    }
    if (diffInMinutes < 60) {
      return `${diffInMinutes} min ago`;
    } else if (diffInMinutes < 24 * 60) {
      return `${Math.round(diffInMinutes / 60)} hours ago`;
    } else {
      return `${Math.round(diffInMinutes / (60 * 24))} days ago`;
    }
  }, [user.last_active]);

  const title = user.name || user.id;

  return (
    <label htmlFor={user.id} title={title} className="user-list__row">
      <div className="user-list__column-block">
        <div className="user-list__column--user-data">
          <Avatar image={user.image} name={title} size={32} />
          <p className="user-item__name">{title}</p>
        </div>
        <p className="user-list__column--last-active">{lastActive}</p>
      </div>
      <div className="user-list__column--checkbox">
        <input
          type={type === 'team' ? 'checkbox' : 'radio'}
          name="members"
          id={user.id}
          value={user.id}
          onChange={handleMemberSelect}
        />
      </div>
    </label>
  );
};

type UserListLoadState = 'loading' | 'error' | 'empty';

const LOAD_STATE_NOTIFICATION: Record<UserListLoadState, string> = {
  empty: 'No users found.',
  error: 'Error loading, please refresh and try again.',
  loading: 'Loading users...',
};

export const UserList = () => {
  const { client, channel } = useChatContext<StreamChatType>();
  const { createChannelType } = useAdminPanelFormState();
  const [loadState, setLoadState] = useState<UserListLoadState | null>(null);
  const [users, setUsers] = useState<
    UserResponse<StreamChatType>[] | undefined
  >();
  const [searchTerm, setSearchTerm] = useState('');

  const filteredUsers = useMemo(() => {
    if (!users) return [];
    return users.filter(
      (user) =>
        user.name?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        user.id.toLowerCase().includes(searchTerm.toLowerCase())
    );
  }, [users, searchTerm]);

  const channelMembers = useMemo(
    () => (channel?.state.members ? Object.keys(channel.state.members) : []),
    [channel?.state?.members]
  );

  useEffect(() => {
    const getUsers = async () => {
      if (loadState) return;
      setLoadState('loading');

      let userSearchQuery = {};
      if (createChannelType === 'team') {
        userSearchQuery = {
          role: { $in: ['admin', 'moderator'] },
        };
      } else if (createChannelType === 'messaging') {
        userSearchQuery = {
          role: { $in: ['admin', 'moderator'] },
        };
      } else if (createChannelType === 'cleanerSupport') {
        userSearchQuery = {
          role: { $in: ['cleaner'] },
        };
      } else if (createChannelType === 'clientSupport') {
        userSearchQuery = {
          role: { $in: ['client'] },
        };
      }
      try {
        const response = await client.queryUsers(
          userSearchQuery,
          { id: 1 }, // Sorting criteria: sort by 'id' in ascending order
          { limit: 8 } // Options: limit the number of users to fetch to 8
        );

        if (response.users.length) {
          setUsers(response.users);
        } else {
          setLoadState('empty');
        }
      } catch (event) {
        setLoadState('error');
      }

      setLoadState(null);
    };

    if (client) getUsers();
  }, [client, channelMembers, createChannelType]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <input
        type="text"
        placeholder="Search Users..."
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        className="user-list__search"
      />
      <ListContainer>
        {loadState ? (
          <div className="user-list__message">
            {LOAD_STATE_NOTIFICATION[loadState]}
          </div>
        ) : (
          filteredUsers.map((user, i) => (
            <UserItem
              key={user.id}
              user={user}
              index={i}
              type="team" // Adjust based on your use case
            />
          ))
        )}
      </ListContainer>
    </>
  );
};
