import { useState, Fragment, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useSnackbar } from 'notistack';
// api
import { getSettingsFields, saveSettingsFields } from '../api';
// form
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { object, string, number } from 'yup';
// hooks
import useSettings from '@/hooks/useSettings';
import { useGetAccess } from '@/hooks/useAccess';
// @mui
import { LoadingButton } from '@mui/lab';
import {
  Box,
  Card,
  Container,
  Skeleton,
  Stack,
  Typography,
} from '@mui/material';
// types
import { SettingsFieldsData } from '../types';
// components
import { FormProvider, RHFSelect, RHFTextField } from '@/components/hook-form';
import { isEmpty } from 'lodash';
import { Page, HeaderBreadcrumbs } from '@/components';
// paths
import { PATH_USER } from '@/features/app';
import { cancelRequest } from '@/utils/axios';
import { useSnackbarMsg } from '@/hooks/useSnackbarMsg';
// ----------------------------------------------------------------------

export default function SettingsList() {
  const { themeStretch } = useSettings();
  const [fieldsData, setFieldsData] = useState<SettingsFieldsData[]>([]);

  const { id } = useParams();

  const { enqueueSnackbar } = useSnackbar();
  const { errorMsg } = useSnackbarMsg();

  const createValidationSchema = () => {
    const validationObject: any = {};

    if (!isEmpty(fieldsData)) {
      fieldsData?.forEach((field: SettingsFieldsData) => {
        validationObject[field.ref] = field.ref.includes('email')
          ? string()
              .email('Email must be a valid email address')
              .required('Email is required')
          : field.ref.includes('number') || field.ref.includes('phone')
          ? number()
              .typeError('Enter only numeric characters')
              .required(`Required ${field.name.toLocaleLowerCase()}`)
          : string().required(`Required ${field.name.toLocaleLowerCase()}`);
      });

      return object().shape(validationObject);
    }

    return object().shape({});
  };

  const methods = useForm<any>({
    resolver: yupResolver(createValidationSchema()),
    defaultValues: {},
    mode: 'onBlur',
  });

  const {
    reset,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const onInvalid = (error: any) => console.error(error);
  //
  const onSubmit = async (_data: any) => {
    // modifie data to assign it to value
    // const modifData = fieldsData.map((item) => {
    //   return {
    //     ...item,
    //     ...{ value: data[item.ref] },
    //   };
    // });
    try {
      await saveSettingsFields();
      reset();

      enqueueSnackbar('Update success!');
    } catch (error) {
      console.error(error);
    }
  };
  async function handleFieldSelection(id: number) {
    try {
      const fieldsDataResponse = await getSettingsFields(Number(id));
      setFieldsData(fieldsDataResponse);
      const defaults = fieldsDataResponse.reduce(
        (obj: any, item: any) => ((obj[item.ref] = item.value), obj),
        {}
      );
      reset(defaults);
    } catch (e) {
      errorMsg(e);
    }
  }

  useEffect(() => {
    handleFieldSelection(Number(id));
    // return () => cancelRequest();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  // get the access for the module
  const allow = useGetAccess('settings');
  return (
    <Page title="Settings: Company Settings">
      <Container maxWidth={themeStretch ? false : 'lg'}>
        <HeaderBreadcrumbs
          heading={'Settings'}
          links={[
            { name: 'Dashboard', href: PATH_USER.root },
            { name: 'Settings' },
          ]}
        />
        <FormProvider
          methods={methods}
          onSubmit={handleSubmit(onSubmit, onInvalid)}
        >
          <Card sx={{ p: 3 }}>
            <Box
              sx={{
                display: 'grid',
                columnGap: 1,
                rowGap: 3,

                gridTemplateColumns: {
                  xs: 'repeat(1, 1fr)',
                  sm: id === String(1) ? '3fr 1fr' : 'repeat(1, 1fr)',
                },
              }}
            >
              {!fieldsData.length ? (
                <>
                  {[...Array(16)].map((_, index) => (
                    <Skeleton
                      height={70}
                      key={index}
                      sx={{ '&:not(:last-child)': { mb: -3 } }}
                    />
                  ))}
                </>
              ) : (
                fieldsData.map((item) =>
                  item.type === 'input' ? (
                    <Fragment key={item.id}>
                      <RHFTextField
                        name={item.ref}
                        label={item.name}
                        InputLabelProps={{ shrink: true }}
                      />

                      <Typography
                        variant="caption"
                        sx={{
                          mt: 0.5,
                          display: 'flex',
                          alignItems: 'center',
                          color: 'text.disabled',
                        }}
                      >
                        {item.template_field}
                      </Typography>
                    </Fragment>
                  ) : item.type === 'select' ? (
                    <RHFSelect name={item.ref} label={item.name} key={item.id}>
                      <option key={item.value}>{item.name}</option>
                      {/* {item?.value_list?.map((option) => (
                        <option key={option.value}>{option.name}</option>

                      ))} */}
                    </RHFSelect>
                  ) : (
                    ''
                  )
                )
              )}
            </Box>

            <Stack alignItems="flex-end" sx={{ mt: 3 }}>
              {allow.canUpdate && (
                <LoadingButton
                  type="submit"
                  variant="contained"
                  loading={isSubmitting}
                >
                  Save Changes
                </LoadingButton>
              )}
            </Stack>
          </Card>
        </FormProvider>
      </Container>
    </Page>
  );
}
