export const PLATFORMS = [
  {
    id: 1,
    name: 'Web Platform',
  },
  {
    id: 2,
    name: 'Android App',
  },
];
