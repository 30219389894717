//
import { Grid, Typography } from '@mui/material';
import { useFormContext, useWatch } from 'react-hook-form';
//
import { RHFTextField, RHFCheckbox } from '@/components/hook-form';

type Props = {
  checkboxRHFName: string;
  checkboxLabel: string;
  textfieldRHFName: string;
  textfieldLabel?: string;
};

export default function ServiceExtrasInputWrapper({
  checkboxRHFName,
  checkboxLabel,
  textfieldLabel = 'Custom hours',
  textfieldRHFName,
}: Props) {
  const { control } = useFormContext();
  const currentCheckboxChecked = useWatch({ control, name: checkboxRHFName });

  return (
    <Grid
      container
      item
      xs={12}
      md={6}
      lg={4}
      direction="row"
      alignItems={'center'}
      spacing={1}
    >
      <Grid item xs={5}>
        <RHFCheckbox name={checkboxRHFName} label={checkboxLabel} />
      </Grid>

      <Grid item xs={3}>
        <RHFTextField
          name={textfieldRHFName}
          label={textfieldLabel}
          type="number"
          disabled={!currentCheckboxChecked}
        />
      </Grid>

      <Grid item>
        <Typography>hrs</Typography>
      </Grid>
    </Grid>
  );
}
