import { ChangeEvent } from 'react';
import { Stack, InputAdornment, TextField, MenuItem } from '@mui/material';

import Iconify from '@/components/Iconify';

type Props = {
  autoFocusSearchfield?: boolean;
  filterText: string;
  filterStatus?: string;
  labels?: {
    search: string;
    status?: string;
  };
  status?: string[];
  statusTextfieldSize?: 'small' | 'medium';
  searchTextfieldSize?: 'small' | 'medium';
  searchfieldVariant?: 'standard' | 'outlined' | 'filled';
  onFilterText: (value: string) => void;
  onFilterStatus?: (event: ChangeEvent<HTMLInputElement>) => void;
  size?: 'small' | 'medium';
};

export default function ToolbarWithSearchAndStatus({
  autoFocusSearchfield,
  filterStatus,
  filterText,
  labels = { search: 'Search...' },
  status,
  statusTextfieldSize = 'small',
  searchTextfieldSize = 'small',
  searchfieldVariant = 'outlined',
  onFilterText,
  onFilterStatus,
}: Props) {
  return (
    <Stack
      spacing={2}
      direction={{ xs: 'column', sm: 'row' }}
      sx={{ py: 2.5, px: 3 }}
    >
      {labels?.status && (
        <TextField
          fullWidth
          select
          label={labels.status}
          value={filterStatus}
          onChange={onFilterStatus}
          size={statusTextfieldSize}
          SelectProps={{
            MenuProps: {
              sx: { '& .MuiPaper-root': { maxHeight: 260 } },
            },
          }}
          sx={{
            maxWidth: { sm: 120, md: 200 },
            textTransform: 'capitalize',
          }}
        >
          {status?.map((option) => (
            <MenuItem
              key={option.toLowerCase()}
              value={option.toLocaleLowerCase()}
              sx={{
                mx: 1,
                my: 0.5,
                borderRadius: 0.75,
                typography: 'body2',
                textTransform: 'capitalize',
              }}
            >
              {option}
            </MenuItem>
          ))}
        </TextField>
      )}
      <TextField
        fullWidth
        autoFocus={autoFocusSearchfield}
        value={filterText}
        onChange={(event) => onFilterText(event.target.value)}
        placeholder={labels?.search}
        size={searchTextfieldSize}
        variant={searchfieldVariant}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Iconify
                icon={'eva:search-fill'}
                sx={{ color: 'text.disabled', width: 20, height: 20 }}
              />
            </InputAdornment>
          ),
        }}
      />
    </Stack>
  );
}
