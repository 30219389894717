import { useState, useEffect } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';
// api
import { deleteDiaryData, getDiaryData } from '../api/index';
// @mui
import {
  Box,
  Button,
  Card,
  Container,
  Divider,
  FormControlLabel,
  IconButton,
  Stack,
  Switch,
  Tab,
  Table,
  TableBody,
  TableContainer,
  TablePagination,
  Tabs,
  Tooltip,
  Skeleton,
} from '@mui/material';
// routes
import { activitiesPath, PATH_ACTIVITIES } from '../routes/paths';
import { PATH_USER } from '@/features/app';
// hooks
import useSettings from '@/hooks/useSettings';
import useTable, { getComparator, emptyRows } from '@/hooks/useTable';
import useTabs from '@/hooks/useTabs';
import { useGetAccess } from '@/hooks/useAccess';
// components
import {
  Page,
  Label,
  Iconify,
  Scrollbar,
  HeaderBreadcrumbs,
} from '@/components';
import AlertDialog from '@/components/dialog/Dialog';
import {
  TableNoData,
  TableEmptyRows,
  TableSelectedActions,
  TableHeadCustom,
} from '@/components/table';
// custom components
import { TableToolbarData, TableRowData } from '../components';
// types
import type { Activity } from '../types';
// sort function
import { applySortFilterList } from '../utils';
import { uniq } from 'lodash';
// date fns
import { isFuture, isPast, isThisWeek, isToday, isTomorrow } from 'date-fns';
// ----------------------------------------------------------------------
let ASSIGNED: string[] = ['all'];
let LINKED: string[] = ['all'];
let TYPE: string[] = ['all'];
let STATUS: string[] = ['all', 'To Do', 'Done'];
let PRIORITY: string[] = ['all'];

const TABLE_HEAD = [
  { id: 'priority', label: 'Priority', align: 'left' },
  { id: 'activity_type', label: 'Type', align: 'center' },
  { id: 'activity_reason', label: 'Subject', align: 'left' },
  { id: 'owner', label: 'Assigned To', align: 'left' },
  { id: 'linked_entity_type', label: 'Linked To', align: 'left' },
  { id: 'completed', label: 'Status', align: 'left' },
  { id: 'activity_to_users', label: 'People Tagged', align: 'left' },
  { id: 'end_date', label: 'Due Date', align: 'left' },

  { id: '' },
];
// ----------------------------------------------------------------------

export default function ActivitiestList() {
  const {
    dense,
    page,
    order,
    orderBy,
    rowsPerPage,
    setPage,
    selected,
    setSelected,
    onSelectRow,
    onSelectAllRows,
    onSort,
    onChangeDense,
    onChangePage,
    onChangeRowsPerPage,
  } = useTable();

  const { themeStretch } = useSettings();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  // --------------------------------------------------------------------------
  //  API call + states for user data
  const [tableData, setTableData] = useState<Activity[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [filterType, setFilterType] = useState<any>('all');
  const [filterStatus, setFilterStatus] = useState('all');
  const [filterPriority, setFilterPriority] = useState('all');
  const [filterAssigned, setFilterAssigned] = useState('all');
  const [filterLinked, setFilterLinked] = useState('all');
  const [filterStartDate, setFilterStartDate] = useState<Date | null>(null);
  const [filterEndDate, setFilterEndDate] = useState<Date | null>(null);
  const [filterName, setFilterName] = useState<string>('');

  // MODAL/DIALOG STATES AND FUNCTIONS
  const [openModal, setOpenModal] = useState(false);
  const [reRender, setReRender] = useState<boolean>(false);
  // --------------------------------------------------------------------------
  // Get, match and assign data
  useEffect(() => {
    const fetchData = async () => {
      const data = await getDiaryData();
      setTableData(data);
    };
    fetchData();
    setIsLoading(false);
  }, [reRender]);

  // --------------------------------------------------------------------------
  // Assign dropdown filters
  const usersFromData = tableData.map((item) => item.owner.first_name);
  const linkedFromData = tableData.map((item: any) =>
    item.linked_entity_type?.description.split(' ').at(0)
  );
  const activityTypeFromData = tableData.map((item) => item.activity_type.name);
  const priorityFromData = tableData.map((item) => item.priority.name);
  LINKED = ['all'].concat(uniq(linkedFromData).sort());
  ASSIGNED = ['all'].concat(uniq(usersFromData).sort());
  TYPE = ['all'].concat(uniq(activityTypeFromData).sort());
  PRIORITY = ['all'].concat(uniq(priorityFromData).sort());
  // --------------------------------------------------------------------------
  // Handle filter change
  const handleFilterType = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFilterType(event.target.value);
  };
  const handleFilterStatus = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFilterStatus(event.target.value);
  };
  const handleFilterPriority = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFilterPriority(event.target.value);
  };
  const handleFilterAssigned = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFilterAssigned(event.target.value);
  };
  const handleFilterLinked = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFilterLinked(event.target.value);
  };
  const handleFilterName = (filterName: string) => {
    setFilterName(filterName.toLowerCase());
    setPage(0);
  };

  const handleDeleteRow = async (id: number) => {
    try {
      await deleteDiaryData(id);
      const deleteRow = tableData.filter((row) => row.id !== id);
      setSelected([]);
      setTableData(deleteRow);

      enqueueSnackbar('Successfully deleted');
    } catch (error) {
      enqueueSnackbar(`Something went wrong: ${error}`, {
        variant: 'error',
      });
    }
  };
  const handleDeleteRows = async (selected: string[]) => {
    try {
      if (selected.length === 1) {
        await deleteDiaryData(parseInt(selected[0]));
        setSelected([]);
      } else {
        for (let i = 0; i < selected.length; i++) {
          const currentActivityID = parseInt(selected[i]);
          await deleteDiaryData(currentActivityID);
        }
        setSelected([]);

        enqueueSnackbar('Successfully deleted');
      }
    } catch (error) {
      enqueueSnackbar(`Something went wrong: ${error}`, { variant: 'error' });
    }
  };

  const handleEditRow = (id: number) => {
    navigate(activitiesPath('edit', 'id', id));
  };

  // get the access for the module
  const allow = useGetAccess('diary');
  // --------------------------------------------------------------------------
  // Set tabs and filters

  const { currentTab: filterDate, onChangeTab: onFilterDate } = useTabs('todo');

  const dataFiltered = applySortFilterList({
    tableData,
    comparator: getComparator(order, orderBy),
    filterType,
    filterStatus,
    filterPriority,
    filterAssigned,
    filterLinked,
    filterStartDate,
    filterEndDate,
    filterName,
    filterDate,
  });

  const denseHeight = dense ? 52 : 72;

  const isNotFound =
    (!dataFiltered.length && !!filterName) ||
    (!dataFiltered.length && !!filterStatus) ||
    (!dataFiltered.length && !!filterType) ||
    (!dataFiltered.length && !!filterPriority) ||
    (!dataFiltered.length && !!filterAssigned) ||
    (!dataFiltered.length && !!filterLinked) ||
    (!dataFiltered.length && !!filterStartDate) ||
    (!dataFiltered.length && !!filterDate) ||
    (!dataFiltered.length && !!filterEndDate);

  // Assign activities to filter tabs
  const dateFormatCheck = (date: any) => {
    if (isToday(new Date(date))) return 'today';
    if (isTomorrow(new Date(date))) return 'tomorrow';
    if (isThisWeek(new Date(date))) return 'thisWeek';
    if (isPast(new Date(date))) return 'overdue';
    if (isFuture(new Date(date))) return 'nextWeek';
    return 'all';
  };

  const getLengthByStatus = (status: string) =>
    tableData.filter((item) => dateFormatCheck(item.end_date) === status)
      .length;

  const getLengthByTaskFinished = (status: string) =>
    tableData.filter((item) => (item.completed ? 'done' : 'todo') === status)
      .length;

  const TABS = [
    {
      value: 'todo',
      label: 'To Do',
      color: 'info',
      count: getLengthByTaskFinished('todo'),
    },
    {
      value: 'overdue',
      label: 'Overdue',
      color: 'error',
      count: getLengthByStatus('overdue'),
    },
    {
      value: 'today',
      label: 'Today',
      color: 'secondary',
      count: getLengthByStatus('today'),
    },
    {
      value: 'tomorrow',
      label: 'Tomorrow',
      color: 'default',
      count: getLengthByStatus('tomorrow'),
    },
    {
      value: 'thisWeek',
      label: 'This Week',
      color: 'default',
      count: getLengthByStatus('thisWeek'),
    },
    {
      value: 'nextWeek',
      label: 'Following Weeks',
      color: 'default',
      count: getLengthByStatus('nextWeek'),
    },
    { value: 'all', label: 'All', color: 'info', count: tableData.length },
  ] as const;

  // ----------------------------------------------------------------------
  // MODAL/DIALOG STATES AND FUNCTIONS

  const handleClickonDeleteRow = () => {
    setOpenModal(true);
  };
  const handleCloseDialog = () => {
    setOpenModal(false);
  };
  const dialogDisagree = () => {};

  // ----------------------------------------------------------------------
  return (
    <>
      {openModal && (
        <AlertDialog
          dialogTitle="Delete activities"
          dialogText={`Are you sure you want to delete 
                  ${selected.length} ${
            selected.length === 1 ? 'activity' : 'activities'
          }?`}
          dialogAgree={() =>
            handleDeleteRows(selected).finally(() => setReRender(!reRender))
          }
          dialogDisagree={dialogDisagree}
          isDialogOpen={openModal}
          setIsDialogOpen={setOpenModal}
          handleCloseDialog={handleCloseDialog}
          agreeButtonText="Delete"
          disagreeButtonText="Cancel"
          agreeButtonColor="error"
          disagreeButtonColor="inherit"
        />
      )}
      {
        <Page title="Activities">
          <Container maxWidth={themeStretch ? false : 'lg'}>
            <HeaderBreadcrumbs
              heading="Activities"
              links={[
                { name: 'Dashboard', href: PATH_USER.root },
                {
                  name: 'Diary',
                  href: PATH_ACTIVITIES.root,
                },
                { name: 'Activities', href: PATH_ACTIVITIES.root },
              ]}
              action={
                allow?.canCreate && (
                  <Button
                    variant="contained"
                    component={RouterLink}
                    to={activitiesPath('new')}
                    startIcon={<Iconify icon={'eva:plus-fill'} />}
                  >
                    Add Activity
                  </Button>
                )
              }
            />

            <Card>
              {isLoading ? (
                <>
                  <Box pl={2} pb={2}>
                    <Skeleton sx={{ width: 0.98 }} height={100} />
                  </Box>
                  <Divider />
                  <Box pl={2} pb={2}>
                    <Skeleton sx={{ width: 0.98 }} height={100} />
                  </Box>
                  <Box pl={2} pb={2}>
                    <Skeleton sx={{ width: 0.98 }} height={60} />
                    <Skeleton sx={{ width: 0.98 }} height={60} />
                    <Skeleton sx={{ width: 0.98 }} height={60} />
                    <Skeleton sx={{ width: 0.98 }} height={60} />
                    <Skeleton sx={{ width: 0.98 }} height={60} />
                  </Box>
                </>
              ) : (
                <>
                  <Tabs
                    allowScrollButtonsMobile
                    variant="scrollable"
                    scrollButtons="auto"
                    value={filterDate}
                    onChange={onFilterDate}
                    sx={{ px: 2, bgcolor: 'background.neutral' }}
                  >
                    {TABS.map((tab) => (
                      <Tab
                        disableRipple
                        key={tab.value}
                        value={tab.value}
                        label={
                          <Stack
                            spacing={1}
                            direction="row"
                            alignItems="center"
                          >
                            <div>{tab.label}</div>{' '}
                            <Label color={tab.color}> {tab.count} </Label>
                          </Stack>
                        }
                      />
                    ))}
                  </Tabs>
                  <Divider />

                  <TableToolbarData
                    filterType={filterType}
                    filterStatus={filterStatus}
                    filterPriority={filterPriority}
                    filterAssigned={filterAssigned}
                    filterLinked={filterLinked}
                    filterStartDate={filterStartDate}
                    filterEndDate={filterEndDate}
                    filterName={filterName}
                    onFilterType={handleFilterType}
                    onFilterStatus={handleFilterStatus}
                    onFilterPriority={handleFilterPriority}
                    onFilterAssigned={handleFilterAssigned}
                    onFilterLinked={handleFilterLinked}
                    onFilterName={handleFilterName}
                    onFilterStartDate={(newValue) => {
                      setFilterStartDate(newValue);
                    }}
                    onFilterEndDate={(newValue) => {
                      setFilterEndDate(newValue);
                    }}
                    optionsType={TYPE}
                    optionsStatus={STATUS}
                    optionsPriority={PRIORITY}
                    optionsAssigned={ASSIGNED}
                    optionsLinked={LINKED}
                  />

                  <Scrollbar>
                    <TableContainer
                      sx={{ minWidth: 800, position: 'relative' }}
                    >
                      {selected.length > 0 && (
                        <TableSelectedActions
                          dense={dense}
                          numSelected={selected.length}
                          rowCount={dataFiltered.length}
                          onSelectAllRows={(checked) =>
                            onSelectAllRows(
                              checked,
                              dataFiltered.map((row) => String(row.id))
                            )
                          }
                          actions={
                            <Tooltip title="Delete">
                              <IconButton
                                color="primary"
                                onClick={handleClickonDeleteRow}
                                disabled={!allow?.canDelete}
                              >
                                <Iconify icon={'eva:trash-2-outline'} />
                              </IconButton>
                            </Tooltip>
                          }
                        />
                      )}
                      <Table size={dense ? 'small' : 'medium'}>
                        <TableHeadCustom
                          order={order}
                          orderBy={orderBy}
                          headLabel={TABLE_HEAD}
                          rowCount={tableData.length}
                          numSelected={selected.length}
                          onSort={onSort}
                          onSelectAllRows={(checked) =>
                            onSelectAllRows(
                              checked,
                              dataFiltered.map((row) => String(row.id))
                            )
                          }
                        />

                        <TableBody>
                          {dataFiltered
                            .slice(
                              page * rowsPerPage,
                              page * rowsPerPage + rowsPerPage
                            )
                            .map((row) => (
                              <TableRowData
                                key={row.id}
                                row={row}
                                selected={selected.includes(String(row.id))}
                                onSelectRow={() => onSelectRow(String(row.id))}
                                onDeleteRow={() =>
                                  handleDeleteRow(row.id).finally(() =>
                                    setReRender(!reRender)
                                  )
                                }
                                onEditRow={() => handleEditRow(row.id)}
                                permissions={allow}
                              />
                            ))}

                          <TableEmptyRows
                            height={denseHeight}
                            emptyRows={emptyRows(
                              page,
                              rowsPerPage,
                              tableData.length
                            )}
                          />

                          <TableNoData isNotFound={isNotFound} />
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Scrollbar>

                  <Box sx={{ position: 'relative' }}>
                    <TablePagination
                      rowsPerPageOptions={[25, 50, 100]}
                      component="div"
                      count={dataFiltered.length}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      onPageChange={onChangePage}
                      onRowsPerPageChange={onChangeRowsPerPage}
                    />

                    <FormControlLabel
                      control={
                        <Switch checked={dense} onChange={onChangeDense} />
                      }
                      label="Dense"
                      sx={{
                        px: 3,
                        py: 1.5,
                        top: 0,
                        position: { md: 'absolute' },
                      }}
                    />
                  </Box>
                </>
              )}
            </Card>
          </Container>
        </Page>
      }
    </>
  );
}
