import { useNavigate, useParams } from 'react-router-dom';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { isEmpty } from 'lodash';

// api
import { getRolesModules, createRole, updateRole, getSingleRole } from '../api';
// form
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import {
  Stack,
  Grid,
  Box,
  Card,
  Table,
  Switch,
  TableBody,
  Container,
  TableContainer,
  FormControlLabel,
  MenuItem,
} from '@mui/material';
//
import Skeleton from '@mui/material/Skeleton';
import LoadingButton from '@mui/lab/LoadingButton';
// components
import { HeaderBreadcrumbs, Page, Scrollbar } from '@/components';
import { FormProvider, RHFTextField } from '@/components/hook-form';
import {
  TableNoData,
  TableEmptyRows,
  TableHeadCustom,
} from '@/components/table';
import { SkeletonLoadingTableData } from '@/components/skeleton';
import { PermissionsTableRow } from '../components';
// hooks + utils
import useSettings from '@/hooks/useSettings';
import useTable, { emptyRows } from '@/hooks/useTable';
import { useSnackbarMsg } from '@/hooks/useSnackbarMsg';
import {
  NEW_ROLE_DEFAULT_VALUES,
  NEW_ROLE_SCHEMA,
  ROLE_MODULES_TABLE_HEAD,
  STATUS_OPTIONS,
} from '../utils';
//
import { PATH_USER } from '@/features/app';
import { PATH_ROLES } from '../routes/paths';
// types
import { RoleFormValuesProps, RoleObjectToPost } from '../types';

// ----------------------------------------------------------------------

export default function RoleCreateEdit() {
  const navigate = useNavigate();
  const { errorMsg, successMsg } = useSnackbarMsg();
  const { themeStretch } = useSettings();
  const { roleId } = useParams();
  const queryClient = useQueryClient();

  const { dense, page, order, orderBy, rowsPerPage, onChangeDense } = useTable({
    defaultDense: true,
  });

  // ========================
  //     REACT HOOK FROM
  // ========================

  const methods = useForm<RoleFormValuesProps>({
    resolver: yupResolver(NEW_ROLE_SCHEMA),
    defaultValues: NEW_ROLE_DEFAULT_VALUES,
  });

  const {
    reset,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  // =================
  //      QUERIES
  // =================

  // ROLE MODULES
  const { data: roleModules } = useQuery({
    queryKey: ['rolesModules'],
    queryFn: getRolesModules,
    onError: (error) => {
      errorMsg(error, `Something went wrong while fetching current client`);
    },
    refetchOnWindowFocus: false,
  });

  // CURRENT ROLE
  const { data: currentRole, isLoading: isRoleDataLoading } = useQuery({
    queryKey: ['roles', roleId],
    queryFn: () => getSingleRole(Number(roleId)),
    onSuccess: (data) => {
      reset({
        name: data?.details?.name || '',
        description: data?.details?.description || '',
        active: data?.details?.active
          ? 'Active'
          : isEmpty(data)
          ? 'Active'
          : 'Inactive',
        selected_permissions: data?.selected_permissions || [],
      });
    },
    onError: (error) => {
      errorMsg(error, `Something went wrong while fetching current client`);
    },
    enabled: !!roleId,
    refetchOnWindowFocus: false,
  });

  // CREATE
  const createRoleMutation = useMutation({
    mutationFn: (roleDto: RoleObjectToPost) => createRole(roleDto),
    onSuccess: () => {
      queryClient.invalidateQueries(['roles', roleId]);
      successMsg('Role successfully created!');
      navigate('/roles');
    },
    onError: (error) =>
      errorMsg(error, `Something went wrong while creating role`),
  });

  // UPDATE
  const updateRoleMutation = useMutation({
    mutationFn: ({
      roleId,
      roleDto,
    }: {
      roleId: number;
      roleDto: RoleObjectToPost;
    }) => updateRole(roleId, roleDto),
    onSuccess: () => {
      queryClient.invalidateQueries(['roles', roleId]);
      successMsg('Role successfully updated!');
      navigate('/roles');
    },
    onError: (error) =>
      errorMsg(error, `Something went wrong while updating role`),
  });

  const onSubmitRole = async (data: RoleFormValuesProps) => {
    const roleDto = {
      name: data.name,
      description: data.description,
      active: data.active === 'Active' ? true : false,
      selected_permissions: data.selected_permissions,
    };

    !isEmpty(currentRole)
      ? updateRoleMutation.mutate({ roleId: Number(roleId), roleDto })
      : createRoleMutation.mutate(roleDto);
  };

  // ====================
  //    DERIVED VALUES
  // ====================

  const denseHeight = dense ? 52 : 72;
  const isNotFound = !(roleModules || []).length;

  // ----------------------------------------------------------------------

  return (
    <Page title="Roles &amp; Permissions">
      <Container
        maxWidth={themeStretch ? false : 'lg'}
        style={{ position: 'relative' }}
      >
        <HeaderBreadcrumbs
          heading={!roleId ? 'Create a new role' : 'Edit role'}
          links={[
            { name: 'Dashboard', href: PATH_USER.root },
            { name: 'Roles', href: PATH_ROLES.root },
            {
              name: !roleId ? 'New role' : 'Edit role',
            },
          ]}
        />
        <Card>
          <FormProvider methods={methods} onSubmit={handleSubmit(onSubmitRole)}>
            <Grid item xs={12} md={8}>
              <Card sx={{ p: 3 }}>
                <Box
                  sx={{
                    display: 'grid',
                    columnGap: 2,
                    rowGap: 3,
                    gridTemplateColumns: {
                      xs: 'repeat(1, 1fr)',
                      sm: 'repeat(1, 1fr)',
                    },
                  }}
                >
                  {isRoleDataLoading && !!roleId ? (
                    <Box pl={2} pt={3}>
                      <Skeleton sx={{ width: 0.98 }} height={80} />
                      <Skeleton sx={{ width: 0.98 }} height={80} />
                    </Box>
                  ) : (
                    <>
                      <RHFTextField name="name" label="Role name" fullWidth />
                      <RHFTextField
                        name="description"
                        label="Role Description"
                        fullWidth
                      />

                      <RHFTextField
                        name="active"
                        label="Status"
                        placeholder="Status"
                        select
                      >
                        {STATUS_OPTIONS.map((option) => (
                          <MenuItem key={option} value={option}>
                            {option}
                          </MenuItem>
                        ))}
                      </RHFTextField>
                    </>
                  )}
                </Box>

                {isRoleDataLoading && !!roleId ? (
                  <SkeletonLoadingTableData />
                ) : (
                  <Scrollbar>
                    <TableContainer
                      sx={{ minWidth: 800, position: 'relative', mt: 4 }}
                    >
                      <Table size={dense ? 'small' : 'medium'}>
                        <TableHeadCustom
                          order={order}
                          orderBy={orderBy}
                          headLabel={ROLE_MODULES_TABLE_HEAD}
                          rowCount={(roleModules || []).length}
                        />

                        <TableBody>
                          {(roleModules || [])
                            .slice(
                              page * rowsPerPage,
                              page * rowsPerPage + rowsPerPage
                            )
                            .map((roleModule) => (
                              <PermissionsTableRow
                                key={roleModule.id}
                                roleModule={roleModule}
                              />
                            ))}

                          <TableEmptyRows
                            height={denseHeight}
                            emptyRows={emptyRows(
                              page,
                              rowsPerPage,
                              (roleModules || []).length
                            )}
                          />

                          <TableNoData
                            isNotFound={isNotFound}
                            title="No permissions found"
                          />
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Scrollbar>
                )}

                <Stack
                  alignItems="justify-between"
                  direction="row"
                  justifyContent="space-between"
                  sx={{ mt: 3 }}
                >
                  <FormControlLabel
                    control={
                      <Switch checked={dense} onChange={onChangeDense} />
                    }
                    label="Dense"
                    sx={{ py: 0.5 }}
                  />

                  <LoadingButton
                    type="submit"
                    variant="contained"
                    loading={isSubmitting}
                    disabled={isRoleDataLoading && !!roleId}
                  >
                    {isEmpty(currentRole) ? 'Create Role' : 'Save Changes'}
                  </LoadingButton>
                </Stack>
              </Card>
            </Grid>
          </FormProvider>
        </Card>
      </Container>
    </Page>
  );
}
