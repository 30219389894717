import { useCallback, useEffect, useRef, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { isEmpty } from 'lodash';
import { useForm, Controller, useWatch } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
//
import { Stack, OutlinedInput, FormHelperText, Alert } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { FormProvider } from '@/components/hook-form';
// api
import { verifyCode } from '../api';
// hooks + utils
import { useSnackbarMsg } from '@/hooks/useSnackbarMsg';
import { authPath } from '../routes/paths';
import {
  VERIFY_CODE_SCHEMA,
  VERIFY_CODE_FORM_DEFAULT_VALUES,
  constructVerificationCode,
} from '../utils';
// types
import { ResetFormValuesProps, ResetValueNames } from '../types';
// ----------------------------------------------------------------------

export default function VerifyCodeForm() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { errorMsg } = useSnackbarMsg();
  const [message, setMessage] = useState<string>('');
  const veryifyButton = useRef<HTMLButtonElement | null>(null);

  const email = searchParams.get('email');
  const OTP = searchParams.get('otp');

  const methods = useForm({
    mode: 'all',
    resolver: yupResolver(VERIFY_CODE_SCHEMA),
    defaultValues: VERIFY_CODE_FORM_DEFAULT_VALUES,
  });

  const {
    control,
    setValue,
    handleSubmit,
    clearErrors,
    formState: { isSubmitting, errors },
  } = methods;

  const formValues = useWatch({ control });

  const handlePaste = useCallback(
    (event: any) => {
      let data = event.clipboardData.getData('text');

      data = data.split('');

      [].forEach.call(
        document.querySelectorAll('.field-code'),
        (node: any, index) => {
          node.value = data[index];

          const fieldIndex = `code${index + 1}`;

          setValue(fieldIndex as ResetValueNames, data[index]);
          clearErrors(fieldIndex as ResetValueNames);
        }
      );

      event.preventDefault();
      veryifyButton.current && veryifyButton.current.click();
    },
    [setValue, clearErrors]
  );

  useEffect(() => {
    const target = document.querySelector('input.field-code');
    target?.addEventListener('paste', handlePaste);

    return () => {
      target?.removeEventListener('paste', handlePaste);
    };
  }, [handlePaste]);

  const handleChangeWithNextField = (
    event: React.ChangeEvent<HTMLInputElement>,
    handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void
  ) => {
    const { maxLength, value, name } = event.target;

    const fieldIndex = name.replace('code', '');

    const fieldIntIndex = Number(fieldIndex);

    if (value.length >= maxLength) {
      if (fieldIntIndex < 6) {
        const nextfield = document.querySelector(
          `input[name=code${fieldIntIndex + 1}]`
        );

        if (nextfield !== null) {
          (nextfield as HTMLElement).focus();
        }
      }
    }

    handleChange(event);
  };

  const onSubmitVerificationCode = async (
    data: ResetFormValuesProps | string
  ) => {
    try {
      const codeVerify = constructVerificationCode(data);

      const dataValues = await verifyCode(codeVerify);

      if (dataValues.hasOwnProperty('valid')) {
        sessionStorage.setItem('code-recovery', codeVerify);
        navigate(authPath('password'));
      }
    } catch (error) {
      errorMsg(error, 'Invalid code');
      setMessage(error.message);
    }
  };

  // when recovery email is sent out, it contains a link with searchParams
  // in that case we want to programmatically submit the form and navigate to the next page ==> set new password
  if (email && OTP) {
    sessionStorage.setItem('email-recovery', email);
    onSubmitVerificationCode(OTP);
    return null;
  }

  const errorInCode =
    !!errors.code1 ||
    !!errors.code2 ||
    !!errors.code3 ||
    !!errors.code4 ||
    !!errors.code5 ||
    !!errors.code6;

  return (
    <FormProvider
      methods={methods}
      onSubmit={handleSubmit(onSubmitVerificationCode)}
    >
      <Stack spacing={3}>
        {!isEmpty(message) && (
          <Alert sx={{ textAlign: 'left' }} severity="error">
            {message}
          </Alert>
        )}
        <Stack direction="row" spacing={2} justifyContent="center">
          {Object.keys(formValues).map((name, index) => (
            <Controller
              key={name}
              name={`code${index + 1}` as ResetValueNames}
              control={control}
              render={({ field, fieldState: { error } }) => (
                <OutlinedInput
                  {...field}
                  error={!!error}
                  autoFocus={index === 0}
                  placeholder="-"
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                    handleChangeWithNextField(event, field.onChange)
                  }
                  inputProps={{
                    className: 'field-code',
                    maxLength: 1,
                    sx: {
                      p: 0,
                      textAlign: 'center',
                      width: { xs: 36, sm: 56 },
                      height: { xs: 36, sm: 56 },
                    },
                  }}
                />
              )}
            />
          ))}
        </Stack>

        {errorInCode && (
          <FormHelperText error sx={{ px: 2 }}>
            Code is required
          </FormHelperText>
        )}

        <LoadingButton
          ref={veryifyButton}
          fullWidth
          size="large"
          type="submit"
          variant="contained"
          loading={isSubmitting}
          sx={{ mt: 3 }}
        >
          Verify
        </LoadingButton>
      </Stack>
    </FormProvider>
  );
}
