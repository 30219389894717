import { tableSortFilter } from '@/utils/arrayProcessing';
import { DummyProviderInvoices } from '../components/client-invoices/ClientInvoices';

export const PROVIDER_INVOICES_TABLE_HEAD = [
  { id: 'emptyCell' },
  { id: 'id', label: 'Invoice ID', align: 'left' },
  { id: 'from_date', label: 'From Date', align: 'left' },
  { id: 'to_date', label: 'To Date', align: 'left' },
  { id: 'payment_date', label: 'Payment Date', align: 'left' },
  { id: 'jobs_no', label: 'Jobs No', align: 'left' },
  { id: 'action-buttons' },
];

export function applySortFilterProviderInvoices({
  tableData,
  comparator,
  searchFieldValue,
}: {
  tableData: DummyProviderInvoices[];
  comparator: (a: any, b: any) => number;
  searchFieldValue: string;
}) {
  tableData = tableSortFilter(tableData, comparator);

  if (searchFieldValue) {
    tableData = tableData.filter(
      (item) => item.id.toString().indexOf(searchFieldValue) !== -1
    );
  }

  return tableData;
}
