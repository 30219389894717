import { RHFTextField } from '@/components/hook-form';
type NotesProps = {
  name?: string;
  label?: string;
  minRows?: number;
};

export default function TimeoffRequestNotes({
  name = 'timeoff_request_notes',
  label = 'Request notes',
  minRows = 3,
}: NotesProps) {
  return <RHFTextField name={name} label={label} multiline minRows={minRows} />;
}
