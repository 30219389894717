import { useState, MouseEvent } from 'react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
//
import {
  Card,
  MenuItem,
  Rating,
  Stack,
  TableCell,
  TableRow,
  Typography,
  useTheme,
} from '@mui/material';
//
import { Iconify, Label } from '@/components';
import { TableMoreMenu, TableRowLink } from '@/components/table';
import DialogSimple from '@/components/dialog/DialogSimple';
// API
import { updateReview } from '@/features/reviews/api';
// hooks + utils
import { useSnackbarMsg } from '@/hooks/useSnackbarMsg';
import { fDateAbbrevMonth } from '@/utils/formatTime';
import { createDialog } from '@/utils/createDialog';
import { REVIEW_STATUSES } from '@/features/reviews//utils';
//
import { clientManagementPath } from '@/features/users/clients';
import { providersPath } from '@/features/users/providers';
// types
import { InvoiceRun } from '@/features/invoices/types';
import { ButtonColors, MultiPurposeDialog } from '@/types';
import { invoicesPath } from '../routes/paths';

type PreviewTableCustomRowProps = {
  row: InvoiceRun;
  canUpdate: boolean;
  queryKey: string;
};

export default function InvoicesRunTableRow({
  row,
  canUpdate,
  queryKey,
}: PreviewTableCustomRowProps) {
  const theme = useTheme();
  const queryClient = useQueryClient();
  const { successMsg, errorMsg } = useSnackbarMsg();

  const [selectedDialog, setSelectedDialog] =
    useState<MultiPurposeDialog | null>(null);

  const {
    id,
    uuid,
    total_jobs,
    processed_jobs,
    percentage,
    running,
    start_date,
    end_date,
    created_at,
  } = row;

  const filteredSatusesBasedOnCurentStatus = REVIEW_STATUSES.filter(
    (s) => s.name !== (status || '').toLowerCase()
  );

  const currentStatusObject = REVIEW_STATUSES.find((s) => s.name === status);

  // ===================
  //      3DOT MENU
  // ===================

  const [openMenu, setOpenMenuActions] = useState<HTMLElement | null>(null);

  const handleOpenMenu = (event: MouseEvent<HTMLElement>) => {
    setOpenMenuActions(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setOpenMenuActions(null);
  };

  // ========================
  //      UPDATE STATUS
  // ========================

  const updateReviewStatusMutation = useMutation({
    mutationFn: (updatedStatus: string) =>
      updateReview(row.id, {
        status: updatedStatus,
      }),
    onSuccess: () => {
      queryClient.invalidateQueries([queryKey]);
      successMsg(`Review status updated`);
    },
    onError: (error) => {
      errorMsg(error, `There was an error while updating review status`);
    },
  });

  return (
    <>
      <TableRow hover>
        <TableCell width={10} />

        <TableCell align="left">
          <TableRowLink
            linkText={id}
            linkTo={invoicesPath('providerRunInvoicesList', {
              runId: String(id),
            })}
          />
        </TableCell>

        <TableCell align="left">{fDateAbbrevMonth(start_date)}</TableCell>

        <TableCell align="left">{fDateAbbrevMonth(end_date)}</TableCell>

        <TableCell align="left">
          <Label
            variant={theme.palette.mode === 'light' ? 'ghost' : 'filled'}
            color={running === true ? 'info' : 'success'}
            sx={{ textTransform: 'capitalize' }}
          >
            {running ? 'Running' : 'Completed'}
          </Label>
        </TableCell>

        <TableCell align="left">
          {processed_jobs} / {total_jobs}
        </TableCell>

        <TableCell align="left">{fDateAbbrevMonth(created_at)}</TableCell>
      </TableRow>
    </>
  );
}
