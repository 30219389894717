import { AppDispatch } from '@/stores';
import { InitialBookingState, ValidationErrorField } from '../types';
import { setValidationError } from '../slices';
import { isEmpty } from 'lodash';
import { ServiceVariant } from '@/types';

/**
 * Validates the booking form and returns a boolean value. It also dispatches an action to set the validation error.
 *
 * @param {AppDispatch} dispatch - The dispatch method used to send actions to the store.
 * @param {InitialBookingState} state - The current state of the booking form.
 * @returns {boolean} - True if the booking form is valid, otherwise false.
 *
 * @description This function validates the booking form based on the given state and dispatches an action to set the validation error if the form is invalid. It checks: client, address, cleaner, payment_status, booking_status, date, time, and service. It also checks some conditional fields for access_type, pets, and property_type_id.
 */
export const validateBookingForm = (
  dispatch: AppDispatch,
  state: InitialBookingState
) => {
  let isValid = true;

  const flattenedState = { ...state, ...state.cleanDetails };

  const requiredFields: {
    field: ValidationErrorField;
    errorMessage: string | null;
    condition?: () => boolean; // Condition to check if the field is required
  }[] = [
    { field: 'client', errorMessage: '* Client is required.' },
    { field: 'address', errorMessage: '* Address is required.' },
    {
      field: 'booking_status_id',
      errorMessage: '* Booking status is required.',
    },
    { field: 'date', errorMessage: '* Booking date is required.' },
    { field: 'time', errorMessage: '* Booking time is required.' },
    {
      field: 'selectedServiceVariant',
      errorMessage: '* Service type is required.',
    },
    { field: 'selectedServiceId', errorMessage: '* Service is required.' },

    // Conditional checks
    {
      field: 'access_type',
      errorMessage: '* Access type is required.',
      condition: () => state.cleanDetails.access_instructions_enabled,
    },
    {
      field: 'pets',
      errorMessage: '* Select at least one pet type.',
      condition: () => state.cleanDetails.pets_enabled,
    },
    {
      field: 'property_type_id',
      errorMessage: '* Property type is required.',
      condition: () =>
        !isEmpty(state.selectedServiceVariant) &&
        [4, 5].includes((state.selectedServiceVariant as ServiceVariant)?.id), // 4: holday rental, 5: commercal
    },
  ];

  requiredFields.forEach(({ field, errorMessage, condition }) => {
    if (condition && !condition()) return;

    const value = flattenedState[field as keyof InitialBookingState];
    const isDateValid = value instanceof Date && !isNaN(value.getTime());
    if (
      !value ||
      (Array.isArray(value) && value.length === 0) ||
      (typeof value === 'object' &&
        !isDateValid &&
        Object.keys(value).length === 0) ||
      (value instanceof Date && !isDateValid)
    ) {
      dispatch(setValidationError({ field, error: errorMessage }));
      isValid = false;
    } else {
      dispatch(setValidationError({ field, error: null }));
    }
  });

  return isValid;
};
