// @mui
import { Box, ListItemButtonProps } from '@mui/material';
import { forwardRef } from 'react';
//
import Iconify from '../../Iconify';
//
import { NavItemProps } from '../type';
import { ListItemStyle, ListItemTextStyle, ListItemIconStyle } from './style';

// ----------------------------------------------------------------------

type Props = NavItemProps & ListItemButtonProps;

export const NavItem = forwardRef<HTMLDivElement & HTMLAnchorElement, Props>(
  ({ item, depth, active, open, isCollapse, hide, ...other }, ref) => {
    const { title, icon, children, disabled } = item;
    const subItem = depth !== 1;
    const renderContent = (
      <ListItemStyle
        depth={depth}
        active={active}
        disabled={disabled}
        ref={ref}
        {...other}
      >
        {icon && (
          <>
            <ListItemIconStyle
              sx={{ widht: '100%', mx: isCollapse ? 'auto' : '' }}
            >
              {icon}
            </ListItemIconStyle>
            {children && isCollapse && (
              <Iconify
                width={12}
                icon={'eva:arrow-ios-forward-fill'}
                sx={{ position: 'absolute', right: 4, top: 16 }}
              />
            )}
          </>
        )}

        {subItem && (
          <DotIcon isCollapse={isCollapse} active={active && subItem} />
        )}
        <ListItemTextStyle
          isCollapse={isCollapse}
          primary={title}
          primaryTypographyProps={{
            ...(isCollapse && {
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              width: 75,
              textAlign: 'center',
              noWrap: true,
            }),
            ...(!isCollapse
              ? { variant: active ? 'subtitle2' : 'body2' }
              : { variant: 'caption' }),
            fontSize: isCollapse ? 10 : '5',
          }}
        />
        {!isCollapse && (
          <>
            {!!children && (
              <Iconify
                icon={
                  open
                    ? 'eva:arrow-ios-downward-fill'
                    : 'eva:arrow-ios-forward-fill'
                }
                sx={{ width: 16, height: 16, ml: 1, flexShrink: 0 }}
              />
            )}
          </>
        )}
      </ListItemStyle>
    );
    if (hide) {
      return <></>;
    } else {
      return renderContent;
    }
  }
);

// ----------------------------------------------------------------------

type DotIconProps = {
  active: boolean;
  isCollapse?: boolean;
};

export function DotIcon({ active }: DotIconProps) {
  return (
    <ListItemIconStyle>
      <Box
        component="span"
        sx={{
          width: 4,
          height: 4,
          borderRadius: '50%',
          bgcolor: 'text.disabled',
          transition: (theme) =>
            theme.transitions.create('transform', {
              duration: theme.transitions.duration.shorter,
            }),
          ...(active && {
            transform: 'scale(2)',
            bgcolor: 'primary.main',
          }),
        }}
      />
    </ListItemIconStyle>
  );
}
