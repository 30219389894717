/**
 * Remove the specified search parameter(s) from the URL.
 *
 * @param currentSearchParams The current search parameters in the URL.
 * @param paramName The name of the parameter(s) to remove. Can be a string or an array of strings, ie: 'id' or ['id', 'name', 'status'].
 * @param setSearchParams The function to update the search parameters in the URL.
 */

export const removeSearchParam = (
  currentSearchParams: URLSearchParams,
  paramName: string | string[],
  setSearchParams: (params: URLSearchParams) => void
) => {
  // Clone the current search parameters
  const params = new URLSearchParams(currentSearchParams);

  // Delete the specified parameter(s)
  Array.isArray(paramName)
    ? paramName.forEach((param) => params.delete(param))
    : params.delete(paramName);

  // Update the search parameters in the URL
  setSearchParams(params);
};
