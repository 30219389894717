import axios from '@/utils/axios';
import { AxiosResponse } from 'axios';
import {
  Role,
  RoleDetails,
  RolesModules,
  RoleObjectToPost,
} from '../types/roles';

// GET
export async function getRoles(): Promise<Role[]> {
  const {
    data: { data },
  } = await axios.get('/roles');
  return data;
}

export async function getSingleRole(roleID: number): Promise<RoleDetails> {
  const {
    data: { data },
  } = await axios.get(`/roles/${roleID}`);
  return data;
}

export async function getRolesModules(): Promise<RolesModules[]> {
  const {
    data: { response },
  } = await axios.get('roles/modules');
  return response;
}

// POST
export const createRole = async (roleData: RoleObjectToPost) => {
  const data = axios.post('/roles', roleData);
  return data;
};

// PUT
export const updateRole = async (
  roleID: number,
  dataToSend: RoleObjectToPost
) => {
  const data = axios.put(`/roles/${roleID}`, dataToSend);
  return data;
};

// DELETE PROMPT
export async function deleteRolePrompt(roleID: number): Promise<AxiosResponse> {
  const data = await axios.get(`/roles/${roleID}/prompt`);
  return data;
}

// DELETE
export async function deleteRole(roleID: number): Promise<AxiosResponse> {
  const data = await axios.delete(`/roles/${roleID}`);
  return data;
}
