import jwtDecode from 'jwt-decode';
import axios from './axios';

interface JwtPayload {
  exp: number; // Expiration time (in seconds since the epoch)
}
const isTokenValid = (accessToken: string): boolean => {
  const { exp } = jwtDecode<JwtPayload>(accessToken);
  return Date.now() <= exp * 1000; // checks if the current time is still before or equal to the token's expiration time
};

const handleTokenExpired = (exp: number) => {
  let expiredTimer;

  window.clearTimeout(expiredTimer);
  const currentTime = Date.now();
  const timeLeft = exp * 1000 - currentTime;

  expiredTimer = window.setTimeout(() => {
    window.location.href = '/';
    localStorage.removeItem('accessToken');
    delete axios.defaults.headers.common.Authorization;
  }, timeLeft);
};

const setSession = (accessToken: string | null) => {
  if (accessToken) {
    localStorage.setItem('accessToken', accessToken);
    axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
    // This function below will handle when token is expired
    const { exp } = jwtDecode<{ exp: number }>(accessToken);
    handleTokenExpired(exp);
  } else {
    localStorage.removeItem('accessToken');
    delete axios.defaults.headers.common.Authorization;
  }
};

const saveToLocalStorage = (name: string, value: string | null) => {
  if (value) {
    localStorage.setItem(name, value);
  } else {
    localStorage.removeItem(name);
  }
};

export { isTokenValid, setSession, saveToLocalStorage };
