// THIS COMPONENT IS USED WITH BOOKINGS AND BOOKING SESSIONS AS WELL
// mui
import {
  Grid,
  IconButton,
  InputAdornment,
  Skeleton,
  TextField,
  Tooltip,
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
// components
import Iconify from '@/components/Iconify';
import DropdownSelection from '@/components/DropdownSelection';
//
import useResponsive from '@/hooks/useResponsive';
//types
import { Settings } from '@/types';
import { User } from '@/features/users/shared/types';

type InvoiceRunsTableToolbarProps = {
  filterStartDate: string | null;
  filterEndDate: string | null;
  //
  handleFilterDropdown: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleDatepickerChange: (
    value: string | null,
    date_type: string,
    keyboardInputValue?: string | undefined
  ) => void;
  resetFilters: () => void;
};

export default function InvoiceRunsTableToolbar({
  filterStartDate,
  filterEndDate,
  //
  handleFilterDropdown,
  handleDatepickerChange,
  resetFilters,
}: InvoiceRunsTableToolbarProps) {
  const downXl = useResponsive('down', 'xl');

  const isFiltering = !!filterStartDate || !!filterEndDate;

  const xlDropdownGridItemSize = 2;

  return (
    <Grid
      container
      spacing={2}
      direction={{ xs: 'column', lg: 'row' }}
      sx={{ py: 2.5, px: 3 }}
    >
      {/* START DATE */}
      <Grid item xs={6} sm={6} md={6} lg={6} xl={xlDropdownGridItemSize}>
        <DatePicker
          label="Start Date"
          value={filterStartDate}
          onChange={(date) => handleDatepickerChange(date, 'start')}
          renderInput={(params) => (
            <TextField {...params} size="small" fullWidth />
          )}
          componentsProps={{
            actionBar: {
              actions: ['clear'],
            },
          }}
        />
      </Grid>

      {/* END DATE */}
      <Grid item xs={6} sm={6} md={6} lg={6} xl={xlDropdownGridItemSize}>
        <DatePicker
          label="End Date"
          value={filterEndDate}
          minDate={filterStartDate}
          onChange={(date) => handleDatepickerChange(date, 'end')}
          renderInput={(params) => (
            <TextField {...params} size="small" fullWidth />
          )}
          componentsProps={{
            actionBar: {
              actions: ['clear'],
            },
          }}
        />
      </Grid>
      {isFiltering && (
        <Grid item xs={12} md={12} xl={1} justifyContent="center">
          <Tooltip title="Reset filters" placement="top">
            <span>
              <IconButton
                color="inherit"
                onClick={resetFilters}
                sx={{
                  fontSize: 14,
                  display: 'flex',
                  direction: 'row',
                  gap: 0.5,
                }}
              >
                <Iconify
                  icon="eva:close-circle-outline"
                  sx={{ width: 20, height: 20 }}
                />
                {downXl && 'Reset filters'}
              </IconButton>
            </span>
          </Tooltip>
        </Grid>
      )}
    </Grid>
  );
}
