import ReactDOM from 'react-dom';
import { useCallback, useRef, useState } from 'react';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useNavigate, useParams } from 'react-router-dom';
import { Controller, useForm, useWatch } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { isEmpty } from 'lodash';
import parsePhoneNumber from 'libphonenumber-js';
//
import {
  Autocomplete,
  Box,
  Card,
  Grid,
  MenuItem,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import {
  FormProvider,
  RHFSwitch,
  RHFTextField,
  RHFUploadAvatar,
} from '@/components/hook-form';
import {
  SectionTitle,
  UserPasswordReset,
} from '@/features/users/shared/components';
import { SkeletonLoadingTable } from '@/components/skeleton';
import {
  ClientAddressPlaceholder,
  ClientAddressTable,
} from '@/features/users/clients/components';
// API
import {
  getSingleUser,
  createNewUser,
  updateUser,
} from '@/features/users/shared/api';
import {
  uploadImage,
  getSettingsData,
  getAvailablePostcodes,
} from '@/features/app/api';
// utils + hooks
import { useSnackbarMsg } from '@/hooks/useSnackbarMsg';
import { fData } from '@/utils/formatNumber';
import {
  NEW_PROVIDER_SCHEMA,
  NEW_PROVIDER_DEFAULT_VALUES,
  findDefaultAddress,
  retrievePostcodeObjects,
  retrieveServiceTypeObjects,
} from '../../utils';
import { MAX_PROFILE_PIC_UPLOAD_SIZE } from '@/utils/maxFileSizes';
import { providersPath } from '../../routes/paths';
// types
import { FormValuesProps } from '../../types';
import { UserDto } from '@/features/users/shared/types';
import { Postcode } from '@/features/app/types/postcode';

// ----------------------------------------------------------------------

export default function ProviderDetails() {
  const navigate = useNavigate();
  const { errorMsg, successMsg } = useSnackbarMsg();
  const { userId } = useParams();
  const queryClient = useQueryClient();
  const portalRef = useRef<HTMLDivElement>(null); // Create a ref for the portal root
  const [profileImageUrl, setProfileImageUrl] = useState<string>('');

  // ========================
  //     REACT HOOK FROM
  // ========================

  const methods = useForm<FormValuesProps>({
    resolver: yupResolver(NEW_PROVIDER_SCHEMA),
    defaultValues: NEW_PROVIDER_DEFAULT_VALUES,
  });

  const {
    handleSubmit,
    reset: resetFormValues,
    setValue,
    control,
    formState,
    formState: { isSubmitting },
  } = methods;

  // =================
  //      QUERIES
  // =================
  const { data: settingsData } = useQuery({
    queryKey: ['settings'],
    queryFn: getSettingsData,
    onError: (error) => {
      errorMsg(error, `Something went wrong while fetching settings data`);
    },
    refetchOnWindowFocus: false,
    // TODO: revisit these settings once MVP is ready to be launched
    // staleTime: 24 * 60 * 60 * 1000, // update every 24 hours
    // cacheTime: 24 * 60 * 60 * 1000, // update every 24 hours
  });
  const { data: postcodes } = useQuery({
    queryKey: ['postcodes'],
    queryFn: getAvailablePostcodes,
    onError: (error) => {
      errorMsg(error, `Something went wrong while fetching postcodes`);
    },
    refetchOnWindowFocus: false,
    // TODO: revisit these settings once MVP is ready to be launched
    // staleTime: 24 * 60 * 60 * 1000, // update every 24 hours
    // cacheTime: 24 * 60 * 60 * 1000, // update every 24 hours
  });

  const { data: currentProvider, isLoading: isProviderDataLoading } = useQuery({
    queryKey: ['providers', userId],
    queryFn: () => getSingleUser(Number(userId)),
    onSuccess: (data) => {
      const defaultAddress = findDefaultAddress(data.address);
      setProfileImageUrl(data.profile_image ?? '');
      resetFormValues({
        first_name: data.first_name ?? '',
        last_name: data.last_name ?? '',
        email: data.email ?? '',
        phone_number: data.phone ?? '',
        profile_image: data.profile_image ?? '',
        status: data.user_statuses ? data?.user_statuses.id : '',
        position: data.position ?? '',
        short_description: data.short_description ?? '',
        allergy: !!data.allergy,
        allergy_description: data.allergy ? data.allergy : '',
        gender: data.gender ? data.gender.toLowerCase() : '',
        //
        address_1: defaultAddress ? defaultAddress.line_1 : '',
        address_2: defaultAddress ? defaultAddress.line_2 || '' : '',
        town_city: defaultAddress ? defaultAddress.town_or_city : '',
        postcode: defaultAddress ? defaultAddress.postcode : '',
        //
        covered_postcodes: retrievePostcodeObjects(
          data.covered_postcodes,
          postcodes || []
        ),
        nearby_postcodes: retrievePostcodeObjects(
          data.nearby_postcodes,
          postcodes || []
        ),
        covered_service_types: retrieveServiceTypeObjects(
          data.service_types,
          settingsData?.services || []
        ),
        is_chat_user: data.is_chat_user,
      });
    },
    onError: (error) => {
      errorMsg(error, `Something went wrong while fetching current provider`);
    },
    enabled: !!userId && !!settingsData && !!postcodes,
    refetchOnWindowFocus: false,
  });

  const createProviderMutation = useMutation({
    mutationFn: (clientDto: UserDto) => createNewUser(clientDto),
    onSuccess: (data) => {
      queryClient.invalidateQueries(['providers']);
      successMsg('Provider successfully created!');
      return_to_edit_user
        ? navigate(
            providersPath('edit', {
              userId: String(data.id),
              tabSid: 'details',
            })
          )
        : navigate('/providers');
    },
    onError: (error) =>
      errorMsg(error, `Something went wrong while creating provider`),
  });

  const updateProviderMutation = useMutation({
    mutationFn: ({
      providerId,
      providerDto,
    }: {
      providerId: number;
      providerDto: UserDto;
    }) => updateUser(providerId, providerDto),
    onSuccess: () => {
      queryClient.invalidateQueries(['providers', userId]);
      successMsg('Provider successfully updated!');
      navigate('/providers');
    },
    onError: (error) =>
      errorMsg(error, `Something went wrong while updating provider`),
  });

  const onSubmitProvider = async (data: FormValuesProps) => {
    const parsedPhoneNumber = parsePhoneNumber(data.phone_number, 'GB');

    // we need to make sure we only send the fields that have been changed otherwise we run into some issues from the API
    // TODO: this is not the most elegant and easiest way to do it
    // TODO: check other user creation (client, dashboard user);
    //       could this be encapsulated in a hook ?
    const dirtyFieldsData: Partial<UserDto> = {
      user_type: 2,
      profile_image: profileImageUrl,
      is_chat_user: data.is_chat_user,
    };
    (Object.keys(formState.dirtyFields) as (keyof FormValuesProps)[]).forEach(
      (field) => {
        if (formState.dirtyFields[field]) {
          const value = data[field];
          if (field === 'status') {
            Object.assign(dirtyFieldsData, {
              [field]: Number(value),
            });
            return;
          }

          if (field === 'phone_number') {
            Object.assign(dirtyFieldsData, {
              [field]: parsedPhoneNumber?.number,
            });
            return;
          }

          if (field === 'gender') {
            Object.assign(dirtyFieldsData, {
              [field]: String(value).toLowerCase(),
            });
            return;
          }
          if (field === 'return_to_edit_user') return;

          if (field === 'covered_postcodes' || field === 'nearby_postcodes') {
            Object.assign(dirtyFieldsData, {
              [field]: (value as Postcode[])
                .map((p) => p.outer_postcode)
                .join('|'),
            });
            return;
          }

          if (field === 'covered_service_types') {
            Object.assign(dirtyFieldsData, {
              service_types: (value as any[]).map((s) => s.id).join('|'),
            });
            return;
          }

          if (field === 'allergy') {
            !value && Object.assign(dirtyFieldsData, { allergy: '' });
            return;
          }

          if (field === 'allergy_description' && data.allergy) {
            Object.assign(dirtyFieldsData, {
              allergy: data.allergy_description,
            });
            return;
          }

          Object.assign(dirtyFieldsData, { [field]: value });
        }
      }
    );

    !isEmpty(currentProvider)
      ? updateProviderMutation.mutate({
          providerId: Number(userId),
          providerDto: dirtyFieldsData,
        })
      : createProviderMutation.mutate(dirtyFieldsData);
  };

  // UPLOAD PHOTO
  const uploadPhotoMutation = useMutation({
    mutationFn: (formData: FormData) => uploadImage(formData),
    onSuccess: ({
      data: {
        data: { file: fileURL },
      },
    }) => setProfileImageUrl(fileURL),
    onError: (error) => {
      errorMsg(error, `Couldn't upload profile image`);
    },
  });

  const handleFileDrop = useCallback(
    (acceptedFiles: File[]) => {
      const file = acceptedFiles[0];

      if (file) {
        setValue(
          'profile_image',
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        );
        const formData = new FormData();
        formData.append('file', file);
        uploadPhotoMutation.mutate(formData);
      }
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [setValue]
  );

  // =====================
  //    DERIVED VALUES
  // =====================

  const STATUS_OPTIONS =
    settingsData?.userStatuses.filter((m) => m.display !== 'Removed') || [];

  const { allergy, return_to_edit_user } = useWatch({
    control,
  });

  // ----------------------------------------------------------------------

  return (
    <>
      {userId && isProviderDataLoading ? (
        <SkeletonLoadingTable />
      ) : (
        <>
          <FormProvider
            methods={methods}
            onSubmit={handleSubmit(onSubmitProvider)}
          >
            <Grid
              container
              spacing={2}
              sx={{
                flexDirection: {
                  xs: 'column',
                  sm: 'row',
                },
              }}
            >
              {/* LEFT CARD */}
              <Grid
                item
                container
                spacing={2}
                xs={12}
                md={4}
                sx={{ height: 'fit-content' }}
              >
                <Grid item xs={12}>
                  <Stack component={Card} sx={{ p: 3 }}>
                    <RHFUploadAvatar
                      name="profile_image"
                      maxSize={MAX_PROFILE_PIC_UPLOAD_SIZE}
                      onDrop={handleFileDrop}
                      isAvatarUploading={uploadPhotoMutation.isLoading}
                      removeAvatar={() => setProfileImageUrl('')}
                      helperText={
                        <Typography
                          variant="caption"
                          sx={{
                            mt: 2,
                            mx: 'auto',
                            display: 'block',
                            textAlign: 'center',
                            color: 'text.secondary',
                          }}
                        >
                          Allowed *.jpeg, *.jpg, *.png, *.gif
                          <br /> max size of{' '}
                          {fData(MAX_PROFILE_PIC_UPLOAD_SIZE)}
                        </Typography>
                      }
                    />
                    <RHFSwitch
                      name="is_chat_user"
                      labelPlacement="start"
                      label={
                        <Typography variant="subtitle2">Chat user</Typography>
                      }
                      sx={{
                        mx: 0,
                        width: 1,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        gap: 2,
                        mt: 2,
                      }}
                    />
                  </Stack>
                </Grid>
                {userId && (
                  <Grid item xs={12}>
                    <UserPasswordReset currentEmail={currentProvider?.email} />
                  </Grid>
                )}
              </Grid>

              {/* RIGHT CARD */}
              <Grid
                item
                container
                spacing={2}
                xs={12}
                md={8}
                sx={{
                  height: 'fit-content',
                  flexDirection: {
                    xs: 'column',
                    sm: 'row',
                  },
                }}
              >
                {/* PERSONAL INFORMATION */}
                <Grid item xs={12} sx={{ maxWidth: '100%' }}>
                  <Stack spacing={2} component={Card} sx={{ p: 3 }}>
                    <SectionTitle title="Personal Information" />
                    <Stack direction={{ xs: 'column', md: 'row' }} spacing={2}>
                      <RHFTextField name="first_name" label="First Name" />
                      <RHFTextField name="last_name" label="Last Name" />
                    </Stack>
                    <Stack direction={{ xs: 'column', md: 'row' }} spacing={2}>
                      <RHFTextField name="position" label="Position" />
                      <RHFTextField name="gender" label="Gender" select>
                        {['Male', 'Female', 'Other'].map((option) => (
                          <MenuItem key={option} value={option.toLowerCase()}>
                            {option}
                          </MenuItem>
                        ))}
                      </RHFTextField>
                    </Stack>
                    <Stack direction={{ xs: 'column', md: 'row' }} spacing={2}>
                      <RHFTextField
                        name="short_description"
                        label="Short description"
                        multiline
                        maxRows={10}
                      />
                    </Stack>

                    <Stack direction={{ xs: 'column', md: 'row' }} spacing={2}>
                      <Box sx={{ width: '50%' }}>
                        <RHFTextField name="status" label="Status" select>
                          {STATUS_OPTIONS.map((option) => (
                            <MenuItem key={option.id} value={option.id}>
                              {option.display}
                            </MenuItem>
                          ))}
                        </RHFTextField>
                      </Box>
                      <Stack
                        direction={{ xs: 'column', md: 'row' }}
                        spacing={2}
                        sx={{ width: '50%' }}
                      >
                        <RHFSwitch
                          name="allergy"
                          label="Allergy"
                          sx={{ pb: 0.5 }}
                        />
                        {allergy && (
                          <RHFTextField
                            name="allergy_description"
                            label="Allergy Description"
                          />
                        )}
                      </Stack>
                    </Stack>
                  </Stack>
                </Grid>

                {/* CONTACT INFORMATION */}
                <Grid item xs={12}>
                  <Stack spacing={2}>
                    <Stack spacing={2} component={Card} sx={{ p: 3 }}>
                      <SectionTitle title="Contact Information" />
                      <Stack
                        direction={{ xs: 'column', md: 'row' }}
                        spacing={2}
                        sx={{ pb: 1.5 }}
                      >
                        <RHFTextField name="email" label="Email Address" />
                        <RHFTextField
                          name="phone_number"
                          label="Phone Number"
                        />
                      </Stack>
                    </Stack>
                    {!userId && <ClientAddressPlaceholder />}
                    {/* this way there's no issue with the two formproviders */}
                    <Box ref={portalRef} />
                  </Stack>
                </Grid>

                {/* COVERED POSTCODES/AREA */}
                <Grid item xs={12}>
                  <Stack spacing={2} component={Card} sx={{ p: 3 }}>
                    <SectionTitle title="Covered postcodes and service types" />
                    <Stack
                      direction={{ xs: 'column', md: 'row' }}
                      spacing={2}
                      sx={{ pb: 1.5 }}
                    >
                      <Controller
                        name="covered_postcodes"
                        control={control}
                        render={({ field: { value, onChange } }) => (
                          <Autocomplete
                            value={value}
                            fullWidth
                            size="small"
                            multiple
                            disableCloseOnSelect
                            filterSelectedOptions
                            noOptionsText="Postcode not found"
                            options={(postcodes || []).sort((a, b) =>
                              b.district && a.district
                                ? -b.district.localeCompare(a.district)
                                : -b.outer_postcode.localeCompare(
                                    a.outer_postcode
                                  )
                            )}
                            isOptionEqualToValue={(option, value) =>
                              option.id === value.id
                            }
                            getOptionLabel={(option) => option.outer_postcode}
                            onChange={(_event, value) => onChange(value)}
                            renderOption={(props, option) => (
                              <MenuItem {...props} key={option.id}>
                                {option.outer_postcode}
                              </MenuItem>
                            )}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="Covered postcodes"
                              />
                            )}
                          />
                        )}
                      />

                      <Controller
                        name="nearby_postcodes"
                        control={control}
                        render={({ field: { value, onChange } }) => (
                          <Autocomplete
                            value={value}
                            fullWidth
                            size="small"
                            multiple
                            disableCloseOnSelect
                            filterSelectedOptions
                            noOptionsText="Postcode not found"
                            groupBy={(option) =>
                              option.district
                                ? option.district
                                : option.outer_postcode
                            }
                            options={(postcodes || []).sort((a, b) =>
                              b.district && a.district
                                ? -b.district.localeCompare(a.district)
                                : -b.outer_postcode.localeCompare(
                                    a.outer_postcode
                                  )
                            )}
                            isOptionEqualToValue={(option, value) =>
                              option.id === value.id
                            }
                            getOptionLabel={(option) => option.outer_postcode}
                            onChange={(_event, value) => onChange(value)}
                            renderOption={(props, option) => (
                              <MenuItem {...props} key={option.id}>
                                {option.outer_postcode}
                              </MenuItem>
                            )}
                            renderInput={(params) => (
                              <TextField {...params} label="Nearby postcodes" />
                            )}
                          />
                        )}
                      />
                      <Controller
                        name="covered_service_types"
                        control={control}
                        render={({ field: { value, onChange } }) => (
                          <Autocomplete
                            value={value}
                            fullWidth
                            size="small"
                            multiple
                            disableCloseOnSelect
                            filterSelectedOptions
                            noOptionsText="Service type not found"
                            options={settingsData?.services || []}
                            isOptionEqualToValue={(option, value) =>
                              option.id === value.id
                            }
                            getOptionLabel={(option) => option.name}
                            onChange={(_event, value) => onChange(value)}
                            renderOption={(props, option) => (
                              <MenuItem {...props} key={option.id}>
                                {option.name}
                              </MenuItem>
                            )}
                            renderInput={(params) => (
                              <TextField {...params} label="Service types" />
                            )}
                          />
                        )}
                      />
                    </Stack>
                  </Stack>

                  <Stack alignItems="flex-end" sx={{ mt: 3 }}>
                    <LoadingButton
                      type="submit"
                      variant="contained"
                      loading={isSubmitting}
                    >
                      {currentProvider ? 'Save Changes' : 'Create Provider'}
                    </LoadingButton>
                  </Stack>
                </Grid>
              </Grid>
            </Grid>
          </FormProvider>
          {/* using a portal prevents nested formprovider issues */}
          {portalRef.current &&
            ReactDOM.createPortal(
              <>
                {userId && (
                  <ClientAddressTable
                    addresses={currentProvider?.address}
                    queryKey={['providers', userId]}
                    useGetAccessKey="providers"
                  />
                )}
              </>,
              portalRef.current
            )}
        </>
      )}
    </>
  );
}
