import { useFormContext, Controller } from 'react-hook-form';
// mui
import {
  Checkbox,
  FormControlLabel,
  FormControlLabelProps,
} from '@mui/material';

interface RHFCheckboxProps extends Omit<FormControlLabelProps, 'control'> {
  name: string;
  selectedPermissions: string[];
}

export default function RHFCheckbox({
  name,
  disabled,
  selectedPermissions,
  value,
  ...other
}: RHFCheckboxProps) {
  const { control, setValue } = useFormContext();

  const handleAddPermission = (permission: string) => {
    setValue('selected_permissions', [...selectedPermissions, permission]);
  };
  const handleRemovePermission = (permission: string) => {
    setValue(
      'selected_permissions',
      selectedPermissions.filter((p) => p !== permission)
    );
  };
  return (
    <FormControlLabel
      disabled={disabled}
      control={
        <Controller
          name={name}
          control={control}
          defaultValue={value}
          render={({ field: props }) => (
            <Checkbox
              checked={selectedPermissions.includes(name)}
              {...props}
              onChange={(e) => {
                if (e.target.checked) {
                  handleAddPermission(props.name);
                } else if (!e.target.checked) {
                  handleRemovePermission(props.name);
                }
                props.onChange(e.target.checked);
              }}
            />
          )}
        />
      }
      {...other}
    />
  );
}
