import { useEffect, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

// mui
import { FormControl, TextField } from '@mui/material';

type Props = {
  name: string;
  label: string;
  value?: string | Date;
  disabled?: boolean;
  minDate?: any;
  maxDate?: any;
  disablePast?: boolean;
  onChange?: (value: any) => void;
  size?: 'small' | 'medium';
  helperTextFromRhfContext?: string;
};

export default function RHFDatepicker({
  name,
  label,
  value,
  disabled = false,
  minDate,
  maxDate,
  disablePast,
  onChange,
  size = 'medium',
  helperTextFromRhfContext,
  ...other
}: Props) {
  const { control, setValue, getValues } = useFormContext();
  const formPickerValue = getValues(name);
  const [pickerValue, setPickerValue] = useState<Date | null>(null);
  useEffect(() => {
    value
      ? setPickerValue(new Date(value))
      : setPickerValue(new Date(formPickerValue));
  }, [value, formPickerValue]);

  return (
    <FormControl sx={{ width: '100%' }}>
      <Controller
        control={control}
        name={name}
        render={({ field, fieldState: { error } }) => {
          field.value && (error = undefined);
          return (
            <DatePicker
              minDate={minDate}
              maxDate={maxDate}
              label={label}
              disabled={disabled}
              value={pickerValue}
              disablePast={disablePast}
              onChange={(newValue) => {
                setPickerValue(newValue);
                setValue(name, newValue);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  error={!!error || !!helperTextFromRhfContext}
                  helperText={helperTextFromRhfContext ?? error?.message}
                  size={size}
                  {...other}
                />
              )}
            />
          );
        }}
      />
    </FormControl>
  );
}
