import { useState, useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, List, Badge, Button, Divider, Typography, ListSubheader, Skeleton } from '@mui/material';

import { useSelector } from '@/stores';
import { initiateNotifications } from '../slices';
import Iconify from '@/components/Iconify';
import Scrollbar from '@/components/Scrollbar';
import MenuPopover from '@/components/MenuPopover';
import IconNotificationAnimate from './IconNotificationAnimate';
import NotificationItem from './NotificationItem';
import { ROOTS_NOTIFICATIONS } from '../routes/paths';

export default function NotificationsPopover() {
  const { isLoading, notifications, totalUnseen } = useSelector((state) => state.notfication);

  const [open, setOpen] = useState<HTMLElement | null>(null);
  const navigate = useNavigate();

  const handleOpen = (event: React.MouseEvent<HTMLElement>) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };

  const handleOnClick = useCallback(() => {
    handleClose();
    navigate(ROOTS_NOTIFICATIONS);
  }, [navigate]);

  useEffect(() => {
    initiateNotifications();
  }, []);

  return isLoading ? (
    <Skeleton variant="circular" width={40} height={40} />
  ) : (
    <>
      <IconNotificationAnimate
        count={totalUnseen}
        color={open ? 'primary' : 'default'}
        onClick={handleOpen}
        sx={{
          width: 40,
          height: 40,
          '&:hover': { backgroundColor: 'background.paper' },
        }}
      >
        <Badge badgeContent={totalUnseen} color="error">
          <Iconify icon="eva:bell-fill" width={25} height={25} />
        </Badge>
      </IconNotificationAnimate>

      <MenuPopover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        sx={{ width: 360, p: 0, mt: 1.5, ml: 0.75 }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center', py: 2, px: 2.5 }}>
          <Box sx={{ flexGrow: 1 }}>
            <Typography variant="subtitle1">Notifications</Typography>
            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
              You have {totalUnseen} unread messages
            </Typography>
          </Box>
        </Box>

        <Divider sx={{ borderStyle: 'dashed' }} />

        <Scrollbar sx={{ height: { xs: 340, sm: 'auto' } }}>
          <List
            disablePadding
            subheader={
              <ListSubheader disableSticky sx={{ py: 1, px: 2.5, typography: 'overline' }}>
                New
              </ListSubheader>
            }
            sx={{
              bgcolor: 'action.selected',
            }}
          >
            {notifications
              .filter((notification) => !notification.seen)
              .map((notification) => (
                <NotificationItem key={notification.id} notificationObject={notification} popover={true} />
              ))}
          </List>

          <List
            disablePadding
            subheader={
              <ListSubheader disableSticky sx={{ py: 1, px: 2.5, typography: 'overline' }}>
                Before that
              </ListSubheader>
            }
          >
            {notifications
              .filter((notification) => notification.seen)
              .map((notification) => (
                <NotificationItem key={notification.id} notificationObject={notification} popover={true} />
              ))}
          </List>
        </Scrollbar>

        <Divider sx={{ borderStyle: 'dashed' }} />

        <Box sx={{ p: 1 }}>
          <Button fullWidth disableRipple onClick={handleOnClick}>
            View All
          </Button>
        </Box>
      </MenuPopover>
    </>
  );
}
