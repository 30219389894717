export const TeamChannelPreview = ({
  name,
  date = '',
}: {
  name: string;
  date: string;
}) => (
  <div className="channel-preview__item">
    <div className="channel-preview__name">{`# ${name}`}</div>
    <div className="channel-preview__date">{`# ${date}`}</div>
  </div>
);
