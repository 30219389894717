import { object, string, number } from 'yup';

export const CouponSchema = object().shape({
  coupon_code: string()
    .matches(
      /^[A-Za-z0-9]*$/,
      'Only alphanumeric characters are allowed (no spaces or special characters)'
    )
    .required('Code is required'),
  description: string().required('Description is required'),
  value: string().required('Value is required'),
  value_type: string().required('Value type is required'),
  usage_limit: number()
    .min(1)
    .typeError('Value must be an integer')
    .required('Usage limit is required'),
  start_date: string().required('Start date is required'),
  end_date: string().required('End date is required'),
});

export const couponDefaultValues = {
  coupon_code: '',
  description: '',
  value: '',
  value_type: '',
  status: '',
  usage_limit: '',
  start_date: '',
  end_date: '',
  coupon_platforms: [],
  user_id: null,
  testObject: null,
};
