import { useState, MouseEvent } from 'react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
//
import {
  Card,
  MenuItem,
  Rating,
  Stack,
  TableCell,
  TableRow,
  Typography,
  useTheme,
} from '@mui/material';
//
import { Iconify, Label } from '@/components';
import { TableMoreMenu, TableRowLink } from '@/components/table';
import DialogSimple from '@/components/dialog/DialogSimple';
// API
import { updateReview } from '@/features/reviews/api';
// hooks + utils
import { useSnackbarMsg } from '@/hooks/useSnackbarMsg';
import { fDateAbbrevMonth } from '@/utils/formatTime';
import { createDialog } from '@/utils/createDialog';
import { REVIEW_STATUSES } from '@/features/reviews//utils';
//
import { clientManagementPath } from '@/features/users/clients';
import { providersPath } from '@/features/users/providers';
// types
import { Review } from '@/features/reviews/types';
import { ButtonColors, MultiPurposeDialog } from '@/types';

type PreviewTableCustomRowProps = {
  row: Review;
  canUpdate: boolean;
  queryKey: string;
};

export default function ReviewsTableRow({
  row,
  canUpdate,
  queryKey,
}: PreviewTableCustomRowProps) {
  const theme = useTheme();
  const queryClient = useQueryClient();
  const { successMsg, errorMsg } = useSnackbarMsg();

  const [selectedDialog, setSelectedDialog] =
    useState<MultiPurposeDialog | null>(null);

  const { id, client, cleaner, created_at, rating, review, status } = row;

  const clientFullName = `${client.first_name} ${client.last_name}`;
  const cleanerFullName = `${cleaner.first_name} ${cleaner.last_name}`;

  const filteredSatusesBasedOnCurentStatus = REVIEW_STATUSES.filter(
    (s) => s.name !== (status || '').toLowerCase()
  );

  const currentStatusObject = REVIEW_STATUSES.find((s) => s.name === status);

  // ===================
  //      3DOT MENU
  // ===================

  const [openMenu, setOpenMenuActions] = useState<HTMLElement | null>(null);

  const handleOpenMenu = (event: MouseEvent<HTMLElement>) => {
    setOpenMenuActions(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setOpenMenuActions(null);
  };

  // ========================
  //      UPDATE STATUS
  // ========================
  const DIALOGS = [
    createDialog('rejected', 'reject', 'Reject review', 'error', () =>
      handleStatusChange('rejected')
    ),
    createDialog('published', 'publish', 'Publish review', 'success', () =>
      handleStatusChange('published')
    ),
    createDialog(
      'pending',
      'Set pending',
      'Set review status to pending',
      'warning',
      () => handleStatusChange('pending')
    ),
  ];

  const updateReviewStatusMutation = useMutation({
    mutationFn: (updatedStatus: string) =>
      updateReview(row.id, {
        status: updatedStatus,
      }),
    onSuccess: () => {
      queryClient.invalidateQueries([queryKey]);
      successMsg(`Review status updated`);
    },
    onError: (error) => {
      errorMsg(error, `There was an error while updating review status`);
    },
  });

  const handleStatusChange = async (status: string) => {
    // approved, pending, rejected
    updateReviewStatusMutation.mutate(status);
    setSelectedDialog(null);
  };

  const handleOpenDialog = (dialogName: string) => {
    const selectedDialog = DIALOGS.find((d) => d.name === dialogName);
    selectedDialog
      ? setSelectedDialog(selectedDialog)
      : errorMsg(null, 'Dialog not found');
    handleCloseMenu();
  };

  return (
    <>
      {selectedDialog && (
        <DialogSimple
          dialogTitle={selectedDialog.title}
          open={!!selectedDialog}
          onClickButtonClose={() => setSelectedDialog(null)}
          onClickButtonLoading={selectedDialog.agreeFunction}
          loadingButtonText={selectedDialog.actionVerb}
          disagreeButtonText={selectedDialog.disagreeButtonText}
          loadingButtonColor={
            selectedDialog.loadingButtonColor ?? ('primary' as ButtonColors)
          }
          disagreeButtonColor="inherit"
        >
          <Stack spacing={1} sx={{ px: 3, pt: 1.5 }}>
            <Typography>
              Confirm to set status{' '}
              <span style={{ fontWeight: 600 }}>{selectedDialog.name}</span> for
              the following review:
            </Typography>
            <Card sx={{ background: (theme) => theme.palette.grey[50], p: 2 }}>
              <Rating
                name="rating"
                defaultValue={rating}
                precision={0.5}
                readOnly
              />
              <Typography>{review}</Typography>
            </Card>
          </Stack>
        </DialogSimple>
      )}

      <TableRow hover>
        <TableCell width={10} />

        <TableCell align="left">{id}</TableCell>

        <TableCell align="left">
          <TableRowLink
            linkText={clientFullName}
            linkTo={clientManagementPath('edit', {
              userId: String(client.id),
              tabSid: 'details',
            })}
          />
        </TableCell>

        <TableCell align="left">
          <Rating
            name="rating"
            defaultValue={rating}
            precision={0.5}
            readOnly
          />
        </TableCell>

        <TableCell align="left">{review}</TableCell>

        <TableCell align="left">
          <TableRowLink
            linkText={cleanerFullName}
            linkTo={providersPath('edit', {
              userId: String(cleaner.id),
              tabSid: 'details',
            })}
          />
        </TableCell>

        <TableCell align="left">
          <Label
            variant={theme.palette.mode === 'light' ? 'ghost' : 'filled'}
            color={currentStatusObject?.color ?? 'error'}
            sx={{ textTransform: 'capitalize' }}
          >
            {currentStatusObject?.name ?? 'N/A'}
          </Label>
        </TableCell>

        <TableCell align="left">{fDateAbbrevMonth(created_at)}</TableCell>

        <TableCell align="right">
          {canUpdate && (
            <TableMoreMenu
              open={openMenu}
              onOpen={handleOpenMenu}
              onClose={handleCloseMenu}
              arrow="top-right"
              actions={
                <>
                  {canUpdate &&
                    filteredSatusesBasedOnCurentStatus.map((status) => (
                      <MenuItem
                        key={status.id}
                        onClick={() => handleOpenDialog(status.name)}
                        sx={{
                          color: `${status.color}.main`,
                        }}
                      >
                        <Iconify icon={status.icon} />
                        {status.actionVerb.charAt(0).toUpperCase() +
                          status.actionVerb.slice(1)}
                      </MenuItem>
                    ))}
                </>
              }
            />
          )}
        </TableCell>
      </TableRow>
    </>
  );
}
