import { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
// @mui
import {
  Box,
  Button,
  Card,
  Container,
  Divider,
  FormControlLabel,
  Switch,
  Tab,
  Table,
  TableBody,
  TableContainer,
  TablePagination,
  Tabs,
} from '@mui/material';
// hooks
import useTabs from '@/hooks/useTabs';
import useSettings from '@/hooks/useSettings';
import useTable, { getComparator, emptyRows } from '@/hooks/useTable';
import { useSnackbarMsg } from '@/hooks/useSnackbarMsg';
// components
import { HeaderBreadcrumbs, Iconify, Page, Scrollbar } from '@/components';
import {
  TableEmptyRows,
  TableHeadCustom,
  TableNoData,
} from '@/components/table';
// custom components
import {
  BookingsTableToolbar,
  BookingsListSkeletons,
  SessionsTableRow,
} from '../components';
// API
import { getFilteredBookingSessions } from '../api';
import { getSettingsData } from '@/features/app/api';
// utils
import { applySortFilterSessions, SESSIONS_TABLE_HEAD } from '../utils';
import { consoleError } from '@/utils/consoleError';
import { bookingsManagementPath } from '../routes/paths';
// redux
import { dispatch } from '@/stores';
import { resetReduxValues } from '@/features/bookings-management/slices';
// types

export default function SessionsList() {
  const { themeStretch } = useSettings();
  const { errorMsg } = useSnackbarMsg();
  const { currentTab: sessionFilter, onChangeTab: onChangeFilterStatus } =
    useTabs('today');

  const [searchFieldValue, setSearchFieldValue] = useState('');
  const [filterServiceId, setFilterServiceId] = useState<number>(0);
  const [filterStatusId, setFilterStatusId] = useState<number>(0);
  const [filterPaymentStatusId, setFilterPaymentStatusId] = useState<number>(0);
  const [filterServiceTypeId, setFilterServiceTypeId] = useState<number>(0);
  const [filterStartDate, setFilterStartDate] = useState<string | null>(null);
  const [filterEndDate, setFilterEndDate] = useState<string | null>(null);

  const resetFilters = () => {
    setSearchFieldValue('');
    setFilterServiceId(0);
    setFilterServiceTypeId(0);
    setFilterStartDate(null);
    setFilterEndDate(null);
    setFilterStatusId(0);
    setFilterPaymentStatusId(0);
  };

  // =================
  //      USETABLE
  // =================

  const {
    dense,
    page,
    order,
    orderBy,
    rowsPerPage,
    setPage,
    //
    selected,
    setSelected,
    //
    onSort,
    onChangeDense,
    onChangePage,
    onChangeRowsPerPage,
  } = useTable();

  // =================
  //      QUERIES
  // =================

  // SETTINGS
  const { data: settingsData } = useQuery({
    queryKey: ['settings'],
    queryFn: () => getSettingsData(),
    onError: (error) => {
      errorMsg(error, `Something went wrong while fetching settings data `);
    },
    // TODO
    // staleTime: 24 * 60 * 60 * 1000, // update every 24 hours
    // cacheTime: 24 * 60 * 60 * 1000, // update every 24 hours
  });

  // BOOKINGS
  const { data: bookingSessions, isLoading: isSessionsDataLoading } = useQuery({
    queryKey: ['bookingSessions', sessionFilter],
    queryFn: () => getFilteredBookingSessions(sessionFilter),
    onError: (error) => {
      errorMsg(
        error,
        `Something went wrong while fetching booking sessions data `
      );
    },
    enabled: !!settingsData,
  });

  // DERIVED VALUES
  const sessionFilterOptions = [
    'previous',
    'today',
    'upcoming',
    'unassigned',
    'cancelled',
  ];

  const servicesWithTheirVariants =
    settingsData?.services?.map((service) => ({
      id: service.id,
      name: service.name,
      variants: settingsData.serviceVariants.reduce<number[]>(
        (variantIDs, variant) => {
          if (variant.service_id === service.id) {
            variantIDs.push(variant.id);
          }
          return variantIDs;
        },
        []
      ),
    })) ?? [];

  // ================
  //      FILTER
  // ================

  // FILTER
  const handleSearchField = (filterName: string) => {
    setSearchFieldValue(filterName);
    setPage(0);
  };

  const handleFilterDropdown = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    switch (name) {
      case 'status':
        setFilterStatusId(Number(value));
        break;
      case 'payment_status':
        setFilterPaymentStatusId(Number(value));
        break;
      case 'service':
        setFilterServiceId(Number(value));
        setFilterServiceTypeId(0);
        break;
      case 'service_type':
        setFilterServiceTypeId(Number(value));
        break;
      case 'start_date':
        setFilterStartDate(value);
        break;
      case 'end_date':
        setFilterEndDate(value);
        break;
      default:
        consoleError('BookingsList', 'handleFilterDropdown()');
    }
    setSelected([]);
    setPage(0);
  };

  const handleDatepickerChange = (date: string | null, date_type: string) => {
    switch (date_type) {
      case 'start':
        setFilterStartDate(date);
        break;
      case 'end':
        setFilterEndDate(date);
        break;
      default:
        consoleError('BookingsList', 'handleDatepickerChange()');
    }
    setSelected([]);
    setPage(0);
  };

  const dataFiltered = applySortFilterSessions({
    tableData: bookingSessions || [],
    comparator: getComparator(order, orderBy),
    searchFieldValue,
    filterServiceId,
    filterServiceTypeId,
    filterStartDate,
    filterEndDate,
    filterStatusId,
    filterPaymentStatusId,
    servicesWithTheirVariants,
  });

  const denseHeight = dense ? 52 : 72;
  const isNotFound =
    !dataFiltered.length &&
    (!!searchFieldValue || filterServiceId !== 0 || filterStatusId !== 0);

  // ---------------------------------------------------

  return (
    <Page title="Bookings Management">
      <Container maxWidth={themeStretch ? false : 'lg'}>
        <HeaderBreadcrumbs
          heading="Booking Sessions"
          links={[
            { name: 'Dashboard', href: '' },
            { name: 'Sessions', href: '' },
          ]}
          action={
            <Button
              variant="contained"
              component={RouterLink}
              to={bookingsManagementPath('new')}
              startIcon={<Iconify icon={'eva:plus-fill'} />}
              onClick={() => dispatch(resetReduxValues())}
              disabled={isSessionsDataLoading}
            >
              New Booking
            </Button>
          }
        />

        {/* TABLE  */}
        <Card>
          {isSessionsDataLoading ? (
            <BookingsListSkeletons />
          ) : (
            <>
              <Tabs
                allowScrollButtonsMobile
                variant="scrollable"
                scrollButtons="auto"
                value={sessionFilter}
                onChange={onChangeFilterStatus}
                sx={{ px: 2, bgcolor: 'background.neutral' }}
              >
                {sessionFilterOptions.map((tab, index) => (
                  <Tab disableRipple key={index} label={tab} value={tab} />
                ))}
              </Tabs>

              <Divider />

              <BookingsTableToolbar
                filterService={filterServiceId}
                filterServiceType={filterServiceTypeId}
                filterSearchBar={searchFieldValue}
                filterStartDate={filterStartDate}
                filterEndDate={filterEndDate}
                filterStatusId={filterStatusId}
                filterPaymentStatusId={filterPaymentStatusId}
                //
                handleFilterDropdown={handleFilterDropdown}
                handleSearchField={handleSearchField}
                handleDatepickerChange={handleDatepickerChange}
                resetFilters={resetFilters}
                //
                settingsData={settingsData}
                showStatusFilters
              />

              <Scrollbar>
                <TableContainer sx={{ minWidth: 800, position: 'relative' }}>
                  <Table size={dense ? 'small' : 'medium'}>
                    <TableHeadCustom
                      order={order}
                      orderBy={orderBy}
                      headLabel={SESSIONS_TABLE_HEAD}
                      rowCount={(bookingSessions || []).length}
                      numSelected={selected.length}
                      onSort={onSort}
                    />
                    <TableBody>
                      {dataFiltered
                        .slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                        .map((session) => (
                          <SessionsTableRow key={session.id} row={session} />
                        ))}

                      <TableEmptyRows
                        height={denseHeight}
                        emptyRows={emptyRows(
                          page,
                          rowsPerPage,
                          (bookingSessions || []).length
                        )}
                      />

                      <TableNoData
                        isNotFound={isNotFound}
                        title="No sessions found"
                      />
                    </TableBody>
                  </Table>
                </TableContainer>
              </Scrollbar>

              <Box sx={{ position: 'relative' }}>
                <TablePagination
                  rowsPerPageOptions={[25, 50, 100, 150]}
                  component="div"
                  count={dataFiltered.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={onChangePage}
                  onRowsPerPageChange={onChangeRowsPerPage}
                />

                <FormControlLabel
                  control={<Switch checked={dense} onChange={onChangeDense} />}
                  label="Dense"
                  sx={{ px: 3, py: 1.5, top: 0, position: { md: 'absolute' } }}
                />
              </Box>
            </>
          )}
        </Card>
      </Container>
    </Page>
  );
}
