import { tableSortFilter } from '@/utils/arrayProcessing';
import { User } from '../../shared/types';

export function applySortFilter({
  tableData,
  comparator,
  searchFieldValue,
  filterStatus,
  filterRole,
}: {
  tableData: User[];
  comparator: (a: any, b: any) => number;
  searchFieldValue: string;
  filterStatus: string;
  filterRole: string;
}) {
  tableData = tableSortFilter(tableData, comparator);

  const searchFieldLowerCase = searchFieldValue.toLowerCase();

  if (searchFieldValue) {
    tableData = tableData.filter((user) => {
      const userFullName = `${user.first_name} ${user.last_name}`;
      return (
        userFullName.toLowerCase().indexOf(searchFieldLowerCase) !== -1 ||
        user.email.toLowerCase().indexOf(searchFieldLowerCase) !== -1
      );
    });
  }
  if (filterStatus !== 'all') {
    tableData = tableData.filter(
      (user) => user.user_statuses.value.toLowerCase() === filterStatus
    );
  }

  if (filterRole !== 'all') {
    tableData = tableData.filter((user) =>
      user.user_roles.some(
        (role) => String(role.name).toLowerCase().indexOf(filterRole) !== -1
      )
    );
  }

  return tableData;
}
