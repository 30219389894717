import { useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
// mui
import {
  Box,
  Button,
  Card,
  Container,
  Divider,
  FormControlLabel,
  Switch,
  Tab,
  Tabs,
  Table,
  TableBody,
  TableContainer,
  TablePagination,
} from '@mui/material';
// components
import { Iconify, Page, Scrollbar, HeaderBreadcrumbs } from '@/components';
import {
  TableEmptyRows,
  TableHeadCustom,
  TableNoData,
} from '@/components/table';
import { SkeletonLoadingTable } from '@/components/skeleton';
import { ToolbarWithSearchAndStatus } from '@/components/toolbar';
// custom components
import { UserTableRow } from '../components';
// API
import { getDashboardUsers, deleteUser } from '../../shared/api';
import { getRoles } from '@/features/roles-and-permissions/api';
import { getSettingsData } from '@/features/app/api';
// hooks + utils
import useTabs from '@/hooks/useTabs';
import useSettings from '@/hooks/useSettings';
import useTable, { getComparator, emptyRows } from '@/hooks/useTable';
import { useSnackbarMsg } from '@/hooks/useSnackbarMsg';
import { useGetAccess } from '@/hooks/useAccess';
//
import { DASHBOARD_USERS_TABLE_HEAD, applySortFilter } from '../utils';
import { PATH_USER } from '@/features/app';
import { userPath, ROOTS_USER_MANAGEMENT } from '../routes/paths';
// types
import { User } from '../../shared/types';

// ----------------------------------------------------------------------

export default function UserList() {
  const {
    dense,
    page,
    order,
    orderBy,
    rowsPerPage,
    setPage,
    //
    selected,
    //
    onSort,
    onChangeDense,
    onChangePage,
    onChangeRowsPerPage,
  } = useTable();

  const { themeStretch } = useSettings();
  const allow = useGetAccess('users');
  const navigate = useNavigate();
  const { errorMsg, successMsg } = useSnackbarMsg();
  const queryClient = useQueryClient();

  // =================
  //      QUERIES
  // =================

  // SETTINGS
  const { data: userStatuses, isLoading: statusesLoading } = useQuery({
    queryKey: ['settings'],
    queryFn: getSettingsData,
    onError: (error) => {
      errorMsg(error, `Something went wrong while fetching settings data `);
    },
    select: (data) => [
      'all',
      ...data?.userStatuses.map((s) => s.display.toLowerCase()),
    ],

    // TODO: revisit these settings once MVP is ready to be launched
    // staleTime: 24 * 60 * 60 * 1000, // update every 24 hours
    // cacheTime: 24 * 60 * 60 * 1000, // update every 24 hours
  });

  // DASHBOARD USERS
  const { data: dashboardUsers, isLoading: usersDataLoading } = useQuery({
    queryKey: ['dashboardUsers'],
    queryFn: getDashboardUsers,
    onError: (error) =>
      errorMsg(error, 'There was an error while fetching users'),
  });

  // DASHBOARD USERS
  const { data: roles } = useQuery({
    queryKey: ['roles'],
    queryFn: getRoles,
    onError: (error) =>
      errorMsg(error, 'There was an error while fetching roles'),
    select: (data) => ['all', ...data.map((r) => r.name)],
  });

  // =============================
  //      FILTER DELETE EDIT
  // =============================
  const [searchFieldValue, setSearchFieldValue] = useState('');
  const [filterRole, setFilterRole] = useState('all');

  const { currentTab: filterStatus, onChangeTab: onChangeFilterStatus } =
    useTabs('active');

  const handleFilterSearchValue = (filterName: string) => {
    setSearchFieldValue(filterName);
    setPage(0);
  };

  const handleFilterRole = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFilterRole(event.target.value);
    setPage(0);
  };

  const deleteClientMutation = useMutation({
    mutationFn: (id: number) => deleteUser(id),
    onSuccess: (_apiResponse, variables) => {
      queryClient.setQueryData(['dashboardUsers'], (old: User[] = []) =>
        old.filter((b) => b.id !== variables)
      );
      queryClient.invalidateQueries(['providers']);
      setPage(0);
      successMsg(`User succesfully deleted`);
    },
    onError: (error) => {
      errorMsg(error, `There was an error while deleting user`);
    },
  });

  const handleDeleteRow = async (id: number) => {
    deleteClientMutation.mutate(id);
  };

  const handleEditRow = (id: number) => {
    navigate(userPath('edit', { userId: String(id), tabSid: 'details' }));
  };

  const dataFiltered = applySortFilter({
    tableData: dashboardUsers || [],
    comparator: getComparator(order, orderBy),
    searchFieldValue,
    filterRole,
    filterStatus,
  });

  const denseHeight = dense ? 52 : 72;

  const isNotFound =
    (!dataFiltered.length && !!searchFieldValue) ||
    (!dataFiltered.length && !!filterRole) ||
    (!dataFiltered.length && !!filterStatus);

  // ----------------------------------------------------------------------

  return (
    <Page title="Dashboard Users">
      <Container maxWidth={themeStretch ? false : 'lg'}>
        <HeaderBreadcrumbs
          heading="User List"
          links={[
            { name: 'Dashboard', href: PATH_USER.root },
            { name: 'User', href: ROOTS_USER_MANAGEMENT },

            { name: 'List' },
          ]}
          action={
            allow.canCreate && (
              <Button
                variant="contained"
                component={RouterLink}
                to={userPath('new')}
                startIcon={<Iconify icon={'eva:plus-fill'} />}
              >
                Add User
              </Button>
            )
          }
        />

        <Card>
          {usersDataLoading || statusesLoading ? (
            <SkeletonLoadingTable />
          ) : (
            <>
              <Tabs
                allowScrollButtonsMobile
                variant="scrollable"
                scrollButtons="auto"
                value={filterStatus}
                onChange={onChangeFilterStatus}
                sx={{ px: 2, bgcolor: 'background.neutral' }}
              >
                {(userStatuses || []).map((tab) => (
                  <Tab disableRipple key={tab} label={tab} value={tab} />
                ))}
              </Tabs>

              <Divider />

              <ToolbarWithSearchAndStatus
                filterText={searchFieldValue}
                filterStatus={filterRole}
                onFilterText={handleFilterSearchValue}
                onFilterStatus={handleFilterRole}
                status={roles || []}
                labels={{
                  search: 'Search in users...',
                  status: 'Role',
                }}
              />

              <Scrollbar>
                <TableContainer sx={{ minWidth: 800, position: 'relative' }}>
                  <Table size={dense ? 'small' : 'medium'}>
                    <TableHeadCustom
                      order={order}
                      orderBy={orderBy}
                      headLabel={DASHBOARD_USERS_TABLE_HEAD}
                      rowCount={dataFiltered.length}
                      numSelected={selected.length}
                      onSort={onSort}
                    />
                    <TableBody>
                      {dataFiltered
                        .slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                        .map((user) => (
                          <UserTableRow
                            key={user.id}
                            row={user}
                            onDeleteRow={() => handleDeleteRow(user.id)}
                            onEditRow={() => handleEditRow(user.id)}
                            canDelete={allow.canDelete}
                            canUpdate={allow.canUpdate}
                          />
                        ))}

                      <TableEmptyRows
                        height={denseHeight}
                        emptyRows={emptyRows(
                          page,
                          rowsPerPage,
                          dataFiltered.length
                        )}
                      />

                      <TableNoData
                        isNotFound={isNotFound}
                        title="No user found"
                      />
                    </TableBody>
                  </Table>
                </TableContainer>
              </Scrollbar>

              <Box sx={{ position: 'relative' }}>
                <TablePagination
                  rowsPerPageOptions={[5, 10, 25]}
                  component="div"
                  count={dataFiltered.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={onChangePage}
                  onRowsPerPageChange={onChangeRowsPerPage}
                />

                <FormControlLabel
                  control={<Switch checked={dense} onChange={onChangeDense} />}
                  label="Dense"
                  sx={{ px: 3, py: 1.5, top: 0, position: { md: 'absolute' } }}
                />
              </Box>
            </>
          )}
        </Card>
      </Container>
    </Page>
  );
}
