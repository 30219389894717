import { useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
// mui
import {
  Box,
  Button,
  Card,
  Container,
  FormControlLabel,
  Switch,
  Table,
  TableBody,
  TableContainer,
  TablePagination,
} from '@mui/material';
import { HeaderBreadcrumbs, Page, Scrollbar, Iconify } from '@/components';
import {
  TableNoData,
  TableEmptyRows,
  TableHeadCustom,
} from '@/components/table';
import { RolesTableRow } from '../components';
import { SkeletonLoadingTable } from '@/components/skeleton';
import { ToolbarWithSearchAndStatus } from '@/components/toolbar';
// api
import { getRoles, deleteRole } from '../api/index';
// hooks + utils
import useSettings from '@/hooks/useSettings';
import useTable, { getComparator, emptyRows } from '@/hooks/useTable';
import { useGetAccess } from '@/hooks/useAccess';
import { useSnackbarMsg } from '@/hooks/useSnackbarMsg';
//
import { ROLES_TABLE_HEAD, applySortFilterForRolesList } from '../utils';
import { rolesPath, PATH_ROLES } from '../routes/paths';
// types
import type { Role } from '../types/roles';

// ----------------------------------------------------------------------

export default function RolesList() {
  const {
    dense,
    page,
    order,
    orderBy,
    rowsPerPage,
    setPage,
    //
    selected,
    //
    onSort,
    onChangeDense,
    onChangePage,
    onChangeRowsPerPage,
  } = useTable();

  const { themeStretch } = useSettings();
  const navigate = useNavigate();
  const { errorMsg, successMsg } = useSnackbarMsg();
  const allow = useGetAccess('roles & permissions');
  const queryClient = useQueryClient();

  const [searchFieldValue, setSearchFieldValue] = useState('');

  // =================
  //      QUERIES
  // =================
  const { data: roles, isLoading: isRolesDataLoading } = useQuery({
    queryKey: ['roles'],
    queryFn: getRoles,
    onError: (error) => {
      errorMsg(error, `Something went wrong while fetching roles`);
    },
  });

  const ROLE_OPTIONS = ['all'].concat((roles || []).map((role) => role.name));

  // =======================
  //    FILTER/EDIT/DELETE
  // =======================

  const [filterRole, setFilterRole] = useState('all');

  const handleFilterSearchFieldValue = (filterName: string) => {
    setSearchFieldValue(filterName);
    setPage(0);
  };

  const handleFilterRole = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFilterRole(event.target.value);
  };

  const deleteRoleMutation = useMutation({
    mutationFn: (id: number) => deleteRole(id),
    onSuccess: (_apiResponse, variables) => {
      queryClient.setQueryData(['roles'], (old: Role[] = []) =>
        old.filter((b) => b.id !== variables)
      );
      queryClient.invalidateQueries(['roles']);
      setPage(0);
      successMsg(`Role succesfully deleted`);
    },
    onError: (error) => {
      errorMsg(error, `There was an error while deleting role`);
    },
  });

  const handleDeleteRow = async (id: number) => {
    deleteRoleMutation.mutate(id);
  };
  const handleEditRow = (id: number) => {
    navigate(rolesPath('edit', { roleId: String(id) }));
  };

  const dataFiltered = applySortFilterForRolesList({
    tableData: roles || [],
    comparator: getComparator(order, orderBy),
    searchFieldValue,
    filterRole,
  });

  const denseHeight = dense ? 52 : 72;

  const isNotFound =
    (!dataFiltered.length && !!searchFieldValue) ||
    (!dataFiltered.length && !!filterRole);

  return (
    <>
      <Page title="Roles &amp; Permissions">
        <Container maxWidth={themeStretch ? false : 'lg'}>
          <HeaderBreadcrumbs
            heading="Roles &amp; Permissions"
            links={[
              { name: 'Dashboard', href: PATH_ROLES.root },
              { name: 'Roles & Permissions' },
            ]}
            action={
              allow.canCreate && (
                <Button
                  variant="contained"
                  component={RouterLink}
                  to={rolesPath('new')}
                  startIcon={<Iconify icon={'eva:plus-fill'} />}
                >
                  Add role
                </Button>
              )
            }
          />

          <Card>
            {isRolesDataLoading ? (
              <SkeletonLoadingTable />
            ) : (
              <>
                <ToolbarWithSearchAndStatus
                  filterText={searchFieldValue}
                  onFilterText={handleFilterSearchFieldValue}
                  status={ROLE_OPTIONS}
                  onFilterStatus={handleFilterRole}
                  filterStatus={filterRole}
                  labels={{ search: 'Search in roles...', status: 'Roles' }}
                />
                <Scrollbar>
                  <TableContainer sx={{ minWidth: 800, position: 'relative' }}>
                    <Table size={dense ? 'small' : 'medium'}>
                      <TableHeadCustom
                        order={order}
                        orderBy={orderBy}
                        headLabel={ROLES_TABLE_HEAD}
                        rowCount={dataFiltered.length}
                        numSelected={selected.length}
                        onSort={onSort}
                      />

                      <TableBody>
                        {dataFiltered
                          .slice(
                            page * rowsPerPage,
                            page * rowsPerPage + rowsPerPage
                          )
                          .map((role) => (
                            <RolesTableRow
                              key={role.id}
                              row={role}
                              onEditRow={() => handleEditRow(role.id)}
                              onDeleteRow={() =>
                                handleDeleteRow(Number(role.id))
                              }
                              canUpdate={allow.canUpdate}
                              canDelete={allow.canDelete}
                            />
                          ))}

                        <TableEmptyRows
                          height={denseHeight}
                          emptyRows={emptyRows(
                            page,
                            rowsPerPage,
                            dataFiltered.length
                          )}
                        />

                        <TableNoData
                          isNotFound={isNotFound}
                          title="Role is not found"
                        />
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Scrollbar>
                <Box sx={{ position: 'relative' }}>
                  <TablePagination
                    rowsPerPageOptions={[25, 50, 100]}
                    component="div"
                    count={dataFiltered.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={onChangePage}
                    onRowsPerPageChange={onChangeRowsPerPage}
                  />

                  <FormControlLabel
                    control={
                      <Switch checked={dense} onChange={onChangeDense} />
                    }
                    label="Dense"
                    sx={{
                      px: 3,
                      py: 1.5,
                      top: 0,
                      position: { md: 'absolute' },
                    }}
                  />
                </Box>
              </>
            )}
          </Card>
        </Container>
      </Page>
    </>
  );
}
