// @mui
import { Checkbox, TableRow, TableCell, Button } from '@mui/material';
// @types
import { AuditTableRowProps } from '../types';
// components
import { RemoveRedEye } from '@mui/icons-material';

// ----------------------------------------------------------------------

export default function AuditTableRow({
  row,
  selected,
  onSelectRow,
}: AuditTableRowProps) {
  const {
    user_info,
    action,
    resource_name,
    resource_id,
    fields_changed,
    created_at,
  } = row;

  function dateFormat(date: string) {
    const time = date.split('T').reverse()[0].slice(0, -5);
    const year = date.split('T')[0].split('-').reverse().join('-');
    return `${year}<br/>${time} `;
  }

  function userFormat(user: string) {
    const [userName, userEmail] = user.split('|');
    return `${userName}<br/>${userEmail}`;
  }

  return (
    <TableRow hover selected={selected}>
      <TableCell padding="checkbox">
        <Checkbox checked={selected} onClick={onSelectRow} />
      </TableCell>

      <TableCell
        sx={{ display: 'flex', alignItems: 'center' }}
        dangerouslySetInnerHTML={{ __html: userFormat(user_info) }}
      />
      <TableCell align="left">{action}</TableCell>

      <TableCell align="left">{resource_name}</TableCell>

      <TableCell align="left">{resource_id}</TableCell>
      <TableCell align="left">{fields_changed?.join(', ')}</TableCell>
      <TableCell
        align="left"
        dangerouslySetInnerHTML={{ __html: dateFormat(created_at) }}
      />

      <TableCell align="center">
        <Button
          variant="contained"
          sx={{ justifyContent: 'start' }}
          endIcon={<RemoveRedEye />}
        />
      </TableCell>
    </TableRow>
  );
}
