import { useState } from 'react';
import { useQuery } from '@tanstack/react-query';
//
import {
  Box,
  Card,
  Container,
  FormControlLabel,
  Switch,
  Table,
  TableBody,
  TableContainer,
  TablePagination,
} from '@mui/material';
import { HeaderBreadcrumbs, Page, Scrollbar } from '@/components';
import { SkeletonLoadingTable } from '@/components/skeleton';
import { ToolbarWithSearchAndStatus } from '@/components/toolbar';
import {
  TableEmptyRows,
  TableHeadCustom,
  TableNoData,
} from '@/components/table';
import { ReviewsTableRow } from '../components';
// api
import { getAllReviews } from '@/features/reviews/api';
// hookes + utils
import { useGetAccess } from '@/hooks/useAccess';
import useSettings from '@/hooks/useSettings';
import { useSnackbarMsg } from '@/hooks/useSnackbarMsg';
import useTable, { emptyRows, getComparator } from '@/hooks/useTable';
//
import {
  REVIEWS_TABLE_HEAD,
  REVIEW_STATUSES,
  applySortFilterReviews,
} from '@/features/reviews/utils';
import { PATH_USER } from '@/features/app';

export default function Reviews() {
  const {
    dense,
    page,
    order,
    orderBy,
    rowsPerPage,
    //
    selected,
    //
    onSort,
    onChangeDense,
    onChangePage,
    onChangeRowsPerPage,
  } = useTable();

  const allow = useGetAccess('reviews');
  const { errorMsg } = useSnackbarMsg();
  const { themeStretch } = useSettings();
  const [searchFieldValue, setSearchFieldValue] = useState('');

  const statusOptions = ['all', ...REVIEW_STATUSES.map((s) => s.name)];
  const [filterStatus, setFilterStatus] = useState('all');
  const handleFilterStatus = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFilterStatus(event.target.value);
  };

  // =================
  //      QUERIES
  // =================
  const { data: reviews, isLoading: reviewsLoading } = useQuery({
    queryKey: ['reviews', filterStatus],
    queryFn: () => getAllReviews(filterStatus),
    onError: (error) => {
      errorMsg(error, `Something went wrong while fetching reviews`);
    },
  });

  const dataFiltered = applySortFilterReviews({
    tableData: reviews || [],
    comparator: getComparator(order, orderBy),
    searchFieldValue,
  });

  const denseHeight = dense ? 52 : 72;

  const isNotFound =
    (!dataFiltered.length && !!searchFieldValue) || !dataFiltered.length;

  return (
    <Page title="Reviews">
      <Container maxWidth={themeStretch ? false : 'lg'}>
        <HeaderBreadcrumbs
          heading="Reviews"
          links={[
            { name: 'Dashboard', href: PATH_USER.root },
            { name: 'Reviews', href: '' },
          ]}
        />
        <Card>
          {reviewsLoading ? (
            <SkeletonLoadingTable />
          ) : (
            <>
              <ToolbarWithSearchAndStatus
                filterText={searchFieldValue}
                onFilterText={setSearchFieldValue}
                status={statusOptions}
                onFilterStatus={handleFilterStatus}
                filterStatus={filterStatus}
                labels={{ search: 'Search in reviews...', status: 'Status' }}
              />

              <Scrollbar>
                <TableContainer sx={{ minWidth: 800, position: 'relative' }}>
                  <Table size={dense ? 'small' : 'medium'}>
                    <TableHeadCustom
                      order={order}
                      orderBy={orderBy}
                      headLabel={REVIEWS_TABLE_HEAD}
                      rowCount={dataFiltered.length}
                      numSelected={selected.length}
                      onSort={onSort}
                    />
                    <TableBody>
                      {dataFiltered
                        .slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                        .map((preview) => (
                          <ReviewsTableRow
                            key={preview.id}
                            row={preview}
                            canUpdate={allow.canUpdate}
                            queryKey="reviews"
                          />
                        ))}

                      <TableEmptyRows
                        height={denseHeight}
                        emptyRows={emptyRows(
                          page,
                          rowsPerPage,
                          dataFiltered.length
                        )}
                      />

                      <TableNoData
                        isNotFound={isNotFound}
                        title="No reviews found"
                      />
                    </TableBody>
                  </Table>
                </TableContainer>
              </Scrollbar>

              <Box sx={{ position: 'relative' }}>
                <TablePagination
                  rowsPerPageOptions={[5, 10, 25]}
                  component="div"
                  count={dataFiltered.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={onChangePage}
                  onRowsPerPageChange={onChangeRowsPerPage}
                />

                <FormControlLabel
                  control={<Switch checked={dense} onChange={onChangeDense} />}
                  label="Dense"
                  sx={{ px: 3, py: 1.5, top: 0, position: { md: 'absolute' } }}
                />
              </Box>
            </>
          )}
        </Card>
      </Container>
    </Page>
  );
}
