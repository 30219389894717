import { useState, useEffect } from 'react';
import { useSnackbar } from 'notistack';
// @mui
import { Container, Grid, Skeleton, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
// custom components
import { SalesApexChart } from '../components';
// hooks
import useAuth from '@/hooks/useAuth';
import useSettings from '@/hooks/useSettings';
// API
import { getDashboardData } from '../api';
// types
import { DashboardData } from '../types';

// =======================================================
//                TEMP API CALL AND TYPES
// =======================================================
// api
// import { getDiaryData } from '@/features/diary/api';
// types
// import type { Activity } from '@/features/diary/types';
import { Page } from '@/components';
// =======================================================

export default function AgentDashboard() {
  const theme = useTheme();
  const { user } = useAuth();

  const { enqueueSnackbar } = useSnackbar();

  const [dashboardData, setDashboardData] = useState<DashboardData>();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    (async function fetchDashboardData() {
      try {
        const responseData = await getDashboardData();
        setDashboardData(responseData);
      } catch (error) {
        console.error(error);
        enqueueSnackbar('Error fetching dashboard data', { variant: 'error' });
      }
    })().finally(() => setIsLoading(false));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { themeStretch } = useSettings();
  return !isLoading && dashboardData ? (
    <Page title="Dashboard">
      <Container maxWidth={themeStretch ? false : 'lg'}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={12}>
            <Typography fontWeight="bold" fontSize={22} sx={{ mb: 6 }}>
              Welcome back, {user?.first_name}
            </Typography>
          </Grid>
          <Grid container item spacing={2}>
            <Grid item xs={12} md={3}>
              <SalesApexChart
                title="Bookings made this month"
                percent={dashboardData.product_sold.trend}
                total={dashboardData.product_sold.value}
                trendLabel={dashboardData.product_sold.trend_label}
                chartColor={theme.palette.primary.main}
                chartData={[22, 8, 35, 50, 82, 84, 77, 12, 87, 43]}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <SalesApexChart
                title="Comission income"
                percent={dashboardData.commission_earned.trend}
                total={dashboardData.commission_earned.value}
                trendLabel={dashboardData.commission_earned.trend_label}
                chartColor="#f54254"
                chartData={[22, 8, 35]}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <SalesApexChart
                title="Total Comission earned"
                percent={dashboardData.commission_earned.trend}
                total={dashboardData.commission_earned.value}
                trendLabel={dashboardData.commission_earned.trend_label}
                chartColor="#f54254"
                chartData={[22, 8, 35]}
              />
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </Page>
  ) : (
    <Page title="Dashboard">
      <Container maxWidth={themeStretch ? false : 'lg'}>
        <Grid container spacing={0}>
          <Grid item xs={12} md={12}>
            <Skeleton sx={{ width: 0.98 }} height={100} />
          </Grid>
          <Grid container spacing={0}>
            <Grid item xs={12} md={3}>
              <Skeleton sx={{ width: 0.98 }} height={100} />
            </Grid>
            <Grid item xs={12} md={3}>
              <Skeleton sx={{ width: 0.98 }} height={100} />
            </Grid>
            <Grid item xs={12} md={6}>
              <Skeleton sx={{ width: 0.98 }} height={100} />
            </Grid>
          </Grid>

          <Grid container spacing={0}>
            <Grid item xs={12} md={8}>
              <Skeleton sx={{ width: 0.98 }} height={200} />
            </Grid>
            <Grid item container xs={12} md={4}>
              <Grid item sx={{ width: '100%' }}>
                <Skeleton sx={{ width: 0.98 }} height={200} />
              </Grid>
            </Grid>
            <Grid container spacing={1}>
              <Grid item xs={12} md={4}>
                <Skeleton sx={{ width: 0.98 }} height={100} />
              </Grid>
              <Grid item xs={12} md={4}>
                <Skeleton sx={{ width: 0.98 }} height={100} />
              </Grid>
              <Grid item xs={12} md={4}>
                <Skeleton sx={{ width: 0.98 }} height={100} />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
}
