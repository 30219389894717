import { Route, Routes } from 'react-router-dom';
import DashboardLayout from '@/layouts/dashboard';
import AuthGuard from '@/guards/AuthGuard';
import { PATH_INVOICES } from './paths';

import InvoiceRunList from '../pages/InvoiceRunList';
import InvoiceRunInvoicesList from '../pages/InvoiceRunInvoicesList';
import RoleBasedGuard from '@/guards/RoleBasedGuard';
import { ProviderInvoiceDetails } from '../pages';

const INVOICES_ROUTES = [
  {
    path: PATH_INVOICES.list,
    roles: ['reviews.view'],
    component: <InvoiceRunList />,
    index: true,
  },
  {
    path: PATH_INVOICES.providerRunInvoicesList,
    roles: ['coupons.update'],
    component: <InvoiceRunInvoicesList />,
    index: false,
  },
  {
    path: PATH_INVOICES.providerInvoiceDetails,
    roles: ['coupons.update'],
    component: <ProviderInvoiceDetails />,
    index: false,
  },
];

export const InvoicesRoutes = () => (
  <Routes>
    <Route
      element={
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      }
    >
      {INVOICES_ROUTES.map((route, index) => (
        <Route
          key={index}
          path={route.path}
          index={route.index}
          element={
            <RoleBasedGuard roles={route.roles}>
              {route.component}
            </RoleBasedGuard>
          }
        />
      ))}
    </Route>
  </Routes>
);
