import { useState, MouseEvent } from 'react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
// mui
import { Box, MenuItem, TableCell, TableRow, Typography } from '@mui/material';
// components
import { Iconify } from '@/components';
import { TableMoreMenu, TableRowLink } from '@/components/table';
import DialogSimple from '@/components/dialog/DialogSimple';
import VariantCustomPriceDialog from './VariantCustomPriceDialog';
// api
import { deleteVariantCustomPrice } from '../../api';
// hooks + utils
import useToggle from '@/hooks/useToggle';
import { useSnackbarMsg } from '@/hooks/useSnackbarMsg';
import { fCurrency } from '@/utils/formatNumber';
import { fDateAbbrevMonth } from '@/utils/formatTime';
// types
import { VariantCustomPrice } from '../../types/custom-prices';

type VariantCustomPricesTableRowProps = {
  row: VariantCustomPrice;
  canDelete: boolean;
  canUpdate: boolean;
};

// ----------------------------------------------------------------------
export default function VariantCustomPricesTableRow({
  row,
  canDelete,
  canUpdate,
}: VariantCustomPricesTableRowProps) {
  const { errorMsg, successMsg } = useSnackbarMsg();
  const queryClient = useQueryClient();

  const {
    id,
    price,
    sun_bank_holiday_extra,
    description,
    start_date,
    end_date,
    overwrite,
    cities,
  } = row;

  // ============================
  //      3DOT - MENU ACTIONS
  // ============================
  const [openMenu, setOpenMenuActions] = useState<HTMLElement | null>(null);

  const handleOpenMenu = (event: MouseEvent<HTMLElement>) => {
    setOpenMenuActions(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setOpenMenuActions(null);
  };
  // =================
  //    EDIT/DELETE
  // =================
  const {
    toggle: isCustomPriceDialogOpen,
    onOpen: openCustomPriceDialog,
    onClose: closeCustomPriceDialog,
  } = useToggle();

  const {
    toggle: isDeleteDialogOpen,
    onOpen: openDeleteDialog,
    onClose: closeDeleteDialog,
  } = useToggle();

  const handleDelete = () => {
    deleteCustomPriceMutation.mutate();
    closeDeleteDialog();
  };

  const deleteCustomPriceMutation = useMutation({
    mutationFn: () => deleteVariantCustomPrice(String(id)),
    onSuccess: () => {
      queryClient.invalidateQueries(['variantCustomPrices']);
      successMsg(`Price succesfully deleted`);
    },
    onError: (error) => {
      errorMsg(error, `There was an error while deleting custom price`);
    },
  });

  // ----------------------------------------------------------------------

  return (
    <>
      <TableRow hover>
        <TableCell align="left">
          <TableRowLink
            linkText={cities.name ?? 'N/A'}
            linkTo="#"
            onClick={openCustomPriceDialog}
          />
        </TableCell>
        <TableCell align="left">{fCurrency(price)}</TableCell>
        <TableCell align="left">{fCurrency(sun_bank_holiday_extra)}</TableCell>
        <TableCell align="left">{description}</TableCell>
        <TableCell align="left">
          <Iconify
            icon={overwrite ? 'eva:checkmark-outline' : 'eva:close-outline'}
            color={overwrite ? 'success.dark' : 'text.disabled'}
          />
        </TableCell>
        <TableCell align="left">
          {fDateAbbrevMonth(start_date, 'EEE, dd MMM yyyy')}
        </TableCell>
        <TableCell align="left">
          {fDateAbbrevMonth(end_date, 'EEE, dd MMM yyyy')}
        </TableCell>

        <TableCell align="right">
          {(canDelete || canUpdate) && (
            <TableMoreMenu
              open={openMenu}
              onOpen={handleOpenMenu}
              onClose={handleCloseMenu}
              actions={
                <>
                  {canUpdate && (
                    <MenuItem
                      onClick={() => {
                        openCustomPriceDialog();
                        handleCloseMenu();
                      }}
                    >
                      <Iconify icon={'eva:edit-fill'} />
                      Edit
                    </MenuItem>
                  )}
                  {canDelete && (
                    <MenuItem
                      onClick={() => {
                        openDeleteDialog();
                        handleCloseMenu();
                      }}
                      sx={{ color: 'error.main' }}
                    >
                      <Iconify icon={'eva:trash-2-outline'} />
                      Delete
                    </MenuItem>
                  )}
                </>
              }
            />
          )}
        </TableCell>
      </TableRow>
      <DialogSimple
        dialogTitle={`Delete custom price`}
        open={isDeleteDialogOpen}
        onClickButtonClose={closeDeleteDialog}
        onClickButtonLoading={handleDelete}
        loadingButton={true}
        disagreeButton={true}
        loadingButtonText="Delete"
        loadingButtonColor="error"
        disagreeButtonText="Cancel"
        disagreeButtonColor="inherit"
      >
        <Box sx={{ px: 3 }}>
          <Typography sx={{ mt: 1 }}>
            Are you sure you want to delete this custom prices:{' '}
            <span style={{ fontWeight: 600 }}>
              {cities.name}: {fCurrency(price)}
            </span>{' '}
            ?
          </Typography>
        </Box>
      </DialogSimple>

      <VariantCustomPriceDialog
        isDialogOpen={isCustomPriceDialogOpen}
        closeDialog={closeCustomPriceDialog}
        customPriceId={String(row.id)}
      />
    </>
  );
}
