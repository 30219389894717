import { useState, MouseEvent, useRef, useEffect } from 'react';
import { Link, useSearchParams } from 'react-router-dom';
//
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Chip,
  MenuItem,
  Stack,
  Typography,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  VBMApplyCouponDialog,
  VBMBookingDetails,
  VBMCloneSessionDialog,
} from '..';
import { Iconify, Label } from '@/components';
import { TableMoreMenu } from '@/components/table';
// hooks + utils
import useToggle from '@/hooks/useToggle';
import useResponsive from '@/hooks/useResponsive';
import { useGetAccess } from '@/hooks/useAccess';
import { removeSearchParam } from '@/utils/removeSearchParams';
import { fDate } from '@/utils/formatTime';
import { providersPath } from '@/features/users/providers';
//
import { BookingSession } from '@/features/bookings-management/types';
import { LabelColors } from '@/types';

//
type VBMAccordionProps = {
  bookingSession: BookingSession;
  isExpanded?: boolean;
  hideSummary?: boolean;
};

export default function VBMAccordion({
  bookingSession,
  isExpanded,
  hideSummary = false,
}: VBMAccordionProps) {
  const accordionSummaryRef = useRef<HTMLDivElement | null>(null);
  const downMd = useResponsive('down', 'md');
  const downLg = useResponsive('down', 1500); // default lg value is 1200 but cleaners and status labels are messing up the layout from <1500
  const { canUpdate } = useGetAccess('bookings');
  const { toggle: isAccordionExpanded, onToggle: toggleAccordion } = useToggle(
    !!isExpanded
  );
  const {
    toggle: isCloneSessionDialogOpen,
    onOpen: openCloneSessionDialog,
    onClose: closeCloneSessionDialog,
  } = useToggle();
  const {
    toggle: isCouponCodeDialogOpen,
    onOpen: openCouponCodeDialog,
    onClose: closeCouponCodeDialog,
  } = useToggle();

  // 3DOTMENU AND ACCORDION
  const [openMenu, setOpenMenuActions] = useState<HTMLElement | null>(null);
  const [searchParams, setSearchParams] = useSearchParams();

  const handleOpenMenu = (event: MouseEvent<HTMLElement>) => {
    event.stopPropagation(); // it's in the accordion header, it would affect the toggle
    setOpenMenuActions(event.currentTarget);
  };

  const handleCloseMenu = (event: MouseEvent<HTMLElement>) => {
    event.stopPropagation(); // it's in the accordion header, it would affect the toggle
    setOpenMenuActions(null);
  };

  const handleToggleAccordion = () => {
    !isAccordionExpanded
      ? setSearchParams({ sessionID: String(bookingSession.id) })
      : removeSearchParam(searchParams, 'sessionID', setSearchParams);
    toggleAccordion();
  };

  // DERIVED VALUES
  const sessionDateDisplayConfig = downLg ? 'EE, do MMM yy' : 'EE, dd MMM yyyy';
  const sessionTitleDateTime = `${fDate(
    bookingSession.date,
    sessionDateDisplayConfig
  )} - ${bookingSession.time}`;
  const sessionTitleTotalHours = downLg
    ? `${bookingSession.total_hours ?? '??'}hrs`
    : `${bookingSession.total_hours ?? '??'} HOURS`;

  // STATUS AND CLEANER COMPONENT
  const renderStatusLabels = () => (
    <Stack
      direction={downLg ? 'column' : 'row'}
      spacing={downLg ? 1 : 2}
      sx={{ fontSize: 10 }}
    >
      <Label
        color={
          (bookingSession.booking_session_payment_status
            .style_class as LabelColors) ?? 'info'
        }
        sx={{ fontSize: downMd ? 11 : 14 }}
      >
        {bookingSession.booking_session_payment_status.display ?? 'N/A'}
      </Label>
      <Label
        color={
          (bookingSession.booking_session_status.style_class as LabelColors) ??
          'info'
        }
        sx={{ fontSize: downMd ? 11 : 14 }}
      >
        {bookingSession.booking_session_status.display ?? 'N/A'}
      </Label>
    </Stack>
  );

  const renderAssignedCleaner = () => (
    <Stack direction={downLg ? 'column' : 'row'} spacing={downLg ? 1 : 2}>
      {bookingSession.booking_session_cleaners.map((cleaner) => (
        <Chip
          label={
            <span style={{ display: 'flex', alignItems: 'center' }}>
              {`${cleaner?.first_name ?? 'N/A'} ${cleaner?.last_name ?? 'N/A'}`}
              {cleaner?.seen_by_provider ? (
                <Iconify
                  icon={'eva:eye-fill'}
                  width={16}
                  height={16}
                  sx={{ color: 'success.dark', marginLeft: 1 }}
                  title="Seen"
                />
              ) : (
                <Iconify
                  icon={'eva:eye-fill'}
                  width={16}
                  height={16}
                  sx={{ opacity: 0.2, marginLeft: 1 }}
                  title="Not Seen"
                />
              )}
              {cleaner?.accepted_by_provider ? (
                <Iconify
                  icon={'eva:checkmark-circle-2-fill'}
                  width={16}
                  height={16}
                  sx={{ color: 'success.dark', marginLeft: 1 }}
                  title="Accepted"
                />
              ) : (
                <Iconify
                  icon={'eva:checkmark-circle-2-fill'}
                  width={16}
                  height={16}
                  sx={{ opacity: 0.2, marginLeft: 1 }}
                  title="Not Accepted"
                />
              )}
            </span>
          }
          key={cleaner?.id}
          color="default"
          sx={{ cursor: 'pointer', pointerEvents: 'auto' }}
          component={Link}
          to={providersPath('edit', {
            userId: String(cleaner.id),
            tabSid: 'details',
          })}
          size="small"
        />
      ))}
    </Stack>
  );

  // HANDLE CLONE + COUPON CODE
  const handleCloneSession = () => {
    openCloneSessionDialog();
    setOpenMenuActions(null);
  };

  const handleApplyCoupon = () => {
    openCouponCodeDialog();
    setOpenMenuActions(null);
  };

  // SCROLL TO EXPANDED ACCORDION
  const scrollToAccordion = () => {
    if (accordionSummaryRef.current) {
      accordionSummaryRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
    }
  };
  useEffect(() => {
    let timeoutId: NodeJS.Timeout;

    if (isExpanded) {
      timeoutId = setTimeout(() => {
        scrollToAccordion();
      }, 150);
    }

    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [isExpanded]);

  return (
    <>
      <Accordion expanded={isAccordionExpanded}>
        {!hideSummary && (
          <AccordionSummary
            expandIcon={<ExpandMoreIcon onClick={handleToggleAccordion} />}
            onClick={handleToggleAccordion}
            sx={{
              backgroundColor: isAccordionExpanded
                ? 'primary.lighter'
                : 'inherit',
            }}
            ref={isAccordionExpanded ? accordionSummaryRef : null}
          >
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              sx={{ width: '100%', mr: 1 }}
            >
              {/* TITLE */}
              <Stack direction="row" spacing={1.2}>
                <Box sx={{ minWidth: 20 }}>
                  <Iconify
                    icon={
                      bookingSession.is_clone ? 'flowbite:file-clone-solid' : ''
                    }
                    sx={{ color: 'info.main' }}
                  />
                </Box>
                <Stack
                  direction={downMd ? 'column' : 'row'}
                  spacing={downMd ? 0.5 : 1.5}
                  divider={
                    downMd ? null : (
                      <span style={{ fontSize: 16, fontWeight: 600 }}> - </span>
                    )
                  }
                >
                  <Typography fontSize={downMd ? 14 : 16} fontWeight={600}>
                    #{bookingSession.id}
                  </Typography>
                  <Typography fontSize={downMd ? 12 : 16} fontWeight={600}>
                    {sessionTitleDateTime}
                  </Typography>
                  <Typography
                    fontSize={downMd ? 12 : 16}
                    fontWeight={600}
                    sx={{
                      backgroundColor: (theme) =>
                        isAccordionExpanded
                          ? theme.palette.background.paper
                          : theme.palette.primary.lighter,
                      px: 1,
                      borderRadius: 1,
                    }}
                  >
                    {sessionTitleTotalHours}
                  </Typography>
                  {downMd && renderAssignedCleaner()}
                  {downMd && renderStatusLabels()}
                </Stack>
              </Stack>

              <Stack
                direction="row"
                spacing={downMd ? 2 : 4}
                alignItems="center"
              >
                {/* STATUS LABELS */}
                {!downMd && renderAssignedCleaner()}
                {!downMd && renderStatusLabels()}

                {/* 3-DOT-MENU */}
                <TableMoreMenu
                  open={openMenu}
                  onOpen={handleOpenMenu}
                  onClose={handleCloseMenu}
                  iconHeight={15}
                  iconWidth={15}
                  popOverWidth={170}
                  arrow="top-right"
                  actions={
                    <>
                      {canUpdate && (
                        <MenuItem
                          onClick={(e) => {
                            e.stopPropagation();
                            handleCloneSession();
                          }}
                        >
                          <Iconify icon={'clarity:clone-line'} />
                          Clone session
                        </MenuItem>
                      )}
                      {canUpdate && (
                        <MenuItem
                          onClick={(e) => {
                            e.stopPropagation();
                            handleApplyCoupon();
                          }}
                        >
                          <Iconify icon={'iconoir:percentage'} />
                          Apply Coupon
                        </MenuItem>
                      )}
                    </>
                  }
                />
              </Stack>
            </Stack>
          </AccordionSummary>
        )}
        <AccordionDetails>
          <VBMBookingDetails
            displayPaymentDetails
            sessionView
            bookingSession={bookingSession}
          />
        </AccordionDetails>
      </Accordion>
      {isCouponCodeDialogOpen && (
        <VBMApplyCouponDialog
          isDialogOpen={isCouponCodeDialogOpen}
          closeDialog={closeCouponCodeDialog}
          serviceVariantId={bookingSession.service_variant.id}
          bookingSessionId={bookingSession.id}
        />
      )}
      {isCloneSessionDialogOpen && (
        <VBMCloneSessionDialog
          isDialogOpen={isCloneSessionDialogOpen}
          closeDialog={closeCloneSessionDialog}
          currentBookingSession={bookingSession}
        />
      )}
    </>
  );
}
