import { Link, useSearchParams } from 'react-router-dom';
import { format } from 'date-fns';
// @mui
import {
  Chip,
  Fade,
  IconButton,
  MenuItem,
  Stack,
  TableCell,
  TableRow,
  Typography,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
// components
import { Iconify, Label } from '@/components';
import { TableMoreMenu, TableRowLink } from '@/components/table';
import AddressWithGoogleMapsIconButton from '@/components/AddressWithGoogleMapsIconButton';
import {
  VBMAccordion,
  VBMApplyCouponDialog,
  VBMCloneSessionDialog,
} from '../bookings/view';
// hooks + utils
import useToggle from '@/hooks/useToggle';
import { bookingsManagementPath } from '@/features/bookings-management';
import { clientManagementPath } from '@/features/users/clients';
import { providersPath } from '@/features/users/providers';
import { returnSessionFilterFromDate } from '../../utils';
import { removeSearchParam } from '@/utils/removeSearchParams';
// types
import { BookingSession } from '@/features/bookings-management/types';
import { LabelColors } from '@/types';
import { useGetAccess } from '@/hooks/useAccess';
import { use3DotMenu } from '@/hooks/use3DotMenu';

type SessionsTableRowProps = {
  row: BookingSession;
};

// ----------------------------------------------------------------------

export default function SessionsTableRow({ row }: SessionsTableRowProps) {
  const {
    id,
    booking_id,
    user,
    address,
    booking_session_cleaners,
    booking_session_payment_status,
    booking_session_status,
    total_hours,
    service_variant,
    date,
    time,
    is_clone,
  } = row;
  const [searchParams, setSearchParams] = useSearchParams();
  const { canUpdate } = useGetAccess('bookings');
  // =========================
  //    ACCORDION, DIALOG
  // =========================

  // DIALOGS
  const {
    toggle: isCloneSessionDialogOpen,
    onOpen: openCloneSessionDialog,
    onClose: closeCloneSessionDialog,
  } = useToggle();
  const {
    toggle: isCouponCodeDialogOpen,
    onOpen: openCouponCodeDialog,
    onClose: closeCouponCodeDialog,
  } = useToggle();

  const { openMenu, handleOpenMenu, handleCloseMenu } = use3DotMenu();

  const handleCloneSession = () => {
    openCloneSessionDialog();
    handleCloseMenu();
  };

  const handleApplyCoupon = () => {
    openCouponCodeDialog();
    handleCloseMenu();
  };

  // ACCORDION
  const { toggle: isAccordionOpen, onToggle: toggleAccordion } = useToggle();

  const handleToggleAccordion = () => {
    !isAccordionOpen
      ? setSearchParams({ sessionID: String(id) })
      : removeSearchParam(searchParams, 'sessionID', setSearchParams);
    toggleAccordion();
  };

  const isHolidayRental =
    service_variant?.service.name.indexOf('Holiday Rental') !== -1;

  const renderAssignedCleaner = () => (
    <Stack direction="row" spacing={1}>
      {booking_session_cleaners.map((cleaner) => (
        <Chip
          label={
            <span style={{ display: 'flex', alignItems: 'center' }}>
              {`${cleaner?.first_name ?? 'N/A'} ${cleaner?.last_name ?? 'N/A'}`}
              {cleaner?.seen_by_provider ? (
                <Iconify
                  icon={'eva:eye-fill'}
                  width={16}
                  height={16}
                  sx={{ color: 'success.dark', marginLeft: 1 }}
                  title="Seen"
                />
              ) : (
                <Iconify
                  icon={'eva:eye-fill'}
                  width={16}
                  height={16}
                  sx={{ opacity: 0.2, marginLeft: 1 }}
                  title="Not Seen"
                />
              )}
              {cleaner?.accepted_by_provider ? (
                <Iconify
                  icon={'eva:checkmark-circle-2-fill'}
                  width={16}
                  height={16}
                  sx={{ color: 'success.dark', marginLeft: 1 }}
                  title="Accepted"
                />
              ) : (
                <Iconify
                  icon={'eva:checkmark-circle-2-fill'}
                  width={16}
                  height={16}
                  sx={{ opacity: 0.2, marginLeft: 1 }}
                  title="Not Accepted"
                />
              )}
            </span>
          }
          key={cleaner?.id}
          color="default"
          sx={{ cursor: 'pointer' }}
          component={Link}
          to={providersPath('edit', {
            userId: String(cleaner.id),
            tabSid: 'details',
          })}
          size="small"
        />
      ))}
    </Stack>
  );
  return (
    <>
      <TableRow
        hover
        sx={{
          verticalAlign: 'center',
          backgroundColor: isAccordionOpen ? 'primary.lighter' : 'inherit',
        }}
      >
        {/* ID */}
        <TableCell>
          <Stack direction="row" spacing={1} alignItems="center">
            <TableRowLink
              linkText={id ?? 'N/A'}
              linkTo={bookingsManagementPath('view', {
                bookingId: String(booking_id),
                bookingTabSid: 'booking-sessions',
                sessionFilter: `${returnSessionFilterFromDate(
                  date
                )}?sessionID=${id}`,
              })}
              sx={{ width: 'fit-content', ml: -0.5 }}
              target="_blank"
            />
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="center"
              spacing={1}
            >
              {is_clone && (
                <Iconify
                  icon={'flowbite:file-clone-solid'}
                  sx={{ color: 'info.main' }}
                />
              )}
              {isHolidayRental && <Iconify icon="logos:airbnb-icon" />}
            </Stack>
          </Stack>
        </TableCell>

        {/* SERVICE TYPE + NAME */}
        <TableCell align="left">
          <Typography noWrap fontSize={14} fontWeight={500}>
            {service_variant?.service.name ?? 'N/A'}
          </Typography>
          <Typography noWrap fontSize={14} fontWeight={500}>
            {service_variant?.name ?? 'N/A'}
          </Typography>
        </TableCell>

        {/* DATE + TIME */}
        <TableCell align="left">
          <Typography noWrap fontSize={14}>
            {format(new Date(date), 'EEE, dd MMM yyyy')}
          </Typography>
        </TableCell>
        <TableCell align="left">{time}</TableCell>

        {/* TOTAL HOURS */}
        <TableCell align="left">{total_hours}</TableCell>

        {/* CUSTOMER DETAILS */}
        <TableCell align="left">
          <Stack direction="column" spacing={0}>
            <TableRowLink
              linkText={user?.first_name + ' ' + user?.last_name}
              linkTo={clientManagementPath('edit', {
                userId: String(user.id),
                tabSid: 'details',
              })}
            />
            <TableRowLink
              linkText={user?.email ?? 'N/A'}
              linkTo={`mailto:${user?.email ?? ''}`}
            />
          </Stack>
        </TableCell>

        {/* CLEANER */}
        <TableCell align="left">
          {!!booking_session_cleaners.length && renderAssignedCleaner()}
        </TableCell>

        {/* ADDRESS */}
        <TableCell align="left">
          <AddressWithGoogleMapsIconButton
            formatted_address={address.formatted_address}
            postcode={address.postcode}
          />
        </TableCell>

        {/* STATUSUSES */}
        <TableCell align="left">
          <Stack spacing={0.5}>
            <Label
              color={
                (booking_session_payment_status.style_class as LabelColors) ||
                'info'
              }
            >
              {booking_session_payment_status.display || 'N/A'}
            </Label>
            <Label
              color={
                (booking_session_status.style_class as LabelColors) || 'info'
              }
            >
              {booking_session_status.display || 'N/A'}
            </Label>
          </Stack>
        </TableCell>

        <TableCell align="right" sx={{ px: 0 }}>
          {/* originally this menu is kept in the accordion summary but that part of the component is hidden here */}
          {/* there's a bit of duplication going on here */}
          <Stack direction="row" spacing={1}>
            {/* 3-DOT-MENU */}
            <TableMoreMenu
              open={openMenu}
              onOpen={handleOpenMenu}
              onClose={handleCloseMenu}
              iconHeight={15}
              iconWidth={15}
              popOverWidth={170}
              arrow="top-right"
              actions={
                <>
                  {canUpdate && (
                    <MenuItem
                      onClick={(e) => {
                        e.stopPropagation();
                        handleCloneSession();
                      }}
                    >
                      <Iconify icon={'clarity:clone-line'} />
                      Clone session
                    </MenuItem>
                  )}
                  {canUpdate && (
                    <MenuItem
                      onClick={(e) => {
                        e.stopPropagation();
                        handleApplyCoupon();
                      }}
                    >
                      <Iconify icon={'iconoir:percentage'} />
                      Apply Coupon
                    </MenuItem>
                  )}
                </>
              }
            />
            <IconButton
              onClick={handleToggleAccordion}
              sx={{
                transform: isAccordionOpen ? `rotate(180deg)` : '',
                transition: `transform 0.3s`,
              }}
            >
              <ExpandMoreIcon />
            </IconButton>
          </Stack>
        </TableCell>
      </TableRow>
      <Fade
        in={isAccordionOpen}
        mountOnEnter
        unmountOnExit
        timeout={{ enter: 500, exit: 300 }}
      >
        <TableRow>
          <TableCell colSpan={100}>
            <VBMAccordion bookingSession={row} hideSummary />
          </TableCell>
        </TableRow>
      </Fade>
      {isCouponCodeDialogOpen && (
        <VBMApplyCouponDialog
          isDialogOpen={isCouponCodeDialogOpen}
          closeDialog={closeCouponCodeDialog}
          serviceVariantId={service_variant.id}
          bookingSessionId={id}
        />
      )}
      {isCloneSessionDialogOpen && (
        <VBMCloneSessionDialog
          isDialogOpen={isCloneSessionDialogOpen}
          closeDialog={closeCloneSessionDialog}
          currentBookingSession={row}
        />
      )}
    </>
  );
}
