import { BaseObjectEntity } from '@/types';
import { path } from '@/utils/formatLink';
import { generatePath } from 'react-router';

export const ROOTS_BOOKINGS_MANAGEMENT = '/bookings-management';

export const PATH_BOOKINGS_MANAGEMENT: BaseObjectEntity = {
  root: ROOTS_BOOKINGS_MANAGEMENT,
  bookingsRoot: `/bookings`,
  list: '/bookings/list',
  new: '/bookings/new',
  edit: '/bookings/edit/:bookingId',
  view: '/bookings/view/:bookingId/:bookingTabSid',
  viewWithSessionFilter:
    '/bookings/view/:bookingId/:bookingTabSid/:sessionFilter',
  sessionsRoot: '/sessions',
  sessionsList: '/sessions/list',
};

export function bookingsManagementPath(
  pathKey: string,
  params?: BaseObjectEntity
) {
  let finalPathKey = pathKey;

  // Check if we need to switch to the route with the filter parameter
  if (pathKey === 'view' && params?.bookingTabSid === 'booking-sessions') {
    finalPathKey = 'viewWithSessionFilter';
  }
  return generatePath(
    path(ROOTS_BOOKINGS_MANAGEMENT, PATH_BOOKINGS_MANAGEMENT[finalPathKey]),
    params
  );
}
