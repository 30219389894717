// mui
import { useTheme } from '@mui/material/styles';
import { Box, MenuItem, TableCell, TableRow, Typography } from '@mui/material';
//
import { Iconify, Label } from '@/components';
import { TableMoreMenu, TableRowLink } from '@/components/table';
import DialogSimple from '@/components/dialog/DialogSimple';
// utils + hooks
import { clientManagementPath } from '../../routes/paths';
import useToggle from '@/hooks/useToggle';
import { use3DotMenu } from '@/hooks/use3DotMenu';
// types
import { LabelColors } from '@/types';
import { User } from '@/features/users/shared/types';

type ClientTableRowProps = {
  row: User;
  onEditRow: VoidFunction;
  onDeleteRow: VoidFunction;
  canDelete: boolean;
  canUpdate: boolean;
};

export default function ClientTableRow({
  row,
  onEditRow,
  onDeleteRow,
  canDelete,
  canUpdate,
}: ClientTableRowProps) {
  const theme = useTheme();

  const {
    id,
    first_name,
    last_name,
    email,
    phone,
    user_statuses,
    is_chat_user,
  } = row;

  // ===================

  const { openMenu, handleCloseMenu, handleOpenMenu } = use3DotMenu();

  // ========================
  //      DELETE CLIENT
  // ========================
  const {
    toggle: isDeleteDialogOpen,
    onOpen: openDeleteDialog,
    onClose: closeDeleteDialog,
  } = useToggle();

  const handleDelete = () => {
    onDeleteRow();
    closeDeleteDialog();
  };

  // =============================================

  return (
    <>
      <DialogSimple
        dialogTitle={`Delete client: ${first_name} ${last_name}`}
        open={isDeleteDialogOpen}
        onClickButtonClose={closeDeleteDialog}
        onClickButtonLoading={handleDelete}
        loadingButton={true}
        disagreeButton={true}
        loadingButtonText="Delete"
        loadingButtonColor="error"
        disagreeButtonText="Cancel"
        disagreeButtonColor="inherit"
      >
        <Box sx={{ px: 3 }}>
          <Typography variant="body2" sx={{ mb: 2 }}>
            Are you sure you want to delete this client:{' '}
            <span style={{ fontWeight: 600 }}>
              {first_name} {last_name}
            </span>
            ?
          </Typography>
        </Box>
      </DialogSimple>

      <TableRow hover sx={{ verticalAlign: 'center' }}>
        <TableCell width={10} />

        <TableCell>
          <TableRowLink
            linkTo={clientManagementPath('edit', {
              userId: String(id),
              tabSid: 'details',
            })}
            linkText={`${first_name} ${last_name}`}
            noWrap
          />
        </TableCell>

        <TableCell align="left">
          <TableRowLink linkText={email} linkTo={`mailto:${email}`} />
        </TableCell>

        <TableCell align="left">{phone}</TableCell>

        <TableCell align="left">
          <Iconify
            icon={
              is_chat_user
                ? 'eva:checkmark-circle-2-fill'
                : 'eva:close-circle-outline'
            }
            color={is_chat_user ? 'green' : 'gray'}
          />
        </TableCell>

        <TableCell align="left">
          <Label
            variant={theme.palette.mode === 'light' ? 'ghost' : 'filled'}
            color={
              user_statuses
                ? (user_statuses.style_class as LabelColors)
                : 'default'
            }
            sx={{ textTransform: 'capitalize' }}
          >
            {user_statuses ? user_statuses.value : 'N/A'}
          </Label>
        </TableCell>

        <TableCell align="right">
          {(canDelete || canUpdate) && (
            <TableMoreMenu
              open={openMenu}
              onOpen={handleOpenMenu}
              onClose={handleCloseMenu}
              actions={
                <>
                  {canUpdate && (
                    <MenuItem
                      onClick={() => {
                        onEditRow();
                        handleCloseMenu();
                      }}
                    >
                      <Iconify icon={'eva:edit-fill'} />
                      Edit
                    </MenuItem>
                  )}
                  {canDelete && (
                    <MenuItem
                      onClick={() => {
                        openDeleteDialog();
                        handleCloseMenu();
                      }}
                      sx={{ color: 'error.main' }}
                    >
                      <Iconify icon={'eva:trash-2-outline'} />
                      Delete
                    </MenuItem>
                  )}
                </>
              }
            />
          )}
        </TableCell>
      </TableRow>
    </>
  );
}
