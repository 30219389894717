import { isEmpty } from 'lodash';
import { InitialBookingState } from '../types';
import { formatAddress } from '@/utils/formatAddress';

export const getDefaultClientAddress = (
  booking: InitialBookingState
): string | null => {
  let defaultAddress = null;
  if (!isEmpty(booking.client)) {
    const defAddress = booking.client.address.find(
      (address) => address.default
    );
    if (defAddress) {
      const { formattedAddressWithPostcode } = formatAddress(
        defAddress.formatted_address,
        defAddress.postcode
      );
      defaultAddress = formattedAddressWithPostcode;
    }
  }
  return defaultAddress;
};

export const setEvaIcon = (isEmpty: boolean) =>
  isEmpty ? 'eva:plus-fill' : 'eva:edit-fill';
