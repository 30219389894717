// components

import { ROOTS_AUDIT } from '@/features/audit';
import { ROOTS_SETTINGS } from '@/features/settings';
import { ROOTS_ROLES } from '@/features/roles-and-permissions';

import { ROOTS_USER_MANAGEMENT } from '@/features/users/dashboard-users';
import SvgIconStyle from '../../../components/SvgIconStyle';
import Iconify from '@/components/Iconify';
import { ROOTS_AGENTDASHBOARD } from '@/features/agent-dashboard';
import {
  ROOTS_BOOKINGS_MANAGEMENT,
  bookingsManagementPath,
} from '@/features/bookings-management';
import {
  ROOTS_SERVICES_MANAGEMENT,
  PATH_SERVICES_MANAGEMENT,
} from '@/features/services-management';
import { ROOTS_PROVIDER_MANAGEMENT } from '@/features/users/providers';
import { ROOTS_COUPONS } from '@/features/coupons';
import { ROOTS_CLIENT_MANAGEMENT } from '@/features/users/clients';
import { ROOTS_REVIEWS } from '@/features/reviews';
import { ROOTS_JOB_SCHEDULER } from '@/features/job-scheduler';
import { invoicesPath, ROOT_INVOICES } from '@/features/invoices';
import { ROOTS_TASKS } from '@/features/tasks';

const getIcon = (name: string) => (
  <SvgIconStyle
    src={`/assets/icons/navbar/${name}.svg`}
    sx={{ width: 1, height: 1 }}
  />
);

const ICONS = {
  user: getIcon('ic_user'),
  audit: <Iconify icon={'eva:file-text-fill'} width={32} height={32} />,
  ecommerce: getIcon('ic_ecommerce'),
  analytics: getIcon('ic_analytics'),
  dashboard: getIcon('ic_dashboard'),
  agent_dashboard: <Iconify icon={'eva:grid-fill'} width={32} height={32} />,
  roles: <Iconify icon={'eva:lock-fill'} width={32} height={32} />,
  pots: <Iconify icon={'eva:stop-circle-fill'} width={32} height={32} />,
  settings: <Iconify icon={'eva:settings-fill'} width={32} height={32} />,
  products: <Iconify icon={'eva:cube-fill'} width={32} height={32} />,
  flow: <Iconify icon={'eva:phone-outline'} width={32} height={32} />,
  diary: <Iconify icon={'eva:calendar-fill'} width={32} height={32} />,
  coupons: <Iconify icon={'mdi:coupon'} width={32} height={32} />,
  bookings: <Iconify icon={'eva:book-open-outline'} width={32} height={32} />,
  reviews: (
    <Iconify icon={'eva:message-square-outline'} width={32} height={32} />
  ),
  services: <Iconify icon={'healthicons:clean-hands'} width={32} height={32} />,
  invoices: <Iconify icon={'eva:file-text-fill'} width={32} height={32} />,
  tasks: <Iconify icon={'eva:list-fill'} width={32} height={32} />,
};

const navConfig = [
  {
    subheader: '',
    restricted_to: [],
    items: [
      {
        title: 'Dahsboard',
        path: ROOTS_AGENTDASHBOARD,
        icon: ICONS.agent_dashboard,
        restricted_to: [],
      },
      {
        title: 'Tasks',
        path: ROOTS_TASKS,
        icon: ICONS.tasks,
        restricted_to: [],
      },
    ],
  },
  {
    subheader: 'management',
    restricted_to: ['management'],
    items: [
      {
        title: 'Dashboard Users',
        path: ROOTS_USER_MANAGEMENT,
        icon: ICONS.user,
        restricted_to: ['users.view'],
      },
      {
        title: 'Providers',
        path: ROOTS_PROVIDER_MANAGEMENT,
        icon: ICONS.user,
        restricted_to: ['users.view'],
      },
      {
        title: 'Clients',
        path: ROOTS_CLIENT_MANAGEMENT,
        icon: ICONS.user,
        restricted_to: ['users.view'],
      },

      {
        title: 'bookings',
        path: ROOTS_BOOKINGS_MANAGEMENT,
        icon: ICONS.bookings,
        restricted_to: ['users.view'],
        children: [
          {
            title: 'Manage bookings',
            path: bookingsManagementPath('list'),
            restricted_to: ['users.view'],
          },
          {
            title: 'Manage sessions',
            path: bookingsManagementPath('sessionsList'),
            restricted_to: ['users.view'],
          },
        ],
      },

      {
        title: 'Job Scheduler',
        path: ROOTS_JOB_SCHEDULER,
        icon: ICONS.diary,
        restricted_to: ['roles_permissions.view'],
      },
      {
        title: 'services',
        path: ROOTS_SERVICES_MANAGEMENT,
        icon: ICONS.services,
        restricted_to: ['users.view'],
        children: [
          {
            title: 'Manage Services',
            path: `${PATH_SERVICES_MANAGEMENT.root}${PATH_SERVICES_MANAGEMENT.services}`,
            restricted_to: ['users.view'],
          },
          {
            title: 'Service Variants',
            path: `${PATH_SERVICES_MANAGEMENT.root}${PATH_SERVICES_MANAGEMENT.variants}`,
            restricted_to: ['users.view'],
          },
        ],
      },
      {
        title: 'invoices',
        path: ROOT_INVOICES,
        icon: ICONS.invoices,
        restricted_to: ['users.view'],
        children: [
          {
            title: 'Cleaner Invoices',
            path: invoicesPath('list'),
            restricted_to: ['users.view'],
          },
        ],
      },
      {
        title: 'Coupons',
        path: ROOTS_COUPONS,
        icon: ICONS.coupons,
        restricted_to: ['coupons.view'],
      },
      {
        title: 'Reviews',
        path: ROOTS_REVIEWS,
        icon: ICONS.reviews,
        restricted_to: ['reviews.view'],
      },
      {
        title: 'Roles & Permissions',
        path: ROOTS_ROLES,
        icon: ICONS.roles,
        restricted_to: ['roles_permissions.view'],
      },
    ],
  },
  {
    subheader: 'Payments',
    restricted_to: ['payments'],
    items: [
      {
        title: 'Transactions',
        path: ROOTS_AUDIT,
        icon: ICONS.audit,
        restricted_to: ['audit.view'],
      },
      {
        title: 'Refunds',
        path: ROOTS_SETTINGS,
        icon: ICONS.settings,
        restricted_to: ['settings.view'],
      },
    ],
  },
  {
    subheader: 'System',
    restricted_to: ['system'],
    items: [
      {
        title: 'Audit Logs',
        path: ROOTS_AUDIT,
        icon: ICONS.audit,
        restricted_to: ['audit.view'],
      },
      {
        title: 'Settings',
        path: ROOTS_SETTINGS,
        icon: ICONS.settings,
        restricted_to: ['settings.view'],
      },
    ],
  },
];

export default navConfig;
