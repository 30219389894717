// form
import { useFormContext, Controller } from 'react-hook-form';
// @mui
import {
  Switch,
  FormHelperText,
  FormControlLabel,
  FormControlLabelProps,
} from '@mui/material';

// ----------------------------------------------------------------------

type IProps = Omit<FormControlLabelProps, 'control'>;

interface Props extends IProps {
  name: string;
}

export default function RHFSwitch({ name, ...other }: Props) {
  const { control } = useFormContext();

  return (
    <FormControlLabel
      control={
        <Controller
          name={name}
          control={control}
          render={({ field, fieldState: { error } }) => (
            <>
              <Switch {...field} checked={field.value} />
              {error && <FormHelperText error>{error.message}</FormHelperText>}
            </>
          )}
        />
      }
      {...other}
    />
  );
}
