import { isBefore, isAfter } from 'date-fns';
import { tableSortFilter } from '@/utils/arrayProcessing';
import { Coupon } from '../types';

export function applySortFilterCoupons({
  tableData,
  comparator,
  searchFieldValue,
  filterUserId,
  filterStatus,
  filterPlatform,
  filterStartDate,
  filterEndDate,
}: {
  tableData: Coupon[];
  comparator: (a: any, b: any) => number;
  searchFieldValue: string;
  filterUserId: number;
  filterStatus: string;
  filterPlatform: number;
  filterStartDate: string | null;
  filterEndDate: string | null;
}) {
  tableData = tableSortFilter(tableData, comparator);

  if (searchFieldValue) {
    tableData = tableData.filter(
      (coupon) =>
        coupon.code.toLowerCase().indexOf(searchFieldValue.toLowerCase()) !==
          -1 ||
        coupon.description
          .toLowerCase()
          .indexOf(searchFieldValue.toLowerCase()) !== -1 ||
        String(coupon.value).indexOf(searchFieldValue.toLowerCase()) !== -1 ||
        String(coupon.usage_limit).indexOf(searchFieldValue.toLowerCase()) !==
          -1 ||
        (coupon.coupon_platforms &&
          coupon.coupon_platforms.some(
            (platform) =>
              platform.name
                .toLowerCase()
                .indexOf(searchFieldValue.toLowerCase()) !== -1
          )) ||
        coupon.value_type
          .toLowerCase()
          .indexOf(searchFieldValue.toLowerCase()) !== -1
    );
  }
  if (filterStatus !== 'all' && filterStatus === 'active') {
    tableData = tableData.filter((coupon) => coupon.active);
  }
  if (filterStatus !== 'all' && filterStatus === 'inactive') {
    tableData = tableData.filter((coupon) => !coupon.active);
  }

  // ==========================
  //    CLIENT
  // ==========================
  if (filterUserId !== 0) {
    tableData = tableData.filter((coupon) => coupon.user_id === filterUserId);
  }

  // ==========================
  //    PLATFORM
  // ==========================
  if (filterPlatform !== 0) {
    tableData = tableData.filter((coupon) =>
      coupon.coupon_platforms.some((platform) => platform.id === filterPlatform)
    );
  }

  // ===================
  //        DATES
  // ===================

  if (filterStartDate) {
    tableData = tableData.filter((coupon) => {
      if (coupon.start_date) {
        return isAfter(new Date(filterStartDate), new Date(coupon.start_date));
      }
      return false;
    });
  }

  if (filterEndDate) {
    tableData = tableData.filter((coupon) => {
      if (coupon.end_date) {
        return isBefore(new Date(filterEndDate), new Date(coupon.end_date));
      }
      return false;
    });
  }

  return tableData;
}
