import { useState } from 'react';
import { Link } from 'react-router-dom';
//
import {
  Button,
  Stack,
  TextField,
  TextFieldProps,
  Typography,
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import { VBMCardWrapper, VBMCardTitle } from '..';
import DialogSimple from '@/components/dialog/DialogSimple';
import useToggle from '@/hooks/useToggle';

export default function VBMPauseCancel() {
  const [pauseFrom, setPauseFrom] = useState<Date | null>(null);
  const [pauseTo, setPauseTo] = useState<Date | null>(null);
  const [cancelDate, setCancelDate] = useState<Date | null>(null);

  const handlePauseFromChange = (newValue: Date | null) => {
    setPauseFrom(newValue);
  };
  const handlePauseToChange = (newValue: Date | null) => {
    setPauseTo(newValue);
  };
  const handleCancelDateChange = (newValue: Date | null) => {
    setCancelDate(newValue);
  };

  const {
    toggle: isPauseDialogOpen,
    onOpen: openPauseDialog,
    onClose: closePauseDialog,
  } = useToggle();

  const {
    toggle: isDeleteDialogOpen,
    onOpen: openDeleteDialog,
    onClose: closeDeleteDialog,
  } = useToggle();

  return (
    <>
      <Stack direction="row" spacing={2}>
        <VBMCardWrapper>
          <Stack spacing={2} sx={{ height: '100%' }}>
            <VBMCardTitle title="Pause booking" />
            <Typography variant="body2">
              Bookings can be paused for up to 3 months at no extra cost.
            </Typography>
            <Stack direction="row" spacing={2} sx={{ py: 1 }}>
              <DatePicker
                label="Start date"
                value={pauseFrom}
                disablePast
                onChange={handlePauseFromChange}
                renderInput={(params: TextFieldProps) => (
                  <TextField {...params} fullWidth size="small" />
                )}
              />

              <DatePicker
                label="End date"
                value={pauseTo}
                minDate={pauseFrom ?? undefined}
                onChange={handlePauseToChange}
                renderInput={(params: TextFieldProps) => (
                  <TextField {...params} fullWidth size="small" />
                )}
              />
            </Stack>
            <Button
              variant="contained"
              color="info"
              sx={{ width: 200, alignSelf: 'flex-end' }}
              disabled={!pauseFrom || !pauseTo}
              onClick={openPauseDialog}
            >
              Pause booking
            </Button>
          </Stack>
        </VBMCardWrapper>
        <VBMCardWrapper>
          <Stack spacing={2}>
            <VBMCardTitle title="Cancel booking" />
            <Typography variant="body2">
              Free cancellation up to 24 hours.{' '}
              <Link to="">See cancellation policy.</Link>
            </Typography>
            <Stack direction="row" spacing={2} sx={{ py: 1 }}>
              <DatePicker
                label="End date"
                disablePast
                value={cancelDate}
                onChange={handleCancelDateChange}
                renderInput={(params: TextFieldProps) => (
                  <TextField {...params} fullWidth size="small" />
                )}
              />
            </Stack>

            <Button
              variant="contained"
              color="error"
              sx={{ width: 200, alignSelf: 'flex-end' }}
              disabled={!cancelDate}
              onClick={openDeleteDialog}
            >
              Cancel booking
            </Button>
          </Stack>
        </VBMCardWrapper>
      </Stack>
      <DialogSimple
        dialogTitle="Pause booking"
        open={isPauseDialogOpen}
        loadingButton={false}
        agreeButton
        agreeButtonText="Pause"
        disagreeButtonText="Cancel"
        onClickButtonAgree={closePauseDialog}
        onClickButtonClose={closePauseDialog}
        disagreeButtonColor="inherit"
      >
        <Typography sx={{ px: 3, py: 1.5 }}>
          Are you sure you want to pause this booking from XXXX to XXXX?
        </Typography>
      </DialogSimple>
      <DialogSimple
        dialogTitle="Delete booking"
        open={isDeleteDialogOpen}
        loadingButton={false}
        agreeButton
        agreeButtonText="Delete"
        disagreeButtonText="Cancel"
        onClickButtonAgree={closeDeleteDialog}
        onClickButtonClose={closeDeleteDialog}
        agreeButtonColor="error"
        disagreeButtonColor="inherit"
      >
        <Typography sx={{ px: 3, py: 1.5 }}>
          Are you sure you want to delete this booking from XXXX ?
        </Typography>
      </DialogSimple>
    </>
  );
}
