import { useCallback, useState } from 'react';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useNavigate, useParams } from 'react-router-dom';
import { FieldErrors, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { capitalize, isEmpty } from 'lodash';
import parsePhoneNumber from 'libphonenumber-js';
//
import { Card, Grid, MenuItem, Stack, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import {
  FormProvider,
  RHFSwitch,
  RHFTextField,
  RHFUploadAvatar,
} from '@/components/hook-form';
import { SkeletonLoadingTable } from '@/components/skeleton';
import { UserPasswordReset } from '@/features/users/shared/components';
import { RHFMultiSelect } from '@/features/users/dashboard-users/components';
// API
import {
  getSingleUser,
  createNewUser,
  updateUser,
} from '@/features/users/shared/api';
import { getSettingsData, uploadImage } from '@/features/app/api';
import { getRoles } from '@/features/roles-and-permissions/api';
// utils + hooks
import { useSnackbarMsg } from '@/hooks/useSnackbarMsg';
import { fData } from '@/utils/formatNumber';
import {
  NEW_DASHBOARD_USER_SCHEMA,
  NEW_DASH_USER_DEFAULT_VALUES,
} from '../utils/user-details';
// types
import { FormValuesProps } from '../types';
import { UserDto } from '../../shared/types';
import { MAX_PROFILE_PIC_UPLOAD_SIZE } from '@/utils/maxFileSizes';

// ----------------------------------------------------------------------

export default function UserDetails() {
  const navigate = useNavigate();
  const { errorMsg, successMsg } = useSnackbarMsg();
  const { userId } = useParams();
  const queryClient = useQueryClient();
  const [profileImageUrl, setProfileImageUrl] = useState<string>('');

  // ========================
  //     REACT HOOK FROM
  // ========================

  const methods = useForm<FormValuesProps>({
    resolver: yupResolver(NEW_DASHBOARD_USER_SCHEMA),
    defaultValues: NEW_DASH_USER_DEFAULT_VALUES,
  });

  const {
    handleSubmit,
    reset,
    setValue,
    formState: { isSubmitting, errors },
  } = methods;

  // =================
  //      QUERIES
  // =================
  const { data: userStatuses } = useQuery({
    queryKey: ['settings'],
    queryFn: getSettingsData,
    onError: (error) => {
      errorMsg(error, `Something went wrong while fetching settings data `);
    },
    select: (data) =>
      data.userStatuses.filter((s) => s.display !== 'Removed') || [],
    refetchOnWindowFocus: false,
    // TODO: revisit these settings once MVP is ready to be launched
    // staleTime: 24 * 60 * 60 * 1000, // update every 24 hours
    // cacheTime: 24 * 60 * 60 * 1000, // update every 24 hours
  });

  const { data: roles } = useQuery({
    queryKey: ['roles'],
    queryFn: getRoles,
    onError: (error) =>
      errorMsg(error, 'There was an error while fetching roles'),
  });

  const { data: currentUser, isLoading: isUserDataLoading } = useQuery({
    queryKey: ['dashboardUsers', userId],
    queryFn: () => getSingleUser(Number(userId)),
    onSuccess: (data) => {
      reset({
        first_name: data.first_name ?? '',
        last_name: data.last_name ?? '',
        email: data.email ?? '',
        phone_number: data.phone ?? '',
        status: data.user_statuses ? data?.user_statuses.id : '',
        profile_image: data.profile_image ?? '',
        is_chat_user: data.is_chat_user ?? false,
        gender: data.gender ? capitalize(data.gender) : '',
        user_roles: data.user_roles ? data.user_roles.map((r) => r.id) : [],
        short_description: data.short_description ?? '',
        job_title: data.job_title ?? '',
      });
      setProfileImageUrl(data.profile_image ?? '');
    },
    onError: (error) => {
      errorMsg(error, `Something went wrong while fetching user`);
    },
    enabled: !!userId && !!userStatuses,
    refetchOnWindowFocus: false,
  });

  const createUserMutation = useMutation({
    mutationFn: (userDto: UserDto) => createNewUser(userDto),
    onSuccess: () => {
      queryClient.invalidateQueries(['dashboardUsers']);
      successMsg('User successfully created!');

      navigate('/users');
    },
    onError: (error) =>
      errorMsg(error, `Something went wrong while creating user`),
  });

  const updateUserMutation = useMutation({
    mutationFn: ({ userId, userDto }: { userId: number; userDto: UserDto }) =>
      updateUser(userId, userDto),
    onSuccess: () => {
      queryClient.invalidateQueries(['dashboardUsers', userId]);
      successMsg('User successfully updated!');
      navigate('/users');
    },
    onError: (error) =>
      errorMsg(error, `Something went wrong while updating user`),
  });

  const onSubmitUser = (data: FormValuesProps) => {
    const parsedPhoneNumber = parsePhoneNumber(data.phone_number, 'GB');

    const userDto = {
      first_name: data.first_name,
      last_name: data.last_name,
      email: data.email,
      phone: parsedPhoneNumber?.number,
      status: Number(data.status),
      profile_image: profileImageUrl,
      gender: !!data.gender.length ? data.gender.toLowerCase() : null,
      user_type: 3,
      user_roles: data.user_roles,
      is_chat_user: data.is_chat_user,
      job_title: data.job_title,
      short_description: data.short_description,
    };

    !isEmpty(currentUser)
      ? updateUserMutation.mutate({ userId: Number(userId), userDto })
      : createUserMutation.mutate(userDto);
  };

  // UPLOAD PHOTO
  const uploadMutation = useMutation({
    mutationFn: (formData: FormData) => uploadImage(formData),
    onSuccess: ({
      data: {
        data: { file: fileURL },
      },
    }) => setProfileImageUrl(fileURL),
    onError: (error) => {
      errorMsg(error, `Couldn't upload profile image`);
    },
  });

  const handleDrop = useCallback(
    (acceptedFiles: File[]) => {
      const file = acceptedFiles[0];

      if (file) {
        setValue(
          'profile_image',
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        );
        const formData = new FormData();
        formData.append('file', file);
        uploadMutation.mutate(formData);
      }
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [setValue]
  );

  // ========================
  //    HANDLE FORM ERRORS
  // ========================

  const handleFormError = (errors: FieldErrors<FormValuesProps>) => {
    const errorKeys = Object.keys(errors);
    !!errorKeys.length &&
      errorMsg(
        `\nPlease complete the required fields: ${errorKeys.join(', ')}`
      );
  };
  errors && handleFormError(errors);

  // ----------------------------------------------------------------------

  return (
    <>
      {userId && isUserDataLoading ? (
        <SkeletonLoadingTable />
      ) : (
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmitUser)}>
          <Grid container spacing={2}>
            {/* LEFT CARD */}
            <Grid
              item
              container
              spacing={2}
              xs={12}
              md={4}
              sx={{ height: 'fit-content' }}
            >
              <Grid item xs={12}>
                <Stack component={Card} sx={{ p: 3 }}>
                  <RHFUploadAvatar
                    name="profile_image"
                    maxSize={MAX_PROFILE_PIC_UPLOAD_SIZE}
                    onDrop={handleDrop}
                    isAvatarUploading={uploadMutation.isLoading}
                    removeAvatar={() => setProfileImageUrl('')}
                    helperText={
                      <Typography
                        variant="caption"
                        sx={{
                          mt: 2,
                          mx: 'auto',
                          display: 'block',
                          textAlign: 'center',
                          color: 'text.secondary',
                        }}
                      >
                        Allowed *.jpeg, *.jpg, *.png, *.gif
                        <br /> max size of {fData(MAX_PROFILE_PIC_UPLOAD_SIZE)}
                      </Typography>
                    }
                  />
                  <RHFSwitch
                    name="is_chat_user"
                    labelPlacement="start"
                    label={
                      <Typography variant="subtitle2">Chat user</Typography>
                    }
                    sx={{
                      mx: 0,
                      width: 1,
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      gap: 2,
                      mt: 2,
                    }}
                  />
                </Stack>
              </Grid>

              {userId && (
                <Grid item xs={12}>
                  <UserPasswordReset currentEmail={currentUser?.email} />
                </Grid>
              )}
            </Grid>

            {/* RIGHT CARD */}
            <Grid
              item
              container
              spacing={2}
              xs={12}
              md={8}
              sx={{ height: 'fit-content' }}
            >
              <Grid item xs={12}>
                <Stack spacing={2} component={Card} sx={{ p: 3 }}>
                  <Stack direction={{ xs: 'column', md: 'row' }} spacing={2}>
                    <RHFTextField name="first_name" label="First Name" />
                    <RHFTextField name="last_name" label="Last Name" />
                  </Stack>
                  <Stack direction={{ xs: 'column', md: 'row' }} spacing={2}>
                    <RHFTextField name="email" label="Email Address" />
                    <RHFTextField name="phone_number" label="Phone Number" />
                  </Stack>

                  <Stack direction={{ xs: 'column', md: 'row' }} spacing={2}>
                    <RHFTextField name="status" label="Status" select>
                      {(userStatuses || []).map((option) => (
                        <MenuItem key={option.id} value={option.id}>
                          {option.display}
                        </MenuItem>
                      ))}
                    </RHFTextField>

                    <RHFTextField name="gender" label="Gender" select>
                      {['Male', 'Female', 'Other'].map((option) => (
                        <MenuItem key={option} value={option}>
                          {option}
                        </MenuItem>
                      ))}
                    </RHFTextField>
                  </Stack>
                  <Stack direction={{ xs: 'column', md: 'row' }} spacing={2}>
                    <RHFMultiSelect
                      name="user_roles"
                      currentUserRoles={
                        currentUser?.user_roles.map((r) => r.name) || []
                      }
                      fullRolesData={roles || []}
                      label="Roles"
                      placeholder="Roles"
                      roleNames={roles?.map((r) => r.name) || []}
                    />
                    <RHFTextField name="job_title" label="Job Title" />
                  </Stack>
                  <RHFTextField
                    name="short_description"
                    label="Short description"
                  />
                </Stack>
              </Grid>

              <Grid item xs={12}>
                <Stack alignItems="flex-end" sx={{ mt: 3 }}>
                  <LoadingButton
                    type="submit"
                    variant="contained"
                    loading={isSubmitting}
                  >
                    {currentUser ? 'Save Changes' : 'Create User'}
                  </LoadingButton>
                </Stack>
              </Grid>
            </Grid>
          </Grid>
        </FormProvider>
      )}
    </>
  );
}
