//
import { Container, Typography } from '@mui/material';
import { Page } from '@/components';
import {
  ContentStyle,
  LoginFormBackButton,
  LoginFormLogo,
  NewPasswordForm,
} from '@/features/auth/components';
// utils
import { authPath } from '../routes/paths';

// ----------------------------------------------------------------------

export default function NewPassword() {
  return (
    <Page title="New Password">
      <LoginFormLogo imageSrc="/logo/logo_full.svg" />

      <Container>
        <ContentStyle>
          <LoginFormBackButton linkTo={authPath('verify')} />
          <Typography variant="h3" gutterBottom>
            Set New Password
          </Typography>
          <Typography sx={{ color: 'text.secondary' }}>
            The password should have at least 6 characters.
          </Typography>

          <NewPasswordForm />
        </ContentStyle>
      </Container>
    </Page>
  );
}
