import { useEffect, useRef, useState } from 'react';
import { debounce } from 'lodash';
import axios from '@/utils/axios';
//@mui
import {
  Paper,
  styled,
  useTheme,
  TextField,
  PaperProps,
  Autocomplete,
  Typography,
  Link,
  // Link,
} from '@mui/material';
import Iconify from '@/components/Iconify';

// types

type Props = {
  apiUrl: string;
  autoFocus?: boolean;
  keystrokesNumberForSearch?: number;
  noOptionsText: string;
  multiOptionFirstKeyValue?: string;
  multiOptionSecondKeyValue?: string;
  singleOptionKeyValue?: string;
  textFieldPlaceholder: string;
  disbaleLink?: boolean;
  //
  handleClose?: VoidFunction;
  callback?: (contact_id: number) => void;
};

const StyledPaper = styled(Paper)<PaperProps>(({ theme }) => ({
  borderRadius: 0,
  '&.MuiAutocomplete-paper': {
    borderRadius: '0px 0px 5px 5px !important',
    transform: 'translateY(-3px)',
  },
}));

const DEBOUNCE_TIME = 500;
export default function AutocompleteAsyncLookupForGlobalSearch({
  apiUrl,
  autoFocus = false,
  keystrokesNumberForSearch = 2,
  noOptionsText,
  multiOptionFirstKeyValue,
  multiOptionSecondKeyValue,
  singleOptionKeyValue,
  textFieldPlaceholder,
  disbaleLink = false,
  //
  handleClose,
  callback,
}: Props) {
  // local states
  const [autocompleteValue, setAutocompleteValue] = useState<any | null>(null);
  const [autocompleteOptions, setAutocompleteOptions] = useState<any[]>([]);
  const [autocompleteInputValue, setAutocompleteInputValue] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const theme = useTheme();

  const controller = useRef(new AbortController());

  useEffect(() => () => controller.current.abort(), []);

  // API call
  async function getDataForSearchBar(
    searchValue: string | number
  ): Promise<any[]> {
    const {
      data: { data },
    } = await axios.get(`${apiUrl}/${String(searchValue).toLowerCase()}`, {
      signal: controller.current.signal,
    });
    return data;
  }

  // lookup searchValue and set options with response data
  const debouncedFetch = useRef(
    debounce(async (searchValue: string | number) => {
      setIsLoading(true);
      controller.current.abort(); // Cancel the previous request
      controller.current = new AbortController(); // Create a new controller
      try {
        const resp = await getDataForSearchBar(searchValue);
        setAutocompleteOptions(resp);
      } catch (error) {
        console.error(error);
      }
      setIsLoading(false);
    }, DEBOUNCE_TIME)
  );

  // option is the object which from we wanna get a value to be displayed
  // ie. option[email] OR option[first_name] + option[last_name]
  const returnOptionValue = (option: any) => {
    if (singleOptionKeyValue) {
      return typeof option === 'string' ? option : option[singleOptionKeyValue];
    } else if (multiOptionFirstKeyValue && multiOptionSecondKeyValue) {
      return typeof option === 'string'
        ? option
        : `${option[multiOptionFirstKeyValue]} ${option[multiOptionSecondKeyValue]}`;
    }
  };

  // NAVIGATE TO NEW TAB ON ENTER
  const handlePressEnter = (event: any, contact_id: number) => {
    if (callback) {
      callback(contact_id);
    }

    if (!disbaleLink && event.code === 'Enter') {
      // navigate(contactPath('contact', 'contactId', contact_id));
    }
  };

  return (
    <Autocomplete
      loading={isLoading}
      loadingText="Loading..."
      PaperComponent={StyledPaper}
      open={autocompleteInputValue.length > keystrokesNumberForSearch}
      sx={{ width: '100%', p: '0px !important' }}
      autoComplete
      filterOptions={(x) => x}
      filterSelectedOptions
      disableCloseOnSelect={!disbaleLink}
      getOptionLabel={(option) => returnOptionValue(option)}
      includeInputInList
      noOptionsText={noOptionsText}
      options={autocompleteOptions}
      value={autocompleteValue}
      onChange={(event: any, newValue: any | null) => {
        setAutocompleteOptions(
          newValue ? [newValue, ...autocompleteOptions] : autocompleteOptions
        );
        setAutocompleteValue(newValue);
        newValue && handlePressEnter(event, newValue.id);
        handleClose && handleClose();
        disbaleLink && setAutocompleteInputValue('');
      }}
      onInputChange={(event, newInputValue) => {
        setAutocompleteInputValue(newInputValue);
        if (newInputValue.length > keystrokesNumberForSearch) {
          // Invoke the debounced function
          debouncedFetch.current(newInputValue);
        } else {
          debouncedFetch.current.cancel(); // Cancel the debounced function
          controller.current.abort(); // Cancel the previous request
          setAutocompleteOptions([]);
        }
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          id="autocomplete-search-bar"
          fullWidth
          sx={{
            '& .MuiFilledInput-root': {
              padding: 1,
            },
          }}
          autoFocus={autoFocus}
          placeholder={textFieldPlaceholder}
          InputProps={{
            ...params.InputProps,
            startAdornment: (
              <Iconify
                icon={'eva:search-fill'}
                sx={{
                  color: 'text.disabled',
                  width: 20,
                  height: 20,
                  p: '0px !important',
                }}
              />
            ),
            endAdornment: (
              <Typography
                variant="caption"
                sx={{
                  backgroundColor: 'grey.300',
                  color: 'grey.600',
                  px: 0.8,
                  py: 0.2,
                  borderRadius: 0.5,
                  fontWeight: 'bold',
                  display: disbaleLink ? 'none' : '',
                }}
              >
                {disbaleLink ? '' : 'Esc'}
              </Typography>
            ),
            style: {
              textAlign: 'center',
              backgroundColor: theme.palette.grey[0],
            },
            disableUnderline: true,
          }}
          variant="filled"
        />
      )}
      renderOption={(props, option) => (
        <li {...props} key={option.id}>
          {typeof option === 'string' ? (
            option
          ) : (
            <Link
              href={disbaleLink ? '#' : '#'}
              component={disbaleLink ? 'div' : 'a'}
              target="_blank"
              rel="noopener noreferrer"
              onKeyUp={(event: any) => handlePressEnter(event, option.id)}
              //
              display="flex"
              flexDirection="column"
            >
              <Typography color="primary">
                {option[multiOptionFirstKeyValue as string]}{' '}
                {option[multiOptionSecondKeyValue as string]}
              </Typography>
              <Typography color="grey.900">
                T: {option.mobile || 'N/A'} {'  '}
                E: {option.email || 'N/A'} {'  '}
                DOB: {option.dob || 'N/A'} {'  '}
                Postcode: {option.postcode || 'N/A'}
              </Typography>
            </Link>
          )}
        </li>
      )}
    />
  );
}
