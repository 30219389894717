import { useState } from 'react';
import {
  FormControl,
  FormControlLabel,
  FormLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import DialogSimple from '@/components/dialog/DialogSimple';
import { useSelector } from '@/stores';
import RTKNotes from './main-section/notes-partials/RTKNotes';
//
type ConfirmDialogProps = {
  isDialogOpen: boolean;
  handleCloseDialog: () => void;
  handleConfirmDialog: () => void;
  confirmRecurringChanges?: boolean;
};

export default function ConfirmRecurringChangesDialog({
  isDialogOpen,
  handleCloseDialog,
  handleConfirmDialog,
  confirmRecurringChanges,
}: ConfirmDialogProps) {
  const [radioButtonValue, setRadioButtonValue] = useState('');
  const [dropdownSelectValue, setDropdownSelectValue] = useState('');
  const { next_cleaning_sessions } = useSelector((state) => state.booking);

  return (
    <DialogSimple
      dialogTitle={`Confirm ${
        confirmRecurringChanges ? 'recurring event' : ''
      } changes`}
      open={isDialogOpen}
      loadingButtonText="Save changes"
      disableLoadingButton={
        confirmRecurringChanges ? radioButtonValue === '' : false
      }
      disagreeButtonText="Cancel"
      disagreeButtonColor="inherit"
      onClickButtonLoading={() => handleConfirmDialog()}
      onClickButtonClose={() => handleCloseDialog()}
    >
      <Stack direction="column" spacing={2} sx={{ px: 5, py: 1 }}>
        <Stack spacing={2}>
          <Typography>
            Please reason it in a few words why these changes were necessary
          </Typography>
          <RTKNotes noteType="reason_for_change" />
        </Stack>
        {confirmRecurringChanges && (
          <>
            <FormControl>
              <FormLabel
                id="request-type-radio-buttons-group-label"
                sx={{
                  color: (theme) => theme.palette.text.primary,
                  fontWeight: 600,
                  pb: 0.5,
                }}
              >
                Request Type
              </FormLabel>
              <RadioGroup
                row
                aria-labelledby="request-type-radio-buttons-group-label"
                name="row-radio-buttons-group"
              >
                <FormControlLabel
                  value="one"
                  control={
                    <Radio
                      onChange={(e) => setRadioButtonValue(e.target.value)}
                    />
                  }
                  label="One cleaning only"
                />
                <FormControlLabel
                  value="all"
                  control={
                    <Radio
                      onChange={(e) => setRadioButtonValue(e.target.value)}
                    />
                  }
                  label="All future cleaning"
                />
              </RadioGroup>
            </FormControl>

            {radioButtonValue === 'one' && (
              <Stack direction="column" spacing={2}>
                <TextField
                  select
                  label="Select booking"
                  size="small"
                  value={dropdownSelectValue}
                  onChange={(e) => setDropdownSelectValue(e.target.value)}
                >
                  {next_cleaning_sessions.map((date, index) => (
                    <MenuItem key={index} value={date}>
                      {date}
                    </MenuItem>
                  ))}
                </TextField>
                {!!dropdownSelectValue.length && (
                  <Typography>
                    The changes will apply to the following date only:{' '}
                    <span style={{ fontWeight: 600 }}>
                      {dropdownSelectValue}
                    </span>
                  </Typography>
                )}
              </Stack>
            )}
            {radioButtonValue === 'all' && (
              <Typography>
                The changes will apply to{' '}
                <span style={{ fontWeight: 600 }}>all future bookings</span>
              </Typography>
            )}
          </>
        )}
      </Stack>
    </DialogSimple>
  );
}
