import { isEmpty } from 'lodash';
//
import { Button, Card, Divider, Stack } from '@mui/material';
import { AddressInfo, SectionLabel } from '../..';
import { Iconify } from '@/components';
import { TableRowLink } from '@/components/table';
// redux
import { useSelector } from '@/stores';
// hooks + utils
import useResponsive from '@/hooks/useResponsive';
import useSettings from '@/hooks/useSettings';
import { getDefaultClientAddress, tableRowLinkSx } from '../../../utils';
import { providersPath } from '@/features/users/providers';
// types
import { UserAddress } from '@/features/users/shared/types';

export default function ViewBookingHeader() {
  const { themeStretch } = useSettings();
  const upMd = useResponsive('up', 'md');
  const booking = useSelector((state) => state.booking);

  const cleaner = !!booking.booking_cleaners.length
    ? booking.booking_cleaners[0]
    : null;
  const cleanerName = cleaner
    ? `${cleaner.first_name ?? 'N/A'} ${cleaner.last_name ?? 'N/A'}`
    : 'N/A';
  const serviceLabelText = `${
    booking.service_variant?.service.name ?? 'N/A'
  } - ${booking.service_variant?.name ?? 'N/A'}`;

  return (
    <Card sx={{ px: themeStretch ? 5 : 3, py: 3 }}>
      <Stack
        spacing={{ xs: 2, md: 5 }}
        direction={{ xs: 'column', md: 'row' }}
        divider={
          <Divider
            flexItem
            orientation={upMd ? 'vertical' : 'horizontal'}
            sx={{ borderStyle: 'dashed' }}
          />
        }
      >
        {/* CLIENT */}
        <Stack sx={{ width: 1 }}>
          <SectionLabel label="Client:" sx={{ pb: 1 }} />

          {!isEmpty(booking.client) && (
            <AddressInfo
              name={`${booking.client.first_name} ${booking.client.last_name}`}
              address={getDefaultClientAddress(booking)}
              phone={booking.client.phone}
              clientId={booking.client.id}
              hideGoogleMapsButton={!!!getDefaultClientAddress(booking)}
            />
          )}

          <Button
            variant="text"
            size="small"
            sx={{ width: 'fit-content' }}
            startIcon={<Iconify icon="eva:message-circle-outline" width={15} />}
          >
            Chat Client
          </Button>
        </Stack>

        {/* ADDRESS */}
        <Stack sx={{ width: 1 }}>
          <SectionLabel label="Address:" sx={{ pb: 1 }} />
          {!isEmpty(booking.address) && (
            <AddressInfo
              name={(booking.address as UserAddress).name}
              address={(booking.address as UserAddress).formatted_address}
              postcode={(booking.address as UserAddress).postcode}
              serviceLabelText={serviceLabelText}
            />
          )}
        </Stack>

        {/* CLEANER */}
        <Stack sx={{ width: 1 }}>
          <SectionLabel label="Cleaner:" sx={{ pb: 1 }} />
          <TableRowLink
            linkText={cleanerName}
            linkTo={providersPath('edit', {
              userId: String(cleaner?.id ?? 0),
              tabSid: 'details',
            })}
            noWrap
            sx={tableRowLinkSx}
          />

          <Button
            variant="text"
            size="small"
            sx={{ width: 'fit-content' }}
            startIcon={<Iconify icon="eva:message-circle-outline" width={15} />}
          >
            Chat Cleaner
          </Button>
        </Stack>
      </Stack>
    </Card>
  );
}
