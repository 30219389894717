import { Item } from '@/types';

function flattenArray<T>(list: T[], key = 'children'): T[] {
  let children: T[] = [];

  const flatten = list?.map((item: any) => {
    if (item[key] && item[key].length) {
      children = [...children, ...item[key]];
    }
    return item;
  });

  return flatten?.concat(
    children.length ? flattenArray(children, key) : children
  );
}

const reorder = <T>(list: T[], startIndex: number, endIndex: number): T[] => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const stringsForDraggable = (stringArray: string[]): Item[] =>
  stringArray.map((str, index) => ({ primary: str, id: String(index + 1) }));

const addOptionForDraggable = (value: string, appendingArray: Item[]): Item[] =>
  [...appendingArray].concat([
    {
      primary: value,
      id: String(
        appendingArray.length > 0
          ? Math.max(...appendingArray.map((o) => Number(o.id))) + 1
          : 1
      ),
    },
  ]);
function tableSortFilter(data: any[], comparator: any) {
  const stabilizedThis = data
    .map((el, index) => [el, index] as const)
    .sort((a: any, b: any) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });

  return stabilizedThis.map((el) => el[0]);
}

function combineWords(words: string[]): string {
  return words.reduce((accumulator, currentWord, index) => {
    if (index === 0) {
      return currentWord;
    } else if (index === words.length - 1) {
      return `${accumulator} and ${currentWord}`;
    } else {
      return `${accumulator}, ${currentWord}`;
    }
  }, '');
}
export {
  flattenArray,
  reorder,
  stringsForDraggable,
  addOptionForDraggable,
  tableSortFilter,
  combineWords,
};
