/* eslint-disable arrow-body-style */
import { ChannelList } from 'stream-chat-react';

import { ChannelSearch } from '../ChannelSearch/ChannelSearch';
import { SupportChannelList } from '../SupportChannelList/SupportChannelList';
import { JobChannelList } from '../JobSupportChannelList/JobSupportChannelList';
import { TeamChannelList } from '../TeamChannelList/TeamChannelList';
import { ChannelPreview } from '../ChannelPreview/ChannelPreview';

import type { Channel, ChannelFilters, ChannelSort } from 'stream-chat';

import { StreamChatType } from '../../types';

const filters: ChannelFilters[] = [{ type: 'team' }];
const cleanerSupportFilters: ChannelFilters = {
  type: { $in: ['cleanerSupport'] },
};
const clientSupportFilters: ChannelFilters = {
  type: { $in: ['clientSupport'] },
};
const jobSupportFilters: ChannelFilters = {
  type: { $in: ['jobSupport'] },
};
const options = { state: true, watch: true, presence: true, limit: 100 };
const sort: ChannelSort<StreamChatType> = {
  last_message_at: -1,
  updated_at: -1,
};

const customChannelTeamFilter = (channels: Channel[]) => {
  return channels.filter((channel) => channel.type === 'team');
};

const customChannelMessagingFilter = (channels: Channel[]) => {
  return channels.filter((channel) => channel.type === 'messaging');
};

const customChannelCleanerSupportFilter = (channels: Channel[]) => {
  return channels.filter((channel) => channel.type === 'cleanerSupport');
};

const customChannelClientSupportFilter = (channels: Channel[]) => {
  return channels.filter((channel) => channel.type === 'clientSupport');
};

const customChannelJobSupportFilter = (channels: Channel[]) => {
  return channels.filter((channel) => channel.type === 'jobSupport');
};

const TeamChannelsList = () => (
  <ChannelList
    channelRenderFilterFn={customChannelTeamFilter}
    filters={filters[0]}
    options={options}
    sort={sort}
    List={(listProps) => <TeamChannelList {...listProps} type="team" />}
    Preview={(previewProps) => <ChannelPreview {...previewProps} type="team" />}
  />
);

const MessagingChannelsList = () => (
  <ChannelList
    channelRenderFilterFn={customChannelMessagingFilter}
    filters={filters[1]}
    options={options}
    sort={sort}
    setActiveChannelOnMount={false}
    List={(listProps) => <TeamChannelList {...listProps} type="messaging" />}
    Preview={(previewProps) => (
      <ChannelPreview {...previewProps} type="messaging" />
    )}
  />
);

const CleanersChannelsList = () => (
  <ChannelList
    channelRenderFilterFn={customChannelCleanerSupportFilter}
    filters={cleanerSupportFilters}
    options={options}
    sort={sort}
    setActiveChannelOnMount={false}
    List={(listProps) => (
      <SupportChannelList {...listProps} type="cleanerSupport" />
    )}
    Preview={(previewProps) => (
      <ChannelPreview {...previewProps} type="cleanerSupport" />
    )}
    EmptyStateIndicator={() => (
      <div className="str-chat-channel-list_empty">
        No conversations here yet
      </div>
    )}
  />
);

const ClientsChannelsList = () => (
  <ChannelList
    channelRenderFilterFn={customChannelClientSupportFilter}
    filters={clientSupportFilters}
    options={options}
    sort={sort}
    setActiveChannelOnMount={false}
    List={(listProps) => (
      <SupportChannelList {...listProps} type="clientSupport" />
    )}
    Preview={(previewProps) => (
      <ChannelPreview {...previewProps} type="clientSupport" />
    )}
    EmptyStateIndicator={() => (
      <div className="str-chat-channel-list_empty">
        No conversations here yet
      </div>
    )}
  />
);

const JobsSupportChannelsList = () => (
  <ChannelList
    channelRenderFilterFn={customChannelJobSupportFilter}
    filters={jobSupportFilters}
    options={options}
    sort={sort}
    setActiveChannelOnMount={false}
    List={(listProps) => <JobChannelList {...listProps} type="jobSupport" />}
    Preview={(previewProps) => (
      <ChannelPreview {...previewProps} type="jobSupport" />
    )}
    EmptyStateIndicator={() => (
      <div className="str-chat-channel-list_empty">
        No conversations here yet
      </div>
    )}
  />
  // <ChannelList
  //   channelRenderFilterFn={customChannelCleanerSupportFilter}
  //   filters={jobSupportFilters}
  //   options={options}
  //   sort={sort}
  //   setActiveChannelOnMount={false}
  //   List={(listProps) => <SupportChannelList {...listProps} type="messaging" />}
  //   Preview={(previewProps) => (
  //     <ChannelPreview {...previewProps} type="jobSupport" />
  //   )}
  //   EmptyStateIndicator={() => (
  //     <div className="str-chat-channel-list_empty">
  //       No conversations here yet
  //     </div>
  //   )}
  // />
);

export const Sidebar = () => {
  return (
    <div className="sidebar">
      <div className="channel-list-bar">
        <ChannelSearch />
        <TeamChannelsList />
        <MessagingChannelsList />
        <CleanersChannelsList />
        <ClientsChannelsList />
        <JobsSupportChannelsList />
      </div>
    </div>
  );
};
