import axios from '@/utils/axios';
import { AxiosResponse } from 'axios';
import {
  Category,
  Product,
  ProductObjectToPost,
  Provider,
  CoverExtra,
} from '../types';
// ----------------------------------------------------------------------
// Products
export async function getProductData(): Promise<Product[]> {
  const {
    data: { data },
  } = await axios.get('/products');
  return data;
}
export async function getSingleProductData(
  productID: number
): Promise<Product> {
  const {
    data: { data },
  } = await axios.get(`/products/${productID}`);
  return data;
}
export const postProductData = async (productData: ProductObjectToPost) =>
  axios.post('/products', productData);

export const updateProductData = async (
  data: ProductObjectToPost,
  productID: number
) => axios.put(`/products/${productID}`, data);

export async function deleteProduct(productID: number): Promise<AxiosResponse> {
  const data = await axios.delete(`/products/${productID}`);

  return data;
}

// ----------------------------------------------------------------------
// Providers
export async function getProviderData(): Promise<Provider[]> {
  const {
    data: { data },
  } = await axios.get('/providers');
  return data;
}
// Categories
export async function getCategoryData(): Promise<Category[]> {
  const {
    data: { data },
  } = await axios.get('/products/categories');
  return data;
}
// Cover level extras
export async function getCoverExtrasData(): Promise<CoverExtra[]> {
  const {
    data: { data },
  } = await axios.get('/products/cover-extras');
  return data;
}
