import { useState } from 'react';
import { object, string } from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// mui
import { InputAdornment, MenuItem, TextField, Stack } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// component
import Iconify from '@/components/Iconify';
// hooks
import { FormProvider, RHFTextField } from '@/components/hook-form';
import { useSnackbarMsg } from '@/hooks/useSnackbarMsg';
// redux
import { dispatch, useSelector } from '@/stores';
import {
  addNewAddress,
  setValidationError,
} from '@/features/bookings-management/slices';
// API
import { findAddressPostcodeLookup } from '@/features/app/api';
import { createAddressForUser } from '@/features/users/shared/api';
// type
import { UKAddress } from '@/features/users/shared/types';

interface FormValuesProps {
  name?: string;
  line_1?: string;
  line_2?: string;
  line_3?: string;
  line_4?: string;
  town_or_city?: string;
  postcode?: string;
}

interface Props {
  onClose: VoidFunction;
}

// --------------------------------------------------

export default function AddNewAddress({ onClose }: Props) {
  // TODO: refactor and simplify this component
  const [addressesFromAPILookup, setAddressesFromAPILookup] = useState<
    UKAddress[]
  >([]);
  const [showAddressList, setShowAddressList] = useState<boolean>(false);
  const [postCodeFieldValue, setPostCodeFieldValue] = useState<string>('');
  const [selectedAddress, setSelectedAddress] = useState<UKAddress>();
  const [isPostcodeLookupFieldValid, setIsPostcodeLookupFieldValid] =
    useState<boolean>(true);

  const { errorMsg, successMsg } = useSnackbarMsg();

  const { client, validationErrors } = useSelector((state) => state.booking);

  // ---------------------------------------------
  //              HELPER FUNCTIONS
  // ---------------------------------------------

  // returns address object based on address.line_1
  // shown in select options
  function findAddressDetails(value: string) {
    const retrievedAddress = addressesFromAPILookup.find(
      (address) => address.line_1 === value
    );
    return retrievedAddress;
  }

  async function lookupPostcode(postcode: string) {
    if (postcode.length === 0) {
      setIsPostcodeLookupFieldValid(false);
    } else {
      setIsPostcodeLookupFieldValid(true);
      try {
        const response = await findAddressPostcodeLookup(postcode);
        setAddressesFromAPILookup(response.addresses);
        setPostCodeFieldValue(response.postcode);
        setShowAddressList(true);
      } catch (error) {
        errorMsg(error, `Something went wrong: ${error.message}`);
      }
    }
  }

  const handleChangeInInputFields = (inputField: string, value: string) => {
    if (selectedAddress) {
      const updatedAddress = {
        ...selectedAddress,
        [inputField]: value,
      };
      setSelectedAddress(updatedAddress);
    }
    setValue(inputField, value);
  };
  // ---------------------------------------------
  //                YUP, RHF, FORM
  // ---------------------------------------------

  const NewUserSchema = object().shape({
    line_1: string().required('Address line 1 is required.'),
    town_or_city: string().required('Town/city is required.'),
    postcode: string().required('Postcode is required.'),
  });

  const defaultValues = {
    name: '',
    line_1: '',
    line_2: '',
    line_3: '',
    line_4: '',
    town_or_city: '',
    postcode: '',
  };

  const methods2 = useForm<any>({
    resolver: yupResolver(NewUserSchema),
    defaultValues,
  });

  const { handleSubmit: handleSubmit2, setValue, clearErrors } = methods2;

  // ---------------------------------------------
  //            SUBMIT SELECTED ADDRESS
  // ---------------------------------------------

  const addSelectedAddress = async (data: FormValuesProps) => {
    if (!selectedAddress) {
      console.error('No address selected');
      return;
    }
    const reFormattedLine = selectedAddress.formatted_address
      .filter((n) => n)
      .join('</br>');
    const formattedAddress = {
      ...selectedAddress,
      postcode: postCodeFieldValue,
      name: data.name || 'N/A',
      formatted_address: reFormattedLine,
      user_id: client?.id,
    };

    try {
      if (!client?.id) throw new Error('Client ID not found');
      const userAddress = await createAddressForUser(formattedAddress);
      dispatch(addNewAddress(userAddress));
      validationErrors.address &&
        dispatch(setValidationError({ field: 'address', error: null }));
      successMsg('Address added successfully');
    } catch (error) {
      errorMsg(
        error,
        `Something went wrong while creating address: ${error.message}`
      );
    } finally {
      onClose();
    }
  };

  // ---------------------------------------------

  return (
    <FormProvider key={2} methods={methods2}>
      <Stack spacing={2} sx={{ mt: 1, p: 2 }}>
        <Stack direction="row" spacing={2}>
          <TextField
            size="small"
            error={!isPostcodeLookupFieldValid}
            helperText={
              !isPostcodeLookupFieldValid && 'This field needs to be filled out'
            }
            name="postcode_lookup"
            label="Postcode"
            fullWidth
            onKeyPress={(e: React.KeyboardEvent) => {
              e.key === 'Enter' && lookupPostcode(postCodeFieldValue);
            }}
            onChange={(e) =>
              setPostCodeFieldValue(e.target.value.replace(/\s/g, ''))
            }
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Iconify icon={'eva:map-fill'} width={20} height={20} />
                </InputAdornment>
              ),
            }}
          />
          <LoadingButton
            sx={{ width: '30%', height: '80%', alignSelf: 'auto' }}
            variant="contained"
            size="medium"
            onClick={() => lookupPostcode(postCodeFieldValue)}
          >
            Find Address
          </LoadingButton>
        </Stack>

        {showAddressList && (
          <TextField
            name="lookedup_addresses"
            label="Choose an address"
            value=""
            select
            size="small"
            onChange={(e) => {
              if (e.target.value) {
                const { value } = e.target;
                const address: any = findAddressDetails(value);
                setValue('line_1', address.line_1);
                setValue('line_2', address.line_2);
                setValue('line_3', address.line_3);
                setValue('line_4', address.line_4);
                setValue('town_or_city', address.town_or_city);
                setValue('postcode', postCodeFieldValue);
                clearErrors(['line_1', 'town_or_city', 'postcode']);
                setSelectedAddress(address);
                setShowAddressList(false);
              }
            }}
          >
            <option defaultValue="" />
            {addressesFromAPILookup &&
              addressesFromAPILookup.map((address, index) => (
                <MenuItem key={index} value={address.line_1}>
                  {address.line_1}
                </MenuItem>
              ))}
          </TextField>
        )}

        {selectedAddress && (
          <>
            <Stack direction="column" spacing={2}>
              <RHFTextField
                name="line_1"
                label="Address 1"
                onChange={(e) =>
                  handleChangeInInputFields(e.target.name, e.target.value)
                }
              />
              <RHFTextField
                name="line_2"
                label="Address 2"
                onChange={(e) =>
                  handleChangeInInputFields(e.target.name, e.target.value)
                }
              />

              <RHFTextField
                name="line_3"
                label="Address 3"
                onChange={(e) =>
                  handleChangeInInputFields(e.target.name, e.target.value)
                }
              />
              <RHFTextField
                name="line_4"
                label="Address 4"
                onChange={(e) =>
                  handleChangeInInputFields(e.target.name, e.target.value)
                }
              />

              <RHFTextField
                name="town_or_city"
                label="City"
                onChange={(e) =>
                  handleChangeInInputFields(e.target.name, e.target.value)
                }
              />
              <RHFTextField
                name="postcode"
                label="Postcode"
                onChange={(e) =>
                  handleChangeInInputFields(e.target.name, e.target.value)
                }
              />
              <RHFTextField
                name="name"
                label="Address Name"
                onChange={(e) =>
                  handleChangeInInputFields(e.target.name, e.target.value)
                }
              />
            </Stack>
            <LoadingButton
              fullWidth
              type="submit"
              variant="contained"
              size="medium"
              onClick={handleSubmit2(addSelectedAddress)}
            >
              Add Address
            </LoadingButton>
          </>
        )}
      </Stack>
    </FormProvider>
  );
}
