import { useState, useEffect, useMemo } from 'react';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';
import { isEmpty } from 'lodash';
import { object, string } from 'yup';
// api
import { postActivityData, updateActivityData } from '../api';
// form
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import {
  Stack,
  Grid,
  Box,
  Card,
  Container,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
  Skeleton,
} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
// hooks
import useSettings from '@/hooks/useSettings';
// components
import { FormProvider, RHFSelect, RHFTextField } from '@/components/hook-form';
import { Iconify } from '@/components';
// types
import type { ActivityType } from '../types';
// roots
import { PATH_SETTINGS, ROOTS_SETTINGS } from '../routes/paths';
import { ALL_ACTIVITY_TYPE_ICONS } from '@/config';

// ----------------------------------------------------------------------
const STATUS_OPTIONS = ['Active', 'Inactive'];

// PROP TYPES
interface FormValuesProps {
  name: string;
  icon: string;
  active: string;
}

type Props = {
  currentData?: ActivityType;
  ID?: number;
};

export default function ActivityTypesCreateEdit({ currentData }: Props) {
  const [icon, setIcon] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();

  const { enqueueSnackbar } = useSnackbar();

  // ----------------------------------------------------------------------
  // REACT HOOK FORM

  const Schema = object().shape({
    name: string().required('Name is required'),
    icon: string().required('Icon is required'),
    active: string().required('Status is required'),
  });

  const defaultValues = useMemo(
    () => ({
      name: currentData?.name || '',
      icon: currentData?.icon || '',
      active: currentData?.active ? 'Active' : isEmpty(currentData) ? 'Active' : 'Inactive',
    }),

    [currentData]
  );

  const methods = useForm<FormValuesProps>({
    resolver: yupResolver(Schema),
    defaultValues,
  });

  const {
    reset,
    handleSubmit,
    setValue,
    setError,
    clearErrors,
    formState: { isSubmitting, errors },
  } = methods;

  useEffect(() => {
    if (!isEmpty(currentData)) {
      setIcon(currentData.icon);
      reset(defaultValues);
    }
    setIsLoading(false);
  }, [currentData, reset, defaultValues]);

  const onSubmit = async (data: FormValuesProps) => {
    try {
      const onSubmitData = {
        name: data.name,
        icon: data.icon,
        active: data.active === 'Active' ? true : false,
      };

      !isEmpty(currentData)
        ? await updateActivityData(onSubmitData, currentData?.id)
        : await postActivityData(onSubmitData);
      enqueueSnackbar(!isEmpty(currentData) ? 'Activity successfully updated!' : 'Activity successfully created!');
      navigate(`${ROOTS_SETTINGS}${PATH_SETTINGS.leads}`);
    } catch (error) {
      console.error(error);
      if (data.icon) {
        setError('icon', { type: 'custom', message: 'Icon is required' });
      }

      enqueueSnackbar('Something went wrong', { variant: 'error' });
    }
  };

  // ----------------------------------------------------------------------

  const { themeStretch } = useSettings();

  function handleChange(event: React.MouseEvent<HTMLElement>, iconType: string) {
    const iconTypeString = iconType ? iconType : '';
    setIcon(iconTypeString);
    setValue('icon', iconTypeString);
    clearErrors('icon');
  }

  return (
    <Container maxWidth={themeStretch ? false : 'lg'} style={{ position: 'relative' }}>
      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <Grid item xs={12}>
          <Card sx={{ p: 3 }}>
            <Box
              sx={{
                display: 'grid',
                columnGap: 2,
                rowGap: 3,
                gridTemplateColumns: {
                  xs: 'repeat(1, 1fr)',
                  sm: 'repeat(1, 1fr)',
                },
              }}
            >
              {isLoading ? (
                <Box pl={2} pt={3}>
                  <Skeleton sx={{ width: 0.98 }} height={80} />
                  <Skeleton sx={{ width: 0.98 }} height={80} />
                </Box>
              ) : (
                <>
                  <RHFTextField name="name" label="Name" fullWidth />
                  <ToggleButtonGroup
                    color="primary"
                    size="small"
                    value={icon}
                    exclusive
                    onChange={handleChange}
                    aria-label="Platform"
                    sx={{
                      display: 'flex',
                      flexWrap: 'wrap',
                      height: '250px',
                      overflow: 'scroll',
                    }}
                  >
                    {ALL_ACTIVITY_TYPE_ICONS.map((item, idx) => (
                      <ToggleButton value={item.name} key={idx}>
                        <Iconify icon={item.name} width={25} height={25} />
                      </ToggleButton>
                    ))}
                  </ToggleButtonGroup>
                  <Typography color="error" fontSize={'small'} sx={{ verticalAlign: 'top', margin: '-14px 0 0 14px' }}>
                    {errors.icon?.message}
                  </Typography>
                  <RHFSelect name="active" label="Status" placeholder="Status">
                    {STATUS_OPTIONS.map((option) => (
                      <option key={option} value={option}>
                        {option}
                      </option>
                    ))}
                  </RHFSelect>
                </>
              )}
            </Box>

            <Stack alignItems="flex-end" sx={{ mt: 3 }}>
              {!isLoading && (
                <LoadingButton type="submit" variant="contained" loading={isSubmitting}>
                  {isEmpty(currentData) ? 'Create Activity' : 'Save Changes'}
                </LoadingButton>
              )}
            </Stack>
          </Card>
        </Grid>
      </FormProvider>
    </Container>
  );
}
