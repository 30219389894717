//
import { Container, Typography } from '@mui/material';
import { Page } from '@/components';
import {
  ContentStyle,
  LoginFormBackButton,
  LoginFormLogo,
  ResetPasswordForm,
} from '@/features/auth/components';
// utils
import { authPath } from '../routes/paths';

// ----------------------------------------------------------------------

export default function ResetPassword() {
  return (
    <Page title="Reset Password">
      <LoginFormLogo imageSrc="/logo/logo_full.svg" />
      <Container>
        <ContentStyle>
          <LoginFormBackButton linkTo={authPath('login')} />

          <Typography variant="h3" paragraph>
            Forgot your password?
          </Typography>

          <Typography sx={{ color: 'text.secondary', mb: 3 }}>
            Please enter the email address associated with your account, and
            we'll send you a security code to verify your identity.
          </Typography>

          <ResetPasswordForm />
        </ContentStyle>
      </Container>
    </Page>
  );
}
