import { object, string, number, mixed, array } from 'yup';

export const NewBookingSchema = object().shape({
  client: object().required('Client is required.'),
  address: object().required('Address is required.'),
  booking_cleaners: number()
    .typeError('Cleaner is required')
    .required('Cleaner is required.'),
  //
  payment_status: number()
    .transform((value, originalValue) =>
      originalValue === '' ? undefined : value
    )
    .required('Payment status is required.'),
  booking_status: number()
    .transform((value, originalValue) =>
      originalValue === '' ? undefined : value
    )
    .required('Booking status is required.'),
  //
  date: mixed().required('Booking date is required.'),
  time: string().required('Booking time is required.'),
  //
  service_variant_id: number()
    .typeError('Service is required.')
    .required('Service is required.'),
  extra_dates: array().of(
    object().shape({
      date: mixed().required('Date is required'),
      time: string().required('Time is required'),
    })
  ),
  //
  bedrooms: number().required('Bedrooms is required.'),
  bathrooms: number().required('Bathrooms is required.'),
  hours: number().required('Hours is required.'),
  //

  // cleaning_instructions: string(),
  // notes_for_cleaner: string(),
  // admin_notes: string(),
});
