import { useState } from 'react';
import { useParams } from 'react-router';
import { useQuery } from '@tanstack/react-query';
import {
  Box,
  Card,
  FormControlLabel,
  Switch,
  Table,
  TableBody,
  TableContainer,
  TablePagination,
} from '@mui/material';
//
import { SkeletonLoadingTable } from '@/components/skeleton';
import { Scrollbar } from '@/components';
import {
  TableHeadCustom,
  TableEmptyRows,
  TableNoData,
} from '@/components/table';
import { ClientWalletTableRow } from '..';
import { ToolbarWithSearchAndStatus } from '@/components/toolbar';
// API
import { getSingleUser } from '@/features/users/shared/api';
// hooks + utils
import useTable, { emptyRows, getComparator } from '@/hooks/useTable';
import { useSnackbarMsg } from '@/hooks/useSnackbarMsg';
import {
  CLIENT_WALLET_TABLE_HEAD,
  applySortFilterClientCards,
} from '../../utils';

export default function ClientWallet() {
  const { userId } = useParams();
  const { errorMsg } = useSnackbarMsg();

  const { data: currentProvider, isLoading: isCurrentProviderLoading } =
    useQuery({
      queryKey: ['clients', userId],
      queryFn: () => getSingleUser(Number(userId)),
      onError: (error) => {
        errorMsg(error, `Something went wrong while fetching current provider`);
      },
      enabled: !!userId,
    });

  const {
    dense,
    page,
    order,
    orderBy,
    rowsPerPage,
    setPage,
    //
    selected,
    //
    onSort,
    onChangeDense,
    onChangePage,
    onChangeRowsPerPage,
  } = useTable();

  const [searchFieldValue, setSearchFieldValue] = useState('');

  const handleFilterSearchValue = (filterName: string) => {
    setSearchFieldValue(filterName);
    setPage(0);
  };

  const dataFiltered = applySortFilterClientCards({
    tableData: currentProvider?.wallet || [],
    comparator: getComparator(order, orderBy),
    searchFieldValue,
  });

  const denseHeight = dense ? 52 : 72;
  const isNotFound =
    (!dataFiltered.length && !!searchFieldValue) || !dataFiltered.length;
  return (
    <Card>
      {isCurrentProviderLoading ? (
        <SkeletonLoadingTable />
      ) : (
        <>
          <ToolbarWithSearchAndStatus
            filterText={searchFieldValue}
            onFilterText={handleFilterSearchValue}
            labels={{ search: 'Search in wallet...' }}
          />

          <Scrollbar>
            <TableContainer sx={{ minWidth: 800, position: 'relative' }}>
              <Table size={dense ? 'small' : 'medium'}>
                <TableHeadCustom
                  order={order}
                  orderBy={orderBy}
                  headLabel={CLIENT_WALLET_TABLE_HEAD}
                  rowCount={dataFiltered.length}
                  numSelected={selected.length}
                  onSort={onSort}
                />
                <TableBody>
                  {dataFiltered
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((card) => (
                      <ClientWalletTableRow key={card.id} row={card} />
                    ))}

                  <TableEmptyRows
                    height={denseHeight}
                    emptyRows={emptyRows(
                      page,
                      rowsPerPage,
                      (currentProvider?.wallet || []).length
                    )}
                  />

                  <TableNoData isNotFound={isNotFound} title="No cards found" />
                </TableBody>
              </Table>
            </TableContainer>
          </Scrollbar>

          <Box sx={{ position: 'relative' }}>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={dataFiltered.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={onChangePage}
              onRowsPerPageChange={onChangeRowsPerPage}
            />

            <FormControlLabel
              control={<Switch checked={dense} onChange={onChangeDense} />}
              label="Dense"
              sx={{ px: 3, py: 1.5, top: 0, position: { md: 'absolute' } }}
            />
          </Box>
        </>
      )}
    </Card>
  );
}
