import { Stack, Skeleton } from '@mui/material';

export default function CleaningJobDetailsDialogSkeleton() {
  return (
    <Stack spacing={3} sx={{ p: 4 }}>
      {[...new Array(8)].map((_, index) => (
        <Skeleton key={index} variant="rounded" height={50} />
      ))}
    </Stack>
  );
}
