import React from 'react';
import { TableCell, Tooltip, IconButton } from '@mui/material';
import { Iconify } from '@/components';

const NotesCell = ({ notes }: { notes: string }) => (
  <>
    {notes ? (
      <Tooltip title={notes}>
        <IconButton>
          <Iconify icon={'eva:file-text-outline'} height={25} />
        </IconButton>
      </Tooltip>
    ) : (
      notes
    )}
  </>
);

export default NotesCell;
