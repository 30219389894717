import { AnimatePresence, m } from 'framer-motion';
import { useState, useEffect } from 'react';
// @mui
import { alpha, styled } from '@mui/material/styles';
import {
  Box,
  Stack,
  Divider,
  Backdrop,
  Typography,
  IconButton,
} from '@mui/material';
// hooks
import useSettings from '../../../hooks/useSettings';
// utils
import cssStyles from '../../../utils/cssStyles';
// config
import { NAVBAR, defaultSettings } from '../../../config';
//
import Iconify, { TooltipIcon } from '../../Iconify';
import Scrollbar from '../../Scrollbar';
import { varFade } from '../../animate';
//
import ToggleButton from './ToggleButton';
import SettingMode from './SettingMode';
import SettingLayout from './SettingLayout';
import SettingStretch from './SettingStretch';
import SettingContrast from './SettingContrast';
import SettingDirection from './SettingDirection';
import SettingFullscreen from './SettingFullscreen';
import SettingColorPresets from './SettingColorPresets';
import Block from '@/components/Block';
import BadgeDot from '@/components/BadgeDot';

// ----------------------------------------------------------------------

const RootStyle = styled(m.div)(({ theme }) => ({
  ...cssStyles(theme).bgBlur({
    color: theme.palette.background.paper,
    opacity: 0.92,
  }),
  top: 0,
  right: 0,
  bottom: 0,
  display: 'flex',
  position: 'fixed',
  overflow: 'hidden',
  width: NAVBAR.BASE_WIDTH,
  flexDirection: 'column',
  paddingBottom: theme.spacing(2),
  zIndex: theme.zIndex.drawer + 3,
  borderRadius: Number(theme.shape.borderRadius) * 1.5,
  boxShadow: `-24px 12px 32px -4px ${alpha(
    theme.palette.mode === 'light'
      ? theme.palette.grey[500]
      : theme.palette.common.black,
    0.16
  )}`,
}));

// ----------------------------------------------------------------------

export default function SettingsDrawer() {
  const {
    themeMode,
    themeLayout,
    themeStretch,
    themeContrast,
    themeDirection,
    themeColorPresets,
    onResetSetting,
  } = useSettings();

  const [open, setOpen] = useState(false);

  const notDefault =
    themeMode !== defaultSettings.themeMode ||
    themeLayout !== defaultSettings.themeLayout ||
    themeStretch !== defaultSettings.themeStretch ||
    themeContrast !== defaultSettings.themeContrast ||
    themeDirection !== defaultSettings.themeDirection ||
    themeColorPresets !== defaultSettings.themeColorPresets;

  const varSidebar =
    themeDirection !== 'rtl'
      ? varFade({
          distance: NAVBAR.BASE_WIDTH,
          durationIn: 0.32,
          durationOut: 0.32,
        }).inRight
      : varFade({
          distance: NAVBAR.BASE_WIDTH,
          durationIn: 0.32,
          durationOut: 0.32,
        }).inLeft;

  useEffect(() => {
    if (open) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = '';
    }
  }, [open]);

  const handleToggle = () => {
    setOpen((prev) => !prev);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Backdrop
        open={open}
        onClick={handleClose}
        sx={{
          background: 'transparent',
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
      />

      {!open && (
        <ToggleButton
          open={open}
          notDefault={notDefault}
          onToggle={handleToggle}
        />
      )}

      <AnimatePresence>
        {open && (
          <>
            <RootStyle {...varSidebar}>
              <Box sx={{ height: '85%' }}>
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between"
                  sx={{ py: 2, pr: 1, pl: 2.5 }}
                >
                  <Typography variant="subtitle2" sx={{ flexGrow: 1 }}>
                    Settings
                  </Typography>

                  <Box sx={{ position: 'relative' }}>
                    <IconButton onClick={onResetSetting}>
                      {notDefault && <BadgeDot />}
                      <TooltipIcon
                        title="Reset"
                        icon={'ic:round-refresh'}
                        width={20}
                        height={20}
                      />
                    </IconButton>
                  </Box>
                  <IconButton onClick={handleClose}>
                    <Iconify icon={'eva:close-fill'} width={20} height={20} />
                  </IconButton>
                </Stack>

                <Divider sx={{ borderStyle: 'dashed' }} />

                <Scrollbar sx={{ p: 2.5, pb: 0 }}>
                  <Block title="Mode">
                    <SettingMode />
                  </Block>

                  <Block title="Contrast">
                    <SettingContrast />
                  </Block>

                  <Block title="Direction">
                    <SettingDirection />
                  </Block>

                  <Block title="Layout">
                    <SettingLayout />
                  </Block>

                  <Block
                    title="Stretch"
                    tooltip="Only available on large resolutions > 1600px"
                  >
                    <SettingStretch />
                  </Block>

                  <Block title="Presets">
                    <SettingColorPresets />
                  </Block>
                </Scrollbar>
                <Stack
                  spacing={1}
                  position="fixed"
                  px={2}
                  pb={1}
                  bottom={8}
                  sx={{ width: '100%' }}
                >
                  <Stack>
                    <SettingFullscreen />
                  </Stack>
                </Stack>
              </Box>
            </RootStyle>
          </>
        )}
      </AnimatePresence>
    </>
  );
}
