import { tableSortFilter } from '@/utils/arrayProcessing';
import { CleaningExtra, Service } from '../types';

export function applySortFilterServices({
  tableData,
  comparator,
  filterName,
  filterStatus,
}: {
  tableData: Service[];
  comparator: (a: any, b: any) => number;
  filterName: string;
  filterStatus: string;
}) {
  tableData = tableSortFilter(tableData, comparator);

  if (filterName) {
    tableData = tableData.filter(
      (item: Record<string, any>) =>
        item.name.toLowerCase().indexOf(filterName.toLowerCase()) !== -1
    );
  }

  if (filterStatus.toLowerCase() !== 'all') {
    tableData = tableData.filter((item) => {
      const currentStatus = item.active ? 'active' : 'inactive';
      return currentStatus === filterStatus.toLowerCase();
    });
  }

  return tableData;
}

export const SERVICES_TABLE_HEAD = [
  { id: 'emptyCell' },
  { id: 'name', label: 'Name', align: 'left' },
  { id: 'variants', label: 'Variants', align: 'left' },
  { id: 'cleaning_extras', label: 'Extras', align: 'left' },
  { id: 'active', label: 'Status', align: 'left' },
  { id: 'actionBtns' },
];

/**
 * Takes the dynamicly generated defaulFormValues object and processes that into an array of selected service extras for the API
 * @param obj - ServiceObject
 * @returns Partial<CleaningExtra>[] = { id: string, custom_hours: number }
 */
export const processServiceFormObject = (obj: Record<string, any>) => {
  // service_extra_checkbox_ID and service_extra_textfield_ID are genereted dynamically, where ID = extra.id, so they make pairs based on the extra.id
  const finalArray: Partial<CleaningExtra>[] = [];
  Object.keys(obj).forEach((key) => {
    if (key.startsWith('service_extra_checkbox_') && obj[key] === true) {
      // grabs the ID from the key
      const id = parseInt(key.split('_').pop()!);
      //
      const textfieldKey = `service_extra_textfield_${id}`;
      if (textfieldKey in obj) {
        finalArray.push({
          id: id,
          custom_hours: Number(obj[textfieldKey]),
        });
      }
    }
  });

  return finalArray;
};

//
