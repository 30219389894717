import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
//
import { Scrollbar } from '@/components';
import {
  Box,
  Card,
  FormControlLabel,
  Switch,
  Table,
  TableBody,
  TableContainer,
  TablePagination,
} from '@mui/material';
import { SkeletonLoadingTable } from '@/components/skeleton';
import { ToolbarWithSearchAndStatus } from '@/components/toolbar';
import {
  TableEmptyRows,
  TableHeadCustom,
  TableNoData,
} from '@/components/table';
import { ProviderAvailabilityTableRow } from '..';
import { SectionTitle } from '@/features/users/shared/components';
// api
import { getProviderAllDaysOff } from '../../api';
import { getSettingsData } from '@/features/app/api';
// hookes + utils
import { useGetAccess } from '@/hooks/useAccess';
import { useSnackbarMsg } from '@/hooks/useSnackbarMsg';
import useTable, { emptyRows, getComparator } from '@/hooks/useTable';
import {
  PROVIDER_AVAILABILTY_TABLE_HEAD,
  applySortFilterProviderAvailability,
} from '../../utils';
// type
type TableProps = {
  handleEdit: (id: string) => void;
};

export default function ProviderAvailabilityTable({ handleEdit }: TableProps) {
  const {
    dense,
    page,
    order,
    orderBy,
    rowsPerPage,
    //
    selected,
    //
    onSort,
    onChangeDense,
    onChangePage,
    onChangeRowsPerPage,
  } = useTable({ defaultOrderBy: 'id', defaultOrder: 'desc' });

  const { userId } = useParams();
  const allow = useGetAccess('provider');
  const { errorMsg } = useSnackbarMsg();

  const [searchFieldValue, setSearchFieldValue] = useState('');
  const [availabilityTypeFilterValue, setAvailabilityTypeFilterValue] =
    useState<string>('all');
  const handleFilterStatus = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAvailabilityTypeFilterValue(event.target.value);
  };
  // =================
  //      QUERIES
  // =================
  const { data: providerDaysOff, isLoading: isDaysOffDataLoading } = useQuery({
    queryKey: ['providerTimeoff', userId],
    queryFn: () => getProviderAllDaysOff(String(userId)),
    onError: (error) => {
      errorMsg(error, `Something went wrong while fetching days off`);
    },
    enabled: !!userId,
  });

  // SETTINGS
  const { data: timeOffRequestTypes } = useQuery({
    queryKey: ['settings'],
    queryFn: () => getSettingsData(),
    onError: (error) => {
      errorMsg(
        error,
        `Something went wrong while fetching time-off request types`
      );
    },
    select: (data) => data?.offDaysRequestTypes,
    // TODO: ADD STALE TIME
  });

  const dataFiltered = applySortFilterProviderAvailability({
    tableData: providerDaysOff || [],
    availabilityTypeFilterValue,
    comparator: getComparator(order, orderBy),
    searchFieldValue,
    timeOffRequestTypes: timeOffRequestTypes || [],
  });

  const denseHeight = dense ? 52 : 72;

  const isNotFound =
    !dataFiltered.length ||
    (!dataFiltered.length && !!searchFieldValue) ||
    (!dataFiltered.length && !!availabilityTypeFilterValue);

  return (
    <Card sx={{ width: '100%', p: 3 }}>
      {isDaysOffDataLoading ? (
        <SkeletonLoadingTable />
      ) : (
        <>
          <SectionTitle title="Provider Availability" />
          <ToolbarWithSearchAndStatus
            filterText={searchFieldValue}
            onFilterText={setSearchFieldValue}
            filterStatus={availabilityTypeFilterValue}
            onFilterStatus={handleFilterStatus}
            status={[
              'all',
              ...(timeOffRequestTypes || []).map((t) => t.description),
            ]}
            labels={{ search: 'Search in time-off notes...', status: 'Type' }}
          />

          <Scrollbar>
            <TableContainer sx={{ minWidth: 800, position: 'relative' }}>
              <Table size="small">
                <TableHeadCustom
                  order={order}
                  orderBy={orderBy}
                  headLabel={PROVIDER_AVAILABILTY_TABLE_HEAD}
                  rowCount={dataFiltered.length}
                  numSelected={selected.length}
                  onSort={onSort}
                />
                <TableBody>
                  {dataFiltered
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((availability) => (
                      <ProviderAvailabilityTableRow
                        key={availability.id}
                        row={availability}
                        canDelete={allow.canDelete}
                        canUpdate={allow.canUpdate}
                        handleEdit={handleEdit}
                        timeOffRequestTypes={timeOffRequestTypes || []}
                      />
                    ))}

                  <TableEmptyRows
                    height={denseHeight}
                    emptyRows={emptyRows(
                      page,
                      rowsPerPage,
                      dataFiltered.length
                    )}
                  />

                  <TableNoData isNotFound={isNotFound} />
                </TableBody>
              </Table>
            </TableContainer>
          </Scrollbar>

          <Box sx={{ position: 'relative' }}>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={dataFiltered.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={onChangePage}
              onRowsPerPageChange={onChangeRowsPerPage}
            />

            <FormControlLabel
              control={<Switch checked={dense} onChange={onChangeDense} />}
              label="Dense"
              sx={{ px: 3, py: 1.5, top: 0, position: { md: 'absolute' } }}
            />
          </Box>
        </>
      )}
    </Card>
  );
}
