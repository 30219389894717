import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import { format } from 'date-fns';
//
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Stack,
  Typography,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import DialogSimple from '@/components/dialog/DialogSimple';
import { LoadingButton } from '@mui/lab';
import { Datepicker } from '@mobiscroll/react';
//
import { cloneBookingSession } from '@/features/bookings-management/api';
import { useSnackbarMsg } from '@/hooks/useSnackbarMsg';
import {
  getInputValue,
  timePickerSx,
} from '@/features/bookings-management/utils';
import { bookingsManagementPath } from '@/features/bookings-management/routes/paths';
//
import { BookingSession } from '@/features/bookings-management/types';

type CloneSessionProps = {
  isDialogOpen: boolean;
  closeDialog: VoidFunction;
  currentBookingSession: BookingSession;
};
export default function VBMCloneSessionDialog({
  isDialogOpen,
  closeDialog,
  currentBookingSession,
}: CloneSessionProps) {
  const navigate = useNavigate();
  const { successMsg, errorMsg } = useSnackbarMsg();
  const queryClient = useQueryClient();

  // min date for datepicker
  const today = new Date();
  const tomorrow = new Date(today);
  tomorrow.setDate(today.getDate() + 1);

  const sessionUpdateMutation = useMutation({
    mutationFn: ({ date, time }: { date: string; time: string }) =>
      cloneBookingSession(currentBookingSession.id, { time, date }),
    onSuccess: (clonedSession) => {
      successMsg(
        `Booking session #${currentBookingSession.id} successfully cloned.\nNew booking session #${clonedSession.id} created.`
      );
      closeDialog();
      queryClient.invalidateQueries(['bookingSessions', 'upcoming']); // the cloned session has to be in the future (it cannot be in the past or today)
      navigate(
        bookingsManagementPath('view', {
          bookingId: String(currentBookingSession.booking_id),
          bookingTabSid: 'booking-sessions',
          sessionFilter: `upcoming`,
        }).concat(`?sessionID=${clonedSession.id}`)
      );
    },
    onError: (error) => {
      errorMsg(error, `Something went wrong while cloning session`);
    },
  });

  const handleSubmitCloneSession = (
    event: React.FormEvent<HTMLFormElement>
  ) => {
    event.preventDefault();
    const date = getInputValue(event, 'VBMDateTime-mbscDatePicker');
    const time = getInputValue(event, 'VBMDateTime-mbscTimePicker');
    sessionUpdateMutation.mutate({
      date: format(new Date(date), 'yyyy-MM-dd'),
      time,
    });
  };

  return (
    <DialogSimple
      dialogTitle={`Clone session #${currentBookingSession.id}`}
      open={isDialogOpen}
      loadingButton={false}
      disagreeButton={false}
      onClickButtonClose={closeDialog}
    >
      <form onSubmit={handleSubmitCloneSession}>
        <Stack spacing={3} sx={{ px: 5, py: 2 }}>
          <Typography>
            You are about to clone the selected booking session. Select time and
            date, and once the new session is created, you can edit its details.
          </Typography>
          <Stack direction="row" spacing={2}>
            <Box sx={timePickerSx}>
              <Datepicker
                name="VBMDateTime-mbscDatePicker"
                label="Date"
                theme="ios"
                themeVariant="light"
                controls={['calendar']}
                touchUi={true}
                display="anchored"
                animation="pop"
                min={tomorrow}
                buttons={[]}
              />
            </Box>
            <Box sx={timePickerSx}>
              <Datepicker
                name="VBMDateTime-mbscTimePicker"
                label="Time"
                theme="ios"
                themeVariant="light"
                controls={['timegrid']}
                display="anchored"
                touchUi={true}
                minTime="06:00"
                maxTime="23:00"
                stepMinute={30}
                animation="pop"
                timeFormat="HH:mm"
              />
            </Box>
          </Stack>
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography fontWeight={600}>
                Booking Session #{currentBookingSession.id} details
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Stack spacing={0.5} sx={{ pl: 1 }}>
                <Typography fontWeight={600}>
                  Total hours: {currentBookingSession.total_hours}
                </Typography>
                <Typography fontWeight={600}>
                  Number of bedrooms: {currentBookingSession.bedrooms}
                </Typography>
                <Typography fontWeight={600}>
                  Number of bathrooms: {currentBookingSession.bathrooms}
                </Typography>
                <Stack>
                  <Typography fontWeight={600}>Extras:</Typography>
                  {currentBookingSession.booking_items.map((item) => (
                    <Typography sx={{ pl: 2 }} key={item.id}>
                      - {item.name}
                    </Typography>
                  ))}
                  {!!currentBookingSession.pets &&
                    currentBookingSession.pets.length > 0 && (
                      <Typography sx={{ pl: 2 }}>
                        - {currentBookingSession.pets.join(' + ')}
                      </Typography>
                    )}
                  {!!currentBookingSession.cleaning_products && (
                    <Typography sx={{ pl: 2 }}>- Cleaning products</Typography>
                  )}
                </Stack>
              </Stack>
            </AccordionDetails>
          </Accordion>
          <Stack
            direction="row"
            justifyContent="flex-end"
            spacing={1}
            sx={{ mt: 2 }}
          >
            <Button
              variant="outlined"
              size="small"
              color="inherit"
              onClick={closeDialog}
            >
              Cancel
            </Button>
            <LoadingButton
              variant="contained"
              size="small"
              type="submit"
              loading={sessionUpdateMutation.isLoading}
              disabled={sessionUpdateMutation.isLoading}
            >
              Clone Session
            </LoadingButton>
          </Stack>
        </Stack>
      </form>
    </DialogSimple>
  );
}
