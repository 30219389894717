import { useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
//
import {
  MenuItem,
  Skeleton,
  Stack,
  TextField,
  Typography,
  alpha,
} from '@mui/material';
import {
  VBMActionButtons,
  VBMCardTitle,
  VBMCardWrapper,
  VBMEditButton,
} from '..';
import { Label } from '@/components';
// API
import { getSettingsData } from '@/features/app/api';
// hooks + utils
import useResponsive from '@/hooks/useResponsive';
import { useSnackbarMsg } from '@/hooks/useSnackbarMsg';
import usePostBookingSessionUpdate from '@/features/bookings-management/hooks/usePostBookingSessionUpdate';
import { getNamedInputValueFromForm } from '@/features/bookings-management/utils';
// types
import { LabelColors } from '@/types';

type VBMStatusProps = {
  selectedBookingStatusID: number;
  selectedBookingPaymentStatusID: number;
  editable?: boolean;
};

export default function VBMStatus({
  selectedBookingStatusID,
  selectedBookingPaymentStatusID,
  editable = false,
}: VBMStatusProps) {
  const betweenMdAndXl = useResponsive('between', undefined, 'md', 'xl');
  const downMd = useResponsive('down', 'md');
  const [isEdit, setIsEdit] = useState(false);
  const [searchParams] = useSearchParams();
  const sessionID = searchParams.get('sessionID');
  const { errorMsg } = useSnackbarMsg();

  const { sessionUpdateMutation } = usePostBookingSessionUpdate({
    onSuccessFn: () => setIsEdit(false),
    successText: `Session status updated`,
  });

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const bookingStatus = getNamedInputValueFromForm(
      event,
      'booking_session_status'
    );
    const paymentStatus = getNamedInputValueFromForm(
      event,
      'booking_session_payment_status'
    );

    sessionUpdateMutation.mutate({
      bookingSessionId: Number(sessionID),
      bookingSessionDto: {
        status_id: Number(bookingStatus),
        payment_status_id: Number(paymentStatus),
      },
    });
  };

  // STATUSES
  const { data: settingsData, isLoading: isStatusDataLoading } = useQuery({
    // TODO: probably this data should be fetched on app load
    queryKey: ['settings'],
    queryFn: getSettingsData,
    onError: (error) => {
      errorMsg(error, `Something went wrong while fetching statuses`);
    },
    refetchOnWindowFocus: false,
  });

  const currentBookingStatus = (
    settingsData?.bookingSessionStatuses || []
  ).find((s) => s.id === selectedBookingStatusID);
  const currentPaymentStatus = (
    settingsData?.bookingPaymentStatuses || []
  ).find((s) => s.id === selectedBookingPaymentStatusID);

  return (
    <VBMCardWrapper>
      {!isEdit && editable && (
        <VBMEditButton onClickButton={() => setIsEdit(true)} />
      )}

      <VBMCardTitle title="Statuses" sx={{ mb: 1 }} />

      {isEdit ? (
        <form onSubmit={handleSubmit}>
          {!isStatusDataLoading ? (
            <Stack spacing={2}>
              {/* BOOKING STATUS */}
              <Stack spacing={1}>
                <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                  Booking status
                </Typography>
                <TextField
                  defaultValue={selectedBookingStatusID}
                  name="booking_session_status"
                  fullWidth
                  select
                  size="small"
                  sx={{ background: (theme) => theme.palette.background.paper }}
                >
                  {(settingsData?.bookingSessionStatuses || []).map(
                    (status) => (
                      <MenuItem key={status.id} value={status.id}>
                        {status.display}
                      </MenuItem>
                    )
                  )}
                </TextField>
              </Stack>

              {/* PAYMENT STATUS */}
              <Stack spacing={1}>
                <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                  Payment status
                </Typography>
                <TextField
                  defaultValue={selectedBookingPaymentStatusID}
                  name="booking_session_payment_status"
                  fullWidth
                  select
                  size="small"
                  sx={{
                    background: (theme) => theme.palette.background.paper,
                  }}
                >
                  {(settingsData?.bookingPaymentStatuses || []).map(
                    (status) => (
                      <MenuItem key={status.id} value={status.id}>
                        {status.display}
                      </MenuItem>
                    )
                  )}
                </TextField>
              </Stack>

              <VBMActionButtons
                onClickCancel={() => setIsEdit(false)}
                isSubmitting={sessionUpdateMutation.isLoading}
                disabled={isStatusDataLoading}
              />
            </Stack>
          ) : (
            <Skeleton
              height={50}
              sx={{
                bgcolor: (theme) => alpha(theme.palette.primary.main, 0.1),
              }}
            />
          )}
        </form>
      ) : (
        <>
          {sessionUpdateMutation.isLoading ? (
            <Skeleton height={50} />
          ) : (
            <Stack
              direction={betweenMdAndXl || downMd ? 'column' : 'row'}
              spacing={1.5}
            >
              <Label
                color={
                  (currentPaymentStatus?.style_class as LabelColors) ?? 'info'
                }
              >
                {currentPaymentStatus?.display ?? 'N/A - ??'}
              </Label>
              <Label
                color={
                  (currentBookingStatus?.style_class as LabelColors) ?? 'info'
                }
              >
                {currentBookingStatus?.display ?? 'N/A - ??'}
              </Label>
            </Stack>
          )}
        </>
      )}
    </VBMCardWrapper>
  );
}
