import { styled } from '@mui/material/styles';
import { grey } from '@mui/material/colors';
import { Button, ButtonProps } from '@mui/material';

export const AddItemButton = styled(Button)<ButtonProps>(() => ({
  width: 'max-content',
  color: 'black',
  fontSize: 'smaller',
  fontWeight: '600',
  padding: '0.5em 2em',
  boxShadow: 'none',
  backgroundColor: grey[200],
  '&:hover': {
    backgroundColor: grey[500],
  },
}));
