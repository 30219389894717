export const enableBrowserNotifications = () => {
  if (!('Notification' in window)) {
    alert('Browser does not support notifications');
    return;
  }

  Notification.requestPermission().then((permission) => {
    if (permission === 'granted') {
      new Notification('You have enabled the browser notifications');
    }
  });
};
