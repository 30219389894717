import { tableSortFilter } from '@/utils/arrayProcessing';
import { TransformedBooking } from '../types';

export function applyFilterJobs({
  tableData,
  comparator,
  filterServiceTypeId,
}: {
  tableData: TransformedBooking[];
  comparator: (a: any, b: any) => number;
  filterServiceTypeId: number;
}) {
  tableData = tableSortFilter(tableData, comparator);

  if (filterServiceTypeId !== 0) {
    tableData = tableData.filter(
      (bookingSession) => bookingSession.serviceTypeId === filterServiceTypeId
    );
  }

  return tableData;
}
