// routes
import { Suspense } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import { Provider as ReduxProvider } from 'react-redux';
import { PersistGate } from 'redux-persist/lib/integration/react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import locale from 'date-fns/locale/en-GB';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
// theme
import ThemeProvider from '@/theme';

import { store, persistor } from '@/stores';
import { SettingsProvider } from '@/contexts/SettingsContext';
import { CollapseDrawerProvider } from '@/contexts/CollapseDrawerContext';
import { AuthProvider } from '@/contexts/JWTContext';
import ThemeSettings from '@/components/settings';
import { ProgressBarStyle } from '@/components/ProgressBar';
import MotionLazyContainer from '@/components/animate/MotionLazyContainer';
import NotistackProvider from '@/components/NotistackProvider';
import LoadingScreen from '@/components/LoadingScreen';

type AppProviderProps = {
  children: React.ReactNode;
};

const queryClient = new QueryClient();

const AppProvider = ({ children }: AppProviderProps) => (
  <AuthProvider>
    <HelmetProvider>
      <ReduxProvider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <LocalizationProvider
            dateAdapter={AdapterDateFns}
            adapterLocale={locale}
          >
            <SettingsProvider>
              <CollapseDrawerProvider>
                <MotionLazyContainer>
                  <ThemeProvider>
                    <ThemeSettings>
                      <NotistackProvider>
                        <ProgressBarStyle />
                        <Suspense fallback={<LoadingScreen />}>
                          <QueryClientProvider client={queryClient}>
                            <Router>{children}</Router>
                          </QueryClientProvider>
                        </Suspense>
                      </NotistackProvider>
                    </ThemeSettings>
                  </ThemeProvider>
                </MotionLazyContainer>
              </CollapseDrawerProvider>
            </SettingsProvider>
          </LocalizationProvider>
        </PersistGate>
      </ReduxProvider>
    </HelmetProvider>
  </AuthProvider>
);

export default AppProvider;
