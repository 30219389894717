import { useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { useSnackbarMsg } from '@/hooks/useSnackbarMsg';
//
import { ListItemButton, Stack, Typography } from '@mui/material';
// components
import Scrollbar from '@/components/Scrollbar';
import { ToolbarWithSearchAndStatus } from '@/components/toolbar';
import EmptyContent from '@/components/EmptyContent';
import SearchClientSkeleton from '../../../skeletons/SearchClientSkeleton';
// API
import { getClients } from '@/features/users/shared/api';
// Redux
import { useSelector, dispatch } from '@/stores';
import {
  setClient,
  setValidationError,
} from '@/features/bookings-management/slices';
// utils
import { applyFilterSearchbar } from '@/features/bookings-management/utils';
import useTable, { getComparator } from '@/hooks/useTable';
// types
import { User } from '@/features/users/shared/types';

type SearchClientProps = {
  onClose: VoidFunction;
};

export default function SearchClient({ onClose }: SearchClientProps) {
  const { errorMsg } = useSnackbarMsg();

  const booking = useSelector((state) => state.booking);

  const { data: clients, isLoading } = useQuery({
    queryKey: ['clients'],
    queryFn: () => getClients(),
    onError: (error) => {
      errorMsg(error, `Something went wrong while fetching clients data`);
    },
  });

  const handleChange = (clientObject: User) => {
    dispatch(setClient(clientObject));
    booking.validationErrors.client &&
      dispatch(setValidationError({ field: 'client', error: null }));
    onClose();
  };

  // ---------------------------------------------
  //              SEARCH BAR FILTERING
  // ---------------------------------------------

  const [searchFieldValue, setSearchFieldValue] = useState<string>('');
  const handleSearchFieldValue = (searchFieldValue: string) => {
    setSearchFieldValue(searchFieldValue);
  };

  const { order, orderBy } = useTable();

  const dataFiltered = applyFilterSearchbar({
    tableData: clients || [],
    comparator: getComparator(order, orderBy),
    searchFieldValue,
  });

  const isNotFound = !dataFiltered.length && !!searchFieldValue;

  // ---------------------------------------------

  return (
    <Stack direction="column" sx={{ pb: 2 }}>
      {isLoading ? (
        <SearchClientSkeleton />
      ) : (
        <>
          <ToolbarWithSearchAndStatus
            filterText={searchFieldValue}
            onFilterText={handleSearchFieldValue}
            autoFocusSearchfield
          />
          <Scrollbar sx={{ p: 1.5, pt: 0, maxHeight: 80 * 8 }}>
            {(dataFiltered as User[]).map((client) => (
              <ListItemButton
                key={client.id}
                selected={client.id === (booking.client?.id ?? 0)}
                onClick={() => handleChange(client)}
                sx={{
                  p: 1.5,
                  borderRadius: 1,
                  flexDirection: 'column',
                  alignItems: 'flex-start',
                }}
              >
                <Typography variant="subtitle2">
                  {client.first_name !== null
                    ? `${client.first_name} ${client.last_name}`
                    : 'no name'}
                </Typography>

                <Typography
                  variant="caption"
                  sx={{
                    color: 'primary.main',
                    my: 0.5,
                    fontWeight: 'fontWeightMedium',
                  }}
                >
                  {client.email}
                </Typography>

                <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                  {client.phone}
                </Typography>
              </ListItemButton>
            ))}
            {isNotFound && <EmptyContent title="No client found" />}
          </Scrollbar>
        </>
      )}
    </Stack>
  );
}
