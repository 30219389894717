import { useState, MouseEvent } from 'react';
import { blue } from '@mui/material/colors';
import { useTheme } from '@mui/material/styles';
// mui
import { MenuItem, TableRow, TableCell, Typography, Box } from '@mui/material';
//
import { Iconify, Label } from '@/components';
import { TableMoreMenu, TableRowLink } from '@/components/table';
// utils
import { fCurrency } from '@/utils/formatNumber';
// types
import { Variant } from '@/features/services-management/types';
import { LabelColors } from '@/types';
import useToggle from '@/hooks/useToggle';
import DialogSimple from '@/components/dialog/DialogSimple';
import { servicesManagementPath } from '@/features/services-management';

type Props = {
  row: Variant;
  onEditRow: VoidFunction;
  onDeleteRow: VoidFunction;
  canDelete: boolean;
  canUpdate: boolean;
};

// ----------------------------------------------------------

export default function VariantsTableRow({
  row,
  onEditRow,
  onDeleteRow,
  canDelete,
  canUpdate,
}: Props) {
  const theme = useTheme();

  const { id, name, service, price, service_reccurence, active } = row;

  // ===================
  //      3DOT MENU
  // ===================

  const [openMenu, setOpenMenuActions] = useState<HTMLElement | null>(null);

  const handleOpenMenu = (event: MouseEvent<HTMLElement>) => {
    setOpenMenuActions(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setOpenMenuActions(null);
  };

  // ========================
  //      DELETE CLIENT
  // ========================
  const {
    toggle: isDeleteDialogOpen,
    onOpen: openDeleteDialog,
    onClose: closeDeleteDialog,
  } = useToggle();

  const handleDelete = () => {
    onDeleteRow();
    closeDeleteDialog();
  };

  // =============================================

  return (
    <>
      <DialogSimple
        dialogTitle={`Delete variant`}
        open={isDeleteDialogOpen}
        onClickButtonClose={closeDeleteDialog}
        onClickButtonLoading={handleDelete}
        loadingButton={true}
        disagreeButton={true}
        loadingButtonText="Delete"
        loadingButtonColor="error"
        disagreeButtonText="Cancel"
        disagreeButtonColor="inherit"
      >
        <Box sx={{ px: 3 }}>
          <Typography variant="body2" sx={{ mb: 2 }}>
            Are you sure you want to delete this variant:{' '}
            <span style={{ fontWeight: 600 }}>{name}</span>?
          </Typography>
        </Box>
      </DialogSimple>

      <TableRow hover sx={{ verticalAlign: 'center' }}>
        <TableCell sx={{ width: '0.2rem' }} />

        <TableCell>
          <TableRowLink
            linkText={name}
            linkTo={servicesManagementPath('editVariant', {
              variantId: String(id),
              tabSid: 'details',
            })}
          />
        </TableCell>

        <TableCell>
          <TableRowLink
            linkText={service.name}
            linkTo={servicesManagementPath('editService', {
              serviceId: String(service.id),
            })}
          />
        </TableCell>

        <TableCell align="left">{fCurrency(price)}</TableCell>

        <TableCell align="left">
          <Label
            variant={theme.palette.mode === 'light' ? 'ghost' : 'filled'}
            sx={{
              textTransform: 'capitalize',
              backgroundColor: blue[500],
              color: '#fff',
            }}
          >
            {service_reccurence.name}
          </Label>
        </TableCell>

        <TableCell align="left">
          <Label
            variant={theme.palette.mode === 'light' ? 'ghost' : 'filled'}
            color={active ? 'success' : ('error' as LabelColors)}
            sx={{ textTransform: 'capitalize' }}
          >
            {active ? 'Active' : 'Inactive'}
          </Label>
        </TableCell>

        <TableCell align="right">
          {(canDelete || canUpdate) && (
            <TableMoreMenu
              open={openMenu}
              onOpen={handleOpenMenu}
              onClose={handleCloseMenu}
              actions={
                <>
                  {canUpdate && (
                    <MenuItem
                      onClick={() => {
                        onEditRow();
                        handleCloseMenu();
                      }}
                    >
                      <Iconify icon={'eva:edit-fill'} />
                      Edit
                    </MenuItem>
                  )}
                  {canDelete && (
                    <MenuItem
                      onClick={() => {
                        openDeleteDialog();
                        handleCloseMenu();
                      }}
                      sx={{ color: 'error.main' }}
                    >
                      <Iconify icon={'eva:trash-2-outline'} />
                      Delete
                    </MenuItem>
                  )}
                </>
              }
            />
          )}
        </TableCell>
      </TableRow>
    </>
  );
}
