import { TableRow, TableCell } from '@mui/material';
//
import { Label } from '@/components';
import { TableRowLink } from '@/components/table';
// utils
import { fDate } from '@/utils/formatTime';
import { providersPath } from '../../routes/paths';
import { clientManagementPath } from '@/features/users/clients';
import { bookingsManagementPath } from '@/features/bookings-management';
//
import { Booking } from '@/features/bookings-management/types';

type ProviderJobsRowProps = {
  row: Booking;
};

export default function ProviderJobsTableRow({ row }: ProviderJobsRowProps) {
  const {
    id,
    user,
    date,
    booking_session_cleaners,
    // provider_income,
    provider_paid,
  } = row;

  const client = `${user?.first_name ?? 'N/A'} ${user?.last_name ?? 'N/A'}`;
  const assignedTo = booking_session_cleaners;

  return (
    <>
      <TableRow hover>
        <TableCell width={10} />

        {/* ID */}
        <TableCell align="left">
          <TableRowLink
            linkText={id}
            linkTo={bookingsManagementPath('view', {
              bookingId: String(id),
              bookingTabSid: 'booking-sessions',
              sessionFilter: 'today',
            })}
          />
        </TableCell>

        {/* CLIENT */}
        <TableCell align="left">
          <TableRowLink
            linkText={client}
            linkTo={clientManagementPath('edit', {
              userId: String(user.id),
              tabSid: 'details',
            })}
          />
        </TableCell>

        {/* EMAIL */}
        <TableCell align="left">
          <TableRowLink
            linkText={user?.email ?? 'N/A'}
            linkTo={`mailto:${user?.email}`}
          />
        </TableCell>

        {/* ASSIGNED TO */}
        <TableCell align="left">
          <TableRowLink
            linkText={`${assignedTo[0]?.first_name} ${assignedTo[0]?.last_name}`}
            linkTo={providersPath('edit', {
              userId: String(assignedTo[0]?.id || 0),
              tabSid: 'details',
            })}
          />
        </TableCell>

        {/* PROVIDER INCOME */}
        <TableCell align="left">
          {/* {provider_income ? fCurrency(provider_income) : 'N/A'} */}
          CHECK API?ADRIAN
        </TableCell>

        {/* PAYMENT STATUS */}
        <TableCell align="left">
          <Label color={provider_paid ? 'success' : 'error'}>
            {provider_paid ? 'Paid' : 'Unpaid'}
          </Label>
        </TableCell>

        <TableCell align="left">{fDate(date, 'EE, dd MMM yy')}</TableCell>
      </TableRow>
    </>
  );
}
