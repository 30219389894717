import { useEffect, useState } from 'react';
import { isEmpty } from 'lodash';
// api
import { getSingleProductData } from '../api';
// react router
import { useParams, useLocation } from 'react-router-dom';
// @mui
import { Container } from '@mui/material';
// hooks
import useSettings from '@/hooks/useSettings';
// components
import { Page, HeaderBreadcrumbs } from '@/components';
// custom components
import { ProductCreateEdit } from './index';
// types
import type { Product } from '../types';
// routes
import { ROOTS_PRODUCTS } from '../routes/paths';
import { PATH_USER } from '@/features/app';

// ----------------------------------------------------------------------

export default function ProductCreate() {
  const { themeStretch } = useSettings();
  const [currentProduct, setCurrentProduct] = useState<Product | {}>({});

  const { pathname } = useLocation();

  const { id } = useParams();

  const isEdit = pathname.includes('edit');

  useEffect(() => {
    const fetchData = async () => {
      const data = await getSingleProductData(Number(id));
      setCurrentProduct(data);
    };

    id && fetchData();
  }, [id]);

  return (
    <Page title="Add new product">
      <Container maxWidth={themeStretch ? false : 'lg'}>
        <HeaderBreadcrumbs
          heading={!isEdit ? 'Add new product' : 'Edit product'}
          links={[
            { name: 'Dashboard', href: PATH_USER.root },
            {
              name: 'Product Management',
              href: `${ROOTS_PRODUCTS}`,
            },
            {
              name: 'Product',
              href: `${ROOTS_PRODUCTS}`,
            },
            {
              name: !isEdit
                ? 'Add New Product'
                : isEmpty(currentProduct)
                ? ''
                : (currentProduct as Product).name,
            },
          ]}
        />
        <ProductCreateEdit
          currentProduct={currentProduct as Product}
          productID={Number(id)}
        />
      </Container>
    </Page>
  );
}
