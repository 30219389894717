import { useEffect, useState } from 'react';
import { isEmpty } from 'lodash';
// api
import { getSingleProviderData } from '../api';
// react router
import { useParams, useLocation } from 'react-router-dom';
// @mui
import { Container } from '@mui/material';
// hooks
import useSettings from '@/hooks/useSettings';
// components
import { Page, HeaderBreadcrumbs } from '@/components';
// custom components
import { ProviderCreateEdit } from './index';
// types
import type { Provider } from '../types';
// paths
import { PATH_USER } from '@/features/app';
import { PATH_SETTINGS } from '../routes/paths';

// ----------------------------------------------------------------------

export default function Create() {
  const { themeStretch } = useSettings();
  const [currentData, setCurrentData] = useState<Provider | {}>({});

  const { pathname } = useLocation();

  const { id } = useParams();

  const isEdit = pathname.includes('edit');

  useEffect(() => {
    const fetchData = async () => {
      const data = await getSingleProviderData(Number(id));
      setCurrentData(data);
    };

    id && fetchData();
  }, [id]);

  return (
    <Page title="Create or Edit provider">
      <Container maxWidth={themeStretch ? false : 'lg'}>
        <HeaderBreadcrumbs
          heading={!isEdit ? 'Add new provider' : 'Edit provider'}
          links={[
            { name: 'Dashboard', href: PATH_USER.root },
            {
              name: 'Settings',
              href: `${PATH_SETTINGS.root}${PATH_SETTINGS.providers}`,
            },
            {
              name: 'Providers',
              href: `${PATH_SETTINGS.root}${PATH_SETTINGS.providers}`,
            },
            {
              name: !isEdit
                ? 'Add New Provider'
                : isEmpty(currentData)
                ? ''
                : (currentData as Provider).name,
            },
          ]}
        />
        <ProviderCreateEdit
          currentData={currentData as Provider}
          ID={Number(id)}
        />
      </Container>
    </Page>
  );
}
