import axios from '@/utils/axios';
import { LogsData, AuditLogResponseData } from '../types';

export async function getAuditLogs(): Promise<LogsData[]> {
  const {
    data: { data },
  }: AuditLogResponseData = await axios.get('/auditlog');

  return data;
}
