import { tableSortFilter } from '@/utils/arrayProcessing';
import { UserCustomPrice } from '@/features/users/shared/types';
import { number, object, string } from 'yup';

export const CLIENT_CUSTOM_PRICES_TABLE_HEAD = [
  { id: 'emptyCell', label: '', align: 'left' },
  { id: 'cities.name', label: 'City', align: 'left' },
  { id: 'service_variant.service.name', label: 'Service Type', align: 'left' },
  { id: 'service_variant.name', label: 'Service Variant', align: 'left' },
  { id: 'price', label: 'Price', align: 'left' },
  { id: 'discount_percentage', label: 'Discount %', align: 'left' },
  { id: 'start_date', label: 'Start Date', align: 'left' },
  { id: 'end_date', label: 'End Date', align: 'left' },
  { id: 'locked', label: 'Locked', align: 'left' },
  { id: '3dotMenu', label: '', align: 'left' },
];

export function applySortFilterClientPrices({
  tableData,
  comparator,
  filterStatus,
}: {
  tableData: UserCustomPrice[];
  comparator: (a: any, b: any) => number;
  filterStatus: string;
}) {
  tableData = tableSortFilter(tableData, comparator);
  if (filterStatus.toLowerCase() === 'expired') {
    tableData = tableData.filter((price) => {
      const isPriceExpired =
        new Date(price.end_date) < new Date() || price.locked;
      return isPriceExpired;
    });
  }
  if (filterStatus.toLowerCase() === 'active') {
    tableData = tableData.filter((price) => {
      const isPriceExpired = new Date(price.end_date) >= new Date();
      return isPriceExpired;
    });
  }
  return tableData;
}

export const CLIENT_CUSTOM_PRICE_DEFAULT_VALUES = {
  city_id: '',
  service_type_id: '',
  service_variant_id: '',
  price: '',
  discount_percentage: '',
  start_date: '',
  end_date: '',
};

export const NEW_CLIENT_CUSTOM_PRICE_SCHEMA = object().shape({
  city_id: string().typeError('City is required').required('City is required'),
  service_variant_id: string().required('Service Variant is required'),
  price: number().required('Price is required'),
  discount_percentage: number().required('Discount is required'),
  start_date: string().required('Start Date is required'),
  end_date: string()
    .required('End Date is required')
    .test(
      'is-greater',
      'End Date must be greater than Start Date',
      function (end_date) {
        const { start_date } = this.parent;
        return end_date && start_date
          ? new Date(end_date) > new Date(start_date)
          : true;
      }
    ),
});
