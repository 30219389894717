import { BookingSession } from '@/features/bookings-management/types';
import { DAYS_OF_WEEK } from '@/utils/months-and-days';
import { isFuture, isPast, isToday } from 'date-fns';

export const returnLongDayNames = (weekDays: string) => {
  const arrayOfDays = weekDays.split(',');
  return arrayOfDays
    .map(
      (weekday) =>
        DAYS_OF_WEEK.find(
          (day) =>
            day.recurrence_short_name.toLowerCase() === weekday.toLowerCase()
        )?.name
    )
    .join(', ');
};

export const generateRecurringRuleText = (
  currentCleaningSession: BookingSession
) => {
  const { booking_recurrence } = currentCleaningSession;
  const { interval_number, repeat_string, until, week_days } =
    booking_recurrence;
  if (repeat_string === 'daily') {
    return `Every ${returnLongDayNames(week_days)}`;
  }

  if (repeat_string === 'weekly' && interval_number === 1) {
    return week_days.length === 0
      ? 'N/A - no weekdays provided'
      : `Every week on ${returnLongDayNames(week_days)}`;
  }

  if (repeat_string === 'weekly' && interval_number === 2) {
    return `Fortnightly on ${returnLongDayNames(week_days)}`;
  }

  if (repeat_string === 'monthly') {
    return `Monthly on the ${returnLongDayNames(week_days)} day of the month`;
  }

  if (repeat_string === 'yearly') {
    return `Yearly on ${returnLongDayNames(week_days)} of ${until}`;
  }

  return 'Cannot generate recurring rule text';
};

export const getSessionDateFilter = (date: string | Date) => {
  const d = new Date(date);
  if (isToday(d)) return 'today';
  if (isFuture(d)) return 'upcoming';
  if (isPast(d)) return 'previous';

  return 'today';
  // the return value will be used as a urlSearchParam to filter the sessions using an endpoint
};
