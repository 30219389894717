import { boolean, object, string } from 'yup';
import { UserAddress } from '../../shared/types';
import { Postcode } from '@/features/app/types/postcode';
import { Service } from '@/types';

export const NEW_PROVIDER_SCHEMA = object().shape({
  first_name: string().required('Name is required'),
  last_name: string().required('Name is required'),
  email: string().required('Email is required').email(),
  // phone_number: string().required('Phone number is required'),
  status: string().required('Status is required'),
  // ADDRESS
  // address_1: string().required('Address is required'),
  // address_2: string().required('Address is required'),
  // town_city: string().required('Town/City is required'),
  // postcode: string().required('Postcode is required'),
  // ALLERGY
  allergy: boolean(),
  allergy_description: string().when('allergy', {
    is: true,
    then: string().required('Allergy description is required'),
  }),
});

export const NEW_PROVIDER_DEFAULT_VALUES = {
  first_name: '',
  last_name: '',
  gender: '',
  position: '',
  short_description: '',
  status: '',
  //
  profile_image: '',
  pets: false,
  // CONTACT DETAILS
  email: '',
  phone_number: '',
  has_address: false,
  address_1: '',
  address_2: '',
  town_city: '',
  postcode: '',
  // ALLERGY
  allergy: false,
  allergy_description: '',
  return_to_edit_user: false,
  covered_postcodes: [],
  nearby_postcodes: [],
  covered_service_types: [],
  is_chat_user: true,
};

export const findDefaultAddress = (addresses: UserAddress[] | null) => {
  if (!addresses) return undefined;
  return addresses.find((address) => address.default);
};

export const retrievePostcodeObjects = (
  covered_postcodes: string | undefined,
  postcodes: Postcode[]
) => {
  if (!covered_postcodes || covered_postcodes?.length === 0) return []; // covered_postcodes = 'BS1|BS2|BS3' or ''
  const selectedPostcodes = covered_postcodes
    .split('|')
    .map((postcode) =>
      postcodes.find(
        (p) => p.outer_postcode.toLowerCase() === postcode.toLowerCase()
      )
    );
  return selectedPostcodes || [];
};
export const retrieveServiceTypeObjects = (
  services: string | undefined,
  serviceOptions: Service[]
) => {
  if (!services || services?.length === 0) return []; // covered_postcodes = 'BS1|BS2|BS3' or ''
  return (
    services
      .split('|')
      .map((service) => serviceOptions.find((s) => s.id === Number(service))) ||
    []
  );
};
