import { useState, MouseEvent } from 'react';
//
import { TableRow, TableCell, MenuItem } from '@mui/material';
import { TableMoreMenu, TableRowLink } from '@/components/table';
import { Iconify } from '@/components';
//
import { DummyProviderInvoices } from './ClientInvoices';
// utils
import { fDateAbbrevMonth } from '@/utils/formatTime';
// types

type ClientInvoicesRowProps = {
  row: DummyProviderInvoices;
  canDelete: boolean;
  canUpdate: boolean;
};

export default function ClientInvoicesTableRow({
  row,
  canDelete,
  canUpdate,
}: ClientInvoicesRowProps) {
  const { id, from_date, to_date, payment_date, jobs_no } = row;

  // ===================
  //      3DOT MENU
  // ===================

  const [openMenu, setOpenMenuActions] = useState<HTMLElement | null>(null);

  const handleOpenMenu = (event: MouseEvent<HTMLElement>) => {
    setOpenMenuActions(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setOpenMenuActions(null);
  };

  // ========================
  //      DELETE PROVIDER
  // ========================
  // const [openModal, setOpenModal] = useState(false);
  // const handleClickonDeleteRow = () => {
  //   setOpenModal(true);
  // };

  // const handleCloseDialog = () => {
  //   setOpenModal(false);
  // };

  // const dialogDisagree = () => {};
  return (
    <>
      <TableRow hover>
        <TableCell width={10} />
        <TableCell align="left">
          <TableRowLink
            linkText={id}
            linkTo="#"
            onClick={() => alert('This will open the invoice')}
          />
        </TableCell>

        <TableCell align="left">{fDateAbbrevMonth(from_date)}</TableCell>

        <TableCell align="left">{fDateAbbrevMonth(to_date)}</TableCell>

        <TableCell align="left">{fDateAbbrevMonth(payment_date)}</TableCell>

        <TableCell align="left">{jobs_no}</TableCell>
        <TableCell align="right">
          {(canDelete || canUpdate) && (
            <TableMoreMenu
              open={openMenu}
              onOpen={handleOpenMenu}
              onClose={handleCloseMenu}
              actions={
                <>
                  {canUpdate && (
                    <MenuItem
                      onClick={() => {
                        alert('This will open the edit form');
                        handleCloseMenu();
                      }}
                    >
                      <Iconify icon={'eva:edit-fill'} />
                      Edit
                    </MenuItem>
                  )}
                  {canDelete && (
                    <MenuItem
                      onClick={() => {
                        alert('This will delete the job');
                        handleCloseMenu();
                      }}
                      sx={{ color: 'error.main' }}
                    >
                      <Iconify icon={'eva:trash-2-outline'} />
                      Delete
                    </MenuItem>
                  )}
                </>
              }
            />
          )}
        </TableCell>
      </TableRow>
    </>
  );
}
