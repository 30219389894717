import { useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
//
import {
  Skeleton,
  FormControlLabel,
  Checkbox,
  Stack,
  Button,
} from '@mui/material';
import { VBMConfirmCleanerDialog } from '..';
// api
import { getSingleService } from '@/features/services-management/api';
import { getSettingsData } from '@/features/app/api';
// hooks + utils
import useToggle from '@/hooks/useToggle';
import useResponsive from '@/hooks/useResponsive';
import { useSnackbarMsg } from '@/hooks/useSnackbarMsg';
import {
  transformExtraIds,
  retrieveExtrasFromId,
} from '@/features/bookings-management/utils';
//types
import { BookingSession } from '@/features/bookings-management/types';

type ExtrasEditProps = {
  bookingSession: BookingSession | undefined;
  handleSetIsEdit: (value: boolean) => void;
};

// TODO: this component was refactored to accomodate the dialog before changes happen
//       could do with a bit more refactoring to make it neater

export default function VBMCleaningExtrasEdit({
  bookingSession,
  handleSetIsEdit,
}: ExtrasEditProps) {
  const [searchParams] = useSearchParams();
  const sessionID = searchParams.get('sessionID');
  const { errorMsg } = useSnackbarMsg();
  const downMd = useResponsive('down', 'md');
  const {
    toggle: isConfirmCleanerDialogOpen,
    onOpen: openConfirmCleanerDialog,
    onClose: closeConfirmCleanerDialog,
  } = useToggle();

  // local states to manage change in checkboxes and sessionDto
  const [availableExtras, toggleAvailableExtras] = useState<
    {
      id: number;
      name: string;
      selected: boolean;
    }[]
  >([]);
  const [isCleaningProductsRequested, setIsCleaningProductsRequested] =
    useState<boolean>(!!bookingSession?.cleaning_products);

  const [sessionDto, setSessionDto] = useState<{
    extras: string;
    cleaning_products: boolean;
  }>({
    extras: bookingSession?.extras || '',
    cleaning_products: !!bookingSession?.cleaning_products,
  });

  // ==================
  //      QUERIES
  // ==================

  // EXTRAS FROM SETTINGS
  const { data: serviceExtras, isLoading: isExtrasDataLoading } = useQuery({
    queryKey: ['settings'],
    queryFn: getSettingsData,
    onError: (error) => {
      errorMsg(error, `Something went wrong while fetching settings`);
    },
    select: (data) => data.serviceExtras.filter((e) => !e.name.includes('pet')),
    refetchOnWindowFocus: false,
    // TODO: stale time
  });

  // DERIVED VALUES
  const transformedBookingSessionExtras = transformExtraIds(
    // returns an array of extra IDs ie [1, 2, 3, 4]
    bookingSession?.extras
  );
  const selectedSessionExtraObjects = retrieveExtrasFromId(
    // finds the selected extra's object and returns an array of <ServiceExtra[]>
    transformedBookingSessionExtras,
    serviceExtras || []
  );

  // GET SERVICE TO DETERMINE AVAILABLE EXTRAS
  const { isLoading: isServiceDataLoading } = useQuery({
    queryKey: ['services', bookingSession?.service_variant.service.id ?? ''],
    queryFn: () =>
      getSingleService(Number(bookingSession?.service_variant.service.id)),
    onSuccess: (data) => {
      const avaliableExtrasForSelectedService = // this could be done in the query select: (data) => transform/flatten array
        data.service_cleaning_extras.map((e) => ({
          id: e.cleaning_extra.id,
          name: e.cleaning_extra.name,
          selected: selectedSessionExtraObjects.some(
            (x) => x.id === e.cleaning_extra.id
          ),
        })); // flattens the available extras arrray
      toggleAvailableExtras(avaliableExtrasForSelectedService);
    },
    onError: (error) => {
      errorMsg(error, `Something went wrong while fetching selected service`);
    },
    refetchOnWindowFocus: false,
    enabled:
      !!bookingSession &&
      !!bookingSession?.service_variant.service.id &&
      !!serviceExtras,
  });

  // ==================
  //      UTIL FNS
  // ==================

  // Function to update an object in the array by its ID
  const updateExtraById = (
    id: number,
    updatedFields: Partial<(typeof availableExtras)[0]>
  ) => {
    toggleAvailableExtras((prevExtras) =>
      prevExtras.map((extra) =>
        extra.id === id ? { ...extra, ...updatedFields } : extra
      )
    );

    // Update sessionData.extras based on the new selected extras
    setSessionDto((prevData) => {
      const selectedExtraIds = availableExtras
        .map((extra) =>
          extra.id === id ? { ...extra, selected: !extra.selected } : extra
        )
        .filter((extra) => extra.selected)
        .map((extra) => extra.id)
        .join('|');

      return { ...prevData, extras: selectedExtraIds };
    });
  };

  // Example usage: toggle the "selected" field of an object by its ID
  const toggleSelection = (id: number) => {
    const selectedExtra = availableExtras.find((xx) => xx.id === id);
    if (selectedExtra) {
      updateExtraById(id, { selected: !selectedExtra.selected });
    }
  };

  const handleToggleCleaningProducts = (value: boolean) => {
    setIsCleaningProductsRequested(value);
    setSessionDto((prevData) => ({
      ...prevData,
      cleaning_products: value,
    }));
  };

  const handleToggleEdit = (value: boolean) => handleSetIsEdit(value);

  const onSuccessCallbackFn = () => {
    handleToggleEdit(false);
    closeConfirmCleanerDialog();
  };

  const showSkeleton =
    isExtrasDataLoading || isServiceDataLoading || !availableExtras;

  if (showSkeleton) {
    return (
      <Stack spacing={1}>
        {[...new Array(5)].map((_e, i) => (
          <Skeleton variant="text" height={50} key={i} />
        ))}
      </Stack>
    );
  }

  return (
    <>
      <Stack spacing={1}>
        <>
          <>
            {availableExtras.map((extra, index: number) => (
              <FormControlLabel
                control={
                  <Checkbox
                    name={extra.id.toString()}
                    checked={extra.selected}
                    onChange={() => {
                      toggleSelection(extra.id);
                    }}
                  />
                }
                label={extra.name}
                key={index}
                sx={{ ml: 0 }}
              />
            ))}
          </>
          <FormControlLabel
            control={
              <Checkbox
                name="cleaning_products"
                checked={isCleaningProductsRequested}
                onChange={(e) => handleToggleCleaningProducts(e.target.checked)}
              />
            }
            label="Cleaning products"
            sx={{ ml: 0 }}
          />
        </>
        <Stack
          direction="row"
          justifyContent="flex-end"
          spacing={1}
          sx={{ mt: 2 }}
        >
          <Button
            variant="outlined"
            size="small"
            color="inherit"
            onClick={() => handleToggleEdit(false)}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            size="small"
            onClick={openConfirmCleanerDialog}
          >
            {downMd ? 'Save' : 'Save changes'}
          </Button>
        </Stack>
      </Stack>
      <VBMConfirmCleanerDialog
        isDialogOpen={isConfirmCleanerDialogOpen}
        onCloseDialog={closeConfirmCleanerDialog}
        bookingSessionId={Number(sessionID)}
        onSuccessCallbackFn={onSuccessCallbackFn}
        onSuccessText={`Cleaning extras updated`}
        parentBookingSessionDto={sessionDto}
      />
    </>
  );
}
