import { useSnackbar } from 'notistack';

export const useSnackbarMsg = () => {
  const { enqueueSnackbar } = useSnackbar();

  const errorMsg = (
    error: any,
    message: string = 'Something went wrong',
    style: React.CSSProperties = { whiteSpace: 'pre-wrap' }
  ) => {
    if (error === 'Cancelled') {
      enqueueSnackbar(`Cancelled by interceptor`, {
        variant: 'error',
        style,
      });
      console.error('Cancelled by interceptor >>> utils/axios.ts');
    }

    if (error instanceof Error) {
      enqueueSnackbar(`${message}: \n${error.message}`, {
        variant: 'error',
        style,
      });
    } else {
      // Fallback error message or stringify the unknown error
      // This handles cases where the error might not be an instance of Error
      const errorMessage =
        typeof error === 'string'
          ? error
          : JSON.stringify((error as Error).message);

      enqueueSnackbar(`${message}: \n${errorMessage}`, {
        variant: 'error',
        style,
      });
    }
    console.error(message, error);
  };

  const successMsg = (
    message: string = 'Operation successful',
    style: React.CSSProperties = { whiteSpace: 'pre-wrap' }
  ) => {
    enqueueSnackbar(message, {
      variant: 'success',
      style,
    });
  };

  return { errorMsg, successMsg };
};
