import { Helmet } from 'react-helmet-async';
import { forwardRef, ReactNode } from 'react';
// @mui
import { Box, BoxProps, Fade } from '@mui/material';
import { TIMEOUT } from '@/config';

// ----------------------------------------------------------------------

interface Props extends BoxProps {
  children: ReactNode;
  meta?: ReactNode;
  title: string;
}

const Page = forwardRef<HTMLDivElement, Props>(
  ({ children, title = '', meta, ...other }, ref) => (
    <>
      <Helmet>
        <title>{`${title} | Get Set Clean`}</title>
        {meta}
      </Helmet>
      <Fade in timeout={TIMEOUT}>
        <Box ref={ref} {...other}>
          {children}
        </Box>
      </Fade>
    </>
  )
);

export default Page;
