import { useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
// @mui
import {
  Box,
  Button,
  Card,
  Container,
  Divider,
  FormControlLabel,
  IconButton,
  Stack,
  Switch,
  Tab,
  Table,
  TableBody,
  TableContainer,
  TablePagination,
  Tabs,
  Tooltip,
} from '@mui/material';
// hooks
import useTabs from '@/hooks/useTabs';
import useSettings from '@/hooks/useSettings';
import useToggle from '@/hooks/useToggle';
import useTable, { getComparator, emptyRows } from '@/hooks/useTable';
import { useGetAccess } from '@/hooks/useAccess';
import { useSnackbarMsg } from '@/hooks/useSnackbarMsg';
// components
import {
  HeaderBreadcrumbs,
  Iconify,
  Label,
  Page,
  Scrollbar,
} from '@/components';
import Dialog from '@/components/dialog/Dialog';
import {
  TableEmptyRows,
  TableHeadCustom,
  TableNoData,
  TableSelectedActions,
} from '@/components/table';
// custom components
import {
  BookingsTableRow,
  BookingsTableToolbar,
  BookingsListSkeletons,
} from '../components';
// API
import { deleteBooking, getBookings } from '../api';
import { getSettingsData } from '@/features/app/api';
// utils
import {
  applySortFilterBookings,
  BOOKINGS_TABLE_HEAD,
  calculateBookingStatusFigures,
} from '../utils';
import { consoleError } from '@/utils/consoleError';
import { bookingsManagementPath } from '../routes/paths';
// redux
import { dispatch } from '@/stores';
import { resetReduxValues } from '@/features/bookings-management/slices';
// types
import { Booking } from '../types';

export default function BookingsList() {
  const { themeStretch } = useSettings();
  const navigate = useNavigate();
  const { errorMsg, successMsg } = useSnackbarMsg();
  const allow = useGetAccess('bookings');
  const queryClient = useQueryClient();

  const [searchFieldValue, setSearchFieldValue] = useState('');
  const [filterServiceId, setFilterServiceId] = useState<number>(0);
  const [filterServiceTypeId, setFilterServiceTypeId] = useState<number>(0);
  const [filterStartDate, setFilterStartDate] = useState<string | null>(null);
  const [filterEndDate, setFilterEndDate] = useState<string | null>(null);

  const resetFilters = () => {
    setSearchFieldValue('');
    setFilterServiceId(0);
    setFilterServiceTypeId(0);
    setFilterStartDate(null);
    setFilterEndDate(null);
  };

  // =================
  //      USETABLE
  // =================

  const {
    dense,
    page,
    order,
    orderBy,
    rowsPerPage,
    setPage,
    //
    selected,
    setSelected,
    onSelectRow,
    onSelectAllRows,
    //
    onSort,
    onChangeDense,
    onChangePage,
    onChangeRowsPerPage,
  } = useTable({ defaultOrder: 'desc', defaultOrderBy: 'id' });

  // =================
  //      QUERIES
  // =================

  // SETTINGS
  const { data: settingsData } = useQuery({
    queryKey: ['settings'],
    queryFn: () => getSettingsData(),
    onError: (error) => {
      errorMsg(error, `Something went wrong while fetching settings data `);
    },
    staleTime: 24 * 60 * 60 * 1000, // update every 24 hours
    cacheTime: 24 * 60 * 60 * 1000, // update every 24 hours
  });

  // BOOKINGS
  const { data: bookings, isLoading: isBookingsDataLoading } = useQuery({
    queryKey: ['bookings'],
    queryFn: () => getBookings(),
    onError: (error) => {
      errorMsg(error, `Something went wrong while fetching bookings data `);
    },
    enabled: !!settingsData,
  });

  // DERIVED VALUES
  const bookingStatusOptions = (settingsData?.bookingStatuses || []).map(
    (status) => status.display ?? ''
  );
  bookingStatusOptions.push('All');

  const servicesWithTheirVariants =
    settingsData?.services?.map((service) => ({
      id: service.id,
      name: service.name,
      variants: settingsData.serviceVariants.reduce<number[]>(
        (variantIDs, variant) => {
          if (variant.service_id === service.id) {
            variantIDs.push(variant.id);
          }
          return variantIDs;
        },
        []
      ),
    })) ?? [];

  // ================================
  //      DELETE, EDIT, FILTER
  // ================================

  const { currentTab: filterStatus, onChangeTab: onChangeFilterStatus } =
    useTabs('All');

  // DELETE
  const deleteBookingMutation = useMutation({
    mutationFn: (bookingId: number) => deleteBooking(bookingId),
    onSuccess: (_apiResponse, variables) => {
      // TODO: consider using optimistic updates with onMutate fn, though this will do for now
      queryClient.setQueryData(['bookings'], (old: Booking[] = []) =>
        old.filter((b: any) => b.id !== variables)
      );
      queryClient.invalidateQueries(['bookings']);
      successMsg(`Booking succesfully deleted`);
    },
    onError: (error) => {
      errorMsg(error, `There was an error while deleting booking`);
    },
  });

  // API IS NOT READY YET
  const handleDeleteRows = async (selected: string[]) => {
    // TODO: Adrian -- we should have a separate API for this that takes an array of IDs
  };

  // EDIT
  const handleEditRow = (bookingId: number) => {
    navigate(bookingsManagementPath('edit', { bookingId: String(bookingId) }));
  };

  // FILTER
  const handleSearchField = (filterName: string) => {
    setSearchFieldValue(filterName);
    setPage(0);
  };

  const handleFilterDropdown = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    switch (name) {
      case 'service':
        setFilterServiceId(Number(value));
        setFilterServiceTypeId(0);
        break;
      case 'service_type':
        setFilterServiceTypeId(Number(value));
        break;
      case 'start_date':
        setFilterStartDate(value);
        break;
      case 'end_date':
        setFilterEndDate(value);
        break;
      default:
        consoleError('BookingsList', 'handleFilterDropdown()');
    }
    setSelected([]);
    setPage(0);
  };

  const handleDatepickerChange = (date: string | null, date_type: string) => {
    switch (date_type) {
      case 'start':
        setFilterStartDate(date);
        break;
      case 'end':
        setFilterEndDate(date);
        break;
      default:
        consoleError('BookingsList', 'handleDatepickerChange()');
    }
    setSelected([]);
    setPage(0);
  };

  const dataFiltered = applySortFilterBookings({
    tableData: bookings || [],
    comparator: getComparator(order, orderBy),
    searchFieldValue,
    filterServiceId,
    filterServiceTypeId,
    filterStartDate,
    filterEndDate,
    filterStatus,
    servicesWithTheirVariants,
  });

  const denseHeight = dense ? 52 : 72;
  const isNotFound = !dataFiltered.length && !!searchFieldValue;

  // =================
  //      DIALOG
  // =================
  const {
    toggle: isDeleteDialogOpen,
    setToggle: setIsDeleteDialogOpen,
    onClose: closeDeleteDialog,
  } = useToggle();

  // ---------------------------------------------------

  return (
    <Page title="Bookings Management">
      <Container maxWidth={themeStretch ? false : 'lg'}>
        <HeaderBreadcrumbs
          heading="Bookings Management"
          links={[
            { name: 'Dashboard', href: '' },
            { name: 'Bookings', href: '' },
          ]}
          action={
            <Button
              variant="contained"
              component={RouterLink}
              to={bookingsManagementPath('new')}
              startIcon={<Iconify icon={'eva:plus-fill'} />}
              onClick={() => dispatch(resetReduxValues())}
              disabled={isBookingsDataLoading}
            >
              New Booking
            </Button>
          }
        />

        {/* TABLE  */}
        <Card>
          {isBookingsDataLoading ? (
            <BookingsListSkeletons />
          ) : (
            <>
              <Tabs
                allowScrollButtonsMobile
                variant="scrollable"
                scrollButtons="auto"
                value={filterStatus}
                onChange={onChangeFilterStatus}
                sx={{ px: 2, bgcolor: 'background.neutral' }}
              >
                {bookingStatusOptions.map((tab, index) => (
                  <Tab
                    disableRipple
                    key={index}
                    label={tab}
                    value={tab}
                    icon={
                      <Label color="success">
                        {calculateBookingStatusFigures(tab, bookings ?? [])}
                      </Label>
                    }
                  />
                ))}
              </Tabs>

              <Divider />

              <BookingsTableToolbar
                filterService={filterServiceId}
                filterServiceType={filterServiceTypeId}
                filterSearchBar={searchFieldValue}
                filterStartDate={filterStartDate}
                filterEndDate={filterEndDate}
                //
                handleFilterDropdown={handleFilterDropdown}
                handleSearchField={handleSearchField}
                handleDatepickerChange={handleDatepickerChange}
                resetFilters={resetFilters}
                //
                settingsData={settingsData}
              />

              <Scrollbar>
                <TableContainer sx={{ minWidth: 800, position: 'relative' }}>
                  {selected.length > 0 && (
                    <TableSelectedActions
                      sx={{ height: 60 }}
                      dense={dense}
                      numSelected={dataFiltered.length}
                      rowCount={dataFiltered.length}
                      onSelectAllRows={(checked) =>
                        onSelectAllRows(
                          checked,
                          dataFiltered.map((row) => row.id.toString())
                        )
                      }
                      actions={
                        <Stack direction="row" gap={1}>
                          {allow.canUpdate && (
                            <Tooltip title="Assign Cleaner">
                              <IconButton
                                color="info"
                                onClick={() =>
                                  alert('Need API to batch assign cleaners')
                                }
                                disabled={!allow.canDelete}
                              >
                                <Iconify icon={'eva:person-add-outline'} />
                              </IconButton>
                            </Tooltip>
                          )}
                          {filterStatus !== 'removed' && allow.canDelete && (
                            <Tooltip title="Delete">
                              <IconButton
                                color="error"
                                onClick={() =>
                                  alert('Need API to batch delete')
                                }
                              >
                                <Iconify icon={'eva:trash-2-outline'} />
                              </IconButton>
                            </Tooltip>
                          )}
                        </Stack>
                      }
                    />
                  )}

                  <Table size={dense ? 'small' : 'medium'}>
                    <TableHeadCustom
                      sx={{ pt: 1 }}
                      order={order}
                      orderBy={orderBy}
                      headLabel={BOOKINGS_TABLE_HEAD}
                      rowCount={(bookings || []).length}
                      numSelected={selected.length}
                      onSort={onSort}
                      onSelectAllRows={(checked) => {
                        onSelectAllRows(
                          checked,
                          dataFiltered.map((row) => row.id.toString())
                        );
                      }}
                    />
                    <TableBody>
                      {dataFiltered
                        .slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                        .map((booking) => (
                          <BookingsTableRow
                            permissions={allow}
                            key={booking.id}
                            row={booking}
                            selected={selected.includes(booking.id.toString())}
                            onSelectRow={() =>
                              onSelectRow(booking.id.toString())
                            }
                            onDeleteRow={() =>
                              deleteBookingMutation.mutate(booking.id)
                            }
                            onEditRow={() => handleEditRow(booking.id)}
                          />
                        ))}

                      <TableEmptyRows
                        height={denseHeight}
                        emptyRows={emptyRows(
                          page,
                          rowsPerPage,
                          (bookings || []).length
                        )}
                      />

                      <TableNoData
                        isNotFound={isNotFound}
                        title="No bookings found"
                      />
                    </TableBody>
                  </Table>
                </TableContainer>
              </Scrollbar>

              <Box sx={{ position: 'relative' }}>
                <TablePagination
                  rowsPerPageOptions={[25, 50, 100, 150]}
                  component="div"
                  count={dataFiltered.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={onChangePage}
                  onRowsPerPageChange={onChangeRowsPerPage}
                />

                <FormControlLabel
                  control={<Switch checked={dense} onChange={onChangeDense} />}
                  label="Dense"
                  sx={{ px: 3, py: 1.5, top: 0, position: { md: 'absolute' } }}
                />
              </Box>

              {/* DELETE BOOKINGS DIALOG */}
              <Dialog
                dialogTitle="Delete bookingS"
                dialogText={`Are you sure you want to delete 
                  ${selected.length} ${
                    selected.length === 1 ? 'booking' : 'bookings'
                  }?`}
                dialogAgree={() => handleDeleteRows(selected)}
                dialogDisagree={closeDeleteDialog}
                isDialogOpen={isDeleteDialogOpen}
                setIsDialogOpen={setIsDeleteDialogOpen}
                handleCloseDialog={closeDeleteDialog}
                agreeButtonText="Delete"
                disagreeButtonText="Cancel"
                agreeButtonColor="error"
                disagreeButtonColor="inherit"
              />
            </>
          )}
        </Card>
      </Container>
    </Page>
  );
}
