import axios from '@/utils/axios';
import { VariantCustomPrice } from '../types/custom-prices';

export const getVariantCustomPrices = async (
  serviceVariantId: string
): Promise<VariantCustomPrice[]> => {
  const {
    data: { data },
  } = await axios.get(`prices/service-variant/${serviceVariantId}`);
  return data;
};

export const getVariantSingleCustomPrice = async (
  serviceVariantId: string,
  customPriceId: string
): Promise<VariantCustomPrice> => {
  const {
    data: { data },
  } = await axios.get(
    `prices/service-variant/${serviceVariantId}/price/${customPriceId}`
  );
  return data;
};

export const createVariantCustomPrice = async (
  serviceVariantId: string,
  CustomPriceDto: Partial<VariantCustomPrice>
) => {
  const { data } = await axios.post(
    `prices/service-variant/${serviceVariantId}`,
    CustomPriceDto
  );
  return data;
};

export const updateVariantCustomPrice = async (
  serviceVariantId: string,
  customPriceId: string,
  CustomPriceDto: Partial<VariantCustomPrice>
) => {
  const { data } = await axios.put(
    `prices/service-variant/${serviceVariantId}/price/${customPriceId}`,
    CustomPriceDto
  );
  return data;
};

// TODO: api is not ready yet
export const deleteVariantCustomPrice = async (customPriceId: string) =>
  axios.delete(`prices/${customPriceId}`);
