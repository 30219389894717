import { Stack, InputAdornment, TextField, MenuItem } from '@mui/material';
// components
import Iconify from '@/components/Iconify';
import { City } from '@/types';

// ----------------------------------------------------------------------

type Props = {
  optionsCities: City[];
  filterName: string;
  filterCity: number;
  onFilterName: (value: string) => void;
  onFilterCity: (event: React.ChangeEvent<HTMLInputElement>) => void;
};

export default function ProviderTableToolbar({
  filterName,
  filterCity,
  onFilterName,
  onFilterCity,
  optionsCities,
}: Props) {
  return (
    <Stack
      spacing={2}
      direction={{ xs: 'column', sm: 'row' }}
      sx={{ py: 2.5, px: 3 }}
    >
      <TextField
        fullWidth
        select
        size="small"
        label="City"
        value={filterCity}
        onChange={onFilterCity}
        SelectProps={{
          MenuProps: {
            sx: { '& .MuiPaper-root': { maxHeight: 260 } },
          },
        }}
        sx={{
          maxWidth: { sm: 240 },
          textTransform: 'capitalize',
        }}
      >
        {optionsCities.map((option) => (
          <MenuItem
            key={option.id}
            value={option.id}
            sx={{
              mx: 1,
              my: 0.5,
              borderRadius: 0.75,
              typography: 'body2',
              textTransform: 'capitalize',
            }}
          >
            {option.name}
          </MenuItem>
        ))}
      </TextField>

      <TextField
        fullWidth
        size="small"
        value={filterName}
        onChange={(event) => onFilterName(event.target.value)}
        placeholder="Search provider..."
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Iconify
                icon={'eva:search-fill'}
                sx={{ color: 'text.disabled', width: 20, height: 20 }}
              />
            </InputAdornment>
          ),
        }}
      />
    </Stack>
  );
}
