import { useState, MouseEvent, Provider } from 'react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
//
import {
  Card,
  Chip,
  MenuItem,
  Rating,
  Stack,
  TableCell,
  TableRow,
  Typography,
  useTheme,
  Tooltip,
  IconButton,
} from '@mui/material';
//
import { Avatar, Iconify, Label } from '@/components';
import { TableMoreMenu, TableRowLink } from '@/components/table';
import DialogSimple from '@/components/dialog/DialogSimple';
// API
import { updateReview } from '@/features/reviews/api';
// hooks + utils
import { useSnackbarMsg } from '@/hooks/useSnackbarMsg';
import { fDateAbbrevMonth } from '@/utils/formatTime';
import { createDialog } from '@/utils/createDialog';
import { REVIEW_STATUSES } from '@/features/reviews//utils';
//
import { clientManagementPath } from '@/features/users/clients';
import { providersPath } from '@/features/users/providers';
// types
import { ProviderInvoice } from '@/features/invoices/types';
import { ButtonColors, LabelColors, MultiPurposeDialog } from '@/types';
import { invoicesPath } from '../routes/paths';
import { fCurrency } from '@/utils/formatNumber';
import { useNavigate } from 'react-router';

import NotesCell from './NotesCell';

type PreviewTableCustomRowProps = {
  row: ProviderInvoice;
  canUpdate: boolean;
  queryKey: string;
  hideProvider?: boolean;
};

export default function InvoicesRunInvoicesListTableRow({
  row,
  canUpdate,
  queryKey,
  hideProvider,
}: PreviewTableCustomRowProps) {
  const theme = useTheme();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { successMsg, errorMsg } = useSnackbarMsg();

  const [selectedDialog, setSelectedDialog] =
    useState<MultiPurposeDialog | null>(null);

  const {
    id,
    uuid,
    ref,
    user_id,
    status_id,
    seen,
    accepted,
    date_from,
    date_to,
    due_date,
    total,
    cleaner_notes,
    admin_notes,
    regenerate,
    created_at,
    users,
    invoice_status,
    _count,
  } = row;

  const filteredSatusesBasedOnCurentStatus = REVIEW_STATUSES.filter(
    (s) => s.name !== (status || '').toLowerCase()
  );

  const currentStatusObject = REVIEW_STATUSES.find((s) => s.name === status);

  // ===================
  //      3DOT MENU
  // ===================

  const [openMenu, setOpenMenuActions] = useState<HTMLElement | null>(null);

  const handleOpenMenu = (event: MouseEvent<HTMLElement>) => {
    setOpenMenuActions(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setOpenMenuActions(null);
  };

  const handleClickChip = () => {
    navigate(
      providersPath('edit', {
        userId: String(users?.id),
        tabSid: 'details',
      })
    );
  };

  // ========================
  //      UPDATE STATUS
  // ========================

  const updateReviewStatusMutation = useMutation({
    mutationFn: (updatedStatus: string) =>
      updateReview(row.id, {
        status: updatedStatus,
      }),
    onSuccess: () => {
      queryClient.invalidateQueries([queryKey]);
      successMsg(`Review status updated`);
    },
    onError: (error) => {
      errorMsg(error, `There was an error while updating review status`);
    },
  });

  return (
    <>
      <TableRow hover>
        <TableCell align="left">
          {regenerate && (
            <Tooltip
              title="Job has been modified. Requires new re-generation."
              placement="top"
            >
              <IconButton>
                <Iconify
                  icon={'eva:alert-triangle-fill'}
                  sx={{ color: 'orange' }}
                  width={24}
                  height={24}
                />
              </IconButton>
            </Tooltip>
          )}
        </TableCell>

        <TableCell align="left">
          <TableRowLink
            linkText={id}
            linkTo={invoicesPath('providerInvoiceDetails', {
              invoiceId: String(id),
            })}
          />
        </TableCell>

        {hideProvider ? null : (
          <TableCell align="left" width={20}>
            {users && (
              <Chip
                size="small"
                avatar={
                  <Avatar alt="profile-picture" src={users?.profile_image} />
                }
                label={users?.first_name + ' ' + users?.last_name}
                color="default"
                clickable={true}
                onClick={handleClickChip}
              />
            )}
          </TableCell>
        )}
        <TableCell align="left">
          {seen ? (
            <Iconify
              icon={'eva:eye-fill'}
              width={24}
              height={24}
              sx={{ color: theme.palette.success.dark }}
              title="Seen"
            />
          ) : (
            <Iconify
              icon={'eva:eye-fill'}
              width={24}
              height={24}
              sx={{ opacity: '20%' }}
              title="Not Seen"
            />
          )}
        </TableCell>

        <TableCell align="left">
          {accepted ? (
            <Iconify
              icon={'eva:checkmark-circle-2-fill'}
              width={20}
              height={20}
              sx={{ color: theme.palette.success.dark }}
              title="Accepted"
            />
          ) : (
            <Iconify
              icon={'eva:checkmark-circle-2-fill'}
              width={20}
              height={20}
              sx={{ opacity: '20%' }}
              title="Not Accepted"
            />
          )}
        </TableCell>

        <TableCell align="left">
          <Label
            variant={theme.palette.mode === 'light' ? 'filled' : 'ghost'}
            color={invoice_status.style_class as LabelColors}
            sx={{ textTransform: 'capitalize' }}
          >
            {invoice_status.value}
          </Label>
        </TableCell>

        <TableCell align="left">{fDateAbbrevMonth(date_from)}</TableCell>

        <TableCell align="left">{fDateAbbrevMonth(date_to)}</TableCell>

        <TableCell align="left">{fDateAbbrevMonth(due_date)}</TableCell>

        <TableCell align="left">{fCurrency(total)}</TableCell>

        <TableCell align="left">{_count.provider_invoice_items}</TableCell>

        <TableCell align="left">
          <NotesCell notes={cleaner_notes} />
        </TableCell>

        <TableCell align="left">
          <NotesCell notes={admin_notes} />
        </TableCell>

        <TableCell align="left">{fDateAbbrevMonth(created_at)}</TableCell>
      </TableRow>
    </>
  );
}
