import { useEffect, useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import {
  Box,
  Button,
  Card,
  Container,
  FormControlLabel,
  Skeleton,
  Switch,
  Table,
  TableBody,
  TableContainer,
  TablePagination,
} from '@mui/material';

import useSettings from '@/hooks/useSettings';
import useTable, { emptyRows } from '@/hooks/useTable';

import Page from '@/components/Page';
import HeaderBreadcrumbs from '@/components/HeaderBreadcrumbs';
import Dialog from '@/components/dialog/Dialog';

import { ROOTS_SETTINGS, settingsPath } from '../routes/paths';
import { ToolbarWithSearchAndStatus } from '@/components/toolbar';
import { applySortFilterForTemplates } from '../utils';
import Iconify from '@/components/Iconify';
import Scrollbar from '@/components/Scrollbar';
import {
  TableEmptyRows,
  TableHeadCustom,
  TableNoData,
} from '@/components/table';
import { deleteEmailTemplate, getEmailTemplates } from '../api';
import { EmailTemplate } from '../types';
import EmailTemplateTableRow from '../components/EmailTemplateTableRow';
// access
import { useGetAccess } from '@/hooks/useAccess';
export default function EmailTemplates() {
  const [filterText, setFilterText] = useState('');
  const [isLoading, seIsLoading] = useState<boolean>(false);
  const [filterStatus, setFilterStatus] = useState('all');
  const [tableData, setTableData] = useState<any[]>([]);
  const [openModal, setOpenModal] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const [selectedTemplateId, setSelectedTemplateId] = useState<number>(0);

  const navigate = useNavigate();

  const { themeStretch } = useSettings();
  const {
    order,
    orderBy,
    dense,
    page,
    rowsPerPage,
    setPage,
    onChangePage,
    onChangeRowsPerPage,
    onChangeDense,
  } = useTable({ defaultRowsPerPage: 5 });

  const handleFilterText = (filterText: string) => {
    setFilterText(filterText);
    setPage(0);
  };

  const handlefilterStatus = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFilterStatus(event.target.value);
  };

  const dataFiltered = applySortFilterForTemplates({
    tableData,
    filterText,
    filterStatus,
  });

  const handleDeleteRowPrompt = (id: number) => {
    setSelectedTemplateId(id);
    setOpenModal(true);
  };

  const handleEditRow = (id: number) => {
    navigate(settingsPath('edit-template', 'id', id));
  };

  const handlePreviewRow = (id: number) => {
    navigate(settingsPath('view-template', 'id', id));
  };

  const handleDeleteTemplate = async () => {
    try {
      await deleteEmailTemplate(selectedTemplateId);
      setTableData(
        tableData.filter((template) => template.id !== selectedTemplateId)
      );
      enqueueSnackbar('Email Template Created');
    } catch (error) {
      console.error(error);
      enqueueSnackbar(error.message, { variant: 'error' });
    }
  };

  useEffect(() => {
    async function callGetEmailTemplates() {
      const data: EmailTemplate[] = await getEmailTemplates();
      seIsLoading(false);
      setTableData(data);
    }
    callGetEmailTemplates();
  }, []);

  const denseHeight = dense ? 52 : 72;
  const isNotFound =
    (!dataFiltered.length && !!filterStatus) ||
    (!dataFiltered.length && !!filterText);

  const TABLE_HEAD = [
    { id: 'name', label: 'Name', align: 'left' },
    { id: 'description', label: 'Description', align: 'left' },
    { id: 'category', label: 'Category', align: 'left' },
    { id: 'active', label: 'Status', align: 'left' },
    { id: '' },
  ];
  // get the access for the module
  const allow = useGetAccess('settings');
  return (
    <>
      {openModal && (
        <Dialog
          dialogTitle="Delete Email Template"
          dialogText={'Are you sure you want to delete this template?'}
          dialogAgree={handleDeleteTemplate}
          dialogDisagree={() => setOpenModal(false)}
          isDialogOpen={openModal}
          setIsDialogOpen={setOpenModal}
          handleCloseDialog={() => setOpenModal(false)}
          agreeButtonText="Delete"
          disagreeButtonText="Cancel"
        />
      )}
      <Page title="Settings: Email Templates">
        <Container maxWidth={themeStretch ? false : 'lg'}>
          <HeaderBreadcrumbs
            heading={'Email Templates'}
            links={[
              { name: 'Settings', href: ROOTS_SETTINGS },
              { name: 'Email Templates' },
            ]}
            action={
              allow.canCreate && (
                <Button
                  variant="contained"
                  component={RouterLink}
                  to={settingsPath('new-template')}
                  startIcon={<Iconify icon={'eva:plus-fill'} />}
                >
                  Add email template
                </Button>
              )
            }
          />
          <Card>
            <ToolbarWithSearchAndStatus
              labels={{ search: 'Search for temaples', status: 'Status' }}
              status={['All', 'Active', 'Disabled']}
              filterText={filterText}
              filterStatus={filterStatus}
              onFilterText={handleFilterText}
              onFilterStatus={handlefilterStatus}
            />
            {isLoading ? (
              <Box pl={2} pb={2}>
                <Skeleton sx={{ width: 0.8 }} height={40} />
                <Skeleton sx={{ width: 0.5 }} height={40} />
                <Skeleton sx={{ width: 0.7 }} height={40} />
              </Box>
            ) : (
              <Scrollbar>
                <TableContainer sx={{ minWidth: 800, position: 'relative' }}>
                  {' '}
                  <Table size={dense ? 'small' : 'medium'}>
                    <TableHeadCustom
                      order={order}
                      orderBy={orderBy}
                      headLabel={TABLE_HEAD}
                      rowCount={tableData.length}
                    />
                    <TableBody>
                      {dataFiltered
                        .slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                        .map((row) => (
                          <EmailTemplateTableRow
                            key={row.id}
                            row={row}
                            onDeleteRow={() => handleDeleteRowPrompt(row.id)}
                            onEditRow={() => handleEditRow(row.id)}
                            onPreviewRow={() => handlePreviewRow(row.id)}
                            permissions={allow}
                          />
                        ))}

                      <TableEmptyRows
                        height={denseHeight}
                        emptyRows={emptyRows(
                          page,
                          rowsPerPage,
                          tableData.length
                        )}
                      />

                      <TableNoData isNotFound={isNotFound} />
                    </TableBody>
                  </Table>
                </TableContainer>
              </Scrollbar>
            )}
            <Box sx={{ position: 'relative' }}>
              <TablePagination
                rowsPerPageOptions={[5, 10, 20]}
                component="div"
                count={tableData.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={onChangePage}
                onRowsPerPageChange={onChangeRowsPerPage}
              />

              <FormControlLabel
                control={<Switch checked={dense} onChange={onChangeDense} />}
                label="Dense"
                sx={{ px: 3, py: 1.5, top: 0, position: { md: 'absolute' } }}
              />
            </Box>
          </Card>
        </Container>
      </Page>
    </>
  );
}
