import { format } from 'date-fns';
import { useSelector } from '@/stores';
// mui
import { Stack } from '@mui/material';
import { SummaryFieldLabel, SummaryFieldText } from '.';
//

export default function SummaryBookingDateTime() {
  const booking = useSelector((state) => state.booking);
  return (
    <Stack direction="column" gap={2}>
      <SummaryFieldLabel>Booking Date and Time</SummaryFieldLabel>

      {booking.date !== null && (
        <Stack direction="column" display="table" sx={{ maxWidth: '250px' }}>
          <Stack direction="row" spacing={2} display="table-row">
            <SummaryFieldText display="table-cell">
              {format(booking.date, 'dd-MMM-yyyy, EEEE')}
            </SummaryFieldText>
            <SummaryFieldText display="table-cell">
              {booking.time}
            </SummaryFieldText>
          </Stack>
        </Stack>
      )}
    </Stack>
  );
}
