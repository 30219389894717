import axios from '@/utils/axios';
import { Review } from '../types';

export async function getAllReviews(status?: string): Promise<Review[]> {
  const {
    data: { data },
  } = await axios.get(
    `/reviews/${status !== 'all' ? `?status=${status}` : ''}`
  );
  return data;
}

export async function getReview(id: number): Promise<Review> {
  const {
    data: { data },
  } = await axios.get(`/reviews/${id}`);
  return data;
}

export async function createReview(review: Partial<Review>): Promise<Review> {
  const {
    data: { data },
  } = await axios.post('/reviews', review);
  return data;
}

export async function updateReview(
  id: number,
  review: Partial<Review>
): Promise<Review> {
  const {
    data: { data },
  } = await axios.put(`/reviews/${id}`, review);
  return data;
}

export async function deleteReview(id: number): Promise<void> {
  await axios.delete(`/reviews/${id}`);
}

export async function getReviewForJob(jobId: number): Promise<Review> {
  const {
    data: { data },
  } = await axios.get(`/reviews/job/${jobId}`);
  return data;
}

export async function getReviewsForClient(
  clientId: number,
  status?: string
): Promise<Review[]> {
  const {
    data: { data },
  } = await axios.get(
    `/reviews/client/${clientId}${status !== 'all' ? `?status=${status}` : ''}`
  );
  return data;
}

export async function getReviewsForCleaner(
  cleanerId: number,
  status?: string
): Promise<Review[]> {
  const {
    data: { data },
  } = await axios.get(
    `/reviews/cleaner/${cleanerId}${
      status !== 'all' ? `?status=${status}` : ''
    }`
  );
  return data;
}
