// @mui
import { TableRow, TableCell, Skeleton } from '@mui/material';

// ----------------------------------------------------------------------

export default function TableLoading() {
  return (
    <TableRow>
      <TableCell colSpan={2}>
        <Skeleton height={70} />
        <Skeleton height={70} />
        <Skeleton height={70} />
      </TableCell>
      <TableCell colSpan={2}>
        <Skeleton height={70} />
        <Skeleton height={70} />
        <Skeleton height={70} />
      </TableCell>
      <TableCell colSpan={2}>
        <Skeleton height={70} />
        <Skeleton height={70} />
        <Skeleton height={70} />
      </TableCell>
      <TableCell colSpan={2}>
        <Skeleton height={70} />
        <Skeleton height={70} />
        <Skeleton height={70} />
      </TableCell>
    </TableRow>
  );
}
