import { useState, useEffect, useMemo } from 'react';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';
import { isEmpty } from 'lodash';
// api
import {
  getCoverLevelData,
  postCoverExtraData,
  updateCoverExtraData,
} from '../api';
// form
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { object, string, number } from 'yup';
// @mui
import { Stack, Grid, Box, Card, Container, Skeleton } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
// hooks
import useSettings from '@/hooks/useSettings';
// components
import { FormProvider, RHFSelect, RHFTextField } from '@/components/hook-form';
// types
import type { CoverExtra } from '../types';
// roots
import { settingsPath } from '../routes/paths';

// ----------------------------------------------------------------------
const STATUS_OPTIONS = ['Active', 'Inactive'];

// PROP TYPES
interface FormValuesProps {
  name: string;
  type_id: number;
  active: string;
}

type Props = {
  currentData?: CoverExtra;
  ID?: number;
};

export default function CoverLevelExtrasCreateEdit({ currentData }: Props) {
  const [isLoading, setIsLoading] = useState(true);
  const [coverOptions, setCoverOptions] = useState<any[]>([]);
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    async function getDataFun() {
      const getCoverData = await getCoverLevelData();
      setCoverOptions(getCoverData);
    }
    setIsLoading(false);
    getDataFun();
  }, []);

  // ----------------------------------------------------------------------
  // REACT HOOK FORM

  const Schema = object().shape({
    name: string().required('Cover level type is required'),
    type_id: number().required('Cover level type is required'),
    active: string().required('Status is required'),
  });

  const defaultValues = useMemo(
    () => ({
      name: currentData?.name || '',
      type_id: currentData?.type_id,
      active: currentData?.active
        ? 'Active'
        : isEmpty(currentData)
        ? 'Active'
        : 'Inactive',
    }),

    [currentData]
  );

  const methods = useForm<FormValuesProps>({
    resolver: yupResolver(Schema),
    defaultValues,
  });

  const {
    reset,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  useEffect(() => {
    if (!isEmpty(currentData)) {
      //console.log(currentData);
      reset(defaultValues);
    }
  }, [currentData, reset, defaultValues]);

  const onSubmit = async (data: FormValuesProps) => {
    try {
      const onSubmitData = {
        name: data.name,
        type_id: Number(data.type_id),
        active: data.active === 'Active' ? true : false,
      };

      !isEmpty(currentData)
        ? await updateCoverExtraData(onSubmitData, currentData?.id)
        : await postCoverExtraData(onSubmitData);
      enqueueSnackbar(
        !isEmpty(currentData)
          ? 'Cover type extra successfully updated!'
          : 'Cover type extra successfully created!'
      );
      navigate(settingsPath('coverExtras'));
    } catch (error) {
      console.error(error);

      enqueueSnackbar('Something went wrong', { variant: 'error' });
    }
  };

  // ----------------------------------------------------------------------
  // Skeleton State

  const { themeStretch } = useSettings();

  return (
    <Container
      maxWidth={themeStretch ? false : 'lg'}
      style={{ position: 'relative' }}
    >
      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <Grid item xs={12}>
          <Card sx={{ p: 3 }}>
            <Box
              sx={{
                display: 'grid',
                columnGap: 2,
                rowGap: 3,
                gridTemplateColumns: {
                  xs: 'repeat(1, 1fr)',
                  sm: 'repeat(1, 1fr)',
                },
              }}
            >
              {isLoading ? (
                <Box pl={2} pt={3}>
                  <Skeleton sx={{ width: 0.98 }} height={80} />
                  <Skeleton sx={{ width: 0.98 }} height={80} />
                </Box>
              ) : (
                <>
                  <Box
                    sx={{
                      display: 'grid',
                      columnGap: 2,
                      rowGap: 3,
                      gridTemplateColumns: {
                        xs: 'repeat(1, 1fr)',
                        sm: 'repeat(2, 1fr)',
                      },
                    }}
                  >
                    <RHFTextField name="name" label="Name" fullWidth />
                    <RHFSelect
                      name="type_id"
                      label="Type"
                      placeholder="Type"
                      InputLabelProps={{
                        shrink: true,
                      }}
                    >
                      <option value="" />
                      {coverOptions.map((option) => (
                        <option key={option.id} value={Number(option.id)}>
                          {option.name}
                        </option>
                      ))}
                    </RHFSelect>
                  </Box>

                  <RHFSelect name="active" label="Status" placeholder="Status">
                    {STATUS_OPTIONS.map((option) => (
                      <option key={option} value={option}>
                        {option}
                      </option>
                    ))}
                  </RHFSelect>
                </>
              )}
            </Box>

            <Stack alignItems="flex-end" sx={{ mt: 3 }}>
              {!isLoading && (
                <LoadingButton
                  type="submit"
                  variant="contained"
                  loading={isSubmitting}
                >
                  {isEmpty(currentData)
                    ? 'Create Cover Level Extra'
                    : 'Save Changes'}
                </LoadingButton>
              )}
            </Stack>
          </Card>
        </Grid>
      </FormProvider>
    </Container>
  );
}
