// @mui
import { alpha, styled } from '@mui/material/styles';
import { Popover, ListItemButton, ListItemButtonProps } from '@mui/material';
// utils
import cssStyles from '../../../utils/cssStyles';
// config
import { NAVBAR } from '../../../config';

// ----------------------------------------------------------------------

export interface ListItemStyleProps extends ListItemButtonProps {
  active: boolean;
  depth: number;
  open: boolean;
}

export const ListItemStyle = styled(ListItemButton, {
  shouldForwardProp: (prop) => prop !== 'active',
})<ListItemStyleProps>(({ active, depth, theme, open }) => ({
  position: 'relative',
  textTransform: 'capitalize',

  margin: theme.spacing(0, 0.5),
  color: theme.palette.text.secondary,
  borderRadius: theme.shape.borderRadius,
  height: NAVBAR.DASHBOARD_ITEM_HORIZONTAL_HEIGHT,
  // Active item
  ...(active && {
    color: theme.palette.primary.main,
    backgroundColor: alpha(
      theme.palette.primary.main,
      theme.palette.action.selectedOpacity
    ),
  }),
  // Active item
  ...(active &&
    depth !== 1 && {
      color: theme.palette.text.primary,
      backgroundColor: 'transparent',
    }),
  // Sub item
  ...(depth && {
    ...(depth > 1 && {
      height: NAVBAR.DASHBOARD_ITEM_SUB_HEIGHT,
    }),
    ...(depth > 2 && {
      paddingLeft: theme.spacing(depth),
    }),
  }),
}));

// ----------------------------------------------------------------------

export const PaperStyle = styled(Popover)(({ theme }) => ({
  pointerEvents: 'none',
  '& .MuiPopover-paper': {
    minWidth: 160,
    pointerEvents: 'auto',
    padding: theme.spacing(1),
    // marginTop: theme.spacing(0.5),
    boxShadow: theme.customShadows.dropdown,
    borderRadius: Number(theme.shape.borderRadius) * 1.5,
    ...cssStyles(theme).bgBlur(),
  },
}));
