import { useNavigate, useParams } from 'react-router';
import { Link as RouterLink } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
//
import { Button, Container, Stack } from '@mui/material';
import { HeaderBreadcrumbs, Iconify, Page } from '@/components';
import {
  ViewBookingHeader,
  ViewBookingMain,
} from '../components/bookings/view';
import { ViewBookingSkeleton } from '../components';
// hooks + utils
import useSettings from '@/hooks/useSettings';
import { useSnackbarMsg } from '@/hooks/useSnackbarMsg';
import { useGetAccess } from '@/hooks/useAccess';
// api + redux
import { getSingleBooking } from '../api';
import { dispatch } from '@/stores';
import { updateStoreWithCurrentBooking } from '../slices';
// routes
import { bookingsManagementPath } from '@/features/bookings-management';
import { PATH_AGENTDASHBOARD } from '@/features/agent-dashboard';

export default function ViewBooking() {
  const { themeStretch } = useSettings();
  const { bookingId } = useParams();
  const navigate = useNavigate();
  const { errorMsg } = useSnackbarMsg();
  const { canUpdate } = useGetAccess('bookings');

  // BOOKINGS
  const { isLoading } = useQuery({
    queryKey: ['bookings', bookingId || ''],
    queryFn: () => getSingleBooking(Number(bookingId)),
    onSuccess: (bookingFromApi) =>
      dispatch(updateStoreWithCurrentBooking(bookingFromApi)),
    onError: (error) => {
      errorMsg(error, `Something went wrong while fetching booking data`);
      navigate(bookingsManagementPath('list'));
    },
    // TODO: REVISIT THIS
    refetchOnWindowFocus: false, // otherwise the booking state in store would get overwritten on windowFocus
    enabled: !!bookingId, // we need the serviceVariants and serviceExtras to be loaded before we can set the default values ==> !!settingsData
  });
  return (
    <Page title="Bookings Management">
      <Container maxWidth={themeStretch ? false : 'lg'}>
        <HeaderBreadcrumbs
          heading="Bookings Management"
          links={[
            { name: 'Dashboard', href: PATH_AGENTDASHBOARD.root },
            {
              name: 'Bookings Management',
              href: `${bookingsManagementPath('list')}`,
            },
            { name: 'View booking' },
          ]}
          action={
            canUpdate && (
              <Button
                variant="contained"
                component={RouterLink}
                to={bookingsManagementPath('edit', {
                  bookingId: String(bookingId),
                })}
                startIcon={<Iconify icon="eva:edit-outline" />}
                disabled={isLoading}
              >
                Edit Booking
              </Button>
            )
          }
        />
        {isLoading ? (
          <ViewBookingSkeleton />
        ) : (
          <Stack spacing={2}>
            <ViewBookingHeader />
            <ViewBookingMain />
          </Stack>
        )}
      </Container>
    </Page>
  );
}
