import { Box, Skeleton, Stack } from '@mui/material';

export default function SearchClientSkeleton() {
  return (
    <>
      <Box sx={{ my: 1, textAlign: 'center', width: '100%' }}>
        Loading clients...
      </Box>
      <Stack direction="column" gap={1}>
        {[...new Array(8)].map((_, index) => (
          <Skeleton variant="rounded" width="100%" height={60} key={index} />
        ))}
      </Stack>
    </>
  );
}
