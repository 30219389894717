import useAuth from './useAuth';
import { useHasPermission } from './useHasPermissions';

export const useGetAccess = (
  module: string
): {
  canView: boolean;
  canCreate: boolean;
  canUpdate: boolean;
  canDelete: boolean;
  canExecute: boolean;
} => {
  const { permissions, isSuperAdmin } = useAuth();
  const canView = useHasPermission(
    [`${module}.view`],
    permissions,
    isSuperAdmin
  );

  const canCreate = useHasPermission(
    [`${module}.create`],
    permissions,
    isSuperAdmin
  );
  const canUpdate = useHasPermission(
    [`${module}.update`],
    permissions,
    isSuperAdmin
  );
  const canDelete = useHasPermission(
    [`${module}.delete`],
    permissions,
    isSuperAdmin
  );
  const canExecute = useHasPermission(
    [`${module}.execute`],
    permissions,
    isSuperAdmin
  );

  return {
    canView: canView.isAllowed,
    canCreate: canCreate.isAllowed,
    canUpdate: canUpdate.isAllowed,
    canDelete: canDelete.isAllowed,
    canExecute: canExecute.isAllowed,
  };
};
