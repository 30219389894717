import { useSnackbarMsg } from '@/hooks/useSnackbarMsg';
import { useParams } from 'react-router';
import { useForm, useWatch } from 'react-hook-form';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
//
import { Button, Card, MenuItem, Skeleton, Stack } from '@mui/material';
import { LoadingButton } from '@mui/lab';
//
import { FormProvider, RHFTextField } from '@/components/hook-form';
import {
  ProviderEditTimeOffSkeleton,
  TimeOffOneOffRequest,
  TimeOffRecurringRequest,
} from '..';
import { SectionTitle } from '@/features/users/shared/components';
// api
import {
  createProviderTimeOff,
  getProviderDayOff,
  updateProviderTimeOff,
} from '../../api';
import { getSettingsData } from '@/features/app/api';
// utils
import {
  formatDaysOfWeekFromApi,
  formatRepeatString,
  localZoominEffext,
  TIME_OFF_DEFAULT_VALUES,
} from '../../utils';
// types
import { DayOff, TimeOffFormValues } from '../../types';

type ReqProps = {
  editMode?: boolean;
  currentTimeOffId?: string | null;
  exitEditMode: () => void;
};

// =======================================================

export default function ProviderTimeOffRequest({
  editMode,
  currentTimeOffId,
  exitEditMode,
}: ReqProps) {
  const { userId } = useParams();
  const { successMsg, errorMsg } = useSnackbarMsg();

  // =================
  //  REACT HOOK FORM
  // =================

  const methods = useForm({
    defaultValues: TIME_OFF_DEFAULT_VALUES,
  });
  const { control, reset, handleSubmit } = methods;

  const { request_type_id, recurrence_type } = useWatch({
    control,
  });

  // =================
  //      QUERIES
  // =================

  const queryClient = useQueryClient();

  // FETCH
  const { isLoading: isTimeOffDataLoading } = useQuery({
    queryKey: ['providerTimeoff', currentTimeOffId],
    queryFn: () => getProviderDayOff(String(userId), String(currentTimeOffId)),
    onSuccess: (data) => {
      reset({
        request_type_id: String(data.type_id),
        start_date: data.start_date,
        end_date: data.end_date,
        recurrence_type: data.offday_recurrence ? 'Recurring' : 'One-off',
        timeoff_request_notes: data.notes,
        recurrence_days_of_week: data.offday_recurrence
          ? formatDaysOfWeekFromApi(data.offday_recurrence.week_days)
          : [],
        recurrence_repeat_string: formatRepeatString(data.offday_recurrence),
        recurrence_from_date: data?.offday_recurrence
          ? data?.offday_recurrence.from_date
          : '',
        // TODO: test this
        recurrence_until:
          data?.offday_recurrence?.until === null
            ? ''
            : data?.offday_recurrence?.until,
      });
    },
    onError: (error) => {
      errorMsg(error, `Something went wrong while fetching days off`);
    },
    enabled: !!userId && !!currentTimeOffId,
  });

  // POST
  const createTimeOffRequest = useMutation({
    mutationFn: ({ id, timeoffBaseDto }: { id: string; timeoffBaseDto: any }) =>
      createProviderTimeOff(id, timeoffBaseDto),
    onSuccess: (_apiResponse, variables) => {
      queryClient.setQueryData(
        ['providerTimeoff', userId],
        (old: DayOff[] = []) => [...old, variables.timeoffBaseDto]
      );
      queryClient.invalidateQueries(['providerTimeoff', userId]);
      successMsg(`New request successfully created`);
      handleExitEditMode();
    },
    onError: (error) => {
      errorMsg(error, `There was an error while submitting request`);
    },
  });

  // PUT
  const updateTimeOffRequest = useMutation({
    mutationFn: ({
      userId,
      timeOffId,
      timeoffBaseDto,
    }: {
      userId: string;
      timeOffId: string;
      timeoffBaseDto: any;
    }) => updateProviderTimeOff(userId, timeOffId, timeoffBaseDto),
    onSuccess: (_apiResponse, variables) => {
      queryClient.invalidateQueries(['providerTimeoff', userId]);
      successMsg(`Request successfully updated`);
      handleExitEditMode();
    },
    onError: (error) => {
      errorMsg(error, `There was an error while updating request`);
    },
  });

  // SETTINGS
  const { data: timeOffRequestTypes, isLoading: isTimeOffRequestTypesLoading } =
    useQuery({
      queryKey: ['settings'],
      queryFn: () => getSettingsData(),
      onError: (error) => {
        errorMsg(
          error,
          `Something went wrong while fetching time-off request types`
        );
      },
      select: (data) => data?.offDaysRequestTypes,
    });

  // ================
  //      SUBMIT
  // ================

  const onSubmitTimeoffRequest = async (data: TimeOffFormValues) => {
    const timeoffBaseDto = {
      status: 'approved', // other options: 'rejected', 'pending'
      type_id: Number(data.request_type_id),
      start_date: data.start_date,
      end_date: data.end_date,
      notes: data.timeoff_request_notes,
    };
    if (recurrence_type === 'Recurring') {
      const formatted_repeat_string =
        data.recurrence_repeat_string.toLowerCase() === 'fortnightly'
          ? 'weekly'
          : data.recurrence_repeat_string.toLowerCase();
      Object.assign(timeoffBaseDto, {
        offday_recurrence: {
          repeat_string: formatted_repeat_string,
          week_days: data.recurrence_days_of_week
            .map((day) => day.recurrence_short_name)
            .join(', '),
          from_date: data.recurrence_from_date,
          until: !!data.recurrence_until ? data.recurrence_until : null,
          interval_number:
            data.recurrence_repeat_string.toLowerCase() === 'fortnightly'
              ? 2
              : 1,
        },
      });
    }

    editMode
      ? updateTimeOffRequest.mutate({
          userId: String(userId),
          timeOffId: String(currentTimeOffId),
          timeoffBaseDto,
        })
      : createTimeOffRequest.mutate({ id: String(userId), timeoffBaseDto });
  };
  const onFormError = (errors: any) => console.error(errors);

  const handleExitEditMode = () => {
    exitEditMode();
    reset(TIME_OFF_DEFAULT_VALUES);
  };

  // =========================

  return (
    <FormProvider
      methods={methods}
      onSubmit={handleSubmit(onSubmitTimeoffRequest, onFormError)}
    >
      <Stack
        direction="column"
        spacing={3}
        component={Card}
        sx={{
          p: 2,
          minWidth: 250,
          border: editMode ? '2px solid blue' : 'none',
          animation: editMode ? `${localZoominEffext} 1s` : 'none',
          animationDelay: editMode ? '0.5s' : 'none',
        }}
      >
        <SectionTitle title={editMode ? 'Edit Time-off' : 'Request Time-off'} />

        {editMode && isTimeOffDataLoading ? (
          <ProviderEditTimeOffSkeleton />
        ) : (
          <>
            {/* REQUEST TYPE */}
            {!isTimeOffRequestTypesLoading ? (
              <RHFTextField label="Request Type" name="request_type_id" select>
                {(timeOffRequestTypes || []).map((option) => (
                  // id: 1 - Holiday, 2 - Not available, 3 - Sick leave
                  <MenuItem key={option.id} value={option.id}>
                    {option.description}
                  </MenuItem>
                ))}
              </RHFTextField>
            ) : (
              <Skeleton variant="rounded" height={40} />
            )}

            {/* HOLIDAY */}
            {(Number(request_type_id) === 1 ||
              Number(request_type_id) === 3) && <TimeOffOneOffRequest />}

            {/* NOT AVAILABLE */}
            {Number(request_type_id) === 2 && (
              <>
                <RHFTextField
                  label="Recurrence type"
                  name="recurrence_type"
                  select
                >
                  {['One-off', 'Recurring'].map((option) => (
                    <MenuItem key={option} value={option}>
                      {option}
                    </MenuItem>
                  ))}
                </RHFTextField>
                {recurrence_type === 'One-off' && <TimeOffOneOffRequest />}
                {recurrence_type === 'Recurring' && <TimeOffRecurringRequest />}
              </>
            )}

            <Stack direction="column" spacing={1}>
              {editMode && (
                <Button
                  variant="outlined"
                  color="inherit"
                  onClick={() => handleExitEditMode()}
                >
                  Cancel
                </Button>
              )}
              <LoadingButton
                variant="contained"
                type="submit"
                loading={createTimeOffRequest.isLoading}
              >
                {editMode ? 'Save changes' : 'Submit Request'}
              </LoadingButton>
            </Stack>
          </>
        )}
      </Stack>
    </FormProvider>
  );
}
