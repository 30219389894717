import { BaseObjectEntity } from '@/types';
import { path } from '@/utils/formatLink';

export const ROOTS_AGENTDASHBOARD = '/agent-dashboard';

export const PATH_AGENTDASHBOARD: BaseObjectEntity = {
  root: ROOTS_AGENTDASHBOARD,
};

export function dashboardPath(
  pathKey: string,
  key: string = '',
  value: number | string = ''
) {
  return key
    ? path(ROOTS_AGENTDASHBOARD, PATH_AGENTDASHBOARD[pathKey]).replace(
        `:${key}`,
        String(value)
      )
    : path(ROOTS_AGENTDASHBOARD, PATH_AGENTDASHBOARD[pathKey]);
}
