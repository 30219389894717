import RHFSwitch from '@/components/hook-form/RHFSwitchWithText';
import { Card, Stack, Typography } from '@mui/material';
import { SectionTitle } from '@/features/users/shared/components';

export default function ClientAddressPlaceholder() {
  return (
    <Stack component={Card} spacing={1} sx={{ p: 3, mt: 2 }}>
      <SectionTitle title="Client Address" />
      <Typography fontSize={14}>
        Addresses only can be added to existing users.
      </Typography>
      <RHFSwitch
        name="return_to_edit_user"
        label="Return to this page to add address once the client has been created"
      />
    </Stack>
  );
}
