import { useEffect, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
// mobiscroll
import {
  Datepicker,
  MbscDatepickerChangeEvent,
  MbscDatepickerOptions,
  MbscDatepickerValue,
} from '@mobiscroll/react';
// mui
import { FormControl, TextField, TextFieldProps } from '@mui/material';

type RHFMobiscrollDateTimePickerProps = {
  name: string;
  label: string;
  value?: string | Date;
  disabled?: boolean;
  min?: string;
  max?: string;
  //
  onChange?: (value: any) => void;
  mbscProps: MbscDatepickerOptions;
};

export default function RHFMobiscrollDateTimePicker({
  name,
  label,
  value,
  disabled = false,
  onChange,
  //
  mbscProps,
  min,
  max,
  ...other
}: RHFMobiscrollDateTimePickerProps) {
  const { control, setValue, getValues } = useFormContext();
  const [pickerValue, setPickerValue] = useState<MbscDatepickerValue | null>(
    null
  );
  const formPickerValue = getValues(name);

  const pickerChange = (event: MbscDatepickerChangeEvent) => {
    setPickerValue(event.value);
    setValue(name, event.value, { shouldDirty: true });
  };

  useEffect(() => {
    if (value) {
      setPickerValue(new Date(value));
    } else {
      setPickerValue(!!formPickerValue ? new Date(formPickerValue) : null);
    }
  }, [value, formPickerValue]);

  return (
    <FormControl sx={{ width: '100%' }}>
      <Controller
        control={control}
        name={name}
        render={({ field, fieldState: { error } }) => {
          field.value && (error = undefined);
          return (
            <Datepicker
              theme="ios"
              themeVariant="light"
              select={mbscProps.select}
              label={label}
              disabled={disabled}
              min={min}
              {...mbscProps}
              value={pickerValue}
              onChange={(newValue) => pickerChange(newValue)}
              renderInput={(params: TextFieldProps) => (
                <TextField
                  {...params}
                  error={!!error}
                  helperText={error?.message}
                  {...other}
                />
              )}
            />
          );
        }}
      />
    </FormControl>
  );
}
