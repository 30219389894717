import { string, number, array, object } from 'yup';
import { tableSortFilter } from '@/utils/arrayProcessing';
import { Variant } from '../types';

export function applySortFilterVariants({
  tableData,
  comparator,
  filterName,
  filterService,
  filterStatus,
}: {
  tableData: Variant[];
  comparator: (a: any, b: any) => number;
  filterName: string;
  filterService: string;
  filterStatus: string;
}) {
  tableData = tableSortFilter(tableData, comparator);

  if (filterName) {
    tableData = tableData.filter(
      (item) =>
        item.name.toLowerCase().indexOf(filterName.toLowerCase()) !== -1 ||
        item.price.toString().indexOf(filterName.toLowerCase()) !== -1 ||
        item.service.name.toLowerCase().indexOf(filterName.toLowerCase()) !==
          -1 ||
        item.service_reccurence.name
          .toLowerCase()
          .indexOf(filterName.toLowerCase()) !== -1
    );
  }

  if (filterService.toLowerCase() !== 'all') {
    tableData = tableData.filter(
      (item: Record<string, any>) =>
        item.service.name.toLowerCase() === filterService.toLowerCase()
    );
  }

  if (filterStatus.toLowerCase() !== 'all') {
    tableData = tableData.filter((item) => {
      const currentStatus = item.active ? 'active' : 'inactive';
      return currentStatus === filterStatus.toLowerCase();
    });
  }

  return tableData;
}

export const VARIANTS_TABLE_HEAD = [
  { id: 'emptyCell' },
  { id: 'name', label: 'Name', align: 'left' },
  { id: 'service', label: 'Service', align: 'left' },
  { id: 'price', label: 'Price', align: 'left' },
  { id: 'service_reccurence', label: 'Recurrence', align: 'left' },
  { id: 'active', label: 'Status', align: 'left' },
  { id: 'actionBtns' },
];

export const variantFormFieldDefaultRow = {
  name: '',
  type: '',
  ref: '',
  min_value: '',
  max_value: '',
  increment_value: '',
};

export const variantFormDefaultValues = {
  variant_name: '',
  service_type: '',
  price: '',
  service_recurrence: '',
  status: 'Active',
  description: '',
  service_form_fields: [],
};

export const VariantSchema = object().shape({
  variant_name: string().required('Name is required'),
  service_type: string().required('Service type is required'),
  price: number()
    .typeError('Price must be a number greater than 0')
    .min(1)
    .required('Price is required'),
  service_recurrence: string().required('Recurrence type is required'),
  status: string().required('Status is required'),
  description: string().required('Description is required'),
  service_form_fields: array().of(
    object().shape({
      type: string().required('Type is required'),
      ref: string().required('Ref is required'),
      name: string().required('Name is required'),
      min_value: number()
        .typeError('Value must be a number greater than 0')
        .min(0.1)
        .required('Min value is required'),
      max_value: number()
        .typeError('Value must be a number greater than 0')
        .min(0.1)
        .required('Max value is required'),
      increment_value: number()
        .typeError('Value must be a number greater than 0')
        .min(0.1)
        .required('Increment value is required'),
    })
  ),
});
