import { ButtonColors } from '@/types';

export const createDialog = (
  name: string,
  actionVerb: string,
  title: string,
  loadingButtonColor: ButtonColors,
  agreeFunction: () => void
) => ({
  name,
  actionVerb,
  title,
  loadingButtonColor,
  disagreeButtonText: 'Cancel',
  agreeFunction,
});
