// generate range of array with given increment
export const generateArray = (start: number, end: number, step: number) => {
  const length = (end - start) / step + 1;
  const array = Array.from({ length: length }, (_, i) => start + i * step);
  return array;
};

export const generateArrayOfHoursObject = (
  start: number,
  end: number,
  step: number
) => {
  const arrayToReturn = [];
  for (let i = start, id = 1; i < end + step; i += step, id++) {
    const objectToPush = {
      id: id,
      numberOfHours: i,
      selected: i === start ? true : false,
    };
    arrayToReturn.push(objectToPush);
  }
  return arrayToReturn;
};
