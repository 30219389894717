import { useState, MouseEvent } from 'react';
import { Link as RouterLink } from 'react-router-dom';
// mui
import { useTheme } from '@mui/material/styles';
import {
  Box,
  Button,
  IconButton,
  MenuItem,
  Stack,
  TableCell,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material';
// components
import { Iconify, Label, SvgIconWrapper } from '@/components';
import { TableMoreMenu, TableRowLink } from '@/components/table';
import DialogSimple from '@/components/dialog/DialogSimple';
// hooks + utils
import useToggle from '@/hooks/useToggle';
import { servicesManagementPath } from '../routes/paths';
// types
import { CleaningExtra, Service, ServiceVariant } from '../types';
import { LabelColors } from '@/types';

type Props = {
  row: Service;
  onEditRow: VoidFunction;
  onSetStatusRow: VoidFunction;
  canDelete: boolean;
  canUpdate: boolean;
};

// ----------------------------------------------------------------------

export default function ServicesTableRow({
  row,
  onEditRow,
  onSetStatusRow,
  canDelete,
  canUpdate,
}: Props) {
  const theme = useTheme();

  const { id, name, variants, cleaning_extras, active } = row;

  // ===================
  //      3DOT MENU
  // ===================

  const [openMenu, setOpenMenuActions] = useState<HTMLElement | null>(null);

  const handleOpenMenu = (event: MouseEvent<HTMLElement>) => {
    setOpenMenuActions(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setOpenMenuActions(null);
  };

  // ========================
  //      CHANGE STATUS
  // ========================
  const {
    toggle: isDialogOpen,
    onOpen: openDialog,
    onClose: closeDialog,
  } = useToggle();

  const handleStatusChange = () => {
    onSetStatusRow();
    closeDialog();
  };

  const messageText = active ? 'deactivate' : 'activate';
  const agreeButtonText = active ? 'Deactivate' : 'Activate';
  const agreeButtonColor = active ? 'error' : 'success';
  const evaIcon = active ? 'eva:trash-2-outline' : 'eva:checkmark-outline';

  return (
    <>
      <DialogSimple
        dialogTitle={`Change service status`}
        open={isDialogOpen}
        onClickButtonClose={closeDialog}
        onClickButtonLoading={handleStatusChange}
        loadingButton={true}
        disagreeButton={true}
        loadingButtonText={agreeButtonText}
        loadingButtonColor={agreeButtonColor}
        disagreeButtonText="Cancel"
        disagreeButtonColor="inherit"
      >
        <Box sx={{ px: 3 }}>
          <Typography variant="body2" sx={{ mb: 2 }}>
            Are you sure you want to {messageText} this service:{' '}
            <span style={{ fontWeight: 600 }}>{name}</span>?
          </Typography>
        </Box>
      </DialogSimple>

      <TableRow hover sx={{ verticalAlign: 'center', px: 5 }}>
        <TableCell sx={{ width: '0.2rem' }} />
        <TableCell align="left">
          <TableRowLink
            linkText={name}
            linkTo={servicesManagementPath('editService', {
              serviceId: String(id),
            })}
          />
        </TableCell>

        <TableCell align="left">
          <Stack direction="row" spacing={1}>
            {variants.map((variant: ServiceVariant, index: number) => (
              <Button
                key={index}
                variant="outlined"
                size="small"
                sx={{ whiteSpace: 'nowrap' }}
                component={RouterLink}
                to={servicesManagementPath('editVariant', {
                  variantId: String(id),
                  tabSid: 'details',
                })}
              >
                {variant.name}
              </Button>
            ))}
          </Stack>
        </TableCell>

        <TableCell align="left">
          <Stack direction="row" spacing={1}>
            {cleaning_extras.map((e: CleaningExtra, index: number) => (
              <Tooltip title={e.name} key={index}>
                <IconButton
                  sx={{
                    '&:hover': {
                      cursor: 'default',
                    },
                  }}
                >
                  <SvgIconWrapper
                    iconUrl={e.icon ?? ''}
                    width={20}
                    height={20}
                  />
                </IconButton>
              </Tooltip>
            ))}
          </Stack>
        </TableCell>

        <TableCell align="left">
          <Label
            variant={theme.palette.mode === 'light' ? 'ghost' : 'filled'}
            color={active ? 'success' : ('error' as LabelColors)}
            sx={{ textTransform: 'capitalize', p: 1.5, fontSize: '0.8rem' }}
          >
            {active ? 'Active' : 'Inactive'}
          </Label>
        </TableCell>

        <TableCell align="right">
          {(canDelete || canUpdate) && (
            <TableMoreMenu
              open={openMenu}
              onOpen={handleOpenMenu}
              onClose={handleCloseMenu}
              actions={
                <>
                  {canUpdate && (
                    <MenuItem
                      onClick={() => {
                        onEditRow();
                        handleCloseMenu();
                      }}
                    >
                      <Iconify icon={'eva:edit-fill'} />
                      Edit
                    </MenuItem>
                  )}
                  {canDelete && (
                    <MenuItem
                      onClick={() => {
                        openDialog();
                        handleCloseMenu();
                      }}
                      sx={{ color: `${agreeButtonColor}.main` }}
                    >
                      <Iconify icon={evaIcon} />
                      {agreeButtonText}
                    </MenuItem>
                  )}
                </>
              }
            />
          )}
        </TableCell>
      </TableRow>
    </>
  );
}
