import { object, boolean, string } from 'yup';

export const NEW_DASHBOARD_USER_SCHEMA = object().shape({
  first_name: string().required('Name is required'),
  last_name: string().required('Name is required'),
  email: string().required('Email is required').email(),
  status: string().required('Status is required'),
  is_chat_user: boolean(),
});

export const NEW_DASH_USER_DEFAULT_VALUES = {
  first_name: '',
  last_name: '',
  email: '',
  phone_number: '',
  profile_image: '',
  gender: '',
  is_chat_user: false,
  status: '',
  user_roles: [],
  job_title: '',
  short_description: '',
};
