import { useFormContext, useWatch } from 'react-hook-form';
import { MbscDatepickerOptions } from '@mobiscroll/react';
import { Box, Stack, useTheme } from '@mui/material';
//
import { RHFMobiscrollDateTimePicker } from '@/components/hook-form';
import { TimeoffRequestNotes } from '../..';
// utils + hooks
import { timePickerSx } from '@/features/users/providers/utils';

export default function TimeOffOneOffRequest() {
  const theme = useTheme();

  const { control } = useFormContext();
  const { start_date, end_date } = useWatch({ control });

  const dateTimePickerConfig = {
    controls: ['date', 'time'],
    display: 'anchored',
    touchUi: true,
    minTime: '06:00',
    maxTime: '23:00',
    stepMinute: 60,
    animation: 'pop',
    timeFormat: 'HH:mm',
  };

  return (
    <Stack spacing={2}>
      <Box sx={timePickerSx(theme)}>
        <RHFMobiscrollDateTimePicker
          name="start_date"
          label="Start"
          max={end_date}
          mbscProps={dateTimePickerConfig as MbscDatepickerOptions}
        />
      </Box>

      <Box sx={timePickerSx(theme)}>
        <RHFMobiscrollDateTimePicker
          name="end_date"
          label="End"
          min={start_date}
          mbscProps={dateTimePickerConfig as MbscDatepickerOptions}
        />
      </Box>
      <TimeoffRequestNotes />
    </Stack>
  );
}
