import { Navigate, Route, Routes } from 'react-router-dom';
import DashboardLayout from '@/layouts/dashboard';
import AuthGuard from '@/guards/AuthGuard';
import { PATH_SETTINGS, ROOTS_SETTINGS } from './paths';
import {
  ActivityTypesCreate,
  SettingsList,
  ActivityTypesList,
  ProviderList,
  ProviderCreate,
  CategoryList,
  CategoryCreate,
  CoverTypeList,
  CoverTypeCreate,
  CoverExtrasList,
  CoverExtrasCreate,
  EmailTemplates,
  ViewEmailTemplates,
  EmailTemplateForm,
} from '../pages';
import { SideNavigation } from '../components';
import RoleBasedGuard from '@/guards/RoleBasedGuard';

export const SettingsRoutes = () => (
  <Routes>
    <Route
      element={
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      }
    >
      <Route element={<SideNavigation />}>
        <Route
          index
          element={
            <Navigate to={`${ROOTS_SETTINGS}${PATH_SETTINGS.company}`} />
          }
        />
        <Route
          index
          element={
            <RoleBasedGuard roles={['settings.view']}>
              <SettingsList />
            </RoleBasedGuard>
          }
        />
        <Route
          path={PATH_SETTINGS.system}
          element={
            <RoleBasedGuard roles={['settings.view']}>
              <SettingsList />
            </RoleBasedGuard>
          }
        />

        <Route
          path={PATH_SETTINGS.leads}
          element={
            <RoleBasedGuard roles={['settings.view']}>
              <ActivityTypesList />
            </RoleBasedGuard>
          }
        />
        <Route
          path={PATH_SETTINGS['new-diary-category']}
          element={
            <RoleBasedGuard roles={['settings.create']}>
              <ActivityTypesCreate />
            </RoleBasedGuard>
          }
        />
        <Route
          path={PATH_SETTINGS['edit-diary-category']}
          element={
            <RoleBasedGuard roles={['settings.update']}>
              <ActivityTypesCreate />
            </RoleBasedGuard>
          }
        />

        <Route
          path={PATH_SETTINGS.templates}
          element={
            <RoleBasedGuard roles={['settings.view']}>
              <EmailTemplates />
            </RoleBasedGuard>
          }
        />
        <Route
          path={PATH_SETTINGS['new-template']}
          element={
            <RoleBasedGuard roles={['settings.create']}>
              <EmailTemplateForm />
            </RoleBasedGuard>
          }
        />
        <Route
          path={PATH_SETTINGS['edit-template']}
          element={
            <RoleBasedGuard roles={['settings.update']}>
              <EmailTemplateForm />
            </RoleBasedGuard>
          }
        />
        <Route
          path={PATH_SETTINGS['view-template']}
          element={
            <RoleBasedGuard roles={['settings.view']}>
              <ViewEmailTemplates />
            </RoleBasedGuard>
          }
        />

        <Route
          path={PATH_SETTINGS.providers}
          element={
            <RoleBasedGuard roles={['settings.view']}>
              <ProviderList />
            </RoleBasedGuard>
          }
        />
        <Route
          path={PATH_SETTINGS['new-providers']}
          element={
            <RoleBasedGuard roles={['settings.create']}>
              <ProviderCreate />
            </RoleBasedGuard>
          }
        />
        <Route
          path={PATH_SETTINGS['edit-providers']}
          element={
            <RoleBasedGuard roles={['settings.update']}>
              <ProviderCreate />
            </RoleBasedGuard>
          }
        />

        <Route
          path={PATH_SETTINGS.categories}
          element={
            <RoleBasedGuard roles={['settings.view']}>
              <CategoryList />
            </RoleBasedGuard>
          }
        />
        <Route
          path={PATH_SETTINGS['new-categories']}
          element={
            <RoleBasedGuard roles={['settings.create']}>
              <CategoryCreate />
            </RoleBasedGuard>
          }
        />
        <Route
          path={PATH_SETTINGS['edit-categories']}
          element={
            <RoleBasedGuard roles={['settings.update']}>
              <CategoryCreate />
            </RoleBasedGuard>
          }
        />

        <Route
          path={PATH_SETTINGS.coverLevel}
          element={
            <RoleBasedGuard roles={['settings.view']}>
              <CoverTypeList />
            </RoleBasedGuard>
          }
        />
        <Route
          path={PATH_SETTINGS['new-cover-level']}
          element={
            <RoleBasedGuard roles={['settings.create']}>
              <CoverTypeCreate />
            </RoleBasedGuard>
          }
        />
        <Route
          path={PATH_SETTINGS['edit-cover-level']}
          element={
            <RoleBasedGuard roles={['settings.update']}>
              <CoverTypeCreate />
            </RoleBasedGuard>
          }
        />

        <Route
          path={PATH_SETTINGS.coverExtras}
          element={
            <RoleBasedGuard roles={['settings.view']}>
              <CoverExtrasList />
            </RoleBasedGuard>
          }
        />
        <Route
          path={PATH_SETTINGS['new-cover-extras']}
          element={
            <RoleBasedGuard roles={['settings.create']}>
              <CoverExtrasCreate />
            </RoleBasedGuard>
          }
        />
        <Route
          path={PATH_SETTINGS['edit-cover-extras']}
          element={
            <RoleBasedGuard roles={['settings.update']}>
              <CoverExtrasCreate />
            </RoleBasedGuard>
          }
        />
      </Route>
    </Route>
  </Routes>
);
