import { useState } from 'react';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { uniq } from 'lodash';
// mui
import {
  Box,
  Button,
  Card,
  Container,
  Divider,
  FormControlLabel,
  Switch,
  Tab,
  Tabs,
  Table,
  TableBody,
  TableContainer,
  TablePagination,
} from '@mui/material';
//
import { HeaderBreadcrumbs, Iconify, Page, Scrollbar } from '@/components';
import { ToolbarWithSearchAndStatus } from '@/components/toolbar';
import {
  TableEmptyRows,
  TableHeadCustom,
  TableNoData,
} from '@/components/table';
// custom components
import { VariantsTableRow } from '../components';
import { SkeletonLoadingTableData } from '@/components/skeleton';
// API
import { getVariants, deleteVariant } from '../api';
//  hooks + utils
import { useGetAccess } from '@/hooks/useAccess';
import useTabs from '@/hooks/useTabs';
import useSettings from '@/hooks/useSettings';
import useTable, { getComparator, emptyRows } from '@/hooks/useTable';
import { useSnackbarMsg } from '@/hooks/useSnackbarMsg';
//
import { VARIANTS_TABLE_HEAD, applySortFilterVariants } from '../utils';
import { servicesManagementPath } from '../routes/paths';

// ----------------------------------------------------------------------

export default function ServiceVariantsList() {
  const {
    dense,
    page,
    order,
    orderBy,
    rowsPerPage,
    setPage,
    //
    selected,
    //
    onSort,
    onChangeDense,
    onChangePage,
    onChangeRowsPerPage,
  } = useTable();

  const { themeStretch } = useSettings();
  const navigate = useNavigate();
  const { errorMsg, successMsg } = useSnackbarMsg();
  const queryClient = useQueryClient();
  const allow = useGetAccess('users');
  const STATUS_OPTIONS = ['Active', 'Inactive', 'All'];
  const [serviceOptions, setServiceOptions] = useState<string[]>(['all']);

  // =================
  //      QUERIES
  // =================

  const { data: serviceVariants, isLoading: isVariantDataLoading } = useQuery({
    queryKey: ['serviceVariants'],
    queryFn: getVariants,
    onSuccess: (data) => {
      setServiceOptions((prev) => [
        ...prev,
        ...uniq(data.map((v) => v.service.name)),
      ]);
    },
    onError: (error) => {
      errorMsg(error, `Something went wrong while fetching service variants`);
    },
  });

  // DELETE
  const deleteVariantMutation = useMutation({
    mutationFn: (id: number) => deleteVariant(id),
    onSuccess: (variant) => {
      queryClient.invalidateQueries(['serviceVariants', variant.data.id]);
      successMsg('Variant successfully deleted!');
    },
    onError: (error) =>
      errorMsg(error, `Something went wrong while deleting variant`),
  });

  // =======================
  //      FILTER / EDIT
  // =======================

  const [filterName, setFilterName] = useState('');
  const [filterService, setFilterService] = useState('all');

  const { currentTab: filterStatus, onChangeTab: onChangeFilterStatus } =
    useTabs('Active');

  const handleFilterName = (filterName: string) => {
    setFilterName(filterName);
    setPage(0);
  };

  const handleFilterService = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFilterService(event.target.value);
  };

  const handleEditRow = (id: number) =>
    navigate(
      servicesManagementPath('editVariant', {
        variantId: String(id),
        tabSid: 'details',
      })
    );

  const dataFiltered = applySortFilterVariants({
    tableData: serviceVariants || [],
    comparator: getComparator(order, orderBy),
    filterName,
    filterService,
    filterStatus,
  });

  const denseHeight = dense ? 52 : 72;

  const isNotFound =
    (!dataFiltered.length && !!filterName) ||
    (!dataFiltered.length && !!filterService);

  return (
    <Page title="Service Variants">
      <Container maxWidth={themeStretch ? false : 'lg'}>
        <HeaderBreadcrumbs
          heading="Service Variants"
          links={[
            { name: 'Dashboard', href: '' },
            { name: 'Services', href: servicesManagementPath('services') },
            { name: 'Service Variants' },
          ]}
          action={
            <Button
              variant="contained"
              component={RouterLink}
              to={servicesManagementPath('newVariant')}
              startIcon={<Iconify icon={'eva:plus-fill'} />}
            >
              Add Variant
            </Button>
          }
        />

        <Card>
          {isVariantDataLoading ? (
            <SkeletonLoadingTableData />
          ) : (
            <>
              <Tabs
                allowScrollButtonsMobile
                variant="scrollable"
                scrollButtons="auto"
                value={filterStatus}
                onChange={onChangeFilterStatus}
                sx={{ px: 2, bgcolor: 'background.neutral' }}
              >
                {STATUS_OPTIONS.map((tab) => (
                  <Tab disableRipple key={tab} label={tab} value={tab} />
                ))}
              </Tabs>

              <Divider />

              <ToolbarWithSearchAndStatus
                filterText={filterName}
                onFilterText={handleFilterName}
                filterStatus={filterService}
                onFilterStatus={handleFilterService}
                status={serviceOptions}
                labels={{
                  search: 'Search...',
                  status: 'Service',
                }}
              />

              <Scrollbar>
                <TableContainer sx={{ minWidth: 800, position: 'relative' }}>
                  <Table size={dense ? 'small' : 'medium'}>
                    <TableHeadCustom
                      order={order}
                      orderBy={orderBy}
                      headLabel={VARIANTS_TABLE_HEAD}
                      rowCount={dataFiltered.length}
                      numSelected={selected.length}
                      onSort={onSort}
                    />
                    <TableBody>
                      {dataFiltered
                        .slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                        .map((variant, index) => (
                          <VariantsTableRow
                            key={index}
                            row={variant}
                            onDeleteRow={() =>
                              deleteVariantMutation.mutate(variant.id)
                            }
                            onEditRow={() => handleEditRow(variant.id)}
                            canDelete={allow.canDelete}
                            canUpdate={allow.canUpdate}
                          />
                        ))}

                      <TableEmptyRows
                        height={denseHeight}
                        emptyRows={emptyRows(
                          page,
                          rowsPerPage,
                          dataFiltered.length
                        )}
                      />

                      <TableNoData isNotFound={isNotFound} />
                    </TableBody>
                  </Table>
                </TableContainer>
              </Scrollbar>

              <Box sx={{ position: 'relative' }}>
                <TablePagination
                  rowsPerPageOptions={[5, 10, 25]}
                  component="div"
                  count={dataFiltered.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={onChangePage}
                  onRowsPerPageChange={onChangeRowsPerPage}
                />

                <FormControlLabel
                  control={<Switch checked={dense} onChange={onChangeDense} />}
                  label="Dense"
                  sx={{ px: 3, py: 1.5, top: 0, position: { md: 'absolute' } }}
                />
              </Box>
            </>
          )}
        </Card>
      </Container>
    </Page>
  );
}
