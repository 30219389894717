import { BaseObjectEntity } from '@/types';
import { path } from '@/utils/formatLink';

export const ROOTS_SETTINGS = '/settings';

export const PATH_SETTINGS: BaseObjectEntity = {
  root: ROOTS_SETTINGS,
  company: '/dynamic/company/1',
  system: '/dynamic/company/:id',
  leads: '/diary/activity-category',
  'new-diary-category': '/diary/activity-category/new',
  'edit-diary-category': '/diary/activity-category/:id/edit',
  activityActions: '/diary/activity-actions',
  'new-diary-action': '/diary/activity-actions/new',
  'edit-diary-action': '/diary/activity-actions/:id/edit',
  templates: '/email-templates',
  'new-template': '/email-templates/new',
  'edit-template': '/email-templates/:id',
  'view-template': '/email-templates/:id/view',
  providers: '/products/providers',
  'new-providers': '/products/providers/new',
  'edit-providers': '/products/providers/:id/edit',
  categories: '/products/categories',
  'new-categories': '/products/categories/new',
  'edit-categories': '/products/categories/:id/edit',
  coverLevel: '/products/cover-level-types',
  'new-cover-level': '/products/cover-level-types/new',
  'edit-cover-level': '/products/cover-level-types/:id/edit',
  coverExtras: '/products/cover-extras',
  'new-cover-extras': '/products/cover-extras/new',
  'edit-cover-extras': '/products/cover-extras/:id/edit',
  comission: '/commission-percentage',
};

export function settingsPath(
  pathKey: string,
  key: string = '',
  value: number | string = ''
) {
  return key
    ? path(ROOTS_SETTINGS, PATH_SETTINGS[pathKey]).replace(
        `:${key}`,
        String(value)
      )
    : path(ROOTS_SETTINGS, PATH_SETTINGS[pathKey]);
}
