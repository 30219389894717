import { useCallback, useEffect, useState } from 'react';
import { debounce } from 'lodash';
//
import { Stack, TextField } from '@mui/material';
//
import { dispatch, useSelector } from '@/stores';
import { updateNotes } from '@/features/bookings-management/slices';
import { TooltipIcon } from '@/components/Iconify';
//

export type RTKNoteType =
  | 'provider_instructions'
  | 'admin_notes'
  | 'access_instructions'
  | 'reason_for_change'
  | 'internal_notes';

type RTKNotesProps = {
  noteType: RTKNoteType;
};

export default function RTKNotes({ noteType }: RTKNotesProps) {
  const noteTypeLabels: Record<RTKNotesProps['noteType'], string> = {
    provider_instructions: 'Cleaning Instructions',
    admin_notes: 'Office Notes',
    access_instructions: 'Access Instructions',
    reason_for_change: 'Reason for Change',
    internal_notes: 'Internal Notes',
  };
  const toolTipInfoText: Record<RTKNotesProps['noteType'], string> = {
    provider_instructions:
      'These are the instruction from the client to the provider',
    admin_notes: 'These are the notes from the office to the provider',
    access_instructions:
      'These are the instructions on how to access the property',
    reason_for_change: 'Reason for Change',
    internal_notes: 'These are notes from admin to admin only',
  };

  const noteLabel = noteTypeLabels[noteType];
  const [localValue, setLocalValue] = useState('');
  const noteValue = useSelector(
    (state) => state.booking.cleanDetails.notes[noteType]
  );
  useEffect(() => {
    // the input field should not be updated with the value from the store
    // there's a new reason on each time the user changes something
    noteType !== 'reason_for_change' && setLocalValue(noteValue ?? '');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Debounce the dispatch action
  const debouncedSetNoteValue = useCallback(
    debounce(
      (value: string) => dispatch(updateNotes({ noteType, value })),
      500
    ),
    []
  );

  const handleInputChange = (value: string) => {
    setLocalValue(value);
    debouncedSetNoteValue(value);
  };

  return (
    <Stack direction="row" spacing={0.5}>
      <TooltipIcon
        icon="eva:info-outline"
        title={toolTipInfoText[noteType]}
        sx={{ color: 'text.disabled' }}
      />
      <TextField
        fullWidth
        size={noteType === 'reason_for_change' ? 'small' : 'medium'}
        label={noteLabel}
        multiline
        minRows={noteType === 'reason_for_change' ? 2 : 4}
        maxRows={Infinity}
        value={localValue}
        onChange={(e) => handleInputChange(e.target.value)}
        sx={{
          backgroundColor: (theme) =>
            noteType === 'internal_notes'
              ? theme.palette.error.lighter
              : theme.palette.background.default,
          borderRadius: 1,
        }}
      />
    </Stack>
  );
}
