import { Page, View, Text, Image, Document } from '@react-pdf/renderer';
// utils
import { fCurrency } from '../../../../utils/formatNumber';
import { fDate } from '../../../../utils/formatTime';
// @types
import { ProviderInvoice } from '../../types';
//
import styles from './InvoiceStyle';

// ----------------------------------------------------------------------

type Props = {
  invoice: ProviderInvoice;
};

export default function InvoicePDF({ invoice }: Props) {
  const {
    id,
    uuid,
    ref,
    user_id,
    status_id,
    seen,
    accepted,
    date_from,
    date_to,
    due_date,
    total,
    cleaner_notes,
    admin_notes,
    regenerate,
    created_at,
    users,
    invoice_status,
    _count,
    provider_invoice_items,
  } = invoice;

  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={[styles.gridContainer, styles.mb40]}>
          <Image source="/logo/logo_full.jpg" style={{ height: 32 }} />
          <View style={{ alignItems: 'flex-end', flexDirection: 'column' }}>
            <Text style={styles.h3}>{invoice_status.display}</Text>
            <Text> {`INV-${id}`} </Text>
          </View>
        </View>

        <View style={[styles.gridContainer, styles.mb40]}>
          <View style={styles.col6}>
            <Text style={[styles.overline, styles.mb8]}>Invoice from</Text>
            <Text style={styles.body1}>Name</Text>
            <Text style={styles.body1}>Address</Text>
            <Text style={styles.body1}>Phone</Text>
          </View>

          <View style={styles.col6}>
            <Text style={[styles.overline, styles.mb8]}>Invoice to</Text>
            <Text style={styles.body1}>Name</Text>
            <Text style={styles.body1}>Address</Text>
            <Text style={styles.body1}>Phone</Text>
          </View>
        </View>

        <View style={[styles.gridContainer, styles.mb40]}>
          <View style={styles.col6}>
            <Text style={[styles.overline, styles.mb8]}>Date create</Text>
            <Text style={styles.body1}>{fDate(created_at)}</Text>
          </View>
          <View style={styles.col6}>
            <Text style={[styles.overline, styles.mb8]}>Due date</Text>
            <Text style={styles.body1}>{fDate(due_date)}</Text>
          </View>
        </View>

        <Text style={[styles.overline, styles.mb8]}>Invoice Details</Text>

        <View style={styles.table}>
          <View style={styles.tableHeader}>
            <View style={styles.tableRow}>
              <View style={styles.tableCell_1}>
                <Text style={styles.subtitle2}>#</Text>
              </View>

              <View style={styles.tableCell_2}>
                <Text style={styles.subtitle2}>Description</Text>
              </View>

              <View style={styles.tableCell_3}>
                <Text style={styles.subtitle2}>Hours</Text>
              </View>

              <View style={styles.tableCell_3}>
                <Text style={styles.subtitle2}>Price/h</Text>
              </View>

              <View style={styles.tableCell_3}>
                <Text style={styles.subtitle2}>Cleaning Subtotal</Text>
              </View>

              <View style={(styles.tableCell_3, styles.alignRight)}>
                <Text style={styles.subtitle2}>Cleaning Products</Text>
              </View>

              <View style={[styles.tableCell_3, styles.alignRight]}>
                <Text style={styles.subtitle2}>Total</Text>
              </View>
            </View>
          </View>

          <View style={styles.tableBody}>
            {provider_invoice_items.map((item, index) => (
              <View style={styles.tableRow} key={item.id}>
                <View style={styles.tableCell_1}>
                  <Text>{index + 1}</Text>
                </View>

                <View style={styles.tableCell_2}>
                  <Text style={styles.subtitle2}>
                    {'#' + item.job_id + ':' + item.description}
                  </Text>
                  <Text>{item.description}</Text>
                </View>

                <View style={styles.tableCell_3}>
                  <Text>{item.hours}</Text>
                </View>

                <View style={styles.tableCell_3}>
                  <Text>{fCurrency(item.price_per_hour)}</Text>
                </View>

                <View style={styles.tableCell_3}>
                  <Text>{fCurrency(item.subtotal)}</Text>
                </View>

                <View style={styles.tableCell_3}>
                  <Text>{fCurrency(item.cleaning_products)}</Text>
                </View>

                <View style={[styles.tableCell_3, styles.alignRight]}>
                  <Text>{fCurrency(item.total)}</Text>
                </View>
              </View>
            ))}

            <View style={[styles.tableRow, styles.noBorder]}>
              <View style={styles.tableCell_1} />
              <View style={styles.tableCell_2} />
              <View style={styles.tableCell_3} />
              <View style={styles.tableCell_3} />
              <View style={styles.tableCell_3} />
              <View style={styles.tableCell_3}>
                <Text style={styles.h4}>Total</Text>
              </View>
              <View style={[styles.tableCell_3, styles.alignRight]}>
                <Text style={styles.h4}>{fCurrency(total)}</Text>
              </View>
            </View>
          </View>
        </View>

        <View style={[styles.gridContainer, styles.footer]}>
          <View style={styles.col8}>
            <Text style={styles.subtitle2}>NOTES</Text>
            <Text>We appreciate your business.</Text>
          </View>
          <View style={[styles.col4, styles.alignRight]}>
            <Text style={styles.subtitle2}>Have a Question?</Text>
            <Text>support@getsetclean.co.uk</Text>
          </View>
        </View>
      </Page>
    </Document>
  );
}
