import { boolean, object, string } from 'yup';
import { UserAddress } from '../../shared/types';
import { tableSortFilter } from '@/utils/arrayProcessing';

export const NEW_CLIENT_SCHEMA = object().shape({
  isEdit: boolean(),
  first_name: string().required('Name is required'),
  last_name: string().required('Name is required'),
  email: string().required('Email is required').email(),
  // phone_number: string().required('Phone number is required'),
  status: string().required('Status is required'),
  gender: string().required('Gender is required'),
});

export const NEW_CLIENT_DEFAULT_VALUES = {
  first_name: '',
  last_name: '',
  gender: '',
  short_description: '',
  status: '',
  profile_image: '',
  email: '',
  phone_number: '',
  admin_notes: '',
  return_to_edit_user: false,
  is_chat_user: true,
};

export const CLIENT_ADDRESSES_TABLE_HEAD = [
  { id: 'name', label: 'Name' },
  { id: 'town_or_city', label: 'City' },
  { id: 'postcode', label: 'Postcode' },
  { id: 'formatted_address', label: 'Address' },
  { id: 'actionBtns' },
];

export function applySortFilterClientAddress({
  tableData,
  comparator,
  searchFieldValue,
}: {
  tableData: UserAddress[];
  comparator: (a: any, b: any) => number;
  searchFieldValue: string;
}) {
  tableData = tableSortFilter(tableData, comparator);
  const searchFieldLowerCase = searchFieldValue.toLowerCase();
  if (searchFieldValue) {
    tableData = tableData.filter(
      (item) =>
        item.name.toLowerCase().indexOf(searchFieldLowerCase) !== -1 ||
        item.postcode
          .toLowerCase()
          .replace(' ', '')
          .indexOf(searchFieldLowerCase) !== -1 ||
        item.postcode.toLowerCase().indexOf(searchFieldLowerCase) !== -1 ||
        item.town_or_city.toLowerCase().indexOf(searchFieldLowerCase) !== -1 ||
        item.formatted_address.toLowerCase().indexOf(searchFieldLowerCase) !==
          -1
    );
  }

  return tableData;
}
