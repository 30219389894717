export const PROVIDER_JOBS_TABLE_HEAD = [
  { id: 'emptyCell' },
  { id: 'id', label: 'Job ID', align: 'left' },
  { id: 'user.first_name', label: 'Client', align: 'left' },
  { id: 'user.email', label: 'Email', align: 'left' },
  { id: 'booking_cleaners.firt_name', label: 'Assigned to', align: 'left' },
  { id: 'provider_income', label: 'Amount', align: 'left' },
  { id: 'provider_paid', label: 'Paid to cleaner', align: 'left' },
  { id: 'date', label: 'Job Date', align: 'left' },
];
