import { SxProps, Typography } from '@mui/material';
import useResponsive from '@/hooks/useResponsive';
//
type VBMCardTitlesProps = {
  title: string;
  sx?: SxProps;
};

export default function VBMCardTitle({ title, sx }: VBMCardTitlesProps) {
  const downMd = useResponsive('down', 'md');
  return (
    <Typography
      variant="body2"
      fontSize={downMd ? 16 : 18}
      fontWeight={600}
      sx={{ ...sx }}
    >
      {title}
    </Typography>
  );
}
