import { useState, useEffect } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
// api
import { getActivityData } from '../api';
// @mui
import {
  Box,
  Button,
  Card,
  Container,
  Divider,
  FormControlLabel,
  Switch,
  Tab,
  Table,
  TableBody,
  TableContainer,
  TablePagination,
  Tabs,
  Skeleton,
} from '@mui/material';
// routes
import { settingsPath, PATH_SETTINGS } from '../routes/paths';
// hooks
import useSettings from '@/hooks/useSettings';
import useTable, { emptyRows } from '@/hooks/useTable';
import useTabs from '@/hooks/useTabs';
// components
import { Page, Iconify, Scrollbar, HeaderBreadcrumbs } from '@/components';
import {
  TableNoData,
  TableEmptyRows,
  TableSelectedActions,
  TableHeadCustom,
} from '@/components/table';
// custom components
import { TableToolbarData, TableRowData } from '../components';
// types
import type { ActivityType } from '../types';
// filters
import { applySortFilterList } from '../utils';
import { uniq } from 'lodash';
// paths
import { PATH_USER } from '@/features/app';
// access
import { useGetAccess } from '@/hooks/useAccess';
// ----------------------------------------------------------------------

const TABLE_HEAD = [{ id: 'name', label: 'Name', align: 'left' }, { id: '' }];
// ----------------------------------------------------------------------

export default function ActivityTypesList() {
  const {
    dense,
    page,
    order,
    orderBy,
    rowsPerPage,
    setPage,
    //
    selected,
    onSelectRow,
    onSelectAllRows,
    //
    onSort,
    onChangeDense,
    onChangePage,
    onChangeRowsPerPage,
  } = useTable();

  const { themeStretch } = useSettings();

  const navigate = useNavigate();

  // --------------------------------------------------------------------------

  const [tableData, setTableData] = useState<ActivityType[]>([]);
  const [statusOptions, setStatusOptions] = useState<string[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    async function getActivityDataFun() {
      const fetchData = await getActivityData();
      setTableData(fetchData);
      setIsLoading(false);
    }
    getActivityDataFun();
  }, []);

  // get the access for the module
  const allow = useGetAccess('diary');
  // --------------------------------------------------------------------------
  //  FILTER & DELETE FUNCTIONS

  const [filterName, setFilterName] = useState('');

  const { currentTab: filterStatus, onChangeTab: onChangeFilterStatus } =
    useTabs('active');

  const handleFilterName = (filterName: string) => {
    setFilterName(filterName.toLowerCase());
    setPage(0);
  };

  const handleEditRow = (id: number) => {
    navigate(settingsPath('edit-leads', 'id', id));
  };

  const dataFiltered = applySortFilterList({
    tableData,
    filterName,
    filterStatus,
  });

  useEffect(() => {
    async function fetchActivityStatuses() {
      const fetchData = await getActivityData();
      const _allStatuses = fetchData.map((status) =>
        status.active === true ? 'active' : 'inactive'
      );

      setStatusOptions(uniq(_allStatuses).sort());

      setIsLoading(false);
    }
    fetchActivityStatuses();
  }, []);

  const denseHeight = dense ? 52 : 72;

  const isNotFound = !dataFiltered.length && !!filterName;

  return (
    <>
      {
        <Page title="Activity Types">
          <Container maxWidth={themeStretch ? false : 'lg'}>
            <HeaderBreadcrumbs
              heading="Activity Types"
              links={[
                { name: 'Dashboard', href: PATH_USER.root },
                {
                  name: 'Settings',
                  href: PATH_SETTINGS.rootLeads,
                },
                { name: 'Leads', href: PATH_SETTINGS.rootLeads },
              ]}
              action={
                allow?.canCreate && (
                  <Button
                    variant="contained"
                    component={RouterLink}
                    to={settingsPath('new-leads')}
                    startIcon={<Iconify icon={'eva:plus-fill'} />}
                  >
                    Add Activity Type
                  </Button>
                )
              }
            />

            <Card>
              <Tabs
                allowScrollButtonsMobile
                variant="scrollable"
                scrollButtons="auto"
                value={filterStatus}
                onChange={onChangeFilterStatus}
                sx={{ px: 2, bgcolor: 'background.neutral' }}
              >
                {statusOptions!.map((tab) => (
                  <Tab disableRipple key={tab} label={tab} value={tab} />
                ))}
              </Tabs>

              <Divider />
              {isLoading ? (
                <Box pl={2} pb={2}>
                  <Skeleton sx={{ width: 0.98 }} height={100} />
                </Box>
              ) : (
                <TableToolbarData
                  filterName={filterName}
                  onFilterName={handleFilterName}
                />
              )}
              {isLoading ? (
                <Box pl={2} pb={2}>
                  {[...Array(5)].map((_, index) => (
                    <Skeleton key={index} sx={{ width: 0.98 }} height={40} />
                  ))}
                </Box>
              ) : (
                <Scrollbar>
                  <TableContainer sx={{ minWidth: 800, position: 'relative' }}>
                    {selected.length > 0 && (
                      <TableSelectedActions
                        dense={dense}
                        numSelected={selected.length}
                        rowCount={tableData.length}
                        onSelectAllRows={(checked) =>
                          onSelectAllRows(
                            checked,
                            tableData.map((row) => String(row.id))
                          )
                        }
                      />
                    )}
                    <Table size={dense ? 'small' : 'medium'}>
                      <TableHeadCustom
                        order={order}
                        orderBy={orderBy}
                        headLabel={TABLE_HEAD}
                        rowCount={tableData.length}
                        numSelected={selected.length}
                        onSort={onSort}
                      />

                      <TableBody>
                        {dataFiltered
                          .slice(
                            page * rowsPerPage,
                            page * rowsPerPage + rowsPerPage
                          )
                          .map((row) => (
                            <TableRowData
                              permissions={allow}
                              key={row.id}
                              row={row}
                              selected={selected.includes(String(row.id))}
                              onSelectRow={() => onSelectRow(String(row.id))}
                              onEditRow={() => handleEditRow(row.id)}
                            />
                          ))}

                        <TableEmptyRows
                          height={denseHeight}
                          emptyRows={emptyRows(
                            page,
                            rowsPerPage,
                            tableData.length
                          )}
                        />

                        <TableNoData isNotFound={isNotFound} />
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Scrollbar>
              )}

              <Box sx={{ position: 'relative' }}>
                <TablePagination
                  rowsPerPageOptions={[25, 50, 100]}
                  component="div"
                  count={tableData.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={onChangePage}
                  onRowsPerPageChange={onChangeRowsPerPage}
                />

                <FormControlLabel
                  control={<Switch checked={dense} onChange={onChangeDense} />}
                  label="Dense"
                  sx={{ px: 3, py: 1.5, top: 0, position: { md: 'absolute' } }}
                />
              </Box>
            </Card>
          </Container>
        </Page>
      }
    </>
  );
}
