import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { Controller, useForm, useWatch } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
//
import {
  Autocomplete,
  Button,
  InputAdornment,
  MenuItem,
  Skeleton,
  Stack,
  TextField,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import DialogSimple from '@/components/dialog/DialogSimple';
import {
  FormProvider,
  RHFDatepicker,
  RHFTextField,
} from '@/components/hook-form';
// API
import { getSettingsData } from '@/features/app/api';
import {
  createUserCustomPrice,
  getUserCustomPrice,
  updateUserCustomPrice,
} from '@/features/users/shared/api';
// hooks + utils
import useResponsive from '@/hooks/useResponsive';
import { useSnackbarMsg } from '@/hooks/useSnackbarMsg';
import {
  NEW_PROVIDER_CUSTOM_PRICE_SCHEMA,
  PROVIDER_CUSTOM_PRICE_DEFAULT_VALUES,
} from '../../utils';
// types
import { ServiceVariant } from '@/types';
import {
  UserCustomPrice,
  ProviderPriceFormValue,
} from '@/features/users/shared/types';

type ProviderPriceDialogProps = {
  isCustomPriceDialogOpen: boolean;
  closeCustomPriceDialog: VoidFunction;
  currentPriceId?: string;
};

export default function ProviderPriceDialog({
  isCustomPriceDialogOpen,
  closeCustomPriceDialog,
  currentPriceId,
}: ProviderPriceDialogProps) {
  const [filteredServiceVariants, setFilteredServiceVariants] = useState<
    ServiceVariant[]
  >([]);
  const belowLg = useResponsive('down', 'lg');
  const { errorMsg, successMsg } = useSnackbarMsg();
  const { userId } = useParams();
  const queryClient = useQueryClient();

  const belowLgStackDirection = belowLg ? 'column' : 'row';
  const belowLgSpacing = belowLg ? 2 : 1;

  // ========================
  //     QUERIES - FETCH
  // ========================
  const { data: settings, isLoading: isSettingsDataLoading } = useQuery({
    queryKey: ['settings'],
    queryFn: getSettingsData,
    onError: (error) => {
      errorMsg(error, `Something went wrong while fetching settings data `);
    },
    // TODO: revisit these settings once MVP is ready to be launched
    // staleTime: 24 * 60 * 60 * 1000, // update every 24 hours
    // cacheTime: 24 * 60 * 60 * 1000, // update every 24 hours
    refetchOnWindowFocus: false,
    enabled: isCustomPriceDialogOpen,
  });

  const { data: currentPrice, isLoading: isCurrentPriceLoading } = useQuery({
    queryKey: ['currentPrice', currentPriceId],
    queryFn: () => getUserCustomPrice(String(userId), String(currentPriceId)),
    onSuccess: (apiResponse) => {
      // filter service variants based on the service type
      const filteredServiceVariants = settings?.serviceVariants.filter(
        (variant) =>
          variant.service_id === Number(apiResponse.service_variant.service.id)
      );
      filteredServiceVariants
        ? setFilteredServiceVariants(filteredServiceVariants)
        : errorMsg('There was an error while getting service variants ready');

      // reset rhf form with fetched data
      resetForm({
        city_id: apiResponse.city_id,
        service_type_id: apiResponse.service_variant.service.id,
        service_variant_id: apiResponse.service_variant_id,
        price: apiResponse.price,
        sun_bank_holiday_extra: apiResponse.sun_bank_holiday_extra,
        start_date: apiResponse.start_date,
        end_date: apiResponse.end_date || '',
      });
    },
    onError: (error) => {
      errorMsg(error, `Something went wrong while fetching settings data `);
    },
    enabled: isCustomPriceDialogOpen && !!currentPriceId && !!settings,
    refetchOnWindowFocus: false, // TODO: check if it's necessary elsewhere, too, when editing data - on refocus, the form resets
  });

  // =============
  //      RHF
  // =============

  const methods = useForm<ProviderPriceFormValue>({
    defaultValues: PROVIDER_CUSTOM_PRICE_DEFAULT_VALUES,
    resolver: yupResolver(NEW_PROVIDER_CUSTOM_PRICE_SCHEMA),
  });

  const {
    control,
    handleSubmit,
    setValue,
    reset: resetForm,
    formState: { isSubmitting, errors },
  } = methods;

  const { service_type_id, start_date } = useWatch({ control });

  // =================
  //      UTIL FNS
  // =================

  const handleServiceTypeChange = (serviceTypeId: string) => {
    setValue('service_type_id', serviceTypeId);
    setValue('service_variant_id', '');
    const filteredServiceVariants = settings?.serviceVariants.filter(
      (variant) => variant.service_id === Number(serviceTypeId)
    );
    filteredServiceVariants
      ? setFilteredServiceVariants(filteredServiceVariants)
      : errorMsg('There was an error while getting service variants ready');
  };

  const retrieveCityObject = (cityId: number) =>
    settings?.cities.find((city) => city.id === cityId);

  const handleCloseDialog = () => {
    resetForm(PROVIDER_CUSTOM_PRICE_DEFAULT_VALUES);
    closeCustomPriceDialog();
  };

  // =========================
  //      SUBMIT + QUERIES
  // =========================
  // these queries were in the parent component origianlly and were passed down as props
  // but for convenience, I moved them here
  const createProviderPriceMutation = useMutation({
    mutationFn: (customPriceDto: Partial<UserCustomPrice>) =>
      createUserCustomPrice(String(userId), customPriceDto),
    onSuccess: () => {
      queryClient.invalidateQueries(['providerCustomPrices']);
      successMsg('Price successfully created');
      handleCloseDialog();
    },
    onError: (error) =>
      errorMsg(error, `Something went wrong while creating custom price`),
  });

  const updateProviderPriceMutation = useMutation({
    mutationFn: (customPriceDto: Partial<UserCustomPrice>) =>
      updateUserCustomPrice(
        String(userId),
        String(currentPriceId),
        customPriceDto
      ),
    onSuccess: () => {
      queryClient.invalidateQueries(['providerCustomPrices']);
      successMsg('Price successfully updated');
      handleCloseDialog();
    },
    onError: (error) =>
      errorMsg(error, `Something went wrong while creating custom price`),
  });

  const submitProviderPrices = async (data: ProviderPriceFormValue) => {
    const priceDto = {
      city_id: Number(data.city_id),
      service_variant_id: Number(data.service_variant_id),
      price: Number(data.price),
      sun_bank_holiday_extra: Number(data.sun_bank_holiday_extra),
      start_date: new Date(data.start_date).toISOString(),
      end_date: new Date(data.end_date).toISOString(),
    };

    !currentPrice
      ? createProviderPriceMutation.mutate(priceDto)
      : updateProviderPriceMutation.mutate(priceDto);
  };

  const showSkeleton =
    isSettingsDataLoading ||
    !settings ||
    (currentPriceId && isCurrentPriceLoading) ||
    (currentPriceId && !currentPrice);

  return (
    <DialogSimple
      dialogTitle={`${currentPrice ? 'Edit' : 'Add'} custom price`}
      open={isCustomPriceDialogOpen}
      onClickButtonClose={handleCloseDialog}
      loadingButton={false}
      disagreeButton={false}
      maxWidth="md"
    >
      <FormProvider
        methods={methods}
        onSubmit={handleSubmit(submitProviderPrices)}
      >
        {!showSkeleton ? (
          <Stack spacing={belowLg ? 2 : 1.5} sx={{ px: 3, py: 1.5 }}>
            {/* TODO: re-create RHFAutocomplete */}
            <Controller
              name="city_id"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <Autocomplete
                  {...field}
                  fullWidth
                  value={retrieveCityObject(Number(field.value)) ?? null}
                  filterSelectedOptions
                  options={settings?.cities || []}
                  noOptionsText="No such city"
                  getOptionLabel={(option) => option.name}
                  onChange={(_event, newValue) =>
                    setValue('city_id', newValue ? Number(newValue?.id) : '')
                  }
                  renderOption={(props, city) => (
                    <MenuItem {...props} key={city.id} value={city.id}>
                      {city.name}
                    </MenuItem>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Search city"
                      fullWidth
                      autoFocus
                      size="small"
                      error={!!error}
                      helperText={error?.message}
                    />
                  )}
                />
              )}
            />

            <Stack direction={belowLgStackDirection} spacing={belowLgSpacing}>
              {/* TODO: I believe this appears the 2nd time throughout the app - check BookingSessionsToolbar
                        first you need to pick a service type and then a service variant based on the type */}
              <RHFTextField
                select
                name="service_type_id"
                label="Service type"
                onChange={(event) =>
                  handleServiceTypeChange(event.target.value)
                }
                fullWidth
                size="small"
              >
                {settings?.services.map((service) => (
                  <MenuItem key={service.id} value={service.id}>
                    {service.name}
                  </MenuItem>
                ))}
              </RHFTextField>

              <RHFTextField
                select
                name="service_variant_id"
                label="Service variant"
                disabled={!service_type_id}
                fullWidth
                size="small"
              >
                {filteredServiceVariants.map((variant) => (
                  <MenuItem key={variant.id} value={variant.id}>
                    {variant.name}
                  </MenuItem>
                ))}
              </RHFTextField>
            </Stack>

            <Stack direction={belowLgStackDirection} spacing={belowLgSpacing}>
              <RHFTextField
                name="price"
                label="Price"
                inputProps={{ type: 'number', step: '0.5' }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">£</InputAdornment>
                  ),
                }}
                fullWidth
                size="small"
              />

              <RHFTextField
                name="sun_bank_holiday_extra"
                label="Sunday/Bank holiday extra"
                inputProps={{ type: 'number', step: '1', min: 0, max: 100 }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">£</InputAdornment>
                  ),
                }}
                fullWidth
                size="small"
              />
            </Stack>

            <Stack direction={belowLgStackDirection} spacing={belowLgSpacing}>
              <RHFDatepicker
                name="start_date"
                label="Start date"
                size="small"
                disablePast
              />
              <RHFDatepicker
                name="end_date"
                label="End date"
                size="small"
                disablePast
                minDate={start_date}
                helperTextFromRhfContext={errors.end_date?.message}
              />
            </Stack>

            {/* TODO: extract these action buttons so they can be used in forms - ie VBMActionButtons */}
            <Stack
              direction="row"
              justifyContent="flex-end"
              spacing={1.5}
              sx={{ pt: 1.5, pb: 1 }}
            >
              <Button
                variant="outlined"
                size="small"
                color="inherit"
                onClick={handleCloseDialog}
              >
                Cancel
              </Button>
              <LoadingButton
                variant="contained"
                size="small"
                type="submit"
                loading={isSubmitting}
              >
                {currentPrice ? 'Save changes' : 'Add price'}
              </LoadingButton>
            </Stack>
          </Stack>
        ) : (
          <Stack spacing={1} sx={{ px: 3, pb: 4 }}>
            {[...new Array(5)].map((_, i) => (
              <Skeleton height={40} key={i} />
            ))}
          </Stack>
        )}
      </FormProvider>
    </DialogSimple>
  );
}
