import { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
// mui
import { Container } from '@mui/material';
//
import { ServiceCreateForm } from '@/features/services-management/components';
import { HeaderBreadcrumbs, Page } from '@/components';
// API
import { getSingleService } from '@/features/services-management/api';
import { getSettingsData } from '@/features/app/api';
// hooks + utils
import { useSnackbarMsg } from '@/hooks/useSnackbarMsg';
import useSettings from '@/hooks/useSettings';
//
import { PATH_USER } from '@/features/app';
import { PATH_SERVICES_MANAGEMENT } from '@/features/services-management/routes/paths';
// types
import { ServiceExtra } from '@/types';

// ----------------------------------------------------------------------

export default function ServiceCreateEdit() {
  const { themeStretch } = useSettings();
  const { errorMsg } = useSnackbarMsg();
  const { serviceId } = useParams();

  // =================
  //      QUERIES
  // =================
  // SETTINGS
  const { data: defaultServiceExtras } = useQuery({
    queryKey: ['settings'],
    queryFn: getSettingsData,
    select: (data) => data?.serviceExtras,
    onError: (error) => {
      errorMsg(error, `Something went wrong while fetching service extras`);
    },
    refetchOnWindowFocus: false,
    // TODO: add stale time
  });

  // CURRENT SERVICE
  const { data: currentService } = useQuery({
    queryKey: ['services', serviceId],
    queryFn: () => getSingleService(Number(serviceId)),
    onError: (error) => {
      errorMsg(error, `Something went wrong while fetching services`);
    },
    enabled: !!serviceId && !!defaultServiceExtras,
  });

  const generateDefaultValues = (extras: ServiceExtra[]) => {
    const serviceSatus = currentService?.active ? 'Active' : 'Inactive';
    const defaultValues = {
      service_name: currentService?.name || '',
      service_status: currentService ? serviceSatus : '',
      multiple_dates: currentService?.allow_multiple_dates || false,
    };
    const flattenedCurrentExtrasArray =
      currentService?.service_cleaning_extras.map((e) => e.cleaning_extra) ||
      [];

    const getExtraCustomHours = (extraId: number, defaultHours: number) => {
      const extra = flattenedCurrentExtrasArray.find((e) => e.id === extraId);
      return extra ? extra.custom_hours : defaultHours;
    };

    extras.forEach((extra) =>
      Object.assign(defaultValues, {
        [`service_extra_textfield_${extra.id}`]: currentService
          ? getExtraCustomHours(extra.id, extra.hours)
          : extra.hours,
        [`service_extra_checkbox_${extra.id}`]: currentService
          ? flattenedCurrentExtrasArray.some((e) => e.id === extra.id)
          : false,
      })
    );
    return defaultValues;
  };

  const formDefaultValues = useMemo(
    () => generateDefaultValues(defaultServiceExtras || []),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [defaultServiceExtras, currentService]
  );

  return (
    <Page title="Service Management: Add new service">
      <Container maxWidth={themeStretch ? false : 'lg'}>
        <HeaderBreadcrumbs
          heading={!serviceId ? 'Add new service' : 'Edit service'}
          links={[
            { name: 'Dashboard', href: PATH_USER.root },
            {
              name: 'Services',
              href: `${PATH_SERVICES_MANAGEMENT.root}${PATH_SERVICES_MANAGEMENT.services}`,
            },
            { name: !serviceId ? 'Add new' : 'Edit service' },
          ]}
        />
        <ServiceCreateForm
          currentService={currentService}
          defaultServiceExtras={defaultServiceExtras || []}
          defaultValues={formDefaultValues}
        />
      </Container>
    </Page>
  );
}
