import { Link as RouterLink } from 'react-router-dom';
// @mui
import { Box, BoxProps } from '@mui/material';

interface Props extends BoxProps {
  disabledLink?: boolean;
}

export default function Logo({ disabledLink = false, sx }: Props) {
  const logo_small: string | undefined =
    localStorage.getItem('logo_small') || undefined;

  const logo = (
    <Box
      component="img"
      src={logo_small}
      sx={{ height: 50, cursor: 'pointer', ...sx }}
    />
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="/">{logo}</RouterLink>;
}
