import { useCallback, useState } from 'react';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { format, isSameDay } from 'date-fns';
import {
  Draggable,
  Dropcontainer,
  MbscCalendarEvent,
  MbscItemDragEvent,
  snackbar,
} from '@mobiscroll/react';
//
import {
  Box,
  Card,
  CircularProgress,
  IconButton,
  MenuItem,
  Skeleton,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
//
import { Iconify, Label } from '@/components';
import { ToolbarWithSearchAndStatus } from '@/components/toolbar';
// API
import {
  getFilteredBookingSessions,
  updateBookingSession,
} from '@/features/bookings-management/api';
import { getSettingsData } from '@/features/app/api';
// hooks + utils
import useTable, { getComparator } from '@/hooks/useTable';
import { useSnackbarMsg } from '@/hooks/useSnackbarMsg';
import {
  applyFilterJobs,
  getNextPrevDay,
  parseBookingSessionData,
} from '../../utils';
// type
import { TransformedBooking } from '../../types';
import CleaningJobDetailsDialog from '../cleaning-job-dialog/CleaningJobDetailsDialog';
import useToggle from '@/hooks/useToggle';

type UnassignedEventsContainerProps = {
  selectedDate: string | Date | undefined;
};

export default function UnassignedEventsContainer({
  selectedDate,
}: UnassignedEventsContainerProps) {
  const COMPONENT_MIN_HEIGHT = 400; // the container needs a minimum height
  const [filterServiceTypeId, setFilterServiceTypeId] = useState<number>(0);
  const [currentSessionId, setCurrentSessionId] = useState<number | null>(null);

  const [dropCont, setDropCont] = useState<HTMLDivElement>();
  const { errorMsg } = useSnackbarMsg();
  const queryClient = useQueryClient();

  const {
    toggle: isJobDialogOpen,
    onClose: closeJobDialog,
    onOpen: openJobDialog,
  } = useToggle();

  const handleEditSession = (id: number) => {
    setCurrentSessionId(id);
    openJobDialog();
  };

  // QUERIES
  const nextDay = getNextPrevDay(new Date(selectedDate ?? ''), 'next');
  const prevDay = getNextPrevDay(new Date(selectedDate ?? ''), 'prev');
  const {
    data: unassignedBookingSessions,
    isLoading: isBookingSessionsDataLoading,
  } = useQuery({
    queryKey: ['unassignedJobs', selectedDate],
    queryFn: () =>
      getFilteredBookingSessions(
        `unassigned`,
        `?startDate=${prevDay}&endDate=${nextDay}`
      ),
    onError: (error) => errorMsg(error, 'Error fetching cleaning jobs'),
    select: (data) =>
      parseBookingSessionData(data || []).filter((session) =>
        isSameDay(new Date(selectedDate ?? ''), new Date(session.start))
      ) || [],
  });

  const { data: serviceTypes, isLoading: isSettingsDataLoading } = useQuery({
    queryKey: ['settings'],
    queryFn: getSettingsData,
    onError: (error) => errorMsg(error, 'Error fetching settings'),
    select: (data) => [{ id: 0, name: 'All' }, ...data.services],
  });

  // =================================
  //     REACT QUERY / UPDATE DATA
  // =================================

  // more info on useMutation --> https://www.youtube.com/watch?v=DocXo3gqGdI&t=4431s
  const unassignBookingSessionMutation = useMutation({
    // mutate function will make the API call
    mutationFn: (updatedSession: MbscCalendarEvent) =>
      updateBookingSession(Number(updatedSession.id), {
        booking_cleaners: [],
      }),
    // onMutate fires before the mutationFn and optimistically updates the cache
    onMutate: (updatedSessionFromScheduler) => {
      const oldSchedulerData = queryClient.getQueryData(['scheduler']);

      if (queryClient.getQueryData(['scheduler'])) {
        // TODO: TS any
        queryClient.setQueryData(['scheduler'], (old: any) =>
          old.filter((b: any) => b.id === updatedSessionFromScheduler.id)
        );
      }

      // this will be used in the onError callback
      return () => queryClient.setQueryData(['scheduler'], oldSchedulerData);
    },

    onError: (error, _v, rollback) => {
      // this fn is returned from onMutate callback
      rollback && rollback();
      errorMsg(error, `Something went wrong while updating booking`);
    },

    onSettled: (_data, error, mutationData) => {
      // if mutation was successful, we show a snackbar with the option to undo the mutation
      if (!!!error) {
        snackbar({
          message: `#${mutationData.id} successfully unassigned`,
          color: 'success',
          duration: 6000,
        });
      }
      queryClient.invalidateQueries(['scheduler']);
      queryClient.invalidateQueries(['unassignedJobs', selectedDate]);
    },
  });

  const setDropElm = useCallback((element: HTMLDivElement) => {
    setDropCont(element);
  }, []);
  const handleItemDrop = useCallback(
    (args: MbscItemDragEvent) => {
      unassignBookingSessionMutation.mutate(args.data);
    },
    [unassignBookingSessionMutation]
  );

  const [showSearch, setShowSearch] = useState(false);
  // ==============================
  //          QUICK FILTER
  // ==============================

  const [searchFieldValue, setSearchFieldValue] = useState('');

  const handleSearchFieldValue = (searchFieldValue: string) => {
    setSearchFieldValue(searchFieldValue);
  };

  const { order, orderBy } = useTable({ defaultDense: true });
  const dataFiltered = applyFilterJobs({
    tableData: unassignedBookingSessions || [],
    comparator: getComparator(order, orderBy),
    filterServiceTypeId,
  });

  const isNotFound = !!!dataFiltered.length;

  return (
    <>
      <Card
        ref={setDropElm}
        sx={{
          width: '100%',
          height: '100%',
          minHeight: COMPONENT_MIN_HEIGHT,
          p: 1,
          backgroundColor: 'paper',
          display: 'flex',
          flexDirection: 'column',
          gap: 1,
        }}
      >
        <Stack
          sx={{
            flexDirection: 'row',
            justifyContent: 'space-evenly',
            alignItems: 'center',
            pl: 0.5,
          }}
        >
          <Typography fontWeight={600}>Unassigned jobs</Typography>

          <IconButton onClick={() => setShowSearch(!showSearch)}>
            <Iconify icon="eva:search-outline" />
          </IconButton>
        </Stack>
        {showSearch && (
          <Box sx={{ pb: 0.5, px: 1.5 }}>
            <ToolbarWithSearchAndStatus
              filterText={searchFieldValue}
              onFilterText={handleSearchFieldValue}
              searchfieldVariant="standard"
              labels={{ search: 'Search in jobs...' }}
            />
          </Box>
        )}
        {isSettingsDataLoading ? (
          <Skeleton />
        ) : (
          <TextField
            select
            size="small"
            value={filterServiceTypeId}
            onChange={(e) => setFilterServiceTypeId(Number(e.target.value))}
          >
            {(serviceTypes || []).map((option) => (
              <MenuItem value={option.id} key={option.id}>
                {option.name}
              </MenuItem>
            ))}
          </TextField>
        )}
        {isBookingSessionsDataLoading || !dataFiltered ? (
          <Stack
            alignItems="center"
            justifyContent="center"
            sx={{ height: '100%', minHeight: COMPONENT_MIN_HEIGHT }}
          >
            <CircularProgress />
          </Stack>
        ) : (
          <Stack
            spacing={1}
            ref={setDropElm}
            sx={{ height: '100%', minHeight: COMPONENT_MIN_HEIGHT }}
          >
            <Dropcontainer
              onItemDrop={handleItemDrop}
              element={dropCont}
              style={{ height: '100%', width: '100%' }}
            >
              {dataFiltered.map((task) => (
                <UnassignedJob
                  key={task.id}
                  event={task}
                  handleEditSession={handleEditSession}
                />
              ))}
              {isNotFound && (
                <Stack
                  spacing={1}
                  justifyContent="center"
                  alignItems={'center'}
                  sx={{ textAlign: 'center', pt: 1.5 }}
                >
                  {filterServiceTypeId !== 0 ? (
                    <Typography>
                      No unassigned jobs available for the selected filter
                    </Typography>
                  ) : (
                    <>
                      <Typography>
                        No unassigned jobs available for the selected date:
                      </Typography>
                      <Typography fontWeight={600}>
                        {format(
                          new Date(selectedDate ?? ''),
                          'EEE, dd-MM-yyyy'
                        )}
                      </Typography>
                    </>
                  )}
                </Stack>
              )}
            </Dropcontainer>
          </Stack>
        )}
      </Card>

      <CleaningJobDetailsDialog
        isJobDialogOpen={isJobDialogOpen}
        closeJobDialog={closeJobDialog}
        currentSessionId={currentSessionId}
      />
    </>
  );
}

function UnassignedJob({
  event,
  handleEditSession,
}: {
  event: TransformedBooking;
  handleEditSession: (id: number) => void;
}) {
  const [draggable, setDraggable] = useState<HTMLDivElement>();

  const setDragElm = useCallback((elm: HTMLDivElement) => {
    setDraggable(elm);
  }, []);

  const eventLength = Math.round(
    Math.abs(
      new Date(event.end as string).getTime() -
        new Date(event.start as string).getTime()
    ) /
      (60 * 60 * 1000)
  );

  return (
    <Card
      sx={{
        p: 1,
        cursor: 'pointer',
        ':hover': { backgroundColor: 'warning.lighter' },
      }}
      onDoubleClick={() => handleEditSession(event.id)}
    >
      <div ref={setDragElm}>
        <Stack
          direction="column"
          alignItems="center"
          sx={{
            position: 'absolute',
            right: 1,
            top: 1,
            bottom: 1,
            p: 1,
          }}
        >
          {/* SERVICE + RECURRENCE LABEL, ie PO - personal one-off */}

          <Label color="info" variant="filled" sx={{ p: 0 }}>
            {event.serviceLabel}
          </Label>
        </Stack>
        <Typography fontWeight={600}>{event.title}</Typography>
        <Typography fontSize={12}>
          {eventLength + ' hour' + (eventLength > 1 ? 's' : '')}
        </Typography>
        <Typography fontSize={12}>{event.address.postcode}</Typography>
        <Draggable dragData={event} element={draggable} />
      </div>
    </Card>
  );
}
