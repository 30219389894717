import { useNavigate } from 'react-router';
import { useState, MouseEvent } from 'react';
//
import { MenuItem, TableCell, TableRow, Typography } from '@mui/material';
//
import { Iconify, Label } from '@/components';
import { TableMoreMenu, TableRowLink } from '@/components/table';
import AddressWithGoogleMapsIconButton from '@/components/AddressWithGoogleMapsIconButton';
// utils
import { fCurrency } from '@/utils/formatNumber';
import { fDate } from '@/utils/formatTime';
import { providersPath } from '@/features/users/providers';
import { bookingsManagementPath } from '@/features/bookings-management';
// types
import { LabelColors } from '@/types';
import { Booking } from '@/features/bookings-management/types';

type ProviderJobsRowProps = {
  row: Booking;
  canDelete: boolean;
  canUpdate: boolean;
};

export default function ClientBookingsTableRow({
  row,
  canDelete,
  canUpdate,
}: ProviderJobsRowProps) {
  const navigate = useNavigate();

  const {
    id,
    date,
    time,
    total_amount,
    service_variant,
    address,
    booking_cleaners,
    booking_status,
  } = row;

  const cleaner =
    booking_cleaners[0]?.first_name && booking_cleaners[0]?.last_name
      ? `${booking_cleaners[0]?.first_name ?? 'N/A'} ${
          booking_cleaners[0]?.last_name ?? 'N/A'
        }`
      : null;

  const cleanerId = !!booking_cleaners.length ? booking_cleaners[0]?.id : 0;

  const [openMenu, setOpenMenuActions] = useState<HTMLElement | null>(null);

  const handleOpenMenu = (event: MouseEvent<HTMLElement>) => {
    setOpenMenuActions(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setOpenMenuActions(null);
  };

  return (
    <>
      <TableRow hover>
        <TableCell width={10} />
        {/* ID */}
        <TableCell align="left">
          <TableRowLink
            linkText={id}
            linkTo={bookingsManagementPath('view', {
              bookingId: String(id),
              bookingTabSid: 'booking-sessions',
              sessionFilter: 'today',
            })}
          />
        </TableCell>

        {/* DATE AND TIME */}
        <TableCell align="left">
          <Typography noWrap fontSize={14}>
            {time ? time.slice(0, 5) : 'N/A'} @{' '}
            {date ? fDate(date, 'EE, dd MMM yy') : 'N/A'}
          </Typography>
        </TableCell>

        {/* TOTAL AMOUNT */}
        <TableCell align="left">{fCurrency(total_amount)}</TableCell>

        {/* BOOKING SERVICE */}
        <TableCell align="left">{service_variant.service.name}</TableCell>

        {/* SERVICE VARIANT */}
        <TableCell align="left">{service_variant?.name ?? 'N/A'}</TableCell>

        {/* ADDRESS */}
        <TableCell align="left">
          <AddressWithGoogleMapsIconButton
            formatted_address={address.formatted_address}
            postcode={address.postcode}
          />
        </TableCell>

        {/* BOOKING STATUS */}
        <TableCell align="left">
          <Label color={(booking_status.style_class as LabelColors) ?? 'info'}>
            {booking_status.display ?? 'N/A'}
          </Label>
        </TableCell>

        <TableCell align="left">
          {cleaner ? (
            <TableRowLink
              linkText={cleaner}
              linkTo={providersPath('edit', {
                userId: String(cleanerId),
                tabSid: 'details',
              })}
              noWrap
            />
          ) : (
            <Typography
              fontStyle="italic"
              fontSize={14}
              fontWeight={500}
              sx={{ pl: 1 }}
            >
              Unassigned
            </Typography>
          )}
        </TableCell>

        <TableCell align="right">
          {(canDelete || canUpdate) && (
            <TableMoreMenu
              open={openMenu}
              onOpen={handleOpenMenu}
              onClose={handleCloseMenu}
              actions={
                <>
                  {canUpdate && (
                    <MenuItem
                      onClick={() =>
                        navigate(
                          bookingsManagementPath('view', {
                            bookingId: String(id),
                            bookingTabSid: 'booking-sessions',
                            sessionFilter: 'today',
                          })
                        )
                      }
                    >
                      <Iconify icon={'eva:edit-fill'} />
                      Edit
                    </MenuItem>
                  )}
                  {canDelete && (
                    <MenuItem
                      onClick={() => alert('DELETE')}
                      sx={{ color: 'error.main' }}
                    >
                      <Iconify icon={'eva:trash-2-outline'} />
                      Delete
                    </MenuItem>
                  )}
                </>
              }
            />
          )}
        </TableCell>
      </TableRow>
    </>
  );
}
