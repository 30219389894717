import {
  Box,
  FormControlLabel,
  Stack,
  Switch,
  ToggleButton,
  ToggleButtonGroup,
} from '@mui/material';
import SectionLabel from '../label-partial/SectionLabel';
// hooks
import useResponsive from '@/hooks/useResponsive';
// redux
import {
  setPets,
  setValidationError,
  togglePetsSwitch,
} from '@/features/bookings-management/slices';
import { dispatch, useSelector } from '@/stores';
//
import { PETS } from '@/features/bookings-management/utils';
import CustomErrorMessage from '../top-section/error-message/CustomErrorMessage';

export default function Pets() {
  const downMd = useResponsive('down', 'md');
  const booking = useSelector((state) => state.booking);
  const handleToggleGroupChange = (value: string[]) => {
    dispatch(setPets(value));
    booking.validationErrors.pets &&
      dispatch(setValidationError({ field: 'pets', error: null }));
  };

  return (
    <Stack direction="column" sx={{ mt: -1 }}>
      <SectionLabel label="Pets:" />
      <Stack direction={downMd ? 'column' : 'row'} spacing={1}>
        <Box>
          <FormControlLabel
            control={
              <Switch
                onChange={(e) => dispatch(togglePetsSwitch(e.target.checked))}
                checked={booking.cleanDetails.pets_enabled}
              />
            }
            label="Add pets"
          />
        </Box>
        {booking.cleanDetails.pets_enabled && (
          <ToggleButtonGroup
            orientation={downMd ? 'vertical' : 'horizontal'}
            color="primary"
            value={booking.cleanDetails.pets}
            exclusive={false} // enables multieselect
            onChange={(_, value) => handleToggleGroupChange(value)}
            aria-label="Pet selector"
            sx={{
              maxWidth: 'fit-content',
              height: downMd ? 'auto' : 46,
              border: (theme) =>
                !!booking.validationErrors.pets
                  ? `1px solid ${theme.palette.error.main}`
                  : '1px solid text.secondary',
            }}
          >
            {PETS.map((pet) => (
              <ToggleButton key={pet.id} value={pet.value} sx={{ width: 120 }}>
                {pet.name}
              </ToggleButton>
            ))}
          </ToggleButtonGroup>
        )}
        {booking.validationErrors.pets && (
          <CustomErrorMessage label={booking.validationErrors.pets} />
        )}
      </Stack>
    </Stack>
  );
}
