import { useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { lightFormat } from 'date-fns';
import { isEmpty } from 'lodash';
// @mui
import { Button, Card, Divider, Grid, Stack } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// components
import {
  BookingIdStatusTimeBar,
  BookingsManagementSkeleton,
  BookingSummary,
  CleaingProducts,
  ClientAddressCleanerTopSection,
  ConfirmRecurringChangesDialog,
  Coupon,
  LinenAmnetities,
  Notes,
  Pets,
  ServiceDetails,
  ServiceExtras,
  ValidationErrorsList,
} from '../..';
// API
import { getSettingsData } from '@/features/app/api';
import {
  createBooking,
  getSingleBooking,
  updateBooking,
} from '@/features/bookings-management/api';
// redux
import { dispatch, useSelector } from '@/stores';
import {
  updateStoreWithCurrentBooking,
  selectedVariant,
  updateServiceVariantsAndExtras,
} from '@/features/bookings-management/slices';
// utils + hooks
import { useSnackbarMsg } from '@/hooks/useSnackbarMsg';
import {
  getSelectedDayNames,
  validateBookingForm,
} from '@/features/bookings-management/utils';
import { bookingsManagementPath } from '@/features/bookings-management/routes/paths';
// types
import { ServiceVariant } from '@/types';
import { UserAddress } from '@/features/users/shared/types';

// ----------------------------------------------------------------------

export default function BookingNewEditForm() {
  const navigate = useNavigate();
  const { bookingId } = useParams();
  const { errorMsg, successMsg } = useSnackbarMsg();
  const queryClient = useQueryClient();

  const [formIsSubmitting, setFormIsSubmitting] = useState<boolean>(false);
  const [isRecurringDialogOpen, setRecurringDialogOpen] = useState(false);

  const booking = useSelector((state) => state.booking);
  const choosenVariant: ServiceVariant = useSelector(selectedVariant);

  const isHolidayRental =
    !isEmpty(booking.selectedServiceVariant) &&
    choosenVariant.ref.includes('holiday');

  const submitBtnRef = useRef<null | HTMLButtonElement>(null);

  // ===============
  //     QUERIES
  // ===============

  // SETTINGS
  const { data: settingsData, isLoading: isSettingsDataLoading } = useQuery({
    // TODO: probably this data should be fetched on app load
    queryKey: ['settings'],
    queryFn: () => getSettingsData(),
    onSuccess: (data) => {
      dispatch(updateServiceVariantsAndExtras(data)); // and maybe this could be stored in a separate slice
    },
    onError: (error) => {
      errorMsg(error, `Something went wrong while fetching settings data `);
      navigate(bookingsManagementPath('list'));
    },

    refetchOnWindowFocus: false,
  });

  // BOOKINGS
  const { data: currentBooking, isLoading: isBookingLoading } = useQuery({
    queryKey: ['bookings', bookingId || ''],
    queryFn: () => getSingleBooking(Number(bookingId)),
    onSuccess: (bookingFromApi) =>
      dispatch(updateStoreWithCurrentBooking(bookingFromApi)),
    onError: (error) => {
      errorMsg(error, `Something went wrong while fetching booking data`);
      navigate(bookingsManagementPath('list'));
    },
    refetchOnWindowFocus: false, // otherwise the booking state in store would get overwritten on windowFocus
    enabled: !!bookingId && !!settingsData, // we need the serviceVariants and serviceExtras to be loaded before we can set the default values ==> !!settingsData
  });

  // ===================
  //        SUBMIT
  // ===================

  const handleSubmitBooking = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const isFormValid = validateBookingForm(dispatch, booking);

    if (!isFormValid) return;

    const recurringDays = getSelectedDayNames(booking.recurring_days)
      .recurrenceNames.map((n) => n.toUpperCase())
      .join(',');

    const formattedData = {
      user_id: booking.client?.id,
      address_id: (booking.address as UserAddress).id,
      booking_cleaners:
        booking.booking_cleaners.length > 0
          ? [booking.booking_cleaners[0].id]
          : [],
      //
      booking_status_id: booking.booking_status_id,
      date: lightFormat(new Date(booking.date as Date), 'yyyy-MM-dd'),
      time: booking.time,
      //
      service_variant_id: (booking.selectedServiceVariant as ServiceVariant).id,
      bedrooms: booking.cleanDetails.numberOfBedrooms,
      bathrooms: booking.cleanDetails.numberOfBathrooms,
      // desks: number; ??
      hours: booking.cleanDetails.hours,
      extras: booking.selectedServiceCleaningExtras
        .map((extra) => extra.id)
        .join('|'),
      //
      provider_instructions: booking.cleanDetails.notes.provider_instructions,
      access_instructions: booking.cleanDetails.access_instructions_enabled
        ? booking.cleanDetails.notes.access_instructions
        : '',
      access_type: booking.cleanDetails.access_instructions_enabled
        ? booking.cleanDetails.access_type
        : '',
      admin_notes: booking.cleanDetails.notes.admin_notes,
      total_amount: booking.totalAmount,
      //
      cleaning_products: booking.cleanDetails.requestForCleaningProduct,
      pets: booking.cleanDetails.pets.join('|'),
      linen: booking.cleanDetails.linen,
      essential_amenities: booking.cleanDetails.essential_amenities,
      property_type_id: booking.cleanDetails.property_type_id,
      // coupon
      coupon_code_id: Number(booking.cleanDetails.couponObject?.id),
      recurring_days: recurringDays,
      // cleaning_sessions_date:
    };

    try {
      setFormIsSubmitting(true);
      if (bookingId) {
        await updateBooking(Number(bookingId), formattedData);
        successMsg(`Booking #${bookingId} updated successfully`);
      } else {
        await createBooking(formattedData as any);
        successMsg('Booking created successfully');
      }
      navigate(bookingsManagementPath('list'));
      queryClient.invalidateQueries(['bookings']);
    } catch (error) {
      errorMsg(
        error,
        `Something went wrong while ${
          bookingId ? 'updating' : 'creating'
        } booking`
      );
    } finally {
      setFormIsSubmitting(false);
    }
  };

  const initiateSubmit = () => {
    setRecurringDialogOpen(false);
    submitBtnRef.current?.click();
  };

  const validationErrors = Object.values(booking.validationErrors).filter(
    (error) => typeof error === 'string'
  );

  // ===================

  const showSkeleton =
    isSettingsDataLoading ||
    (bookingId && isBookingLoading) ||
    (bookingId && !currentBooking) ||
    booking.bookingLoading ||
    (bookingId && isEmpty(choosenVariant));

  // ===================

  return (
    <form onSubmit={(e) => handleSubmitBooking(e)}>
      <Card sx={{ pb: 4 }}>
        {/* SKELETON */}
        {showSkeleton ? (
          <BookingsManagementSkeleton />
        ) : (
          // TOP SECTION
          <Stack direction="column" spacing={0.1}>
            <ClientAddressCleanerTopSection />
            <BookingIdStatusTimeBar
              bookingStatuses={settingsData?.bookingStatuses || []}
            />

            {/* MAIN SECTION */}
            <Grid container sx={{ pl: 2, pr: 4 }} spacing={2}>
              {/* LEFT */}
              <Grid
                item
                container
                xs={12}
                md={8}
                spacing={2}
                sx={{ display: 'block' }}
              >
                <Grid item container spacing={6}>
                  <Grid item xs={12} md={7}>
                    <ServiceDetails />
                  </Grid>

                  <Grid item xs={12} md={5}>
                    <ServiceExtras />

                    {isHolidayRental && (
                      <Stack direction="column" spacing={1} sx={{ mt: 1 }}>
                        <Divider sx={{ width: '90%', pb: 1 }} />
                        <LinenAmnetities />
                      </Stack>
                    )}
                  </Grid>
                </Grid>

                <Grid item container xs={12} sx={{ mt: 4 }}>
                  <Notes />
                </Grid>

                <Grid item container xs={12} sx={{ mt: 4 }}>
                  <Pets />
                </Grid>
              </Grid>

              {/* RIGHT */}
              <Grid
                item
                container
                xs={12}
                md={4}
                direction="column"
                spacing={2}
                sx={{ mt: 2 }}
              >
                <BookingSummary />
                <Grid item>
                  <CleaingProducts />
                </Grid>
                <Grid item>
                  <Coupon />
                </Grid>

                <Grid
                  item
                  container
                  justifyContent="flex-end"
                  direction="row"
                  spacing={2}
                  sx={{ mt: 'auto' }}
                >
                  <Grid item>
                    {!bookingId && (
                      <LoadingButton
                        type="submit"
                        size="large"
                        variant="contained"
                        loading={formIsSubmitting}
                      >
                        Create booking
                      </LoadingButton>
                    )}

                    <LoadingButton
                      type="submit"
                      size="large"
                      variant="contained"
                      ref={submitBtnRef}
                      loading={formIsSubmitting}
                      sx={{ visibility: 'hidden', display: 'none' }}
                    >
                      Update booking
                    </LoadingButton>

                    {bookingId && (
                      <Button
                        variant="contained"
                        size="large"
                        onClick={() => setRecurringDialogOpen(true)}
                      >
                        Update booking
                      </Button>
                    )}
                    {!!validationErrors.length && (
                      <ValidationErrorsList
                        validationErrors={validationErrors}
                      />
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Stack>
        )}
      </Card>

      <ConfirmRecurringChangesDialog
        isDialogOpen={isRecurringDialogOpen}
        handleCloseDialog={() => setRecurringDialogOpen(false)}
        handleConfirmDialog={() => initiateSubmit()}
      />
    </form>
  );
}
