import { useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
// @mui
import {
  Box,
  Button,
  Card,
  Container,
  Divider,
  FormControlLabel,
  Switch,
  Tab,
  Tabs,
  Table,
  TableBody,
  TableContainer,
  TablePagination,
  Skeleton,
} from '@mui/material';
// components
import { Page, Iconify, Scrollbar, HeaderBreadcrumbs } from '@/components';
import {
  TableEmptyRows,
  TableHeadCustom,
  TableNoData,
} from '@/components/table';
//
import { ClientTableRow } from '../components';
import { SkeletonLoadingTable } from '@/components/skeleton';
import { ToolbarWithSearchAndStatus } from '@/components/toolbar';
// API
import { deleteUser, getClients } from '@/features/users/shared/api';
import { getSettingsData } from '@/features/app/api';
// hooks +  utils
import useTabs from '@/hooks/useTabs';
import useSettings from '@/hooks/useSettings';
import useTable, { getComparator, emptyRows } from '@/hooks/useTable';
import { useGetAccess } from '@/hooks/useAccess';
import { useSnackbarMsg } from '@/hooks/useSnackbarMsg';
//
import { CLIENTS_TABLE_HEAD, applySortFilterClients } from '../utils';
import { PATH_USER } from '@/features/app';
import { clientManagementPath, ROOTS_CLIENT_MANAGEMENT } from '../routes/paths';
// types
import { User } from '@/features/users/shared/types';

// ----------------------------------------------------------------------

export default function ClientList() {
  const {
    dense,
    page,
    order,
    orderBy,
    rowsPerPage,
    setPage,
    //
    selected,
    //
    onSort,
    onChangeDense,
    onChangePage,
    onChangeRowsPerPage,
  } = useTable();

  const navigate = useNavigate();
  const allow = useGetAccess('clients');
  const { themeStretch } = useSettings();
  const { errorMsg, successMsg } = useSnackbarMsg();
  const queryClient = useQueryClient();

  // =================
  //      QUERIES
  // =================

  // SETTINGS
  const { data: userStatusOptions, isLoading: isSatusOptionsLoading } =
    useQuery({
      queryKey: ['settings'],
      queryFn: getSettingsData,
      onError: (error) => {
        errorMsg(error, `Something went wrong while fetching settings data `);
      },
      // with select we can transform the fetched data from the query
      // https://tanstack.com/query/v4/docs/framework/react/reference/useQuery
      select: (data) => {
        const userStatuses = data.userStatuses.map((s) =>
          s.display.toLowerCase()
        );
        return ['all', ...userStatuses];
      },
      // TODO: revisit these settings once MVP is ready to be launched
      // staleTime: 24 * 60 * 60 * 1000, // update every 24 hours
      // cacheTime: 24 * 60 * 60 * 1000, // update every 24 hours
    });

  // PROVIDERS
  const { data: clients, isLoading: isClientsDataLoading } = useQuery({
    queryKey: ['clients'],
    queryFn: getClients,
    onError: (error) =>
      errorMsg(error, 'There was an error while fetching clients data'),
  });

  // =======================
  //    FILTER/EDIT/DELETE
  // =======================
  const [filterName, setFilterName] = useState('');
  // TODO: reset selected on tab change
  const { currentTab: filterStatus, onChangeTab: onChangeFilterStatus } =
    useTabs('active');

  const handleFilterName = (filterName: string) => {
    setFilterName(filterName);
    setPage(0);
  };

  const deleteClientMutation = useMutation({
    mutationFn: (id: number) => deleteUser(id),
    onSuccess: (_apiResponse, variables) => {
      queryClient.setQueryData(['clients'], (old: User[] = []) =>
        old.filter((b) => b.id !== variables)
      );
      queryClient.invalidateQueries(['clients']);
      setPage(0);
      successMsg(`Client succesfully deleted`);
    },
    onError: (error) => {
      errorMsg(error, `There was an error while deleting client`);
    },
  });

  const handleDeleteRow = async (id: number) => {
    deleteClientMutation.mutate(id);
  };

  const handleEditRow = (id: number) => {
    navigate(
      clientManagementPath('edit', { userId: String(id), tabSid: 'details' })
    );
  };

  const dataFiltered = applySortFilterClients({
    tableData: clients || [],
    comparator: getComparator(order, orderBy),
    filterName,
    filterStatus,
  });

  const denseHeight = dense ? 52 : 72;

  const isNotFound =
    (!dataFiltered.length && !!filterName) ||
    (!dataFiltered.length && !!filterStatus) ||
    !dataFiltered.length;

  // ----------------------------------------------------------------------

  return (
    <Page title="Clients: List">
      <Container maxWidth={themeStretch ? false : 'lg'}>
        <HeaderBreadcrumbs
          heading="Client List"
          links={[
            { name: 'Dashboard', href: PATH_USER.root },
            { name: 'Client', href: ROOTS_CLIENT_MANAGEMENT },

            { name: 'List' },
          ]}
          action={
            allow.canCreate && (
              <Button
                variant="contained"
                component={RouterLink}
                to={clientManagementPath('new')}
                startIcon={<Iconify icon={'eva:plus-fill'} />}
              >
                Add Client
              </Button>
            )
          }
        />

        <Card>
          {isClientsDataLoading ? (
            <SkeletonLoadingTable />
          ) : (
            <>
              {isSatusOptionsLoading ? (
                <Skeleton variant="rounded" height={56} />
              ) : (
                <Tabs
                  allowScrollButtonsMobile
                  variant="scrollable"
                  scrollButtons="auto"
                  value={filterStatus}
                  onChange={onChangeFilterStatus}
                  sx={{ px: 2, bgcolor: 'background.neutral' }}
                >
                  {(userStatusOptions || []).map((tab) => (
                    <Tab disableRipple key={tab} label={tab} value={tab} />
                  ))}
                </Tabs>
              )}

              <Divider />

              <ToolbarWithSearchAndStatus
                filterText={filterName}
                onFilterText={handleFilterName}
                labels={{ search: 'Search in clients...' }}
              />

              <Scrollbar>
                <TableContainer sx={{ minWidth: 800, position: 'relative' }}>
                  <Table size={dense ? 'small' : 'medium'}>
                    <TableHeadCustom
                      order={order}
                      orderBy={orderBy}
                      headLabel={CLIENTS_TABLE_HEAD}
                      rowCount={dataFiltered.length}
                      numSelected={selected.length}
                      onSort={onSort}
                    />
                    <TableBody>
                      {dataFiltered
                        .slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                        .map((client) => (
                          <ClientTableRow
                            key={client.id}
                            row={client}
                            onDeleteRow={() =>
                              handleDeleteRow(Number(client.id))
                            }
                            onEditRow={() => handleEditRow(client.id)}
                            canDelete={allow.canDelete}
                            canUpdate={allow.canUpdate}
                          />
                        ))}

                      <TableEmptyRows
                        height={denseHeight}
                        emptyRows={emptyRows(
                          page,
                          rowsPerPage,
                          dataFiltered.length
                        )}
                      />

                      <TableNoData
                        isNotFound={isNotFound}
                        title="No clients found"
                      />
                    </TableBody>
                  </Table>
                </TableContainer>
              </Scrollbar>

              <Box sx={{ position: 'relative' }}>
                <TablePagination
                  rowsPerPageOptions={[5, 10, 25]}
                  component="div"
                  count={dataFiltered.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={onChangePage}
                  onRowsPerPageChange={onChangeRowsPerPage}
                />

                <FormControlLabel
                  control={<Switch checked={dense} onChange={onChangeDense} />}
                  label="Dense"
                  sx={{ px: 3, py: 1.5, top: 0, position: { md: 'absolute' } }}
                />
              </Box>
            </>
          )}
        </Card>
      </Container>
    </Page>
  );
}
