import { useState, useEffect } from 'react';
// import { useNavigate } from 'react-router-dom';
// @mui
import {
  Box,
  Card,
  Table,
  Stack,
  Switch,
  Tooltip,
  TableBody,
  Container,
  IconButton,
  TableContainer,
  TablePagination,
  FormControlLabel,
} from '@mui/material';
// hooks
import useSettings from '@/hooks/useSettings';
import useTable, { getComparator, emptyRows } from '@/hooks/useTable';
// utils
import { applySortFilter } from '../utils';
// components
import { Page, Iconify, Scrollbar, HeaderBreadcrumbs } from '@/components';
import {
  TableNoData,
  TableEmptyRows,
  TableHeadCustom,
  TableSelectedActions,
} from '@/components/table';
import {
  AuditTableRow,
  AuditTableToolbar,
  TableLoading,
} from '@/features/audit/components';
// api
import { getAuditLogs } from '../api';
// types
import { LogsData } from '../types';
import { uniq } from 'lodash';
// paths
import { PATH_USER } from '@/features/app';

let ACTIONS: string[] = ['all'];
let USERS: string[] = ['all'];

const TABLE_HEAD = [
  { id: 'user_info', label: 'User', align: 'left' },
  { id: 'action', label: 'Action', align: 'left' },
  { id: 'resource_name', label: 'Resource Name', align: 'left' },
  { id: 'resource_id', label: 'Resource ID', align: 'left', width: 140 },
  { id: 'changes', label: 'Fields Changed', align: 'left', width: 140 },
  { id: 'created_at', label: 'Date & Time', align: 'left' },
  { id: 'view', label: 'View', align: 'center' },
];

// ----------------------------------------------------------------------

export default function AuditList() {
  const { themeStretch } = useSettings();

  const {
    dense,
    page,
    order,
    orderBy,
    rowsPerPage,
    setPage,
    selected,
    onSelectRow,
    onSelectAllRows,
    onSort,
    onChangeDense,
    onChangePage,
    onChangeRowsPerPage,
  } = useTable({ defaultOrderBy: 'created_at', defaultOrder: 'desc' });

  const [tableData, setTableData] = useState<LogsData[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [filterUsers, setFilterUsers] = useState('all');
  const [filterActions, setFilterActions] = useState('all');
  const [filterStartDate, setFilterStartDate] = useState<Date | null>(null);
  const [filterEndDate, setFilterEndDate] = useState<Date | null>(null);
  const [filterName, setFilterName] = useState<string>('');

  const handleFilterName = (filterName: string) => {
    setFilterName(filterName);
    setPage(0);
  };

  const handleFilterUsers = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFilterUsers(event.target.value);
  };
  const handleFilterActions = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFilterActions(event.target.value);
  };

  const handleViewRow = (_id: string) => {
    // TODO
    // navigate(PATH_DASHBOARD.invoice.view(id));
  };

  const dataFiltered = applySortFilter({
    tableData,
    comparator: getComparator(order, orderBy),
    filterName,
    filterUsers,
    filterActions,
    filterStartDate,
    filterEndDate,
  });

  const isNotFound =
    (!dataFiltered.length && !!filterName) ||
    (!dataFiltered.length && !!filterActions) ||
    (!dataFiltered.length && !!filterUsers) ||
    (!dataFiltered.length && !!filterEndDate) ||
    (!dataFiltered.length && !!filterStartDate);

  const denseHeight = dense ? 56 : 76;

  useEffect(() => {
    async function getLogs() {
      const logsData = await getAuditLogs();
      setTableData(logsData);
    }
    getLogs();
  }, []);

  useEffect(() => {
    let users = uniq(tableData.map((item) => item.user_info.split('|')[0]));
    let actions = uniq(tableData.map((item) => item.action));
    users.length && actions.length && setIsLoading(false);
    USERS = ['all'].concat(users);
    ACTIONS = ['all'].concat(actions);
  }, [tableData]);

  return (
    <>
      <Page title="Audit: List">
        <Container maxWidth={themeStretch ? false : 'lg'}>
          <HeaderBreadcrumbs
            heading="Audit Logs"
            links={[
              { name: 'Dashboard', href: PATH_USER.root },
              { name: 'Augit Logs' },
            ]}
          />
          <Card>
            {!isLoading && (
              <AuditTableToolbar
                filterName={filterName}
                filterUsers={filterUsers}
                filterActions={filterActions}
                filterStartDate={filterStartDate}
                filterEndDate={filterEndDate}
                onFilterName={handleFilterName}
                onFilterUsers={handleFilterUsers}
                onFilterActions={handleFilterActions}
                onFilterStartDate={(newValue) => {
                  setFilterStartDate(newValue);
                }}
                onFilterEndDate={(newValue) => {
                  setFilterEndDate(newValue);
                }}
                optionsUsers={USERS}
                optionsActions={ACTIONS}
              />
            )}
            <Scrollbar>
              <TableContainer sx={{ minWidth: 800, position: 'relative' }}>
                {selected.length > 0 && (
                  <TableSelectedActions
                    dense={dense}
                    numSelected={selected.length}
                    rowCount={tableData.length}
                    onSelectAllRows={(checked) =>
                      onSelectAllRows(
                        checked,
                        tableData.map((row) => String(row.id))
                      )
                    }
                    actions={
                      <Stack spacing={1} direction="row">
                        <Tooltip title="Sent">
                          <IconButton color="primary">
                            <Iconify icon={'ic:round-send'} />
                          </IconButton>
                        </Tooltip>

                        <Tooltip title="Download">
                          <IconButton color="primary">
                            <Iconify icon={'eva:download-outline'} />
                          </IconButton>
                        </Tooltip>

                        <Tooltip title="Print">
                          <IconButton color="primary">
                            <Iconify icon={'eva:printer-fill'} />
                          </IconButton>
                        </Tooltip>

                        <Tooltip title="Delete">
                          <IconButton color="primary">
                            <Iconify icon={'eva:trash-2-outline'} />
                          </IconButton>
                        </Tooltip>
                      </Stack>
                    }
                  />
                )}

                <Table size={dense ? 'small' : 'medium'}>
                  <TableHeadCustom
                    order={order}
                    orderBy={orderBy}
                    headLabel={TABLE_HEAD}
                    rowCount={tableData.length}
                    numSelected={selected.length}
                    onSort={onSort}
                    onSelectAllRows={(checked) =>
                      onSelectAllRows(
                        checked,
                        tableData.map((row) => String(row.id))
                      )
                    }
                  />

                  <TableBody>
                    {dataFiltered
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((row) => (
                        <AuditTableRow
                          key={row.id}
                          row={row}
                          selected={selected.includes(String(row.id))}
                          onSelectRow={() => onSelectRow(String(row.id))}
                          onViewRow={() => handleViewRow(String(row.id))}
                        />
                      ))}
                    {isLoading ? (
                      <TableLoading />
                    ) : (
                      <TableNoData isNotFound={isNotFound} />
                    )}
                    <TableEmptyRows
                      height={denseHeight}
                      emptyRows={emptyRows(page, rowsPerPage, tableData.length)}
                    />
                  </TableBody>
                </Table>
              </TableContainer>
            </Scrollbar>

            <Box sx={{ position: 'relative' }}>
              <TablePagination
                rowsPerPageOptions={[25, 50, 100]}
                component="div"
                count={dataFiltered.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={onChangePage}
                onRowsPerPageChange={onChangeRowsPerPage}
              />

              <FormControlLabel
                control={<Switch checked={dense} onChange={onChangeDense} />}
                label="Dense"
                sx={{ px: 3, py: 1.5, top: 0, position: { md: 'absolute' } }}
              />
            </Box>
          </Card>
        </Container>
      </Page>
    </>
  );
}
