import { useState } from 'react';
import { useParams } from 'react-router';
import { useMutation, useQueryClient } from '@tanstack/react-query';
//
import {
  Box,
  Button,
  Card,
  Stack,
  Table,
  TableBody,
  TableContainer,
} from '@mui/material';
//
import { Iconify, Scrollbar } from '@/components';
import {
  TableEmptyRows,
  TableHeadCustom,
  TableNoData,
} from '@/components/table';
import { ToolbarWithSearchAndStatus } from '@/components/toolbar';
import { ClientAddressDialog, ClientAddressTableRow } from '..';
import { SectionTitle } from '@/features/users/shared/components';
// api
import { deleteAddress } from '@/features/users/shared/api';
// utils + hooks
import useTable, { emptyRows, getComparator } from '@/hooks/useTable';
import { useGetAccess } from '@/hooks/useAccess';
import { useSnackbarMsg } from '@/hooks/useSnackbarMsg';
import useToggle from '@/hooks/useToggle';
import {
  CLIENT_ADDRESSES_TABLE_HEAD,
  applySortFilterClientAddress,
} from '../../utils';
// types
import { UserAddress } from '@/features/users/shared/types';

type AddressTableProps = {
  addresses: UserAddress[] | undefined;
  queryKey: string[];
  useGetAccessKey: string;
};

export default function ClientAddressTable({
  addresses,
  queryKey,
  useGetAccessKey,
}: AddressTableProps) {
  const {
    dense,
    page,
    order,
    orderBy,
    rowsPerPage,
    setPage,
    //
    selected,
    setSelected,
    //
    onSort,
  } = useTable();

  const allow = useGetAccess(useGetAccessKey);
  const { errorMsg, successMsg } = useSnackbarMsg();
  const { userId } = useParams();
  const queryClient = useQueryClient();

  const [searchFieldValue, setSearchFieldValue] = useState('');
  const [dialogMode, setDialogMode] = useState<'add' | 'edit'>('add');
  const [currentAddress, setCurrentAddress] = useState<UserAddress | null>(
    null
  );

  const deleteAddressMutation = useMutation({
    mutationFn: (id: number) => deleteAddress(id),
    onSuccess: () => {
      queryClient.invalidateQueries(queryKey);
      successMsg(`Address succesfully deleted`);
    },
    onError: (error) => {
      errorMsg(error, `There was an error while deleting address`);
    },
  });

  const dataFiltered = applySortFilterClientAddress({
    tableData: userId ? addresses || [] : [],
    comparator: getComparator(order, orderBy),
    searchFieldValue,
  });

  const handleSearchFieldValueChange = (newValue: string) => {
    setSearchFieldValue(newValue);
    setPage(0);
    setSelected([]);
  };

  const isNotFound = !dataFiltered.length && !!searchFieldValue;
  const denseHeight = dense ? 52 : 72;

  const {
    toggle: isAddressDialogOpen,
    onOpen: openAddressDialog,
    onClose: closeAddressDialog,
  } = useToggle();

  const handleEditDialog = (addressId: number) => {
    const currentAddress = (addresses || []).find(
      (address) => address.id === addressId
    );
    setCurrentAddress(currentAddress ?? null);
    setDialogMode('edit');
    openAddressDialog();
  };

  const handleDeleteRow = (id: number) => deleteAddressMutation.mutate(id);

  return (
    <Stack spacing={2} component={Card} sx={{ p: 3 }}>
      <ClientAddressDialog
        isDialogOpen={isAddressDialogOpen}
        closeDialog={closeAddressDialog}
        mode={dialogMode}
        currentAddress={currentAddress}
        queryKey={queryKey}
      />
      <Box>
        <Stack direction="row" justifyContent="space-between">
          <SectionTitle title="Addresses" />
          <Button
            variant="contained"
            onClick={() => {
              setDialogMode('add');
              openAddressDialog();
            }}
            size="small"
            startIcon={<Iconify icon="eva:plus-outline" />}
          >
            Add new address
          </Button>
        </Stack>
        <ToolbarWithSearchAndStatus
          filterText={searchFieldValue}
          onFilterText={handleSearchFieldValueChange}
          labels={{ search: 'Search in addresses...' }}
        />
        <Scrollbar>
          <TableContainer>
            <Table size="small">
              <TableHeadCustom
                order={order}
                orderBy={orderBy}
                headLabel={CLIENT_ADDRESSES_TABLE_HEAD}
                rowCount={dataFiltered.length}
                numSelected={selected.length}
                onSort={onSort}
              />
              <TableBody>
                {dataFiltered
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((address) => (
                    <ClientAddressTableRow
                      key={address.id}
                      row={address}
                      onDeleteRow={() => handleDeleteRow(Number(address.id))}
                      onEditRow={() => handleEditDialog(Number(address.id))}
                      canDelete={allow.canDelete}
                      canUpdate={allow.canUpdate}
                      queryKey={queryKey}
                    />
                  ))}

                <TableEmptyRows
                  height={denseHeight}
                  emptyRows={emptyRows(page, rowsPerPage, dataFiltered.length)}
                />

                <TableNoData
                  isNotFound={isNotFound}
                  title="No addresses found"
                />
              </TableBody>
            </Table>
          </TableContainer>
        </Scrollbar>
      </Box>
    </Stack>
  );
}
