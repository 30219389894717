import { useNavigate, useParams } from 'react-router-dom';
// @mui
import { Container } from '@mui/material';
//
import Dialog from '@/components/dialog/Dialog';
import { HeaderBreadcrumbs, Page } from '@/components';
import { BookingNewEditForm } from '../components';
// routes
import {
  PATH_BOOKINGS_MANAGEMENT,
  bookingsManagementPath,
} from '../routes/paths';
// hooks
import useSettings from '@/hooks/useSettings';
import useToggle from '@/hooks/useToggle';
import { useSnackbarMsg } from '@/hooks/useSnackbarMsg';
// API
import { deleteBooking } from '../api';

// ----------------------------------------------------------------------

export default function CreateEditBooking() {
  const { themeStretch } = useSettings();
  const { bookingId } = useParams();
  const navigate = useNavigate();

  const {
    toggle: isDeleteDialogOpen,
    onClose: closeDeleteDialog,
    setToggle: setIsDeleteDialogOpen,
  } = useToggle();

  const { errorMsg, successMsg } = useSnackbarMsg();

  const handleDeleteBooking = async () => {
    // TODO: add useMutation
    try {
      await deleteBooking(Number(bookingId));
      successMsg(`Booking #${bookingId} has been deleted`);
    } catch (error) {
      errorMsg(error, `Couldn't delete booking: ${error.message}`);
    } finally {
      closeDeleteDialog();
      navigate(bookingsManagementPath('list'));
    }
  };

  return (
    <Page title="Bookings Management">
      <Container maxWidth={themeStretch ? false : 'lg'}>
        <HeaderBreadcrumbs
          heading="Bookings Management"
          links={[
            { name: 'Dashboard', href: PATH_BOOKINGS_MANAGEMENT.root },
            {
              name: 'Bookings Management',
              href: `${PATH_BOOKINGS_MANAGEMENT.root}${PATH_BOOKINGS_MANAGEMENT.list}`,
            },
            { name: !bookingId ? 'New booking' : 'Edit booking' },
          ]}
        />

        <BookingNewEditForm />

        {/* DELETE BOOKINGS DIALOG */}
        <Dialog
          dialogTitle="Delete booking"
          dialogText={`Are you sure you want to delete this booking: #${bookingId}?`}
          dialogAgree={() => handleDeleteBooking()}
          dialogDisagree={closeDeleteDialog}
          isDialogOpen={isDeleteDialogOpen}
          setIsDialogOpen={setIsDeleteDialogOpen}
          handleCloseDialog={closeDeleteDialog}
          agreeButtonText="Delete"
          disagreeButtonText="Cancel"
          agreeButtonColor="error"
          disagreeButtonColor="inherit"
        />
      </Container>
    </Page>
  );
}
