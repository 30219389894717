import { useCallback, BaseSyntheticEvent, useState } from 'react';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useNavigate, useParams } from 'react-router-dom';
import { FieldErrors, useForm, useWatch } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { capitalize, isEmpty } from 'lodash';
import parsePhoneNumber from 'libphonenumber-js';
//
import { Box, Card, Grid, MenuItem, Stack, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import {
  FormProvider,
  RHFSwitch,
  RHFTextField,
  RHFUploadAvatar,
} from '@/components/hook-form';
import { SkeletonLoadingTable } from '@/components/skeleton';
import { ClientAddressPlaceholder, ClientAddressTable } from '..';
import {
  SectionTitle,
  UserPasswordReset,
} from '@/features/users/shared/components';
// API
import { getSettingsData, uploadImage } from '@/features/app/api';
import {
  getSingleUser,
  createNewUser,
  updateUser,
} from '@/features/users/shared/api';
// utils + hooks
import { useSnackbarMsg } from '@/hooks/useSnackbarMsg';
import { fData } from '@/utils/formatNumber';
import { NEW_CLIENT_SCHEMA, NEW_CLIENT_DEFAULT_VALUES } from '../../utils';
import { clientManagementPath } from '../../routes/paths';
// types
import { FormValuesProps } from '../../types';
import { UserDto } from '@/features/users/shared/types';
import { MAX_PROFILE_PIC_UPLOAD_SIZE } from '@/utils/maxFileSizes';

// ----------------------------------------------------------------------

export default function ClientDetails() {
  const navigate = useNavigate();
  const { errorMsg, successMsg } = useSnackbarMsg();
  const { userId } = useParams();
  const queryClient = useQueryClient();
  const [profileImageUrl, setProfileImageUrl] = useState<string>('');

  // ========================
  //     REACT HOOK FROM
  // ========================

  const methods = useForm<FormValuesProps>({
    resolver: yupResolver(NEW_CLIENT_SCHEMA),
    defaultValues: NEW_CLIENT_DEFAULT_VALUES,
  });

  const {
    handleSubmit,
    reset,
    setValue,
    formState: { isSubmitting, errors },
    control,
  } = methods;

  const { return_to_edit_user } = useWatch({ control });

  // =================
  //      QUERIES
  // =================
  const { data: userStatuses } = useQuery({
    queryKey: ['settings'],
    queryFn: getSettingsData,
    onError: (error) => {
      errorMsg(error, `Something went wrong while fetching settings data `);
    },
    select: (data) =>
      data.userStatuses.filter((s) => s.display !== 'Removed') || [],
    refetchOnWindowFocus: false,
    // TODO: revisit these settings once MVP is ready to be launched
    // staleTime: 24 * 60 * 60 * 1000, // update every 24 hours
    // cacheTime: 24 * 60 * 60 * 1000, // update every 24 hours
  });

  const { data: currentClient, isLoading: isClientDataLoading } = useQuery({
    queryKey: ['clients', userId],
    queryFn: () => getSingleUser(Number(userId)),
    onSuccess: (data) => {
      reset({
        first_name: data.first_name ?? '',
        last_name: data.last_name ?? '',
        email: data.email ?? '',
        phone_number: data.phone ?? '',
        profile_image: data.profile_image ?? '',
        status: data.user_statuses ? data?.user_statuses.id : '',
        short_description: data.short_description ?? '',
        gender: data.gender ? capitalize(data.gender) : '',
        admin_notes: data.admin_notes ?? '',
        is_chat_user: data.is_chat_user,
      });
      setProfileImageUrl(data.profile_image ?? '');
    },
    onError: (error) => {
      errorMsg(error, `Something went wrong while fetching current client`);
    },
    enabled: !!userId && !!userStatuses,
    refetchOnWindowFocus: false,
  });

  const createClientMutation = useMutation({
    mutationFn: (clientDto: UserDto) => createNewUser(clientDto),
    onSuccess: (data) => {
      queryClient.invalidateQueries(['clients']);
      successMsg('Client successfully created!');
      return_to_edit_user
        ? navigate(
            clientManagementPath('edit', {
              userId: String(data.id),
              tabSid: 'details',
            })
          )
        : navigate('/clients');
    },
    onError: (error) =>
      errorMsg(error, `Something went wrong while creating client`),
  });

  const updateClientMutation = useMutation({
    mutationFn: ({
      clientId,
      clientDto,
    }: {
      clientId: number;
      clientDto: UserDto;
    }) => updateUser(clientId, clientDto),
    onSuccess: () => {
      queryClient.invalidateQueries(['clients', userId]);
      successMsg('Client successfully updated!');
      navigate('/clients');
    },
    onError: (error) =>
      errorMsg(error, `Something went wrong while updating client`),
  });

  const onSubmitClient = (
    data: FormValuesProps,
    event?: BaseSyntheticEvent
  ) => {
    // prevent default form submission in parent component
    event && event.preventDefault();
    event && event.stopPropagation();

    const parsedPhoneNumber = parsePhoneNumber(data.phone_number, 'GB');

    const clientDto = {
      first_name: data.first_name,
      last_name: data.last_name,
      email: data.email,
      phone: parsedPhoneNumber?.number,
      status: Number(data.status),
      short_description: data.short_description,
      admin_notes: data.admin_notes,
      profile_image: profileImageUrl,
      gender: data.gender.toLowerCase(),
      user_type: 1,
      is_chat_user: data.is_chat_user,
    };

    !isEmpty(currentClient)
      ? updateClientMutation.mutate({ clientId: Number(userId), clientDto })
      : createClientMutation.mutate(clientDto);
  };

  // UPLOAD PHOTO
  const uploadMutation = useMutation({
    mutationFn: (formData: FormData) => uploadImage(formData),
    onSuccess: ({
      data: {
        data: { file: fileURL },
      },
    }) => setProfileImageUrl(fileURL),
    onError: (error) => {
      errorMsg(error, `Couldn't upload profile image`);
    },
  });

  const handleDrop = useCallback(
    (acceptedFiles: File[]) => {
      const file = acceptedFiles[0];

      if (file) {
        setValue(
          'profile_image',
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        );
        const formData = new FormData();
        formData.append('file', file);
        uploadMutation.mutate(formData);
      }
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [setValue]
  );

  // ========================
  //    HANDLE FORM ERRORS
  // ========================

  const handleFormError = (errors: FieldErrors<FormValuesProps>) => {
    const errorKeys = Object.keys(errors);
    !!errorKeys.length &&
      errorMsg(
        `\nPlease complete the required fields: ${errorKeys.join(', ')}`
      );
  };
  errors && handleFormError(errors);

  // ----------------------------------------------------------------------
  // TODO: the FormProvider is broken into multiple parts probably because of the nested froms
  //       Use portal like in PorviderDetails

  return (
    <>
      {userId && isClientDataLoading ? (
        <SkeletonLoadingTable />
      ) : (
        <Grid container spacing={2}>
          {/* LEFT CARD */}
          <Grid
            item
            container
            spacing={2}
            xs={12}
            md={4}
            sx={{ height: 'fit-content' }}
          >
            <Grid item xs={12}>
              <Box component={Card} sx={{ p: 3 }}>
                <FormProvider
                  methods={methods}
                  onSubmit={handleSubmit(onSubmitClient)}
                >
                  <Stack component={Card} sx={{ p: 3 }}>
                    <RHFUploadAvatar
                      name="profile_image"
                      maxSize={MAX_PROFILE_PIC_UPLOAD_SIZE}
                      onDrop={handleDrop}
                      isAvatarUploading={uploadMutation.isLoading}
                      removeAvatar={() => setProfileImageUrl('')}
                      helperText={
                        <Typography
                          variant="caption"
                          sx={{
                            mt: 2,
                            mx: 'auto',
                            display: 'block',
                            textAlign: 'center',
                            color: 'text.secondary',
                          }}
                        >
                          Allowed *.jpeg, *.jpg, *.png, *.gif
                          <br /> max size of{' '}
                          {fData(MAX_PROFILE_PIC_UPLOAD_SIZE)}
                        </Typography>
                      }
                    />
                    <RHFSwitch
                      name="is_chat_user"
                      labelPlacement="start"
                      label={
                        <Typography variant="subtitle2">Chat user</Typography>
                      }
                      sx={{
                        mx: 0,
                        width: 1,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        gap: 2,
                        mt: 2,
                      }}
                    />
                  </Stack>
                </FormProvider>
              </Box>
            </Grid>

            {userId && (
              <Grid item xs={12}>
                <UserPasswordReset currentEmail={currentClient?.email} />
              </Grid>
            )}
            <Grid item xs={12}>
              <Stack
                direction="column"
                spacing={1}
                component={Card}
                sx={{ p: 3 }}
              >
                <SectionTitle title="Admin Notes" />
                <FormProvider
                  methods={methods}
                  onSubmit={handleSubmit(onSubmitClient)}
                >
                  <RHFTextField
                    label="These notes are only visible to admins"
                    name="admin_notes"
                    multiline
                    minRows={3}
                    fullWidth
                  />
                </FormProvider>
              </Stack>
            </Grid>
          </Grid>

          {/* RIGHT CARD */}
          <Grid
            item
            container
            spacing={2}
            xs={12}
            md={8}
            sx={{ height: 'fit-content' }}
          >
            <Grid item xs={12}>
              <FormProvider
                methods={methods}
                onSubmit={handleSubmit(onSubmitClient)}
              >
                <Stack
                  spacing={2}
                  component={Card}
                  sx={{ p: 3 }}
                  direction="column"
                >
                  <Stack direction={{ xs: 'column', md: 'row' }} spacing={2}>
                    <RHFTextField name="first_name" label="First Name" />
                    <RHFTextField name="last_name" label="Last Name" />
                  </Stack>
                  <Stack direction={{ xs: 'column', md: 'row' }} spacing={2}>
                    <RHFTextField name="email" label="Email Address" />
                    <RHFTextField name="phone_number" label="Phone Number" />
                  </Stack>

                  <Stack direction={{ xs: 'column', md: 'row' }} spacing={2}>
                    <RHFTextField name="status" label="Status" select>
                      {(userStatuses || []).map((option) => (
                        <MenuItem key={option.id} value={option.id}>
                          {option.display}
                        </MenuItem>
                      ))}
                    </RHFTextField>

                    <RHFTextField name="gender" label="Gender" select>
                      {['Male', 'Female', 'Other'].map((option) => (
                        <MenuItem key={option} value={option}>
                          {option}
                        </MenuItem>
                      ))}
                    </RHFTextField>
                  </Stack>
                </Stack>
                {!userId && <ClientAddressPlaceholder />}
              </FormProvider>
            </Grid>
            <Grid item xs={12}>
              {userId && (
                <ClientAddressTable
                  addresses={currentClient?.address}
                  queryKey={['clients', userId]}
                  useGetAccessKey="clients"
                />
              )}
            </Grid>
            <Grid item xs={12}>
              <Stack alignItems="flex-end" sx={{ mt: 3 }}>
                <FormProvider
                  methods={methods}
                  onSubmit={handleSubmit(onSubmitClient)}
                >
                  <LoadingButton
                    type="submit"
                    variant="contained"
                    loading={isSubmitting}
                  >
                    {currentClient ? 'Save Changes' : 'Create Client'}
                  </LoadingButton>
                </FormProvider>
              </Stack>
            </Grid>
          </Grid>
        </Grid>
      )}
    </>
  );
}
