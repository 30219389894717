import { useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';
// components
import { Grid, Skeleton } from '@mui/material';
import { NavSectionVertical } from '@/components/nav-section';
// api
import { getSettingsCategories } from '../api';
// types
import { SettingsResponseCategories } from '../types';

// ----------------------------------------------------------------------

export default function SideNavigation() {
  const [navData, setNavData] = useState<SettingsResponseCategories[]>([]);

  useEffect(() => {
    getSettingsCategories().then((response) => setNavData(response));
  }, []);

  const navConfig = navData.map(({ module, categories }) => ({
    subheader: module,
    hide: true,
    items: [
      {
        title: module,
        path:
          categories[0].route.split('/').length > 2
            ? categories[0].route.split('/').slice(0, -1).join('/')
            : categories[0].route,
        children: [
          categories.map(({ name, route }) => ({
            title: name,
            path: `/${route}`,
          })),
        ].flat(),
      },
    ],
  }));
  return (
    <>
      {!navData.length ? (
        <>
          <Grid container spacing={3}>
            <Grid item xs={12} md={3}>
              {[...Array(5)].map((_, index) => (
                <Skeleton height={60} key={index} />
              ))}
            </Grid>
            <Grid item xs={12} md={9}>
              {[...Array(5)].map((_, index) => (
                <Skeleton height={60} key={index} />
              ))}
            </Grid>
          </Grid>
        </>
      ) : (
        <Grid container spacing={3}>
          <Grid item xs={12} md={3}>
            <NavSectionVertical navConfig={navConfig} />
          </Grid>
          <Grid item xs={12} md={9}>
            <Outlet />
          </Grid>
        </Grid>
      )}
    </>
  );
}
