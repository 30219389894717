import { Typography } from '@mui/material';
import { Label } from '@/components';
//
import AddressWithGoogleMapsIconButton from '@/components/AddressWithGoogleMapsIconButton';
import { TableRowLink } from '@/components/table';
import { clientManagementPath } from '@/features/users/clients';
import { tableRowLinkSx } from '@/features/bookings-management/utils';

type AddressInfoProps = {
  name: string;
  address: string | null;
  clientId?: number;
  phone?: string;
  postcode?: string;
  hideGoogleMapsButton?: boolean;
  serviceLabelText?: string;
};

export default function AddressInfo({
  name,
  address,
  clientId,
  phone,
  postcode,
  hideGoogleMapsButton = false,
  serviceLabelText,
}: AddressInfoProps) {
  const addressString = address || 'No default address available';

  return (
    <>
      {clientId ? (
        <TableRowLink
          linkText={name}
          linkTo={clientManagementPath('edit', {
            userId: String(clientId),
            tabSid: 'details',
          })}
          sx={tableRowLinkSx}
        />
      ) : (
        <Typography variant="subtitle2">{name}</Typography>
      )}
      <AddressWithGoogleMapsIconButton
        formatted_address={addressString}
        postcode={postcode}
        hideGoogleMapsButton={hideGoogleMapsButton}
      />
      {phone && <Typography variant="body2">Phone: {phone}</Typography>}
      {serviceLabelText && (
        <Label color="info" sx={{ mt: 1 }}>
          {serviceLabelText}
        </Label>
      )}
    </>
  );
}
