import { useEffect, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { TimePicker } from '@mui/x-date-pickers/';

// mui
import { FormControl, TextField } from '@mui/material';
import { format, set } from 'date-fns';

type Props = {
  name: string;
  label: string;
  value?: string;
  minTime?: any;
  size?: 'small' | 'medium';
};
// value HH:MM format
export default function RHFTimePicker({
  name,
  label,
  value,
  minTime,
  size = 'small',
}: Props) {
  const { control, setValue } = useFormContext();
  const [pickerValue, setPickerValue] = useState<Date | null>(null);
  useEffect(() => {
    value &&
      setPickerValue(
        set(new Date(), {
          hours: Number(value?.split(':')[0]),
          minutes: Number(value?.split(':')[1]),
        })
      );
  }, [value]);

  return (
    <FormControl sx={{ width: '100%' }}>
      <Controller
        control={control}
        name={name}
        render={({ field, fieldState: { error } }) => {
          field.value && (error = undefined);
          return (
            <TimePicker
              label={label}
              minTime={minTime}
              value={pickerValue}
              onChange={(newValue) => {
                setPickerValue(newValue);
                setValue(name, format(newValue as Date, 'HH:mm'));
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  size="small"
                  error={!!error}
                  helperText={error?.message}
                />
              )}
            />
          );
        }}
      />
    </FormControl>
  );
}
