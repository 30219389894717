import { useState, MouseEvent } from 'react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
// mui
import { Box, MenuItem, TableCell, TableRow, Typography } from '@mui/material';
// components
import { Iconify, Label } from '@/components';
import { TableMoreMenu, TableRowLink } from '@/components/table';
import DialogSimple from '@/components/dialog/DialogSimple';
import AddressWithGoogleMapsIconButton from '@/components/AddressWithGoogleMapsIconButton';
// api
import { updateDefaultAddress } from '@/features/users/shared/api';
// hooks
import useToggle from '@/hooks/useToggle';
import { useSnackbarMsg } from '@/hooks/useSnackbarMsg';
// types
import { UserAddress } from '@/features/users/shared/types';

type AddressProps = {
  row: UserAddress;
  onEditRow: VoidFunction;
  onDeleteRow: VoidFunction;
  canDelete: boolean;
  canUpdate: boolean;
  queryKey: string[];
};

// ----------------------------------------------------------------------
export default function ClientTableAddressRow({
  row,
  onEditRow,
  onDeleteRow,
  canDelete,
  canUpdate,
  queryKey,
}: AddressProps) {
  const { errorMsg, successMsg } = useSnackbarMsg();
  const queryClient = useQueryClient();

  // user data from parent component
  const {
    id,
    name,
    town_or_city,
    postcode,
    formatted_address,
    default: isDefault,
  } = row;

  // ============================
  //      3DOT - MENU ACTIONS
  // ============================
  const [openMenu, setOpenMenuActions] = useState<HTMLElement | null>(null);

  const handleOpenMenu = (event: MouseEvent<HTMLElement>) => {
    setOpenMenuActions(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setOpenMenuActions(null);
  };
  // =================
  //    EDIT/DELETE
  // =================
  const {
    toggle: isDeleteDialogOpen,
    onOpen: openDeleteDialog,
    onClose: closeDeleteDialog,
  } = useToggle();

  const handleDelete = () => {
    onDeleteRow();
    closeDeleteDialog();
  };

  const updateAddressMutation = useMutation({
    mutationFn: (id: number) => updateDefaultAddress(id),
    onSuccess: () => {
      queryClient.invalidateQueries(queryKey);
      successMsg(`Address succesfully set as default`);
    },
    onError: (error) => {
      errorMsg(error, `There was an error while updating address`);
    },
  });
  // ----------------------------------------------------------------------

  return (
    <>
      <DialogSimple
        dialogTitle={`Delete user address`}
        open={isDeleteDialogOpen}
        onClickButtonClose={closeDeleteDialog}
        onClickButtonLoading={handleDelete}
        loadingButton={true}
        disagreeButton={true}
        loadingButtonText="Delete"
        loadingButtonColor="error"
        disagreeButtonText="Cancel"
        disagreeButtonColor="inherit"
      >
        <Box sx={{ px: 3 }}>
          <Typography variant="body2" sx={{ mb: 2 }}>
            Are you sure you want to delete this address:{' '}
            <span style={{ fontWeight: 600 }}>{formatted_address}</span> ?
          </Typography>
        </Box>
      </DialogSimple>

      <TableRow hover>
        <TableCell align="left">
          <TableRowLink
            linkText={!!name.length ? name : '<unnamed address>'}
            linkTo="#"
            onClick={onEditRow}
          />
          {isDefault && (
            <Label color="info" sx={{ ml: 0.5 }}>
              Default
            </Label>
          )}
        </TableCell>
        <TableCell align="left">{town_or_city}</TableCell>
        <TableCell align="left">{postcode}</TableCell>
        <TableCell align="left">
          <AddressWithGoogleMapsIconButton
            formatted_address={formatted_address}
            postcode={postcode}
          />
        </TableCell>

        <TableCell align="right">
          {(canDelete || canUpdate) && (
            <TableMoreMenu
              open={openMenu}
              onOpen={handleOpenMenu}
              onClose={handleCloseMenu}
              actions={
                <>
                  {canUpdate && (
                    <MenuItem
                      onClick={() => {
                        updateAddressMutation.mutate(id);
                        handleCloseMenu();
                      }}
                    >
                      <Iconify icon={'eva:star-outline'} />
                      Set default
                    </MenuItem>
                  )}
                  {canUpdate && (
                    <MenuItem
                      onClick={() => {
                        onEditRow();
                        handleCloseMenu();
                      }}
                    >
                      <Iconify icon={'eva:edit-fill'} />
                      Edit
                    </MenuItem>
                  )}
                  {canDelete && (
                    <MenuItem
                      onClick={() => {
                        openDeleteDialog();
                        handleCloseMenu();
                      }}
                      sx={{ color: 'error.main' }}
                    >
                      <Iconify icon={'eva:trash-2-outline'} />
                      Delete
                    </MenuItem>
                  )}
                </>
              }
            />
          )}
        </TableCell>
      </TableRow>
    </>
  );
}
