import { Stack, Box, Typography } from '@mui/material';
import { Iconify, Image } from '@/components';
//
import { useSelector } from '@/stores';
import useResponsive from '@/hooks/useResponsive';
//
import { BookingSession } from '@/features/bookings-management/types';

type ExtrasViewProps = {
  bookingSession: BookingSession | undefined;
  sessionView: boolean;
};
export default function VBMCleaningExtrasView({
  bookingSession,
  sessionView,
}: ExtrasViewProps) {
  const downMd = useResponsive('down', 'md');
  const booking = useSelector((state) => state.booking);
  const bookingItems =
    bookingSession && sessionView
      ? bookingSession.booking_items
      : booking.booking_items;
  const cleaningProductsRequested =
    bookingSession && sessionView
      ? bookingSession.cleaning_products
      : booking.cleanDetails.requestForCleaningProduct;

  const iconSize = downMd ? 20 : 30;
  return (
    <Stack spacing={1.5} sx={{ pl: 1, pt: 0.5 }}>
      {(bookingItems || []).map((item) => (
        <Stack direction="row" alignItems="center" key={item.id} spacing={1}>
          <Box sx={{ width: 35 }}>
            <Image
              src={item.icon_url}
              alt={item.name}
              sx={{ width: iconSize, height: iconSize }}
            />
          </Box>
          <Typography fontSize={downMd ? 12 : 16}>{item.name}</Typography>
        </Stack>
      ))}
      {cleaningProductsRequested && (
        <Stack
          direction="row"
          alignItems="center"
          spacing={1}
          sx={{
            py: 0.5,
            backgroundColor: (theme) => theme.palette.warning.main,
            borderRadius: 1,
          }}
        >
          <Box
            sx={{
              width: 35,
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Iconify icon="mdi:spray-bottle" width={iconSize} />
          </Box>
          <Typography fontSize={downMd ? 12 : 16}>Cleaning products</Typography>
        </Stack>
      )}
    </Stack>
  );
}
