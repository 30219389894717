import axios, { AxiosRequestConfig } from 'axios';
import { SERVICE_API_BASE, SERVICE_API_VERSION } from '@/config';

const controllers = new Map<string, AbortController>(); // store controllers in a Map keyed by request identifiers (such as config.url or a custom ID)

const axiosInstance = axios.create({
  baseURL: SERVICE_API_BASE,
  headers: {
    'X-Api-Version': SERVICE_API_VERSION,
  },
});

axiosInstance.interceptors.request.use(function (config: AxiosRequestConfig) {
  const controller = new AbortController();
  config.signal = controller.signal;

  const requestKey = `${config.method}:${config.url}`;
  controllers.set(requestKey, controller);

  config?.headers && (config.headers.Accept = 'application/json');
  return config;
});

axiosInstance.interceptors.response.use(
  (response) => {
    const requestKey = `${response.config.method}:${response.config.url}`;
    controllers.delete(requestKey); // Remove the controller for the completed request
    return response;
  },
  (error) => {
    const requestKey = `${error.config?.method}:${error.config?.url}`;
    controllers.delete(requestKey);

    // Handle unauthorized or forbidden errors
    if (error.response?.status === 403 || error.response?.status === 401) {
      console.error('Unauthorized: ', error.response.status);
      // window.location.href = '/';
    }

    // Check if the request was cancelled
    if (error.code === 'ERR_CANCELED') {
      return Promise.reject('Cancelled');
    }

    return Promise.reject(
      (error.response && error.response.data) ||
        'Something went wrong while intercepting the request -- axios.ts'
    );
  }
);

export const cancelRequest = (requestKey: string) => {
  const controller = controllers.get(requestKey);
  if (controller) {
    controller.abort();
    controllers.delete(requestKey);
  }
};

export default axiosInstance;
