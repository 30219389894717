import { useState, ChangeEvent } from 'react';
import { Stack, InputAdornment, TextField, MenuItem } from '@mui/material';

import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
// components
import Iconify from '@/components/Iconify';

type Props = {
  optionsRole: string[];
  filterName: string;
  filterTable: string;
  onFilterName: (value: string) => void;
  onFilterToDate: (value: Date | null) => void;
  onFilterFromDate: (value: Date | null) => void;
  onFilterTable: (event: ChangeEvent<HTMLInputElement>) => void;
};

export default function NotificationTableToolbar({
  filterName,
  filterTable,
  optionsRole,
  onFilterName,
  onFilterFromDate,
  onFilterToDate,
  onFilterTable,
}: Props) {
  const [from, setFrom] = useState<Date | null>();
  const [to, setTo] = useState<Date | null>();

  const handleFromChange = (newValue: Date | null) => {
    onFilterFromDate(newValue);
    setFrom(newValue);
  };
  const handleToChange = (newValue: Date | null) => {
    onFilterToDate(newValue);
    setTo(newValue);
  };
  return (
    <Stack
      spacing={2}
      direction={{ xs: 'column', sm: 'row' }}
      sx={{ py: 2.5, px: 3 }}
    >
      <TextField
        fullWidth
        select
        label="Role"
        value={filterTable}
        onChange={onFilterTable}
        SelectProps={{
          MenuProps: {
            sx: { '& .MuiPaper-root': { maxHeight: 260 } },
          },
        }}
        sx={{
          maxWidth: { sm: 120 },
          textTransform: 'capitalize',
        }}
      >
        {optionsRole.map((option) => (
          <MenuItem
            key={option}
            value={option}
            sx={{
              mx: 1,
              my: 0.5,
              borderRadius: 0.75,
              typography: 'body2',
              textTransform: 'capitalize',
            }}
          >
            {option}
          </MenuItem>
        ))}
      </TextField>
      <DesktopDatePicker
        label="From Date"
        value={from}
        onChange={handleFromChange}
        renderInput={(params) => <TextField {...params} />}
      />
      <DesktopDatePicker
        label="To Date"
        value={to}
        onChange={handleToChange}
        renderInput={(params) => <TextField {...params} />}
      />
      <TextField
        fullWidth
        value={filterName}
        onChange={(event) => onFilterName(event.target.value)}
        placeholder="Search user..."
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Iconify
                icon={'eva:search-fill'}
                sx={{ color: 'text.disabled', width: 20, height: 20 }}
              />
            </InputAdornment>
          ),
        }}
      />
    </Stack>
  );
}
