// THIS COMPONENT IS USED WITH BOOKINGS AND BOOKING SESSIONS AS WELL
// mui
import {
  Grid,
  IconButton,
  InputAdornment,
  Skeleton,
  TextField,
  Tooltip,
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
// components
import Iconify from '@/components/Iconify';
import DropdownSelection from '@/components/DropdownSelection';
//
import useResponsive from '@/hooks/useResponsive';
//types
import { Settings } from '@/types';
import { User } from '@/features/users/shared/types';

type CouponsTableToolbarProps = {
  filterUser: number;
  filterPlatform: number;
  filterSearchBar: string;
  filterStartDate: string | null;
  filterEndDate: string | null;
  filterStatusId?: number;
  //
  handleFilterDropdown: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleSearchField: (value: string) => void;
  handleDatepickerChange: (
    value: string | null,
    date_type: string,
    keyboardInputValue?: string | undefined
  ) => void;
  resetFilters: () => void;
  //
  settingsData: Settings | undefined;
  clientsList: User[] | undefined;
  showStatusFilters?: boolean;
};

export default function CouponsTableToolbar({
  filterUser,
  filterPlatform,
  filterSearchBar,
  filterStartDate,
  filterEndDate,
  filterStatusId = 0,
  //
  handleFilterDropdown,
  handleSearchField,
  handleDatepickerChange,
  resetFilters,
  //
  settingsData,
  clientsList,
  showStatusFilters = false,
}: CouponsTableToolbarProps) {
  const downXl = useResponsive('down', 'xl');
  if (!settingsData || !clientsList)
    return (
      <Skeleton
        variant="rectangular"
        height={64}
        animation="wave"
        sx={{ backgroundColor: 'background.paper' }}
      />
    );

  const isFiltering =
    filterUser !== 0 ||
    filterPlatform !== 0 ||
    !!filterStartDate ||
    !!filterEndDate ||
    !!filterSearchBar ||
    (showStatusFilters && filterStatusId !== 0);

  const BASE_ARRAY = [{ id: 0, name: 'All' }];

  const clientsOptions = [
    ...BASE_ARRAY,
    ...clientsList.map((item: User) => ({
      id: item.id,
      name: item.first_name + ' ' + item.last_name,
    })),
  ];

  const platformsOptions = [
    ...BASE_ARRAY,
    ...settingsData.platforms.map((item) => ({
      id: item.id,
      name: item.name,
    })),
  ];

  const xlDropdownGridItemSize = showStatusFilters ? 1.5 : 2;
  const xlSearchbarSize = showStatusFilters ? 3 : 4;

  return (
    <Grid
      container
      spacing={2}
      direction={{ xs: 'column', lg: 'row' }}
      sx={{ py: 2.5, px: 3 }}
    >
      {/* CLIENT */}
      <Grid item xs={12} md={6} xl={xlDropdownGridItemSize}>
        <DropdownSelection
          name="user"
          label="Client"
          filterDropdownValue={filterUser}
          listOptions={clientsOptions}
          handleFilterDropdown={handleFilterDropdown}
        />
      </Grid>

      {/* PLATFORM */}
      <Grid item xs={12} md={6} xl={xlDropdownGridItemSize}>
        <DropdownSelection
          name="platform"
          label="Platform"
          filterDropdownValue={filterPlatform}
          listOptions={platformsOptions}
          handleFilterDropdown={handleFilterDropdown}
        />
      </Grid>

      {/* START DATE */}
      <Grid item xs={12} md={6} xl={xlDropdownGridItemSize}>
        <DatePicker
          label="Start Date"
          value={filterStartDate}
          onChange={(date) => handleDatepickerChange(date, 'start')}
          renderInput={(params) => (
            <TextField {...params} size="small" fullWidth />
          )}
          componentsProps={{
            actionBar: {
              actions: ['clear'],
            },
          }}
        />
      </Grid>

      {/* END DATE */}
      <Grid item xs={12} md={6} xl={xlDropdownGridItemSize}>
        <DatePicker
          label="End Date"
          value={filterEndDate}
          minDate={filterStartDate}
          onChange={(date) => handleDatepickerChange(date, 'end')}
          renderInput={(params) => (
            <TextField {...params} size="small" fullWidth />
          )}
          componentsProps={{
            actionBar: {
              actions: ['clear'],
            },
          }}
        />
      </Grid>

      <Grid
        item
        container
        direction={{ xs: 'row', md: 'row', xl: 'row' }}
        spacing={2}
        xs={12}
        md={12}
        xl={xlSearchbarSize}
        alignItems="center"
      >
        <Grid item xs={12} md={12} xl={isFiltering ? 10.5 : 12}>
          <TextField
            fullWidth
            value={filterSearchBar}
            onChange={(event) => handleSearchField(event.target.value)}
            placeholder="Search in coupons..."
            size="small"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Iconify
                    icon={'eva:search-fill'}
                    sx={{ color: 'text.disabled', width: 20, height: 20 }}
                  />
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        {isFiltering && (
          <Grid item xs={12} md={12} xl={1} justifyContent="center">
            <Tooltip title="Reset filters" placement="top">
              <span>
                <IconButton
                  color="inherit"
                  onClick={resetFilters}
                  sx={{
                    fontSize: 14,
                    display: 'flex',
                    direction: 'row',
                    gap: 0.5,
                  }}
                >
                  <Iconify
                    icon="eva:close-circle-outline"
                    sx={{ width: 20, height: 20 }}
                  />
                  {downXl && 'Reset filters'}
                </IconButton>
              </span>
            </Tooltip>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
}
