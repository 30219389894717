import { useEffect, useRef, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
// @mui
import { List, Collapse, Link, SxProps } from '@mui/material';
//
import { NavListProps } from '../type';
import { getActive, isExternalLink } from '..';
import { useHasPermission } from '@/hooks/useHasPermissions';
import useAuth from '@/hooks/useAuth';
import { isEmpty } from 'lodash';
import { PaperStyle } from '../horizontal/style';
import { NavItem } from './NavItem';

// ----------------------------------------------------------------------

type NavListRootProps = {
  sx: SxProps;
  data: NavListProps;
  depth: number;
  hasChildren: boolean;
  isCollapse?: boolean;
  restrictedTo?: string[];
};

export default function NavList({
  sx,
  data,
  depth,
  hasChildren,
  isCollapse = false,
  restrictedTo = [],
}: NavListRootProps) {
  const { pathname } = useLocation();
  const active = getActive(data.path, pathname);
  // use States
  const [open, setOpen] = useState(active);
  const navigate = useNavigate();
  const menuRef = useRef(null);

  useEffect(() => {
    isCollapse ? setOpen(false) : setOpen(active);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCollapse]);

  const handleClickListItem = () => {
    if (!hasChildren) {
      navigate(data.path);
    }
    setOpen(!open);
  };

  const handleMouseEnter = () => {
    setOpen(true);
  };

  const handleMouseLeave = () => {
    setOpen(false);
  };

  // Auth restrictions
  const { isSuperAdmin, permissions } = useAuth();
  let { isAllowed } = useHasPermission(restrictedTo, permissions, isSuperAdmin);
  if (isEmpty(restrictedTo)) {
    isAllowed = true;
  }
  return (
    <>
      {isExternalLink(data.path) ? (
        <Link href={data.path} target="_blank" rel="noopener" underline="none">
          <NavItem
            item={data}
            depth={depth}
            open={open}
            active={active}
            isCollapse={isCollapse}
            hide={!isAllowed}
          />
        </Link>
      ) : (
        <NavItem
          sx={sx}
          item={data}
          depth={depth}
          open={open}
          active={active}
          ref={menuRef}
          isCollapse={isCollapse}
          onClick={handleClickListItem}
          onMouseLeave={isCollapse ? handleMouseLeave : undefined}
          onMouseEnter={isCollapse ? handleMouseEnter : undefined}
          hide={!isAllowed}
        />
      )}

      {!isCollapse && hasChildren && (
        <Collapse in={open} unmountOnExit>
          <List component="div" disablePadding sx={sx}>
            <NavSubList sx={sx} data={data.children} depth={depth} />
          </List>
        </Collapse>
      )}
      {isCollapse && hasChildren && !!menuRef.current && (
        <PaperStyle
          open={open}
          anchorEl={menuRef.current}
          anchorOrigin={
            depth === 1
              ? { vertical: 'top', horizontal: 'right' }
              : { vertical: 'top', horizontal: 'right' }
          }
          transformOrigin={
            depth === 1
              ? { vertical: 'top', horizontal: 'left' }
              : { vertical: 'center', horizontal: 'left' }
          }
          PaperProps={{
            onMouseEnter: handleMouseEnter,
            onMouseLeave: handleMouseLeave,
            sx: {
              '& .MuiListItemIcon-root': {
                display: 'none',
              },
              '& > .MuiListItemButton-root ': {
                display: 'block',
              },
              '& .MuiButtonBase-root ': {
                m: 0,
                height: 'auto',
              },
            },
          }}
        >
          <NavSubList data={data.children} depth={depth} sx={sx} />
        </PaperStyle>
      )}
    </>
  );
}

// ----------------------------------------------------------------------

type NavListSubProps = {
  data: NavListProps[];
  depth: number;
  sx: SxProps;
};

function NavSubList({ data, depth, sx }: NavListSubProps) {
  return (
    <>
      {data.map((list) => (
        <NavList
          key={list.title + list.path}
          data={list}
          depth={depth + 1}
          hasChildren={!!list.children}
          restrictedTo={list.restricted_to}
          sx={sx}
        />
      ))}
    </>
  );
}
