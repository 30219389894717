import axios from '@/utils/axios';
// types
import { Coupon, CouponDto } from '../types';

// GET
export async function getCoupons(): Promise<Coupon[]> {
  const {
    data: { data },
  } = await axios.get('/coupons');
  return data;
}

export async function getSingleCoupon(couponId: number): Promise<Coupon> {
  const {
    data: { data },
  } = await axios.get(`/coupons/${couponId}`);

  return data;
}

// POST
export const createCoupon = async (coupon: CouponDto) =>
  axios.post('/coupons', coupon);

// PUT
export const updateCoupon = async (couponId: number, coupon: CouponDto) =>
  axios.put(`/coupons/${couponId}`, coupon);

// DELETE
export const deleteCoupon = async (couponId: number) =>
  axios.delete(`/coupons/${couponId}`);

type VerifyCoupon = {
  service_variant_id: number;
  couponCode: string;
  platform: string;
};

export async function verifyCoupon(
  verifyCouponData: VerifyCoupon
): Promise<Coupon> {
  const {
    data: { coupon },
  } = await axios.post(`/coupons/verify`, verifyCouponData);
  return coupon;
}

export async function removeCoupon(bookingID: number) {
  const { data } = await axios.post(`/coupons/remove`, { bookingID });
  return data;
}
